import React from 'react';
import classNames from 'classnames';
import { useStoreState } from '~/store';
import Preview from './Preview';
import ControlBar from './ControlBar';
import { IBannerCard } from './types';

export default ({ id }: IBannerCard) => {
  const item = useStoreState(state => state.banners.items.byId(id));

  if (!item) return null;

  return (
    <div
      className={classNames('banner-card', {
        active: item.ispublished,
      })}
    >
      <div className="banner-card-content">
        <div className="banner-card-preview-status">
          <Preview {...item} />
        </div>
        <ControlBar {...item} />
      </div>
    </div>
  );
};
