type Code = 200 | 204 | 304 | 400 | 401 | 403 | 404 | 500;

interface ApiResponse<S extends Code, D> extends Response {
  readonly status: S;
  json(): Promise<D>;
}

type AnyResponse = ApiResponse<Code, any>;

type Options<R extends AnyResponse> = {
  [N in Code]?: R extends ApiResponse<N, infer D>
    ? (response: ApiResponse<N, D>) => any
    : never;
} & {
  default?: (response: AnyResponse) => any;
};

export function processResponse<R extends AnyResponse>(
  response: R,
  options: Options<R>
) {
  const handler = options[response.status] || options.default;
  if (typeof handler === 'function') return handler(response as any);
}
