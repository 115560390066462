import React, { useContext, useState, useEffect } from 'react';
import { Switch, Button, Loading } from 'Components/nui';
import { useParams, useHistory } from 'react-router-dom';
import { ApiContext } from '~/context';
import { useAsync } from 'react-use';
import { LoadingState } from 'Components/Layout';
import { useStoreState } from 'easy-peasy';
import R from 'ramda';
import { api } from '~/api';
import classNames from 'classnames';
import Helmet from 'Components/Helmet';
import { useSharedProduct } from '..';
import { toast } from 'react-toastify';

const List = ({ preselected, customers }) => {
  const { id } = useParams();
  const solution = useStoreState(state => state.auth.solution);

  const [selected, setSelected] = useState(() => preselected.map(x => x.id));
  const [selIncoterms, setSelIncoterms] = useState(() => {
    let d = {};
    preselected.forEach(x => {
      d[x.id] = x.productlink.incoterm;
    });
    return d;
  });

  const undetermined =
    selected.length < customers.length && selected.length !== 0;

  const save = async (data, selData) => {
    toast('Customer preferences are saved', {
      type: 'success',
    });
    try {
      await api.getData({ type: 'products', id: `${id}/customers` }, null, {
        method: 'put',
        data: {
          customers: data.map(el => ({
            id: el,
            incoterm: selData[el] || defIncoterm,
          })),
        },
      });
    } catch (err) {
      // const errors = R.path(
      //   ['response', 'data', 'errors', 0, 'description'],
      //   err
      // );
    }
  };

  const saveCustomer = async (customerId, incoterm) => {
    toast('Customer added to the list', {
      type: 'success',
    });
    try {
      await api.getData(
        { type: 'products', id: `${id}/customers/${customerId}` },
        null,
        {
          method: 'put',
          data: {
            incoterm,
          },
        }
      );
    } catch (err) {
      // const errors = R.path(
      //   ['response', 'data', 'errors', 0, 'description'],
      //   err
      // );
    }
  };

  const delCustomer = async customerId => {
    toast('Customer removed from the list', {
      type: 'success',
    });
    try {
      await api.getData(
        { type: 'products', id: `${id}/customers/${customerId}` },
        null,
        {
          method: 'delete',
        }
      );
    } catch (err) {
      // const errors = R.path(
      //   ['response', 'data', 'errors', 0, 'description'],
      //   err
      // );
    }
  };

  const onChange = (c_id, checked) => {
    const newData = checked
      ? R.append(c_id, selected)
      : R.without([c_id], selected);

    setSelected(newData);
    if (checked) saveCustomer(c_id, selIncoterms[c_id] || defIncoterm);
    else delCustomer(c_id);
  };

  const onSelChange = (c_id, value) => {
    const newData = { ...selIncoterms, [c_id]: value };
    setSelIncoterms(newData);
    saveCustomer(c_id, value);
  };

  const onChangeAll = checked => {
    const newData = checked ? customers.map(item => item.id) : [];
    setSelected(newData);
    save(newData, selIncoterms);
  };

  const incoterms = R.pathOr([], ['incoterms'], solution);
  const defIncoterm = R.pathOr('EXW', [0, 'id'], incoterms);

  const options = incoterms.map(x => (
    <option key={x.id} value={x.id}>
      {x.id}
    </option>
  ));

  return (
    <ul className="item-list no-ws">
      <li dataset="">
        <label className="master-switch">
          <Switch
            className={classNames({ 'nui-switch-undetermined': undetermined })}
            checked={selected.length > 0}
            onChange={checked => onChangeAll(checked)}
          />
          <strong className="all-black">All customers</strong>
        </label>
        <span className="pos-right">
          <strong className="all-black">Incoterms</strong>
        </span>
      </li>
      {customers.map(c => (
        <li key={c.id} dataset={c.name.charAt(0).toUpperCase()}>
          <label>
            <Switch
              checked={selected.includes(c.id)}
              onChange={checked => onChange(c.id, checked)}
            />
            <span>{c.name}</span>
          </label>
          <div className="pos-right">
            <div className="nui-fieldset inline-block p-0 m-0 mt--2">
              <div className="select-holder tiny">
                <select
                  value={R.propOr(defIncoterm, c.id, selIncoterms)}
                  onChange={e => onSelChange(c.id, e.target.value)}
                  disabled={!selected.includes(c.id)}
                >
                  {options}
                </select>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default () => {
  const { id } = useParams();
  const api = useContext(ApiContext);
  const history = useHistory();
  const solution = useStoreState(state => state.auth.solution);
  const [product, _] = useSharedProduct();
  const [customers, setCustomers] = useState(null);

  useEffect(() => {
    const getResult = x => {
      let all = [];
      x.customers.forEach(item => {
        item.links.forEach(z => {
          if (z.seller.id === product.division.id) {
            all.push(z.buyer);
          }
        });
      });
      return R.sortBy(R.compose(R.toLower, R.prop('name')))(all);
    };

    const fetch = async () => {
      const data = await api.getData(
        {
          type: 'customers',
          getResult,
        },
        { solution: solution.id }
      );
      setCustomers(data);
    };
    if (solution && product) fetch();
  }, [api, solution, product]);

  const state = useAsync(() => {
    return api.getData({
      type: 'products',
      id: `${id}/customers`,
      getResult: x => x.customers,
    });
  }, [api, solution]);

  const finish = () => {
    history.push('/products');
    toast('Your product is saved', {
      type: 'success',
    });
  };

  if (!product) return null;
  if (customers === null) return <Loading size="medium" />;

  return (
    <>
      <Helmet>
        <title>Edit product {product.name} - Customers</title>
      </Helmet>
      <LoadingState state={state}>
        {preselected => (
          <List preselected={preselected} customers={customers} />
        )}
      </LoadingState>
      <div className="sticky-btm">
        <Button
          type="primary"
          className="button primary mb-10"
          onClick={finish}
        >
          Finish
        </Button>
      </div>
    </>
  );
};
