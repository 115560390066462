import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import classNames from 'classnames';
import { Button, Checkbox } from 'Components/nui';

export default () => {
  const mode = useStoreState(state => state.marketplace.mode);
  const volumeMode = useStoreState(state => state.marketplace.volumeMode);
  const setData = useStoreActions(actions => actions.marketplace.setData);

  const extra = { tableData: {}, columns: [] };

  return (
    <div className="view-options">
      <div className="table-display-options">
        <Button
          className={classNames({ active: mode === 'month' })}
          onClick={() => setData({ mode: 'month', ...extra })}
        >
          Months
        </Button>
        <Button
          className={classNames({ active: mode === 'week' })}
          onClick={() => setData({ mode: 'week', ...extra })}
        >
          Weeks
        </Button>
        <Button
          className={classNames({ active: mode === 'day' })}
          onClick={() => setData({ mode: 'day', ...extra })}
        >
          Days
        </Button>
      </div>
      <div className="toggle-volume">
        <div className="fieldset check-fieldset">
          <Checkbox
            checked={volumeMode}
            onChange={() => setData({ volumeMode: !volumeMode })}
            title="Display volume"
          >
            Vol<span className="hide-for-small">ume</span>
          </Checkbox>
        </div>
      </div>
    </div>
  );
};
