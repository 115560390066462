import React, { useEffect } from 'react';
import { Table, Loading } from 'Components/nui';
import { useStoreState, useStoreActions } from '~/store';
import { useInterval } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import useTable from './hooks/useTable';
import useSortedData from './hooks/useSortedData';
import MarketRow from 'Pages/MarketRow';

const UPDATE_INTERVAL = 30000;

const Body = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const solution = useStoreState(state => state.auth.solution);
  const productById = useStoreState(state => state.auth.productById);

  const setData = useStoreActions(actions => actions.market.setData);
  const update = useStoreActions(actions => actions.market.update);

  useEffect(() => {
    setData({
      updatedDate: null,
    });
    update();
  }, [solution]);

  useInterval(() => {
    update();
  }, UPDATE_INTERVAL);

  const { columns, expandedRowKeys, setExpandedRowKeys } = useTable([
    'details',
    ['product', ['product-brand', 'product-name']],
    ['legend', ['legend-price', 'legend-volume', 'legend-type']],
    ['ref-price', ['ref-price-content']],
    ['state', ['state-tradeable']],
    'attribute',
    'origins',
    'locations',
    'incoterms',
    ['etd', ['etd-from', 'etd-to']],
    ['loading', ['loading-desc']],
    'splittable',
    'payment-term',
    'published',
  ]);

  const data = useSortedData();

  if (data === null)
    return (
      <div className="nui-row full-page-messaging align-center mt-10 pt-100 p-100">
        <Loading size="huge" />
      </div>
    );

  if (data.length === 0)
    return (
      <div className="nui-row full-page-messaging align-center mt-10 pt-100 p-100">
        <div className="icon-inbox bordered align-center">
          There are currently no offers or bids
          {data.length !== 0 && (
            <>
              <br />
              for the selected products
            </>
          )}
        </div>
      </div>
    );

  return (
    <div className="full-page-content">
      <Table
        className="md-table generic-table expand-table"
        columns={columns}
        data={data}
        rowClassName={(record, _index) => {
          let name = expandedRowKeys.includes(record.id) ? 'selected' : '';
          const product = productById(record.product.id);
          if (!product) {
            name = name + ' viewonly';
          }
          return name;
        }}
        expandedRowRender={record => (
          <MarketRow id={record.id} updated={record.updated} />
        )}
        expandedRowKeys={expandedRowKeys}
        onExpand={(expanded, record) => {
          if (expandedRowKeys.includes(record.id)) {
            setExpandedRowKeys([0]);
            history.push(pathname);
          } else {
            setExpandedRowKeys([record.id]);
            history.push(
              `${pathname}?${qs.stringify({
                order: record.id,
              })}`
            );
          }
        }}
      />
    </div>
  );
};

export default Body;
