//
import * as React from 'react';

// TODO - what are these types?

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error('Error boundary caught', error, info);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Error!</h1>;
    }
    return this.props.children;
  }
}
