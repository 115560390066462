import React from 'react';
import Upload from 'rc-upload';
import type { IUpload } from 'rc-upload';

export default (props: Omit<IUpload, 'component' | 'prefixCls'>) => (
  <Upload component="div" prefixCls="input-holder nui-upload" {...props}>
    <span className="icon-upload-cloud">
      Drag your files here, or click to browse...
    </span>
  </Upload>
);
