import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withPreload, matchUrl, usePathTools } from '~/router';
import * as service from '~/services/tenders';
import { capitalize } from '~/utils';
import classnames from 'classnames';

export default () => {
  const cls = 'm-0';

  return (
    <>
      <Switch>
        <Route exact path={matchUrl('tender-overview')}>
          <h1 className={cls}>Tenders</h1>
        </Route>
        <Route path={matchUrl('tender-history')}>
          <h1 className={cls}>Tender history</h1>
        </Route>
        <Route path={matchUrl('tender-analytics')}>
          <h1 className={cls}>Tender analytics</h1>
        </Route>
        <Route path={matchUrl('tender-view')}>
          <ViewHeading />
        </Route>
        <Route path={matchUrl('tender-new')}>
          <EditHeading />
        </Route>
      </Switch>
    </>
  );
};

const ViewHeading = withPreload({
  route: 'tender-view',
  preload: service.observe.tender(),
})(({ data: tender }) => {
  return (
    <h1 className="m-0">
      <span
        className={classnames(`type-${tender.type}`, 'inline', 'align-middle')}
      >
        {tender.type}
      </span>{' '}
      Tender <span className="smaller">{tender.name}</span>
    </h1>
  );
});

const EditHeading = withPreload({
  route: 'tender-new',
  preload: service.observe.tender({ requireId: false }),
})(({ data: tender }) => {
  const { matchParams } = usePathTools();
  const { mode = 'new' } = matchParams('tender-new');
  return (
    <h1 className="m-0">
      {capitalize(mode)} tender{' '}
      {tender && <span className="smaller">{tender.name}</span>}
    </h1>
  );
});
