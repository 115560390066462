import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import R from 'ramda';
import { capitalize } from '~/utils';
import classNames from 'classnames';
import { Button } from 'Components/nui';

export default ({
  errors = [],
  label = 'Upload file(s)',
  initialData = {},
  files,
  setFiles,
  accept,
}) => {
  const [existing, setExisting] = useState(
    R.indexBy(R.prop('id'), R.propOr([], 'files', initialData))
  );

  const deleteFile = id => {
    setFiles(items => R.reject(R.propEq('id', id), items));
  };

  const removeFile = id => {
    setExisting(R.reject(R.propEq('id', id), existing));
  };

  return (
    <>
      <hr />
      <div className="tender-offer-documents upload-attachments file-section">
        <div
          className={classNames('nui-fieldset', {
            'has-errors': errors.length,
          })}
        >
          <label htmlFor="files">{label}</label>
          <div className="nui-files-wrapper">
            <div className="input-holder">
              <input
                multiple
                type="file"
                id="files"
                className="inputfile"
                onChange={e => {
                  const newFiles = [...e.target.files].map(f => {
                    f.id = uuid();
                    return f;
                  });
                  setFiles(st => st.concat(newFiles));
                  // onChange(e);
                }}
                accept={accept}
              />
              <div className="icon-upload-cloud dummy upload">
                Drag your files here, or click to browse...
              </div>
            </div>
            <div className="document-list-box">
              <ul className="file-list">
                {files.map(file => (
                  <li key={file.id}>
                    <span title={file.name}>{file.name}</span>

                    <Button
                      icon="trash"
                      type="reverse"
                      onClick={() => deleteFile(file.id)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {!!errors.length && (
            <ul className="error-list">
              {errors.map(e => (
                <li key={e.description} className="error-description">
                  {capitalize(e.description)}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
