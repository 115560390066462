import React from 'react';
import { makeStatusBadge } from '~/components/nui/StatusBadge';
import type { BannerStatus as BannerStatusType } from '~/store/bannerModel/types';
import { BannerStatus } from '~/store/bannerModel/utils/getStatus';

export const Badge = makeStatusBadge<BannerStatusType[]>(
  ['expired', 'scheduled', 'live', 'paused', 'draft'],
  undefined,
  {
    paused: (_status, { formattedStatus }) => formattedStatus('Planned'),
    expired: (_status, { formattedStatus }) => formattedStatus('Expired'),
  }
);

export default function BadgeMapper({ status }: { status: BannerStatus }) {
  return <Badge status={status} />;
}
