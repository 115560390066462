import type { Tender, Order } from '~/models/tenders';
import React from 'react';
import { routeUrl, withPreload, withRouteX } from '~/router';
import { LabelledInfo, StackItem, ListedData } from 'Components/nui/Wizard';
import * as Data from 'Components/Data';
import { capitalize } from '~/utils';
import classnames from 'classnames';
import { Alert, Button } from 'Components/nui';
import { toast } from 'react-toastify';
import Access from 'Components/Access';
import { useMountedState } from '~/hooks';
import { useHistory } from 'react-router-dom';
import * as service from '~/services/tenders';

type TenderProp = Record<'tender', Tender>;
type OrdersProp = Record<'orders', Order[]>;

const Review = withPreload({
  route: 'tender-edit-review',
  preload: service.observe.orders(),
})(({ data: { tender, orders } }) => {
  return (
    <div className="tender-edit-step review">
      <FormInfo tender={tender} />

      <TenderDetails tender={tender} />
      <hr />

      <OrderDetails tender={tender} orders={orders} />

      <div className="sticky-btm">
        <Access to="publish" model={tender}>
          <Publish tender={tender} />
        </Access>

        <Access to="notify" model={tender}>
          <Notify tender={tender} />
        </Access>
      </div>
    </div>
  );
});
export default withRouteX({ name: 'tender-edit-review' })(Review);

const FormInfo = ({ tender }: TenderProp) => (
  <Access to={['publish', 'notify']} model={tender}>
    <Alert type="info" hasicon className="mb-20">
      <Access to="publish" model={tender}>
        <p>
          Once you publish this tender, participants will be notified and they
          will be able to view the tender. You will not be able to change the
          start, duration, or method of the tender.
        </p>
      </Access>
      <Access to="notify" model={tender}>
        <p>
          Notifying participants will create a notification for all participants
          about the tender. You can notify participants at any time.
        </p>
        {tender.notified && (
          <p>
            Participants were last notified on{' '}
            <strong>
              <Data.Datetime value={tender.notified} />
            </strong>
          </p>
        )}
      </Access>
    </Alert>
  </Access>
);

const infoFmt = 'LL HH:mm Z';
type ITenderDetails = TenderProp;
const TenderDetails = ({ tender }: ITenderDetails) => (
  <LabelledInfo
    data={[
      ['Tender name', tender.name],
      ['Method', <Data.TenderMethod info={tender} />],
      ['Status', <TenderStatus value={tender.status} />],
      ['Start', <Data.Datetime value={tender.start} fmt={infoFmt} />],
      ['Finish', <Data.Datetime value={tender.finish} fmt={infoFmt} />],
      ['Duration', <Data.Duration value={tender.duration} />],
      [
        capitalize(tender.ordername.order) + 's',
        tender.orders.total.toFixed(0),
      ],
      [
        'Participants',
        tender.participants!.included.toFixed(0) +
          '/' +
          tender.participants!.total.toFixed(0),
      ],
    ]}
  />
);

const OrderDetails = ({ tender, orders }: TenderProp & OrdersProp) =>
  orders.length ? (
    <ListedData
      title={capitalize(tender.ordername.order) + 's'}
      className="summary-orders-list"
    >
      {orders.map(order => (
        <StackItem
          key={order.id}
          title={order.product.name}
          data={
            <>
              <Data.IndexUnit value={order.startprice} />{' '}
              {order.reserve.price.val && (
                <>
                  Reserve price: <Data.Price value={order.reserve.price} />
                  {' | '}
                </>
              )}
              <Data.Price onZero="Any" value={order.startprice} /> /{' '}
              <Data.Volume value={order.volume} /> {order.volume.unit} |{' '}
              <Data.Attributes value={order.attributes} />
              ETD <Data.ETD value={order.etd} />
            </>
          }
        />
      ))}
    </ListedData>
  ) : null;

const TenderStatus = ({ value }: Record<'value', string>) => (
  <span className={classnames('status', value)}>{value}</span>
);

const Publish = ({ tender }: TenderProp) => {
  const [loading, setLoading] = useMountedState(false);
  const history = useHistory();

  return (
    <Button
      type="primary"
      className="mb-10"
      loading={loading}
      disabled={loading}
      onClick={async () => {
        setLoading(true);

        const result = await service.actions.publish(tender.id);
        if (result?.success) {
          toast.success(
            <>
              Tender <strong>{tender.name}</strong> has been published
              successfully.
            </>
          );
          history.push(routeUrl('tender-view', { tenderId: tender.id }));
        } else if (result?.validate) {
          toast.error(<p>{capitalize(result.validate)}</p>);
          setLoading(false);
        } else if (result?.errors) {
          toast.error(
            <>
              <p>This tender could not be published</p>
              <ul>
                {result.errors.map(e => (
                  <li>{capitalize(e)}</li>
                ))}
              </ul>
            </>
          );
          setLoading(false);
        }
      }}
    >
      Publish tender
    </Button>
  );
};

const Notify = ({ tender }: TenderProp) => {
  const [loading, setLoading] = useMountedState(false);

  return (
    <Button
      type="primary"
      loading={loading}
      disabled={loading}
      onClick={async () => {
        setLoading(true);

        const result = await service.actions.notify(tender.id);
        if (result?.success) toast.success('Participants will be notified');
        else if (result?.errors)
          toast.error('An error occurred. Participants were not notified.');

        setLoading(false);
      }}
    >
      Notify participants
    </Button>
  );
};
