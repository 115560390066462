import React from 'react';
import classnames from 'classnames';

const sizes = {
  full: 'full-page-messaging',
  medium: 'half-page-messaging',
};
type MessageSize = keyof typeof sizes;

interface IConfig {
  size?: MessageSize;
  icon?: string;
  className?: string;
  children?: React.ReactNode;
}
const InfoMessage = ({
  size = 'full',
  icon = 'icon-inbox',
  className,
  children,
}: IConfig) => (
  <div className={classnames(sizes[size], className, 'align-center')}>
    <div className={classnames(icon, 'bordered align-center')}>{children}</div>
  </div>
);
export default InfoMessage;

export const ComingSoon = (props: IConfig) => (
  <InfoMessage icon="icon-eye-off" {...props}>
    This content is under development.
  </InfoMessage>
);

export const NoData = ({
  children = 'No data',
  icon = 'icon-inbox',
  ...props
}: IConfig) => (
  <InfoMessage icon={icon} {...props}>
    {children}
  </InfoMessage>
);

export const PermissionDenied = ({
  children = 'You do not have permission to view this content.',
  icon = 'icon-attention',
  ...props
}: IConfig) => (
  <InfoMessage icon={icon} {...props}>
    {children}
  </InfoMessage>
);
