import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Helmet from 'Components/Helmet';
import Edit from './Edit';
import Warehouses from './Warehouses';

export default () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Locations</title>
      </Helmet>
      <Switch>
        <Route path={`${url}/new`}>
          <Edit />
        </Route>
        <Route>
          <Warehouses />
        </Route>
      </Switch>
    </>
  );
};
