import React from 'react';
import YesNo from 'Components/YesNo';
import { FormattedMessage } from 'react-intl';

export default ({ loading, unit }) => {
  if (!loading) return null;
  return (
    <>
      <h2>
        <FormattedMessage
          id="loadingdetails-header"
          description="Header for Loading details"
          defaultMessage="Loading details"
        />
      </h2>

      <ul className="trade-details-list loading-details">
        {loading.container && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="loadingdetails-field-shippingunittype-label"
                description="Label for 'Shipping unit type' field on Loading details"
                defaultMessage="Shipping unit type"
              />
            </strong>
            <span>{loading.container}</span>
          </li>
        )}

        {loading.reefer && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="loadingdetails-field-reefer-label"
                description="Label for 'Reefer' field on Loading details"
                defaultMessage="Reefer"
              />
            </strong>
            <YesNo yes={!!loading.reefer} />
          </li>
        )}

        <li className="reversed">
          <strong>
            <FormattedMessage
              id="loadingdetails-field-loadingweight-label"
              description="Label for 'Loading weight' field on Loading details"
              defaultMessage="Loading weight"
            />
          </strong>
          <span>
            {loading.fixed ? (
              <>
                {loading.qty} {unit}
              </>
            ) : (
              'Variable'
            )}
          </span>
        </li>

        {loading.packaging && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="loadingdetails-field-packagingtype-label"
                description="Label for 'Packaging type' field on Loading details"
                defaultMessage="Packaging type"
              />
            </strong>
            <span>{loading.packaging}</span>
          </li>
        )}

        {'packing_load' in loading && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="loadingdetails-field-numberofpackages-label"
                description="Label for 'Number of packages' field on Loading details"
                defaultMessage="Number of packages"
              />
            </strong>
            <span>{loading.packing_load}</span>
          </li>
        )}
      </ul>
    </>
  );
};
