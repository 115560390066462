import React from 'react';

export default () => {
  return (
    <div className="full-page-header">
      <h1 className="inline mt-10 mr-20 mb-0">Dashboard</h1>

      <ul className="nui-bc right pt-20">
        <li />
      </ul>
    </div>
  );
};
