import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useInterval } from 'react-use';
import { Counter } from 'models.market';

export interface ICounterExpiry {
  counter: Counter;
}

const UPDATE_INTERVAL = 20000;

export default function CounterExpiry({ counter }: ICounterExpiry) {
  const [now, setNow] = useState(moment());
  useInterval(() => setNow(moment()), UPDATE_INTERVAL);

  const expiryAt = moment(counter.expiry);
  const expiryIn = moment.duration(expiryAt.diff(now));

  return (
    <div className="icon-clock expiry">
      {expiryAt.isBefore(now) ? 'Expired' : 'Expires'} on{' '}
      {expiryAt.format('LLL')} ({expiryIn.humanize(true)})
    </div>
  );
}
