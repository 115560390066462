import type { ICustomerRequest } from '~/store/models';
import * as React from 'react';
import classnames from 'classnames';
import { capitalize } from '~/utils';

interface IFormStatus {
  customer: { formstatus?: ICustomerRequest['formstatus'] };
}
export const FormStatus = ({ customer }: IFormStatus) => {
  const { formstatus = 'incomplete' } = customer;
  const icons = {
    incomplete: 'icon-pencil',
    rejected: 'icon-attention',
    pending: 'icon-clock',
    processed: 'icon-check',
    cancelled: 'icon-trash',
  };
  const icon = icons[formstatus];

  return (
    <span className={classnames('formstatus', customer.formstatus, icon)}>
      {capitalize(formstatus)}
    </span>
  );
};
