import { Drawer } from 'antd';
import R from 'ramda';
import * as React from 'react';
import { useDrawerWidth } from '~/hooks';
import { Loading, Alert } from 'Components/nui';

// Show loading, error state or render children props, depending on state
// render props makes it easier to write cleaner hooks logic
// TODO use Skeleton in some cases?
export function LoadingState({
  state,
  children,
  placeholder = <div style={{ height: 80 }} />,
}) {
  if (!state) return null;
  const { loading, error, value } = state;

  if (loading) {
    return <Loading className="mb-10 mt-10" size="medium" />;
  }

  if (R.isNil(value) && !error) {
    return placeholder;
  }

  if (error) {
    return (
      <Alert hasicon type="error" className="align-center">
        Failed to get data. Please try later.
      </Alert>
    );
  }

  return children(value);
}

export const ModalContext = React.createContext({
  close: () => null,
  prevLocation: {},
});

// XXX React router's children props does not support React hooks well, we need a function component here
export function DrawerWrapper({
  props,
  handleModalClose,
  wrapperProps,
  component: Component,
  prevLocation,
}) {
  const width = useDrawerWidth();
  return (
    <Drawer
      id="neworder"
      placement="left"
      width={width}
      visible={!!props.match}
      onClose={handleModalClose}
      // css={drawerStyle}

      {...wrapperProps}
    >
      {props.match && (
        <ModalContext.Provider
          value={{ prevLocation, close: handleModalClose }}
        >
          <Component
            {...props}
            handleModalClose={handleModalClose}
            prevLocation={prevLocation}
          />
        </ModalContext.Provider>
      )}
    </Drawer>
  );
}
