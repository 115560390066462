import React from 'react';
import { Tooltip } from 'antd';

// export default props => <Tooltip className="" {...props} />;

// import React from 'react';
// import Tooltip from 'rc-tooltip';

// export default props => (
//   <Tooltip
//     placement="top"
//     trigger={['hover']}
//     prefixCls="nui-tooltip"
//     {...props}
//   />
// );

export default ({ img = undefined, children, ...props }) =>
  img ? (
    <Tooltip
      className=""
      title={
        <span className="img-tooltip">
          <img src={img.href} alt={img.filename} />
        </span>
      }
      {...props}
    >
      {children || (
        <span className="icon-file-image image-preview" title={img.filename}>
          {img.filename}
        </span>
      )}
    </Tooltip>
  ) : (
    <Tooltip className="" {...props}>
      {children}
    </Tooltip>
  );
