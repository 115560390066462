import React from 'react';
import * as Wizard from 'Components/nui/Wizard';
import { capitalize } from '~/utils';
import { can } from 'Components/Access';
import Summary from './Summary';
import Details from './Details';
import Orders from './Orders';
import Participants from './Participants';
import Review from './Review';
import {
  matchUrl,
  routeUrl,
  RoutingTable,
  withPreload,
  withRouteX,
} from '~/router';
import * as services from '~/services';
import { useStoreState } from '~/store';

interface IConfig {
  mode?: string;
}
const Edit = withPreload({
  route: 'tender-new',
  preload: services.tenders.observe.orders({ requireId: false }),
})<IConfig>(({ mode = 'edit', data: { tender, orders } }) => {
  const tenderlogic = useStoreState(state => state.auth.solution.tenderlogic);
  const purchasetender = useStoreState(
    state => state.auth.solution.settings.purchasetender
  );

  const isBid = purchasetender && tenderlogic === 'purchase';

  const tenderId = tender?.id;
  const ordername = tender?.ordername.order || (isBid ? 'bid' : 'offer');

  const hasOrders = !!tender?.orders.total;
  const editable = can('edit', tender);

  const canAddOrders = can('orders_add', tender);
  const canEditParticipants = can('participants_edit', tender);
  const canPublish = can('publish', tender);
  const canNotify = can('notify', tender);

  const editDetails = editable;
  const editParticipants = canEditParticipants;
  const editOrders = canAddOrders;
  const allowReview = canPublish || canNotify;

  const steps = [
    {
      id: 'details',
      url: routeUrl('tender-edit-details', { mode, tenderId }),
      path: matchUrl('tender-edit-details'),
      exact: true,
      disabled: !editDetails,
      completed: !!tenderId,
      title: 'Details',
      desc: 'Edit basic tender details',
    },
    {
      id: 'orders',
      url: tenderId && routeUrl('tender-edit-orders', { mode, tenderId }),
      path: matchUrl('tender-edit-orders'),
      disabled: !editOrders,
      completed: hasOrders,
      title: `${capitalize(ordername)}s`,
      desc: `Add ${ordername}s to this tender`,
    },
    {
      id: 'participants',
      url: tenderId && routeUrl('tender-edit-participants', { mode, tenderId }),
      path: matchUrl('tender-edit-participants'),
      disabled: !editParticipants,
      completed: hasOrders,
      title: 'Participants',
      desc: 'Decide who may view this tender',
    },
    {
      id: 'review',
      url: tenderId && routeUrl('tender-edit-review', { mode, tenderId }),
      path: matchUrl('tender-edit-review'),
      disabled: !allowReview,
      title: canPublish
        ? 'Review and publish'
        : canNotify
        ? 'Notify participants'
        : 'Review details',
      desc: canPublish
        ? 'Review tender details and publish when ready'
        : canNotify
        ? 'Notify and remind participants about this tender'
        : 'Review details of this tender',
      numbered: false,
      summary: false,
    },
  ];

  return (
    <Wizard.Wizard steps={steps}>
      <Wizard.Content>
        <Wizard.StepHeading />
        <RoutingTable routes={[Details, Orders, Participants, Review]} />
      </Wizard.Content>
      <Wizard.Summary title="Summary">
        <Summary tender={tender} orders={orders} />
      </Wizard.Summary>
    </Wizard.Wizard>
  );
});
export default withRouteX({
  name: 'tender-new',
})(({ params: { mode } }) => {
  return <Edit mode={mode} />;
});
