import * as Models from '~/models/tenders';
import React, { useState } from 'react';
import { withRouteX, withPreload, usePreloadable } from '~/router';
import * as service from '~/services/tenders';
import { Loading, Alert, Input, Switch } from 'Components/nui';
import * as Data from 'Components/Data';
import { capitalize } from '~/utils';
import { SimpleTable, GenericTable } from 'Components/nui/Table';
import classnames from 'classnames';
import * as Charts from './Charts';

type PReport = Record<'report', Models.TenderReport>;
type PTender = Record<'tender', Models.Tender>;

export default withRouteX({ name: 'tender-report' })(() => <Page />);

const Page = withPreload({
  route: 'tender-report',
  preload: service.observe.tender(),
})(({ data: tender }) => {
  const {
    data: report,
    loading,
    errors,
  } = usePreloadable({
    route: 'tender-report',
    preload: service.analytics.tenderReport(),
  });

  if (errors.length)
    return (
      <div className="tender-report-content error">
        <Alert hasicon type="error">
          There was an error loading this data
        </Alert>
      </div>
    );

  if (loading)
    return (
      <div className="tender-report-content loading">
        <Loading />
      </div>
    );

  if (report)
    return (
      <div className="tender-report-content">
        <hr />
        <TenderInfo tender={tender} report={report} />
        <ChartSection
          title={`${capitalize(tender.ordername.counter)} price`}
          className="counter-price"
        >
          <Charts.CountersPrice tender={tender} charts={report.charts} />
        </ChartSection>
        <hr className="mt-30 mb-30 hide-for-xlarge" />
        <Participants tender={tender} report={report} />
        <hr />
        <OrdersDetails tender={tender} report={report} />
      </div>
    );

  return <></>;
});

const TenderInfo = ({ tender, report }: PTender & PReport) => {
  const counterName = capitalize(tender.ordername.counter);
  return (
    <div className="chart-container table-chart-container tender-report-info">
      <h3>Tender trade details</h3>
      <Data.List>
        <Data.Item
          title={`${counterName}s during extension`}
          className="tender-report-info-extensions"
        >
          <Data.Number value={report.info.extensions} />
        </Data.Item>
        <Data.Item title="Viewers" className="tender-report-info-viewers">
          <Data.Number value={report.info.viewers} />
        </Data.Item>
        <Data.Item
          title={`${counterName}s`}
          className="tender-report-info-counters"
        >
          <Data.Number value={report.info.counters} />
        </Data.Item>
        <Data.Item title="Trades" className="tender-report-info-trades">
          <Data.Number value={report.info.trades} />
        </Data.Item>
        <Data.Item title="Total value" className="tender-report-info-value">
          <Data.PriceTicker title="" value={report.info.value} />
        </Data.Item>
        <Data.Item
          title="Extension value"
          className="tender-report-info-extvalue"
        >
          <Data.PriceTicker title="" value={report.info.extvalue} />
        </Data.Item>
      </Data.List>
    </div>
  );
};

const Participants = ({ tender, report }: PTender & PReport) => {
  const [viewers, setViewers] = useState(true);
  const [search, setSearch] = useState('');

  const column = ({
    className,
    index,
    ...data
  }: {
    name: string;
    title: React.ReactNode;
    className?: string;
    index?: keyof typeof report.participants[number];
    render?: (p: typeof report.participants[number]) => React.ReactNode;
  }) => ({
    ...data,
    key: `col-${data.name}`,
    dataIndex: index,
    className: classnames(`col-${data.name}`, className),
  });

  let processed = report.participants.slice(0);
  if (!viewers) processed = processed.filter(x => x.viewed);
  if (search.length)
    processed = processed.filter(x =>
      x.name.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <div className="chart-container table-chart-container tender-report-participants">
      <h3>Participants</h3>
      <ParticipantFilters
        search={{ state: search, setState: setSearch }}
        viewers={{ state: viewers, setState: setViewers }}
      />
      <div className="table-wrapper">
        <SimpleTable
          className="table"
          columns={[
            column({ name: 'company', title: 'Company', index: 'name' }),
            column({
              name: 'viewed',
              title: 'Viewed',
              render: p => <Data.Bool value={p.viewed} />,
            }),
            column({
              name: 'counters',
              title: capitalize(tender.ordername.counter) + 's',
              index: 'counters',
            }),
            column({
              name: 'trades',
              title: 'Trades',
              render: p => p.trades.count,
            }),
            column({
              name: 'value',
              title: "Trades' value",
              render: p => <Data.PriceTicker title="" value={p.trades.value} />,
            }),
          ]}
          data={processed}
          rowKey="id"
        />
      </div>
    </div>
  );
};

const OrdersDetails = ({ tender, report }: PTender & PReport) => {
  const column = ({
    className,
    index,
    ...data
  }: {
    name: string;
    title: React.ReactNode;
    className?: string;
    index?: keyof typeof report.orders[number];
    render?: (o: typeof report.orders[number]) => React.ReactNode;
  }) => ({
    ...data,
    key: data.name,
    dataIndex: index,
    className: classnames(`col-${data.name}`, className),
  });

  return (
    <div className="full-page-content tender-report-orders">
      <h3>{capitalize(tender.ordername.order)}s</h3>
      <GenericTable
        columns={[
          column({
            name: 'product',
            title: 'Product',
            render: o => o.product.label,
            className: 'shaded-right',
          }),
          column({
            name: 'participants',
            title: 'Participants',
            index: 'participants',
          }),
          column({
            name: 'counters',
            title: capitalize(tender.ordername.counter) + 's',
            index: 'counters',
          }),
          column({
            name: 'extensions',
            title: `${capitalize(tender.ordername.counter)} extensions`,
            render: o => o.extensions.count,
          }),
          column({
            name: 'ext-amount',
            title: 'Ext. value',
            render: o => (
              <Data.PriceTicker title="" value={o.extensions.value} />
            ),
          }),
          column({
            name: 'max-price',
            title: 'Best price',
            render: o => <Data.PriceTicker title="" value={o.price} />,
          }),
          column({
            name: 'trades',
            title: 'Trades',
            render: o => o.trades.count,
          }),
          column({
            name: 'value',
            title: "Trades' value",
            render: o => <Data.PriceTicker title="" value={o.trades.value} />,
          }),
        ]}
        data={report.orders}
        rowKey="id"
      />
    </div>
  );
};

type IChartSection = Record<'title' | 'className', string> &
  Record<'children', React.ReactNode>;
const ChartSection = ({ title, children, className }: IChartSection) => (
  <div className="chart-container tender-bid-price">
    <div className={classnames('tender-chart', className)}>
      <h3>{title}</h3>
      {children}
    </div>
  </div>
);

interface IParticipantFilter {
  search: { state: string; setState(s: string): void };
  viewers: { state: boolean; setState(s: boolean): void };
}
const ParticipantFilters = ({ search, viewers }: IParticipantFilter) => {
  const [internal, setInternal] = useState(search.state);

  return (
    <section className="orders-table-filter-section">
      <div className="search-fieldset w-240">
        <div className="nui-fieldset">
          <label htmlFor="filter-search" className="offset">
            Search
          </label>
          <div className="input-holder">
            <Input
              id="filter-search"
              type="search"
              placeholder="Search"
              value={internal}
              onChange={e => {
                const value = e?.target.value || '';
                setInternal(value);
                search.setState(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="nui-field-holder show-viewers">
        <label htmlFor="filter-viewers">Show absentees </label>
        <Switch
          loading={false}
          id="filter-viewers"
          checked={viewers.state}
          onChange={(checked: boolean) => void viewers.setState(checked)}
        />
      </div>
    </section>
  );
};
