import React, { useState } from 'react';
import { useCustomerRequest } from './EditContext';
import { Alert, Loading, Button } from 'Components/nui';
import { toast } from 'react-toastify';
import { capitalize } from '~/utils';
import R from 'ramda';
import { useStoreState } from '~/store';
import {
  StackedInfo,
  LabelledInfo,
  ListedData,
  ListedEntry,
  RoutedSummary,
} from 'Components/nui/Wizard';
import FNumber from 'Components/FNumber';
import { FormStatus } from '../Components';
import { Link } from 'react-router-dom';

export default () => {
  const { customer, loading } = useCustomerRequest();

  if (loading) return <Loading size="medium" />;

  return (
    <div className="summary-details">
      {!customer.id ? (
        <Alert hasicon>
          <p className="smaller">
            A summary of your customer request will appear here. You can save
            and exit at any step. Once complete, you can submit the form to Nui
            for processing.
          </p>
        </Alert>
      ) : (
        <>
          <FormDetails />
          <hr className="m-0" />
          <CompanyDetails />
          <AddressDetails />
          <LinksDetails />
          <LocationsDetails />
          <UsersDetails />
        </>
      )}
    </div>
  );
};

const FormDetails = () => {
  const { customer } = useCustomerRequest();

  const { formstatus = '' } = customer;
  const showCom = !['incomplete', 'cancelled'].includes(formstatus);

  return (
    <LabelledInfo
      data={[
        ['Form status', <FormStatus customer={customer} />],
        ['Your comments', showCom && (customer.comment || 'No comments')],
        ['Nui feedback', showCom && (customer.feedback || 'No feedback')],
      ]}
    />
  );
};

const CompanyDetails = () => {
  const { customer } = useCustomerRequest();
  const { company } = customer;

  if (company)
    return (
      <RoutedSummary stepId="company" title="Customer details">
        <LabelledInfo
          data={[
            ['Company name', company.name],
            ['Company no', company.companyno],
            ['Currency', company.currency],
            ['Email', company.email],
            ['Phone', company.workphone],
            ['Mobile', company.mobilephone],
          ]}
        />
      </RoutedSummary>
    );

  return null;
};

const PhyAddressDetails = () => {
  const { customer } = useCustomerRequest();
  const { address } = customer;

  if (address) {
    const data = R.filter(x => !!x)([
      address.phyaddr1,
      address.phyaddr2,
      address.phycity,
      address.phystate,
      address.phyzip,
      address.phycountry?.name,
    ]);

    if (data.length)
      return <StackedInfo title="Physical address" data={data} />;
  }
  return null;
};

const PostAddressDetails = () => {
  const { customer } = useCustomerRequest();
  const { address } = customer;
  const sameasphy =
    address.postaddr1 === address.phyaddr1 &&
    address.postaddr2 === address.phyaddr2 &&
    address.postcity === address.phycity &&
    address.poststate === address.phystate &&
    address.postzip === address.phyzip &&
    address.postcountry?.name === address.phycountry?.name;

  if (address) {
    const data = sameasphy
      ? ['Same as physical address']
      : R.filter(
          x => !!x,
          [
            address.postaddr1,
            address.postaddr2,
            address.postcity,
            address.poststate,
            address.postzip,
            address.postcountry?.name,
          ]
        );

    if (data.length) return <StackedInfo title="Postal address" data={data} />;
  }

  return null;
};

const AddressDetails = () => {
  const { customer } = useCustomerRequest();
  const { address } = customer;

  if (address)
    return (
      <RoutedSummary stepId="address" title="Address details">
        <PhyAddressDetails />
        <PostAddressDetails />
      </RoutedSummary>
    );

  return null;
};

const sortByPath = (path: string[]) => (a: any, b: any) =>
  R.pathOr('', path, a)?.localeCompare(R.pathOr('', path, b));

interface IDynamicEntry {
  id: string;
  title: string;
  data: [string, React.ReactNode][];
  model: 'links' | 'locations' | 'users';
  name: string;
}
const DynamicEntry = ({ id, title, data, model, name }: IDynamicEntry) => {
  const [loading, setLoading] = useState(false);
  const { actions, customer } = useCustomerRequest();

  const onDelete = async () => {
    setLoading(true);
    try {
      await actions.remove(model, id);
      toast(`${capitalize(name)} removed successfully`, {
        type: 'success',
      });
    } catch (error) {
      setLoading(false);
      toast(`There was an error while removing this ${name}.`, {
        type: 'error',
      });
    }
  };

  return (
    <ListedEntry
      title={
        <>
          {title}
          <span className="edit-delete">
            <Link
              to={`/customer-requests/${customer.id}/${model}/${id}`}
              title="Edit details"
            >
              Edit
            </Link>
            |
            <Button
              type="buttonlink"
              onClick={onDelete}
              loading={loading}
              disabled={loading}
              title="Delete entry"
            >
              Delete
            </Button>
          </span>
        </>
      }
      data={data}
    />
  );
};

const LinksDetails = () => {
  const currency = useStoreState(state => state.auth.solution.currency);
  const { customer } = useCustomerRequest();

  if (customer.links?.length)
    return (
      <RoutedSummary stepId="links" title="Linked divisions">
        <ListedData>
          {customer.links.sort(sortByPath(['division', 'name'])).map(link => (
            <DynamicEntry
              key={link.id}
              id={link.id}
              title={link.division.name}
              name="division link"
              model="links"
              data={[
                [
                  `Credit limit (${currency})`,
                  link.nocreditlimit ? (
                    'No limit'
                  ) : (
                    <FNumber value={link.creditamount} />
                  ),
                ],
                ['Limit period', link.creditperiod?.name],
                ['Payment term', link.paymentterm?.name],
              ]}
            />
          ))}
        </ListedData>
      </RoutedSummary>
    );

  return null;
};

const LocationsDetails = () => {
  const { customer } = useCustomerRequest();

  if (customer.locations?.length)
    return (
      <RoutedSummary stepId="locations" title="Locations">
        <ListedData>
          {customer.locations.sort(sortByPath(['description'])).map(loc => (
            <DynamicEntry
              key={loc.id}
              id={loc.id}
              title={loc.description}
              name="location"
              model="locations"
              data={[
                ['Default location', loc.isdefault ? 'Yes' : null],
                ['Port', loc.port?.name],
                ['Country', loc.country?.name],
              ]}
            />
          ))}
        </ListedData>
      </RoutedSummary>
    );

  return null;
};

const UsersDetails = () => {
  const { customer } = useCustomerRequest();

  if (customer.users?.length)
    return (
      <RoutedSummary stepId="users" title="People">
        <ListedData>
          {customer.users.sort(sortByPath(['firstname'])).map(user => (
            <DynamicEntry
              key={user.id}
              id={user.id}
              title={`${user.firstname} ${user.lastname}`}
              name="user"
              model="users"
              data={[
                ['Email', <a href={`mailto:${user.email}`}>{user.email}</a>],
                ['Role', capitalize(user.role)],
              ]}
            />
          ))}
        </ListedData>
      </RoutedSummary>
    );

  return null;
};
