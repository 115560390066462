import React, { useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import classNames from 'classnames';
import { Button } from 'Components/nui';
import { useStoreState } from 'easy-peasy';
import R from 'ramda';

const DropdownPanel = ({ toggle }) => {
  const solution = useStoreState(state => state.auth.solution);
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);

  const canCounter =
    R.pathOr(true, ['counterbids'], solutionSettings) ||
    R.pathOr(true, ['counteroffers'], solutionSettings);

  const canPrivate =
    R.pathOr(true, ['privatebids'], solutionSettings) ||
    R.pathOr(true, ['privateoffers'], solutionSettings);

  return (
    <div className="panel mpkey-panel">
      <h2>
        <em className="icon-help inline-block">&#65279;</em> What&apos;s what
      </h2>
      <p>A visual guide to help you navigate around</p>

      <ul>
        <li>
          <span className="mp-help-icon has-qa">Q&amp;A</span>
          Includes questions from users
        </li>
        <li>
          <em className="mp-help-icon icon-comment has-comment"></em>
          Includes additional comments
        </li>
        {R.path(['attachments', 'type'], solutionColumns) === 'file' && (
          <li>
            <em className="mp-help-icon icon-attach-circled is-bundle"></em>
            This order has attachments
          </li>
        )}
        {canCounter && (
          <>
            <li>
              <span className="mp-help-icon has-counters">2</span>
              There are counter orders
            </li>
            <li>
              <span className="mp-help-icon has-counters">
                <span className="my-counter"></span>2
              </span>
              You placed your counter order
            </li>
          </>
        )}
        <li>
          <span className="mp-help-icon my-offer-bid">
            <span></span>
          </span>
          Indicates your own order
        </li>
        <li>
          <span className="mp-help-icon broker-offer-bid">
            <span></span>
          </span>
          Indicates a broker order
        </li>
        {canPrivate && (
          <>
            <li>
              <span className="mp-help-icon private">p</span>
              Private offer/bid
            </li>
            <li>
              <span className="mp-help-icon private">pg</span>
              Private group offer/bid
            </li>
            {/* <li className="mt-15">
              <span className="mp-help-icon best-counter">10</span>
              Counter offer/bid placed
            </li>
            <li>
              <span className="mp-help-icon best-counter my">10</span>
              Your leading counter offer/bid
            </li> */}
          </>
        )}
      </ul>
      <Button
        htmlType="button"
        onClick={toggle}
        className="nui-button nui-small nui-secondary"
      >
        Close
      </Button>
    </div>
  );
};

export default () => {
  const ref = useRef(null);
  const [opened, toggle] = useToggle(false);

  useClickAway(ref, () => {
    toggle(false);
  });

  return (
    <>
      <Button
        type="primary"
        onClick={toggle}
        className="icon-help show-mpkey-panel"
      >
        &#65279;
      </Button>
      <div
        ref={ref}
        className={classNames('dd', {
          opened,
        })}
      >
        {opened && <DropdownPanel toggle={toggle} />}
      </div>
    </>
  );
};
