import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { capitalize } from '~/utils';
import { useStoreActions, useStoreState } from '~/store';
import Button from '~/components/nui/Button';
import Modal from '~/components/nui/Modal';
import { WithId } from '../../types';
import { getStatus } from '~/store/bannerModel/utils';

interface IAction {
  action: 'edit' | 'delete' | 'reactivate';
  onTrigger(): void;
  confirmation?: (close: () => void) => React.ReactNode;
}
function Action({ action, onTrigger, confirmation }: IAction) {
  const [isConfirming, setIsConfirming] = useState(false);
  function close() {
    setIsConfirming(false);
  }

  return (
    <>
      <Button
        type="buttonlink"
        className={`banner-action banner-action-${action}`}
        onClick={() => {
          if (confirmation) setIsConfirming(true);
          else onTrigger();
        }}
      >
        <FormattedMessage
          id={`banner-action-${action}`}
          defaultMessage={capitalize(action)}
          description={`Banner action text for ${action}`}
        />
      </Button>
      {confirmation && isConfirming && (
        <Modal close={close} size="small">
          {confirmation(close)}
        </Modal>
      )}
    </>
  );
}

function ActionDivider() {
  return <div className="banner-action-divider">|</div>;
}

export default function Actions({ id }: WithId) {
  const item = useStoreState(state => state.banners.items.byId(id));
  const editItem = useStoreActions(actions => actions.banners.editItem);
  const remove = useStoreActions(actions => actions.banners.apiRemove);

  if (!item) return null;

  const status = getStatus(item);

  return (
    <div className="banner-action-bar">
      <div className="banner-actions">
        {status === 'expired' ? (
          <Action
            action="reactivate"
            onTrigger={() => {
              editItem(item);
            }}
          />
        ) : (
          <Action
            action="edit"
            onTrigger={() => {
              editItem(item);
            }}
          />
        )}
        <ActionDivider />
        <Action
          action="delete"
          onTrigger={() => {
            remove(item);
          }}
          confirmation={close => (
            <>
              <h2 className="banner-action-confirm-title-span">
                <FormattedMessage
                  id="banner-action-delete-confirm-title"
                  defaultMessage="Attention"
                  description="Banner action confirm text for delete"
                />
              </h2>
              <span className="banner-action-warning">
                <FormattedMessage
                  id="banner-action-message-delete"
                  defaultMessage="Are you sure you want to delete banner <s>{banner}</s>?"
                  values={{
                    banner: item.subject,
                    s: chunks => (
                      <span>
                        <strong className="all-black">{chunks}</strong>
                      </span>
                    ),
                  }}
                  description="Message for user to confirm deletion of Banner"
                />
              </span>
              <br />
              This can&apos;t be undone.
              <hr />
              <div className="button-set">
                <Button
                  type="primary"
                  className="banner-action banner-action-delete-confirm"
                  onClick={() => {
                    remove(item);
                    close();
                  }}
                >
                  <FormattedMessage
                    id="banner-action-delete"
                    defaultMessage="Confirm"
                    description="Banner action text for delete confirmation"
                  />
                </Button>
                <Button
                  type="simple"
                  className="banner-action banner-action-delete-cancel"
                  onClick={() => close()}
                >
                  <FormattedMessage
                    id="banner-action-delete"
                    defaultMessage="Cancel"
                    description="Banner action text for delete confirmation"
                  />
                </Button>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
}
