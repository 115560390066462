import type { IRouteDefns, IRouteName, IRouteProps } from 'router';
import { generatePath, matchPath, useLocation } from 'react-router-dom';

const subPath = ':subPath(.*)*';
const UUID =
  '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';
const nameId = (name: string) => `:${name}(${UUID})`;

const tenderId = nameId('tenderId');
const orderId = nameId('orderId');

const routes: IRouteDefns = {
  /****** Tenders ******/
  'tenders': {
    route: '/tenders',
    paths: ['/tenders'],
  },
  'tender-overview': { route: '/tenders' },
  'tender-history': {
    route: '/tenders/history',
  },
  'tender-analytics': {
    route: '/tenders/analytics',
  },

  /****** View tender ******/
  'tender-view': { route: `/tenders/${tenderId}` },
  'tender-trades': { route: `/tenders/${tenderId}/trades` },
  'tender-report': { route: `/tenders/${tenderId}/report` },

  /****** Tender orders ******/
  'tender-orders': { route: `/tenders/${tenderId}/orders` },
  'tender-order-view': { route: `/tenders/${tenderId}/orders/${orderId}` },
  'tender-order-details': {
    route: `/tenders/${tenderId}/orders/${orderId}/details`,
  },
  'tender-order-history': {
    route: `/tenders/${tenderId}/orders/${orderId}/history`,
  },
  'tender-order-buyers': {
    route: `/tenders/${tenderId}/orders/${orderId}/prices`,
  },

  /****** Tender counter orders ******/
  'tender-counter': {
    route: `/tenders/${tenderId}/orders/${orderId}/my-counter`,
  },
  'tender-counter-orders': {
    route: `/tenders/${tenderId}/orders/${orderId}/counters`,
  },
  'tender-counter-history': {
    route: `/tenders/${tenderId}/orders/${orderId}/counter-history`,
  },

  /****** Add/edit tenders ******/
  'tender-new': {
    route: '/tenders/new',
    paths: [`/tenders/:mode(new|edit)/${tenderId}?`],
  },
  'tender-edit-details': { route: `/tenders/:mode(new|edit)/${tenderId}?` },
  'tender-edit-orders': {
    route: `/tenders/:mode(new|edit)/${tenderId}/orders/${orderId}?`,
  },
  'tender-edit-participants': {
    route: `/tenders/:mode(new|edit)/${tenderId}/participants`,
  },
  'tender-edit-review': {
    route: `/tenders/:mode(new|edit)/${tenderId}/review`,
  },

  'tender-duplicate-order': {
    route: `/duplicate/tender/${tenderId}/order/${orderId}`,
  },
};

export function routeUrl<N extends IRouteName>(
  name: N,
  params: IRouteProps<N> = {}
) {
  return generatePath(routes[name].route, params);
}

export function matchUrl<N extends IRouteName>(
  name: N,
  params?: IRouteProps<N>
) {
  const defn = routes[name];
  const paths = defn.paths || [defn.route];

  if (params) return paths.map(p => generatePath(p, { ...params, subPath }));
  return paths;
}

export function matchMany(...names: IRouteName[]) {
  return names.flatMap(n => matchUrl(n));
}

export function pathActive(path: IRouteName, url: string, exact = false) {
  return !!matchPath(url, { path: matchUrl(path), exact });
}

export function matchParams<N extends IRouteName>(
  path: N,
  url: string,
  exact = false
): Partial<IRouteProps<N>> {
  return matchPath(url, { path: matchUrl(path), exact })?.params || {};
}

export function usePathTools() {
  const { pathname } = useLocation();

  return {
    pathActive(path: IRouteName, exact = false) {
      return pathActive(path, pathname, exact);
    },

    matchParams<N extends IRouteName>(path: N, exact = false) {
      return matchParams(path, pathname, exact);
    },
  };
}
