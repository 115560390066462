import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import Edit from './Edit';
import View from './View';

export default () => {
  return (
    <Switch>
      <Route path="/customer-requests/:id/:action?">
        <Edit />
      </Route>
      <Route path="/customer-requests">
        <View />
      </Route>
      <Route>
        <strong>Error 404</strong>
      </Route>
    </Switch>
  );
};
