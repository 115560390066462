import React from 'react';
import { withRoute, withPreload } from '~/router';
import * as services from '~/services/tenders';
import { TenderItem } from '../View';
import { NoData } from 'Components/InfoMessage';
import Pagination from 'Components/nui/Pagination';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

export default withRoute({
  name: 'tender-history',
  head: { title: 'Tender History' },
})(() => {
  return (
    <>
      <HistoryList />
      <HistoryFooter />
    </>
  );
});

const HistoryList = withPreload({
  route: 'tender-history',
  preload: services.history.observe(),
})(({ data: tenders }) => {
  if (!tenders.length)
    return (
      <NoData className="mt-10 mb--11 p-100 b-0">
        There are currently no historical tenders
      </NoData>
    );

  return (
    <div className="tender-list">
      {tenders.map(tender => (
        <TenderItem key={tender.id} tender={tender} />
      ))}
    </div>
  );
});

const HistoryFooter = withPreload({
  route: 'tender-history',
  preload: services.history.controls(),
})(({ data: { loading, total, page, limit } }) => {
  const index = (page - 1) * limit;
  const start = index + 1;
  const end = Math.min(index + limit, total);

  const history = useHistory();
  const redirect = (params: any) => {
    history.push({
      search: qs.stringify({
        ...qs.parse(history.location.search.slice(1)),
        ...params,
      }),
    });
  };

  return (
    <div className="tender-history-footer pb-7">
      <div className="inline-block">
        <Pagination
          loading={loading}
          pageSizeOptions={['10', '25', '50']}
          pagination={{
            total,
            page,
            limit,
            setPage: n => void redirect({ page: n }),
            setLimit: n => void redirect({ limit: n }),
          }}
        />
      </div>
      {!loading && total > 0 && (
        <span className="pagination-total inline-block m-5">
          Showing {start}&mdash;{end} of {total} results
        </span>
      )}
    </div>
  );
});
