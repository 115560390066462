import React from 'react';
import { Button } from 'Components/nui';
import { ClassicResult } from 'Components/Result';
import moment from 'moment-timezone';
import R from 'ramda';
import { getPrecision } from '~/utils';

export const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 2 ** 53 - 1;
export const MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER || (-2) ** 53 - 1;

// For DatePicker disabledDate, only supprots fromDate for now.
export const getDisabledDate = fromDate => date =>
  fromDate &&
  date &&
  date.clone().endOf('day').isBefore(fromDate.clone().startOf('day'));

// For DatePicker disabledTime, only supports fromDate for now.
export const getDisabledTime = fromDate => date => {
  if (!(date && fromDate && date.isSame(fromDate, 'day'))) return {};
  return {
    // In the same day, disable hours that are before the fromDate hour
    disabledHours: () => R.range(0, 24).filter(R.gt(fromDate.hour())),
    // In the same hour as fromDate, disable minutes that are before the fromDate minute
    disabledMinutes: hour => {
      if (hour < fromDate.hour()) {
        return R.range(0, 60);
      } else if (hour === fromDate.hour())
        return R.range(0, 60).filter(R.gt(fromDate.minute()));
      return [];
    },
  };
};

export function getDisabledRangeTime(fromDateTime) {
  return (ref, type) => {
    const [startDate, endDate] =
      ref && ref.length ? ref : [undefined, undefined];
    switch (type) {
      case 'start':
        return getDisabledTime(fromDateTime)(startDate);
      case 'end':
        return getDisabledTime(startDate)(endDate);
      default:
        return {};
    }
  };
}

export const roundMinute = (time, round) => {
  return time
    .clone()
    .startOf('hour')
    .minute(Math.ceil(time.minute() / round) * round);
};

export function nextPeriods(now, n, period, format) {
  return R.range(1, n + 1).map(i => {
    const x = now.clone().add(i, period);
    return {
      [x.format(format)]: [x.clone().startOf(period), x.clone().endOf(period)],
    };
  });
}

export function getRanges(n) {
  const now = moment();
  return {
    'This week': [now, now.clone().endOf('week')],
    ...R.mergeAll(nextPeriods(now, n, 'week', '\\W\\e\\e\\kW YYYY')),
    'This month': [now, now.clone().endOf('month')],
    ...R.mergeAll(nextPeriods(now, n, 'month', 'MMMM YYYY')),
    'This quarter': [now, now.clone().endOf('quarter')],
    ...R.mergeAll(nextPeriods(now, n, 'quarter', '\\QQ YYYY')),
  };
}

export function toBoolean(v) {
  const s = String(v).toLowerCase();
  if (R.includes(s, ['1', 'true', 'on', 'yes'])) return true;
  if (R.includes(s, ['0', 'false', 'off', 'no'])) return false;
}

/**
 * Merge fields spec with schema returned from API, by using spec.name as lookup key in schema object,
 * value of schema is like { type, required, type } which is used by Field to render
 * @param {*} schema
 * @param {*} fields
 */
export function mergeSchema(schema, fields) {
  return (
    fields
      // Only process field having either render() or name, or `psuedo` for
      // fields that mirror another field(s)
      .filter(
        field => field.render || field.psuedo || R.has(field.name, schema)
      )
      .map(field => {
        if (field.skip) return field;
        const { name, render, choices, type, required, help, label, ...rest } =
          field;
        // XXX When using mergeSchema, name should be string actually
        const fieldSchema = schema[String(name)] || {};
        return {
          name,
          render,
          label: fieldSchema.label || label,
          // Merge choices, type and required of field and fieldSchema.
          // For choices, type and required values in field, if callable,
          //  call them on provided schema to get the transformed spec out of schema.
          ...R.mergeAll(
            R.toPairs({ choices, type, required, help }).map(([k, v]) => ({
              [k]:
                typeof v === 'function' ? v(fieldSchema) : v || fieldSchema[k],
            }))
          ),
          ...rest,
        };
      })
  );
}

// Ramda is used in following functions to avoid possible failure caused by invalid input.

// Check the type grouped choices or normal choices, depends on whether the type of value in choices is Array
export const isGrouped = R.pathSatisfies(Array.isArray, [0, 'value']);

// Convert [[key, label]] into the form of [{key, value, label}]
export function normalizeChoices(input, sort = true) {
  const choices = R.has('choices', input) ? input.choices : input;
  if (R.pathSatisfies(Array.isArray, [0], choices)) {
    return R.map(
      choice => ({
        key: R.prop(0, choice),
        value: R.prop(0, choice),
        label: R.prop(1, choice),
      }),
      sort ? R.sortBy(R.prop(1))(choices) : choices
    );
  }
  return choices;
}

export function normalizeChoicesNS(input) {
  const choices = R.has('choices', input) ? input.choices : input;
  if (R.pathSatisfies(Array.isArray, [0], choices)) {
    return choices.map(choice => ({
      key: R.prop(0, choice),
      value: R.prop(0, choice) + '',
      label: R.prop(1, choice),
    }));
  }
  return choices;
}

/**
 * Support
 * - [{ key, label, value: [{ key, label, value }]}]
 * No support, because we simply apply R.flatten in flattenChoices
 * - [{ key, label, value: [[key, label]]]
 */
export function flattenChoices(choices) {
  return isGrouped(choices)
    ? R.flatten(R.map(R.prop('value'), choices))
    : choices;
}

export function hasDateRange(v) {
  return Array.isArray(v) && v[0] && v[1];
}

export const normalizeDateTime = (cur, fromDt, toDt, interval) => {
  if (R.isNil(cur)) return;
  let datetime = moment(cur);

  // TODO handle erroneous inputs logic in the actual widget.

  // TODO assert datetime >= fromDt. If it isn't, set to fromDt. Is this the best
  // logic from a UX/UI and development perspective? Maybe just display an error?
  // -- Same for toDt.
  if (fromDt && datetime.isBefore(fromDt)) datetime = fromDt.clone();
  if (toDt && datetime.isAfter(fromDt)) datetime = toDt.clone();

  // Support restricting minutes to intervals
  if (interval) datetime = roundMinute(datetime, interval);

  return datetime.isValid() ? datetime : undefined;
};

export function roundToSamePrecision(v, precisionValue) {
  return Number(v.toFixed(getPrecision(precisionValue)));
}

export function getCallableName(fn) {
  if (typeof fn === 'function') {
    const match = /^(?:function|class)\s+\b(\w+)\b/.exec(fn + '');
    return match ? match[1] : '';
  }
  return '';
}

export const submitSuccess = (result, submitState) => (
  <ClassicResult title="Success">
    <p>{R.prop('message', result)}</p>
  </ClassicResult>
);

export const submitError = (result, submitState) => (
  <ClassicResult success={false} title="Submit failed">
    <p>Something went wrong!</p>
    <Button
      className="mt-10 mb-10"
      type="primary"
      onClick={() => {
        // Clear result page
        submitState.setResult();
      }}
    >
      Try edit again
    </Button>
  </ClassicResult>
);

export const strBoolIsTruthy = (form, schema, field) => {
  return R.isNil(form.getFieldValue(field))
    ? R.pathOr(false, [field, 'value'], schema)
    : form.getFieldValue(field) === 'true';
};
