import type Struct from 'struct';
import type { Tender, Order } from '~/models/tenders';
import React, { useMemo } from 'react';
import { routeUrl, withRouteX, withPreload } from '~/router';
import { GenericTable } from 'Components/nui/Table';
import * as Data from 'Components/Data';
import { Link } from 'react-router-dom';
import { capitalize } from '~/utils';
import { useMountedState } from '~/hooks';
import { Button, Modal, Tooltip } from 'Components/nui';
import { toast } from 'react-toastify';
import * as services from '~/services/tenders';

type TenderProp = Record<'tender', Tender>;
type OrderProp = Record<'order', Order>;
type AllOrdersProp = Record<'orders', Order[]>;

const Trades = withPreload({
  route: 'tender-trades',
  preload: services.observe.orders(),
})(({ data: { tender, orders } }) => {
  return (
    <div className="full-page-content">
      <div className="tender2-trades-header">
        <hr className="m-0 mb-20" />
        <h2>Review trades and finalise</h2>
      </div>
      <div className="tender-trades-summary">
        <TenderSummary tender={tender} orders={orders} />
        {tender.status !== 'archived' && <FinaliseTender tender={tender} />}
      </div>
      <TradesTable tender={tender} orders={orders} />
    </div>
  );
});
export default withRouteX({ name: 'tender-trades' })(Trades);

const TenderSummary = ({ tender, orders }: TenderProp & AllOrdersProp) => {
  const totalVal = useMemo(
    () => orders.reduce((a, b) => b.trades.value.add(a), {} as Struct.Price),
    [orders]
  );

  const indexOrdersWithTBDValue = orders.filter(
    order =>
      order.trades?.value.val === 0 && order?.index && order.trades.length
  );

  return (
    <div className="tender2-summary">
      <h3>Tender summary</h3>
      <Data.List>
        <Data.Item title="Name">{tender.name}</Data.Item>
        <Data.Item title="Total tender value">
          <Data.PriceTicker title="" value={totalVal} />
          {indexOrdersWithTBDValue.length > 0 && (
            <span className="trade-tbd">
              +{' '}
              <span className="tbd-value">
                {indexOrdersWithTBDValue.length}
              </span>{' '}
              Trades{' '}
              <Tooltip
                placement="bottom"
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled" />
                    <strong className="all-black block">
                      To be determined
                    </strong>
                    <span>Index-based pricing date has not been reached.</span>
                  </span>
                }
              >
                <span className="tbd">TBD</span>{' '}
                <span className="show-help inline-block" />
              </Tooltip>
            </span>
          )}
        </Data.Item>
      </Data.List>
    </div>
  );
};

const FinaliseTender = ({ tender }: TenderProp) => {
  const [isOpen, setIsOpen] = useMountedState(false);
  const [loading, setLoading] = useMountedState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  async function onFinalise() {
    setLoading(true);
    const response = await services.actions.finalise(tender.id);
    if (response?.success) {
      toast.success(tender.name + ' has been finalised');
    } else if (response?.validate) {
      toast.error(capitalize(response.validate));
    } else if (response?.errors) {
      for (const e of response.errors) toast.error(e);
    }
    setLoading(false);
    close();
  }

  return (
    <div className="finaliseme">
      <Button onClick={open}>Finalise tender</Button>
      {isOpen && (
        <Modal size="small" close={close}>
          <h2>Confirm</h2>
          <p>
            Once <strong className="all-black">{tender.name}</strong> is
            finalised, participants will be notified and you will not be able to
            complete any further trades.
          </p>
          <hr className="mt-15" />
          <div className="button-set">
            <Button loading={loading} disabled={loading} onClick={onFinalise}>
              Confirm
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              type="simple"
              onClick={close}
            >
              Close
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

const ViewOrder = ({ tender, order }: TenderProp & OrderProp) => (
  <Link
    to={routeUrl('tender-counter-orders', {
      tenderId: tender.id,
      orderId: order.id,
    })}
  >
    View {order.ordertype}
  </Link>
);

export const TradesTable = ({ tender, orders }: TenderProp & AllOrdersProp) => {
  return (
    <GenericTable
      data={orders.sort((a, b) => a.created.valueOf() - b.created.valueOf())}
      rowKey={o => o.id}
      columns={[
        {
          name: 'view-order',
          className: 'col-product shaded-right',
          title: 'Product',
          render: (_, order) => (
            <>
              <Data.ProductLink product={order.product} />
              <span className="small">
                <ViewOrder tender={tender} order={order} />
              </span>
            </>
          ),
        },
        {
          name: 'num-counters',
          title: capitalize(tender.ordername.counter) + 's',
          render: (_, order) => order.counters.length,
        },
        {
          name: 'num-trades',
          title: 'Trades',
          render: (_, order) =>
            order.counters.length ? order.trades.length : 'N/A',
        },
        {
          name: 'total-volume',
          title: 'Total volume',
          render: (_, order) =>
            order.trades.length ? (
              <Data.VolumeTicker value={order.trades.volume} title="" />
            ) : (
              'No trades'
            ),
        },
        {
          name: 'total-value',
          title: 'Total value',
          render: (_, order) =>
            order.trades.length ? (
              order?.index ? (
                <Tooltip
                  placement="bottom"
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled" />
                      <strong className="all-black block">
                        To be determined
                      </strong>
                      <span>
                        Index-based pricing date has not been reached.
                      </span>
                    </span>
                  }
                >
                  <span className="tbd all-black bold">TBD</span>{' '}
                  <span className="show-help inline-block" />
                </Tooltip>
              ) : (
                <Data.PriceTicker value={order.trades.value} title="" />
              )
            ) : (
              'N/A'
            ),
        },
        {
          name: 'wap',
          title: 'Weighted average price',
          render: (_, order) =>
            order.trades.length ? (
              <Data.PriceTicker value={order.trades.wap} title="" />
            ) : (
              'N/A'
            ),
        },
      ]}
    />
  );
};
