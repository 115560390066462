import React from 'react';
import classnames from 'classnames';
import R from 'ramda';

const types = {
  default: 'nui-button',
  primary: 'nui-primary',
  secondary: 'nui-secondary',
  reverse: 'nui-reverse',
  simple: 'nui-simple',
  buttonlink: 'nui-button-link',
  warning: 'nui-warning',
  error: 'nui-error',
  bid: 'nui-bid',
  offer: 'nui-offer',
};

const sizes = {
  default: '',
  small: 'nui-small',
  large: 'nui-large',
};

const shapes = {
  default: '',
  rounded: 'nui-rounded',
  beveled: 'nui-beveled',
};

export default React.forwardRef((props, ref) => {
  const {
    htmlType,
    children,
    size,
    type,
    shape,
    disabled,
    loading,
    onClick,
    className,
    expanded = true,
    icon = false,
    ...rest
  } = props;

  return (
    <button
      ref={ref}
      type={htmlType}
      className={classnames(
        'nui-button',
        R.pathOr(types.default, [type], types),
        R.pathOr(sizes.default, [size], sizes),
        R.pathOr(shapes.default, [shape], shapes),
        { 'nui-button-icon': icon, expand: type === 'expand' },
        className
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {type === 'expand' && (
        <span className={classnames('expand-icon', { expanded })} />
      )}
      {loading && <span className="icon-spin6 animate-spin ml--5 mr-5"></span>}
      {icon && !loading && <span className={`icon-${icon}`} />}
      {children}
    </button>
  );
});
