import React, { useContext, useEffect, useState } from 'react';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'util';
import { Form, Button, Loading } from 'Components/nui';
import { api } from '~/api';
import R from 'ramda';
import { ModalContext } from 'Components/Layout';
import { nuiFormatNumber } from '~/utils';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStoreState } from '~/store';

function useFormWrapper({ form, user, schema }) {
  const normalize = x =>
    R.map(([value, label]) => ({ value, label, key: value }))(x.choices || []);

  const fields = [
    {
      name: 'firstname',
      label: 'First name',
      type: 'Input',
    },
    {
      name: 'lastname',
      label: 'Last name',
      type: 'Input',
    },

    {
      render: () => (
        <>
          <h3 className="numbered outside nowrap">Sign in protocol</h3>
          This option allows you to sign in on your trusted browser(s). See{' '}
          <Link to="/account/security">security settings</Link> for your list of
          trusted browsers.
        </>
      ),
    },

    {
      name: 'autoemailsignin',
      label: 'Auto sign in from emails',
      type: 'Checkbox',
    },
    {
      name: 'autologout',
      label: 'Auto sign out',
      required: true,
      type: 'RadioSelect',
      choices: [
        {
          key: 'false',
          label: 'Never',
          value: false,
        },
        {
          key: 'true',
          label: 'After 1 hour',
          value: true,
        },
      ],
    },
    {
      // and another brilliant solution!
      render: () => (
        <>
          <hr className="mt-0" />
          <h3 className="numbered outside nowrap">Number formatting</h3>
          <p className="smaller justify m-0">
            Select a grouping separator (or thousand&apos;s separator) to
            separate the last three digits of a number. Select a decimal
            separator to separate the integral and the fractional parts of a
            decimal numeral. See example below.
          </p>
        </>
      ),
    },
    {
      name: 'numeric_group',
      label: 'Grouping separator',
      type: 'Select',
      className: 'nowrap',
      choices: normalize,
      required: false,
    },
    {
      name: 'numeric_decimal',
      label: 'Decimal separator',
      type: 'Select',
      choices: normalize,
    },
    {
      render: () => {
        const { numeric_group, numeric_decimal } = form.getFieldsValue();
        return (
          <div key="group-decimal-example" className="pb-20">
            Formatting example:{' '}
            <strong className="all-black">
              {nuiFormatNumber(12345678.09, 2, numeric_decimal, numeric_group)}
            </strong>
          </div>
        );
      },
    },
  ];
  return new FormWrapper(form, user, mergeSchema(schema, fields));
}

const BaseProfileForm = ({ form, schema }) => {
  const user = useStoreState(state => state.auth.user);
  const formWrapper = useFormWrapper({ form, user, schema });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const { close } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }

    setLoading(true);

    const data = formWrapper.serialize();

    try {
      await api.putData('/user/profile', data);
      window.location.reload();
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      formWrapper.setErrors(errors);
      setLoading(false);
    }
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit} hideRequiredMark={true}>
        {formWrapper.render()}
        <Form.Item className="button-set">
          <Button htmlType="submit" type="primary" disabled={loading}>
            Update details
          </Button>
          <Button onClick={close} disabled={loading} type="simple">
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const WrappedForm = Form.create()(BaseProfileForm);

export default () => {
  const [schema, setSchema] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    (async () => {
      try {
        const response = await api.getData2('/user/profile/schema');
        if (mounted) {
          setSchema(response.fields);
          setLoading(false);
        }
      } catch (err) {
        toast.error('An error occurred while loading the profile form.');
      }
    })();

    return () => {
      mounted = false;
    };
  }, []);

  if (loading || schema === null) {
    return <Loading size="large" />;
  }

  return <WrappedForm schema={schema} />;
};
