import { Loading } from 'Components/nui';
import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
// import { createStateContext } from 'react-use';
import { useSharedTIData, ApiError, useSharedFilter } from '..';
import Prices from './Prices';
import TopTradeProducts from './TopTradeProducts';
import TotalVolume from './TotalVolume';
import TradeVolume from './TradeVolume';
import R from 'ramda';

export const scale = {
  '1h': {
    tickValues: 'every hour',
    precision: 'hour',
    format: '%H:%M',
    tooltipFormat: 'DD MMMM, HH:mm',
    axisBottomLegend: 'Dates (grouped by an hour)',
  },
  '6h': {
    tickValues: 'every hour',
    precision: 'hour',
    format: '%d %b, %H:%M',
    tooltipFormat: 'DD MMMM, HH:mm',
    axisBottomLegend: 'Dates (grouped by 6 hours)',
  },
  '1d': {
    tickValues: 'every day',
    precision: 'day',
    format: '%d %b',
    tooltipFormat: 'DD MMMM',
    axisBottomLegend: 'Dates (grouped by a day)',
  },
  '1w': {
    tickValues: 'every 7 days',
    precision: 'day',
    format: '%d %b',
    tooltipFormat: 'DD MMMM',
    axisBottomLegend: 'Dates (grouped by a week)',
  },
  '1M': {
    tickValues: 'every month',
    precision: 'month',
    format: '%B',
    tooltipFormat: 'MMMM YYYY',
    axisBottomLegend: 'Dates (grouped by a month)',
  },
};

export default () => {
  const [data] = useSharedTIData();
  const [loading, toggle] = useToggle(true);
  const [filter] = useSharedFilter();

  useEffect(() => {
    if (data) {
      toggle(false);
    }
  }, [data]);

  return data === 'Error' ? (
    <ApiError />
  ) : R.isEmpty(filter) && loading ? (
    <Loading size="large" />
  ) : (
    <>
      <Prices />

      <hr className="mt-30 mb-30 hide-for-xlarge" />
      <TopTradeProducts />

      <hr className="mt-30 mb-30" />
      <TradeVolume />
      <TotalVolume />
    </>
  );
};
