import * as React from 'react';
import Helmet from 'Components/Helmet';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { useScrollToTopOnMount } from '~/hooks';
import { Consolidate } from './Consolidation';
import DeliveriesTable from './DeliveriesTable';

interface IHeader {
  id: string;
  title: string;
  url: string;
  children?: React.ReactNode;
}
const Header = ({ id, title, children }: IHeader) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <div className="full-page-header">
      <h1 className="inline mt-10 mr-20 mb-0">{title}</h1>

      {children}

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active={id} />
        </li>
        <li>{title}</li>
      </ul>
      <hr className="mb-40" />
    </div>
  </>
);

export const Consolidation = () => {
  useScrollToTopOnMount();

  return (
    <>
      <Header id="consolidation" title="Consolidation" url="/consolidation" />

      <Consolidate />
    </>
  );
};

export const Deliveries = () => {
  useScrollToTopOnMount();

  return (
    <>
      <Header id="deliveries" title="Deliveries" url="/deliveries" />
      <div className="full-page-content">
        <DeliveriesTable />
      </div>
    </>
  );
};
