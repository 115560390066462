import type * as Events from 'services.tenders.events';
import type * as Responses from 'endpoints.tenders';
import type { Moment } from 'moment-timezone';
import * as api from '~/fetch';
import * as service from './internal';
import Socket from '~/fetch/SocketX';
import moment from 'moment-timezone';

const { errortype } = service.events;

const BASE_INTERVAL = 5e3 as const;
const MAX_INTERVAL = 16e3 as const; // Every four cycles
const MAX_DELAY = 604800 as const; // ~7 days

class TenderService {
  private id: string;
  private interval!: NodeJS.Timeout;
  private toEvent!: NodeJS.Timeout;
  private socket?: Socket<Responses.BidsEvent>;

  private pollStart?: Moment;
  private pollEnd?: Moment;

  constructor(tender: string) {
    this.id = tender;
  }

  private get polled() {
    return service.storage.getCountersPolled(this.id);
  }

  async load() {
    const self = this;

    try {
      const response = await api.tenders.tender(this.id);
      const polled = moment(response.headers.get('Date') as string);
      await api.utils.processResponse(response, {
        async 200(response) {
          const data = await response.json();
          service.events.tender({ data, polled });
        },
        async default(response) {
          service.events.error({
            type: errortype.tenderload,
            data: { tender: self.id, response },
          });
        },
      });
    } catch (error) {
      service.events.error({
        type: errortype.tenderload,
        data: { tender: self.id, error },
      });
    }
  }

  private async poll() {
    const since = this.polled;
    if (!since) return;
    const self = this;

    this.pollStart = moment.utc();

    try {
      const response = await api.tenders.pollCounters(self.id, since);
      await api.utils.processResponse(response, {
        async 200(response) {
          const data = await response.json();
          const polled = moment(response.headers.get('Date') as string);
          service.events.counters({ tender: self.id, data, polled });
        },
        async 204() {},
        async 304() {
          void 0;
        },
        async default(response) {
          service.events.error({
            type: errortype.tenderpoll,
            data: { tender: self.id, response },
          });
        },
      });
    } catch (error) {
      service.events.error({
        type: service.events.errortype.tenderpoll,
        data: { tender: self.id, error },
      });
    }

    this.pollEnd = moment.utc();
  }

  async refreshCounters() {
    const self = this;

    this.pollStart = moment.utc();

    try {
      const response = await api.tenders.getCounters(self.id);
      await api.utils.processResponse(response, {
        async 200(response) {
          const data = await response.json();
          const polled = moment(response.headers.get('Date') as string);
          service.events.counters({ tender: self.id, data, polled });
        },
        async 204() {},
        async default(response) {
          service.events.error({
            type: errortype.tenderpoll,
            data: { tender: self.id, response },
          });
        },
      });
    } catch (error) {
      service.events.error({
        type: service.events.errortype.tenderpoll,
        data: { tender: self.id, error },
      });
    }

    this.pollEnd = moment.utc();
  }

  private get pollFinished() {
    return (
      !!this.pollStart &&
      !!this.pollEnd &&
      this.pollStart.isBefore(this.pollEnd)
    );
  }

  private get spamming() {
    return this.pollEnd?.isAfter(moment.utc().subtract(2, 'seconds'));
  }

  private get longPoll() {
    return this.pollStart?.isBefore(
      moment.utc().subtract(MAX_INTERVAL, 'milliseconds')
    );
  }

  loop() {
    if (this.polling) return;

    this.interval = setInterval(() => {
      if (!this.pollStart) {
        this.poll();
        return;
      }

      if (!this.pollFinished || this.spamming) return;

      if (!this.open) {
        this.poll();
      } else {
        if (this.longPoll) {
          this.poll();
        }
      }
    }, BASE_INTERVAL);
  }

  startSocket() {
    if (this.socket) return;

    const since = this.polled?.toISOString() || '';
    const self = this;
    const params = { since };

    this.socket = new Socket({ url: `/tender/${this.id}`, params });
    this.socket.onmessage('bids', ({ data }) => {
      service.events.counters({
        tender: self.id,
        data: {
          bids: data.bids.map(({ offer, ...bid }) => ({
            ...bid,
            offer: {
              id: offer.id,
              href: `/tenders/${self.id}/offers/${offer.id}`,
            },
          })),
          offers: data.offers,
        },
      });
    });
  }

  eventIn(seconds: number) {
    clearTimeout(this.toEvent);
    if (seconds < MAX_DELAY)
      this.toEvent = setTimeout(() => void this.load(), seconds * 1e3);
  }

  finish() {
    clearInterval(this.toEvent);
    clearInterval(this.interval);
    this.socket?.finish();

    delete this.toEvent;
    delete this.interval;
  }

  kill() {
    clearInterval(this.toEvent);
    clearInterval(this.interval);
    this.socket?.finish();

    delete this.toEvent;
    delete this.interval;
  }

  get polling() {
    return !!this.interval;
  }

  get open() {
    return !!this.socket?.ready;
  }
}

let connections: Record<string, TenderService> = {};

function onTender({ data }: Events.Tender) {
  const tender = data.tender;
  const { solution } = service.storage.getOverview();

  if (solution === tender.solution.id) {
    if (!connections[tender.id]) {
      connections[tender.id] = new TenderService(tender.id);
      connections[tender.id].load();
    } else if (tender.status === 'active') {
      const connection = connections[tender.id];

      if (tender.started) {
        connection.loop();

        if (!tender.finished) {
          connection.eventIn(tender.seconds_remaining! + 2);
          connection.startSocket();
        } else {
          connection.finish();
        }
      } else {
        connection.eventIn(tender.seconds_to_start! + 2);
      }
    }
  }
}
service.events.onTender(onTender);

service.events.onOverview(({ data }) => {
  for (const tender of data.tenders) onTender({ data: { tender } });
});

service.events.onStarted(({ tender }) => {
  const connection = connections[tender];
  if (connection) connection.eventIn(0);
});

service.events.onExtended(({ tender, seconds_remaining }) => {
  const connection = connections[tender];
  if (connection) connection.eventIn(seconds_remaining);
});

service.events.onFinished(({ tender }) => {
  const connection = connections[tender];
  if (connection) connection.eventIn(0);
});

service.events.onSolution(() => {
  for (const connection of Object.values(connections)) connection.kill();
  connections = {};
});

service.events.onSignout(() => {
  for (const connection of Object.values(connections)) connection.kill();
  connections = {};
});

service.events.onCancelTender(({ tender }) => {
  const connection = connections[tender];
  if (connection) connection.kill();
});

service.events.onPlaceCounter(({ tender }) => {
  const connection = connections[tender];
  if (connection) connection.refreshCounters();
});

export async function load(tender: string) {
  if (!connections[tender]) {
    connections[tender] = new TenderService(tender);
    await connections[tender].load();
  }
}
