import React from 'react';
import { useParams, Link } from 'react-router-dom';
import classnames from 'classnames';
import { areas } from './references';
import R from 'ramda';

const Item = ({ area }) => {
  const params = useParams();

  const active =
    params.area === area || (params.area === undefined && area === 'details');
  const disabled = params.id === undefined && !active;

  return (
    <li className={classnames({ active, disabled })}>
      <h2>
        {active || !params.id ? (
          areas[area].title
        ) : (
          <Link to={`/routes/${params.id}/edit/${area}`}>
            {areas[area].title}
          </Link>
        )}
      </h2>
      <p className="description">{areas[area].description}</p>
    </li>
  );
};

const MobileSteps = () => {
  const params = useParams();

  const area = R.path([params.area], areas);

  if (!area)
    return (
      <div className="mobile-stepper show-for-small-only">
        <a
          className={classnames('icon-angle-left', 'prev', 'disabled')}
          href="#previous"
          title="Previous step"
        >
          <span className="offset">&nbsp;</span>
        </a>
        <span>
          Step <strong>1</strong> / <strong>5</strong>
        </span>
        <a
          className={classnames('icon-angle-right', 'next', 'disabled')}
          href="#next"
          title="Next step"
        >
          <span className="offset">&nbsp;</span>
        </a>
      </div>
    );

  return (
    <div className="mobile-stepper show-for-small-only">
      <Link
        className={classnames('icon-angle-left', 'prev', {
          disabled: area.previous === null,
        })}
        to={`/routes/${params.id}/edit/${area.previous}`}
        title="Previous step"
      >
        <span className="offset">&nbsp;</span>
      </Link>
      <span>
        Step <strong>{area.step}</strong> / <strong>2</strong>
      </span>

      <Link
        className={classnames('icon-angle-right', 'next', {
          disabled: area.next === null,
        })}
        to={`/routes/${params.id}/edit/${area.next}`}
        title="Next step"
      >
        <span className="offset">&nbsp;</span>
      </Link>
    </div>
  );
};

export default () => (
  <>
    <MobileSteps />
    <ul>
      <Item area="details" />
      <Item area="freight" />
    </ul>
  </>
);
