import React from 'react';
// import { getEnv } from '~/utils';
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useStoreState } from '~/store';
import classnames from 'classnames';
import { useSiteTitle } from '~/hooks';
import R from 'ramda';
import Exchange from './Exchange';
import Notifications from './Notifications';

const Fallback = ({ title, route }) => {
  const divisionId = useStoreState(
    R.path(['auth', 'solution', 'roles', 0, 'division', 'id'])
  );

  return (
    <li>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${route}?active_division=${divisionId}`}
      >
        {title}
      </a>
    </li>
  );
};

const Config = ({ title, route }) => {
  const { pathname } = useLocation();
  const selected = pathname.startsWith(route);

  return (
    <li className={classnames({ selected })}>
      <Link to={route}>{title}</Link>
    </li>
  );
};

const Navigation = () => {
  // const domain = getEnv(process.env.REACT_APP_OLD_DOMAIN);
  // const oldRoute = route => `${domain}/platform/${route}`;
  const canEditExchangeRates = useStoreState(
    state => state.auth.canEditExchangeRates
  );
  const { url } = useRouteMatch();

  return (
    <ul className="configuration-list">
      {/* <Fallback title="Divisions" route={oldRoute('config/divisions')} /> */}
      {canEditExchangeRates && (
        <Config title="Exchange rates" route={`${url}/exchange-rates`} />
      )}
      <Config title="Notifications" route={`${url}/notifications`} />
    </ul>
  );
};

const Section = () => {
  const { slug } = useParams();

  return (
    <>
      {slug === 'exchange-rates' && <Exchange />}
      {slug === 'notifications' && <Notifications />}
    </>
  );
};

export default () => {
  useSiteTitle('Configuration');
  const { url } = useRouteMatch();

  return (
    <div className="wrapper">
      <div>
        <h2>Settings</h2>

        <Navigation />

        <div className="sub-configuration">
          <Switch>
            <Route path={`${url}/:slug`}>
              <Section />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
