import React from 'react';
import Tooltip from 'rc-tooltip';

export default props => {
  const { children, ...rest } = props;
  return (
    <Tooltip
      placement="left"
      trigger={['hover']}
      prefixCls="nui-popover"
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
