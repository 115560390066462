import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';

import * as api from '~/fetch/analytics';
import { Loading, Tooltip } from 'Components/nui';
import { SimpleTable } from 'Components/nui/Table';
import * as Data from 'Components/Data';
import { capitalize } from '~/utils';

type IRefName = 'csc' | 'blk';
type IRef = { id: string; settle: number; change: number };
type IRefInfo = {
  id: string;
  name: string;
  currency: string;
  unit: string;
  dt: string;
};
type IDatum = { period: string; refs: Record<IRefName, IRef> };

type IFutures = {
  refs: Record<IRefName, IRefInfo>;
  data: IDatum[];
};

const init = { loading: true, error: false, data: null as IFutures | null };
type State = typeof init;

const getFutures = (ref: string[]) =>
  api
    .get<IFutures>({ url: '/futures/settled/CME', params: { ref } })
    .then(async resp => {
      switch (resp.status) {
        case 200: {
          return { data: await resp.json() };
        }
        default:
          return { error: true };
      }
    });

function useFutures() {
  const [state, setState] = useState(init);

  const setError = (error: boolean) => void setState(st => ({ ...st, error }));

  const setData = (data: IFutures) =>
    void setState(st => ({ ...st, loading: false, data }));

  useEffect(() => {
    let mounted = true;

    getFutures(['blk', 'csc']).then(({ data, error }) => {
      if (!mounted) return;
      if (error || !data) {
        setError(true);
        return;
      }
      setData(data);
    });

    return () => void (mounted = false);
  }, []);

  return { state };
}

export const Futures = () => {
  const futures = useFutures();

  return (
    <div className="futures">
      <Helmet>
        <title>Futures</title>
      </Helmet>
      <h2>Futures settlements</h2>

      <div className="futures-content">
        {futures.state.loading ? (
          <Loading size="huge" className="mb-16 mt-16" />
        ) : (
          <Content state={futures.state} />
        )}
      </div>
    </div>
  );
};

const refcol = (name: IRefName, refs: Record<IRefName, IRefInfo>) => ({
  name,
  key: name,
  title: (
    <>
      {refs[name].name}
      <span className="unit">
        {refs[name].currency}/{capitalize(refs[name].unit)}
      </span>
    </>
  ),
  render: (datum: IDatum) => (
    <Data.Number precision={4} value={datum.refs[name].settle} />
  ),
});

interface IContent {
  state: State;
}
const Content = ({ state }: IContent) => {
  return (
    <div>
      <div className="futures-info">
        <div className="data-info">
          <div className="item">
            <strong className="all-black">Type</strong>
            <span>
              CME Futures Settlements
              <Tooltip
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled" />
                    Final data represents the futures settlement price from the
                    end of the previous day, sourced from the CME Futures
                    Settlements
                  </span>
                }
              >
                <span className="icon-help-circled" />
              </Tooltip>
            </span>
          </div>
          <div className="item">
            <strong className="all-black">Last updated</strong>
            <span>{moment(state.data?.refs.blk.dt).format('DD MMM YYYY')}</span>
          </div>
        </div>
      </div>
      <div className="futures-table">
        <SimpleTable
          data={state.data?.data.slice(0, 18)}
          columns={[
            {
              name: 'period',
              key: 'period',
              title: 'Month',
              render: d => moment(d.period, 'YYYY-MM').format('MMM YYYY'),
            },
            refcol('blk', state.data!.refs),
            refcol('csc', state.data!.refs),
          ]}
        />
      </div>
    </div>
  );
};
