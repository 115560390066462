import { action, thunk, thunkOn } from 'easy-peasy';
import { banners as service } from '~/services';
import { match } from '~/services/utils';
import {
  BannersModel,
  IBanner,
  BannerModelMode,
  BannerModelGroup,
} from './types';
import listModel from '~/store/utils/listModel';
import stackModel from '~/store/utils/stackModel';
import errorModel from '~/store/utils/errorModel';
import { getGroup } from './utils';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import R from 'ramda';

const INITIAL_MODE: BannerModelMode = 'list-current';

function bannerList() {
  return listModel<IBanner, BannerModelGroup>([], item => item.id, {
    data: {
      current: [],
      expired: [],
    },
    getGroup,
  });
}

export default function bannersModel(): BannersModel {
  return {
    mode: stackModel<BannerModelMode>(
      [INITIAL_MODE],
      (value, previous) => value !== previous
    ),
    schema: null,
    setSchema: action((state, payload) => {
      state.schema = payload;
    }),
    items: bannerList(),
    selectedItem: null,
    setSelectedItem: action((state, payload) => {
      state.selectedItem = payload;
    }),
    onEnter: thunkOn(
      actions => actions.mode.push,
      async (actions, { payload }) => {
        if (payload === 'new') {
          actions.setSelectedItem(null);
        }
      }
    ),
    editSchema: null,
    setEditSchema: action((state, payload) => {
      if (!payload) state.editSchema = state.schema;
      else state.editSchema = payload;
    }),
    editItem: thunk(
      async (
        { setEditSchema, setSelectedItem, apiListDisplayBanners, mode },
        payload
      ) => {
        if (payload)
          return match(await service.editSchema(payload), {
            async error() {
              toast.error('Error getting Banner schema');
              return null;
            },
            async success({ value }) {
              setEditSchema(value);
              setSelectedItem(payload);
              mode.push('edit');
              await apiListDisplayBanners();
              return value;
            },
          });
      }
    ),
    apiError: errorModel(),
    apiSchema: thunk(async ({ apiError, setSchema }) => {
      match(await service.schema(), {
        async error() {
          apiError.set({ message: 'apiSchema' });
        },
        async success({ value }) {
          setSchema(value);
        },
      });
    }),
    apiStatus: 'initial',
    setApiStatus: action((state, payload) => {
      state.apiStatus = payload;
    }),
    apiList: thunk(
      async (
        { items, apiError, setApiStatus },
        _payload,
        { getStoreState }
      ) => {
        const state = getStoreState() as any;
        const solutionId = state.auth.solution.id as string;
        apiError.set(null);
        setApiStatus('loading');
        const result = await service.list({ solutionId });
        match(result, {
          async error() {
            apiError.set({ message: 'apiList' });
            setApiStatus('error');
          },
          async success({ value }) {
            items.set(value);
            setApiStatus('ready');
          },
        });
        return result;
      }
    ),
    apiAdd: thunk(
      async ({ items, mode, apiError, apiListDisplayBanners }, payload) => {
        const result = await service.add({ ...payload, style: 'info' });
        match(result, {
          async error() {
            apiError.set({ message: 'apiAdd' });
            toast.error('Error creating new Banner');
          },
          async success(result) {
            items.prepend(result.value);
            mode.pop();
            toast.success('Banner created');
            await apiListDisplayBanners();
          },
        });
        return result;
      }
    ),
    apiRemove: thunk(async ({ items }, payload) => {
      const result = await service.remove(payload);
      match(result, {
        async error() {
          toast.error('Errors deleting Banner');
        },
        async success() {
          items.remove(payload);
          toast.success('Banner deleted');
        },
      });
      return result;
    }),
    apiSetPublished: thunk(
      async ({ items, apiListDisplayBanners }, payload) => {
        items.update({ id: payload.id, busy: true });
        const result = await service.setPublished(payload);
        match(result, {
          async error() {
            items.update({ id: payload.id, busy: false });
            toast.error('Error publishing Banner');
          },
          async success(result) {
            items.update({ ...result.value, busy: false });
            toast.success(
              result.value.ispublished
                ? 'Banner published'
                : 'Banner un-published'
            );
            await apiListDisplayBanners();
          },
        });
        return result;
      }
    ),
    apiUpdate: thunk(
      async ({ items, mode, apiListDisplayBanners }, payload) => {
        items.update({ id: payload.id, busy: true });
        const result = await service.update(payload);
        match(result, {
          async error() {
            toast.error('Error updating Banner');
            items.update({ id: payload.id, busy: false });
          },
          async success(result) {
            items.update({ ...result.value, busy: false });
            mode.pop();
            toast.success('Banner updated');
            await apiListDisplayBanners();
          },
        });
        return result;
      }
    ),
    displayBanners: [],
    displayBannerSince: null,
    setDisplayBanners: action((state, { items, since }) => {
      state.displayBannerSince = since.toString();
      state.displayBanners = items;
    }),
    apiListDisplayBanners: thunk(
      async ({ setDisplayBanners }, payload, { getStoreState }) => {
        const since = moment(
          R.pathOr(
            undefined,
            ['banners', 'displayBannerSince'],
            getStoreState()
          )
        );
        const response = since
          ? service.pollList({ ...payload, since })
          : service.list(payload);

        match(await response, {
          async error() {},
          async success({ value, since }) {
            if (!since) return;
            setDisplayBanners({ items: value, since });
          },
        });
        return response;
      }
    ),
  };
}
