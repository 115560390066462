import { BannerModelGroup, IBanner } from '~/store/types';
import { getStatus, BannerStatus } from './getStatus';

const GROUP_MATRIX: Readonly<Record<BannerStatus, BannerModelGroup>> = {
  draft: 'current',
  expired: 'expired',
  live: 'current',
  paused: 'current',
  scheduled: 'current',
};

export function getGroup(banner: IBanner): BannerModelGroup {
  const status = getStatus(banner);
  return GROUP_MATRIX[status];
}
