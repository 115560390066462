import { useEffect, useState } from 'react';
import { useStoreState } from './store';
import R from 'ramda';
import * as api from './fetch';

const CDN_URL = process.env.REACT_APP_LOCALE_CDN as string;

const getCurrentLocale = R.pathOr('en', ['auth', 'user', 'locale']);

async function cdn() {
  const response = await fetch(CDN_URL);
  const data = await response.json();

  return data;
}

export function useLocaleProvider() {
  const locale = useStoreState(getCurrentLocale);
  const [messages, setMessages] = useState<Record<string, any>>({});

  useEffect(() => {
    cdn().then(updated => {
      if (updated) setMessages(updated);
    });
  }, []);

  return { messages: R.propOr({}, locale, messages), locale };
}

const LANGUAGES = {
  en: 'English',
  pt: 'Portuguese',
  zh: 'Mandarin',
  ja: 'TEST LANGUAGE',
  es: 'Spanish',
};
if (process.env.REACT_APP_ENV === 'prod') delete LANGUAGES['ja']; // XXX yuck

export function useLocale() {
  const current = useStoreState(getCurrentLocale);
  const [loading, setLoading] = useState(false);

  async function change(locale: string) {
    setLoading(true);

    try {
      const response = await api.user.updateLocale(locale);
      return await api.utils.processResponse(response, {
        200() {
          setLoading(false);
          return { success: true } as const;
        },
        default() {
          setLoading(false);
          return { success: false } as const;
        },
      });
    } catch (error) {
      setLoading(false);
      return { success: false } as const;
    }
  }

  const options = R.sort(R.ascend(R.prop(0 as any)), R.toPairs(LANGUAGES));

  return { current, loading, options, change };
}
