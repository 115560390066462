import { useEffect, useRef } from 'react';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import { useEvent } from 'react-use';
import { useDebounced } from '~/hooks';

type ChangeSource = 'init' | 'user' | 'system';
type TriggerChange = (editor: UnprivilegedEditor, source: ChangeSource) => void;

export function getHtml(editor: UnprivilegedEditor) {
  return editor.getHTML().replaceAll('<p><br></p>', '<br>');
}

export function setHtml(quill: ReactQuill, value: string) {
  const { editor } = quill;
  if (!editor) return;
  quill.setEditorContents(editor, value.replaceAll('<br>', '<p><br></p>'));
}

export function useTooltipFix(rootRef: React.RefObject<HTMLElement>) {
  // Quick and dirty fix for tooltip location.
  useEvent(
    'click',
    e => {
      if (e.type !== 'click') return;
      const { pageX, pageY } = e as MouseEvent;
      const root = rootRef.current!;
      const tooltip = root.querySelector('.ql-tooltip') as HTMLElement;
      if (
        !tooltip ||
        tooltip === e.target ||
        tooltip.contains(e.target as Node)
      )
        return;
      e.stopPropagation();
      const { width } = tooltip.getBoundingClientRect();
      tooltip.style.left = `${Math.max(0, pageX - width / 2)}px`;
      tooltip.style.top = `${pageY}px`;
    },
    rootRef.current,
    {}
  );

  // Disable tab selection of tools.
  useEffect(() => {
    if (!rootRef.current) return;
    rootRef.current
      .querySelectorAll('.ql-formats :not(*[tabindex="-1"])')
      .forEach(element => {
        element.setAttribute('tabindex', '-1');
      });
  }, [rootRef.current]);
}

export function useOnChange(
  value: string | null,
  onChange?: (value: string, source: ChangeSource) => void
): {
  set: TriggerChange;
  commit: TriggerChange;
  commitValue: (value: string, source: ChangeSource) => void;
  cancel: () => void;
  getLastSource: () => ChangeSource;
  getValue: () => string | null;
} {
  const serializedRef = useRef(value);
  const lastSourceRef = useRef<ChangeSource>('init');
  function commitValue(next: string, source: ChangeSource) {
    serializedRef.current = next;
    lastSourceRef.current = source;
    onChange && onChange(next, source);
  }
  const [set, commit, cancel] = useDebounced<TriggerChange>(
    (editor, source) => {
      if (!onChange) return;
      const next = getHtml(editor);
      if (next === serializedRef.current) return;
      commitValue(next, source);
    },
    1000,
    {},
    [onChange, value]
  );

  useEffect(cancel, [value]);

  return {
    set,
    commit,
    commitValue,
    cancel,
    getLastSource: () => lastSourceRef.current,
    getValue: () => serializedRef.current,
  };
}
