import type { Order } from '~/models/tenders';
import type { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import { InputNumberWidget, SelectWidget } from 'Components/form/widgets';
import { MAX_SAFE_INTEGER } from 'Components/form/utils';
import { Button, Alert } from 'Components/nui';

type Schema = {
  startprice: { step: number };
  variants: {
    field: {
      fields: {
        price: {
          required: false;
          type: 'Decimal';
        };
        product: {
          required: false;
          type: 'Select';
          choices: [string, string][];
        };
      };
    };
  };
};

type InitialOrder = Order['formdata'];
type StateData = {
  index: number;
  id: string;
  product: string;
  price?: number | null;
};
type State = Record<string, StateData>;

const NAME = 'variants' as const;

interface IConfig extends FormComponentProps {
  schema: Schema;
  order: InitialOrder;
}
export default class Variants {
  private schema: Schema;
  private form: WrappedFormUtils<{ variants: State }>;
  private order: InitialOrder;

  constructor({ schema, form, order }: IConfig) {
    this.schema = schema;
    this.form = form;
    this.order = order;

    const initialValue: State = this.order?.variants
      ? Object.fromEntries(
          this.order.variants
            .sort((a, b) => a.product.name.localeCompare(b.product.name))
            .map(({ id, price, product }, index) => [
              id,
              { id, index, product: product.id, price: price?.val },
            ])
        )
      : {};

    form.getFieldDecorator(NAME, { initialValue });
    // if (!this.values.length) this.create();
  }

  private get state(): State {
    return this.form.getFieldValue(NAME);
  }

  private get values(): StateData[] {
    return Object.values(this.state);
  }

  private get products() {
    return this.schema.variants.field.fields.product.choices.map(
      ([value, label]) => ({ key: value, value, label })
    );
  }

  get skip() {
    return true;
  }

  get name() {
    return NAME;
  }

  private create() {
    let id: string;
    const flag = (next: string) => this.values.find(s => s.id === next);
    do {
      id = (Math.random() * 1e6).toFixed(0);
    } while (flag(id));

    const values = this.values;

    this.form.setFieldsValue({
      variants: {
        ...this.state,
        [id]: { id, product: '', index: values.length },
      },
    });

    return id;
  }

  private onChange(id: string) {
    return (value: StateData) =>
      void this.form.setFieldsValue({
        variants: { ...this.state, [id]: value },
      });
  }

  private onDelete(id: string) {
    return () =>
      void this.form.setFieldsValue({
        variants: Object.fromEntries(
          this.values.filter(v => v.id !== id).map(v => [v.id, v])
        ),
      });
  }

  private choices(product: string) {
    return this.products.filter(
      p =>
        p.value === product ||
        !this.values.map(v => v.product).includes(p.value)
    );
  }

  serializeField({ variants }: Record<'variants', State>) {
    return Object.fromEntries(
      Object.values(variants).flatMap(({ id, price, product }) =>
        product
          ? [
              [`variants-${id}_price`, price || 0],
              [`variants-${id}_product`, product],
            ]
          : []
      )
    );
  }

  render() {
    const values = this.values;

    return (
      <div className="variants-form">
        <h3 className="variants-title">Product variants</h3>
        <Alert hasicon type="info">
          Product variants allow for product sub-categories. To define different
          variations and prices for this product click on the &lsquo;Add product
          variant&rsquo; button and select the applicable variant and enter the
          price differential.
        </Alert>
        <div className="inset-form mb-20 p-0">
          {values
            .slice(0)
            .sort((a, b) => a.index - b.index)
            .map(v => (
              <Entry
                key={v.id}
                value={v}
                choices={this.choices(v.product)}
                onChange={this.onChange(v.id)}
                schema={this.schema}
                onDelete={v.product.length ? this.onDelete(v.id) : undefined}
              />
            ))}
        </div>
        {!values.find(v => !v.product.length) && (
          <Create onClick={() => void this.create()} />
        )}
      </div>
    );
  }
}

interface IEntry {
  value: StateData;
  choices: { key: string; value: string; label: string }[];
  onChange: (value: StateData) => void;
  schema: Schema;
  onDelete?: () => void;
}
const Entry = ({ value, choices, onChange, schema, onDelete }: IEntry) => {
  return (
    <div className="variants-item">
      <Form.Item label="Product variant" required={true}>
        <SelectWidget
          choices={choices}
          type=""
          props={{
            value: value.product,
            onChange(product: string) {
              onChange({ ...value, product });
            },
          }}
        />
      </Form.Item>
      <Form.Item label="Price differential" required={true}>
        <InputNumberWidget
          min={0}
          max={MAX_SAFE_INTEGER}
          step={schema.startprice.step}
          props={{
            value: value.price || 0,
            onChange(data: number) {
              const price = +(
                Math.floor((data * 1e6) / (schema.startprice.step * 1e6)) *
                schema.startprice.step
              ).toFixed(6);
              onChange({ ...value, price });
            },
          }}
        />
      </Form.Item>
      {onDelete && (
        <Button
          className="variants-remove"
          type="buttonlink"
          htmlType="button"
          onClick={onDelete}
        >
          Remove
        </Button>
      )}
    </div>
  );
};

interface ICreate {
  onClick(): void;
}
const Create = ({ onClick }: ICreate) => {
  return (
    <div className="variants-new">
      <Button type="reverse" htmlType="button" onClick={onClick}>
        Add product variant
      </Button>
    </div>
  );
};
