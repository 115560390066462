import React from 'react';
import Switch from 'rc-switch';

export default React.forwardRef(({ loading, ...props }, ref) => (
  <Switch
    ref={ref}
    className=""
    prefixCls="nui-switch"
    loadingIcon={
      loading ? <span className="icon-spin6 animate-spin"></span> : undefined
    }
    {...props}
  />
));
