import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Reports from './Reports';
import BaseReport from './Base';
import CsvReport from './Csv';

export const reportGroups = [
  'Sales',
  'Average weighted prices',
  'Purchases',
  // 'Trades',
];

export const reportTypes = {
  'trade-csv': {
    group: 'Trades',
    path: 'trade-csv',
    title: 'All trades',
    acl: ['owner'],
    divtype: 'seller',
  },
  'sales-by-customer': {
    group: 'Sales',
    path: 'sales-by-customer',
    title: 'Sales by customer',
    acl: ['owner', 'dao'],
    divtype: 'seller',
  },
  'sales-by-product': {
    group: 'Sales',
    path: 'sales-by-product',
    title: 'Sales by product',
    acl: ['owner', 'dao'],
    divtype: 'seller',
  },
  'sales-by-division': {
    group: 'Sales',
    path: 'sales-by-division',
    title: 'Sales by division',
    acl: ['owner'],
    divtype: 'seller',
  },
  'average-price-by-supplier': {
    group: 'Average weighted prices',
    path: 'average-price-by-supplier',
    title: 'Average weighted prices by product',
    acl: ['owner'],
    divtype: 'seller',
  },
  'average-price-by-supplier-customer': {
    group: 'Average weighted prices',
    path: 'average-price-by-supplier-customer',
    title: 'Average weighted prices by division',
    acl: ['owner'],
    divtype: 'seller',
  },
  'average-price-by-customer-supplier': {
    group: 'Average weighted prices',
    path: 'average-price-by-customer-supplier',
    title: 'Average weighted prices by customer',
    acl: ['owner'],
    divtype: 'seller',
  },
  'purchases-by-product': {
    group: 'Purchases',
    path: 'purchases-by-product',
    title: 'Purchases by product',
    acl: ['dao'],
    divtype: 'buyer',
  },
  'purchases-by-supplier': {
    group: 'Purchases',
    path: 'purchases-by-supplier',
    title: 'Purchases by supplier',
    acl: ['dao'],
    divtype: 'buyer',
  },
  'average-price-dao': {
    group: 'Average weighted prices',
    path: 'average-price-dao',
    title: 'Average weighted price',
    acl: ['dao'],
    divtype: 'buyer',
  },
};

export default () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/trade-csv`}>
        <CsvReport reportType="trade-csv" />
      </Route>
      <Route path={`${url}/:reportType`}>
        <BaseReport />
      </Route>
      <Route>
        <Reports />
      </Route>
    </Switch>
  );
};
