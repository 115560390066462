import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { Form, Button, Loading } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import {
  mergeSchema,
  normalizeChoices,
  submitError,
} from 'Components/form/utils';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import R from 'ramda';
import { useParams, useHistory } from 'react-router-dom';
import { useSharedCustomer } from '.';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { Wizard, WizardStep } from 'Components/Wizard';
import { SelectField, DecimalField } from 'Components/form/fields';
import { useStoreState } from 'easy-peasy';

const PForm = ({ form, sellerId, schema, submitState }) => {
  const history = useHistory();
  const { id } = useParams();
  const solution = useStoreState(state => state.auth.solution);

  const creditamountField = new DecimalField({
    name: 'creditamount',
    label: `Credit limit, ${solution.currency}`,
    step: 100,
    initialValue: R.pathOr(0, ['creditamount', 'value'], schema),
    required: true,
  });

  const creditperiodField = new SelectField({
    name: 'creditperiod',
    label: 'Limit period',
    choices: normalizeChoices(schema.creditperiod),
    initialValue: R.pathOr(0, ['creditperiod', 'value'], schema),
    required: true,
  });

  const getFormWrapper = () => {
    let fields = [
      {
        name: 'reference',
        label: 'Internal counterparty reference',
        type: 'Input',
      },
      {
        name: 'paymentterm',
        label: 'Payment term',
        choices: normalizeChoices,
      },
      {
        name: 'freight',
        label: 'Freight',
        choices: normalizeChoices,
      },
      {
        name: 'docsapproved',
        label: 'All documents approved',
      },
      {
        name: 'nocreditlimit',
        label: 'No credit limit',
        required: true,
      },
      {
        render: () => {
          if (!R.prop('creditamount', schema)) return null;
          return (
            <div
              style={{
                display:
                  form.getFieldValue('nocreditlimit') === 'true'
                    ? 'none'
                    : 'block',
              }}
            >
              {creditamountField.render({ form })}
            </div>
          );
        },
        serializeField: values => {
          if (!R.prop('creditamount', schema)) return null;
          return {
            creditamount: creditamountField.serialize(values.creditamount),
          };
        },
      },
      {
        render: () => {
          if (!R.prop('creditperiod', schema)) return null;
          return (
            <div
              style={{
                display:
                  form.getFieldValue('nocreditlimit') === 'true'
                    ? 'none'
                    : 'block',
              }}
            >
              {creditperiodField.render({ form })}
            </div>
          );
        },
        serializeField: values => {
          if (!R.prop('creditperiod', schema)) return null;
          return {
            creditperiod: creditperiodField.serialize(values.creditperiod),
          };
        },
      },
    ];

    fields = fields.map(item => {
      if (!item.name || !schema[item.name]) return item;
      const s = schema[item.name];
      return {
        ...item,
        initialValue: s.value !== undefined ? s.value : item.initialValue,
        props: {
          ...item.props,
          disabled: !!s.disabled,
        },
      };
    });

    return new FormWrapper(form, {}, mergeSchema(schema, fields));
  };

  const formWrapper = getFormWrapper();
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    submitState.setLoading(true);
    let data = formWrapper.serialize();

    try {
      const result = await api.putData(`/customers/${id}`, {
        ...data,
        division: sellerId,
      });
      submitState.setResult();
      submitState.setLoading(false);
      toast.success('Customers details are saved');
      history.push(`/customers/${id}`);
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
      submitState.setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <div className="button-set mt-20 mb-20">
        <Button htmlType="submit" type="primary" loading={submitState.loading}>
          Update
        </Button>
      </div>
    </Form>
  );
};

const WrappedForm = Form.create()(PForm);

export default () => {
  const { id } = useParams();
  const [customer] = useSharedCustomer();
  const [sellerId, setSellerId] = useState(null);
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    if (customer) {
      setSchema(null);

      setSellerId(
        customer.links.length === 1 ? customer.links[0].seller.id : null
      );
    }
  }, [customer, setSellerId]);

  useEffect(() => {
    const fetch = async () => {
      const params = {
        division: sellerId,
      };
      const response = await api.getData2(`/customers/${id}/schema`, params);
      setSchema(response.fields);
    };
    if (sellerId) {
      fetch();
    }
  }, [id, sellerId]);

  if (!customer) return null;

  return (
    <div className="full-page-header">
      <hr className="mt-10" />
      <div className="nui-form order-form customer-details-form">
        <Wizard>
          <WizardStep
            stepId={1}
            title="My division"
            description="Select the division this customer is linked with"
            completed={!!sellerId}
            active
          >
            <Form.Item label="Division">
              <Select value={sellerId} onChange={v => setSellerId(v)}>
                {customer.links.map(link => (
                  <Select.Option value={link.seller.id} key={link.seller.id}>
                    {link.seller.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </WizardStep>
          {sellerId && (
            <WizardStep
              stepId={2}
              title="Link details"
              description="Edit the credit limit and other details"
              disabled={false}
              active
            >
              <ShowResult renderError={submitError}>
                {submitState => (
                  <div className="edit-customer-details">
                    {schema ? (
                      <WrappedForm
                        key="customer-details"
                        sellerId={sellerId}
                        schema={schema}
                        submitState={submitState}
                      />
                    ) : (
                      <Loading size="large" />
                    )}
                  </div>
                )}
              </ShowResult>
            </WizardStep>
          )}
        </Wizard>
      </div>
    </div>
  );
};
