import type { Tender, Order } from '~/models/tenders';
import React, { useState } from 'react';
import { Alert, Button } from 'Components/nui';
import {
  LabelledInfo,
  RoutedSummary,
  ListedData,
  StackItem,
} from 'Components/nui/Wizard';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { capitalize } from '~/utils';
import * as Data from 'Components/Data';
import * as service from '~/services/tenders';
import { toast } from 'react-toastify';
import { routeUrl, usePathTools } from '~/router';

type TenderProp = Record<'tender', Tender>;
type OrdersProp = Record<'orders', Order[]>;
type Participants = { included: number; excluded: number };

type IConfig = Partial<TenderProp & OrdersProp>;
export default ({ tender, orders }: IConfig) => {
  return (
    <div className="summary-details">
      {tender ? (
        <>
          <TenderDetails tender={tender} />
          {!!orders?.length && (
            <OrdersDetails tender={tender} orders={orders} />
          )}
          {!!tender.participants && (
            <ParticipantsDetails {...tender.participants} />
          )}
        </>
      ) : (
        <Alert type="info" hasicon>
          <p className="smaller">
            A summary of your tender will appear here. You can save and exit at
            any step. Once complete, you can publish your tender and notify all
            selected participants.
          </p>
        </Alert>
      )}
    </div>
  );
};

const infoFmt = 'LL HH:mm Z';
const TenderDetails = ({ tender }: TenderProp) => (
  <LabelledInfo
    data={[
      ['Tender name', tender.name],
      ['Method', <Data.TenderMethod info={tender} />],
      ['Status', <TenderStatus value={tender.status} />],
      ['Start', <Data.Datetime value={tender.start} fmt={infoFmt} />],
      ['Finish', <Data.Datetime value={tender.finish} fmt={infoFmt} />],
      ['Duration', <Data.Duration value={tender.duration} />],
    ]}
  />
);

const TenderStatus = ({ value }: Record<'value', string>) => (
  <span className={classnames('status', value)}>{value}</span>
);

type IDynamicEntry = Record<'data', React.ReactNode> &
  Partial<Record<'edit' | 'duplicate' | 'title', string>> &
  Record<'onDelete', () => any>;
const DynamicEntry = ({
  title,
  edit,
  duplicate,
  data,
  onDelete,
}: IDynamicEntry) => {
  const [loading, setLoading] = useState(false);

  return (
    <StackItem
      title={
        <>
          {title}
          <span className="edit-delete">
            {edit && (
              <>
                <Link to={edit}>Edit</Link>|
              </>
            )}
            {duplicate && (
              <>
                <Link to={duplicate}>Duplicate</Link>|
              </>
            )}
            <Button
              type="buttonlink"
              onClick={() => {
                setLoading(true);
                onDelete();
              }}
              loading={loading}
              disabled={loading}
            >
              Delete
            </Button>
          </span>
        </>
      }
      data={data}
    />
  );
};

const editRoutes = (tender: Tender) => {
  if (tender.started()) return [() => undefined, () => undefined] as const;

  const params = (order: Order, mode?: string) => ({
    tenderId: tender.id,
    orderId: order.id,
    mode,
  });

  return [
    (order: Order, mode: string) =>
      routeUrl('tender-edit-orders', params(order, mode)),
    (order: Order) => routeUrl('tender-duplicate-order', params(order)),
  ] as const;
};

const OrdersDetails = ({ tender, orders }: TenderProp & OrdersProp) => {
  const { matchParams } = usePathTools();
  const { mode = 'edit' } = matchParams('tender-edit-details');
  const history = useHistory();

  const [edit, duplicate] = editRoutes(tender);

  return (
    <RoutedSummary
      stepId="orders"
      title={capitalize(tender.ordername.order) + 's'}
    >
      <ListedData className="summary-orders-list">
        {orders.map(order => (
          <DynamicEntry
            key={order.id}
            title={order.product.name}
            edit={edit(order, mode)}
            duplicate={duplicate(order)}
            onDelete={async () => {
              const params = matchParams('tender-edit-orders');
              if (params.orderId === order.id) {
                history.push(
                  routeUrl('tender-edit-orders', { tenderId: tender.id, mode })
                );
              }

              const result = await service.actions.deleteOrder(
                tender.id,
                order.id
              );
              if (result?.success) {
                toast.success(
                  <>
                    {capitalize(order.ordertype)} for {order.product.name}{' '}
                    removed successfully
                  </>
                );
              } else {
                toast.error(
                  <>
                    An error occurred while removing {order.ordertype} for{' '}
                    {order.product.name}
                  </>
                );
              }
            }}
            data={
              <>
                <Data.IndexUnit value={order.startprice} />
                {' | '}
                {order.reserve.price.val && (
                  <>
                    Reserve price: <Data.Price value={order.reserve.price} />
                    {' | '}
                  </>
                )}
                <Data.Price onZero="Any" value={order.startprice} /> /{' '}
                <Data.Volume value={order.volume} /> {order.volume.unit}
                {' | '}
                <Data.Attributes value={order.attributes} />
                ETD <Data.ETD value={order.etd} />
              </>
            }
          />
        ))}
      </ListedData>
    </RoutedSummary>
  );
};

const ParticipantsDetails = ({ included, excluded }: Participants) => (
  <RoutedSummary stepId="participants" title="Participants">
    <LabelledInfo
      data={[
        ['Included', included.toFixed(0)],
        ['Excluded', excluded.toFixed(0)],
      ]}
    />
  </RoutedSummary>
);
