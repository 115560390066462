import { ResponsiveLine } from '@nivo/line';
import {
  colors,
  ColorSquare,
  TooltipWrapper,
  useFilterIndexData,
} from 'Pages/Dashboard';
import React, { Fragment, useState } from 'react';
import { useDeepCompareEffect, useToggle } from 'react-use';
import R from 'ramda';
import moment from 'moment-timezone';
import Filter, { scale } from './Filter';
import { api } from '~/api';

export default () => {
  const [filter] = useFilterIndexData();
  const [data, setData] = useState([]);
  const [loading, toggle] = useToggle(true);
  const userTz = moment.tz.guess();

  let step = '1w';
  switch (filter.chartPeriodFilter) {
    case '1M':
      step = '1d';
      break;
    case '12M':
      step = '1m';
      break;
    default:
      step = '1w';
      break;
  }

  useDeepCompareEffect(() => {
    if (!filter.productsFilter?.length || !filter.uomFilter) {
      setData(state => ({
        ...state,
        data: [],
      }));
      return;
    }
    let mounted = true;
    toggle(true);

    (async () => {
      try {
        const data = await api.analytics(
          `indexes/chart`,
          [filter.uomFilter, filter.currencyFilter],
          {
            source: filter.productsFilter,
            period: filter.chartPeriodFilter,
            tz: userTz,
          }
        );
        if (mounted) {
          const newData = R.pathOr({}, ['data', 'data'], data);
          const start = R.pathOr({}, ['data', 'start'], data);
          const end = R.pathOr({}, ['data', 'end'], data);

          let thelist = [];
          newData.forEach(record => {
            const colourList = colors[thelist.length % colors.length];
            thelist.push({
              id: record.label,
              color: colourList,
              label: record.label,
              data: record.data
                .filter(item => item.price)
                .map(item => ({
                  x: moment(item.ts).format('YYYY-MM-DDTHH:mm:ss'),
                  y: item.price || -1,
                })),
            });
          });

          if (thelist.length > 0) {
            thelist.push({
              id: 'range',
              color: 'rgba(0,0,0,0)',
              label: '',
              data: [
                {
                  x: moment(data.start).format('YYYY-MM-DDTHH:mm:ss'),
                  y: thelist[0].data[0]?.y,
                },
                {
                  x: moment(data.end).format('YYYY-MM-DDTHH:mm:ss'),
                  y: thelist[0].data[0]?.y,
                },
              ],
            });
          }

          setData({
            data: thelist,
            start: start,
            end: end,
          });
          toggle(false);
        }
      } catch (error) {
        // pass
      }
    })();

    return () => {
      mounted = false;
    };
  }, [filter, setData, toggle]);

  return (
    <div className="index-pricing-graph">
      <Filter />
      {R.isEmpty(data.data) || R.isNil(data.data) ? (
        <div className="nui-row full-page-messaging align-center">
          <div className="icon-inbox bordered align-center">
            No data to display
          </div>
        </div>
      ) : (
        <section className="mt-20">
          <div className="chart">
            <ResponsiveLine
              data={data.data}
              colors={{ datum: 'color' }}
              margin={{ top: 0, right: 0, bottom: 50, left: 60 }}
              enableGridX={false}
              xScale={{
                type: 'time',
                format: '%Y-%m-%dT%H:%M:%S',
                useUTC: false,
                precision: R.pathOr('day', [step, 'precision'], scale),
              }}
              xFormat="time:%Y-%m-%dT%H:%M:%S"
              yScale={{
                type: 'linear',
                stacked: false,
                min: 'auto',
              }}
              axisBottom={{
                format: R.pathOr('day', [step, 'format'], scale),
                tickValues: R.pathOr('day', [step, 'tickValues'], scale),
                legend: '',
                legendOffset: 40,
                legendPosition: 'middle',
                tickRotation: -30,
              }}
              axisLeft={{
                orient: 'left',
                legend: `${filter.currencyFilter}/${filter.uomFilter}`,
                legendOffset: -60,
                legendPosition: 'middle',
              }}
              pointSize={2}
              pointBorderColor="white"
              pointLabelYOffset={-12}
              useMesh={true}
              tooltip={({ point }) => (
                <TooltipWrapper>
                  <ul>
                    <li>{point.serieId}</li>
                    <li>
                      <ColorSquare color={point.color} /> {point.color}
                    </li>
                    <li>
                      {moment(point.data.x).format(
                        R.pathOr('DD MMM', [step, 'tooltipFormat'], scale)
                      )}
                    </li>
                    <li>
                      {point.data.y} {}
                    </li>
                  </ul>
                </TooltipWrapper>
              )}
              enableSlices="x"
              sliceTooltip={({ slice }) => {
                return (
                  <TooltipWrapper>
                    <div>
                      {slice.points &&
                        moment(slice.points[0].data.x).format(
                          R.pathOr('DD MMM', [step, 'tooltipFormat'], scale)
                        )}
                      <hr className="mt-2 mb-2" />
                    </div>
                    {slice.points.map(point => (
                      <Fragment key={point.id}>
                        <ul key={point.id}>
                          <li className="block w-16">
                            <span className="inline-block mt-4">
                              <ColorSquare color={point.color} />
                            </span>
                          </li>
                          <li>
                            <strong className="all-black block pl-16 mt--20">
                              {point.serieId}
                            </strong>
                          </li>

                          <li className="block pl-16">
                            {point.data.y.toLocaleString()}{' '}
                            {filter.currencyFilter}/{filter.uomFilter}
                          </li>
                        </ul>
                      </Fragment>
                    ))}
                  </TooltipWrapper>
                );
              }}
              layers={[
                'grid',
                'axes',
                'areas',
                'lines',
                'crosshair',
                'slices',
                'points',
                'mesh',
              ]}
            />
          </div>

          <ul className="chart-legend">
            {data.data?.map(item => (
              <Fragment key={item.label}>
                {item.id !== 'range' && (
                  <li key={item.label}>
                    <span className="color-block mr-2 mt-2">
                      <ColorSquare color={item.color} />
                    </span>{' '}
                    <span className="name mr-15">{item.label}</span>
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
        </section>
      )}
    </div>
  );
};
