import React, { useEffect, useState, useCallback } from 'react';
import { api } from '~/api';
import { Alert, Input, Button, Loading } from 'Components/nui';
import { toast } from 'react-toastify';
import R from 'ramda';
import { useToggle } from 'react-use';
import { useStoreState } from 'easy-peasy';
import { canEditCustomer } from '~/utils';

const freeIncoterms = ['EXW'];

const NewFreight = ({
  warehouse,
  freight,
  product,
  incoterm,
  customer,
  callback,
}) => {
  const editable = useStoreState(canEditCustomer(customer));
  const [edit, setEdit] = useToggle();
  const [on, toggle] = useToggle();
  const [value, setValue] = useState(
    R.pathOr(0, [warehouse.id, 'val'], freight)
  );
  const solution = useStoreState(state => state.auth.solution);

  const save = async () => {
    try {
      toggle();
      const data = {
        rate: value,
        warehouse: warehouse.id,
      };
      if (product) {
        data.product = product.id;
      } else {
        data.incoterm = incoterm;
      }
      const response = await api.postData(
        `/customers/${customer.id}/freight`,
        data
      );
      callback(response.freight);
      toast.success('New rate is saved');
      setEdit(false);
    } catch (error) {
      toast.error(
        'Something went wrong while saving new rate. Please try again.'
      );
    }

    toggle();
  };

  return (
    <>
      <div className="warehouse-section">
        <h3>Location</h3>
        <p className="smaller mid-gray">{warehouse.desc}</p>
      </div>
      <div className="incoterms-section nowrap">
        <h3>
          Freight rate{' '}
          <span className="smaller regular light-gray">
            ({solution.currency}/Tonne)
          </span>
        </h3>
        {!edit || !editable ? (
          <>
            <div className="nui-fieldset no-ws mr-10 w-150">
              <div className="input-holder">
                <Input
                  className="align-right"
                  type="number"
                  disabled="disabled"
                  value={value}
                  onChange={e => setValue(e.target.value)}
                />
              </div>
            </div>
            <Button type="primary" onClick={() => setEdit(true)}>
              Edit
            </Button>
          </>
        ) : (
          <>
            <div className="nui-fieldset no-ws mr-10 w-150">
              <div className="input-holder">
                <Input
                  className="align-right"
                  type="number"
                  value={value}
                  onChange={e => setValue(e.target.value)}
                />
              </div>
            </div>

            <Button loading={on} disabled={on} type="primary" onClick={save}>
              Save
            </Button>
            <Button
              type="simple"
              className="ml-10"
              onClick={() => setEdit(false)}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default ({ incoterm, product, customer }) => {
  const isfree = freeIncoterms.includes(incoterm);

  const [freight, setFreight] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await api.getData2(
          `/customers/${customer.id}/freight`
        );
        const known = {};

        if (product) {
          response.freight.forEach(item => {
            if (item.product.id === product.id)
              known[item.address.id] = item.rate;
          });
        } else {
          response.freight.forEach(item => {
            if (item.incoterm.id === incoterm)
              known[item.address.id] = item.rate;
          });
        }
        setFreight(known);
      } catch (error) {
        toast.error(`Something went wrong while catching existing freights`);
      }
    };
    if (customer && !isfree) fetch();
  }, [customer, isfree]);

  const callback = useCallback(edited => {
    setFreight({ ...freight, [edited.address.id]: edited.rate });
  }, []);

  return (
    <>
      {isfree && (
        <Alert hasicon type="info">
          No freight rates are required for the selected incoterm.
        </Alert>
      )}
      {!isfree &&
        (freight === null ? (
          <Loading size="medium" />
        ) : (
          <div className="freight-rates">
            <ul>
              {customer.warehouses.map(item => (
                <li key={item.id}>
                  <NewFreight
                    customer={customer}
                    warehouse={item}
                    product={product}
                    incoterm={incoterm}
                    freight={freight}
                    callback={callback}
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
    </>
  );
};
