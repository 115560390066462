import React, { useEffect, useState } from 'react';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'util';
import { Select } from 'antd';
import { Button, Alert, Loading, Form } from 'Components/nui';
import { usePartners } from '~/store/models';
import { toast } from 'react-toastify';
import { useStoreState } from '~/store';
import R from 'ramda';
import { useHistory, useRouteMatch } from 'react-router-dom';

const Step = ({ title, desc }) => (
  <div className="wizard-step">
    <h2>{title}</h2>
    <p className="description">{desc}</p>
  </div>
);

const partnerToForm = partner =>
  partner?.id
    ? {
        ...partner,
        country: partner.country.code,
      }
    : {};

function useFormWrapper({ form, schema, partner }) {
  const countries = R.fromPairs(schema.country.choices);
  const fields = [
    {
      render: () => (
        <Step
          key="step-2"
          title="Partner details"
          desc="Add the details for this partner"
        />
      ),
    },
    { name: 'name', label: 'Company name', type: 'Input' },
    { name: 'buyer', label: 'Buyer' },
    { name: 'consignee', label: 'Consignee' },
    // {
    //   name: 'partner_type',
    //   label: 'Partner type',
    //   type: 'Select',
    //   psuedo: true,
    //   choices: [
    //     { key: 'buyer', label: 'Buyer', value: 'buyer' },
    //     { key: 'consignee', label: 'Consignee', value: 'consignee' },
    //     { key: 'both', label: 'Both', value: 'both' },
    //   ],
    // },
    { name: 'companyno', label: 'Tax number', type: 'Input' },
    {
      name: 'email',
      label: 'Email address',
      type: 'Input',
      props: { type: 'email' },
    },
    {
      name: 'phone',
      label: 'Phone number',
      type: 'Input',
      props: { type: 'tel' },
    },

    {
      render: () => (
        <Step
          key="step-3"
          index={3}
          title="Address details"
          desc="Add the address details for this partner"
        />
      ),
    },
    { name: 'street', label: 'Street', type: 'Input' },
    { name: 'suburb', label: 'Suburb', type: 'Input' },
    { name: 'city', label: 'City', type: 'Input' },
    { name: 'state', label: 'State', type: 'Input' },
    {
      name: 'country',
      label: 'Country',
      type: 'Select',
      choices: R.pipe(
        R.propOr([], 'choices'),
        R.filter(([code]) =>
          R.includes(code, R.keys(R.pathOr({}, ['port', 'choices'], schema)))
        ),
        R.sort(([, first], [, second]) => first.localeCompare(second)),
        R.map(([value, label]) => ({ value, label, key: value }))
      ),
    },
    { name: 'zip', label: 'Zip', type: 'Input' },
    {
      name: 'port',
      label: 'Port',
      choices: R.pipe(
        R.propOr({}, 'choices'),
        R.toPairs,
        R.filter(([code]) =>
          R.equals(code, R.propOr(code, 'country', form.getFieldsValue()))
        ),
        R.sort(([first], [second]) =>
          R.propOr('', first, countries).localeCompare(
            R.propOr('', second, countries)
          )
        ),
        R.map(([country, ports]) => ({
          key: country,
          label: countries[country],
          value: ports.map(([value, label]) => ({ value, label, key: value })),
        }))
      ),
    },
  ];

  return new FormWrapper(
    form,
    partnerToForm(partner),
    mergeSchema(schema, fields)
  );
}

const BaseForm = ({ form, schema, partners, partner, division }) => {
  const formWrapper = useFormWrapper({ form, schema, partner });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }

    setLoading(true);

    const data = formWrapper.serialize();

    try {
      if (partner.id) {
        await partners.update(division, data);
      } else {
        await partners.add(division, data);
      }
      toast('Partner added successfully');
      history.push('/partners');
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      try {
        formWrapper.setErrors(err.response.data);
      } catch (e) {
        toast('Your form could not be submitted. Please try again later.', {
          type: 'error',
        });
      }
      setLoading(false);
    }
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit}>
        {formWrapper.render()}
        <div>
          <Button type="primary" disabled={loading} htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

const PartnerForm = Form.create()(BaseForm);

const Edit = ({ partner = {} }) => {
  const partners = usePartners();
  const [schema, setSchema] = useState({});
  const [schemaLoading, setSchemaLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const roles = useStoreState(state =>
    R.reject(
      R.equals(null),
      state.auth.solution.roles.map(r =>
        r.role !== 'guest' ? r.division : null
      )
    )
  );
  const [division, setDivision] = useState(partner.division?.id || roles[0].id);

  const getSchema = async division => {
    if (division) {
      try {
        setSchemaLoading(true);
        const s = await partners.schema(division);
        setSchema(s);
      } catch (error) {
        toast('An error occurred. Please refresh the page and try again.', {
          type: 'error',
        });
      }
      setSchemaLoading(false);
    }
  };

  useEffect(() => {
    getSchema(division);
  }, [division]);

  return (
    <>
      <div className="full-page-header">
        <div className="partners-form">
          <hr className="mt-10" />
          <div className="wizard">
            <div className="wizard-step-block">
              <div className="wizard-step">
                <Step
                  index={1}
                  title="Division"
                  desc="Select the division this partner will belong to"
                />
              </div>
              <div className="wizard-content">
                <Form.Item label="Division">
                  <Select
                    defaultValue={R.path([0, 'id'], roles)}
                    value={division}
                    onChange={value => {
                      setDivision(value);
                    }}
                  >
                    {roles.map(role => (
                      <Select.Option value={role.id} key={role.id}>
                        {role.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <hr className="dashed" />
            </div>

            {Object.keys(schema).length ? (
              schemaLoading ? (
                'Loading...'
              ) : (
                <div className="wizard-step-block">
                  <PartnerForm
                    schema={schema}
                    division={division}
                    partners={partners}
                    partner={partner}
                  />
                </div>
              )
            ) : (
              <Alert type="warning">Please select a division.</Alert>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default () => {
  const [loading, setLoading] = useState(true);
  const [partner, setPartner] = useState();
  const { params = { partnerId: '' } } =
    useRouteMatch('/partners/edit/:partnerId') || {};
  const partners = usePartners();

  const getPartner = async id => {
    try {
      const all = await partners.fetch();
      const p = R.find(R.propEq('id', params.partnerId), all);
      if (p) {
        setPartner(p);
        setLoading(false);
      }
    } catch (error) {
      toast(
        'There was an error finding this partner. Please try again later.',
        { type: 'error' }
      );
    }
  };

  useEffect(() => {
    if (params.partnerId) getPartner();
    else setLoading(false);
  }, [params.partnerId]);

  if (loading) return <Loading size="medium" />;
  return <Edit partner={partner} />;
};
