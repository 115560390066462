import { Data, Model } from './types';

export default abstract class Base<D extends Data> implements Model {
  private _state: D;

  constructor(state: D) {
    this._state = state;
  }

  protected get state() {
    return this._state;
  }

  get id() {
    return this.state.id;
  }
}
