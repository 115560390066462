import type { Action } from 'easy-peasy';
import { action } from 'easy-peasy';

export interface IErrorModel {
  error: { message: string } | null;
  set: Action<IErrorModel, IErrorModel['error']>;
  reset: Action<IErrorModel>;
}

export default function errorModel(): IErrorModel {
  return {
    error: null,
    set: action((state, payload) => {
      state.error = payload;
    }),
    reset: action((state, payload) => {
      state.error = null;
    }),
  };
}
