import React from 'react';
import { useToggle } from 'react-use';
import classnames from 'classnames';
import Button from './nui/Button';

export interface IFilterConfig<Fields extends string> {
  value: (name: Fields) => any;
  values: () => { [K in Fields]: any };
  filtered: () => boolean;
  reset: () => void;
}

interface ITableFilter {
  filters: IFilterConfig<any>;
  total: number;
  children?: React.ReactNode;
  loading: boolean;
  extra?: React.ReactNode;
}
export default function TableFilter({
  filters,
  total,
  children,
  loading,
  extra,
}: ITableFilter) {
  const [opened, toggleOpen] = useToggle(false);

  const onReset = () => {
    filters.reset();
  };

  return (
    <div className={classnames('filter-section', { opened })}>
      <div className="filterme">
        <Button
          onClick={toggleOpen}
          type="primary"
          size="small"
          className="filter-button pl-30"
        >
          <span className="arrow" />
          Filter <span className="show-for-large">options</span>
        </Button>
        <>{extra}</>
        {filters.filtered() && (
          <span className="filter-message">
            {loading ? (
              'Loading...'
            ) : (
              <>
                Showing <strong className="all-black">{total}</strong> results
                based on your filter settings |{' '}
                <Button type="buttonlink" onClick={onReset}>
                  <span>Reset filters</span>
                </Button>
              </>
            )}
          </span>
        )}
      </div>
      <div className="filter-block">{opened && <>{children}</>}</div>
    </div>
  );
}
