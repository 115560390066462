import React, { useEffect, useState } from 'react';
import { DatePicker, Select } from 'Components/nui';
import moment from 'moment-timezone';
import { useSharedFilter } from '.';
import R from 'ramda';

export default () => {
  const rangesList = [
    ['TODAY', 'Today'],
    ['YESTERDAY', 'Yesterday'],
    ['LAST_7_DAYS', 'Last 7 days'],
    ['LAST_14_DAYS', 'Last 14 days'],
    ['LAST_MONTH', 'Last month'],
    ['LAST_6_MONTHS', 'Last 6 months'],
    ['LAST_YEAR', 'Last year'],
    ['RANGE', 'Custom range'],
  ];

  const ranges = {
    TODAY: [moment().startOf('day'), moment().endOf('day')],
    YESTERDAY: [
      moment().startOf('day').subtract(1, 'd'),
      moment().endOf('day').subtract(1, 'd'),
    ],
    LAST_7_DAYS: [
      moment().startOf('day').subtract(7, 'd'),
      moment().endOf('day'),
    ],
    LAST_14_DAYS: [
      moment().startOf('day').subtract(14, 'd'),
      moment().endOf('day'),
    ],
    LAST_MONTH: [
      moment().startOf('day').subtract(1, 'M'),
      moment().endOf('day'),
    ],
    LAST_6_MONTHS: [
      moment().startOf('day').subtract(6, 'M'),
      moment().endOf('day'),
    ],
    LAST_YEAR: [
      moment().startOf('day').subtract(1, 'Y'),
      moment().endOf('day'),
    ],
  };

  const [filter, setFilter] = useSharedFilter();
  const [selected, setSelected] = useState('LAST_14_DAYS');

  useEffect(() => {
    if (selected === 'RANGE') return;
    setFilter(R.pathOr(ranges.LAST_14_DAYS, [selected], ranges));
  }, [selected]);

  const disabledDate = current => current && current > moment().endOf('day');

  return (
    <div className="nui-fieldset">
      <label htmlFor="view-date-range">Viewing</label>
      <div className="select-holder">
        <Select id="view-date-range" value={selected} onChange={setSelected}>
          {rangesList.map(item => (
            <option value={item[0]} key={item[0]}>
              {item[1]}
            </option>
          ))}
        </Select>
      </div>
      {selected === 'RANGE' && (
        <div className="input-holder date-holder">
          <DatePicker.RangePicker
            name="range"
            disabledDate={disabledDate}
            value={filter}
            format="DD MMM YYYY"
            onChange={value => {
              setFilter(value);
            }}
            allowClear={false}
          />
        </div>
      )}
    </div>
  );
};
