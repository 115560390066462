import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const ProductColumn = ColumnTree.defineNode('product', _context => [
  {
    title: (
      <FormattedMessage
        id="marketplace-table-heading-products"
        description="Table heading"
        defaultMessage="Products"
      />
    ),
    className: 'col-product-name-alt shaded-right',
  },
]);
