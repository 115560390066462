import React, { useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import R from 'ramda';
import classNames from 'classnames';
import { useToggle, useClickAway } from 'react-use';
import { api } from '~/api';
import { Loading } from 'Components/nui';

const MenuItem = ({
  item,
  setProduct,
  setPreset,
  productById,
  toggleVisible,
}) => {
  const updatePresets = useStoreActions(R.path(['auth', 'updatePresets']));

  const onClick = async () => {
    toggleVisible();
    const product = productById(item.product.id);
    setProduct(product);
    setPreset(item.id);
  };

  const onDelete = async () => {
    await api.deleteData(`/presets/${item.id}`);
    updatePresets(null);
  };

  return (
    <li>
      <button onClick={onClick}>{R.prop('name', item)}</button>
      {item.id && (
        <button
          className="icon-trash"
          title="Delete this preset"
          onClick={onDelete}
        />
      )}
    </li>
  );
};

export const PresetDropdown = props => {
  const solution = useStoreState(state => state.auth.solution);
  const presets = useStoreState(state => state.auth.presets);

  const loadPresets = useStoreActions(R.path(['auth', 'loadPresets']));
  const tradableProducts = useStoreState(state =>
    state.auth.tradableProducts(props.orderType)
  );

  const ref = useRef(null);
  const [visible, toggleVisible] = useToggle(false);
  useClickAway(ref, () => {
    toggleVisible(false);
  });

  useEffect(() => {
    if (presets === null && visible) {
      loadPresets(solution.id);
    }
  }, [presets, solution, loadPresets, visible]);

  const acc = item => R.find(R.propEq('id', item.product.id))(tradableProducts);
  const filteredPresets = R.filter(acc, presets || []);

  return (
    <div
      ref={ref}
      className={classNames('presets', {
        open: visible,
      })}
    >
      <button className="presets-dd" onClick={toggleVisible}>
        Load a preset
      </button>
      <nav className="presets-ul">
        <ul>
          {filteredPresets.length ? (
            filteredPresets.map(item => (
              <MenuItem
                key={item.id}
                item={item}
                toggleVisible={toggleVisible}
                {...props}
              />
            ))
          ) : (
            <li>
              {presets === null ? (
                <Loading size="medium" />
              ) : (
                'No presets configured'
              )}
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};
