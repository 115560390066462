import React, { useEffect } from 'react';
import R from 'ramda';
import { DrawerWrapper } from 'Components/Layout';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { useTimezone } from '~/hooks';
import { checkIsModal, ROUTES, usePreviousNormalLocation } from '~/Routes';
import LastTrade from './Marketplace/LastTrade';
import Marketplace from './Marketplace';
import Market from './Market';

import PrivacyDialog from './PrivacyDialog';

import Header from './Header';
import Navbar from './Navbar';
import Footer from './Footer';

import Products from './Products';
import { QuickView as ProductQuickView } from './Products/View';
import ProductSpecifications from './Products/Specifications';

import OrderQuickView from './Orders/QuickView';

import Account from './Account';
import Warehouses from './Warehouses';
import Customers from './Customers';
import Participants from './Participants';
import Routes from './Routes';
import Trades from './Trades';
import Orders, { OrderNew, OrderEdit } from './Orders';
import Config from './Config';
import { toast } from 'react-toastify';
import { useStoreState, useStoreActions } from '~/store';
import ScrollTopButton from 'Components/ScrollTopButton';
import { Modal } from 'Components/nui';
import { useNotifications } from '~/notifications';
import Consignees from './Consignees';
import { Consolidation, Deliveries } from 'Pages/Shipping';
import useTrusted from '~/trusted';
import EnvStripe from 'Components/EnvStripe';
import CustomerRequest from './CustomerRequest';
import Reports from './Reports';
import { RoutingTable } from '~/router';
import Tenders from './Tenders';
import Marketslice from './Marketslice';
import Dashboard from './Dashboard';
import Test from './test';
import Banner from './Banner';
import Users from './Users';
import { Futures } from './Futures';
import { Duplicate } from './Duplicate';

const Body = () => {
  const solution = useStoreState(state => state.auth.solution);
  const user = useStoreState(state => state.auth.user);

  const setParticipantToast = useStoreActions(
    state => state.auth.setParticipantToast
  );

  const location = useLocation();
  const history = useHistory();

  const prevLocation = usePreviousNormalLocation(location) || {
    pathname: ROUTES.home,
  };

  useTimezone(user.timezone);

  const isModal = checkIsModal(location.pathname);

  useEffect(() => {
    if (R.propOr(0, 'new_participants', solution) > 0) {
      const plural = solution.new_participants > 1;
      const toastId = toast(
        <>
          There {plural ? 'are' : 'is'} {solution.new_participants} new
          participant{plural && 's'} on the platform. Click here to review them.
        </>,
        {
          autoClose: false,
          onClick: () => {
            history.push('/participants?dismissed=0');
          },
        }
      );
      setParticipantToast(toastId);
    }
  }, [solution]);

  function handleModalClose() {
    // When modal is closed, jump to prevLocation, prev non-modal location.
    // TODO This might have issue for edge cases, need further exploring.
    history.push(prevLocation);
  }
  // TODO depends on user permission
  const modals = [
    {
      key: ROUTES.orderNew,
      path: ROUTES.orderNew,
      children: props => (
        <DrawerWrapper
          props={props}
          handleModalClose={handleModalClose}
          prevLocation={prevLocation}
          component={OrderNew}
        />
      ),
    },
    {
      key: ROUTES.account,
      path: ROUTES.account,
      children: props => (
        <DrawerWrapper
          props={props}
          handleModalClose={handleModalClose}
          wrapperProps={{ placement: 'right', id: 'account' }}
          component={Account}
        />
      ),
    },
    {
      key: ROUTES.orderEdit,
      path: ROUTES.orderEdit,
      children: props => (
        <DrawerWrapper
          props={props}
          handleModalClose={handleModalClose}
          component={OrderEdit}
        />
      ),
    },
  ];

  const routes = [Tenders];

  return (
    <>
      <section id="content" className="main-content" role="main">
        <main>
          <RoutingTable
            routes={routes}
            location={isModal ? prevLocation : location}
          >
            <Route path={[ROUTES.home, ROUTES.marketplace]} exact>
              <Market />
            </Route>
            <Route path={[ROUTES.overview]}>
              <Marketplace />
            </Route>
            <Route path={[ROUTES.dashboard]}>
              <Dashboard />
            </Route>
            <Route path={ROUTES.products}>
              <Products />
            </Route>
            <Route path={ROUTES.orders}>
              <Orders />
            </Route>
            <Route path={ROUTES.trades}>
              <Trades />
            </Route>
            <Route path={ROUTES.participants}>
              <Participants />
            </Route>
            <Route path={ROUTES.locations}>
              <Warehouses />
            </Route>
            <Route path={ROUTES.customers}>
              <Customers />
            </Route>
            <Route path={ROUTES.reports}>
              <Reports />
            </Route>
            <Route path={ROUTES.config}>
              <Config />
            </Route>
            <Route path={ROUTES.consignees}>
              <Consignees />
            </Route>
            <Route path={ROUTES.consolidation}>
              <Consolidation />
            </Route>
            <Route path={ROUTES.deliveries}>
              <Deliveries />
            </Route>
            <Route path={ROUTES.customerRequest}>
              <CustomerRequest />
            </Route>
            <Route path={ROUTES.users}>
              <Users />
            </Route>
            <Route path="/test">
              <Test />
            </Route>
            <Route path={ROUTES.routes}>
              <Routes />
            </Route>
          </RoutingTable>
        </main>
      </section>

      {/* modals, including drawers, are singletons and separated for transition animation.
Also, they can overlap previous normal routes. */}
      {modals.map(({ children, path, key, extra = {} }) => (
        <Route path={path} key={key} children={children} {...extra} />
      ))}

      <Route path="/preview/order/:id">
        <Modal close={handleModalClose} defaultFooter size="full">
          <OrderQuickView />
        </Modal>
      </Route>
      <Route path="/preview/trade/:id">
        <Modal close={handleModalClose} defaultFooter size="large">
          <LastTrade />
        </Modal>
      </Route>
      <Route path="/products/:id/quick">
        <Modal close={handleModalClose} defaultFooter size="full">
          <ProductQuickView />
        </Modal>
      </Route>
      <Route path="/products/:id/specifications">
        <Modal close={handleModalClose} defaultFooter size="medium">
          <ProductSpecifications />
        </Modal>
      </Route>
      <Route exact path={['/marketplace', '/marketplace/:productId']}>
        <Modal close={handleModalClose} defaultFooter size="full">
          <Marketslice />
        </Modal>
      </Route>

      <Route path="/futures">
        <Modal close={handleModalClose} defaultFooter size="medium">
          <Futures />
        </Modal>
      </Route>

      <Route path="/duplicate">
        <Modal close={handleModalClose} size="medium">
          <Duplicate close={handleModalClose} />
        </Modal>
      </Route>

      <ScrollTopButton />
    </>
  );
};

export default () => {
  const solution = useStoreState(state => state.auth.solution);
  const privacyCheckReq = useStoreState(state => state.auth.privacyCheckReq);

  useNotifications();
  useTrusted();

  if (!solution) return null;

  return (
    <>
      <Header />
      <Navbar />
      <EnvStripe />
      <Banner />
      {privacyCheckReq ? <PrivacyDialog /> : <Body />}
      <Footer />
    </>
  );
};
