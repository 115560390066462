import React, { Fragment, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import {
  ColorSquare,
  NoDataAvailable,
  colors,
  TooltipWrapper,
  useFilterTIData,
  useSharedFilter,
  useSharedTIData,
} from 'Pages/Dashboard';
import R from 'ramda';
import moment from 'moment-timezone';
import { Checkbox, Tooltip } from 'Components/nui';
import { scale } from '..';
import { useDeepCompareEffect, useToggle } from 'react-use';
import DropCheck from 'Components/nui/form/DropCheck';
import { useStoreState } from 'easy-peasy';

const styleById = {
  dashed: {
    strokeDasharray: '12, 6',
    strokeWidth: 2,
  },
  dotted: {
    strokeDasharray: '1, 6',
    strokeWidth: 2,
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
  },
  default: {
    strokeWidth: 2,
  },
};

const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color, style }) => (
    <path
      key={id}
      d={lineGenerator(
        data.map(d => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      stroke={color}
      style={styleById[style] || styleById.default}
    />
  ));
};

const indexProductList = [
  { key: 'cme-butter', label: 'CME - Butter' },
  { key: 'cme-cheese-barrel', label: 'CME - Cheese - Barrel' },
  { key: 'cme-cheese-block', label: 'CME - Cheese - Block' },
  { key: 'cme-dry-whey', label: 'CME - Dry - Whey' },
  {
    key: 'cme-non-fat-dry-milk-grade-a',
    label: 'CME - Non-Fat Dry Milk - Grade A',
  },
  {
    label: 'EEX - European Butter Index',
    key: 'eex-fabt',
  },
  {
    label: 'EEX - European Liquid Milk Index',
    key: 'eex-falm',
  },
  {
    label: 'EEX - European Whey Powder Index',
    key: 'eex-fawh',
  },
  {
    label: 'EEX - Skimmed Milk Powder Index',
    key: 'eex-fasm',
  },
  {
    label: 'EEX WECI - Cheddar Curd Index',
    key: 'eex-weci-chedder-curd',
  },
  {
    label: 'EEX WECI - Mild Cheddar Index',
    key: 'eex-weci-mild-cheddar',
  },
  {
    label: 'EEX WECI - Mozzarella Index',
    key: 'eex-weci-mozzarella',
  },
  {
    label: 'EEX WECI - Young Gouda Index',
    key: 'eex-weci-young-gouda',
  },
  {
    label: 'GDT - Anhydrous Milkfat',
    key: 'gdt-anhydrous-milkfat',
  },
  {
    label: 'GDT - Butter',
    key: 'gdt-butter',
  },
  {
    label: 'GDT - Buttermilk Powders',
    key: 'gdt-buttermilk-powders',
  },
  {
    label: 'GDT - Cheddar Cheese',
    key: 'gdt-cheddar-cheese',
  },
  {
    label: 'GDT - Skimmilk Powders',
    key: 'gdt-skimmilk-powders',
  },
  {
    label: 'GDT - Wholemilk Powders',
    key: 'gdt-wholemilk-powders',
  },
  {
    label: 'NDPSR - Butter',
    key: 'ndpsr-butter',
  },
  {
    label: 'NDPSR - Cheese',
    key: 'ndpsr-cheese',
  },
  {
    label: 'NDPSR - Dry Whey',
    key: 'ndpsr-dry-whey',
  },
  {
    label: 'NDPSR - Nonfat dry milk',
    key: 'ndpsr-nonfat-dm',
  },
];

const indexSolutionList = [
  'a5688d23-32b0-4b9d-9164-a4d8b96c2df5', // Darigold
  '9ad05be3-bef0-4a46-8d2b-bdc6fd736026', // MSG
  '75298463-018f-49dc-93c7-917498a0fe16', // Hilmar
  '05cf31b8-76b4-421e-96ff-7d1a3577c206', // Valley Milk
  'a9275f8c-0aaa-429d-83f3-2ba63c4014a9', // Arla
  '1599143f-7e94-493d-b759-6bcb53449c97',
  '3a50f8a6-b7de-440b-a5ab-7e66dbe37cf4',
  '7c0fd6ed-ed28-4ef9-894c-932842c2047a', // Tirlan
  'f9cef7a8-c3d3-423f-8acf-c59c63431a4b', // Valio
  '4f72fa5a-fa2a-4cb9-8822-6d7a17aa0009', // OCD
  'c429cff0-a32a-40b2-9243-71547ae65a3a', // NMEU
  '585a6acc-e3aa-4f4c-adce-e5ed9c6558cd', // NMNA
  'e01520d4-77e9-4162-bdbd-d55dcf41cce2', // AMPI
];

export default () => {
  const [filter] = useFilterTIData();
  const [data] = useSharedTIData();
  const [loading, toggle] = useToggle(true);

  const solution = useStoreState(state => state.auth.solution);

  const [showMin, setShowMin] = useToggle(false);
  const [showMax, setShowMax] = useToggle(false);
  const [showAvg, setShowAvg] = useToggle(true);
  const [showIndexPicker, setShowIndexPicker] = useToggle(false);

  const rawData = R.propOr([], 'product-price-by-time', data);

  const [chartData, setChartData] = useState([]);

  const [range] = useSharedFilter();
  const [indexes, setIndexes] = useState([]);

  let category = `${filter.currency} / ${filter.uom}`;

  const step = R.pathOr('1d', ['period', 'interval'], data);

  useDeepCompareEffect(() => {
    if (R.isEmpty(rawData)) {
      setChartData([]);
      return;
    }
    let thelist = [];
    setShowIndexPicker(false);
    rawData.forEach(record => {
      if (record.category && R.includes(solution.id, indexSolutionList))
        setShowIndexPicker(true);
      if (record.category && !R.includes(record.key, indexes)) {
        return;
      }
      const label = `${record.category ? record.category : ''} ${record.label}`;
      const colourList = colors[thelist.length % colors.length];
      if (showAvg) {
        thelist.push({
          id: label,
          color: record.color || colourList,
          label: label,
          data: record.data.map(item => ({
            x: moment(item.ts).format('YYYY-MM-DDTHH:mm:ss'),
            y: item.avg_p,
          })),
          style: 'default',
        });
      }
      if (showMin) {
        thelist.push({
          id: `${label} - Min`,
          color: record.color || colourList,
          label: `${label} - Min`,
          data: record.data.map(item => ({
            x: moment(item.ts).format('YYYY-MM-DDTHH:mm:ss'),
            y: item.min_p,
          })),
          style: 'dashed',
        });
      }
      if (showMax) {
        thelist.push({
          id: `${label} - Max`,
          color: record.color || colourList,
          label: `${label} - Max`,
          data: record.data.map(item => ({
            x: moment(item.ts).format('YYYY-MM-DDTHH:mm:ss'),
            y: item.max_p,
          })),
          style: 'dotted',
        });
      }
    });

    if (thelist.length > 0) {
      thelist.push({
        id: 'range',
        color: 'rgba(0,0,0,0)',
        label: '',
        data: [
          {
            x: range[0].format('YYYY-MM-DDTHH:mm:ss'),
            y: thelist[0].data[0]?.y,
          },
          {
            x: range[1].format('YYYY-MM-DDTHH:mm:ss'),
            y: thelist[0].data[0]?.y,
          },
        ],
      });
    }
    setChartData(thelist);
    toggle(false);
  }, [rawData, setChartData, showAvg, showMin, showMax, indexes]);

  return R.isEmpty(rawData) ? (
    !loading && (
      <div className="chart-container historic-prices">
        <h3 className="inline-block m-0 mr-10">
          Historic prices
          <Tooltip
            className="icon-help-circled"
            placement="bottom"
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                Shows trade prices over the selected timeframe for each selected
                product.
              </span>
            }
          />
        </h3>
        <NoDataAvailable />
      </div>
    )
  ) : (
    <div className="chart-container historic-prices">
      <h3 className="inline-block m-0 mr-10">
        Historic prices
        <Tooltip
          className="icon-help-circled"
          placement="bottom"
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              Shows trade prices over the selected timeframe for each selected
              product.
            </span>
          }
        />
      </h3>
      <div>
        {showIndexPicker && (
          <div className="filter-section">
            <label htmlFor="indexes">Indices</label>
            <DropCheck
              name="Indexes"
              title="All"
              options={indexProductList.map(item => [item.key, item.label])}
              value={indexes}
              onChange={(_, values) => {
                setIndexes(values);
              }}
            />
          </div>
        )}
        <div className="checks-inline">
          <Checkbox checked={showMin} onChange={setShowMin}>
            Min price
          </Checkbox>

          <Checkbox checked={showMax} onChange={setShowMax}>
            Max price
          </Checkbox>

          <Checkbox checked={showAvg} onChange={setShowAvg}>
            Average price
          </Checkbox>
        </div>
      </div>

      {
        <section className="mt-20">
          <div className="chart">
            <ResponsiveLine
              data={chartData}
              colors={{ datum: 'color' }}
              margin={{ top: 0, right: 0, bottom: 50, left: 60 }}
              enableGridX={false}
              xScale={{
                type: 'time',
                format: '%Y-%m-%dT%H:%M:%S',
                useUTC: false,
                precision: R.pathOr('day', [step, 'precision'], scale),
              }}
              xFormat="time:%Y-%m-%dT%H:%M:%S"
              yScale={{
                type: 'linear',
                stacked: false,
                min: 'auto',
              }}
              axisBottom={{
                format: R.pathOr('day', [step, 'format'], scale),
                tickValues: R.pathOr('day', [step, 'tickValues'], scale),
                // legend: R.pathOr('', [step, 'axisBottomLegend'], scale),
                legend: '',
                legendOffset: 40,
                legendPosition: 'middle',
                tickRotation: -30,
              }}
              axisLeft={{
                orient: 'left',
                legend: category,
                legendOffset: -60,
                legendPosition: 'middle',
              }}
              pointSize={8}
              // pointBorderWidth={2}
              pointBorderColor="white"
              pointLabelYOffset={-12}
              useMesh={true}
              tooltip={({ point }) => (
                <TooltipWrapper>
                  <ul>
                    <li>{point.serieId}</li>
                    <li>
                      <ColorSquare color={point.color} /> {point.color}
                    </li>
                    <li>
                      {moment(point.data.x).format(
                        R.pathOr('DD MMM', [step, 'tooltipFormat'], scale)
                      )}
                    </li>
                    <li>
                      {point.data.y} {}
                    </li>
                  </ul>
                </TooltipWrapper>
              )}
              // enablePoints={false}
              enableSlices="x"
              sliceTooltip={({ slice }) => {
                return (
                  <TooltipWrapper>
                    <div>
                      {slice.points &&
                        moment(slice.points[0].data.x).format(
                          R.pathOr('DD MMM', [step, 'tooltipFormat'], scale)
                        )}
                      <hr className="mt-2 mb-2" />
                    </div>
                    {slice.points.map(point => (
                      <Fragment key={point.id}>
                        {point.serieId !== 'range' && (
                          <ul key={point.id}>
                            <li className="block w-16">
                              <span className="inline-block mt-4">
                                <ColorSquare color={point.color} />
                              </span>
                            </li>
                            <li>
                              <strong className="all-black block pl-16 mt--20">
                                {point.serieId}
                              </strong>
                            </li>

                            <li className="block pl-16">
                              {point.data.y.toLocaleString()} {category}
                            </li>
                          </ul>
                        )}
                      </Fragment>
                    ))}
                  </TooltipWrapper>
                );
              }}
              layers={[
                // AlternateBackground,
                'grid',
                'axes',
                'areas',
                // AreaLayer,
                // 'lines',
                DashedLine,
                'crosshair',
                'slices',
                'points',
                'mesh',
              ]}
            />
          </div>

          <ul className="chart-legend">
            {chartData.map(item => (
              <Fragment key={item.label}>
                {item.id !== 'range' && (
                  <li key={item.label}>
                    <span className="color-block mr-2 mt-2">
                      <ColorSquare color={item.color} />
                    </span>{' '}
                    <span className="name mr-15">{item.label}</span>
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
        </section>
      }
    </div>
  );
};
