import type * as Models from '~/models/tenders';
import React from 'react';
import { useStoreState } from '~/store';
import { Popover } from 'Components/nui';
import { routesIncluded, getAddrInfo, getAddrChoice } from '~/utils';
import R from 'ramda';

type EtaErrorProps = {
  error: string;
  className?: string;
};
const EtaError = ({ error, className }: EtaErrorProps) => (
  <div key="new-eta" className="mb-10">
    <abbr title={'Estimated Time of Arrival'}>ETA</abbr>{' '}
    <Popover overlay={<span>Estimated Time of Arrival</span>}>
      <span className="popover-link">?</span>
    </Popover>
    <p>
      <strong className={className}>{error}</strong>
    </p>
    <hr />
  </div>
);

type IEta = Record<'order', Models.Order> &
  Record<'form', any> &
  Record<'schema', any>;
export const Eta = ({ order, form, schema }: IEta) => {
  const solution = useStoreState(state => state.auth.solution);
  const toaddr = !R.isNil(form.getFieldValue('toaddr'))
    ? form.getFieldValue('toaddr')
    : schema?.toaddr?.value;
  const division = !R.isNil(form.getFieldValue('division'))
    ? form.getFieldValue('division')
    : schema?.division?.value;
  const toaddrInfo =
    schema?.toaddr?.choices &&
    getAddrInfo(toaddr)(getAddrChoice(division)(schema.toaddr.choices));
  if (!solution.shippingroutes || solution.tenderlogic === 'purchase')
    return <></>;

  if (!toaddrInfo)
    return (
      <EtaError
        error={'Please select delivery location'}
        className="select-location-warning"
      />
    );

  const matchingRoutes = solution.shippingroutes.filter(
    route =>
      order?.location?.[route.type]?.id &&
      routesIncluded(
        [order.location[route.type].id],
        [Object.values(toaddrInfo[2])[0]]
      )(route)
  );

  if (!matchingRoutes.length)
    return (
      <EtaError
        error={`No shipping routes found`}
        className="select-location-warning"
      />
    );

  const sortedRoutes = matchingRoutes.sort(
    (route1, route2) => route1.duration - route2.duration
  );

  return (
    <div key="new-eta" className="mb-10 eta">
      <abbr title={'Estimated Time of Arrival'}>
        {matchingRoutes.length > 1 ? 'ETAs' : 'ETA'}
      </abbr>{' '}
      <Popover overlay={<span>Estimated Time of Arrival</span>}>
        <span className="popover-link">?</span>
      </Popover>
      {sortedRoutes.map(route => (
        <div key={route.id}>
          <strong className={'eta-from'}>
            From {route.source.name} To {route.destination.name}
          </strong>
          {' - '}
          {order.etd.to
            .clone()
            .add(route.duration, 'days')
            .format('DD MMM YYYY')}{' '}
          (ETD + {route.duration} day
          {route.duration > 1 && 's'})
        </div>
      ))}
      <hr />
    </div>
  );
};
