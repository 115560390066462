import type { Activity } from 'models.tender';
import * as api from '~/fetch';
import moment from 'moment-timezone';

type TenderId = Record<'tenderId', string>;

type ChangeData =
  | { loading: true; errors: string[]; data?: TenderActivity[] }
  | { loading: false; errors: string[]; data: TenderActivity[] };
type OnChange = (value: ChangeData) => void;

const POLL_INTERVAL = 15000;

class TenderActivity {
  private state: Activity;

  constructor(state: Activity) {
    this.state = state;
  }

  get id() {
    return this.state.id;
  }

  get name() {
    return this.state.name;
  }

  get lastSeen() {
    if (this.state.last_seen) return moment(this.state.last_seen);
    return undefined;
  }

  get delta() {
    if (this.lastSeen) return moment.duration(moment().diff(this.lastSeen));
    return undefined;
  }

  get status() {
    const delta = this.delta;
    if (!delta) return 'inactive';

    if (delta.asMinutes() > 1) return 'last-seen';
    return 'active';
  }

  get awayFor() {
    const delta = this.delta;
    if (delta) {
      if (delta.asHours() > 1) return delta.asHours().toFixed(0) + 'h';
      if (delta.asMinutes() > 1) return delta.asMinutes().toFixed(0) + 'm';
      return delta.asSeconds().toFixed(0) + 's';
    }
    return undefined;
  }
}

async function load(tender: string): Promise<Activity[]> {
  try {
    const response = await api.tenders.activity(tender);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        return data.tender.participants;
      },
      async default() {
        return [];
      },
    });
  } catch (error) {
    console.error('Activity error', error);
  }

  return [];
}

// TODO on redirect to view a tender, this requests /activity three times, for
// each redirect deeper down the tree. Could be solved by moving to a separate
// service or something.
export function observe() {
  return function (onChange: OnChange) {
    return function ({ tenderId = '' }: Partial<TenderId> = {}) {
      onChange({ loading: true as const, errors: [] });

      const loop = async () => {
        const data = await load(tenderId);
        onChange({
          loading: false as const,
          data: data.map(d => new TenderActivity(d)),
          errors: [],
        });
      };

      loop();
      let timer = setInterval(() => void loop(), POLL_INTERVAL);

      return () => void clearInterval(timer);
    };
  };
}
