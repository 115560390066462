import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { capitalize } from '~/utils';

interface IStatusBadge<S extends string> {
  status: S;
  children?: React.ReactNode;
}

function makeStatusDesc(status: string) {
  return {
    id: `status-badge-${status}`,
    defaultMessage: capitalize(status),
    description: `Status badge ${status} text`,
  };
}

type Renderer<S extends string> = (
  status: S,
  options: { formattedStatus: (defaultMessage: string) => React.ReactNode }
) => React.ReactNode;

export function makeStatusBadge<
  L extends string[],
  S extends L[number] = L[number]
>(
  statusList: L,
  render?: Renderer<S>,
  renderers: Partial<Record<S, Renderer<S>>> = {}
) {
  function StatusBadge({ status, children }: IStatusBadge<S>) {
    const { formatMessage } = useIntl();
    function formattedStatus(defaultMessage?: string) {
      if (defaultMessage)
        formatMessage({ ...makeStatusDesc(status), defaultMessage });
      return formatMessage(makeStatusDesc(status));
    }
    const childNodes = useMemo(() => {
      const renderer = renderers[status] || render;
      if (renderer)
        return renderer(status, {
          formattedStatus,
        });
      return children ? children : formattedStatus();
    }, [status, children, renderers]);

    if (!statusList.includes(status)) return null;

    return (
      <span className={classnames('nui-status-badge', status)}>
        {childNodes}
      </span>
    );
  }
  return Object.assign(StatusBadge, { statusList } as const);
}

export default makeStatusBadge([
  'active',
  'finished',
  'published',
  'finalised',
  'cancelled',
  'expired',
  'reverted',
  'traded',
  'busted',
  'working',
]);
