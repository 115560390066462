import React, { useEffect, useState } from 'react';
import { NoDataAvailable, useSharedCEData } from 'Pages/Dashboard';
import R from 'ramda';
import { Button, Input, Tooltip } from 'Components/nui';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { useToggle } from 'react-use';

export default () => {
  const [_data] = useSharedCEData();
  const rawData = R.pathOr([], ['companies_last_seen', 'companies'], _data);
  const [loading, toggle] = useToggle(true);

  const [data, setData] = useState([]);

  const keyColors = {
    'Active within last 7 days': 'green',
    'Active after last 7 days': 'amber',
    'Active after last 30 days': 'red',
    'Never visited platform': 'gray',
  };

  const [sortColumn, setSortColumn] = useState('seen');
  const [sortDirection, setSortDirection] = useState('down');
  const [q, setQ] = useState('');

  useEffect(() => {
    let thelist = rawData.map(item => ({
      ...item,
      key: item.company,
      moment: item.last_active_user_dt
        ? moment(item.last_active_user_dt)
        : null,
      momentOrder: item.last_order_dt ? moment(item.last_order_dt) : null,
      momentLastBid: item.last_tenderbid_dt
        ? moment(item.last_tenderbid_dt)
        : null,
    }));

    if (q !== '') {
      const sq = q.toLowerCase();
      thelist = thelist.filter(item =>
        R.pathOr('', ['company'], item).toLowerCase().includes(sq)
      );
      toggle(false);
    }

    if (sortColumn === 'company')
      thelist = R.sortWith([R.ascend(R.path(['company']))])(thelist);

    if (sortColumn === 'seen')
      thelist = R.sortWith([
        R.ascend(R.pathOr('1970-01-01', ['last_active_user_dt'])),
        R.ascend(R.path(['company'])),
      ])(thelist);

    if (sortColumn === 'seenOrder')
      thelist = R.sortWith([
        R.ascend(R.pathOr('1970-01-01', ['last_order_dt'])),
        R.ascend(R.path(['company'])),
      ])(thelist);

    if (sortColumn === 'lastBidOrder')
      thelist = R.sortWith([
        R.ascend(R.pathOr('1970-01-01', ['last_tenderbid_dt'])),
        R.ascend(R.path(['company'])),
      ])(thelist);

    if (sortDirection === 'down') thelist = R.reverse(thelist);

    setData(thelist);
  }, [rawData, sortColumn, sortDirection, q]);

  const setSort = column => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'down' ? 'up' : 'down');
    } else {
      setSortDirection('up');
      setSortColumn(column);
    }
  };

  const now = moment();

  return R.isEmpty(rawData) ? (
    !loading && (
      <div className="chart-container last-seen">
        <h3>
          Last active
          <Tooltip
            className="icon-help-circled"
            placement="bottom"
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                <span className="block pb-5">
                  Gives a view of when each company last had an active user
                  (last seen) and last placed an order on your Nui platform.
                </span>
                <span className="block nowrap relative">
                  <span className="last-seen-status gray" />
                  Never visited the platform
                </span>
                <span className="block nowrap relative">
                  <span className="last-seen-status red" />
                  Was active more than 30 days ago
                </span>
                <span className="block nowrap relative">
                  <span className="last-seen-status amber" />
                  Was active more than 7 days ago
                </span>
                <span className="block nowrap relative">
                  <span className="last-seen-status green" />
                  Was active in the last 7 days
                </span>
              </span>
            }
          />
        </h3>
        <NoDataAvailable />
      </div>
    )
  ) : (
    <div className="chart-container last-seen">
      <h3>
        Last active
        <Tooltip
          className="icon-help-circled"
          placement="bottom"
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              <span className="block pb-5">
                Gives a view of when each company last had an active user (last
                seen) and last placed an order on your Nui platform.
              </span>
              <span className="block nowrap relative">
                <span className="last-seen-status gray" />
                Never visited the platform
              </span>
              <span className="block nowrap relative">
                <span className="last-seen-status red" />
                Was active more than 30 days ago
              </span>
              <span className="block nowrap relative">
                <span className="last-seen-status amber" />
                Was active more than 7 days ago
              </span>
              <span className="block nowrap relative">
                <span className="last-seen-status green" />
                Was active in the last 7 days
              </span>
            </span>
          }
        />
      </h3>

      <section>
        <div className="search-fieldset w-300">
          <div className="nui-fieldset">
            <div className="input-holder search-holder">
              <Input
                type="search"
                value={q}
                onChange={e => setQ(e.target.value)}
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <span className="last-seen-status"></span>
                </th>
                <th>
                  <Button
                    className={classnames('icon-sort', 'sortem', {
                      [sortDirection]: sortColumn === 'company',
                    })}
                    onClick={() => setSort('company')}
                    htmlType="button"
                    type="simple"
                  >
                    Company
                  </Button>
                </th>
                <th>
                  <Button
                    className={classnames('icon-sort', 'sortem', {
                      [sortDirection]: sortColumn === 'lastBidOrder',
                    })}
                    onClick={() => setSort('lastBidOrder')}
                    htmlType="button"
                    type="simple"
                  >
                    Last tender bid
                  </Button>
                </th>
                <th>
                  <Button
                    className={classnames('icon-sort', 'sortem', {
                      [sortDirection]: sortColumn === 'seen',
                    })}
                    onClick={() => setSort('seen')}
                    htmlType="button"
                    type="simple"
                  >
                    Last seen
                  </Button>
                </th>
                <th>
                  <Button
                    className={classnames('icon-sort', 'sortem', {
                      [sortDirection]: sortColumn === 'seenOrder',
                    })}
                    onClick={() => setSort('seenOrder')}
                    htmlType="button"
                    type="simple"
                  >
                    Last order
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr key={item.key}>
                  <td>
                    {item.moment ? (
                      <>
                        {now.diff(item.moment, 'days') > 30 ? (
                          <span className="last-seen-status red" />
                        ) : (
                          <>
                            {now.diff(item.moment, 'days') > 7 ? (
                              <span className="last-seen-status amber" />
                            ) : (
                              <span className="last-seen-status green" />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <span className="last-seen-status gray" />
                    )}
                  </td>
                  <td>{item.company}</td>
                  <td>
                    {item.momentLastBid
                      ? item.momentLastBid.format('ll')
                      : 'n/a'}
                  </td>
                  <td>{item.moment ? item.moment.format('ll') : 'n/a'}</td>
                  <td>
                    {item.momentOrder ? item.momentOrder.format('ll') : 'n/a'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      <ul className="chart-legend">
        {Object.entries(keyColors).map(([item, value]) => (
          <li key={item}>
            <span className="color-block mr-2 mt-2">
              <span className={classnames('last-seen-status', value)} />
            </span>{' '}
            <span className="name mr-15">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
