import type * as Models from '~/models/tenders';
import React from 'react';
import { Link } from 'react-router-dom';
import { routeUrl, withPreload } from '~/router';
import { capitalize, getExportLabel, SolutionColumns } from '~/utils';
import classnames from 'classnames';
import * as Data from 'Components/Data';
import Quickbid from './Quickbid';
import Access, { can } from 'Components/Access';
import DisplayPopout from 'Components/DisplayPopout';
import { FieldVisible } from 'Components/FieldVisibility';
import ActionList from 'Components/ActionList';
import CancelTender from './Cancel';
import { Tooltip, Button, Modal, Alert } from 'Components/nui';
import { useMountedState } from '~/hooks';
import * as service from '~/services/tenders';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { useStoreState } from '~/store';
import R from 'ramda';

type TenderProp = Record<'tender', Models.Tender>;
type OrderProp = Record<'order', Models.Order>;
type CounterProp = Record<'counter', Models.Counter>;
type ChildrenProp = Partial<Record<'children', React.ReactNode>>;
type ClassProp = Partial<Record<'className', string>>;

interface Props {
  children?: React.ReactNode;
  className?: string;
}

interface TenderId {
  tender: { id: string };
}

const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

export const TenderOwned = ({ tender }: TenderProp) =>
  tender.owned ? (
    <Tooltip
      title={
        <span className="icon-tooltip">
          <span className="icon-info-circled" />
          This is your own tender
        </span>
      }
    >
      <span className="own-tender" />
    </Tooltip>
  ) : null;

export const OwnCounter = ({ counter }: CounterProp) =>
  counter.isowner ? (
    <span className="own-counter">My {counter.ordertype}</span>
  ) : counter.companybid ? (
    <span className="company-counter">Company {counter.ordertype}</span>
  ) : null;

export const IsAutobid = ({ counter }: CounterProp) =>
  counter.isAuto ? (
    <span className="my-autobid">Auto {counter.ordertype}</span>
  ) : null;

export const TenderStatus = ({ tender }: TenderProp) => (
  <span className={classnames('status', tender.status)}>{tender.status}</span>
);

type ITenderLink = TenderId & Props;
export const TenderLink = ({ tender, children, ...props }: ITenderLink) => (
  <Link to={routeUrl('tender-view', { tenderId: tender.id })} {...props}>
    {children}
  </Link>
);

const infoFmt = 'LL HH:mm';

export const TenderInfo = ({ tender }: TenderProp) => (
  <div className="tender2-details-list">
    <Data.List>
      {tender?.owner?.name && (
        <Data.Item title="Owner">{tender.owner.name}</Data.Item>
      )}
      <Data.Item title="Start">
        <Data.Datetime value={tender.start} fmt={infoFmt} />
      </Data.Item>
      <Data.Item title="Finish">
        <Data.Datetime value={tender.finish} fmt={infoFmt} />
        {!!tender.extramins && ` (+${tender.extramins} mins)`}
      </Data.Item>
      <Data.Item title="Method">
        <Data.TenderMethod info={tender} />
      </Data.Item>
      <Data.Item title="Duration">
        <Data.Duration value={tender.duration} />
      </Data.Item>
      <Data.Item title={capitalize(tender.ordername.order) + 's'}>
        {tender.orders.total}
      </Data.Item>
      {!!tender.participants && (
        <Data.Item title="Participants">
          {tender.participants.included.toFixed(0)}
        </Data.Item>
      )}
      {!!tender.files?.length && (
        <Data.Item title="Documents">
          <Data.Files files={tender.files} />
        </Data.Item>
      )}
    </Data.List>
  </div>
);

const actions = [
  'edit',
  'orders_add',
  'participants_edit',
  'publish',
  'notify',
  'finalise',
  'delete',
  'analyse',
];
export const TenderActions = ({ tender }: TenderProp) => (
  <Access to={actions} model={tender}>
    <ActionList
      className="tender-actions button nui-button nui-secondary has-dd"
      title="Actions"
    >
      <Access to="edit" model={tender}>
        <ActionList.Item>
          <Link
            to={routeUrl('tender-edit-details', {
              tenderId: tender.id,
              mode: 'edit',
            })}
          >
            Edit tender
          </Link>
        </ActionList.Item>
      </Access>
      <Access to="orders_add" model={tender}>
        <ActionList.Item>
          <Link
            to={routeUrl('tender-edit-orders', {
              tenderId: tender.id,
              mode: 'edit',
            })}
          >
            Add {tender.ordername.order}(s)
          </Link>
        </ActionList.Item>
      </Access>
      <Access to="participants_edit" model={tender}>
        <ActionList.Item>
          <Link
            to={routeUrl('tender-edit-participants', {
              tenderId: tender.id,
              mode: 'edit',
            })}
          >
            Edit participants
          </Link>
        </ActionList.Item>
      </Access>
      <Access to={['publish', 'notify']} model={tender}>
        <ActionList.Item>
          <Link
            to={routeUrl('tender-edit-review', {
              tenderId: tender.id,
              mode: 'edit',
            })}
          >
            {tender.status === 'planning'
              ? 'Publish tender'
              : 'Notify participants'}
          </Link>
        </ActionList.Item>
      </Access>
      <Access to="finalise" model={tender}>
        <ActionList.Item className="tender-action-finalise">
          <Link to={routeUrl('tender-trades', { tenderId: tender.id })}>
            Finalise tender
          </Link>
        </ActionList.Item>
      </Access>
      <Access to="delete" model={tender}>
        <ActionList.Item>
          <CancelTender tender={tender} />
        </ActionList.Item>
      </Access>
      <Access to="analyse" model={tender}>
        <ActionList.Item className="tender-action-analyse">
          <Link to={routeUrl('tender-report', { tenderId: tender.id })}>
            View statistics
          </Link>
        </ActionList.Item>
      </Access>
    </ActionList>
  </Access>
);

export const TenderActivity = withPreload({
  route: 'tender-view',
  preload: service.activity.observe(),
  size: 'tiny',
})(({ data: users }) => {
  const num = users?.map(x => x).filter(u => u.status === 'active').length;

  return (
    <DisplayPopout
      className="tender-activity"
      title={
        <span>
          <span className="icon-group">
            {!!num && <span className="badge">{num}</span>}
          </span>
          <span className="title">
            Activity <span className="arrow"></span>
          </span>
        </span>
      }
    >
      <p className="p-0">
        <strong className="all-black">Participants&apos; activity</strong>
      </p>
      <hr className="mt-5 mb-10" />
      <ul
        className={classnames('divisions', {
          'gt-20': users?.length > 20,
          'gt-40': users?.length > 40,
          'gt-60': users?.length > 60,
          'gt-80': users?.length > 80,
          'gt-100': users?.length > 100,
        })}
      >
        {users
          .map(x => x)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(u => (
            <li
              key={u.id}
              title={
                u.status === 'last-seen'
                  ? `Seen ${u.awayFor} ago`
                  : capitalize(u.status)
              }
            >
              {u.name}
              <span className={classnames('status', u.status)}>
                {u.status === 'last-seen' && u.awayFor}
              </span>
            </li>
          ))}
      </ul>
    </DisplayPopout>
  );
});

type IOrderTicket = TenderProp & OrderProp;
export const OrderTicket = ({ tender, order }: IOrderTicket) => {
  const best = order.counters.best;
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);

  return (
    <div className="tender-order-ticket">
      <div className="ticket-column ticket-order-details">
        <Data.List className="tender2-summary-fields">
          {order.productionmonth && (
            <Data.Item title="Production month">
              {order.productionmonth.display}
            </Data.Item>
          )}
          {order.futures?.length && (
            <Data.Item title="Futures">
              <Link onClick={e => void e.stopPropagation()} to="/futures">
                View futures pricing
              </Link>
            </Data.Item>
          )}
        </Data.List>
        <Data.Attributes value={order.attributes} />
        <Data.List>
          <Data.Item>
            {tender.participating && best ? (
              <Data.PriceTicker title="Best price" value={best.price} />
            ) : (
              <Data.PriceTicker
                title="Start price"
                value={order.startprice}
                onZero="Any"
              />
            )}
          </Data.Item>
          {order.startprice.index && (
            <Data.Item>
              <Data.TickerAlt title="Price index">
                <span className="unit">
                  <Data.IndexSummary
                    index={order.startprice.index}
                    date={moment(order.startprice.indexdate)}
                  />
                </span>
              </Data.TickerAlt>
            </Data.Item>
          )}
          <Data.Item>
            <Data.VolumeTicker title="Total volume" value={order.volume} />
          </Data.Item>
          {order.stripvolume && (
            <Data.Item>
              <Data.VolumeTicker
                title="Delivery volume"
                value={order.stripvolume}
              />
            </Data.Item>
          )}
        </Data.List>
        <Data.List className="tender2-summary-fields">
          <FieldVisible name="loading">
            <Data.Item title="Loading details">
              <Data.LoadingDetail value={order.loading} />
            </Data.Item>
          </FieldVisible>
          <FieldVisible name="etd">
            <Data.Item title="ETD">
              <Data.ETD value={order.etd} />
            </Data.Item>
          </FieldVisible>
          {order.location && (
            <FieldVisible name="location">
              <Data.Item title={`Delivery ${order.delivery}`}>
                <Data.ShortLocation value={order.location} />
              </Data.Item>
            </FieldVisible>
          )}
          {!!order.files?.length && (
            <FieldVisible name="attachments">
              <Data.Item title="Attachments">
                <Data.Files files={order.files} />
              </Data.Item>
            </FieldVisible>
          )}
          {!R.isNil(order.exportable) && (
            <FieldVisible name="exportable">
              <Data.Item
                title={
                  getExportLabel(
                    order.ordertype,
                    'exportable'
                  )(solutionColumns as SolutionColumns) ?? 'Exportable'
                }
                className="exportable-snippet"
              >
                <Data.Bool value={!!order.exportable} />
              </Data.Item>
            </FieldVisible>
          )}
          {order.comment && (
            <FieldVisible name="comment">
              <Data.Item title="Comments" className="comment-snippet">
                <Data.Note value={order.comment} />
              </Data.Item>
            </FieldVisible>
          )}
        </Data.List>
      </div>
      <div className="ticket-column ticket-counter-details">
        <div className="counter-details">
          {tender.participating ? (
            <CounterSummary tender={tender} order={order} />
          ) : best ? (
            <>
              <Data.PriceTicker title="Best price" value={best.price} />
              <Data.VolumeTicker value={best.stripvolume ?? best.volume} />
              {!order.startprice.index && (
                <Data.PriceTicker
                  title="Total value"
                  className="total"
                  value={best.askingValue}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

type ICounterSummary = TenderProp & OrderProp;
const CounterSummary = ({ tender, order }: ICounterSummary) => {
  const mine = order.counters.mine;

  return (
    <>
      {!!mine && (
        <>
          <Data.PriceTicker value={mine.price} />
          <Data.VolumeTicker value={mine.stripvolume ?? mine.volume} />
          {!order.startprice.index && (
            <Data.PriceTicker
              title="Total value"
              className="total"
              value={mine.askingValue}
            />
          )}
          {mine.hasAuto && (
            <Data.PriceTicker
              title={'Auto ' + tender.ordername.counter}
              className={classnames('total', {
                index: !!order.startprice.index,
              })}
              value={mine.autobid}
            />
          )}
        </>
      )}
      <CounterActions tender={tender} order={order} />
    </>
  );
};

type ICounterRanking = TenderProp &
  OrderProp & { detail: boolean; children?: React.ReactNode };
export const CounterRanking = ({
  tender,
  order,
  detail,
  children,
}: ICounterRanking) => {
  const mine = order.counters.mine;

  const plural = order.counters.length !== 1 ? 's' : '';

  const blind = mine?.rank === 0;
  const leading = mine?.rank === 1;
  const losing = mine?.rank > 1;
  const none = !order.counters.length;
  const autobid = mine && !tender.started();

  const text = mine ? (
    autobid ? (
      <>Auto {tender.ordername.counter} placed</>
    ) : blind ? (
      <>Blind tender</>
    ) : (
      <>Ranking</>
    )
  ) : none ? (
    <>No {tender.ordername.counter}s</>
  ) : (
    <>
      {order.counters.length} {tender.ordername.counter + plural}
    </>
  );

  return (
    <div
      className={classnames('rank', {
        'num-counters': !mine,
        blind,
        leading,
        losing,
        none,
        autobid,
      })}
    >
      {detail && text}
      {children}
      {tender.started() && !!mine && <Data.Rank value={mine.rank} />}
    </div>
  );
};

type ICounterActions = TenderProp & OrderProp;
export const CounterActions = ({ tender, order }: ICounterActions) => {
  const mine = order.counters.mine;
  const editUrl = routeUrl('tender-counter', {
    tenderId: tender.id,
    orderId: order.id,
  });

  if (can('edit', mine) || can('add', order.counters)) {
    if (mine?.rank > 1) return <Quickbid tender={tender} order={order} />;

    return (
      <Link
        className="add-counter-bid nui-button nui-reverse nui-beveled"
        to={editUrl}
        onClick={stopPropagation}
      >
        {mine ? 'Edit' : 'Place'} {!tender.started() ? 'auto' : ''}{' '}
        {tender.ordertype.counter}
      </Link>
    );
  }

  return <></>;
};

export const OrderDetails = ({ order, className }: OrderProp & ClassProp) => {
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);

  return (
    <div className={classnames('order-details-item', className)}>
      <Data.List className="tender-order-detail-2">
        <Data.Item className="order-created" title="Created">
          <Data.Datetime value={order.created} />
        </Data.Item>
        <Data.Item className="order-price">
          <Data.PriceTicker
            title="Start price"
            value={order.startprice}
            onZero="Any"
          />
        </Data.Item>
        {order.reserve.price.val && (
          <Data.Item className="reserve-price">
            <Data.PriceTicker
              value={order.reserve.price}
              title="Reserve price"
            />
          </Data.Item>
        )}
        <Data.Item className="order-volume">
          <Data.VolumeTicker title="Total volume" value={order.volume} />
        </Data.Item>
        {order.stripvolume && (
          <Data.Item className="order-volume">
            <Data.VolumeTicker
              title="Delivery volume"
              value={order.stripvolume}
            />
          </Data.Item>
        )}
      </Data.List>

      <ResAlert reserve={order.reserve} />

      <Data.List className="tender-order-detail-3">
        {order.startprice.index && (
          <Data.Item title="Price index">
            <Data.IndexSummary
              index={order.startprice.index}
              date={moment(order.startprice.indexdate)}
              className="font-inherit"
            />
          </Data.Item>
        )}
        {order.productionmonth && (
          <Data.Item title="Production month">
            {order.productionmonth.display}
          </Data.Item>
        )}
        {!!order.files?.length && (
          <Data.Item className="attachments" title="Attachments">
            <Data.Files files={order.files} className="icon-file-pdf" />
          </Data.Item>
        )}
        <Data.Item className="etd" title="ETD">
          <Data.ETD value={order.etd} />
        </Data.Item>
        <Data.Item className="loading-details" title="Loading details">
          <Data.LoadingDetail value={order.loading} />
        </Data.Item>
        {!!order.variants.length && (
          <Data.Item className="variants" title="Product variants">
            <Data.ProductVariants variants={order.variants} />
          </Data.Item>
        )}
        {order.exportable != null && (
          <Data.Item
            className="exportable"
            title={solutionColumns?.exportable?.label || 'Exportable'}
          >
            <Data.Bool value={order.exportable} />
          </Data.Item>
        )}
        {order.freightavailable != null && (
          <Data.Item className="freightavailable" title="Additional freight">
            <Data.Bool value={order.freightavailable} />
          </Data.Item>
        )}
        {!!order.origins?.length && (
          <Data.Item className="origins" title="Origin">
            {order.origins.map(x => x.name).join(', ')}
          </Data.Item>
        )}
        {!!order.places?.length && (
          <Data.Item className="places" title="Location">
            {order.places.map(x => x.name).join(', ')}
          </Data.Item>
        )}
        {!!order.incoterms?.length && (
          <Data.Item className="incoterms" title="Incoterm">
            {order.incoterms.map(x => x.desc).join(', ')}
          </Data.Item>
        )}
        {!!order.docs &&
          Object.entries(order.docs).map(([doc, value]) => (
            <Data.Item
              key={`doc-${doc}`}
              title={
                solutionColumns?.[doc]?.label ||
                (doc === 'gfsi' ? 'GFSI' : capitalize(doc)) + ' docs'
              }
            >
              <Data.Bool value={value} />
            </Data.Item>
          ))}
        {order.location && (
          <Data.Item className="delivery" title={`Delivery ${order.delivery}`}>
            <Data.Location value={order.location} className="hide-label" />
          </Data.Item>
        )}
        {order.comment && (
          <Data.Item className="comments stacked" title="Comments">
            <Data.Note value={order.comment} />
          </Data.Item>
        )}
      </Data.List>
    </div>
  );
};

type ICounterDetails = CounterProp & { className?: string };
export const CounterDetails = ({ counter, className }: ICounterDetails) => {
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  return (
    <Data.List className={classnames('counter-details-item', className)}>
      {!!counter.rank && (
        <Data.Item title="Ranking" className="rank">
          <Data.Rank value={counter.rank} />
        </Data.Item>
      )}
      {counter.updated ? (
        <Data.Item title="Updated" className="order-created">
          <Data.Datetime value={counter.updated} />
        </Data.Item>
      ) : (
        !!counter.created && (
          <Data.Item title="Created" className="order-created">
            <Data.Datetime value={counter.created} />
          </Data.Item>
        )
      )}
      <Data.Item className="order-price">
        <Data.PriceTicker value={counter.price} />
      </Data.Item>
      {counter.displayedPrice && (
        <Data.Item className="order-price">
          <Data.PriceTicker
            title="Displayed price"
            value={counter.displayedPrice}
          />
        </Data.Item>
      )}
      <Data.Item className="order-volume">
        <Data.VolumeTicker value={counter.volume} />
      </Data.Item>
      {counter.stripvolume && (
        <Data.Item className="order-volume">
          <Data.VolumeTicker value={counter.stripvolume} />
        </Data.Item>
      )}
      {counter.hasAuto && (
        <Data.Item className="order-value" title="Auto bid">
          <Data.PriceTicker title="" value={counter.autobid} />
        </Data.Item>
      )}
      {!counter.executedValue.index && (
        <Data.Item
          className="order-value"
          title={capitalize(counter.ordertype) + ' value'}
        >
          <Data.PriceTicker title="" value={counter.askingValue} />
        </Data.Item>
      )}
      {counter.owner && (
        <Data.Item title="Division" className="division">
          {counter.owner}
        </Data.Item>
      )}
      {counter.creator && (
        <Data.Item title="Creator" className="creator">
          {counter.creator}
        </Data.Item>
      )}
      {counter.variant && (
        <Data.Item title="Variant" className="variant">
          <Data.ProductVariants variants={[counter.variant]} />
        </Data.Item>
      )}
      {counter.fromaddr && (
        <Data.Item title="Delivery from" className="delivery-from">
          <Data.Location value={counter.fromaddr} />
        </Data.Item>
      )}
      {counter.toaddr && (
        <Data.Item title="Delivery to" className="delivery-to">
          <Data.Location value={counter.toaddr} />
        </Data.Item>
      )}
      {counter.incoterm && (
        <Data.Item title="Incoterm" className="incoterm">
          {counter.incoterm}
        </Data.Item>
      )}
      {!R.isNil(counter.exportable) && (
        <Data.Item
          title={R.pathOr(
            'Exportable',
            [
              'exportable',
              counter.ordertype === 'bid' ? 'buy' : 'sell',
              'label',
            ],
            solutionColumns
          )}
          className="exportable"
        >
          <Data.Bool value={counter.exportable} />
        </Data.Item>
      )}
      {counter.exportdocs !== undefined && (
        <Data.Item
          title={R.pathOr(
            'Export docs',
            [
              'exportdocs',
              counter.ordertype === 'bid' ? 'buy' : 'sell',
              'label',
            ],
            solutionColumns
          )}
          className="exportdocs"
        >
          <Data.Required
            value={counter.exportdocs}
            notRequired={counter.ordertype === 'bid'}
          />
        </Data.Item>
      )}
      {counter.heattreatedpallets !== undefined && (
        <Data.Item
          title="Heat-treated pallets required"
          className="heattreatedpallets"
        >
          <Data.Required
            value={counter.heattreatedpallets}
            notRequired={counter.ordertype === 'bid'}
          />
        </Data.Item>
      )}
      {counter.exportcountry && (
        <Data.Item title="Destination" className="exportcountry">
          {counter.exportcountry.name}
        </Data.Item>
      )}
      {counter.callofftime && (
        <Data.Item title="Call off time" className="callofftime">
          {counter.callofftime}
        </Data.Item>
      )}
      {counter.comment && (
        <Data.Item title="Comments" className="comments stacked">
          <Data.Note value={counter.comment} />
        </Data.Item>
      )}
      {counter.trade && (
        <>
          <Data.Item title="Trade" className="trade">
            <Link to={`/trades/${counter.trade.id}`}>{counter.trade.pid}</Link>
          </Data.Item>
          <Data.Item title="Trade volume" className="trade-volume">
            <Data.VolumeTicker title="" value={counter.executed} />
          </Data.Item>
          {!counter.executedValue.index && (
            <Data.Item title="Trade value" className="trade-value">
              <Data.PriceTicker title="" value={counter.executedValue} />
            </Data.Item>
          )}
        </>
      )}
    </Data.List>
  );
};

export const OrderActions = ({ tender, order }: OrderProp & TenderProp) => (
  <DisplayPopout className="order-actions">
    <ul>
      <Access to="edit" model={order}>
        <li>
          <Link
            onClick={stopPropagation}
            to={routeUrl('tender-edit-orders', {
              tenderId: tender.id,
              orderId: order.id,
              mode: 'edit',
            })}
            className="icon-pencil"
            title={'Edit ' + order.ordertype}
          >
            Edit {order.ordertype}
          </Link>
        </li>
        <li>
          <Link
            onClick={stopPropagation}
            to={routeUrl('tender-duplicate-order', {
              tenderId: tender.id,
              orderId: order.id,
            })}
            className="icon-clone"
            title={'Duplicate ' + order.ordertype}
          >
            Duplicate {order.ordertype}
          </Link>
        </li>
      </Access>
      <Access to="delete" model={order}>
        <li>
          <DeleteOrder tender={tender} order={order} className="icon-trash">
            Delete {order.ordertype}
          </DeleteOrder>
        </li>
      </Access>
    </ul>
  </DisplayPopout>
);

type IDeleteOrder = TenderProp & OrderProp & ClassProp & ChildrenProp;
export const DeleteOrder = ({
  tender,
  order,
  className,
  children,
}: IDeleteOrder) => {
  const [visible, setVisible] = useMountedState(false);
  const [loading, setLoading] = useMountedState(false);

  const toggle = (v: boolean) => () => void setVisible(v);

  const onDelete = async () => {
    setLoading(true);

    const result = await service.actions.deleteOrder(tender.id, order.id);
    if (result?.success) {
      toast.success(
        <>
          {capitalize(order.ordertype)} for {order.product.name} removed
          successfully
        </>
      );
    } else {
      toast.error(
        <>
          An error occurred while removing {order.ordertype} for{' '}
          {order.product.name}
        </>
      );
    }

    setVisible(false);
    setLoading(false);
  };

  return (
    <>
      <Button
        type="buttonlink"
        className={classnames('cancel-order', className)}
        onClick={toggle(true)}
        loading={loading}
        disabled={loading}
      >
        {children}
      </Button>
      {visible && (
        <Modal close={toggle(false)} size="small">
          <h1>Confirm</h1>
          <p>
            Are you sure you want to delete this {order.ordertype} for{' '}
            <strong className="all-black">{order.product.name}</strong>?
          </p>
          <hr />
          <div className="button-set">
            <Button
              type="warning"
              onClick={onDelete}
              loading={loading}
              disabled={loading}
            >
              Delete
            </Button>
            <Button type="simple" onClick={toggle(false)}>
              Close
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export const TenderCountdown = ({ tender }: TenderProp) => {
  const target = tender.target;
  const remaining = tender.isRunning()
    ? target!.diff(moment(), 'seconds')
    : 100;

  return (
    <span
      className={classnames('tender-timer', {
        'finish': tender.isRunning(),
        'start': tender.willStart(),
        's60-sec': remaining < 60,
        's30-sec': remaining < 30,
        's10-sec': remaining < 10,
        'no-value': !target,
        'icon-clock': !!target,
      })}
    >
      {target ? (
        <>
          <strong className="all-black">
            {tender.willStart() ? 'Starts in' : 'Ends in'}
          </strong>{' '}
          <span className="nowrap">
            <Data.Countdown target={target} />
          </span>
        </>
      ) : tender.status === 'planning' ? (
        'Tender is not yet published'
      ) : ['cancelled', 'stopped'].includes(tender.status) ? (
        'Tender was cancelled'
      ) : tender.finalised ? (
        <>
          Finalised on <Data.Datetime value={tender.finalised} />
        </>
      ) : tender.ended() ? (
        'Tender has finished'
      ) : (
        'Checking...'
      )}
    </span>
  );
};

type IEditCounterButton = TenderProp & OrderProp;
export const EditCounterButton = ({ tender, order }: IEditCounterButton) => (
  <>
    {tender?.participating &&
      (order.counters.mine ? (
        <Access to="edit" model={order.counters.mine}>
          <Link
            to={routeUrl('tender-counter', {
              tenderId: tender.id,
              orderId: order.id,
            })}
            className="edit-counter nui-button nui-secondary mt-20"
          >
            Edit {tender.ordertype.counter}
          </Link>
        </Access>
      ) : (
        <Access to="add" model={order.counters}>
          <Link
            to={routeUrl('tender-counter', {
              tenderId: tender.id,
              orderId: order.id,
            })}
            className="edit-counter nui-button nui-secondary mt-20"
          >
            Place {!tender.started() ? 'auto' : ''} {tender.ordertype.counter}
          </Link>
        </Access>
      ))}
  </>
);

export const OrderIcons = ({ order }: OrderProp) => {
  const solution = useStoreState(state => state.auth.solution);

  return (
    <div className="order-icons has-icons">
      {!!order.files?.length && (
        <Data.Icon
          icon="icon-attach-circled"
          className="is-bundle"
          tooltip={`This ${order.ordertype} has ${order.files.length} attachment(s)`}
        />
      )}
      {order.exportable && (
        <Data.Icon
          icon="icon-ship"
          className="has-ship"
          tooltip={
            <>
              <strong className="all-black block">Exportable</strong>
              <span className="block">This product is exportable.</span>
              {!!solution?.exportprice?.price && (
                <span>Extra costs and terms may apply.</span>
              )}
            </>
          }
        />
      )}
      {order.comment && (
        <Data.Icon
          icon="icon-comment"
          className="has-comment"
          tooltip={order.comment}
        />
      )}
    </div>
  );
};

export const MyVariant = ({ order }: OrderProp) =>
  order.counters.mine?.variant && !order.counters.mine.variant.isBase ? (
    <span className="my-variant">{order.counters.mine.variant.name}</span>
  ) : null;

interface Reserve {
  reserve: {
    reserve_met: boolean | undefined;
  };
}

export const ResTag = ({ reserve }: Reserve) => (
  <div className="res-tag">
    {R.isNil(reserve.reserve_met) ? (
      <></>
    ) : (
      <span
        className={classnames(
          'inline-block',
          reserve.reserve_met ? 'res-icon' : 'icon-attention'
        )}
      >
        {reserve.reserve_met && 'RES'}
      </span>
    )}
  </div>
);

export const ResAlert = ({ reserve }: Reserve) => {
  return R.isNil(reserve.reserve_met) ? (
    <></>
  ) : reserve.reserve_met ? (
    <Alert className="mb-20" type="success" hasicon>
      Reserve price met
    </Alert>
  ) : (
    <Alert className="mb-20" type="warning" hasicon>
      Reserve price not met
    </Alert>
  );
};

interface IRelist {
  order: { id: string; product: { id: string }; ordertype: 'bid' | 'offer' };
}
export const Relist = ({ order }: IRelist) => (
  <Link
    to={`/orders/${order.ordertype}?product=${order.product.id}&tender-order=${order.id}`}
    className="button primary"
  >
    Relist
  </Link>
);
