import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Link } from 'react-router-dom';
import { useSharedProduct } from '.';

export default () => {
  const [product] = useSharedProduct();

  return (
    <>
      <h1 className="inline mt-10 mr-20 mb-0">
        {product ? (
          <>
            Edit product <span className="smaller">{product.name}</span>
          </>
        ) : (
          'New product'
        )}
      </h1>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="products" />
        </li>
        <li>
          <Link to="/products" title="Back to the main products page">
            Products
          </Link>
        </li>
        <li>{product ? 'Edit' : 'New'}</li>
      </ul>
    </>
  );
};
