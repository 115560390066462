import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import View from './View';
import AddUser from './View/AddUser';
import { Provider, usePageState } from './Context';

export default () => {
  const context = usePageState();

  return (
    <Provider value={context}>
      <Switch>
        <Route path={['/users/:id/edit', '/users/add']}>
          <AddUser />
        </Route>
        <Route path="/users">
          <View />
        </Route>
        <Route>
          <strong>Error 404</strong>
        </Route>
      </Switch>
    </Provider>
  );
};
