import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const LoadingDescColumn = ColumnTree.defineNode(
  'loading-desc',
  _context => ({
    title: '',
    key: 'loading',
    className: 'col-loading',
    render: (text, record, index) => (
      <div className="td-content">
        <span className="tiny-label">
          <FormattedMessage
            id="marketplace-table-heading-loadingconfig"
            description="Table heading"
            defaultMessage="Loading configuration, repeated for cell headings"
          />
        </span>
        {record.loading.desc}
      </div>
    ),
  })
);
