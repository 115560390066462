import type { IRouteName, IRouteProps } from 'router';
import React, { useState, useEffect, useRef } from 'react';
import Loading from 'Components/nui/Loading';
import { usePathTools } from './utils';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

type Err = string;
type Fmt<D> =
  | { loading: true; errors: Err[]; data?: D }
  | { loading: false; errors: Err[]; data: D };
export type Preloadable<D, P> = (
  onChange: (value: Fmt<D>) => void
) => (params?: P) => () => void;

type Children<D, P> = (config: P & Record<'data', D>) => React.ReactElement;
type PreloadParams<N extends IRouteName> = IRouteProps<N> &
  Record<'options', qs.ParsedQs>;

interface IWithPreload<D, N extends IRouteName> {
  route?: IRouteName;
  exact?: boolean;
  preload: Preloadable<D, PreloadParams<N>>;
  size?: 'hide' | 'tiny' | 'small' | 'medium' | 'large' | 'huge';
}
export function withPreload<D, N extends IRouteName>({
  route,
  exact,
  preload,
  size,
}: IWithPreload<D, N>) {
  return function <P>(Component: Children<D, P>) {
    return (props: P) => {
      const [state, setState] = useState<Fmt<D>>({
        data: undefined,
        loading: true,
        errors: [],
      });

      const { matchParams } = usePathTools();
      const location = useLocation();

      const onChange = useRef(preload(setState));

      useEffect(() => {
        const params = route ? matchParams(route, exact) : {};
        const options = qs.parse(location.search.slice(1));

        return onChange.current({ ...params, options });
      }, [location.pathname, location.search]);

      if (state.errors.length) return <>Error</>;
      if (state.loading) {
        if (size === 'hide') return null;
        return <Loading size={size} />;
      }

      return <Component data={state.data} {...props} />;
    };
  };
}

interface IUsePreloadable<D, N extends IRouteName> {
  route: N;
  preload: Preloadable<D, PreloadParams<N>>;
  exact?: boolean;
}
export function usePreloadable<D, N extends IRouteName>({
  route,
  preload,
  exact,
}: IUsePreloadable<D, N>) {
  const [state, setState] = useState<Fmt<D>>({ loading: true, errors: [] });

  const { matchParams } = usePathTools();
  const { pathname, search } = useLocation();

  const onChange = useRef(preload(setState));

  useEffect(() => {
    const params = route ? matchParams(route, exact) : {};
    const options = qs.parse(search.slice(1));

    return onChange.current({ ...params, options });
  }, [pathname, search]);

  return state;
}
