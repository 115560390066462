import React from 'react';
import Details from './Details';
import Forms from './Forms';
import History from './History';
import { useSharedTrade } from '..';
import { useStoreState } from '~/store';
import R from 'ramda';

export default () => {
  const [trade] = useSharedTrade();
  const auth = useStoreState(R.prop('auth'));

  if (!trade) return null;

  return (
    <div className="post-trade-details">
      <div className="nui-row">
        <div className="relative">
          <div className="trade-details-section">
            <Details />
          </div>
          <div className="post-trade-section">
            {(auth.hasRole('manager') || auth.hasRole('trader')) && (
              <div className="post-trade-actions">
                <Forms />
              </div>
            )}
            <div className="post-trade-history">
              <History />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
