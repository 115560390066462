import React, { useRef, useEffect } from 'react';
import { authService } from './services/auth-service';
import { api } from './api';
import { toast } from 'react-toastify';
import R from 'ramda';
import { Button } from 'Components/nui';

export default () => {
  const ref = useRef(null);

  const clickYes = async () => {
    toast.dismiss(ref.current);
    const response = await api.postData('/user/trusted-browsers', {});
    authService.saveTrusted(
      R.pathOr('', ['trusted-browser', 'code'], response)
    );
  };

  const clickNo = () => {
    toast.dismiss(ref.current);
    authService.saveTrusted('');
  };

  useEffect(() => {
    const trusted = authService.loadTrusted();
    if (trusted === null) {
      ref.current = toast(
        <>
          <h3>New browser</h3>
          <p>Do you want to add this browser to your trusted list?</p>
          <p>
            Enabling this will allow you to &quot;auto sign in from emails&quot;
            with this browser.
          </p>
          <Button
            onClick={clickYes}
            type="primary"
            size="small"
            className="bold mr-10"
          >
            Yes
          </Button>
          <Button onClick={clickNo} size="small">
            No
          </Button>
        </>,
        {
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          autoClose: false,
        }
      );
    }
  }, []);
};
