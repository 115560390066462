import React, { useRef, useEffect } from 'react';
import { api } from './api';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useStoreState } from './store';
import { useStoreActions } from 'easy-peasy';
import R from 'ramda';

async function poll(since) {
  try {
    const response = await api.request({
      url: 'user/notifications',
      headers: {
        'If-Modified-Since': since.toString(),
      },
    });
    switch (response.status) {
      case 200: {
        return response.data.notifications;
      }
      default: {
        return [];
      }
    }
  } catch (error) {
    return [];
  }
}

function getUrl(notification) {
  const { entities } = notification;

  if (entities.tender) return '/tenders';
  if (entities.trade) return `/preview/trade/${entities.trade}`;
  if (entities.order) {
    if (notification.type === 'order_counter') {
      return `/marketplace?order=${entities.order}&tab=counters`;
    }
    return `/marketplace?order=${entities.order}`;
  }

  return false;
}

export function useNotifications() {
  const history = useHistory();
  const since = useRef(moment.utc());
  const solution = useStoreState(state => () => state?.auth?.solution || '');
  const changeSolution = useStoreActions(state => state.auth.changeSolution);
  const getSolution = useStoreState(
    state => solutionId =>
      R.indexBy(R.prop('id'), state.auth.solutions)[solutionId]
  );
  const getProducts = useStoreState(state => state.auth.solutionComProducts);

  useEffect(() => {
    const id = setInterval(async () => {
      const now = moment.utc();
      const notifs = await poll(since.current);
      since.current = now;
      for (const n of notifs) {
        if (n.entities) {
          const url = getUrl(n, getProducts(n.solution));
          toast(
            <>
              <h3>
                {getSolution(n.solution)?.name}&mdash;{n.label}
              </h3>
              <p>{n.subject}</p>
            </>,
            {
              onClick: () => {
                if (n.solution !== solution().id) changeSolution(n.solution);
                if (url) history.push(url);
              },
              pauseOnFocusLoss: true,
              pauseOnHover: true,
            }
          );
        }
      }
    }, 30e3);

    return () => {
      clearInterval(id);
    };
  }, []);
}
