import React from 'react';
import classnames from 'classnames';
import R from 'ramda';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const LocationsColumn = ColumnTree.defineNode('locations', {
  predicate({ solutionColumns }) {
    return 'locations' in solutionColumns && solutionColumns.locations
      ? true
      : false;
  },
  make({ solutionColumns, sortDirection, sortColumn, setSort }) {
    return {
      title: (
        <div>
          <a
            href="#locations"
            className={classnames('icon-sort', 'sortem', {
              [sortDirection]: sortColumn === 'locations',
            })}
            onClick={() => setSort('locations')}
          >
            {R.propOr('Location', 'label', solutionColumns.locations)}
          </a>
        </div>
      ),
      children: [
        {
          title: '',
          key: 'locations',
          className: 'col-locations',
          render: (text, record, index) => (
            <div className="td-content">
              <span className="tiny-label">
                {R.propOr('Location', 'label', solutionColumns.locations)}
              </span>
              {record.locations &&
                record.locations.map(item => item.name).join(', ')}
            </div>
          ),
        },
      ],
    };
  },
});
