import React, { useState } from 'react';
import { UsingSchema, useSchema, useCustomerRequest } from './EditContext';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'util';
import { Button, Alert, Form } from 'Components/nui';
import { toast } from 'react-toastify';
import R from 'ramda';
import { useHistory } from 'react-router-dom';
import { NextStep } from 'Components/nui/Wizard';

interface IStep {
  index: number;
  title: string;
  desc?: string;
}
const Step = ({ index, title, desc }: IStep) => (
  <div className="form-step">
    <h3 className="numbered outside">{title}</h3>
    {desc && <p className="description">{desc}</p>}
  </div>
);

export default () => {
  return (
    <div className="content-address-details">
      <UsingSchema schema="address">
        <AddressForm />
      </UsingSchema>
    </div>
  );
};

function useFormWrapper({ form, schema, customer }: any) {
  const fields = [
    { render: () => <Step key="step-1" index={1} title="Physical address" /> },
    {
      name: 'phyaddr1',
      label: 'Street',
      type: 'Input',
    },
    {
      name: 'phyaddr2',
      label: 'Suburb',
      type: 'Input',
    },
    {
      name: 'phycity',
      label: 'City',
      type: 'Input',
    },
    {
      name: 'phystate',
      label: 'State',
      type: 'Input',
    },
    {
      name: 'phyzip',
      label: 'ZIP code',
      type: 'Input',
    },
    {
      name: 'phycountry',
      label: 'Country',
      type: 'Select',
      choices: R.pipe(
        R.propOr([], 'choices'),
        R.map(([value, label]) => ({ value, label, key: value }))
      ),
    },
    { render: () => <Step key="step-2" index={2} title="Postal address" /> },
    {
      render: () => (
        // Please add checkbox here if postal address is the same, see http://www.farbeyond.co.nz/nui-html/customer-request3.html
        <Alert key="copy-phys" hasicon className="mb-20">
          <Button
            key="copy-address"
            type="simple"
            size="small"
            htmlType="button"
            className="copy-address inline p-0"
            onClick={() => {
              const {
                phyaddr1,
                phyaddr2,
                phycity,
                phystate,
                phyzip,
                phycountry,
              } = form.getFieldsValue();
              form.setFieldsValue({
                postaddr1: phyaddr1,
                postaddr2: phyaddr2,
                postcity: phycity,
                poststate: phystate,
                postzip: phyzip,
                postcountry: phycountry,
              });
            }}
          >
            Copy address details
          </Button>
          <span className="pl-5">from the physical address.</span>
        </Alert>
      ),
    },
    {
      name: 'postaddr1',
      label: 'Street',
      type: 'Input',
    },
    {
      name: 'postaddr2',
      label: 'Suburb',
      type: 'Input',
    },
    {
      name: 'postcity',
      label: 'City',
      type: 'Input',
    },
    {
      name: 'poststate',
      label: 'State',
      type: 'Input',
    },
    {
      name: 'postzip',
      label: 'ZIP code',
      type: 'Input',
    },
    {
      name: 'postcountry',
      label: 'Country',
      type: 'Select',
      choices: R.pipe(
        R.propOr([], 'choices'),
        R.map(([value, label]) => ({ value, label, key: value }))
      ),
    },
  ];

  const { address = {} } = customer;
  const initialData = {
    ...address,
    phycountry: address?.phycountry?.id,
    postcountry: address?.postcountry?.id,
  };

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

const BaseForm = ({ form, schema }: any) => {
  const { customer, actions, validation } = useCustomerRequest();
  const formWrapper = useFormWrapper({ form, schema, customer });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e: any) {
    e.preventDefault();

    setLoading(true);

    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      const data = formWrapper.serialize();
      const customer = await actions.edit('address', data);
      history.push(`/customer-requests/${customer.id}/links`);
      toast('Address updated successfully', { type: 'success' });
    } catch (err) {
      if (err?.response && err.response?.status === 400) {
        const validation = err.response?.data?.errors || [];
        if (validation.length) {
          formWrapper.setErrors(validation[0]?.description || []);
        } else {
          // wtf
          toast('Form did not validate.', { type: 'error' });
          console.error('form did not validate', err);
        }
      } else {
        toast(
          'There was an error while submitting your form. Please try again later.',
          { type: 'error' }
        );
      }
      setLoading(false);
    }

    window.scrollTo(0, 0);
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit}>
        <div className="form-steps-container">
          {formWrapper.render()}
          <div className="sticky-btm button-set mt-20 trio">
            <Button
              type="primary"
              disabled={loading}
              loading={loading}
              htmlType="submit"
            >
              {validation.hasAddress ? 'Update' : 'Save and continue'}
            </Button>
            <NextStep />
          </div>
        </div>
      </Form>
    </div>
  );
};

type IWrappedForm = (props: any) => React.ReactElement;
const WrappedForm: IWrappedForm = Form.create()(BaseForm) as any;

const AddressForm = () => {
  const schema = useSchema('address');
  return <WrappedForm schema={schema} />;
};
