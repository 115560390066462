import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import History from './History';
import Analytics from './Analytics';
import Overview from './Overview';
import Edit from './Edit';
import View from './View';
import { routeUrl, withRoute, RoutingTable, withPreload } from '~/router';
import Breadcrumb from './Breadcrumb';
import Header from './Header';
import Tabs from './Tabs';
import * as service from '~/services/tenders';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

export default withRoute({
  name: 'tenders',
  head: { title: 'Tenders' },
})(() => {
  const msg = useRef<React.ReactText>();

  useEffect(() => {
    document.body.classList.add('page-tenders');
    return () => void document.body.classList.remove('page-tenders');
  }, []);

  useEffect(() => {
    return service.events.onError(({ type }) => {
      const { errortype } = service.events;
      const errors = [errortype.overviewpoll, errortype.overviewload];
      if (errors.includes(type) && !msg.current) {
        const id = toast.error(
          <>
            A network error has occurred. The tender module has disconnected.
          </>,
          { autoClose: false }
        );
        msg.current = id;
      }
    });
  }, []);

  useEffect(
    service.events.onReconnect(() => {
      if (msg.current) {
        toast.dismiss(msg.current);
        msg.current = undefined;
        toast.success(<>The tender module has reconnected</>);
      }
    }),
    []
  );

  return (
    <div className="tenders2">
      <div className="full-page-header tender2-header">
        <Header />
        <Breadcrumb />
      </div>
      <div className="full-page-header pb-0">
        <Tabs />
        <RoutingTable
          routes={[View, History, Analytics, Edit, Overview]}
          redirect={routeUrl('tender-overview')}
        />
      </div>
    </div>
  );
});

export const TendersLink = () => {
  return (
    <li className="tenders-link">
      <TenderCount />
      <NavLink
        to={routeUrl('tenders')}
        activeClassName="selected"
        className="icon-hammer"
        title="Tenders"
      >
        <FormattedMessage
          id="navbar-tab-tenders"
          description="Top level/navbar tenders link"
          defaultMessage="Tenders"
        />
      </NavLink>
    </li>
  );
};

const TenderCount = withPreload({
  preload: service.observe.overview(),
  size: 'hide',
})(({ data: tenders }) => {
  const active = tenders.filter(t => t.isRunning()).length;
  const published = tenders.filter(t => t.willStart()).length;

  if (active || published)
    return (
      <span className="activity-status">
        {!!active && (
          <>
            <FormattedMessage
              id="navbar-tab-tenders-sub-active"
              description="Total {count} of active tenders"
              defaultMessage="{count} active"
              values={{ count: active }}
            />
            {!!published && ' | '}
          </>
        )}
        {!!published && (
          <FormattedMessage
            id="navbar-tab-tenders-sub-published"
            description="Total {count} of published tenders"
            defaultMessage="{count} published"
            values={{ count: published }}
          />
        )}
      </span>
    );

  return <></>;
});
