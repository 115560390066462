import type * as Struct from 'struct';
import type * as Models from 'models';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { store } from '~/store';
import { capitalize } from '~/utils';

const $access: Struct.SAccess = Symbol.for('access') as Struct.SAccess;

export class Base<T extends Models.Base> {
  protected state: T;

  constructor(state: T) {
    this.state = state;
  }

  get id() {
    return this.state.id;
  }

  get [$access](): { [key: string]: boolean } {
    return {};
  }
}

export class Price {
  private state: Struct.IndexedPrice;
  constructor(price: Struct.IndexedPrice) {
    this.state = price;
  }

  get val() {
    return this.state.val;
  }
  get currency() {
    return this.state.currency;
  }
  get step() {
    if (this.state.index) return this.state.index.step;
    return this.state.step;
  }
  get unit() {
    return this.state.unit;
  }
  get index() {
    return this.state.index;
  }
  get indexdate() {
    return this.state.indexdate;
  }
  get rel() {
    return this.state.rel;
  }

  new(price: Partial<Struct.IndexedPrice>) {
    return new Price({ ...this.state, ...price });
  }

  // TODO Enforce currency, convert units (cents to dollars etc)
  add({ val = 0 }: Partial<Struct.Price>) {
    const current = this.val || 0;
    const next = val || 0;
    const res = (next * 1e7 + current * 1e7) / 1e7;
    return this.new({ val: res });
  }
}

export class Volume {
  private state: Struct.Volume;
  private deliveries?: Struct.Delivery;

  constructor(volume: Struct.Volume, deliveries?: Struct.Delivery) {
    this.state = volume;
    this.deliveries = deliveries;
  }

  get val() {
    return this.state.val;
  }
  get unitdesc() {
    return this.state.unitdesc;
  }
  get unit() {
    return this.state.unit;
  }
  get step() {
    return this.state.step;
  }
  get executed() {
    return this.state.executed || 0;
  }
  get pending() {
    return this.state.pending;
  }

  get delivered() {
    if (!this.deliveries) return undefined;

    const frequency = this.deliveries.deliveryfrequency;

    const period =
      frequency === 'daily' ? 'day' : R.replace('ly', '', frequency);

    return {
      val: this.state.delivery_total!,
      deliveries: this.deliveries.deliverytotal,
      frequency,
      period,
    };
  }

  new(volume: Partial<Struct.Volume>, deliveries?: Struct.Delivery) {
    return new Volume({ ...this.state, ...volume }, deliveries);
  }
}

interface ValueState {
  product: string;
  price: Struct.Price;
  volume: Struct.Volume;
  loading: Struct.ProductLoading;
}
export class Value implements Struct.Price {
  private state: ValueState;

  constructor(state: ValueState) {
    this.state = state;
  }

  get val() {
    const product = store
      .getState()
      .auth.products.find(p => p.id === this.state.product);
    if (!product) return 0;

    const { unit: volunit, priceunit, loadingunit } = product;
    const {
      price: { val: price },
      volume: { val: volume },
      loading,
    } = this.state;

    const pf = 1 / priceunit.factor;
    const vf = volunit.factor;

    const packagingfactor = (loading.packing_load || 1) / loading.qty;
    const loadingfactor = loadingunit.rel / volunit.rel;

    const val =
      price === null
        ? null
        : price *
          pf *
          volume *
          vf *
          (volunit.name === 'packaging' ? packagingfactor : loadingfactor);

    return val;
  }

  get currency() {
    return this.state.price.currency;
  }

  get unit() {
    return this.state.price.currency;
  }

  get step() {
    return 0.01;
  }

  get rel() {
    return this.state.price.rel;
  }
}

interface IDelivery {
  frequency: 'daily' | 'weekly' | 'monthly';
  total: number;
}
export class Etd {
  private state: Struct.Etd;
  private delivery?: IDelivery;

  constructor(etd: Struct.Etd, delivery?: IDelivery) {
    this.state = etd;
    this.delivery = delivery;
  }

  get from() {
    return moment(this.state.from);
  }

  get to() {
    return moment(this.state.to);
  }

  get deliveries() {
    return this.delivery;
  }

  format() {
    const start = this.from;
    const end = this.to;

    const fmtYear = start.year() === end.year() ? false : 'YYYY';
    const fmtMth = !fmtYear && start.month() === end.month() ? false : 'MMM';
    const fmtStart = ['D', fmtMth, fmtYear].filter(x => !!x).join(' ');
    return `${this.from.format(fmtStart)} - ${this.to.format('D MMM YYYY')}`;
  }

  toString() {
    return this.format();
  }
}

export class Location extends Base<Models.Location> {
  get name() {
    return this.state.description;
  }

  get street() {
    return this.state.addr1;
  }

  get suburb() {
    return this.state.addr2;
  }

  get city() {
    return this.state.city;
  }

  get fedstate() {
    return this.state.state;
  }

  get country() {
    return this.state.country;
  }

  get postcode() {
    return this.state.zip;
  }

  get port() {
    return this.state.port;
  }

  get zone() {
    return this.state.zone;
  }

  address() {
    return [this.street, this.suburb, this.city, this.postcode, this.fedstate]
      .filter(x => !!x)
      .join(', ');
  }
}

export class Attribute {
  private state: { name: string; value: Struct.OrderAttr[] };

  constructor(name: string, value: Struct.OrderAttr[]) {
    this.state = { name, value };
  }

  get name() {
    return capitalize(this.state.name);
  }

  get length() {
    return this.state.value.length;
  }

  get value() {
    return this.state.value
      .map(({ name, created }) => ({
        name,
        created: moment(created),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  toString() {
    return this.state.value
      .map(v => v.name)
      .sort()
      .join(' | ');
  }
}
