import { IBanner } from '~/store/types';
import { TimeSpan, PastPresentFuture } from '~/utils/timespan';

export type I1 = 'published' | 'unpublished';
export type I2 = PastPresentFuture;
export type BannerStatus =
  | 'scheduled'
  | 'draft'
  | 'live'
  | 'paused'
  | 'expired';

const STATUS_MATRIX: Readonly<Record<I1, Record<I2, BannerStatus>>> = {
  published: {
    past: 'expired',
    present: 'live',
    future: 'scheduled',
  },
  unpublished: {
    past: 'expired',
    present: 'paused',
    future: 'draft',
  },
};

export function getStatus(
  banner: Pick<IBanner, 'start' | 'end' | 'ispublished'>
): BannerStatus {
  const ts = new TimeSpan(banner.start, banner.end);
  const timeStatus = ts.pastPresentFuture();
  const publishedStatus = banner.ispublished ? 'published' : 'unpublished';
  return STATUS_MATRIX[publishedStatus][timeStatus];
}
