import React from 'react';
import { useToggle } from 'react-use';
import { Button } from 'Components/nui';
import { FormattedMessage } from 'react-intl';

export default ({ order }) => {
  if (!order || !order.counterparties) return null;

  const [on, toggle] = useToggle(order.counterparties.length < 4);

  return (
    <>
      <h2>
        {order.counterparties.length === 1 ? 'Counterparty' : 'Counterparties'}
      </h2>

      <ul className="personal-details">
        {on ? (
          order.counterparties.map(item => (
            <li className="reversed" key={item.id}>
              <strong>
                <FormattedMessage
                  id="counterparties-field-name-label"
                  description="Label for 'Name' field on Counterparties"
                  defaultMessage="Name"
                />
              </strong>{' '}
              <span>{item.name}</span>
            </li>
          ))
        ) : (
          <>
            {order.counterparties.slice(0, 3).map(item => (
              <li className="reversed" key={item.id}>
                <strong>
                  <FormattedMessage
                    id="counterparties-field-name-label"
                    description="Label for 'Name' field on Counterparties"
                    defaultMessage="Name"
                  />
                </strong>{' '}
                <span>{item.name}</span>
              </li>
            ))}
            <li className="reversed">
              <Button htmlType="button" type="buttonlink" onClick={toggle}>
                <FormattedMessage
                  id="counterparties-field-showmore-label"
                  description="Label for 'showmore' field on Counterparties"
                  defaultMessage="Show {counterparties} more"
                  values={{ counterparties: order.counterparties.length - 3 }}
                />
              </Button>
            </li>
          </>
        )}
      </ul>
    </>
  );
};
