import React from 'react';
import { WithId } from '../../types';
import Actions from './Actions';
import PublishSwitch from './PublishSwitch';

export default function ControlBar({ id }: WithId) {
  return (
    <div className="banner-control-bar">
      <PublishSwitch id={id} />
      <Actions id={id} />
    </div>
  );
}
