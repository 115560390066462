import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useToggle, useClickAway } from 'react-use';

type ClassProp = Partial<Record<'className', string>>;
type NodeProp<N extends string> = Record<N, React.ReactNode>;
type ChildrenProp = Partial<NodeProp<'children'>>;
type TitleProp = NodeProp<'title'>;

type ICrumb = ClassProp &
  TitleProp & { url: string; hint?: string; active: boolean };
export const Crumb = ({ className, url, hint, active, title }: ICrumb) => (
  <li className={classnames(className, { active })}>
    <Link to={url} title={hint}>
      {title}
    </Link>
  </li>
);

export const Items = ({ className, children }: ClassProp & ChildrenProp) => (
  <ul className={classnames('nui-bc', className)}>{children}</ul>
);

type IRoot = TitleProp & ClassProp & ChildrenProp & Record<'url', string>;
export const Root = ({ title, url, className, children }: IRoot) => {
  const [open, toggle] = useToggle(false);
  const ref = useRef(null);

  useClickAway(ref, () => void toggle(false));

  return (
    <li
      className={classnames('bc-root-entry', className)}
      onMouseEnter={() => void toggle(true)}
    >
      <span className={classnames('nui-bc-root', className)}>
        <Link className="configuration-links inline relative pr-10" to={url}>
          <span className="open-configuration-dd">{title}</span>
        </Link>
        <ul ref={ref} className={classnames('configuration-options', { open })}>
          {children}
        </ul>
      </span>
    </li>
  );
};

export default { Items, Root, Crumb };
