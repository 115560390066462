import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Edit from './Edit';
import Routes from './Routes';

export default () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={[`${url}/:id/edit/:area`, `${url}/:id/edit`, `${url}/new`]}>
        <Edit />
      </Route>

      <Route>
        <Routes />
      </Route>
    </Switch>
  );
};
