import type * as Responses from 'endpoints.base';

import * as api from './api';

type CounterSchema = Responses.Schema & { message?: string };
export async function counterSchema(orderId: string, counterId: string = '') {
  const url = [`/orders/${orderId}/counters`, counterId, 'schema']
    .filter(x => !!x.length)
    .join('/');

  return await api.get<CounterSchema>({ url, method: 'GET' });
}

interface ICounter {
  order: string;
  counter?: string;
  formdata: FormData;
}
export async function updateCounter({ order, counter, formdata }: ICounter) {
  if (counter)
    return await api.modify({
      url: `/orders/${order}/counters/${counter}`,
      method: 'put',
      formdata,
    });
  return await api.modify({
    url: `/orders/${order}/counters`,
    method: 'post',
    formdata,
  });
}

export async function withdrawCounter(order: string, counter: string) {
  return await api.remove<void>({
    url: `/orders/${order}/counters/${counter}`,
  });
}

type CounterTrade = { message: string; trade: never };
export async function tradeCounter(order: string, counter: string) {
  return await api.modify<CounterTrade>({
    url: `/orders/${order}/counters/${counter}`,
    method: 'PATCH',
    data: { ping: 'pong' },
  });
}

type Respond = { decline: boolean; reason: string };
export async function counterRespond(
  order: string,
  counter: string,
  { decline, ...data }: Respond
) {
  const url = `/orders/${order}/counters/${counter}`;
  const method = decline ? 'DELETE' : 'PATCH';

  return await api.modify({ url, method, data });
}

type ClientUsers = { choices: [string, string][] };
export async function clientUsers(party: string) {
  return await api.get<ClientUsers>({ url: `/client-users/${party}` });
}
