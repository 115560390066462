import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'Components/nui';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const LegendTypeColumn = ColumnTree.defineNode(
  'legend-type',
  ({ productById }) => ({
    title: (
      <span>
        <FormattedMessage
          id="marketplace-table-subheading-ordertype"
          description="Table sub-heading, order type e.g. ([My] offer|bid)"
          defaultMessage="Type"
        />
      </span>
    ),
    key: 'type',
    className: 'col-product-type-alt',
    render: (text, record, index) => (
      <div className="td-content">
        <div className="col-product-type">
          <>
            <span
              className={classnames(`type-${record.type}`, {
                'my': record.is_owner && !record.broker?.id,
                'private': record.isprivate,
                'counteronly': record.counteronly,
                'disabled': productById(record.product.id) === null,
                'private-group':
                  (record.counterparties && record.counterparties.length > 1) ||
                  record.ismultiprivate,
              })}
            >
              {record.is_owner && 'My '}
              {record.counteronly && 'SFC '}
              {record.type}
              {record.broker?.id && (
                <span className="broker">
                  <Tooltip
                    className="icon-help-circled"
                    placement="top"
                    title={
                      <span className="icon-tooltip">
                        <span className="icon-info-circled" />
                        Broker order
                      </span>
                    }
                  />
                </span>
              )}
              {record.isprivate && (
                <Tooltip
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled" />
                      {record.is_owner && 'My '} Private order
                    </span>
                  }
                >
                  <span className="private-icon">
                    {record.isprivate &&
                    ((record.counterparties &&
                      record.counterparties.length > 1) ||
                      record.ismultiprivate)
                      ? 'pg'
                      : 'p'}
                  </span>
                </Tooltip>
              )}
            </span>
          </>
        </div>
      </div>
    ),
  })
);
