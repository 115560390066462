import React from 'react';
import Table from './Table';
import Helmet from 'Components/Helmet';
import Header from 'Pages/Market/Header';
import { useStoreState } from 'easy-peasy';

export default () => {
  const mode = useStoreState(state => state.marketplace.mode);

  return (
    <>
      <Helmet>
        <title>Market overview</title>
      </Helmet>

      <Header />

      <div className={`marketplace timeline show-in-${mode}s`}>
        <div className="time-content">
          <Table />
        </div>
      </div>
    </>
  );
};
