import React from 'react';
import { useAsync, createStateContext } from 'react-use';
import { useParams } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import Header from './Header';
import Content from './Content';
import Helmet from 'Components/Helmet';
import Actions from './Actions';
import { toast } from 'react-toastify';
import { api } from '~/api';
import { Loading } from 'Components/nui';

export const [useSharedCustomer, SharedCustomerProvider] = createStateContext();

const Loader = () => {
  const { id } = useParams();
  const solution = useStoreState(state => state.auth.solution);
  const [customer, setCustomer] = useSharedCustomer();

  useAsync(async () => {
    try {
      const params = { solution: solution.id };
      const data = await api.getData2(`/customers/${id}`, params);
      setCustomer(data.customer);
      return data;
    } catch (error) {
      toast.error(
        'Something went wrong while fetching customer details. Please try again.'
      );
    }
  }, [id]);

  if (!customer) return <Loading size="large" />;

  return (
    <Helmet>
      <title>
        {customer.name} ({customer.shortcode})
      </title>
    </Helmet>
  );
};

export default () => (
  <SharedCustomerProvider>
    <Loader />
    <Header />
    <Actions />
    <Content />
  </SharedCustomerProvider>
);
