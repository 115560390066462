import React, { useState } from 'react';
import InfoForm from './InfoForm';
import AttachmentForm, { FilesModal } from './AttachmentForm';
import classNames from 'classnames';
import ProvideForm from './FreightForms/ProvideForm';
import AcceptForm from './FreightForms/AcceptForm';
import RequestForm from './FreightForms/RequestForm';
import ProvideDiscountForm from './DiscountForms/ProvideDiscountForm';
import AcceptDiscountForm from './DiscountForms/AcceptDiscountForm';
import RequestDiscountForm from './DiscountForms/RequestDiscountForm';
import { useSharedTrade } from '..';
import moment from 'moment-timezone';
import R from 'ramda';
import FNumber from 'Components/FNumber';
import { FormattedMessage } from 'react-intl';

export default () => {
  const [trade] = useSharedTrade();

  const isSeller = R.pathOr(false, ['offer', 'is_owner'], trade);
  const isBuyer = R.pathOr(false, ['bid', 'is_owner'], trade);

  const fStatus = trade.freightquote?.status || null;

  const poNumber = trade?.info?.find(i => i.type.id === 0);

  const [opened, setOpened] = useState(null);

  const LiWrapper = ({ code, name, extra, status, children }) => (
    <li
      className={classNames('accordion-item', {
        open: opened === code,
      })}
    >
      <h4
        onClick={e => {
          if (e.target !== e.currentTarget) return;
          setOpened(opened !== code ? code : null);
        }}
      >
        {name} {extra}{' '}
        {status && <span className="item item-status right">{status}</span>}
      </h4>
      {opened === code && <div className="details">{children}</div>}
    </li>
  );

  return (
    <>
      <div className="content">
        <h3>
          <FormattedMessage
            id="viewtrade-tradeactions-header"
            description="Header for Trade Actions in View trade"
            defaultMessage="Actions"
          />
        </h3>
        <p>
          <FormattedMessage
            id="viewtrade-tradeactions-helptext"
            description="Helpttext for Trade Actions in View trade"
            defaultMessage="Please see the options below and submit any required details in order to complete the trade."
          />
        </p>
      </div>
      <div>
        <ul className="accordion">
          {trade.freightavailable && (
            <>
              {isSeller ? (
                <LiWrapper
                  code="freight"
                  name={
                    fStatus ? (
                      <FormattedMessage
                        id="viewtrade-tradeactions-seller-field-freight-label"
                        description="Label for 'Freight' field in Trade Actions in View trade as seller"
                        defaultMessage="Freight"
                      />
                    ) : (
                      <FormattedMessage
                        id="viewtrade-tradeactions-seller-field-addfreight-label"
                        description="Label for 'Add freight' field in Trade Actions in View trade as seller"
                        defaultMessage="Add freight"
                      />
                    )
                  }
                  status={fStatus && <span className={fStatus}>{fStatus}</span>}
                >
                  {(!fStatus || fStatus === 'requested') && <ProvideForm />}

                  {['accepted', 'declined', 'pending'].includes(fStatus) && (
                    <>
                      <FormattedMessage
                        id="viewtrade-tradeactions-seller-field-freightquote-helptext-total"
                        description="Helptext for total freight cost in Trade Actions in View trade as seller"
                        defaultMessage="Total freight costs is"
                      />{' '}
                      <strong className="all-black">
                        <FNumber
                          value={R.path(
                            ['freightquote', 'quote', 'rate'],
                            trade
                          )}
                          decimalCount={2}
                        />{' '}
                        {trade.price.currency}
                      </strong>
                      .
                      <br />
                      {fStatus === 'pending' ? (
                        <>
                          <FormattedMessage
                            id="viewtrade-tradeactions-seller-field-freightquote-helptext-success"
                            description="Helptext for successsful quote submitted in Trade Actions in View trade as seller"
                            defaultMessage="Quote has been sent to the buyer."
                          />
                        </>
                      ) : (
                        <>
                          <FormattedMessage
                            id="viewtrade-tradeactions-seller-field-freightquote-helptext-quote"
                            description="Helptext for 'Quote was' in Trade Actions in View trade as seller"
                            defaultMessage="Quote was {quote_accepted, select, null {declined} other {accepted}}."
                            values={{
                              quote_accepted: R.path([
                                'freightquote',
                                'quote',
                                'accepted',
                              ]),
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </LiWrapper>
              ) : (
                <LiWrapper
                  code="freight"
                  name={
                    fStatus ? (
                      <FormattedMessage
                        id="viewtrade-tradeactions-buyer-field-freight-label"
                        description="Label for 'Freight' in Trade Actions in View trade as buyer"
                        defaultMessage="Freight"
                      />
                    ) : (
                      <FormattedMessage
                        id="viewtrade-tradeactions-buyer-field-reqeustfreight-label"
                        description="Label for 'Request freight quote' in Trade Actions in View trade as buyer"
                        defaultMessage="Request freight quote"
                      />
                    )
                  }
                  status={fStatus && <span className={fStatus}>{fStatus}</span>}
                >
                  {!fStatus && <RequestForm />}
                  {fStatus === 'requested' && (
                    <>
                      <FormattedMessage
                        id="viewtrade-tradeactions-buyer-field-reqeustfreight-helptext-requestedat"
                        description="helptext for 'Request freight quote' time in Trade Actions in View trade as buyer"
                        defaultMessage="Quote has been requested at <strong>{time}</strong>"
                        values={{
                          strong: chunks => (
                            <strong className="all-black nowrap">
                              {chunks}
                            </strong>
                          ),
                          time: moment(
                            R.path(
                              ['freightquote', 'request', 'created'],
                              trade
                            )
                          ).format('D MMMM YYYY HH:mm'),
                        }}
                      />
                    </>
                  )}
                  {fStatus === 'pending' && <AcceptForm />}
                  {['accepted', 'declined'].includes(fStatus) && (
                    <>
                      <FormattedMessage
                        id="viewtrade-tradeactions-buyer-field-reqeustfreight-helptext-total"
                        description="helptext for 'Request freight quote' total in Trade Actions in View trade as buyer"
                        defaultMessage="Total freight costs is"
                      />{' '}
                      <strong className="all-black">
                        <FNumber
                          value={R.path(
                            ['freightquote', 'quote', 'rate'],
                            trade
                          )}
                          decimalCount={2}
                        />{' '}
                        {trade.price.currency}
                      </strong>
                      <br />
                      {R.path(['freightquote', 'quote', 'accepted'], trade) ? (
                        <FormattedMessage
                          id="viewtrade-tradeactions-buyer-field-freightquote-helptext-quoteaccepted"
                          description="Helptext for 'Quote accepted' in Trade Actions in View trade as buyer"
                          defaultMessage="Quote was accepted"
                        />
                      ) : (
                        <FormattedMessage
                          id="viewtrade-tradeactions-buyer-field-freightquote-helptext-quotedeclined"
                          description="Helptext for 'Quote declined' in Trade Actions in View trade as buyer"
                          defaultMessage="Quote was declined"
                        />
                      )}
                      .
                    </>
                  )}
                </LiWrapper>
              )}
            </>
          )}

          {trade.discountavailable && (
            <>
              {isSeller ? (
                <LiWrapper
                  code="discount"
                  name={
                    fStatus ? (
                      <FormattedMessage
                        id="viewtrade-tradeactions-seller-field-discount-label"
                        description="label for 'Discount' in Trade Actions in View trade as seller"
                        defaultMessage="Discount"
                      />
                    ) : (
                      <FormattedMessage
                        id="viewtrade-tradeactions-seller-field-adddiscount-label"
                        description="label for 'Add discount' in Trade Actions in View trade as seller"
                        defaultMessage="Add discount"
                      />
                    )
                  }
                  status={fStatus && <span className={fStatus}>{fStatus}</span>}
                >
                  {(!fStatus || fStatus === 'requested') && (
                    <ProvideDiscountForm />
                  )}

                  {['accepted', 'declined', 'pending'].includes(fStatus) && (
                    <>
                      <FormattedMessage
                        id="viewtrade-tradeactions-seller-field-totaldiscount-label"
                        description="label for 'total discount' in Trade Actions in View trade as seller"
                        defaultMessage="Total discount is"
                      />{' '}
                      <strong className="all-black">
                        <FNumber
                          value={R.path(
                            ['freightquote', 'quote', 'rate'],
                            trade
                          )}
                          decimalCount={2}
                        />{' '}
                        {trade.price.currency}
                      </strong>
                      .
                      <br />
                      {fStatus === 'pending' ? (
                        <>
                          <FormattedMessage
                            id="viewtrade-tradeactions-seller-field-discountpending-helptext"
                            description="helptext for 'desicount pending' in Trade Actions in View trade as seller"
                            defaultMessage="Discount details have been sent to the buyer."
                          />
                        </>
                      ) : (
                        <>
                          {R.path(
                            ['freightquote', 'quote', 'accepted'],
                            trade
                          ) ? (
                            <FormattedMessage
                              id="viewtrade-tradeactions-seller-field-freightquote-helptext-quoteaccepted"
                              description="Helptext for 'Quote accepted' in Trade Actions in View trade as seller"
                              defaultMessage="Quote was accepted"
                            />
                          ) : (
                            <FormattedMessage
                              id="viewtrade-tradeactions-seller-field-freightquote-helptext-quotedeclined"
                              description="Helptext for 'Quote declined' in Trade Actions in View trade as seller"
                              defaultMessage="Quote was declined"
                            />
                          )}
                          .
                        </>
                      )}
                    </>
                  )}
                </LiWrapper>
              ) : (
                <LiWrapper
                  code="discount"
                  name={
                    fStatus ? (
                      <FormattedMessage
                        id="viewtrade-tradeactions-buyer-field-discount-label"
                        description="label for 'Discount' in Trade Actions in View trade as buyer"
                        defaultMessage="Discount"
                      />
                    ) : (
                      <FormattedMessage
                        id="viewtrade-tradeactions-buyer-field-requestdiscount-label"
                        description="label for 'Request discount' in Trade Actions in View trade as buyer"
                        defaultMessage="Request discount"
                      />
                    )
                  }
                  status={fStatus && <span className={fStatus}>{fStatus}</span>}
                >
                  {!fStatus && <RequestDiscountForm />}
                  {fStatus === 'requested' && (
                    <>
                      <FormattedMessage
                        id="viewtrade-tradeactions-buyer-field-requesteddiscount-label"
                        description="label for 'Requested discount' in Trade Actions in View trade as buyer"
                        defaultMessage="Discount has been requested at"
                      />{' '}
                      <strong className="all-black nowrap">
                        {moment(
                          R.path(['freightquote', 'request', 'created'], trade)
                        ).format('D MMMM YYYY HH:mm')}
                      </strong>
                    </>
                  )}
                  {fStatus === 'pending' && <AcceptDiscountForm />}
                  {['accepted', 'declined'].includes(fStatus) && (
                    <>
                      <FormattedMessage
                        id="viewtrade-tradeactions-buyer-field-totaldiscount-label"
                        description="label for 'total discount' in Trade Actions in View trade as buyer"
                        defaultMessage="Total discount is"
                      />{' '}
                      <strong className="all-black">
                        <FNumber
                          value={R.path(
                            ['freightquote', 'quote', 'rate'],
                            trade
                          )}
                          decimalCount={2}
                        />{' '}
                        {trade.price.currency}
                      </strong>
                      <br />
                      {R.path(['freightquote', 'quote', 'accepted'], trade) ? (
                        <FormattedMessage
                          id="viewtrade-tradeactions-buyer-field-freightquote-helptext-discountaccepted"
                          description="Helptext for 'Quote accepted' in Trade Actions in View trade as buyer"
                          defaultMessage="Discount was accepted"
                        />
                      ) : (
                        <FormattedMessage
                          id="viewtrade-tradeactions-buyer-field-freightquote-helptext-discountdeclined"
                          description="Helptext for 'Quote declined' in Trade Actions in View trade as buyer"
                          defaultMessage="Discount was declined"
                        />
                      )}
                      .
                    </>
                  )}
                </LiWrapper>
              )}
            </>
          )}

          {isBuyer && !poNumber && (
            <LiWrapper
              code="po-number"
              name={
                <FormattedMessage
                  id="viewtrade-tradeactions-buyer-field-purchaseordernumber-label"
                  description="Label for 'purchase order number' field in Trade Actions in View trade"
                  defaultMessage="Add a purchase order number"
                />
              }
            >
              <InfoForm type="PO Number" />
            </LiWrapper>
          )}

          {poNumber && (
            <LiWrapper
              code="po-number"
              name={
                <FormattedMessage
                  id="viewtrade-tradeactions-field-purchaseordernumber-label"
                  description="Label for 'Purchase order number' field in Trade Actions in View trade"
                  defaultMessage="Purchase order number"
                />
              }
              status={
                <span className="accepted">
                  {isSeller ? (
                    <FormattedMessage
                      id="viewtrade-tradeactions-seller-field-poaccepted-label"
                      description="Label for 'Purchase order number accepted' field in Trade Actions in View trade as seller"
                      defaultMessage="Received"
                    />
                  ) : (
                    <FormattedMessage
                      id="viewtrade-tradeactions-buyer-field-poprovided-label"
                      description="Label for 'Purchase order number provided' field in Trade Actions in View trade as buyer"
                      defaultMessage="Provided"
                    />
                  )}
                </span>
              }
            >
              <div className="icon-info-circled">
                <p className="p-0">
                  <FormattedMessage
                    id="viewtrade-tradeactions-buyer-field-purchaseordernumber-helptext"
                    description="helptext for 'Purchase order number' field in Trade Actions in View trade"
                    defaultMessage="Purchase order number:"
                  />
                  <br />
                  <strong className="all-black">{poNumber.value}</strong>
                </p>
              </div>
            </LiWrapper>
          )}

          <LiWrapper
            code="files"
            name={
              <FormattedMessage
                id="viewtrade-tradeactions-field-uploadfiles-label"
                description="label for 'Upload files' field in Trade Actions in View trade"
                defaultMessage="Upload files"
              />
            }
            extra={
              R.propOr([], 'attachments', trade).length > 0 && (
                <span className="item right">
                  <FilesModal
                    title={
                      <FormattedMessage
                        id="viewtrade-tradeactions-field-viewfiles-label"
                        description="label for 'View files' field in Trade Actions in View trade"
                        defaultMessage="View files"
                      />
                    }
                    files={trade.attachments.filter(x => x)}
                  />
                </span>
              )
            }
          >
            <AttachmentForm />
          </LiWrapper>

          <LiWrapper
            code="comments"
            name={
              <FormattedMessage
                id="viewtrade-tradeactions-field-addcomment-label"
                description="label for 'Add a comment' field in Trade Actions in View trade"
                defaultMessage="Add a comment"
              />
            }
          >
            <InfoForm type="Comment" />
          </LiWrapper>
        </ul>
      </div>
    </>
  );
};
