import moment from 'moment-timezone';
import R from 'ramda';
import map from 'ramda/src/map';
import React from 'react';
import {
  flattenChoices,
  MAX_SAFE_INTEGER,
  normalizeDateTime,
  roundToSamePrecision,
} from '../utils';
import {
  DateRangeWidget,
  DateTimeRangeWidget,
  DateTimeWidget,
  DateWidget,
  InputNumberWidget,
  RadioWidget,
  SelectWidget,
  InputWidget,
  TextAreaWidget,
  CheckboxWidget,
  SwitchWidget,
  FileWidget,
} from '../widgets';
import BaseField from './BaseField';

export { default as BaseField } from './BaseField';
export { default as RichTextField } from './RichTextField';

export class SelectField extends BaseField {
  widget = SelectWidget;

  constructor({ choices, ...spec }) {
    let newChoices = choices || [];
    const { required } = spec;
    if (typeof newChoices === 'function') {
      newChoices = newChoices();
    }
    if (
      !required &&
      Array.isArray(newChoices) &&
      !Array.isArray(R.path([0, 'value'], newChoices))
    ) {
      newChoices = R.prepend(
        {
          key: 'empty-choice',
          label: '--empty--',
          value: '--empty--',
          className: 'empty-choice',
        },
        newChoices
      );
    }
    super({ choices: newChoices || [], ...spec });
  }

  normalize(cur) {
    const { choices, required } = this.getSpec();
    const flatChoices = flattenChoices(choices);
    // Sanitize value in available choices
    if (cur) {
      cur = R.prop('value', R.find(R.propEq('value', cur), flatChoices));
    }
    // auto-select the value of the only option if the field is required and there is no valid value.
    if (R.isNil(cur) && R.length(flatChoices) === 1 && required) {
      cur = R.path([0, 'value'], flatChoices);
    }
    if (cur === '--empty--') {
      // cur = null;
      cur = undefined;
    }
    return cur;
  }
}

export class MultipleSelectField extends BaseField {
  widget = SelectWidget;

  normalize(cur) {
    const { choices, required } = this.getSpec();
    const flatChoices = flattenChoices(choices);
    let ret = cur;
    if (ret) {
      // Treat those not in Array as single value and put them in Array
      if (!Array.isArray(ret)) {
        ret = [ret];
      }
      // should be subset of choices
      ret = R.filter(x => R.find(R.propEq('value', x), flatChoices), ret);
    }
    // auto-select the value of the only option if the field is required and there is no valid value.
    if (
      (R.isNil(ret) || R.isEmpty(ret)) &&
      R.length(flatChoices) === 1 &&
      required
    ) {
      ret = [R.path([0, 'value'], flatChoices)];
    }
    return ret;
  }
}

export class RadioSelectField extends SelectField {
  widget = RadioWidget;
}

export const YesNoChoices = [
  { label: 'Yes', value: 'true', key: 'yes' },
  { label: 'No', value: 'false', key: 'no' },
];

export const YesNotRequiredChoices = [
  { label: 'Yes', value: 'true', key: 'yes' },
  { label: 'Not required', value: 'false', key: 'no' },
];

export class BooleanField extends SelectField {
  widget = RadioWidget;

  constructor({ choices, ...spec }) {
    super({ choices: choices || YesNoChoices, ...spec });
  }

  normalize(cur) {
    if (typeof cur === 'boolean') {
      cur = String(cur);
    }
    return super.normalize(cur);
  }
}

export class DecimalField extends BaseField {
  widget = InputNumberWidget;

  constructor({ min, max, step, ...spec }) {
    super({
      min: min ?? 0,
      max: max ?? MAX_SAFE_INTEGER,
      step: step || 1,
      ...spec,
    });
  }

  // Round the value to be times of step and inside [rounded min, rounded max]
  normalize(cur) {
    if (R.isNil(cur) || isNaN(parseFloat(cur))) return;
    const spec = this.getSpec();
    const step = parseFloat(spec.step);
    const { min, max } = spec;
    // TODO Use globalize or numeral?
    const round = (x, method) =>
      roundToSamePrecision(
        Math[method](roundToSamePrecision(parseFloat(x) / step, 0.1)) * step,
        step
      );

    return [round(min, 'ceil'), round(cur, 'round'), round(max, 'floor')].sort(
      R.subtract
    )[1];
  }
}

export class IntegerField extends BaseField {
  widget = InputNumberWidget;

  constructor({ min, max, step, ...spec }) {
    super({
      min: min || 0,
      max: max || MAX_SAFE_INTEGER,
      step: step || 1,
      ...spec,
    });
  }

  // Round the value to be times of step and inside [rounded min, rounded max]
  normalize(cur) {
    if (R.isNil(cur) || isNaN(parseFloat(cur))) return;
    const spec = this.getSpec();
    const step = parseFloat(spec.step);
    const { min, max } = spec;
    // TODO Use globalize or numeral?
    const round = (x, method) =>
      roundToSamePrecision(
        Math[method](roundToSamePrecision(parseFloat(x) / step, 0.1)) * step,
        step
      );

    return [round(min, 'ceil'), round(cur, 'round'), round(max, 'floor')].sort(
      R.subtract
    )[1];
  }
}

export class StringField extends BaseField {
  widget = TextAreaWidget;
}

export class InputField extends BaseField {
  widget = InputWidget;
}

// Prevent import moment been removed by prettier
export function dummyMoment() {
  return moment();
}

export class DateTimeField extends BaseField {
  widget = DateTimeWidget;

  normalize(cur) {
    const { fromDate, toDate, interval } = this.getSpec();
    return normalizeDateTime(cur, fromDate, toDate, interval);
  }

  serialize(data) {
    const { format, fromDate, toDate, interval } = this.getSpec();
    const value = normalizeDateTime(data, fromDate, toDate, interval);
    return value ? value.format(format) : undefined;
  }
}

// Operate in default timezone, change default one before use it here.
export class DateTimeRangeField extends BaseField {
  widget = DateTimeRangeWidget;

  normalize(cur) {
    try {
      const { interval } = this.getSpec();
      const [df, dt] = cur.map(dt =>
        normalizeDateTime(dt, null, null, interval)
      );
      return df && dt ? [df, dt] : undefined;
    } catch (e) {
      return undefined;
    }
  }

  serialize(value) {
    const { format } = this.getSpec();
    return Array.isArray(value) ? map(x => x.format(format), value) : value;
  }

  serializeField(values) {
    const { name, start, end } = this.getSpec();
    const serialized = this.serialize(values[name]);
    return {
      [start || 'start']: serialized && serialized[0],
      [end || 'end']: serialized && serialized[1],
    };
  }
}

export class DateField extends DateTimeField {
  widget = DateWidget;

  constructor({ format, ...spec }) {
    super({ format: format || 'YYYY-MM-DD', ...spec });
  }
}

export class DateRangeField extends DateTimeRangeField {
  widget = DateRangeWidget;

  constructor({ format, ...spec }) {
    super({ format: format || 'YYYY-MM-DD', ...spec });
  }
}

export class CheckboxField extends BaseField {
  widget = CheckboxWidget;

  normalize(cur) {
    return cur;
  }
}

export class SwitchField extends CheckboxField {
  widget = SwitchWidget;
}

export class MultipleFileField extends BaseField {
  widget = FileWidget;

  constructor(...params) {
    super(...params);
    this.fileRef = React.createRef();
  }

  serializeField(values) {
    return R.path(['current', 'files'], this.fileRef);
  }

  renderUnbound(spec = this.getSpec()) {
    return super.renderUnbound({
      ...spec,
      multiple: true,
      fileRef: this.fileRef,
    });
  }
}

export class FileField extends MultipleFileField {
  renderUnbound(spec = this.getSpec()) {
    return super.renderUnbound({
      ...spec,
      multiple: false,
      fileRef: this.fileRef,
    });
  }
}
