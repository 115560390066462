import { action, computed, thunk, thunkOn } from 'easy-peasy';
import R from 'ramda';
import { api } from '~/api';
import { history } from '~/history';
import { User } from '~/models';
import moment from 'moment-timezone';
import { Product } from '~/models';
import { toast } from 'react-toastify';
import * as tenderService from '~/services/tenders';

import { defaultSetting } from './defaultSetting';

export const authModel = {
  user: null,
  solutionId: null,
  loading: true, // indicates token loading & sign-in, default to true to show loading spin before routing.
  routes: [],
  products: [],
  comProducts: [], // unique by p_id.
  presets: null,
  participantToast: null,

  // Selectors
  userModel: computed(state => state.user && new User(state.user)),
  solutions: computed(R.pathOr([], ['user', 'solutions'])),
  solution: computed(state =>
    R.find(R.propEq('id', state.solutionId), state.solutions)
  ),
  solutionColumns: computed(state => R.pathOr({}, ['columns'], state.solution)),
  isdao: computed(
    state =>
      state.solution &&
      state.solution.id === 'c429cff0-a32a-40b2-9243-71547ae65a3a'
  ),

  tradableProducts: computed(state => orderType => {
    return R.pipe(
      // Get divisions that user belongs to and can trade
      R.pathOr([], ['solution', 'roles']),
      R.filter(
        role =>
          R.includes(R.prop('role', role), ['manager', 'trader']) &&
          ((orderType === 'bid' &&
            ['buyer', 'trader', 'broker'].includes(
              role.division.divisiontype
            )) ||
            (orderType === 'offer' &&
              ['seller', 'trader', 'broker'].includes(
                role.division.divisiontype
              )))
      ),
      // Get products with associated division
      R.map(role => {
        const { products, ...division } = R.compose(
          R.pickAll(['id', 'currency', 'products']),
          R.prop('division')
        )(role);
        return R.map(R.assoc('_division', division), products);
      }),
      R.flatten,
      // Merge products and divisions
      products => {
        const asMap = {};
        return R.map(product => {
          const pid = R.prop('id', product);
          const ret = R.find(R.propEq('id', pid), state.products);
          if (ret) {
            const div = R.prop('_division', product);
            const divId = R.prop('id', div);
            const divs = R.propOr({}, pid, asMap);
            divs[divId] = div;
            ret.divs = asMap[pid] = divs;
          }
          return ret;
        }, products);
      },
      R.uniqBy(R.prop('id')),
      R.filter(x => x)
    )(state);
  }),

  solutionDivisions: computed(state =>
    R.pipe(
      R.pathOr([], ['solution', 'roles']),
      R.map(R.path(['division']))
    )(state)
  ),

  solutionDivisionIds: computed(state =>
    R.pipe(
      R.pathOr([], ['solution', 'roles']),
      R.map(R.path(['division', 'id']))
    )(state)
  ),

  solutionWarehouses: computed(state =>
    R.pipe(
      R.pathOr([], ['solution', 'roles']),
      R.map(R.path(['division', 'warehouses'])),
      R.flatten
    )(state)
  ),

  solutionProducts: computed(state =>
    R.pipe(
      // Get divisions that user belongs
      R.pathOr([], ['solution', 'roles']),
      R.map(R.path(['division', 'products'])),
      R.flatten,
      R.uniqBy(R.prop('id')),
      R.map(({ id }) => R.find(R.propEq('id', id), state.products)),
      R.filter(x => x)
    )(state)
  ),

  solutionTags: computed(state =>
    R.pipe(
      R.pathOr([], ['solutionProducts']),
      R.map(R.path(['tags'])),
      R.flatten,
      R.uniqBy(R.prop('id')),
      R.filter(x => x)
    )(state)
  ),

  productById: computed(state => id => {
    const p = R.find(R.propEq('id', id), state.solutionProducts);
    return p ? new Product(p) : null;
  }),

  allSolutionProducts: computed(state => solutionId => {
    if (!solutionId) return [];

    const solution = R.find(R.propEq('id', solutionId), state.solutions);

    const products = R.pipe(
      R.pathOr([], ['roles']),
      R.map(R.path(['division', 'products'])),
      R.flatten,
      R.uniqBy(R.prop('id')),
      R.filter(x => x)
    )(solution);

    return R.sortBy(R.compose(R.toLower, R.prop('name')))(
      products.map(p => new Product(p))
    );
  }),

  solutionComProducts: computed(state => solutionId => {
    if (!solutionId) return [];

    const solution = R.find(R.propEq('id', solutionId), state.solutions);

    const products = R.pipe(
      R.pathOr([], ['roles']),
      R.map(R.path(['division', 'products'])),
      R.flatten,
      R.uniqBy(R.prop('id')),
      R.map(({ id }) => R.find(R.propEq('id', id), state.products)),
      R.filter(x => x)
    )(solution);

    return R.sortBy(R.compose(R.toLower, R.prop('name')))(
      products.map(p => new Product(p))
    );
  }),

  marketplaceProducts: computed(
    state => solutionId =>
      R.filter(R.propEq('marketplace', true))(
        state.solutionComProducts(solutionId)
      )
  ),

  canTradeDivisions: computed(state =>
    R.pipe(
      R.pathOr([], ['solution', 'roles']),
      R.filter(role => R.includes(R.prop('role', role), ['manager', 'trader'])),
      R.map(role => R.prop('division', role))
    )(state)
  ),

  canManageDivisions: computed(state => {
    return R.pipe(
      R.pathOr([], ['solution', 'roles']),
      R.filter(role => R.includes(R.prop('role', role), ['manager'])),
      R.map(role => R.prop('division', role))
    )(state);
  }),

  canManageProductsDivisions: computed(state => {
    return R.pipe(
      R.pathOr([], ['solution', 'roles']),
      R.filter(role => R.path(['division', 'manageproducts'], role)),
      R.map(role => R.prop('division', role))
    )(state);
  }),

  userTradeActions: computed(state => {
    let canBuy = false;
    let canSell = false;

    let actions = [];
    state.canTradeDivisions.forEach(item => {
      if (
        !canBuy &&
        ['buyer', 'trader', 'broker'].includes(item.divisiontype)
      ) {
        canBuy = true;
        actions.push('buy');
      }
      if (
        !canSell &&
        ['seller', 'trader', 'broker'].includes(item.divisiontype)
      ) {
        canSell = true;
        actions.push('sell');
      }
    });

    return actions;
  }),

  userProductsTradeActions: computed(state => {
    const roles = R.pipe(
      R.pathOr([], ['solution', 'roles']),
      R.filter(role => R.includes(R.prop('role', role), ['manager', 'trader']))
    )(state);

    const products = state.solutionProducts;

    let productsDict = {};
    const myMerge = (item, action) => {
      // R.pathOr([], ['division', 'products'], item).forEach(p => {
      item.division.products.forEach(p => {
        const product = R.find(R.propEq('id', p.id))(products);
        if (product) {
          productsDict = R.mergeDeepRight(productsDict, {
            [product.p_id]: {
              [action]: p.id,
              allowdecline: item.division.allowdecline,
              divisions: {
                [item.division.id]: {
                  id: item.division.id,
                  name: item.division.name,
                  warehouses: item.division.warehouses,
                },
              },
            },
          });
        }
      });
    };

    if (roles) {
      roles.forEach(item => {
        const divisiontype = R.pathOr('', ['division', 'divisiontype'], item);

        if (['buyer', 'trader', 'broker'].includes(divisiontype)) {
          myMerge(item, 'buy');
        }
        if (['seller', 'trader', 'broker'].includes(divisiontype)) {
          myMerge(item, 'sell');
        }
      });
    }

    return productsDict;
  }),

  tradeActionsByProductPId: computed(state => PId => {
    return PId in state.userProductsTradeActions
      ? state.userProductsTradeActions[PId]
      : null;
  }),

  hasRole: computed(
    state =>
      (role, solution = state.solution) =>
        R.includes(role, R.propOr([], 'roles', solution).map(R.prop('role')))
  ),

  hasRoleInAnySolution: computed(
    state =>
      (role, solutions = state.solutions) =>
        R.any(
          solution =>
            R.includes(
              role,
              R.propOr([], 'roles', solution).map(R.prop('role'))
            ),
          solutions
        )
  ),

  privacyCheckReq: computed(state => {
    const { user, solution } = state;

    if (!user || !solution) return false;

    let check =
      user.policydate === null ||
      solution.policydate === null ||
      user.termsdate === null ||
      solution.termsdate === null;

    if (check) return true;

    check =
      user.policydate !== undefined &&
      !!user.policyupdated &&
      moment(user.policydate) < moment(user.policyupdated);

    if (check) return true;

    check =
      user.termsdate !== undefined &&
      !!user.termsupdated &&
      moment(user.termsdate) < moment(user.termsupdated);

    if (check) return true;

    check =
      solution.policydate !== undefined &&
      solution.privacy &&
      !!solution.privacy.updated &&
      moment(solution.policydate) < moment(solution.privacy.updated);

    if (check) return true;

    check =
      solution.termsdate !== undefined &&
      solution.terms &&
      !!solution.terms.updated &&
      moment(solution.termsdate) < moment(solution.terms.updated);

    return check;
  }),

  isSolutionOwner: computed(state =>
    R.pathEq(
      ['solution', 'owner', 'id'],
      R.path(['user', 'company', 'id'], state),
      state
    )
  ),

  canEditExchangeRates: computed(
    state =>
      R.pathOr(false, ['solution', 'settings', 'manualexchange'], state) &&
      state.isSolutionOwner &&
      state.hasRole('manager')
  ),

  commodityFilteredProducts: computed(state =>
    R.pathEq(['solution', 'settings', 'matchinglevel'], 'commodity', state)
      ? R.uniqBy(R.path(['commodity', 'id']), state.solutionProducts)
      : state.solutionProducts
  ),

  traderType: computed(state => {
    let [seller, buyer] = [false, false];
    for (const r of R.pathOr([], ['solution', 'roles'], state)) {
      if (R.path(['division', 'seller'], r)) seller = true;
      if (R.path(['division', 'buyer'], r)) buyer = true;
      if (seller && buyer) break;
    }
    return { seller, buyer };
  }),

  userPrefs: computed(state => R.pathOr({}, ['user', 'prefs'], state)),

  solutionSettings: computed(state => {
    const solution = R.find(R.propEq('id', state.solutionId), state.solutions);
    return {
      ...defaultSetting,
      ...solution?.settings,
      hasPartners: !!(solution?.columns?.buyer || solution?.columns?.consignee),
    };
  }),

  // Actions
  signIn: action((state, { user }) => {
    state.user = user;
  }),
  signOut: action(state => {
    state.user = null;
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  changeSolution: action((state, id) => {
    state.solutionId = id;
  }),
  updateProducts: action((state, products) => {
    state.products = products.map(item => ({
      key: item.id,
      p_id: item.id,
      ...item,
    }));
  }),
  updateRoutes: action((state, routes) => {
    state.routes = R.sortBy(
      R.prop('key'),
      routes.map(item => ({
        key: `${item.source.id}-${item.destination.id}`,
        ...item,
      }))
    );
  }),
  updatePresets: action((state, presets) => {
    state.presets = presets;
  }),
  updateUser: action((state, data) => {
    state.user = { ...state.user, ...data };
  }),
  setParticipantToast: action((state, data) => {
    state.participantToast = data;
  }),
  dismissParticipants: action(state => {
    const s = R.find(R.propEq('id', state.solution.id), state.solutions);
    state.user.solutions = [
      ...R.reject(R.propEq('id', state.solution.id), state.solutions),
      { ...s, new_participants: 0 },
    ];
  }),
  updateFavouriteproducts: action((state, favouriteproducts) => {
    const s = R.find(R.propEq('id', state.solution.id), state.solutions);
    state.user.solutions = [
      ...R.reject(R.propEq('id', state.solution.id), state.solutions),
      { ...s, favouriteproducts },
    ];
  }),

  // Thunks
  loadUserProfile: thunk(async actions => {
    const result = await api.getData({
      type: 'user',
      id: `profile`,
      getResult: x => x,
    });
    actions.updateUser(result);
  }),

  loadPresets: thunk(async (actions, solutionId) => {
    const presets = await api.getData(
      { type: 'presets' },
      { solution: solutionId }
    );
    actions.updatePresets(presets);
  }),

  startAuth: thunk(async (actions, _, { injections: { authService } }) => {
    setTimeout(() => authService.redirectToAuthorizeUrl(), 0);
  }),
  // Start token exchange cycle by using provided code
  checkAuth: thunk(
    async (actions, payload, { injections: { api, authService, history } }) => {
      // actions.setLoading(true);

      try {
        // from signin
        const target = R.pathOr({}, ['target'], payload);
        const camefrom = R.pathOr('/', ['camefrom'], payload);
        let token = R.pathOr(null, ['token'], payload);
        const urlSolution = R.pathOr('', ['urlSolution'], payload);

        if (!token) {
          authService.clearToken();
          token = await api.refresh();
        }

        api.updateToken(token);
        authService.saveToken(token);

        const user = await api.getData({ type: 'user' });

        actions.signIn({ user, target, camefrom, urlSolution });
      } catch (e) {
        actions.signOut();
        // To has an error display in render, we need to set up another variable for it in global store
        // message.error('Sign in failed. Please try again');
      }
      actions.setLoading(false);
    }
  ),

  fetchProducts: thunk(async (actions, _, { injections: { api } }) => {
    try {
      const products = await api.getData({ type: 'products' });
      actions.updateProducts(products);
    } catch (e) {
      // nothing
    }
  }),

  fetchRoutes: thunk(async (actions, solutionId, { injections: { api } }) => {
    try {
      const params = { solution: solutionId };
      const routes = await api.getData2('/routes', params);
      actions.updateRoutes(routes.routes);
    } catch (e) {
      // nothing
    }
  }),

  fetchUser: thunk(async (actions, _, { injections: { api } }) => {
    try {
      const user = await api.getData({ type: 'user' });
      actions.updateUser(user);
    } catch (e) {
      // nothing
    }
  }),

  onSignIn: thunkOn(
    actions => actions.signIn,
    async (
      actions,
      { payload: { user, target, camefrom, urlSolution } },
      { injections: { authService } }
    ) => {
      const solutions = R.propOr([], 'solutions', user);
      /**
       * Set solution to be either:
       * 1. passed-in one through /authenticate
       * 2. ?solution= param in URL
       * 3. persistent one in localStorage
       * 4. first one of user.solutions
       */
      const sid = R.find(
        id => R.find(R.propEq('id', id), solutions),
        [
          target.solution,
          urlSolution,
          authService.loadSolution(),
          R.path([0, 'id'], solutions),
        ]
      );
      actions.changeSolution(sid);
      /**
       * TODO test scenarios for input URLs with user signed in or not.
       * - when prev token works, redirect to origin URL thru routing
       * - Otherwise, ideally we want to reach the origin URL
       */

      const subtarget = R.path(['target'], target);
      let data = null;
      if (subtarget) {
        try {
          switch (subtarget.type) {
            // case 'order':
            //   data = await api.getData({
            //     type: 'orders',
            //     id: subtarget.id,
            //     getResult: x => x.order,
            //   });
            //   history.replace(
            //     `/marketplace/${data.product.id}?order=${data.id}`
            //   );
            //   return;

            case 'order':
              history.replace(`/market?order=${data.id}`);
              return;

            case 'trade':
              history.replace(`/trades/${subtarget.id}`);
              return;

            case 'participants':
              history.replace(
                `/participants?activatedon=gte:${subtarget.activatedon}`
              );
              return;

            default:
              break;
          }
        } catch (error) {
          // nothing to worry about
        }
      }

      history.replace(camefrom);
    }
  ),
  onSignOut: thunkOn(
    actions => actions.signOut,
    async (actions, _, { injections: { api, authService } }) => {
      actions.setLoading(true);
      // we need to destroy the cookie
      try {
        await api.signOut();
      } catch (error) {
        // Nothing
      }
      tenderService.events.signout({});
      actions.changeSolution(null);
      actions.updateProducts([]);
      api.updateToken(null);
      authService.clearToken();
      // Directly redirect to root
      // TODO Ensure it works for different scenarios
      // history.replace('/signin');
      actions.setLoading(false);
    }
  ),
  onChangeSolution: thunkOn(
    actions => actions.changeSolution,
    async (
      actions,
      { payload: id },
      { injections: { authService }, getState, getStoreActions }
    ) => {
      getStoreActions().market.reset();
      getStoreActions().market.resetFilter();
      actions.updatePresets(null);
      toast.dismiss(getState().participantToast);
      if (id) {
        authService.saveSolution(id);
        actions.setLoading(true);
        setTimeout(() => actions.setLoading(false), 1000);
        tenderService.events.solution({ solution: id });

        try {
          const data = await api.getData2('/products', { solution: id });
          actions.updateProducts(data.products);
        } catch (error) {
          // Nothing
        }
      } else {
        authService.clearSolution(id);
      }
    }
  ),
};
