import React from 'react';
import { TenderItem } from '../View';
import { NoData } from 'Components/InfoMessage';
import { withPreload, withRouteX } from '~/router';
import * as service from '~/services/tenders';

const Overview = withPreload({
  route: 'tender-overview',
  preload: service.observe.overview(),
})(({ data: tenders }) => {
  if (!tenders?.length)
    return (
      <NoData className="mt--1 mb--1 p-100 b-0">
        There are currently no active tenders
      </NoData>
    );

  return (
    <div className="tender-list">
      {tenders
        .filter(t => !t.finalised)
        .sort((a, b) => {
          if (a.status === b.status) {
            switch (a.status) {
              case 'active':
                if (a.finish.isBefore(b.finish)) return -1;
                return 1;
              case 'finished':
                if (a.finish.isBefore(b.finish)) return -1;
                return 1;
              case 'published':
                if (a.start.isBefore(b.start)) return -1;
                return 1;
              case 'planning':
                if (a.start.isBefore(b.start)) return -1;
                return 1;
              default:
                return 0;
            }
          } else {
            for (const s of ['active', 'finished', 'published', 'planning']) {
              if (a.status === s) return -1;
              if (b.status === s) return 1;
            }
          }

          return 0;
        })
        .map(tender => (
          <TenderItem key={tender.id} tender={tender} />
        ))}
    </div>
  );
});

export default withRouteX({ name: 'tender-overview' })(Overview);
