import { StoreProvider, useStoreState } from 'easy-peasy';
import R from 'ramda';
import React, { useEffect, useState, useMemo } from 'react';
import { Router } from 'react-router-dom';
import { history } from '~/history';
import { Routes } from '~/Routes';
import { store } from '~/store';
import { ApiContext } from './context';
import { api } from './api';
import ZendeskChat from './support';
import Tracking from './tracking';
import Helmet from 'Components/Helmet';
import { ToastContainer, Slide } from 'react-toastify';
import Empty from 'Components/Empty';
import { IntlProvider } from 'react-intl';
import { useLocaleProvider } from './lang';

const project = spec => rootCss => {
  if (!rootCss) return {};

  const newkeys = Object.keys(spec);

  const ret = {};

  newkeys.forEach(key => {
    spec[key].forEach(oldKey => {
      Object.assign(
        ret,
        R.assoc(oldKey, R.propOr(R.prop(oldKey, rootCss), key, rootCss), ret)
      );
    });
  });

  return ret;
};

const generateCss = project({
  primary: [
    'primary-color',
    'loader',
    'navbar',
    'checkbox',
    'radio',
    'highlight',
  ],
  primary_2: ['mobile-nav-background'],
  secondary: ['secondary-color', 'loader-before', 'link', 'place-new-order'],
  tertiary: ['loader-after'],
  gray_1: ['panel-h2'],
  gray_2: ['main-panel'],
  gray_3: ['light-panel'],
});

function App() {
  // react-router runs a bit delay when using useStoreState and store gets updated (in PrivateRoute)
  //  Thus get user here and pass it into Routes.
  // TODO If there are more usages, try to pass in user through context,
  //  for components cannot properly react to store update.
  const { loading, user, solution } = useStoreState(state =>
    R.pickAll(['loading', 'user', 'solution'], state.auth)
  );

  if (loading) {
    document.body.classList.add('switching');
  } else {
    document.body.classList.add('loaded');
    document.body.classList.remove('switching');
  }

  const rootCss = {
    primary: '#162f53',
    primary_2: '#367bc2',
    secondary: '#1ba9d6',
    tertiary: '#eb3b2b',
    gray_1: '#666666',
    gray_2: '#999999',
    gray_3: '#f2f2f2',
    ...R.path(['ui_settings', 'lite'], solution),
  };

  const [favicon, setFavicon] = useState();

  useEffect(() => {
    if (solution && solution.favicon) {
      setFavicon(solution.favicon);
    } else {
      setFavicon('/favicon.ico');
    }
  }, [solution]);

  const { messages, locale } = useLocaleProvider();

  const newCss = useMemo(() => generateCss(rootCss), [rootCss]);

  const mapCss = `:root {
  ${Object.entries(newCss)
    .map(([key, value]) => `--${key}: ${value};`)
    .join('\n')}
  }`;

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
      {favicon && (
        <Helmet>
          <link rel="shortcut icon" href={favicon} />
        </Helmet>
      )}
      {rootCss && (
        <Helmet>
          <style>{mapCss}</style>
        </Helmet>
      )}
      <ApiContext.Provider value={api}>
        <Router history={history}>
          <Routes solution={solution} user={user} />
          <Tracking />
          <Empty />
        </Router>
      </ApiContext.Provider>
    </IntlProvider>
  );
}

export default () => (
  <StoreProvider store={store}>
    <App />
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Slide}
      type="info"
    />
    <ZendeskChat />
  </StoreProvider>
);
