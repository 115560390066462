import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import R from 'ramda';
import Button from './nui/Button';
import DisplayPopout from './DisplayPopout';

interface IRoloState {
  active: string;
  isOpen(id: string): boolean;
  toggle(id: string): void;
  toggleAll(): void;
}
const RoloContext = React.createContext<IRoloState | undefined>(undefined);

function useRolodex() {
  const context = useContext(RoloContext);
  if (!context) throw new Error('RoloContext is undefined');

  return context;
}

interface ICard {
  id: string;
  heading(props: { open: boolean }): React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  className?: string;
  children?: React.ReactNode;
}
export const Card = ({ id, heading, onClick, className, children }: ICard) => {
  const rolodex = useRolodex();
  const active = rolodex.active === id;

  function toggle() {
    rolodex.toggle(id);
  }

  const open = rolodex.isOpen(id);
  const classname = classnames('rolodex-card', className, {
    active,
    min: !open,
  });

  return (
    <li
      className={classname}
      onClick={e => {
        document.body.classList.add('detail-area-open');
        onClick && onClick(e);
      }}
    >
      <div className="rolodex-card-heading">
        <Button type="expand" expanded={open} onClick={toggle}>
          Details
        </Button>
        {heading({ open })}
      </div>
      {open && <div className="rolodex-card-content">{children}</div>}
    </li>
  );
};

interface ISortable {
  options: (readonly [string, React.ReactNode])[];
  value: string;
  onChange(value: string): void;
}

const Sortable = ({ options, value, onChange }: ISortable) => (
  <>
    <h4>Sort by</h4>
    <ul className="rolodex-sort-options">
      {options.map(([id, title]) => (
        <li key={id} className="rolodex-sort-item">
          <Button
            className={classnames({ active: value === id })}
            onClick={() => {
              onChange(id);
            }}
          >
            {title}
          </Button>
        </li>
      ))}
    </ul>
  </>
);

interface IWheel {
  heading?: React.ReactNode;
  className?: string;
  sortable?: ISortable;
  filter?: React.ReactNode;
  children: React.ReactElement<ICard>[];
}
export const Wheel = ({
  heading,
  sortable = undefined,
  className,
  filter,
  children,
}: IWheel) => {
  const rolodex = useRolodex();
  const active = rolodex.isOpen('');
  return (
    <div className={classnames('rolodex-column rolodex-wheel', className)}>
      {heading && <div className="rolodex-heading">{heading}</div>}
      {filter && (
        <DisplayPopout className="rolodex-popout boxed" title="Options">
          <div className="rolodex-filter">{filter}</div>
        </DisplayPopout>
      )}
      <div className="rolodex-sort">
        <Button type="expand" expanded={active} onClick={rolodex.toggleAll}>
          Details
        </Button>
        {!!sortable && <Sortable {...sortable} />}
      </div>
      <ul className="rolodex-wheel-items">{children}</ul>
    </div>
  );
};

interface IContent {
  className?: string;
  children?: React.ReactNode;
}
export const Content = ({ className, children }: IContent) => {
  return (
    <div className={classnames('rolodex-column rolodex-content', className)}>
      <div className="content">
        <button
          className="close-detail-area"
          onClick={() =>
            void document.body.classList.remove('detail-area-open')
          }
        >
          <span className="icon-cancel-1 " />
        </button>
        {children}
      </div>
    </div>
  );
};

interface IRolodex {
  active: string;
  className?: string;
  children: [
    React.ReactElement<IWheel, typeof Wheel>,
    React.ReactElement<IContent, typeof Content>
  ];
}
const Rolodex = ({ active, className, children }: IRolodex) => {
  const [all, setAll] = useState(true);
  const [open, setOpen] = useState<string[]>([]);

  useEffect(
    () => () => void document.body.classList.remove('detail-area-open'),
    []
  );

  function isOpen(id: string) {
    return open.includes(id) !== all;
  }

  function toggle(id: string) {
    setOpen(state =>
      state.includes(id) ? R.reject(R.equals(id), state) : [...state, id]
    );
  }

  function toggleAll() {
    if (!open.length) setAll(!all);
    else setOpen([]);
  }

  const state = { active, isOpen, toggle, toggleAll };
  return (
    <RoloContext.Provider value={state}>
      <div className={classnames('rolodex', className)}>{children}</div>
    </RoloContext.Provider>
  );
};

Rolodex.Card = Card;
Rolodex.Wheel = Wheel;
Rolodex.Content = Content;
export default Rolodex;
