import React, { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { DatePicker, Button, Checkbox, Input } from 'Components/nui';
import DropCheck from 'Components/nui/form/DropCheck';
import { useToggle } from 'react-use';
import R from 'ramda';
import FilterProduct from './FilterProduct';

const PresetsList = () => {
  const solution = useStoreState(state => state.auth.solution);
  const prefs = useStoreState(state => state.auth.userPrefs);
  const presets = R.sortBy(
    R.prop('name'),
    R.pathOr([], ['marketplace', 'presets'], prefs)
  ).filter(item => solution && item.solution === solution.id);

  const setData = useStoreActions(action => action.market.setData);
  const deletePreset = useStoreActions(action => action.market.deletePreset);

  return (
    <>
      <h2>Filter presets</h2>
      {presets && presets.length > 0 ? (
        <ul>
          {presets.map(item => (
            <li key={item.id} id={item.id}>
              <Button type="buttonlink" onClick={() => setData(item.data)}>
                <span title={item.name}>{item.name}</span>
              </Button>
              <Button
                className="deleteme"
                onClick={() => deletePreset(item.id)}
              >
                <span className="icon-trash" title="Delete preset"></span>
              </Button>
            </li>
          ))}
        </ul>
      ) : (
        <p>Your saved filter presets will be listed here.</p>
      )}
    </>
  );
};

const PresetForm = () => {
  const savePreset = useStoreActions(action => action.market.savePreset);
  const solution = useStoreState(state => state.auth.solution);

  const [on, toggle] = useToggle(false);
  const [name, setName] = useState('');

  const save = () => {
    setName('');
    toggle(false);
    savePreset({ name, solution: solution.id });
  };

  return (
    <div className="">
      <Checkbox checked={on} onChange={toggle}>
        Save as a preset
      </Checkbox>
      {on && (
        <div className="nui-field-holder nui-fieldset save-order-preset">
          <Input
            placeholder="Name for preset"
            className="pr-75"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <Button type="primary" onClick={save} disabled={name === ''}>
            <span className="icon-floppy button tiny secondary">Save</span>
          </Button>
        </div>
      )}
    </div>
  );
};

const ProductFilter = () => {
  return (
    <div className="nui-field-holder cb-set stack-9">
      <label>Products</label>
      <FilterProduct />
    </div>
  );
};

const TypeFilter = () => {
  const fType = useStoreState(state => state.market.fType);
  const setData = useStoreActions(action => action.market.setData);

  return (
    <div className="nui-field-holder cb-set stack-7">
      <label htmlFor="type">Type</label>

      <DropCheck
        name="type"
        title="All"
        options={[
          ['offer', 'Offers'],
          ['bid', 'Bids'],
        ]}
        value={fType}
        onChange={(_, values) => {
          setData({ fType: values });
        }}
      />
    </div>
  );
};

const TagsFilter = () => {
  const name = 'tags';

  const tags = useStoreState(state => state.auth.solutionTags);
  const fTags = useStoreState(state => state.market.fTags);
  const setData = useStoreActions(action => action.market.setData);

  if (!tags) return null;

  return (
    <div className="nui-field-holder cb-set stack-8">
      <label htmlFor={name}>Product groups</label>
      <DropCheck
        name={name}
        title="All"
        options={tags.map(item => [item.id, item.name])}
        value={fTags}
        onChange={(_, values) => {
          setData({ fTags: values });
        }}
      />
    </div>
  );
};

const UpdatedFilter = () => {
  const name = 'updated';
  const fUpdated = useStoreState(state => state.market.fUpdated);
  const setData = useStoreActions(action => action.market.setData);

  return (
    <div className="nui-field-holder stack-6">
      <label htmlFor={name}>Published between</label>
      <DatePicker.RangePicker
        name
        value={fUpdated}
        format="DD MMM YYYY"
        onChange={value => {
          setData({ fUpdated: value });
        }}
      />
    </div>
  );
};

const EtdFilter = () => {
  const name = 'etd';
  const fEtd = useStoreState(state => state.market.fEtd);
  const setData = useStoreActions(action => action.market.setData);

  return (
    <div className="nui-field-holder stack-5">
      <label htmlFor={name}>Estimated time of departure</label>
      <DatePicker.RangePicker
        name
        value={fEtd}
        format="DD MMM YYYY"
        onChange={value => {
          setData({ fEtd: value });
        }}
      />
    </div>
  );
};

const OriginsFilter = () => {
  const name = 'origins';
  const options = useStoreState(state => state.market.origins);
  const fOrigins = useStoreState(state => state.market.fOrigins);
  const setData = useStoreActions(action => action.market.setData);

  const solution = useStoreState(state => state.auth.solution);
  if (!R.pathOr(false, ['columns', 'origins', 'valid'], solution)) return null;

  return (
    <div className="nui-field-holder cb-set stack-4">
      <label htmlFor={name}>Origins</label>
      <DropCheck
        name={name}
        title="All"
        options={options}
        value={fOrigins}
        onChange={(_, values) => {
          setData({ fOrigins: values });
        }}
      />
    </div>
  );
};

const LocationsFilter = () => {
  const name = 'locations';
  const options = useStoreState(state => state.market.locations);
  const fLocations = useStoreState(state => state.market.fLocations);
  const setData = useStoreActions(action => action.market.setData);

  const solution = useStoreState(state => state.auth.solution);
  if (!R.pathOr(false, ['columns', 'locations', 'valid'], solution))
    return null;

  return (
    <div className="nui-field-holder cb-set stack-3">
      <label htmlFor={name}>Locations</label>
      <DropCheck
        name={name}
        title="All"
        options={options}
        value={fLocations}
        onChange={(_, values) => {
          setData({ fLocations: values });
        }}
      />
    </div>
  );
};

// const ResetButton = () => {
//   const reset = useStoreActions(state => state.market.resetFilter);

//   return (
//     <div>
//       <Button type="reverse" onClick={reset}>
//         Reset filter
//       </Button>
//     </div>
//   );
// };

const FilterStats = () => {
  const total = useStoreState(state => state.market.totalOrders);
  const totalFiltered = useStoreState(
    state => state.market.totalFilteredOrders
  );
  const isFiltered = useStoreState(state => state.market.isFiltered);
  const reset = useStoreActions(state => state.market.resetFilter);

  return (
    <div className="nui-row">
      {isFiltered ? (
        <div className="market-filter-message">
          <p className="inline-block">
            Showing {totalFiltered} out of {total} order{total !== 1 && 's'}{' '}
            <span className="inline-block ml-5 mr-10">|</span>
          </p>
          <Button type="buttonlink" onClick={reset}>
            Reset filter
          </Button>
        </div>
      ) : (
        <div className="market-filter-message offset">
          <p className="inline-block">
            We found {total} order{total !== 1 && 's'}
          </p>
        </div>
      )}
    </div>
  );
};

export default () => {
  const visible = useStoreState(state => state.market.filterVis);

  return (
    <>
      {visible && (
        <div className="market-filter-full filter-section">
          <div className="nui-row">
            <div className="filter-presets">
              <PresetsList />
            </div>
            <div className="filter-block">
              <h3 className="show-for-small-only">Filter options</h3>

              <ProductFilter />
              <TagsFilter />
              <TypeFilter />
              <EtdFilter />
              <UpdatedFilter />
              <OriginsFilter />
              <LocationsFilter />
              <PresetForm />
            </div>
          </div>
        </div>
      )}
      <FilterStats />
    </>
  );
};
