import React from 'react';
import classNames from 'classnames';

export default ({ narrow, BidBtn, OfferBtn }) => {
  return (
    <td
      className={classNames({
        empty: narrow,
        'no-trades': narrow,
      })}
    >
      <div className="data">
        <div className="current">
          {BidBtn}
          {OfferBtn}
        </div>
        <div className="last" />
      </div>
    </td>
  );
};
