import type { Moment } from 'moment-timezone';
import type { Order } from '~/models/tenders';
import React, { useState } from 'react';
import Rolodex from 'Components/Rolodex';
import NavList from 'Components/nui/NavList';
import { Tooltip } from 'Components/nui';
import {
  OrderTicket,
  CounterDetails,
  OrderDetails,
  OwnCounter,
  OrderActions,
  EditCounterButton,
  OrderIcons,
  CounterRanking,
  CounterActions,
  IsAutobid,
  MyVariant,
  ResTag,
  Relist,
} from './components';
import { capitalize, inArrayIf } from '~/utils';
import Access, { can } from 'Components/Access';
import CounterForm from './CounterForm';
import { useHistory } from 'react-router-dom';
import * as Data from 'Components/Data';
import CounterTrade from './CounterTrade';
import { FieldVisibility, ToggleVisibility } from 'Components/FieldVisibility';
import { useStoreState } from '~/store';
import { NoData } from 'Components/InfoMessage';
import {
  routeUrl,
  matchUrl,
  RoutingTable,
  usePathTools,
  withPreload,
  withRouteX,
} from '~/router';
import * as service from '~/services/tenders';
import R from 'ramda';

const sortByString = (a: string | undefined, b?: string | undefined) =>
  a && b ? a.localeCompare(b) : +!a - +!b;

const sortByDate = (a?: Moment, b?: Moment) => (a && b ? a.diff(b) : +!a - +!b);

function sortByCreated(a: Order, b: Order) {
  return sortByDate(a.created, b.created);
}

function sortByProduct(a: Order, b: Order) {
  const diff = sortByString(a.product.name, b.product.name);

  if (diff === 0) return sortByCreated(a, b);
  return diff;
}

function sortByProductionMonth(a: Order, b: Order) {
  const aa = a.productionmonth?.val || '';
  const bb = b.productionmonth?.val || '';
  const diff = sortByString(aa, bb);

  if (diff === 0) return sortByCreated(a, b);
  return diff;
}

function sortByAttr(attr: string) {
  return function (a: Order, b: Order) {
    const aa = a.indexedAttributes?.[attr]?.value[0];
    const bb = a.indexedAttributes?.[attr]?.value[0];
    const diff = sortByString(aa?.name, bb?.name);

    if (diff === 0) return sortByProduct(a, b);
    return diff;
  };
}

function sortByExportable(a: Order, b: Order) {
  const sorted = [null, undefined, false, true];
  const diff = sorted.indexOf(b.exportable) - sorted.indexOf(a.exportable);
  if (diff === 0) return sortByProduct(a, b);
  return diff;
}

function sortOrders(sortOn: string) {
  if (sortOn === 'created') return sortByCreated;
  if (sortOn === 'productionmonth') return sortByProductionMonth;
  if (sortOn === 'product') return sortByProduct;
  if (sortOn === 'exportable') return sortByExportable;
  return sortByAttr(sortOn);
}

type PageName =
  | 'tender-order-details'
  | 'tender-counter'
  | 'tender-counter-orders';

const Orders = withPreload({
  route: 'tender-orders',
  preload: service.observe.orders(),
})(({ data }) => {
  const { tender, orders } = data!;
  const exportableOrders = orders.filter(o => o.exportable);
  const solution = useStoreState(state => state.auth.solution);
  const [attributes, solutionId, isTenderReserve] = useStoreState(state => [
    state.auth.solution.attributes || [],
    state.auth.solution.id,
    state.auth.solution.settings.tenderreserveprice === true,
  ]);
  const [sortby, setSortby] = useState<string>('product');

  const history = useHistory();
  const { matchParams } = usePathTools();
  const tenderId = tender.id;
  const { orderId = '' } = matchParams('tender-order-view');
  const params = { tenderId, orderId };

  const redirect = (route: PageName, order: string) =>
    void history.push(routeUrl(route, { ...params, orderId: order }));

  const onClick = (order: Order) => () => {
    if (tender.willStart()) redirect('tender-order-details', order.id);
    else if (tender.isRunning()) {
      if (tender.participating) {
        if (order.counters.mine) {
          if (tender.method === 'blind') redirect('tender-counter', order.id);
          else redirect('tender-counter-orders', order.id);
        } else {
          redirect('tender-order-details', order.id);
        }
      } else {
        if (order.counters.length) redirect('tender-counter-orders', order.id);
        else redirect('tender-order-details', order.id);
      }
    } else {
      if (order.counters.length) redirect('tender-counter-orders', order.id);
      else redirect('tender-order-details', order.id);
    }
  };

  const sorted = orders.sort(sortOrders(sortby));
  const first = sorted[0];

  const tenderHasReserveOrder = R.any(
    R.o(R.not, R.isNil),
    R.map(R.path(['reserve', 'reserve_met']), sorted)
  );

  if (!orders.length)
    return (
      <>
        <hr />
        <NoData className="mt-10 mb--11 p-100">
          This tender has no {tender.ordername.order}s
        </NoData>
      </>
    );

  const hasProductionmonth = R.any(o => !!o.productionmonth, orders);

  const sortOptions: (readonly [string, React.ReactNode])[] = [
    ['created', 'Created'],
    ...inArrayIf(hasProductionmonth, [
      'productionmonth',
      'Production month',
    ] as const),
    ['product', 'Product'],
    ...attributes.map(x => [x.name, x.desc] as const),
    ...inArrayIf(
      exportableOrders.length > 0 && !!solution?.exportprice?.flatfee,
      ['exportable', 'Exportable'] as const
    ),
  ];

  return (
    <FieldVisibility
      store={'tender-orders-fields-v2.0_' + solutionId}
      initial={{ loading: false, supplier: false, location: false }}
    >
      <Rolodex active={orderId}>
        <Rolodex.Wheel
          heading={
            <>
              <h3>{capitalize(tender.ordertype.order)}s</h3>
              {isTenderReserve && tenderHasReserveOrder && (
                <div className="rolodex-reserve-header">
                  <div className="rolodex-reserve-items">
                    <div>
                      <span className="res-icon inline-block">RES</span>
                      <span>Reserve met</span>
                    </div>
                    <div>
                      <span className="icon-attention inline-block" />
                      <span>Reserve not met</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          }
          sortable={{
            value: sortby,
            onChange: setSortby as (x: string) => void,
            options: sortOptions,
          }}
          filter={
            <div className="nowrap">
              <strong className="all-black block mb-5">
                Display on summary
              </strong>
              <ToggleVisibility name="loading" title="Loading details" />
              <ToggleVisibility name="etd" title="ETD" />
              <ToggleVisibility
                name="location"
                title={'Delivery ' + tender.delivery}
              />
              <ToggleVisibility name="attachments" title="Attachments" />
              <ToggleVisibility name="comment" title="Comments" />
            </div>
          }
        >
          {sorted.map(o => (
            <Rolodex.Card
              key={o.id}
              id={o.id}
              className="tender-order-ticket"
              onClick={onClick(o)}
              heading={({ open }) => (
                <>
                  <h4>
                    <OrderIcons order={o} />
                    <Tooltip
                      title={
                        <span className="icon-tooltip">
                          <span className="icon-info-circled" />
                          <p>{o.product.name}</p>
                          <Data.Attributes value={o.attributes} />
                        </span>
                      }
                    >
                      {o.product.name}
                    </Tooltip>
                    <MyVariant order={o} />
                  </h4>

                  {!tender.participating && !tender.ended() && (
                    <OrderActions tender={tender} order={o} />
                  )}
                  <CounterRanking
                    tender={tender}
                    order={o}
                    detail={
                      open ||
                      !(can('add', o.counters) || can('edit', o.counters.mine))
                    }
                  >
                    {!open && <CounterActions tender={tender} order={o} />}
                    <ResTag reserve={o.reserve} />
                  </CounterRanking>
                </>
              )}
            >
              <OrderTicket order={o} tender={tender} />
            </Rolodex.Card>
          ))}
        </Rolodex.Wheel>
        <Rolodex.Content>
          <RoutingTable
            routes={[OrderView]}
            redirect={routeUrl('tender-order-details', {
              ...params,
              orderId: first.id,
            })}
          />
        </Rolodex.Content>
      </Rolodex>
    </FieldVisibility>
  );
});
export default withRouteX({ name: 'tender-orders' })(Orders);

const DetailSection = withPreload({
  route: 'tender-order-view',
  preload: service.observe.order(),
})(({ data: { tender, order } }) => {
  const { matchParams } = usePathTools();
  const params = matchParams('tender-order-view');
  const viewMyCounter = !!order.counters.mine || can('add', order.counters);
  const viewCounterHistory =
    tender.started() &&
    !!(tender.participating ? order.counters.mine : order.counters.length);

  const canRelist = useStoreState(
    state =>
      state.auth.solution.roles.find(r =>
        ['manager', 'trader'].includes(r.role)
      ) && tender.owned
  );

  return (
    <>
      <NavList>
        <NavList.Tab
          url={routeUrl('tender-order-details', params)}
          path={matchUrl('tender-order-details')}
        >
          {capitalize(tender.ordername.order)} details
        </NavList.Tab>
        {viewMyCounter && (
          <NavList.Tab
            url={routeUrl('tender-counter', params)}
            path={matchUrl('tender-counter')}
          >
            {order.counters.mine ? 'My' : 'Place'} {!tender.started() && 'auto'}{' '}
            {tender.ordername.counter}
          </NavList.Tab>
        )}
        {tender.started() && (
          <NavList.Tab
            url={routeUrl('tender-counter-orders', params)}
            path={matchUrl('tender-counter-orders')}
          >
            {capitalize(tender.ordername.counter)}s ({order.counters.length})
          </NavList.Tab>
        )}
        {tender.owned && (
          <NavList.Tab
            url={routeUrl('tender-order-history', params)}
            path={matchUrl('tender-order-history')}
          >
            {capitalize(tender.ordername.order)} history
          </NavList.Tab>
        )}
        {viewCounterHistory && (
          <NavList.Tab
            url={routeUrl('tender-counter-history', params)}
            path={matchUrl('tender-counter-history')}
          >
            {capitalize(tender.ordername.counter)} history
          </NavList.Tab>
        )}
        {order.buyers && order.startprice.val && (
          <NavList.Tab
            url={routeUrl('tender-order-buyers', params)}
            path={matchUrl('tender-order-buyers')}
          >
            Show prices
          </NavList.Tab>
        )}
      </NavList>
      <div className="order-title">
        <h3>
          {order.product.name}
          <MyVariant order={order} />
        </h3>
        {canRelist && tender.ended() && <Relist order={order} />}
      </div>
      {order.attributes && <Data.Attributes value={order.attributes} />}
      <hr className="mt-0 mb-10" />

      <RoutingTable
        routes={[
          DetailsPage,
          OrderHistoryPage,
          OrderCounterPage,
          CounterOrdersPage,
          CounterHistoryPage,
          OrderBuyersPage,
        ]}
        redirect={routeUrl('tender-order-details', params)}
      />
    </>
  );
});
const OrderView = withRouteX({ name: 'tender-order-view' })(DetailSection);

const Details = withPreload({
  route: 'tender-order-details',
  preload: service.observe.order(),
})(({ data: { tender, order } }) => {
  return (
    <>
      <Data.List className="product-details">
        <Data.Item title="Product">
          <Data.ProductLink product={order.product} />
        </Data.Item>
      </Data.List>
      <OrderDetails order={order} />
      <EditCounterButton tender={tender} order={order} />
    </>
  );
});
const DetailsPage = withRouteX({ name: 'tender-order-details' })(Details);

const OrderHistory = withPreload({
  route: 'tender-order-history',
  preload: service.observe.orderHistory(),
})(({ data: history }) => {
  return (
    <ul>
      {history.map(o => (
        <li key={o.id} className="order-item">
          <OrderDetails order={o} />
        </li>
      ))}
    </ul>
  );
});
const OrderHistoryPage = withRouteX({
  name: 'tender-order-history',
})(OrderHistory);

const CounterOrders = withPreload({
  route: 'tender-counter-orders',
  preload: service.observe.order(),
})(({ data: { tender, order } }) => {
  return (
    <ul className="tender-counter-orders-list">
      {order.counters.ranked.map(counter => (
        <li key={counter.id}>
          <OwnCounter counter={counter} />
          <IsAutobid counter={counter} />
          <CounterDetails counter={counter} />
          <Access to="trade" model={order}>
            {!counter.trade && (
              <CounterTrade tender={tender} order={order} counter={counter} />
            )}
          </Access>
        </li>
      ))}
    </ul>
  );
});
const CounterOrdersPage = withRouteX({
  name: 'tender-counter-orders',
})(CounterOrders);

const CounterHistory = withPreload({
  route: 'tender-counter-history',
  preload: service.observe.counterHistory(),
})(({ data: counters }) => {
  return (
    <ul className="counter-history-content">
      {counters.map(h => (
        <li key={h.id} className="counter-item">
          <IsAutobid counter={h} />
          <CounterDetails counter={h} />
        </li>
      ))}
    </ul>
  );
});
const CounterHistoryPage = withRouteX({
  name: 'tender-counter-history',
})(CounterHistory);

const OrderCounter = withPreload({
  route: 'tender-counter',
  preload: service.observe.order(),
})(({ data: { order } }) => {
  const mine = order.counters.mine;
  const showForm = can('add', order.counters) || can('edit', mine);

  return (
    <>
      {mine && <CounterDetails counter={mine} />}
      {showForm && <CounterForm />}
    </>
  );
});
const OrderCounterPage = withRouteX({
  name: 'tender-counter',
})(OrderCounter);

const OrderBuyers = withPreload({
  route: 'tender-order-buyers',
  preload: service.observe.buyers(),
})(({ data: { order, buyers } }) => {
  return (
    <ul className="order-buyers-list">
      {buyers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(buyer => (
          <li key={buyer.id}>
            <Data.List>
              <Data.Item>{buyer.name}</Data.Item>
              <Data.Item>
                <Data.PriceTicker
                  title="Price"
                  value={order.startprice.new({ ...buyer.price })}
                />
              </Data.Item>
              {buyer.price.reason && (
                <Data.Item title="Reason">{buyer.price.reason}</Data.Item>
              )}
            </Data.List>
          </li>
        ))}
    </ul>
  );
});
const OrderBuyersPage = withRouteX({
  name: 'tender-order-buyers',
})(OrderBuyers);
