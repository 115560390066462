import React from 'react';
import classnames from 'classnames';

type TLoadingSize = 'tiny' | 'small' | 'medium' | 'large' | 'huge';

interface ILoading {
  reverse?: boolean;
  className?: string;
  size?: TLoadingSize;
}
export default function Loading({
  className,
  size = 'large',
  reverse = false,
}: ILoading) {
  return (
    <div className={classnames('nui-loading', size, className, { reverse })} />
  );
}
