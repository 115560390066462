import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';

export function UserAvatar() {
  const user = useStoreState(state => state.auth.userModel);
  return (
    <Link to="/account">
      <div className="icon-user">{user._shortname}</div>
    </Link>
  );
}
