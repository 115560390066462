import React, { useState, useContext, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Button, Input } from 'Components/nui';
import R from 'ramda';
import PhoneInput, {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { ApiContext } from '~/context';

export default () => {
  const user = useStoreState(state => state.auth.user);
  const loadUserProfile = useStoreActions(
    actions => actions.auth.loadUserProfile
  );

  const [editMode, setEditMode] = useState(false);
  const [newPhone, setNewPhone] = useState(user.mobile);
  const [code, setCode] = useState('');
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleting, setDeleting] = useState(false);

  const api = useContext(ApiContext);

  const startOver = () => {
    setEditMode(true);
    setStep(1);
    setCode('');
    setErrorMessage('');
  };

  const deleteMobile = async () => {
    setDeleting(true);
    await api.deleteData('/user/mobile');
    loadUserProfile();
    setDeleting(false);
  };

  const moveToStep2 = async () => {
    setLoading(true);
    setCode('');
    try {
      await api.sendMobileVerifySMS(newPhone);
      setErrorMessage('');
      setStep(2);
    } catch (err) {
      setErrorMessage(
        R.pathOr(
          'Something went wrong. Please try again later.',
          ['response', 'data', 'errors', 0, 'description'],
          err
        )
      );
    }
    setLoading(false);
  };

  const checkCode = async () => {
    setLoading(true);
    try {
      await api.checkMobileVerifyToken(code);
      await loadUserProfile();
      setErrorMessage('');
      setEditMode(false);
    } catch (err) {
      setErrorMessage(
        R.pathOr(
          'Something went wrong. Please try again later.',
          ['response', 'data', 'errors', 0, 'description'],
          err
        )
      );
    }

    setLoading(false);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    // setIsDisabled(false);
    setIsDisabled(!isPossiblePhoneNumber(newPhone));
  }, [newPhone]);

  return (
    <div className="mobile-login-option">
      <strong className="phone-label">Mobile phone</strong>{' '}
      {editMode ? (
        <>
          {step === 1 && (
            <>
              <span className="block mb-10">
                Enter your mobile phone number below. A code will be sent to
                this number for verification.
              </span>
              <div className="ant-col ant-form-item-control-wrapper">
                <div className="ant-form-item-control has-error">
                  <PhoneInput
                    international
                    value={newPhone}
                    onChange={setNewPhone}
                  />
                  {errorMessage && (
                    <div className="ant-form-explain">{errorMessage}</div>
                  )}
                </div>
              </div>
              <div className="button-set pt-15">
                <Button
                  type="primary"
                  loading={loading}
                  disabled={isDisabled}
                  onClick={moveToStep2}
                >
                  Continue
                </Button>
                <Button
                  type="simple"
                  className="ml-5"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
          {step === 2 && (
            <div className="phone-verification">
              <p>
                A verification code has been sent to{' '}
                <strong className="all-black">
                  {formatPhoneNumberIntl(newPhone)}
                </strong>
                . Please enter the code below.
              </p>
              <div className="ant-col ant-form-item-control-wrapper">
                <div className="ant-form-item-control has-error">
                  <Input
                    className="phone-verification-input"
                    value={code}
                    autocomplete="one-time-code"
                    onChange={e => setCode(e.target.value)}
                  />
                  {errorMessage && (
                    <div className="ant-form-explain">{errorMessage}</div>
                  )}
                </div>
              </div>
              <div className="button-set mt-20 mb-20">
                <Button type="primary" loading={loading} onClick={checkCode}>
                  Verify
                </Button>
                <Button
                  type="simple"
                  className="ml-5"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              </div>

              <p>
                Did not receive the code?{' '}
                <Button
                  type="link"
                  className="nui-button nui-button-link m-0 p-0"
                  onClick={startOver}
                >
                  Check the number and try again.
                </Button>
              </p>
            </div>
          )}
        </>
      ) : (
        <span className="right align-right">
          {user.mobile ? (
            <>
              <span className="phone-number">{user.mobile}</span>
              {user.mobileverified ? (
                <span className="verified">Verified</span>
              ) : (
                <span className="not-verified">Not verified</span>
              )}
            </>
          ) : (
            <span className="not-specified">(Not specified)</span>
          )}

          <Button
            type="secondary"
            size="small"
            className="nui-button nui-button-icon ml-10"
            onClick={startOver}
          >
            <span className="icon-pencil"></span>
          </Button>
          {user.mobile && (
            <Button
              onClick={deleteMobile}
              type="reverse"
              className="nui-button nui-button-icon ml-5"
              disabled={deleting}
            >
              {deleting ? (
                <span className="icon-spin6 animate-spin"></span>
              ) : (
                <span className="icon-trash"></span>
              )}
            </Button>
          )}
        </span>
      )}
    </div>
  );
};
