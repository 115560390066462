import React from 'react';
import { useSharedProduct } from '.';
import { Details, LoadingDetails, Specifications } from 'Pages/Products';
import R from 'ramda';
import { Alert } from 'Components/nui';
import { useStoreState } from '~/store';
import { WarehousesList } from 'Components/Lists';
import DisplayedPrices from '../DisplayedPrices';
import * as Data from 'Components/Data';
import { Attribute } from '~/models/utils';

export default () => {
  const [product] = useSharedProduct();
  const solution = useStoreState(state => state.auth.solution);
  const tradeActions = useStoreState(state => state.auth.userTradeActions);

  const attributes = R.toPairs(R.prop('attributes', product))
    .map(([name, value]) => new Attribute(name, value))
    .sort((a, b) => a.name.localeCompare(b.name));

  const customers = R.prop('customers', product);

  if (!product) return null;

  const { owner } = product;
  const division = R.pipe(
    R.pathOr({}, ['roles']),
    R.map(R.prop('division')),
    R.find(R.propEq('id', product?.division?.id))
  )(solution);

  const title =
    (owner?.id === division?.id) === division?.seller
      ? 'Delivery from'
      : 'Delivery to';

  return (
    <>
      <div className="full-page-header">
        <div className="filter-export" />
        <div className="detail-lists">
          <div className="list-box double">
            <Details product={product} />
            {attributes.length > 0 && (
              <div>
                <h3>Product attributes</h3>
                <Data.Attributes value={attributes} />
              </div>
            )}
            <hr className="mt-0 hide-for-xlarge" />
          </div>

          <div className="list-box">
            {R.pathSatisfies(
              item => item && item.length > 0,
              ['specifications'],
              product
            ) ? (
              <Specifications specifications={product.specifications} />
            ) : (
              <>
                <h2>Specifications</h2>
                <ul className="pt-10">
                  <li>
                    <Alert hasicon className="align-center">
                      No specifications to display.
                    </Alert>
                  </li>
                </ul>
              </>
            )}
          </div>
          <div className="list-box">
            {R.pathSatisfies(
              item => item && item.length > 0,
              ['warehouses'],
              product
            ) ? (
              <WarehousesList title={title} warehouses={product.warehouses} />
            ) : (
              <>
                <h2>{title}</h2>
                <ul className="pt-10">
                  <li className="stacked">
                    <Alert hasicon className="align-center">
                      No locations to display.
                    </Alert>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>

        {customers !== undefined && (
          <>
            <hr className="m-0" />
            <div className="detail-lists">
              <div className="list-box full">
                <h2>Customers</h2>
                {customers.length ? (
                  <ul className="pt-10">
                    {customers.map(item => (
                      <li key={item.id} className="ordered-list">
                        {item.name}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Alert hasicon className="align-center mt-10 mb-10">
                    No customers to display.
                  </Alert>
                )}
              </div>
            </div>
          </>
        )}

        <hr className="mt-0" />
        <h2 className="light m-0">Loading options</h2>
      </div>
      <div className="full-page-content dark mt--1">
        {product.loading.length ? (
          <LoadingDetails product={product} details={product.loading} />
        ) : (
          <div className="full-page-messaging b-0">
            <div className="icon-inbox bordered align-center m-0">
              No loading options available
            </div>
          </div>
        )}

        {solution.freight && tradeActions.includes('sell') && (
          <div className="pt-30">
            <DisplayedPrices
              productId={product.id}
              init={{ price: product.step }}
            />
          </div>
        )}
      </div>
    </>
  );
};
