import React, { useState } from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

export default props => {
  const {
    children,
    checked,
    indeterminate,
    onChange,
    className,
    disabled,
    name,
    id,
    title = children,
    ...rest
  } = props;

  const [_id] = useState(id || uuid());

  const combo = {};
  if (checked !== undefined) {
    if (onChange === undefined) combo.defaultChecked = !!checked;
    else {
      combo.checked = !!checked;
      combo.onChange = onChange;
    }
  }

  return (
    <div className={classnames('nui-check-fieldset', className)}>
      <input
        id={_id}
        name={name}
        type="checkbox"
        disabled={disabled}
        ref={r => r && (r.indeterminate = indeterminate)}
        {...combo}
        {...rest}
      />
      {children !== undefined && (
        <label htmlFor={_id} className="nui-label" title={title}>
          {children}
        </label>
      )}
    </div>
  );
};
