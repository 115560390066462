import React from 'react';
import R from 'ramda';
import FNumber from 'Components/FNumber';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Alert, Tooltip } from 'Components/nui';
import Address from 'Components/Address';
import { FormattedMessage } from 'react-intl';

export const CustomerDetails = ({ customer }) => {
  let data = [
    ['Name', customer.name],
    ['Shortcode', customer.shortcode],
  ];

  return (
    <>
      <hr className="hide-for-medium mb-15" />
      <h2>Customer details</h2>
      <ul>
        {data.map(([key, value, className]) => (
          <li key={key} className={className}>
            <strong>{key}</strong>
            <span>{value}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export const CustomerAddresses = ({ customer }) => {
  let data = [
    ['Physical', <Address address={customer.physical_address} />, 'stacked'],
    ['Postal', <Address address={customer.postal_address} />, 'stacked'],
  ];

  return (
    <>
      <hr className="hide-for-medium mb-15" />
      <h2>Address details</h2>
      <ul>
        {data.map(([key, value, className]) => (
          <li key={key} className={className}>
            <strong>{key}</strong>
            <span>{value}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export const Currencies = ({ order }) => {
  const data = order.currencies.map(rec => [
    rec.currency,
    <>
      <FNumber value={rec.val} decimalCount={order.priceDecimalCount} />{' '}
      {rec.unit}
    </>,
  ]);

  return (
    <>
      <h2>Additional currencies</h2>
      <ul>
        {data.map(([key, value, className]) => (
          <li key={key} className={className}>
            <strong>{key}</strong>
            <span>{value}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export const OrderTrades = ({ order }) => {
  const trades = R.pathOr([], ['trades'], order);
  const data = trades.map(item => [
    moment(item.created).format('DD MMMM YYYY HH:mm'),
    <Link to={`/trades/${item.id}`}>{item.pid}</Link>,
  ]);

  return (
    <>
      <h2>
        <FormattedMessage
          id="orderdetails-field-associatedtrades-label"
          description="Label for 'Associated trades' field on Order Details"
          defaultMessage="Associated trades"
        />
      </h2>
      <ul className="nocols pr-0">
        {trades.length === 0 && (
          <li className="stacked pr-0">
            <Alert hasicon className="align-center mr-10">
              <FormattedMessage
                id="orderdetails-field-revision-alert"
                description="Alert for 'Associated' field on Order Details"
                defaultMessage="No associated trades found."
              />
            </Alert>
          </li>
        )}
        {data.map(([key, value, className]) => (
          <li key={key} className="stacked">
            <strong>{key}</strong>
            <span>{value}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export const WarehousesList = ({ warehouses, title = 'Locations' }) => {
  if (!warehouses) return null;

  return (
    <>
      <h2>{title}</h2>
      <ul>
        {warehouses
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((location, index) => (
            <li key={location.id} className="ordered-list">
              {location.default && (
                <Tooltip
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled"></span>
                      Default location
                    </span>
                  }
                >
                  <span className="icon-ok-circled default" />
                </Tooltip>
              )}
              <strong>{index + 1}.</strong>
              <span>{location.desc}</span>
            </li>
          ))}
      </ul>
    </>
  );
};
