import React from 'react';
import { useAsync, createStateContext } from 'react-use';
import { useParams } from 'react-router-dom';
import { Order } from '~/models';

import Header from './Header';
import Content from './Content';
import Actions from './Actions';
import History from './History';
import Helmet from 'Components/Helmet';
import { api } from '~/api';
import { Loading } from 'Components/nui';

export const [useSharedOrder, SharedOrderProvider] = createStateContext();

const Loader = () => {
  const { id } = useParams();
  const [order, setOrder] = useSharedOrder();

  useAsync(async () => {
    const data = await api.getData({
      type: 'orders',
      id,
      getResult: x => x.order,
    });

    setOrder(new Order(data));
  }, [id]);

  if (!order) return <Loading size="large" />;

  return (
    <Helmet>
      <title>{`Order ${order.pid}`}</title>
    </Helmet>
  );
};

export default () => {
  return (
    <SharedOrderProvider>
      <div className="full-page-header">
        <Loader />
        <Header />
        <Actions />
        <Content />
      </div>
      <div className="full-page-content">
        <History />
      </div>
    </SharedOrderProvider>
  );
};
