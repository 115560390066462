import moment from 'moment-timezone';
import React from 'react';
import {
  isGrouped,
  getDisabledDate,
  getDisabledTime,
  getDisabledRangeTime,
  roundMinute,
} from '../utils';
import {
  Checkbox,
  DatePicker,
  Icon,
  Input,
  InputNumber,
  Radio,
  Select,
} from 'antd';
import Switch from '~/components/nui/Switch';
export { default as RichTextWidget } from './RichTextWidget';

const { RangePicker } = DatePicker;

// Widgets are replaceble part as render of Fields.
// Although having same interface, widget is mainly used as function instead of as component,
//  for flexibility and compatibility (for those requiring class-based component).
export function SelectWidget({ props, choices, type }) {
  return (
    <Select
      filterOption
      optionFilterProp="children"
      showSearch
      mode={type === 'MultipleSelect' ? 'multiple' : ''}
      dropdownMatchSelectWidth={false}
      getPopupContainer={trigger => trigger.parentNode}
      style={{ width: '100%' }}
      size="large"
      suffixIcon={<Icon type="caret-down" />}
      {...props}
    >
      {isGrouped(choices)
        ? choices.map(group => (
            <Select.OptGroup label={group.label} key={group.key}>
              {group.value.map(item => (
                <Select.Option
                  key={item.key}
                  value={item.value}
                  className={item.className}
                >
                  {item.label}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))
        : choices.map(item => (
            <Select.Option
              key={item.key}
              value={item.value}
              className={item.className}
            >
              {item.label}
            </Select.Option>
          ))}
    </Select>
  );
}

// The purpose of this widget is to pass accepted props to InputNumber
export function InputNumberWidget({ props, min, max, step }) {
  return (
    <InputNumber
      min={min * 1}
      max={max * 1}
      step={step}
      size="large"
      {...props}
    />
  );
}

export function RadioWidget({ props, choices }) {
  return (
    <Radio.Group {...props}>
      {choices.map(({ key, value, label }) => (
        <Radio key={key} value={value}>
          {label}
        </Radio>
      ))}
    </Radio.Group>
  );
}

export function RadioButtonWidget({ props, choices }) {
  return (
    <Radio.Group buttonStyle="solid" {...props}>
      {choices.map(({ key, value, label }) => (
        <Radio.Button key={key} value={value}>
          {label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}

export function TextAreaWidget({ props }) {
  return (
    <Input.TextArea
      autoSize={{ minRows: 2, maxRows: 5 }}
      size="large"
      {...props}
    />
  );
}

export function InputWidget({ props }) {
  return <Input size="large" {...props} />;
}

export function DateTimeWidget({ props, fromDate, minuteStep, tz }) {
  const rounded = fromDate ? roundMinute(fromDate, minuteStep) : fromDate;

  return (
    <DatePicker
      format="ll HH:mm Z"
      disabledDate={getDisabledDate(rounded)}
      disabledTime={getDisabledTime(rounded)}
      getCalendarContainer={trigger => trigger.parentNode}
      defaultPickerValue={moment()}
      size="large"
      showTime={{
        minuteStep,
        use12Hours: false,
        format: 'HH:mm',
      }}
      renderExtraFooter={() => (
        <span>
          <strong>Timezone: </strong>
          {moment.tz.guess()}
        </span>
      )}
      {...props}
    />
  );
}

export function DateTimeRangeWidget({ props, fromDateTime, interval, tz }) {
  const rounded = roundMinute(fromDateTime, interval);

  return (
    <RangePicker
      disabledDate={getDisabledDate(rounded)}
      disabledTime={getDisabledRangeTime(rounded)}
      format="ll HH:mm Z"
      showTime={{
        // TODO this functionality should be extracted outside the widget
        minuteStep: interval,
        secondStep: 60,
        hideDisabledOptions: true,
        defaultValue: rounded,
      }}
      size="large"
      defaultPickerValue={moment()}
      renderExtraFooter={() => (
        <span>
          <strong>Timezone:</strong>
          {moment.tz.guess()}
        </span>
      )}
      {...props}
    />
  );
}

export function DateWidget({ props, fromDate, tz }) {
  return (
    <DatePicker
      format="LL"
      disabledDate={getDisabledDate(fromDate)}
      getCalendarContainer={trigger => trigger.parentNode}
      size="large"
      {...props}
    />
  );
}

export function DateRangeWidget({ props, fromDate, tz }) {
  const dateFormat = 'LL';

  return (
    <DatePicker.RangePicker
      format={dateFormat}
      disabledDate={getDisabledDate(fromDate)}
      // ranges={getRanges(4)}
      size="large"
      {...props}
    />
  );
}

// TODO Checkbox for boolean, CheckboxGroup
export function CheckboxWidget({ initialValue, props }) {
  return <Checkbox defaultChecked={initialValue} {...props} />;
}

export function SwitchWidget({ initialValue, props }) {
  return <Switch defaultChecked={initialValue} {...props} />;
}

export function FileWidget({ fileRef, multiple = true, props }) {
  return <input multiple={multiple} ref={fileRef} {...props} type="file" />;
}
