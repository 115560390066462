import React, { useEffect, useState } from 'react';
import { useConsolidation, useDeliveries } from '~/store/models';
import { ShipmentAccordion, AccordionToggle } from 'Components/nui/Accordion';
import type Trade from '~/store/models/trades/Trade';
import { Button, Select, Loading } from 'Components/nui';
import Trades from './Trades';
import { useConsolidationContext, ConsolidationContext } from '../Context';
import AddDelivery from '../AddDelivery';
import { toast } from 'react-toastify';
import R from 'ramda';
import { Authorised } from 'Components/Authorised';
import { useStoreState } from '~/store';

type IUseDeliveries = ReturnType<typeof useDeliveries>;

export const Consolidate = () => {
  const shipping = useConsolidation();
  const deliveries = useDeliveries({ limit: 50 });

  const roles = useStoreState(state => state.auth.solution.roles);

  const [division, setDivision] = useState(
    (roles[0] || {})?.division?.id || ''
  );

  const load = async () => {
    try {
      await Promise.all([shipping.load(division), deliveries.load()]);
    } catch (error) {
      console.error({ error });
      toast('There was an error while loading data. Please try again later.', {
        type: 'error',
      });
    }
  };

  useEffect(() => {
    load();
  }, [division]);

  return (
    <ConsolidationContext.Provider value={shipping}>
      <div className="full-page-header consolidate p-0">
        <div className="nui-row">
          <hr />
          <h2 className="all-black inline-block division-trades">
            Trades by division
          </h2>
          {roles.length > 1 && (
            <div className="nui-fieldset inline-block select-division-fieldset">
              <div className="select-holder">
                <Select
                  className="select-division"
                  value={division}
                  onChange={setDivision}
                >
                  {roles
                    .sort((a, b) =>
                      a.division.name.localeCompare(b.division.name)
                    )
                    .map(r => (
                      <option key={r.division.id} value={r.division.id}>
                        {r.division.name}
                      </option>
                    ))}
                </Select>
              </div>
            </div>
          )}
        </div>
        {shipping.trades.length ? (
          <div className="consolidation-list">
            {Object.entries(shipping.byDivision())
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([customer, trades]) => (
                <CompanyEntry
                  key={customer}
                  customer={customer}
                  division={division}
                  trades={trades}
                  deliveries={deliveries}
                />
              ))}
          </div>
        ) : shipping.loading ? (
          <div className="consolidation-loading">
            <Loading size="medium" />
          </div>
        ) : (
          <div className="nui-row full-page-messaging align-center mt-1 pt-100 p-100 b-0">
            <div className="icon-inbox bordered align-center">
              There are currently no trades to consolidate
            </div>
          </div>
        )}
      </div>
    </ConsolidationContext.Provider>
  );
};

interface ICompanyEntry {
  customer: string;
  trades: Trade[];
}

const CompanyHeader = ({ customer, trades }: ICompanyEntry) => {
  const consolidation = useConsolidationContext();
  const data = consolidation.customers[customer];

  return (
    <div className="company-header">
      <AccordionToggle />
      <span className="company-name bold all-black">{data?.name}</span>
      <span className="consolidated-count right">
        Consolidate ({consolidation.getConsolidated(customer).length}/
        {trades.length})
      </span>
    </div>
  );
};

interface IStartDelivery {
  openForm(): void;
  division: string;
  customer: string;
  deliveries: IUseDeliveries;
}
const StartDelivery = ({
  openForm,
  division,
  customer,
  deliveries,
}: IStartDelivery) => {
  const consolidation = useConsolidationContext();
  const { addTradesToDelivery } = useDeliveries();
  const [delivery, setDelivery] = useState('new');
  const myDeliveries = deliveries.byCustomer(customer);
  const consolidated = consolidation.getConsolidated(customer);
  const selected = R.find(R.propEq('id', delivery), myDeliveries);
  const plural = consolidated.length === 1 ? 'trade' : 'trades';
  const loading = consolidation.loading || deliveries.loading;

  const addToExisting = async () => {
    try {
      if (selected) {
        await addTradesToDelivery(selected, consolidated);
        toast(
          `Successfully added ${consolidated.length} ${plural} to ${selected.identifier}`
        );
        await Promise.all([consolidation.load(division), deliveries.load()]);
        consolidation.removeAllTrades(customer);
      }
    } catch (error) {
      console.error({ error });
      toast(
        'There was an error consolidating your trades. Please try again later.',
        { type: 'error' }
      );
    }
  };

  return (
    <div className="start-delivery nui-row pt-20 pb-20">
      <div className="nui-fieldset inline-block valign-t m-0 mr-10">
        <div className="select-holder">
          <Select
            className="select-delivery"
            value={delivery}
            onChange={setDelivery}
          >
            {[['new', 'New delivery']]
              .concat(myDeliveries.map(d => [d.id, d.identifier]))
              .map(([id, name]) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
          </Select>
        </div>
      </div>
      <Button
        className="nui-button nui-primary"
        onClick={() => {
          if (delivery === 'new') openForm();
          else if (selected) addToExisting();
        }}
        disabled={loading || !consolidated.length}
        loading={loading}
      >
        Add {consolidated.length || ''} {plural}
      </Button>
    </div>
  );
};

type Props = ICompanyEntry & { deliveries: IUseDeliveries; division: string };
const CompanyEntry = ({ division, customer, trades, deliveries }: Props) => {
  const consolidation = useConsolidationContext();
  const data = consolidation.customers[customer];

  const [modal, setModal] = useState(false);

  const showModal = () => {
    setModal(true);
  };

  const reset = async () => {
    await Promise.all([consolidation.load(division), deliveries.load()]);
    consolidation.removeAllTrades(customer);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <ShipmentAccordion
      header={<CompanyHeader customer={customer} trades={trades} />}
    >
      {data && <Trades division={data} trades={trades} />}
      <Authorised to="edit" model={deliveries}>
        <StartDelivery
          openForm={showModal}
          customer={customer}
          division={division}
          deliveries={deliveries}
        />
        <AddDelivery
          trades={consolidation.getConsolidated(customer)}
          visible={modal}
          close={closeModal}
          otherparty={customer}
          division={division}
          reset={reset}
        />
      </Authorised>
    </ShipmentAccordion>
  );
};
