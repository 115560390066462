import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Link } from 'react-router-dom';
import { useSharedRoute } from '.';

export default () => {
  const [route] = useSharedRoute();

  return (
    <>
      <h1 className="inline mt-10 mr-20 mb-0">
        {route ? (
          <>
            Edit route <span className="smaller">{route.name}</span>
          </>
        ) : (
          'New route'
        )}
      </h1>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="routes" />
        </li>
        <li>
          <Link to="/routes" title="Back to the main routes page">
            Routes
          </Link>
        </li>
        <li>{route ? 'Edit' : 'New'}</li>
      </ul>
    </>
  );
};
