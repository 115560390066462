import React from 'react';
import { useParams } from 'react-router-dom';
import MarketRow from 'Pages/MarketRow';

export default () => {
  const { id } = useParams();
  return (
    <>
      <h2>Order details</h2>

      <MarketRow id={id} />
    </>
  );
};
