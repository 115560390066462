import React from 'react';
import { Rank } from '~/store/types';
import { AuthorisationModel } from '~/store/AuthorisationModel';
import { useStoreState } from '~/store';
import { State } from 'easy-peasy';

export type Auth = State<AuthorisationModel>;

type AclCheck = (a: Auth) => boolean;

type Action = 'view' | 'add' | 'edit' | 'delete';

export type ACL = {
  [A in Action]: AclCheck;
};
interface WithAcl {
  _acl: ACL;
}

export function useAuthorisation() {
  const auth = useStoreState(state => state.authorisation);

  return {
    can(action: Action, model: WithAcl) {
      return model._acl[action](auth);
    },
    cant(action: Action, model: WithAcl) {
      return !model._acl[action](auth);
    },
  };
}

interface Config {
  to: Action;
  model: WithAcl;
  children?: React.ReactNode;
  fallback?: React.ReactNode;
}

export function Authorised({
  to,
  model,
  children = null,
  fallback = null,
}: Config) {
  const { can } = useAuthorisation();

  if (can(to, model)) return <>{children}</>;
  return <>{fallback}</>;
}

export function Denied({
  to,
  model,
  children = null,
  fallback = null,
}: Config) {
  const { cant } = useAuthorisation();

  if (cant(to, model)) return <>{children}</>;
  return <>{fallback}</>;
}

export function allowRoles(...roles: Rank[]) {
  return function (auth: Auth) {
    for (const r of roles) if (auth.hasRole(r)) return true;
    return false;
  };
}

export function allowRolesIf(...roles: Rank[]) {
  return function (predicate: (a: Auth) => boolean) {
    return function (auth: Auth) {
      return allowRoles(...roles)(auth) && predicate(auth);
    };
  };
}

export function denyRoles(...roles: Rank[]) {
  return function (auth: Auth) {
    for (const r of roles) if (auth.hasRole(r)) return false;
    return true;
  };
}
