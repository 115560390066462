import React, { useEffect, useState } from 'react';
import { getEnv } from '~/utils';
import R from 'ramda';
import { v4 as uuid } from 'uuid';

export default () => {
  const domain = getEnv(process.env.REACT_APP_OLD_DOMAIN);
  const [server, setServer] = useState();

  useEffect(() => {
    if ('https://old.nuimarkets.com' !== domain) {
      document.body.classList.add('dev-mode');
      setServer(
        domain.replace('https://', '').replace('.cream.global', '') + ' server'
      );
    }
  }, [domain]);

  useEffect(() => {
    if (server === null) {
      document.body.classList.remove('dev-mode');
    }
  }, [server]);

  if (!server) return null;

  return (
    <div className="server development">
      <div>
        <span
          className="icon-cancel-1 closeme"
          onClick={() => setServer(null)}
        />
        <div>
          {R.range(1, 100).map(x => (
            <span key={uuid()}>{server}</span>
          ))}
        </div>
      </div>
    </div>
  );
};
