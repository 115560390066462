import type * as Responses from 'endpoints.tenders';
import type { Moment } from 'moment-timezone';
import * as service from './internal';
import * as api from '~/fetch';
import moment from 'moment-timezone';
import Socket from '~/fetch/SocketX';

const { errortype } = service.events;

const BASE_INTERVAL = 5e3 as const;
const MAX_INTERVAL = 16e3 as const;

let interval: NodeJS.Timeout | undefined;
let socket: Socket<Responses.TenderEvent>;
let disconnected = false;

let pollStart: Moment | undefined;
let pollEnd: Moment | undefined;

async function connect(solution: string) {
  reset();

  await load();
  loop();

  socket = new Socket({ url: `/solution/${solution}` });
  socket.onmessage('tender-started', ({ data }) => {
    service.events.started({ tender: data.id });
  });
  socket.onmessage('tender-extended', ({ data }) => {
    const { extramins, seconds_remaining } = data;
    service.events.extend({ tender: data.id, extramins, seconds_remaining });
  });
  socket.onmessage('tender-finished', ({ data }) => {
    service.events.finished({ tender: data.id });
  });
}

service.events.onSolution(({ solution }) => {
  connect(solution);
});

service.events.onSignout(() => {
  reset();
});

function reset() {
  clearInterval(interval!);
  socket?.close();
  pollStart = undefined;
  pollEnd = undefined;
  interval = undefined;
}

function loop() {
  if (interval) return;

  interval = setInterval(() => {
    if (!pollStart) {
      poll();
      return;
    }

    // In the middle of a poll
    if (!pollEnd) return;
    if (pollEnd.isBefore(pollStart)) return;

    // Spamming
    const now = moment.utc();
    if (pollEnd.isAfter(now.subtract(2, 'seconds'))) return;

    if (!socket.ready) {
      poll();
    } else {
      // Should long poll
      if (pollStart.isBefore(now.subtract(MAX_INTERVAL, 'milliseconds'))) {
        poll();
      }
    }
  }, BASE_INTERVAL);
}

async function load() {
  const { solution } = service.storage.getOverview();
  if (!solution) throw new Error('tender-overview.load: Invalid solution');

  try {
    const response = await api.tenders.overview(solution);
    await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        const polled = moment(response.headers.get('date') as string);
        service.events.overview({ data, polled });
      },
      async 204(response) {
        const polled = moment(response.headers.get('date') as string);
        service.events.overview({
          data: { tenders: [], total: 0, unfiltered: 0 },
          polled,
        });
      },
      async default(response) {
        service.events.error({
          type: errortype.overviewload,
          data: { response },
        });
        disconnected = true;
      },
    });
  } catch (error) {
    service.events.error({ type: errortype.overviewload, data: { error } });
    disconnected = true;
  }
}

async function poll() {
  const { solution, polled } = service.storage.getOverview();
  if (!solution || !polled) return;
  // throw new Error('tender-overview.poll: Not initialised');

  pollStart = moment.utc();

  try {
    const response = await api.tenders.pollOverview(solution, moment(polled));
    await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        const polled = moment(response.headers.get('date') as string);
        service.events.overview({ data, polled });
        if (disconnected) {
          disconnected = false;
          service.events.reconnect({});
        }
      },
      async 204() {},
      async 304() {
        if (disconnected) {
          disconnected = false;
          service.events.reconnect({});
        }
      },
      async default(response) {
        service.events.error({
          type: errortype.overviewpoll,
          data: { response },
        });
        disconnected = true;
      },
    });
  } catch (error) {
    service.events.error({ type: errortype.overviewpoll, data: { error } });
    disconnected = true;
  }

  pollEnd = moment.utc();
}
