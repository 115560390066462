import React from 'react';
import classnames from 'classnames';

interface ISelect {
  className: string;
  value: string;
  onChange: (v: string) => any;
  children?: React.ReactNode;
}
export default React.forwardRef<HTMLSelectElement, ISelect>(
  ({ className, children, onChange, ...props }, ref) => {
    return (
      <select
        className={classnames('nui-select', className)}
        ref={ref}
        onChange={e => {
          onChange(e.target.value);
        }}
        {...props}
      >
        {children}
      </select>
    );
  }
);
