import React from 'react';
import { useSharedCustomer } from '.';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useStoreState } from '~/store';
import { canEditCustomer } from '~/utils';

export default () => {
  const [customer] = useSharedCustomer();

  const editable = useStoreState(canEditCustomer(customer));

  if (!editable) return null;

  return (
    <div className="full-page-header">
      <div className="order-actions">
        <div className="actions">
          <div className="button-set align-right">
            <Link
              to={`/customers/${customer.id}/edit`}
              className={classNames(
                'nui-button',
                'mb-0',
                'mr-0',
                'nui-primary',
                'icon-pencil'
              )}
            >
              Edit customer
            </Link>
          </div>
        </div>
        <div className="status"></div>
      </div>
    </div>
  );
};
