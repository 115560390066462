import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import ReactQuill, { ReactQuillProps, Range } from 'react-quill';
import classNames from 'classnames';
import { TOOLBAR } from './constants';
import { useOnChange, useTooltipFix, setHtml } from './utils';
import { FormattedMessage } from 'react-intl';

export interface IRichText {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  bounds?: string;
}

type Delta = Parameters<NonNullable<ReactQuillProps['onChange']>>['1'];

export default function RichText({ className, value, onChange }: IRichText) {
  const rootRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const deltaRef = useRef<Delta | null>(null);
  const quillRef = useRef<ReactQuill>(null);
  const [cleanElement, setCleanElement] = useState<Element>();

  const { set, commitValue, getLastSource } = useOnChange(
    value || '',
    (value, source) => source === 'user' && value && onChange && onChange(value)
  );

  useTooltipFix(rootRef);

  const modules = useMemo(
    () => ({
      toolbar: TOOLBAR.map(o => o.def),
    }),
    [toolbarRef.current]
  );

  const [selection, setSelection] = useState<Range>();
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  useEffect(() => {
    const quill = quillRef.current;
    const root = rootRef.current;
    if (!quill || !root) return;
    const input = root.querySelector(
      '.ql-tooltip input[data-link]'
    ) as HTMLInputElement | null;
    if (!input) return;
    input.dataset.link = 'nuimarkets.com';
  }, [rootRef.current, quillRef.current]);

  const lastSource = getLastSource();

  useEffect(() => {
    if (lastSource !== 'system') return;
    const quill = quillRef.current;
    if (!quill) return;
    setHtml(quill, value || '');
  }, [value, lastSource]);

  useEffect(() => {
    if (lastSource !== 'init') return;
    const quill = quillRef.current;
    if (!quill) return;
    const editor = quill.unprivilegedEditor;
    if (!editor) return;
    commitValue(value || '', 'system');
  }, [value]);

  useEffect(() => {
    const element = rootRef.current;
    if (!element) return;
    const cleanElement = element.querySelector('.ql-clean');
    if (!cleanElement) return;
    setCleanElement(cleanElement);
  }, []);

  return (
    <div
      ref={rootRef}
      className={classNames(
        'nui-richtext',
        {
          'has-selection': selection?.length,
          'has-focus': hasFocus,
        },
        className
      )}
      tabIndex={0}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      onKeyDown={e => {
        if (e.key === 'Tab') {
          e.preventDefault();
          e.stopPropagation();
          (rootRef.current?.nextSibling as HTMLElement)?.focus();
        }
        if (e.key === 'Enter' && hasFocus) {
          e.preventDefault();
          e.stopPropagation();
          quillRef.current?.focus();
        }
      }}
    >
      {cleanElement &&
        createPortal(
          <div className="nui-quill-clear-format-text">
            <FormattedMessage
              id="nui-quill-clear-format-text"
              defaultMessage="Clear format"
              description="Clear format tool text"
            />
          </div>,
          cleanElement
        )}
      <ReactQuill
        ref={quillRef}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            quillRef.current?.focus();
          }
        }}
        defaultValue={value}
        onChangeSelection={setSelection}
        onChange={(_value, delta, _source, editor) => {
          deltaRef.current = delta;
          set(editor, 'user');
        }}
        modules={modules}
        preserveWhitespace
      />
    </div>
  );
}
