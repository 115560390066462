import { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from './store';
import R from 'ramda';
import * as api from './fetch';

const getCurrentTimezone = R.pathOr('', ['auth', 'user', 'timezone']);

export function useTimezoneProvider() {
  const timezone = useStoreState(getCurrentTimezone);

  return timezone;
}

export function useTimezone() {
  const [choices, setChoices] = useState<string[]>([]);
  const options = choices.map(choice => ({
    key: choice,
    value: choice,
    label: choice,
  }));

  const current = useStoreState(getCurrentTimezone);
  const updateUser = useStoreActions(actions => actions.auth.updateUser);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    import('./common-timezones.json').then(success =>
      setChoices(success.default)
    );
  }, []);

  async function change(timezone: string) {
    setLoading(true);

    try {
      const response = await api.user.updateTimezone(timezone);
      return await api.utils.processResponse(response, {
        200() {
          updateUser({ timezone: timezone });
          setLoading(false);
          return { success: true } as const;
        },
        default() {
          setLoading(false);
          return { success: false } as const;
        },
      });
    } catch (error) {
      setLoading(false);
      return { success: false } as const;
    }
  }

  return { current, loading, options, change };
}
