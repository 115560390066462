import React from 'react';
import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import R from 'ramda';
import { Redirect, useParams } from 'react-router';
import { specConst } from '~/utils';
import { api } from '~/api';

export default () => {
  const { id } = useParams();

  const products = useStoreState(state => state.auth.solutionProducts);
  const product = R.find(R.propEq('id', id))(products);

  if (!product) return <Redirect to="/" />;

  const specs = product.specifications;
  const icons = R.uniq(specs.map(item => item.compliance.id));

  return (
    <>
      <h2 className={classNames('light', 'icon-file-pdf')}>Documents</h2>

      <div className={classNames('types', 'no-ws')}>
        {icons.map(id => (
          <div
            key={id}
            className={classNames(
              'specification-type',
              specConst[id].className
            )}
          >
            <h3>
              <span
                className={classNames('legend', specConst[id].className)}
                aria-hidden="true"
              >
                {specConst[id].symbol}
              </span>{' '}
              {specConst[id].name}
            </h3>
            <ul>
              {specs.map(
                item =>
                  id === item.compliance.id && (
                    <li key={item.href}>
                      <a
                        href={`#${item.filename}`}
                        onClick={() => api.getPdf(item.href, item.filename)}
                      >
                        {item.filename}
                      </a>
                    </li>
                  )
              )}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};
