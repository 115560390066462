import R from 'ramda';
import { AttributesColumn } from '../columns/attributes';
import { ColumnTree } from '../hooks/useTable/defineColumns';
import { DetailsColumn } from '../columns/details';
import { EtdColumn } from '../columns/etd';
import { EtdFromColumn } from '../columns/etdFrom';
import { EtdToColumn } from '../columns/etdTo';
import { IncotermColumn } from '../columns/incoterms';
import { LegendColumn } from '../columns/legend';
import { LegendPriceColumn } from '../columns/legendPrice';
import { LegendTypeColumn } from '../columns/legendType';
import { LegendVolumeColumn } from '../columns/legendVolume';
import { LoadingColumn } from '../columns/loading';
import { LoadingDescColumn } from './loadingDesc';
import { LocationsColumn } from '../columns/locations';
import { OriginsColumn } from '../columns/origins';
import { PaymentTermColumn } from '../columns/paymentTerm';
import { ProductBrandColumn } from '../columns/productBrand';
import { ProductColumn } from '../columns/product';
import { ProductNameColumn } from '../columns/productName';
import { PublishedColumn } from '../columns/published';
import { RefPriceColumn } from '../columns/refPrice';
import { RefPriceContentColumn } from '../columns/refPriceContent';
import { SplittableColumn } from '../columns/splittable';
import { StateColumn } from '../columns/state';
import { StateTradableColumn } from '../columns/stateTradeable';
import { COLUMN_KEYS } from '~/store/constants';

export const ColumnFactory = ColumnTree.defineFactory(
  [
    AttributesColumn,
    DetailsColumn,
    EtdColumn,
    EtdFromColumn,
    EtdToColumn,
    IncotermColumn,
    LegendColumn,
    LegendPriceColumn,
    LegendTypeColumn,
    LegendVolumeColumn,
    LoadingColumn,
    LoadingDescColumn,
    LocationsColumn,
    OriginsColumn,
    PaymentTermColumn,
    ProductBrandColumn,
    ProductColumn,
    ProductNameColumn,
    PublishedColumn,
    RefPriceColumn,
    RefPriceContentColumn,
    SplittableColumn,
    StateColumn,
    StateTradableColumn,
  ],
  {
    transform({ solutionColumns }, column, key, children) {
      const definedKeys = ColumnFactory.keys;
      if (
        R.contains(key, definedKeys) &&
        R.contains(key, COLUMN_KEYS) &&
        !R.has(key, solutionColumns)
      )
        return [];
      const { children: columnChildren, ...columnWithoutChildren } = column;
      if (children && children.length) {
        if (columnChildren && columnChildren.length) {
          return [
            {
              ...columnWithoutChildren,
              children: [...columnChildren, ...children],
            },
          ];
        }
        return [{ ...columnWithoutChildren, children }];
      }
      return [column];
    },
  }
);
