import React from 'react';
import type Trade from '~/store/models/trades/Trade';
import { SimpleTable } from 'Components/nui/Table';
import R from 'ramda';
import FNumber from 'Components/FNumber';
import { Checkbox } from 'Components/nui';
import { useConsolidationContext } from '../Context';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface ITrades {
  division: { id: string; name: string };
  trades: Trade[];
}
export default ({ division, trades }: ITrades) => {
  const consolidation = useConsolidationContext();

  const column = (
    name: string,
    title: React.ReactNode,
    render: (t: Trade) => React.ReactNode,
    className: string = ''
  ) => ({
    name,
    title,
    render,
    key: `shipment-trades-${division.id}-${name}`,
    className: classnames(`col-${name}`, className),
  });

  const consolidated = consolidation.getConsolidated(division.id);
  const allConsolidated = consolidated.length === trades.length;
  const noneConsolidated = consolidated.length === 0;
  const someConsolidated = !noneConsolidated && !allConsolidated;

  const columns = [
    column(
      'add',
      <Checkbox
        indeterminate={someConsolidated}
        checked={allConsolidated}
        onChange={() => {
          if (noneConsolidated || someConsolidated)
            consolidation.addAllTrades(division.id);
          else consolidation.removeAllTrades(division.id);
        }}
      >
        &nbsp;
      </Checkbox>,
      trade => (
        <Checkbox
          checked={consolidation.isConsolidated(trade)}
          onChange={() => {
            if (consolidation.isConsolidated(trade))
              consolidation.removeTrade(trade);
            else consolidation.addTrade(trade);
          }}
        >
          &nbsp;
        </Checkbox>
      )
    ),
    column('id', 'Trade ID', trade => (
      <Link to={`/trades/${trade.id}`}>{trade.pid}</Link>
    )),
    column('product', 'Product', R.path(['product', 'name'])),
    column('size', 'Size', trade => trade.size?.map(R.prop('name')).join(', ')),
    column('created', 'Created', trade =>
      trade.created.format('DD MMM YYYY HH:mm')
    ),
    column('volume', 'Volume', trade => (
      <>
        <FNumber
          value={trade.volume.val}
          decimalCount={trade.volumeDecimalCount()}
        />{' '}
        <span className="unit">{trade.volume.unit}</span>
      </>
    )),
    column('etd', 'Estimated time of departure', trade => trade.etd.format()),
    column('buyer', 'Buyer', R.path(['partners', 'buyer', 'name'])),
    column('consignee', 'Consignee', R.path(['partners', 'consignee', 'name'])),
  ];

  return (
    <SimpleTable
      className="consolidation-table nui-row"
      columns={columns}
      data={trades.sort((a, b) => b.created.diff(a.created, 's'))}
      rowKey="id"
    />
  );
};
