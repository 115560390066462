import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import R from 'ramda';
import { useEvent, useInterval } from 'react-use';
import classNames from 'classnames';
import { useIsMobile } from '~/hooks';
import ProductRow from './ProductRow';
import ThCell from './ThCell';
import ProductMobileCell from './ProductMobileCell';
import ProductCell from './ProductCell';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import TableOptions from './TableOptions';

export default () => {
  const mode = useStoreState(state => state.marketplace.mode);

  const solution = useStoreState(state => state.auth.solution);
  const products = useStoreState(state =>
    state.auth.marketplaceProducts(solution.id)
  );
  const productsTradeActions = useStoreState(
    state => state.auth.userProductsTradeActions
  );

  const productIds = products.map(p => p.p_id);

  const mip = useStoreState(state => state.marketplace.mip);
  const tableData = useStoreState(state => state.marketplace.tableData);
  const columns = useStoreState(state => state.marketplace.columns);

  const setData = useStoreActions(actions => actions.marketplace.setData);
  const updateMP = useStoreActions(actions => actions.marketplace.updateData);
  const calculateTableData = useStoreActions(
    actions => actions.marketplace.calculateTableData
  );
  const toggleMipAll = useStoreActions(
    actions => actions.marketplace.toggleMipAll
  );

  const [mouseDownPos, setMouseDownPos] = useState(null);

  const isMobile = useIsMobile();

  // const filteredProductsIds = R.pathOr([], ['favouriteproducts'], solution);
  const filteredProductsIds = []; // R.pathOr([], ['favouriteproducts'], solution);
  const isFiltered =
    filteredProductsIds.length !== 0 &&
    filteredProductsIds.length < products.length;

  useEffect(() => {
    calculateTableData();
  }, [mode]);

  useEffect(() => {
    setData({
      updatedDate: null,
    });
    updateMP();
  }, [solution]);

  useInterval(() => {
    updateMP();
  }, 30000);

  useEvent('mouseup', () => setMouseDownPos(null));

  const handleMouseMove = e => {
    if (mouseDownPos) {
      scrollRef.current.scrollLeft =
        mouseDownPos.scrollLeft + mouseDownPos.x - e.clientX;
      scrollRef.current.scrollTop =
        mouseDownPos.scrollTop + mouseDownPos.y - e.clientY;
    }
  };

  const handleMouseDown = e => {
    const a = e.target.closest('a');
    if (a) {
      setTimeout(() => {
        setMouseDownPos(null);
      }, 1000);
    }
    setMouseDownPos({
      x: e.clientX,
      y: e.clientY,
      scrollLeft: scrollRef.current.scrollLeft,
      scrollTop: scrollRef.current.scrollTop,
    });
  };

  const scrollRef = useRef(null);

  if (!columns) return null;

  return (
    <>
      <div className="favourites-container">
        <div className="favourites"></div>
      </div>

      {products.length === 0 ? <TableOptions /> : <></>}

      <ScrollSync>
        <>
          <ScrollSyncPane>
            {products.length === 0 ? (
              <div className="nui-row full-page-messaging align-center mt-10 p-100">
                <div className="icon-inbox bordered align-center">
                  There are currently no products to display
                </div>
              </div>
            ) : (
              <div className="thead-container">
                <table className="marketplace-table">
                  <thead>
                    <tr>
                      <th>
                        <div className="filter-container m-filter-container">
                          <div className="filter-summary show-for-small">
                            <h2 className="all-black">
                              Products{' '}
                              {isFiltered && (
                                <span className="is-filtered"></span>
                              )}
                            </h2>
                            {isFiltered && (
                              <h3>
                                {filteredProductsIds.length} of{' '}
                                {products.length} products showing
                                {/* <span className="show-all inline-block">
                                | <button>Show all</button>
                              </span> */}
                              </h3>
                            )}
                          </div>
                        </div>

                        <div className="sort product-col-width">
                          {isMobile && (
                            <button
                              className={classNames('toggle', {
                                'close-all': mip.length === 0,
                              })}
                              title="Show/hide all table data"
                              onClick={() => toggleMipAll(productIds)}
                            >
                              Show/hide all table data
                            </button>
                          )}
                        </div>
                      </th>

                      {columns.map(column => (
                        <ThCell key={column.key} column={column} mode={mode} />
                      ))}
                    </tr>
                  </thead>
                </table>
              </div>
            )}
          </ScrollSyncPane>
          <ScrollSyncPane>
            <div className="tbody-container" ref={scrollRef}>
              <table
                className="marketplace-table"
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
              >
                <tbody>
                  {products.map(
                    product =>
                      (filteredProductsIds.length === 0 ||
                        filteredProductsIds.includes(product.p_id)) && (
                        <Fragment key={product.id}>
                          {isMobile && (
                            <ProductMobileCell
                              product={product}
                              colSpan={columns.length + 1}
                            />
                          )}
                          {(!isMobile ||
                            (isMobile && !mip.includes(product.p_id))) && (
                            <tr className="table-data">
                              <ProductCell
                                product={product}
                                total={R.pathOr(
                                  [],
                                  [product.p_id, 'total'],
                                  tableData
                                )}
                              />
                              <ProductRow
                                columns={columns}
                                mode={mode}
                                symbol={product.currency.symbol}
                                data={R.path([product.p_id], tableData)}
                                tradeActions={R.pathOr(
                                  [],
                                  [product.p_id],
                                  productsTradeActions
                                )}
                              />
                            </tr>
                          )}
                        </Fragment>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </ScrollSyncPane>
        </>
      </ScrollSync>
    </>
  );
};
