import type { IButtonConfig } from 'Components/nui/Button';
import React, { useContext } from 'react';
import { useToggle } from 'react-use';
import classnames from 'classnames';
import RowToggle from 'Components/nui/RowToggle';
import { Button } from 'Components/nui';

const AccordionContext = React.createContext({
  isOpen: false,
  toggle(value?: boolean) {},
  open() {},
  close() {},
});

export function useAccordion() {
  const context = useContext(AccordionContext);

  return context;
}

export interface IAccordion {
  header: React.ReactNode;
  children: React.ReactNode;
}
const Accordion = ({ header, children }: IAccordion) => {
  const [isOpen, toggle] = useToggle(false);
  const open = () => toggle(true);
  const close = () => toggle(false);

  return (
    <div className={classnames('accordion', { open: isOpen })}>
      <AccordionContext.Provider value={{ isOpen, toggle, open, close }}>
        <div className="accordion-header">{header}</div>
        <div className={classnames('accordion-content', { open: isOpen })}>
          {isOpen && children}
        </div>
      </AccordionContext.Provider>
    </div>
  );
};

export default Accordion;

interface IAccordionToggle {
  children?: React.ReactNode;
}
export const AccordionToggle = ({ children }: IAccordionToggle) => {
  const accordion = useAccordion();

  return (
    <RowToggle
      className="accordion-toggle"
      onClick={() => accordion.toggle()}
      open={accordion.isOpen}
    >
      {children}
    </RowToggle>
  );
};

export const ShipmentAccordion = (props: IAccordion) => {
  return (
    <div className="nui-accordion shipment-accordion">
      <Accordion {...props} />
    </div>
  );
};

export const TenderAccordion = (props: IAccordion) => {
  return (
    <div className="nui-accordion tender-accordion">
      <Accordion {...props} />
    </div>
  );
};

interface IControlledContext {
  open: boolean;
}
const ControlledContext = React.createContext<IControlledContext>({
  open: false,
});

interface IControlledAccordion {
  open?: boolean;
  children?: React.ReactNode;
  className?: string;
}
export const ControlledAccordion = ({
  open = false,
  children,
  className = '',
}: IControlledAccordion) => {
  return (
    <div className={classnames('accordion', className, { open })}>
      <ControlledContext.Provider value={{ open }}>
        {children}
      </ControlledContext.Provider>
    </div>
  );
};

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

export const ControlledAccordionHeader = ({
  className = '',
  ...props
}: IProps) => {
  const { open } = useContext(ControlledContext);

  return (
    <div
      className={classnames('accordion-header', className, { open })}
      {...props}
    />
  );
};

export const ControlledAccordionContent = ({
  className = '',
  ...props
}: IProps) => {
  const { open } = useContext(ControlledContext);

  if (open)
    return (
      <div className={classnames('accordion-content', className)} {...props} />
    );

  return null;
};

ControlledAccordion.Header = ControlledAccordionHeader;
ControlledAccordion.Content = ControlledAccordionContent;

const PinnedContext = React.createContext({
  pinned: false,
  toggle(val?: boolean) {},
});

interface IPinnedAccordion extends IControlledAccordion {}
export const PinnedAccordion = ({
  open = false,
  className = '',
  ...props
}: IPinnedAccordion) => {
  const [pinned, toggle] = useToggle(false);

  return (
    <PinnedContext.Provider value={{ pinned, toggle }}>
      <ControlledAccordion
        className={classnames('pinned-accordion', className, { pinned })}
        open={pinned || open}
        {...props}
      />
    </PinnedContext.Provider>
  );
};

export const AccordionPin = ({ className = '', ...props }: IButtonConfig) => {
  const { pinned, toggle } = useContext(PinnedContext);

  return (
    <Button
      icon="pin"
      type="buttonlink"
      className={classnames(className, { pinned })}
      onClick={() => {
        toggle();
      }}
      {...props}
    />
  );
};

PinnedAccordion.Header = ControlledAccordionHeader;
PinnedAccordion.Content = ControlledAccordionContent;
PinnedAccordion.Pin = AccordionPin;
