import classnames from 'classnames';
import { GenericTable } from 'Components/nui/Table';
import R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAllUsers } from '../Context';
import UserView from './UserView';
import * as api from '~/fetch';
import { useUser } from '../Context';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal } from 'Components/nui';
import { useToggle } from 'react-use';
import { useStoreState } from '~/store';
import { inArrayIf } from '~/utils';
import DropCheck from 'Components/nui/form/DropCheck';

type Company = {
  id: 'string';
  name: 'string';
};
type User = {
  id: 'string';
  firstname: 'string';
  lastname: 'string';
  company: Company;
};

const calculateCooridinates = (element: any) => {
  const rect = element?.getBoundingClientRect();
  return [rect?.x, rect?.y];
};

const SortTitle = ({ title, onClick, sortDirection, href }: any) => {
  return (
    <div>
      <a
        href={href}
        className={classnames('icon-sort', 'sortem', sortDirection, {
          sortem: sortDirection.length,
        })}
        onClick={onClick}
      >
        {title}
      </a>
    </div>
  );
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default () => {
  const auth = useStoreState(state => state.auth);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const isEnterpriseSolution = solutionSettings.matchinglevel === 'product';
  const users = useAllUsers();
  const companies: Company[] =
    users?.length && R.uniq(R.map(R.prop('company'), users));

  let query = useQuery();
  const [sortAscending, setSortAscending] = useState(true);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);

  const [sortColumn, setSortColumn] = useState<any>(['lastname']);
  const { search } = useLocation();

  const user_id = query.get('user_id');

  const sorter = sortAscending ? R.ascend : R.descend;

  const sortedData = useMemo(
    () =>
      R.sortWith([
        sorter(R.compose<any, any, any>(R.toLower, R.path(sortColumn))),
      ])(users),
    [sortAscending, sortColumn, users]
  );

  const filteredData =
    isEnterpriseSolution && selectedCompanies.length
      ? sortedData.filter(item =>
          R.includes(item.company.id, selectedCompanies)
        )
      : sortedData;

  const anyUserEditable = R.any(R.pathEq(['__acl__', 'edit'], true), users);

  const isSorted = (column: any) => R.equals(column, sortColumn);

  const sortFn = (column: any) => () => {
    setSortColumn(column);
    const ascend = isSorted(column) ? !sortAscending : true;
    setSortAscending(ascend);
  };

  const getSortDirection = (column: any) => {
    return isSorted(column) ? (sortAscending ? 'up' : 'down') : '';
  };

  const [userToDelete, setUserToDelete] = useState<any>();

  const [isDeleting, setIsDeleting] = useToggle(false);

  useEffect(() => {
    const matchedRow = document.querySelector(`[data-row-key="${user_id}"]`);

    const [, y] = calculateCooridinates(matchedRow);
    window.scrollTo({ top: y - 100, left: 0, behavior: 'smooth' });
  }, [search]);

  const { deleteUser } = useUser(undefined);

  const showDeleteModal = (user: any) => {
    setUserToDelete(user);
  };

  const closeDeleteModal = () => {
    setUserToDelete(null);
  };

  async function handleDelete(id: string) {
    try {
      setIsDeleting(true);
      const response = await api.users.deleteUser(id);
      await api.utils.processResponse(response, {
        async 204(response) {
          deleteUser(id);
          setIsDeleting(false);
          setUserToDelete(null);
        },
        async default(response) {
          toast.error(
            <>An unknown error occured, please refresh the page or try again</>
          );
        },
      });
    } catch (err) {
      toast.error(
        <>An unknown error occured, please refresh the page or try again</>
      );
    }
  }

  users.sort(
    (a: any, b: any) =>
      a.lastname.localeCompare(b.lastname) ||
      a.firstname.localeCompare(b.firstname)
  );

  const column = (
    name: string,
    title: any,
    render: (u: any) => React.ReactNode,
    className: string = ''
  ) => ({
    name: name,
    key: `users-table-${name}`,
    className: classnames(`col-${name}`, className),
    title,
    render,
  });

  const columns = [
    ...inArrayIf(
      auth.hasRoleInAnySolution('manager'),
      column('details', '', () => null)
    ),
    column(
      'lastName',
      <SortTitle
        title="User last name"
        onClick={sortFn(['lastname'])}
        sortDirection={getSortDirection(['lastname'])}
        href="#lastname"
      />,
      user => (
        <>
          <span className="tiny-label">User last name</span>
          {R.pathOr('', ['lastname'], user)}
        </>
      )
    ),
    column(
      'firstName',
      <SortTitle
        title="User first name"
        onClick={sortFn(['firstname'])}
        sortDirection={getSortDirection(['firstname'])}
        href="#firstname"
      />,
      user => (
        <>
          <span className="tiny-label">User first name</span>
          {R.pathOr('', ['firstname'], user)}
        </>
      )
    ),
    column('company', 'Company', user => (
      <>
        <span className="tiny-label">Company</span>
        {R.pathOr('', ['company', 'name'], user)}
      </>
    )),
    column('email', 'Email', user => (
      <>
        <span className="tiny-label">Email</span>
        {R.pathOr('', ['email'], user)}
      </>
    )),
    column('mobile', 'Mobile', user => (
      <>
        <span className="tiny-label">Mobile</span>
        {R.pathOr('', ['mobile'], user)}
      </>
    )),
    column('phone', 'Phone', user => (
      <>
        <span className="tiny-label">Phone</span>
        {R.pathOr('', ['phone'], user)}
      </>
    )),

    ...inArrayIf(
      anyUserEditable,
      column(
        'edit',
        '',
        user =>
          user.__acl__.edit && (
            <Link
              to={`/users/${user.id}/edit`}
              className={classnames('nui-button nui-button-icon')}
            >
              <span className={classnames('icon-pencil')} />
            </Link>
          )
      )
    ),

    ...inArrayIf(
      anyUserEditable,
      column(
        'delete',
        '',
        user =>
          user.__acl__.delete && (
            <Button
              title="Delete user"
              icon="trash"
              onClick={() => {
                showDeleteModal(user);
              }}
            />
          )
      )
    ),
  ];

  return (
    <>
      {userToDelete && (
        <Modal size="small" close={closeDeleteModal}>
          <h2>Warning</h2>
          <p>
            Are you sure you want to delete this user? This can&apos;t be
            undone.
          </p>
          <hr />
          <div className="button-set">
            <Button
              type="primary"
              onClick={() => handleDelete(userToDelete.id)}
              loading={isDeleting}
            >
              Yes, delete user
            </Button>
            <Button type="simple" onClick={closeDeleteModal}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}

      {isEnterpriseSolution && users?.length && (
        <div className="full-page-header">
          <div className="customer-filters">
            <span className="status">Filter by company</span>

            <div className="nui-field-holder cb-set stack-8">
              <DropCheck
                name="company-filter"
                title="All"
                options={companies.map((company: Company) => [
                  company.id,
                  company.name,
                ])}
                value={selectedCompanies}
                onChange={(_, values) => {
                  values && setSelectedCompanies(values);
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div>
        <GenericTable
          className="nui-table"
          columns={columns}
          data={filteredData}
          rowKey={(user: User) => user.id}
          expandable={
            auth.hasRoleInAnySolution('manager')
              ? {
                  defaultExpandAllRows: false,
                  defaultExpandedRowKeys: user_id ? [user_id] : undefined,
                  expandedRowRender: (record: any, index, expanded) => (
                    <div className="user-details">
                      <UserView key={record.id} record={record} />
                    </div>
                  ),
                }
              : undefined
          }
          emptyText={
            <div className="nui-row full-page-messaging align-center mt--46 p-100">
              <div className="icon-inbox bordered align-center">
                There are currently no users to display
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};
