import { action, computed, thunk } from 'easy-peasy';
import R from 'ramda';
import { api } from '~/api';

export default {
  renderCounter: 0,
  orderQuestions: {},

  orderQuestionsCount: computed(
    state => id => R.pathOr([], [id], state.orderQuestions).length
  ),

  incRenderCounter: action((state, _) => {
    state.renderCounter += 1;
  }),

  setOrderQuestions: action((state, payload) => {
    state.orderQuestions[payload.id] = payload.questions;
  }),

  loadOrderQuestions: thunk(async (actions, id) => {
    const data = await api.getData({
      type: 'orders',
      id: `${id}/questions`,
      getResult: x => x.questions,
    });
    const questions = R.sortBy(R.prop('created'), data);
    actions.setOrderQuestions({ id, questions });
  }),
};
