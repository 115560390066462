import React, { useEffect } from 'react';
import Navigation from './Navigation';
import Summary from './Summary';
import Content from './Content';
import { createStateContext } from 'react-use';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Header from './Header';
import { useLocation, useParams } from 'react-router-dom';
import { Route as RouteModel } from '~/models';
import Helmet from 'Components/Helmet';
import { api } from '~/api';

export const [useSharedRoute, SharedRouteProvider] = createStateContext();

const Loader = () => {
  const { id } = useParams();
  const [route, setRoute] = useSharedRoute();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  useEffect(() => {
    const fetch = async () => {
      const data = await api.getData2(`/routes/${id}`);
      setRoute(new RouteModel(data));
    };

    if (id) fetch();
  }, [id, pathname]);

  if (!route) return null;

  return (
    <Helmet>
      <title>Edit route {route.name}</title>
    </Helmet>
  );
};

export default () => {
  const solution = useStoreState(state => state.auth.solution);
  const fetchRoutes = useStoreActions(action => action.auth.fetchRoutes);
  const fetchUser = useStoreActions(action => action.auth.fetchUser);

  useEffect(() => {
    return () => {
      fetchRoutes(solution.id);
      fetchUser();
    };
  }, [solution, fetchRoutes, fetchUser]);

  return (
    <SharedRouteProvider>
      <Loader />
      <div className="full-page-header">
        <Header />

        <hr className="sm10-md20 mb-0" />

        <div className="wizard multiple has-summary">
          <div className="wizard-steps">
            <Navigation />
          </div>
          <div className="wizard-content">
            <Content />
          </div>
          <div className="wizard-summary">
            <Summary />
          </div>
        </div>
      </div>
    </SharedRouteProvider>
  );
};
