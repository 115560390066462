import UserContent from 'Components/nui/UserContent';
import React from 'react';
import { useStoreState } from '~/store';
import { WithId } from '../types';
import Badge from './ControlBar/StatusBadge';
import { getStatus } from '~/store/bannerModel/utils';

export default function Preview({ id }: WithId) {
  const item = useStoreState(state => state.banners.items.byId(id));
  if (!item) return null;

  const { subject, content, action_url, action_text } = item;
  
  const status = getStatus(item);

  return (
    <div className="banner-preview">
      <div className="banner-subject">{subject}</div>
      <Badge status={status} />
      <UserContent data={content} />
      {action_url && (
        <a href={action_url} target="_">
          {action_text ? action_text : null}
        </a>
      )}
    </div>
  );
}
