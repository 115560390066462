import React, { useEffect, useState } from 'react';
import { api } from '~/api';
import { useToggle } from 'react-use';
import { useStoreState } from 'easy-peasy';
import R from 'ramda';
import { Loading, Select } from 'Components/nui';
import { useFilterCEData, useSharedCEData, useSharedFilter } from '..';
import moment from 'moment-timezone';

export default () => {
  const [range] = useSharedFilter();
  const [filterNames, setFilterNames] = useFilterCEData();
  const [filter, setFilter] = useState({});
  const [data, setData] = useSharedCEData();
  const [options, setOptions] = useState({
    client: [],
    divisiontype: [],
  });
  const apiError = data === 'Error';

  const solution = useStoreState(state => state.auth.solution);
  const [loading, toggle] = useToggle(true);
  const [availableOptions, setAvailableOptions] = useState({});

  const periodStart = moment(
    R.pathOr(moment().format('YYYY-MM-DD'), ['period', 'start'], data)
  );
  const periodEnd = moment(
    R.pathOr(moment().format('YYYY-MM-DD'), ['period', 'end'], data)
  );
  const periodValidity = R.pathOr('n/a', ['period', 'validity'], data);

  useEffect(() => {
    let mounted = true;
    toggle(true);
    if (!range) return;

    (async () => {
      try {
        const data = await api.analytics(
          'user',
          [
            solution.id,
            range[0].format('YYYY-MM-DD'),
            range[1].format('YYYY-MM-DD'),
          ],
          filter
        );
        if (mounted) {
          const newData = R.pathOr({}, ['data', 'user-activity'], data);
          setData(newData);
          if (R.isEmpty(filter)) {
            const newFilter = R.pathOr({}, ['filters'], newData);
            if (newFilter.client || newFilter.divisiontype) {
              setFilter(R.pathOr({}, ['filters'], newData));
            }
          }

          setOptions({
            client: R.pathOr([], ['fields', 'client', 'values'], newData),
            divisiontype: R.pathOr(
              [],
              ['fields', 'divisiontype', 'values'],
              newData
            ),
          });
          toggle(false);
        }
      } catch (error) {
        setData('Error');
        // pass
      }
    })();

    return () => {
      mounted = false;
    };
  }, [solution, range, setData, setOptions, toggle, filter]);

  useEffect(() => {
    if (filter.client) {
      const newOptions =
        R.prop(
          'options',
          R.find(R.propEq('key', filter.client), R.prop('client', options))
        ) || {};

      setAvailableOptions(newOptions);
    }
  }, [filter.client, options.client, setAvailableOptions]);

  useEffect(() => {
    const _new = {
      client: R.propOr(
        '',
        'label',
        R.find(R.propEq('key', filter.client), R.prop('client', options))
      ),
      divisiontype: R.propOr(
        '',
        'label',
        R.find(
          R.propEq('key', filter.divisiontype),
          R.prop('divisiontype', options)
        )
      ),
    };
    setFilterNames(_new);
  }, [filter, setFilterNames, options]);

  return (
    !apiError &&
    (loading && R.isEmpty(data) ? (
      <Loading size="small" />
    ) : (
      !R.isEmpty(data) && (
        <>
          <div className="nui-fieldset viewedon-filter">
            <label htmlFor="client">Viewed on</label>
            <div className="select-holder">
              <Select
                id="client"
                value={filter.client}
                onChange={value =>
                  setFilter(state =>
                    value
                      ? { ...state, client: value }
                      : R.dissoc('client', state)
                  )
                }
              >
                <option value="">Any</option>
                {options.client.map(item => (
                  <option value={item.key} key={item.key}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="nui-fieldset platformrole-filter">
            <label htmlFor="divisiontype">Platform role</label>
            <div className="select-holder">
              <Select
                id="divisiontype"
                value={filter.divisiontype}
                onChange={value =>
                  setFilter(state =>
                    value
                      ? { ...state, divisiontype: value }
                      : R.dissoc('divisiontype', state)
                  )
                }
              >
                <option value="">Any</option>
                {options.divisiontype.map(item => (
                  <option
                    value={item.key}
                    key={item.key}
                    disabled={
                      filter.client &&
                      !R.propOr([], 'divisiontype', availableOptions).includes(
                        item.key
                      )
                    }
                  >
                    {item.label}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <p className="filter-message p-0">
            Showing{' '}
            {range && (
              <>
                for{' '}
                <strong>
                  {periodStart.format('ll')}
                  {periodStart.format('ll') !== periodEnd.format('ll') && (
                    <> &mdash; {periodEnd.format('ll')}</>
                  )}
                </strong>
                .{' '}
                {range[0].isBefore(periodStart) && (
                  <span>
                    (Your date range has been limited to{' '}
                    {periodStart.format('ll')} due to a restriction on your
                    account (max period = {periodValidity})
                  </span>
                )}
              </>
            )}
            {filter.client && (
              <>
                Viewed on <strong>{filterNames.client}</strong>.{' '}
              </>
            )}
            {filter.divisiontype && (
              <>
                The platform role <strong>{filterNames.divisiontype}</strong>.
              </>
            )}
          </p>
        </>
      )
    ))
  );
};
