import { State } from 'easy-peasy';
import { IListModel } from '../listModel';

export function FindIndex<T>(getKey: (item: T) => string) {
  const predicate = (a: T) => (b: T) => getKey(a) === getKey(b);
  return function findIndex(item: T, items: T[]) {
    return items.findIndex(predicate(item));
  };
}

export function WithItem<T>(getKey: (item: T) => string) {
  const predicate = (a: T) => (b: T) => getKey(a) === getKey(b);
  return function withItem<
    U extends T,
    S extends State<IListModel<U, any>>,
    P extends U
  >(
    apply: (state: S, payload: P, index: number) => void,
    fallback?: (state: S, payload: P) => void
  ) {
    return function actionHandler(state: S, payload: P) {
      const index = state.data.findIndex(predicate(payload));
      if (index === -1) {
        fallback && fallback(state, payload);
        return;
      }
      apply(state, payload, index);
    };
  };
}
