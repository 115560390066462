import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Link } from 'react-router-dom';
import { useSharedCustomer } from '.';

export default () => {
  const [customer] = useSharedCustomer();

  if (!customer) return null;

  return (
    <div className="full-page-header">
      <h1 className="inline mt-10 mr-20 mb-0">
        Customer <span className="smaller">{customer.name}</span>
      </h1>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="customers" />
        </li>
        <li>
          <Link to="/customers" title="Back to the list of customers">
            Customers
          </Link>
        </li>
        <li>View</li>
      </ul>
    </div>
  );
};
