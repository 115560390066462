import React, { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { Fragment } from 'react';

export default function ZendeskChat() {
  const user = useStoreState(state => state.auth.user);
  const solution = useStoreState(state => state.auth.solution);

  useEffect(() => {
    setTimeout(() => {
      const zopim = window.$zopim;
      if (zopim) {
        zopim(() => {
          zopim.livechat.setGreetings({
            online: 'Support',
            offline: 'Leave us a message',
          });
          if (user && user.email) {
            zopim.livechat.setName(`${user.firstname} ${user.lastname}`);
            zopim.livechat.setEmail(user.email);
            zopim.livechat.setPhone(user.mobilephone || '0000');
            if (solution) {
              zopim.livechat.addTags(`Solution: ${solution.name}`);
            }
          }
        });
      }
    }, 5000);
  }, [user, solution]);

  return <Fragment />;
}
