import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const StateColumn = ColumnTree.defineNode(
  'state',
  ({ sortDirection, sortColumn, setSort }) => ({
    title: (
      <a
        href="#state"
        className={classnames('icon-sort', 'sortem', {
          [sortDirection]: sortColumn === 'state',
        })}
        onClick={() => setSort('state')}
      >
        <FormattedMessage
          id="marketplace-table-heading-orderstate"
          description="Table heading, order's state, e.g. Tradeable | Untradeable"
          defaultMessage="State"
        />
      </a>
    ),
  })
);
