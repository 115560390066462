export const unitsOfMeasure = {
  'kg': 'Kilogram',
  'kL': 'Kilolitre',
  'L': 'Litre',
  'lb': 'Pound',
  'lt': 'Long Ton',
  'm³': 'Cubic Metre',
  'st': 'Short Ton',
  't': 'Tonne',
} as const;

export const subcurrencies = {
  AUD: 'Cents',
  BRL: 'Centavo',
  EUR: 'Cents',
  GBP: 'Pence',
  NZD: 'Cents',
  USD: 'Cents',
} as const;
