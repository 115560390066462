import type * as Struct from 'struct';
import type * as Market from 'models.market';

import React, { useState } from 'react';
import R from 'ramda';
import { Button, Alert } from 'Components/nui';
import { useCounterOrders, useCounter } from './helpers';
import { FormattedMessage } from 'react-intl';
import * as Data from 'Components/Data';
import {
  nl2p,
  getExportLabel,
  invertOrderType,
  SolutionColumns,
} from '~/utils';
import { api as Xapi } from '~/api';
import classnames from 'classnames';
import CounterForm from './CounterForm';
import * as actions from './actions';
import { toast } from 'react-toastify';
import { useStoreActions, useStoreState } from '~/store';
import Respond from './Respond';
import CounterExpiry from './CounterExpiry';

interface IConfig {
  order: Market.Order;

  can: {
    add: boolean;
  };
}
export default ({ order, can }: IConfig) => {
  const { editable, others, amBroker } = useCounterOrders(order);

  const canAdd = can.add && (amBroker || !editable.length);

  if (order.counters?.length || can.add) {
    return (
      <div className="mkt-row-counters">
        {canAdd && <AddCounter broker={amBroker} order={order} />}

        {!!editable.length && (
          <ul className="counter-list">
            {editable.map(c => (
              <CounterItem key={c.id} order={order} counter={c} />
            ))}
          </ul>
        )}

        {!!others.length && (
          <>
            <h4>All counters</h4>
            <hr className="mt-12 mb-10" />
            <ul className="counter-list">
              {others.map(c => (
                <CounterItem key={c.id} order={order} counter={c} />
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }

  return (
    <Alert hasicon className="mt-10" type="info">
      <>No counters have been placed</>
    </Alert>
  );
};

interface IAddCounter {
  order: Market.Order;
  broker: boolean;
}
const AddCounter = ({ order, broker }: IAddCounter) => {
  return (
    <div>
      <CounterForm order={order}>
        {onEdit => (
          <Alert className="respond-alert">
            <p className="mb-10">
              {broker ? (
                <>Place a brokered counter.</>
              ) : (
                <>You have not placed any counters yet.</>
              )}
            </p>
            <Button type="primary" size="small" onClick={onEdit}>
              Add a counter
            </Button>
          </Alert>
        )}
      </CounterForm>
    </div>
  );
};

interface ICounterItem {
  order: Market.Order;
  counter: Market.Counter;
}
const CounterItem = ({ order, counter }: ICounterItem) => {
  const solutionAnonymousOrders = useStoreState(
    state => state.auth.solutionSettings?.anonymousorders === 'anonymous'
  );

  const solutionColumns = useStoreState(state => state.auth.solutionColumns);

  const [loading, setLoading] = useState(false);
  const { mine, editable, tradeable, respondable, ...data } = useCounter(
    order,
    counter
  );
  const updateMkt = useStoreActions(state => state.market.update);

  const getFile = (file: Struct.Attachment) =>
    void Xapi.getFile(file.href, file.filename, file.mimetype);

  const isBroker = !!counter.broker?.id;

  const withdraw = async () => {
    setLoading(true);

    const result = await actions.withdraw(order.id, counter.id);

    if (result.error)
      toast.error(<>An unexpected error occurred. Please try again later.</>);
    if (result.success) {
      await updateMkt();
      setLoading(false);
      toast.success(<>Your counter has been withdrawn.</>);
    }
  };

  const dotrade = async () => {
    setLoading(true);

    const result = await actions.trade(order.id, counter.id);

    if (result.error)
      toast.error(<>An unexpected error occurred. Please try again later.</>);
    if (result.success) {
      await updateMkt();
      toast.success(result.success);
    }
    setLoading(false);
  };

  const counterPrice = {
    ...data.price,
    index: order.index,
    indexdate: order.indexdate,
  };

  const fromaddrIncluded =
    counter?.fromaddr?.id &&
    order?.fromaddr?.map(e => e.id).includes(counter.fromaddr.id);

  const describeAddress = (address: any) => {
    const additionalAddressDescription = [
      address.street,
      address.suburb,
      address.city,
      address.state,
      address.country?.name,
      address.zip,
    ]
      .filter(x => !!x)
      .join(', ');
    return `${address.description} (${additionalAddressDescription})`;
  };

  return (
    <li className="counter-item">
      <CounterForm order={order} counter={counter}>
        {onEdit => (
          <div>
            {counter.declinereason && (
              <div>
                <Alert type="error" hasicon size="small">
                  <strong className="all-black">
                    {counter.status === 'Declined' ? (
                      <FormattedMessage
                        id="marketrow-counters-warning-counterdeclined"
                        description="Warning for 'counterdeclined' message for counters"
                        defaultMessage="This counter has been declined with the following comment:"
                      />
                    ) : (
                      <FormattedMessage
                        id="marketrow-counters-warning-counterrespond"
                        defaultMessage="Response from {ordertype, select, offer {seller} bid {buyer} other {owner}}"
                        values={{ ordertype: order.type }}
                      />
                    )}
                  </strong>
                  {nl2p(counter.declinereason)}
                </Alert>
              </div>
            )}
            {(mine || isBroker) && (
              <div className="status">
                {mine && <span className="tag my-counter">My counter</span>}
                {isBroker && (
                  <span className="tag broker-counter">Broker counter</span>
                )}
              </div>
            )}
            <CounterStatus status={counter.status} />
            <div>
              <div className="unit-detail">
                <Data.Price value={counterPrice} />{' '}
                <span className="unit">{counterPrice.unit}</span>
              </div>
              <Data.VolumeTicker onZero="n/a" title="" value={data.volume} />
            </div>
            <div className="updateddt">
              <strong>Updated on {data.timestamp}</strong>
            </div>
            {counter.expiry && <CounterExpiry counter={counter} />}
            <Data.List
              className={classnames({
                'unchanged-list-items':
                  order.fromaddr?.length && !fromaddrIncluded,
              })}
            >
              {counter.anonymous !== undefined &&
                counter.anonymous !== solutionAnonymousOrders && (
                  <Data.Item title="Anonymous">
                    <Data.Bool value={counter.anonymous} />
                  </Data.Item>
                )}
              {counter.broker && (
                <Data.Item title="Broker">{counter.broker.name}</Data.Item>
              )}
              {counter.toaddr && (
                <Data.Item title="Delivery to">
                  {describeAddress(counter.toaddr)}
                </Data.Item>
              )}
              {counter.fromaddr && (
                <Data.Item
                  className={classnames({
                    'changed-list-item': !fromaddrIncluded,
                  })}
                  title="Delivery from"
                >
                  {describeAddress(counter.fromaddr)}
                </Data.Item>
              )}
              {counter.division && (
                <Data.Item title="Division">{counter.division.name}</Data.Item>
              )}
              {counter.creator && (
                <Data.Item title="Creator">
                  {counter.creator.fullname}
                </Data.Item>
              )}
              {data.incoprices.length > 1 &&
                data.incoprices.map(inco => (
                  <Data.Item key={inco.id} title={`Price (${inco.id})`}>
                    <Data.PriceTicker
                      onZero="n/a"
                      title=""
                      value={inco.price}
                    />
                  </Data.Item>
                ))}
              {data.displayed && (
                <Data.Item>
                  <Data.PriceTicker
                    onZero="n/a"
                    title={`Displayed price (${data.displayed.id})`}
                    value={data.displayed.price}
                  />
                </Data.Item>
              )}
              {counter.exportable !== undefined && order.exportable && (
                <Data.Item
                  title={
                    getExportLabel(
                      invertOrderType(order.type),
                      'exportable'
                    )(solutionColumns as SolutionColumns) ?? 'Exportable'
                  }
                >
                  <Data.Bool value={counter.exportable} />
                </Data.Item>
              )}

              {counter.exportdocs !== undefined && (
                <Data.Item
                  title={
                    getExportLabel(
                      invertOrderType(order.type),
                      'exportdocs'
                    )(solutionColumns as SolutionColumns) ?? 'Export docs'
                  }
                >
                  <Data.Required
                    value={counter.exportdocs}
                    notRequired={order.type === 'offer'}
                  />
                </Data.Item>
              )}
              {counter.heattreatedpallets !== undefined && (
                <Data.Item title="Heat-treated pallets required">
                  <Data.Required
                    value={counter.heattreatedpallets}
                    notRequired={order.type === 'offer'}
                  />
                </Data.Item>
              )}
              {counter.exportcountry && (
                <Data.Item title="Destination">
                  {counter.exportcountry.name}
                </Data.Item>
              )}
              {counter.callofftime && (
                <Data.Item title="Call off time">
                  {nl2p(counter.callofftime)}
                </Data.Item>
              )}
              {!!counter.attachments?.length && (
                <li className="attachments">
                  <ul className="file-list">
                    <li className="title">
                      <strong className="all-black">Attachments</strong>
                    </li>
                    {counter.attachments.map(f => (
                      <li key={f.id}>
                        <span className="icon-doc" />
                        <Button
                          type="buttonlink"
                          onClick={() => void getFile(f)}
                        >
                          <span title={f.filename}>{f.filename}</span>
                        </Button>
                      </li>
                    ))}
                  </ul>
                </li>
              )}
              {counter.reference && (
                <Data.Item title="Reference">{counter.reference}</Data.Item>
              )}
              {counter.note && (
                <Data.Item title="Comment">{nl2p(counter.note)}</Data.Item>
              )}
              {counter.partners?.buyer && (
                <Data.Item title="Buyer">
                  {counter.partners.buyer.name}
                </Data.Item>
              )}
              {counter.partners?.consignee && (
                <Data.Item title="Consignee">
                  {counter.partners.consignee.name}
                </Data.Item>
              )}
            </Data.List>
            {editable && counter.status === 'Oversubscribed' && (
              <Alert type="error" hasicon className="error-oversubscribed">
                This counter is not tradeable due to the order being partially
                filled. Please decrease your volume.
              </Alert>
            )}
            <Respond order={order} counter={counter}>
              {onRespond => (
                <div className="button-set">
                  {editable && (
                    <>
                      <Button
                        type="primary"
                        size="small"
                        loading={loading}
                        disabled={loading}
                        onClick={onEdit}
                      >
                        Edit
                      </Button>
                      {counter.status === 'Working' && (
                        <Button
                          size="small"
                          type="secondary"
                          loading={loading}
                          disabled={loading}
                          onClick={withdraw}
                        >
                          Withdraw
                        </Button>
                      )}
                    </>
                  )}
                  {tradeable && (
                    <Button
                      type="primary"
                      size="small"
                      loading={loading}
                      disabled={loading}
                      onClick={dotrade}
                    >
                      Trade
                    </Button>
                  )}
                  {respondable && (
                    <Button type="reverse" size="small" onClick={onRespond}>
                      More options
                    </Button>
                  )}
                </div>
              )}
            </Respond>
          </div>
        )}
      </CounterForm>
    </li>
  );
};

const statusM = { cancelled: 'withdrawn', oversubscribed: 'untradeable' };
interface ICounterStatus {
  status: Market.CounterStatus;
}
const CounterStatus = ({ status }: ICounterStatus) => {
  const statusId = R.toLower(status);

  if (statusId === 'working') return <></>;

  const value: string = R.propOr(statusId, statusId, statusM);

  return (
    <span className={classnames('counter-status', statusId)}>
      <FormattedMessage
        id={`counters-field-status-${value}`}
        description={`Label for 'status - ${value}' for counters`}
        defaultMessage={value}
      />
    </span>
  );
};
