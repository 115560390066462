import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import View from './View';
import Customers from './Customers';
import Edit from './Edit';

export default () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/:id/edit`}>
        <Edit />
      </Route>
      <Route path={`${url}/:id`}>
        <View />
      </Route>
      <Route>
        <Customers />
      </Route>
    </Switch>
  );
};
