import type * as Struct from 'struct';
import type * as Models from '~/models/tenders';
import { loadable } from '~/observable';
import * as api from '~/fetch';
import * as services from './internal';

type TenderId = Record<'tenderId', string>;
type OrderId = Record<'orderId', string>;
type CounterId = Record<'counterId', string>;

type SchemaLoader<T = {}> =
  | { loading: true; errors: string[]; data?: undefined }
  | {
      loading: false;
      errors: string[];
      data: { schema: Struct.Schema } & T;
    };

const genErr = {
  loading: true as const,
  errors: ['There was an error processing this request'],
  data: undefined,
};

type CounterP = TenderId & OrderId & CounterId;
export function counter() {
  return loadable<SchemaLoader, CounterP>(
    async (onChange, { tenderId = '', orderId = '', counterId } = {}) => {
      // TODO caching
      onChange({ loading: true as const, errors: [] });

      try {
        const response = await api.tenders.counterSchema(
          tenderId,
          orderId,
          counterId
        );
        await api.utils.processResponse(response, {
          async 200(response) {
            const data = await response.json();
            onChange({
              loading: false as const,
              errors: [],
              data: { schema: data.fields },
            });
          },
          async default() {
            onChange(genErr);
          },
        });
      } catch (errors) {
        onChange(genErr);
      }
    }
  );
}

export function tenderDetails() {
  return loadable<SchemaLoader<{ tender?: Models.Tender }>, TenderId>(
    async (onChange, { tenderId = '' } = {}) => {
      onChange({ loading: true as const, errors: [] });

      const { solution } = services.storage.getOverview();
      try {
        const [tender, response] = await Promise.all([
          services.observe.queryTender(tenderId),
          api.tenders.tenderSchema(solution!),
        ]);

        await api.utils.processResponse(response, {
          async 200(response) {
            const data = await response.json();
            onChange({
              loading: false as const,
              errors: [],
              data: { schema: data.fields, tender },
            });
          },
          async default() {
            onChange(genErr);
          },
        });
      } catch (error) {
        onChange(genErr);
      }
    }
  );
}

type Result = Promise<
  | { success: true; data: Struct.Schema }
  | { success: false; errors?: string[]; validate?: any; data?: undefined }
  | undefined
>;
interface IOrder {
  tender: string;
  product: string;
  order?: string;
}
export async function order({ tender, order, product }: IOrder): Result {
  try {
    const response = await api.tenders.orderSchema(tender, product, order);
    return await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        return { success: true as const, data: data.fields };
      },
      async 500() {
        return { success: false as const, errors: ['Server error'] };
      },
      async default() {
        return { success: false as const, errors: ['Unknown error'] };
      },
    });
  } catch (error) {
    return { success: false as const, errors: ['Unknown error'] };
  }
}
