import React, { useEffect, useState } from 'react';
import { GenericTable, SimpleTable } from 'Components/nui/Table';
import { useDeliveries, useDeliveryAttachments } from '~/store/models';
import R from 'ramda';
import { Button, Alert, Upload, Loading } from 'Components/nui';
import classnames from 'classnames';
import type { TradeData } from '~/store/models/modelTypes';
import Delivery, { Attachment } from '~/store/models/shipping/Delivery';
import { useAuthorisation, Authorised } from 'Components/Authorised';
import { inArrayIf } from '~/utils';
import { toast } from 'react-toastify';
import { api } from '~/api';
import { Link } from 'react-router-dom';
import Pagination from 'Components/nui/Pagination';
import { useToggle } from 'react-use';
import FNumber from 'Components/FNumber';

type IUseDeliveries = ReturnType<typeof useDeliveries>;

interface IRecordDetails {
  delivery: Delivery;
  deliveries: IUseDeliveries;
}

interface RCFile extends File {
  uid: string;
}

interface IFileEntry {
  file: Attachment;
  actions: ReturnType<typeof useDeliveryAttachments>;
}
const FileEntry = ({ file, actions }: IFileEntry) => {
  const [loading, setLoading] = useState(false);

  return (
    <li>
      <span className="icon-file-pdf" title="File type PDF" />
      <Button
        type="buttonlink"
        onClick={() => api.getPdf(file.href, file.filename)}
      >
        <span title={file.filename}>{file.filename}</span>
      </Button>
      <span className="file-owner">{file.division.name}</span>
      <span className="file-created smaller block">
        {file.created.format('DD MMM YYYY HH:mm')}
      </span>
      <Authorised to="delete" model={file}>
        <Button
          icon="trash"
          type="reverse"
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);

              await actions.removeAttachment(file);

              toast('Attachment removed successfully');
            } catch (err) {
              console.error(err);
              setLoading(false);
              toast('There was an error deleting your file.', {
                type: 'error',
              });
            }
          }}
        />
      </Authorised>
      {!!file.progress && (
        <div className="progress" style={{ width: `${file.progress}%` }} />
      )}
    </li>
  );
};

interface IAddFiles extends IRecordDetails {
  actions: ReturnType<typeof useDeliveryAttachments>;
}
const AddFiles = ({ delivery, deliveries, actions }: IAddFiles) => {
  const [visible, toggle] = useToggle(false);

  const onClick = () => {
    toggle();
  };

  const id = `delivery-${delivery.id}-attachments`;
  return (
    <div className="delivery-attachments">
      <h3 className="inline-block mr-15">Attachments</h3>
      <Button
        onClick={onClick}
        type="buttonlink"
        className="inline-block mb-10"
      >
        {visible ? (
          'Cancel'
        ) : (
          <>
            <span className="icon-upload-cloud larger light-gray mr-5" />
            Upload
          </>
        )}
      </Button>
      {visible && (
        <Upload
          id={id}
          multiple
          type="drag"
          accept="application/pdf"
          customRequest={async ({ file, filename }) => {
            try {
              await actions.addAttachment(file);
              toast('Attachments added successfully');
            } catch (error) {
              console.error(error);
              toast('There was an error during your upload.', {
                type: 'error',
              });
            }
          }}
        />
      )}
      <ul className="file-list">
        {actions.files.map(item => (
          <FileEntry key={item.id} file={item} actions={actions} />
        ))}
      </ul>
    </div>
  );
};

interface IEditAttrib extends IRecordDetails {
  attrib: keyof Delivery;
  isLink?: boolean;
}
const EditAttrib = ({
  attrib,
  delivery,
  deliveries,
  isLink = true,
}: IEditAttrib) => {
  const [value, setValue] = useState<string>(R.propOr('', attrib, delivery));
  const [state, setState] = useState<string>(R.propOr('', attrib, delivery));
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [errors, setErrors] = useState<string[]>([]);

  const validate = (data: string) => {
    const match =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    return !!data.match(match)?.length;
  };

  return (
    <div className="delivery-attrib">
      {mode === 'edit' ? (
        <div className="edit-attrib">
          <div
            className={classnames('nui-fieldset small mt-5', {
              'has-error': !!errors.length,
            })}
          >
            <input
              className="attrib-input"
              value={state || ''}
              onChange={({ target }) => {
                setState(target.value);
              }}
              disabled={loading}
            />
            {!!errors.length && (
              <div className="error-list">
                <ul>
                  {R.uniq(errors).map(e => (
                    <li key={e}>{e}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <Button
            icon="floppy"
            type="secondary"
            className="saveme"
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              try {
                if (!validate(state)) {
                  const message = 'Invalid URL';
                  if (!state.startsWith('http')) {
                    setErrors([
                      message,
                      'Please ensure the URL starts with a valid protocol, such as "http://" or "https://"',
                    ]);
                  } else {
                    setErrors([message]);
                  }
                  setLoading(false);
                  return;
                }
                await deliveries.updateDelivery(delivery, { [attrib]: state });
                await deliveries.load();
                setValue(state);
                setErrors([]);
                toast('Delivery info updated');
              } catch (err) {
                console.error(err);
                toast('There was an error while updating your delivery.', {
                  type: 'error',
                });
              }
              setMode('view');
              setLoading(false);
            }}
          />
          <Button
            icon="cancel"
            type="reverse"
            disabled={loading}
            onClick={() => {
              setState(value);
              setMode('view');
            }}
          />
        </div>
      ) : (
        <div className="view-attrib">
          {value ? (
            isLink ? (
              <a href={value} target="_blank" rel="noopener noreferrer">
                {value}
              </a>
            ) : (
              value
            )
          ) : (
            'Not set'
          )}
          <Authorised to="edit" model={delivery.attribs}>
            <Button
              icon="pencil"
              onClick={() => {
                setMode('edit');
              }}
            />
          </Authorised>
        </div>
      )}
    </div>
  );
};

const NotifyDelivery = ({ delivery, deliveries }: IRecordDetails) => {
  return (
    <>
      {delivery.finalised?.format('DD MMM YYYY') || 'Never'}
      <Button
        size="small"
        type="secondary"
        className="right mt--15"
        loading={deliveries.loading}
        disabled={deliveries.loading}
        onClick={async () => {
          try {
            await deliveries.notify(delivery);
            await deliveries.load();
            toast(`${delivery.buyer.name} will be notified shortly`);
          } catch (err) {
            console.error(err);
            toast('An error occurred while trying to complete your request.', {
              type: 'error',
            });
          }
        }}
      >
        Notify
      </Button>
    </>
  );
};

interface IInfoItem {
  title: React.ReactNode;
  content: React.ReactNode;
}
const InfoItem = ({ title, content }: IInfoItem) => (
  <li className="delivery-info-item">
    <h4 className="info-item-title">{title}</h4>
    <div className="info-item-content">{content}</div>
  </li>
);

const DeliveryInfo = ({ delivery, deliveries }: IRecordDetails) => {
  return (
    <div className="delivery-info-content">
      <h3>Delivery details</h3>
      <ul>
        <InfoItem
          title="Tracking link"
          content={
            <EditAttrib
              attrib="trackinglink"
              delivery={delivery}
              deliveries={deliveries}
              isLink
            />
          }
        />
        <InfoItem
          title="Docs tracking link"
          content={
            <EditAttrib
              attrib="docslink"
              delivery={delivery}
              deliveries={deliveries}
              isLink
            />
          }
        />
        <InfoItem
          title="Comments"
          content={delivery.comment || 'No comments'}
        />
        <Authorised to="edit" model={delivery.attribs}>
          <InfoItem
            title="Last notified"
            content={
              <NotifyDelivery delivery={delivery} deliveries={deliveries} />
            }
          />
        </Authorised>
      </ul>
    </div>
  );
};

const DeliveryDetails = ({ delivery, deliveries }: IRecordDetails) => {
  const actions = useDeliveryAttachments(delivery);

  return (
    <div className="delivery-details">
      <DeliveryInfo delivery={delivery} deliveries={deliveries} />
      <AddFiles delivery={delivery} deliveries={deliveries} actions={actions} />
    </div>
  );
};

const DeliveryTrades = ({ delivery, deliveries }: IRecordDetails) => {
  const { can } = useAuthorisation();

  const column = (
    name: string,
    title: string,
    render: (trade: TradeData) => React.ReactNode,
    className?: string
  ) => ({
    key: `delivery-trades-table-${name}`,
    className: classnames(`col-${name}`, className),
    title,
    render,
  });

  const columns = [
    column('pid', 'ID', trade => (
      <Link to={`/trades/${trade.id}`}>{trade.pid}</Link>
    )),
    column('product', 'Product', R.path(['product', 'name'])),
    column('price', 'Price', trade => (
      <>
        {trade.price.val} <span className="unit">{trade.price.unit}</span>
      </>
    )),
    column('volume', 'Volume', trade => (
      <>
        {trade.volume.val} <span className="unit">{trade.volume.unit}</span>
      </>
    )),
    ...inArrayIf(
      can('edit', delivery),
      column('delete', '', trade => (
        <Button
          size="small"
          type="secondary"
          loading={deliveries.loading}
          disabled={deliveries.loading}
          onClick={() => {
            deliveries.removeTrade(delivery, trade);
          }}
        >
          Remove
        </Button>
      ))
    ),
  ];

  return (
    <div className="delivery-trades">
      <h3>Consolidated trades</h3>
      {delivery.trades.length ? (
        <SimpleTable
          className="delivery-trades-table"
          data={delivery.trades}
          columns={columns}
          rowKey="id"
        />
      ) : (
        <Alert type="info" className="has-icon align-center">
          There are currently no trades in this delivery
        </Alert>
      )}
    </div>
  );
};

interface ITradeTotal {
  total: { [K: string]: number };
  index: 'value' | 'title';
  p?: number;
}
const TradeTotal = ({ total, index, p }: ITradeTotal) => {
  const values = R.toPairs(total).sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <>
      {values.map(([key, value]) => (
        <p key={key} className="m-0">
          {index === 'value' && (
            <span className="nowrap">
              <FNumber decimalCount={p || 0} value={value} />
            </span>
          )}
          {index === 'title' && <span className="unit nowrap">{key}</span>}
        </p>
      ))}
    </>
  );
};

const RecordDetails = ({ delivery, deliveries }: IRecordDetails) => {
  return (
    <div className="delivery-info">
      <DeliveryTrades delivery={delivery} deliveries={deliveries} />
      <DeliveryDetails delivery={delivery} deliveries={deliveries} />
    </div>
  );
};

interface ITable {
  deliveries: IUseDeliveries;
}
const Table = ({ deliveries }: ITable) => {
  const column = (
    name: string,
    title: React.ReactNode,
    render: (d: Delivery) => React.ReactNode,
    className: string = ''
  ) => ({
    key: `deliveries-table-${name}`,
    className: classnames(`col-${name}`, className),
    title,
    render,
  });

  const columns = [
    column('details', '', () => null),
    column('reference', 'Reference', R.prop('reference')),
    column('seller', 'Seller', R.path(['seller', 'name'])),
    column('customer', 'Customer', R.path(['buyer', 'name'])),
    column('buyer', 'Buyer', R.path(['partners', 'buyer', 'name'])),
    column('consignee', 'Consignee', R.path(['partners', 'consignee', 'name'])),
    column('etd', 'ETD', delivery =>
      delivery.etd ? delivery.etd.format('DD MMM YYYY') : 'Not set'
    ),
    {
      key: 'deliveries-table-value',
      className: 'col-value',
      title: (
        <>
          Trades&apos; value
          <span className="unit block mt--8">Per currency</span>
        </>
      ),
      children: [
        {
          key: 'deliveries-table-value-total',
          className: 'col-value-total align-right',
          title: 'Total',
          render: (s: any) => (
            <TradeTotal p={4} total={s.totalValue} index="value" />
          ),
        },
        {
          key: 'deliveries-table-value-unit',
          className: 'col-value-unit',
          title: 'Unit',
          render: (s: any) => (
            <TradeTotal p={4} total={s.totalValue} index="title" />
          ),
        },
      ],
    },
    {
      key: 'deliveries-table-volume',
      className: 'col-volume',
      title: (
        <>
          Trades&apos; volume
          <span className="unit block mt--8">Per loading</span>
        </>
      ),
      children: [
        {
          key: 'deliveries-table-volume-total',
          className: 'col-volume-total align-right',
          title: 'Total',
          render: (s: any) => (
            <TradeTotal p={0} total={s.totalVolume} index="value" />
          ),
        },
        {
          key: 'deliveries-table-volume-unit',
          className: 'col-volume-unit',
          title: 'Unit',
          render: (s: any) => (
            <TradeTotal p={0} total={s.totalVolume} index="title" />
          ),
        },
      ],
    },
    column('eta', 'ETA', delivery =>
      delivery.eta ? delivery.eta.format('DD MMM YYYY') : 'Not set'
    ),
  ];

  return (
    <>
      <GenericTable
        className="deliveries-table generic-table"
        data={deliveries.deliveries}
        columns={columns}
        rowKey="id"
        expandable={{
          defaultExpandAllRows: false,
          expandedRowRender: (record, index, expanded) =>
            expanded ? (
              <RecordDetails delivery={record} deliveries={deliveries} />
            ) : null,
        }}
      />
      <Pagination pagination={deliveries} />
    </>
  );
};

export default () => {
  const deliveries = useDeliveries();
  const { page, limit } = deliveries;

  useEffect(() => {
    deliveries.load();
  }, [page, limit]);

  return deliveries.deliveries.length ? (
    <Table deliveries={deliveries} />
  ) : deliveries.loading ? (
    <Loading size="medium" />
  ) : (
    <>
      <div className="nui-row full-page-messaging align-center mt--40 p-100 pb-110">
        <div className="icon-inbox bordered align-center">
          There are currently no shipments to display
        </div>
      </div>
    </>
  );
};
