import React from 'react';

export default ({ yes, notRequired = false }) =>
  yes ? (
    <span className="icon-ok">Yes</span>
  ) : notRequired ? (
    <span className="icon-block">Not required</span>
  ) : (
    <span className="icon-block">No</span>
  );
