import React from 'react';
import { useScrollToTopOnMount } from '~/hooks.js';
import TradesTable from './Table';
import { useTradesTable } from '~/store/models';
import Header from './Header';
import Helmet from 'Components/Helmet';

export default () => {
  useScrollToTopOnMount();

  const { pagination, ...table } = useTradesTable();

  return (
    <>
      <Helmet>
        <title>Trades</title>
      </Helmet>
      <Header />
      <div className="full-page-content">
        {table.noContent() ? (
          <div className="nui-row full-page-messaging align-center mt--40 p-100 pb-110">
            <div className="icon-inbox bordered align-center">
              There are currently no trades to display
            </div>
          </div>
        ) : (
          <TradesTable trades={table} pagination={pagination} />
        )}
      </div>
    </>
  );
};
