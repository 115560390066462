import type { Tender } from '~/models/tenders';
import React from 'react';
import { withPreload } from '~/router';
import * as services from '~/services/tenders';

type TenderProp = Record<'tender', Tender>;

export default ({ tender }: TenderProp) => {
  return tender.isRunning() && !tender.owned ? (
    <div className="tender-ranking-totals">
      <Display />
    </div>
  ) : null;
};

const Display = withPreload({
  route: 'tender-view',
  preload: services.observe.orders({ requireId: false }),
  size: 'tiny',
})(({ data: { tender, orders } }) => {
  const leading = orders.filter(o => o.counters.mine?.rank === 1).length;
  const losing = orders.filter(o => o.counters.mine?.rank > 1).length;

  if (tender && leading + losing > 0)
    return (
      <>
        <strong className="all-black">
          {tender.ordername.counter} summary
        </strong>
        {leading > 0 && (
          <span className="counter-summary leading">{leading} leading</span>
        )}
        {losing > 0 && (
          <span className="counter-summary losing">{losing} outbid</span>
        )}
      </>
    );

  return <></>;
});
