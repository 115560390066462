import { Alert } from 'Components/nui';
import React from 'react';
import { useSharedRoute } from '.';
import R from 'ramda';

export default () => {
  const [route] = useSharedRoute();

  return (
    <>
      <h3 className="mb-17">Summary</h3>

      {route ? (
        <>
          <dl className="summary-list">
            <dt>Route</dt>
            <dd>{route.name}</dd>

            <dt>Duration</dt>
            <dd>{route.duration} days</dd>
          </dl>

          {route.freight && route.freight.length > 0 && (
            <dl className="summary-list stacked numbered">
              <dt>Freight</dt>
              {route.freight.map(item => (
                <dd key={item.id}>
                  {R.pathOr('', ['division', 'name'], item)},{' '}
                  {R.pathOr('', ['container'], item)},{' '}
                  {item.reefer ? 'Reefer, ' : ''}
                  {R.pathOr('', ['incoterm'], item)},{' '}
                  {R.pathOr('', ['rate', 'val'], item)}{' '}
                  {R.pathOr('', ['rate', 'unit'], item)}
                </dd>
              ))}
            </dl>
          )}
        </>
      ) : (
        <Alert hasicon>
          <p className="smaller">
            A progress summary of the route you are adding will appear here. You
            can save and exit at anytime, and come back to complete the process.
          </p>
        </Alert>
      )}
    </>
  );
};
