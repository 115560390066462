import React, { useEffect, useState } from 'react';
import * as api from '~/fetch';
import { mergeSchema, normalizeChoices } from 'Components/form/utils';
import { FormWrapper } from 'Components/form';
import { promisify } from 'util';
import { Form, Button, Alert } from 'Components/nui';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useUser } from '../Context';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Loading } from 'Components/nui';
import { useStoreState } from 'easy-peasy';
import R from 'ramda';

type IWrappedForm = (props: any) => React.ReactElement;

function useFormWrapper({ form, schema }: any) {
  const suggestedTimeZone = useStoreState(state => state.auth.user?.timezone);
  const [mobileValue, setMobileValue] = useState(schema?.mobilephone?.value);
  const [phoneValue, setPhoneValue] = useState(schema?.ddiphone?.value);
  const userEmail = useStoreState(state => state.auth.user?.email);
  const fields = [
    {
      name: 'firstname',
      label: 'First name',
      type: 'Input',
      required: true,
      initialValue: schema?.firstname?.value,
    },

    {
      name: 'lastname',
      label: 'Last name',
      type: 'Input',
      required: true,
      initialValue: schema?.lastname?.value,
    },

    {
      name: 'email',
      label: 'Email',
      type: 'Input',
      required: true,
      props: {
        type: 'email',
        placeholder: 'E.g. someone@' + userEmail.split('@')[1],
        disabled: schema?.email?.disabled,
      },
      initialValue: schema?.email?.value,
    },

    {
      name: 'ddiphone',
      label: 'Phone',
      render: () => (
        <div className="nui-fieldset">
          <label>
            Phone <span className="optional">optional</span>
          </label>
          <div className="input-holder icon-globe">
            <PhoneInput
              international
              value={phoneValue}
              onChange={setPhoneValue}
            />
          </div>
        </div>
      ),
      serializeField: () => {
        return { ddiphone: formatPhoneNumberIntl(phoneValue) };
      },
    },

    {
      name: 'mobilephone',
      label: 'Mobile',
      initialValue: schema?.mobilephone?.value,
      render: () => (
        <div className="nui-fieldset">
          <label>
            Mobile <span className="optional">optional</span>
          </label>
          <div className="input-holder icon-globe">
            <PhoneInput
              international
              value={mobileValue}
              onChange={setMobileValue}
            />
          </div>
        </div>
      ),
      serializeField: () => {
        return { mobilephone: formatPhoneNumberIntl(mobileValue) };
      },
    },

    {
      name: 'timezone',
      label: 'Time zone',
      type: 'Select',
      choices: normalizeChoices(schema.timezone.choices),
      initialValue: R.pathOr(suggestedTimeZone, ['timezone', 'value'], schema),
    },
  ];

  return new FormWrapper(form, {}, mergeSchema(schema, fields));
}

function BaseForm({ form, schema, setUser, id }: any) {
  const history = useHistory();
  const formWrapper = useFormWrapper({ form, schema });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }

    setLoading(true);

    const sentData = { ...formWrapper.serialize() };
    try {
      const response = await api.users.updateUserInfo(id, sentData);
      await api.utils.processResponse(response, {
        async 200(response) {
          const data: any = await response.json();

          setUser(id, data.user);
          setLoading(false);

          history.push(`/users?user_id=${data.user.id}`);
        },

        async 400(response) {
          const data = await response.json();
          formWrapper.setErrors(data.errors[0].description);
          setLoading(false);
        },
        async default(response) {
          toast.error(
            <>An unknown error occured, please refresh the page or try again</>
          );
          setLoading(false);
        },
      });
    } catch (err) {
      formWrapper.setErrors(err);
      setLoading(false);
    }
  }

  return (
    <div className="full-page-header">
      <hr className="mt-10" />
      <Alert hasicon type="info" className="mb-20">
        Please contact support for adding additional roles to users if not
        available
      </Alert>
      <div className="nui-form add-user-form">
        <Form onSubmit={handleSubmit}>
          {formWrapper.render()}
          <div className="button-set pt-20">
            <Button type="primary" htmlType="submit" disabled={loading}>
              {id ? 'Save' : 'Add user'}
            </Button>

            <Link
              to="/users"
              className="nui-button nui-simple"
              title="Cancel and go back to the main users page"
            >
              Cancel
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
}

const AddForm: IWrappedForm = Form.create()(BaseForm) as any;

export default () => {
  const { id } = useParams<{ id: string }>();
  const { setUser } = useUser(id);
  const [schema, setSchema] = useState({});
  const [schemaLoading, setSchemaLoading] = useState(false);

  const hasSchema = Object.keys(schema).length;

  useEffect(() => {
    let mounted = true;
    const loadUserInfoSchema = async () => {
      try {
        setSchemaLoading(true);
        const response = await api.users.userSchema(id);
        await api.utils.processResponse(response, {
          async 200(response) {
            const data: any = await response.json();
            if (mounted) {
              setSchema(data.fields);
              setSchemaLoading(false);
            }
          },

          async default(response) {
            toast.error(
              <>
                An unknown error occured, please refresh the page or try again
              </>
            );
          },
        });
      } catch (error) {
        toast.error(
          <>An unknown error occured, please refresh the page or try again</>
        );
      }
    };

    loadUserInfoSchema();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <div className="full-page-header">
        <h1 className="inline mt-10 mr-20 mb-0">{id ? 'Edit' : 'Add'} user</h1>

        <ul className="nui-bc right pt-20">
          <li>
            <BreadcrumbRoot active="users" />
          </li>
          <li>
            <Link to="/users">Users</Link>
          </li>
          <li>{id ? 'Edit user' : 'Add'}</li>
        </ul>
      </div>

      {hasSchema && !schemaLoading ? (
        <AddForm schema={schema} setUser={setUser} id={id} />
      ) : (
        <Loading />
      )}
    </>
  );
};
