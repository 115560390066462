import React from 'react';
import R from 'ramda';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const IncotermColumn = ColumnTree.defineNode(
  'incoterms',
  ({ solutionColumns }) => ({
    title: R.propOr('Incoterm', 'label', solutionColumns.incoterms),
    children: [
      {
        title: '',
        key: 'incoterm',
        className: 'col-incoterm',
        render: (text, record, index) => (
          <div className="td-content">
            <span className="tiny-label">
              {R.propOr('Incoterm', 'label', solutionColumns.incoterms)}
            </span>
            <abbr>{record.incoterms && record.incoterms.join(', ')}</abbr>
          </div>
        ),
      },
    ],
  })
);
