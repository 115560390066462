import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Link } from 'react-router-dom';
import { useSharedTrade } from '.';
import { FormattedMessage } from 'react-intl';

export default () => {
  const [trade] = useSharedTrade();

  if (!trade) return null;

  return (
    <>
      <h1 className="inline">
        <FormattedMessage
          id="viewtrade-header"
          description="Header for View Trade details"
          defaultMessage="View trade <span>{pid}</span>"
          values={{
            pid: trade.pid,
            span: chunks => <span className="smaller">{chunks}</span>,
          }}
        />
      </h1>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="trades" />
        </li>
        <li>
          <Link to="/trades" title="Back to the trades page">
            Trades
          </Link>
        </li>
        <li>View</li>
      </ul>
    </>
  );
};
