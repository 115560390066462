import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { useStoreState } from '~/store';
import R from 'ramda';
import TableFilter, { IFilterConfig } from 'Components/TableFilter';
import DropCheck from 'Components/nui/form/DropCheck';
import { useOTable } from '~/store/models';
import { OrderStatus } from '~/const';
import { capitalize } from '~/utils';
import FieldHolder from 'Components/nui/form/FieldHolder';
import ExtraActions from './ExtraActions';
import { useToggle } from 'react-use';
import qs from 'qs';
import { api } from '~/api';
import { Button, DatePicker } from 'Components/nui';

interface IExportButton {
  filters: IFilterConfig<any>;
}

const ExportButton = ({ filters }: IExportButton) => {
  const [loading, toggle] = useToggle(false);
  const solution = useStoreState(state => state.auth.solution);

  const exportToCSV = async () => {
    toggle(true);
    const params = {
      ...filters.values(),
      solution: solution.id,
    };
    const url = `/orders?${qs.stringify(params, { indices: false })}`;
    await api.getFile(url, 'Orders.csv', 'text/csv');
    toggle(false);
  };

  return (
    <Button
      htmlType="button"
      size="small"
      className="export-button"
      type="secondary"
      loading={loading}
      onClick={exportToCSV}
    >
      <span className="icon-export"></span>
      <span className="show-for-large">Export to</span> CSV
    </Button>
  );
};

export default () => {
  const orders = useOTable();
  const { filters, pagination } = orders;

  const traderType: any = useStoreState(R.path(['auth', 'traderType']));
  const products = useStoreState(
    R.pathOr<any[]>([], ['auth', 'commodityFilteredProducts'])
  );

  return (
    <div className="full-page-header">
      <h1 className="inline mt-10 mr-20 mb-0">Orders</h1>
      <div className="filter-export">
        <TableFilter
          filters={filters}
          total={pagination.total()}
          loading={orders.loading()}
          extra={<ExportButton filters={filters} />}
        >
          <FieldHolder
            name="product"
            label="Products"
            className="cb-set stack-9"
          >
            <DropCheck
              name="product"
              title="All products"
              options={products.map(p => [p.id, p.name])}
              value={filters.value('product')}
              onChange={filters.filter}
            />
          </FieldHolder>
          <FieldHolder name="status" label="Status" className="cb-set">
            <DropCheck
              name="status"
              title="All"
              options={OrderStatus.map(s => [s, capitalize(s)])}
              value={filters.value('status')}
              onChange={filters.filter}
            />
          </FieldHolder>
          <FieldHolder name="created" label="Created between">
            <DatePicker.RangePicker
              name="created"
              format="DD MMM YYYY"
              value={filters.value('created')}
              onChange={value => {
                filters.filter('created', value);
              }}
            />
          </FieldHolder>
          <FieldHolder name="etd" label="Estimated time of departure">
            <DatePicker.RangePicker
              name="etd"
              format="DD MMM YYYY"
              value={filters.value('etd')}
              onChange={value => {
                filters.filter('etd', value);
              }}
            />
          </FieldHolder>
          {traderType.seller && traderType.buyer && (
            <FieldHolder label="Type">
              <DropCheck
                name="issell"
                title="All"
                options={[
                  [0, 'Bid'],
                  [1, 'Offer'],
                ]}
                value={filters.value('issell')}
                onChange={filters.filter}
              />
            </FieldHolder>
          )}
        </TableFilter>
      </div>
      <ExtraActions orders={orders} />

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="orders" />
        </li>
        <li>Orders</li>
      </ul>
      <hr className="mb-40" />
    </div>
  );
};
