import { Moment } from 'moment-timezone';

export type SuccessResult<T> = Readonly<{
  type: 'success';
  value: T;
  since?: Moment;
}>;
export type ErrorResult = Readonly<{ type: 'error'; errors: any[] }>;
export type Result<T> = Readonly<
  { type: string } & (SuccessResult<T> | ErrorResult)
>;

export const error = (errors: any[] = []): ErrorResult => ({
  type: 'error',
  errors,
});
export const success = <T>(value: T, since?: Moment): SuccessResult<T> => ({
  type: 'success',
  value,
  since,
});

export const match = async <T, RS, RE>(
  result: Result<T>,
  callback: {
    success: (result: SuccessResult<T>) => Promise<RS>;
    error: (result: ErrorResult) => Promise<RE>;
  }
) => {
  if (result.type === 'success') return callback.success(result);
  if (result.type === 'error') return callback.error(result);
};
