import React, { useEffect, useContext } from 'react';
import Navigation from './Navigation';
import Summary from './Summary';
import Content from './Content';
import { createStateContext } from 'react-use';
import { useStoreActions } from 'easy-peasy';
import Header from './Header';
import { useLocation, useParams } from 'react-router-dom';
import { ApiContext } from '~/context';
import { Product as ProductModel } from '~/models';
import Helmet from 'Components/Helmet';

export const [useSharedProduct, SharedProductProvider] = createStateContext();

const Loader = () => {
  const { id } = useParams();
  const api = useContext(ApiContext);
  const [product, setProduct] = useSharedProduct();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  useEffect(() => {
    const fetch = async () => {
      const data = await api.getData({
        type: 'products',
        id,
        getResult: x => new ProductModel(x.product),
      });
      setProduct(data);
    };
    if (id) fetch();
  }, [id, pathname]);

  if (!product) return null;

  return (
    <Helmet>
      <title>Edit product {product.name}</title>
    </Helmet>
  );
};

export default () => {
  const fetchProducts = useStoreActions(action => action.auth.fetchProducts);
  const fetchUser = useStoreActions(action => action.auth.fetchUser);

  useEffect(() => {
    return () => {
      fetchProducts();
      fetchUser();
    };
  }, [fetchProducts, fetchUser]);

  return (
    <SharedProductProvider>
      <Loader />
      <div className="full-page-header">
        <Header />

        <hr className="sm10-md20 mb-0" />

        <div className="wizard multiple has-summary">
          <div className="wizard-steps">
            <Navigation />
          </div>
          <div className="wizard-content">
            <Content />
          </div>
          <div className="wizard-summary">
            <Summary />
          </div>
        </div>
      </div>
    </SharedProductProvider>
  );
};
