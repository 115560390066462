import { Button, Modal } from 'Components/nui';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import * as api from '~/fetch';
import { useRoles } from '../Context';
import Edit from './Edit';
import { capitalize } from '~/utils';

export default ({ userId, role, canAdd }: any) => {
  const [editing, setEditing] = useState(false);
  const { deleteRole } = useRoles(userId);

  const [isDeleting, setIsDeleting] = useToggle(false);

  const [roleToDelete, setRoleToDelete] = useState<any>();

  const showDeleteModal = (role: any) => {
    setRoleToDelete(role);
  };

  const closeDeleteModal = () => {
    setRoleToDelete(null);
  };

  async function handleDelete(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    try {
      setIsDeleting(true);
      const response = await api.users.deleteUserRole(userId, role.id);

      await api.utils.processResponse(response, {
        async 200(response: any) {
          const data: any = await response.json();
          deleteRole(role.id, data.user);
          setIsDeleting(false);
        },
        async default(response) {
          toast.error(
            <>An unknown error occured, please refresh the page or try again</>
          );
        },
      });
    } catch (err) {
      toast.error(
        <>An unknown error occured, please refresh the page or try again</>
      );
    }
  }

  const canEdit = role?.__acl__?.edit || canAdd;

  return (
    <>
      {roleToDelete && (
        <Modal size="small" close={closeDeleteModal}>
          <h2>Warning</h2>
          <p>
            Are you sure you want to delete this user&apos;s role? This
            can&apos;t be undone.
          </p>
          <hr />
          <div className="button-set">
            <Button
              type="primary"
              onClick={e => handleDelete(e)}
              loading={isDeleting}
            >
              Yes, delete it
            </Button>
            <Button type="simple" onClick={closeDeleteModal}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}

      {editing ? (
        <Edit userId={userId} role={role} setEditing={setEditing} />
      ) : (
        <div id={role?.id} className="user">
          <span className="user-solution">
            {role?.division?.solution?.name
              ? capitalize(role?.division?.solution?.name)
              : ''}
          </span>
          <span className="user-division">{role?.division.name}</span>
          <span className="user-role">{role?.role}</span>

          {canEdit ? (
            role?.id ? (
              <>
                <Button
                  title="Edit this role"
                  icon="pencil"
                  className="editme"
                  onClick={() => {
                    setEditing(true);
                  }}
                />

                <Button
                  title="Delete this role"
                  icon="trash"
                  className="deleteme"
                  onClick={() => {
                    showDeleteModal(role);
                  }}
                />
              </>
            ) : canAdd ? (
              <Button
                title="Add a role"
                className="nui-button-link add-role"
                onClick={() => {
                  setEditing(true);
                }}
              >
                Add a role
              </Button>
            ) : null
          ) : (
            <Button
              title={
                'You cannot edit or delete this role because you are not a manager in this division'
              }
              icon="pencil"
              className="editme"
              onClick={() => {
                setEditing(true);
              }}
              disabled={true}
            />
          )}
        </div>
      )}
    </>
  );
};
