import { TradeData } from '../modelTypes';
import R from 'ramda';
import { useStoreActions, useStoreState } from '~/store';
import { ModelApi } from '../ModelApi';
import { useModel } from '../useModel';
import Trade from './Trade';
import { TradeStatus } from '~/const';
import moment, { Moment } from 'moment-timezone';
import { useEffect } from 'react';
import { Filters } from '../types';
import { arrayFilter } from '../utils';

export { Trade };

export class TradeApi extends ModelApi {
  constructor() {
    const url = '/trades';
    super({ url });
  }
}

interface Config {
  tag?: string;
  filterConfig?: {
    serialize: (f: Filters) => Filters;
    deserialize: (f: { [name: string]: string[] }) => Filters;
    initial: Filters;
  };
}
export function useTrades({ tag = '', filterConfig }: Config) {
  const getResult = R.pathOr<TradeData[]>([], ['data', 'trades']);
  const api = new TradeApi();

  const actions = useStoreActions(state => state.models.trades);
  const state = useStoreState(state => state.models.trades);

  const trades = useModel({
    defaultTag: tag,
    getResult,
    actions,
    state,
    api,
    filterConfig,
  });

  return {
    ...trades,

    fetch() {
      return trades.fetch().map(o => new Trade(o));
    },
  };
}

export function useTradesTable() {
  const solution = useStoreState(state => state.auth.solution);
  const products = useStoreState(
    R.pathOr<any[]>([], ['auth', 'commodityFilteredProducts'])
  );

  const getCustomers = () => {
    const customers = R.pipe(
      R.chain(R.propOr([], 'customers')),
      R.uniq,
      R.map(R.prop('id') as (x: any) => string)
    )(products) as string[];
    if (!customers.length)
      return R.pipe(R.map(R.path(['owner', 'id'])), R.uniq)(products);
    else return customers;
  };

  const trades = useTrades({
    tag: `trades-table-${solution.id}`,
    filterConfig: {
      initial: {
        product: products.map(R.prop('id')),
        status: TradeStatus,
        created: [],
        etd: [],
        otherparty: getCustomers(),
      },
      serialize: filters => {
        const product = arrayFilter('product', products, filters);
        const status = arrayFilter('status', TradeStatus, filters);
        const otherparty = arrayFilter('otherparty', getCustomers(), filters);

        const fmt = 'YYYY-MM-DD';
        const isDt = (dt: any[]): dt is [Moment, Moment] =>
          dt.length === 2 && moment.isMoment(dt[0]) && moment.isMoment(dt[1]);

        let etd;
        if (filters.etd && isDt(filters.etd)) {
          etd = filters.etd.map(d => d.clone().startOf('day').format(fmt));
        }

        let created;
        if (filters.created && isDt(filters.created)) {
          const [start, end] = filters.created.map((d, i) =>
            d.clone().add(i, 'days').startOf('day').format(fmt)
          );
          created = `gte:${start},lte:${end}`;
        }

        return { ...filters, product, status, etd, created, otherparty };
      },
      deserialize: filters => {
        const included = Object.keys(filters);

        const created = R.includes('created', included)
          ? R.pipe(
              R.split(','),
              R.zip(['gte:', 'lte:']),
              R.map(([op, d]) => moment(R.replace(op, '', d)))
            )(filters.created[0])
          : [];

        const etd = R.includes('etd', included)
          ? filters.etd.map(d => moment(d))
          : [];

        return { ...filters, etd, created };
      },
    },
  });

  // Initial setup
  useEffect(() => {
    trades.pagination.changeLimit({ value: 10 });
    trades.pagination.changePage({ value: 1 });
  }, []);

  // Run updates
  useEffect(() => {
    trades.update({
      config: {
        params: { sort: '-created', solution: solution.id },
      },
    });
  }, trades.shouldUpdate());

  return trades;
}
