import moment, {
  duration,
  Moment,
  MomentInput,
  Duration,
} from 'moment-timezone';
import { TimeSpan } from '~/utils/timespan';
import { IBanner } from '~/store/types';

type TimeStatus = 'past' | 'present' | 'future';

export function durationBetween(
  a: MomentInput,
  b: MomentInput = moment()
): Duration {
  const from = moment(a);
  const to = moment(b);
  const minutes = from.diff(to, 'minutes');
  return duration(from.isAfter(to) ? -minutes : minutes, 'minutes');
}

export function timeStatus(
  { start, end }: IBanner,
  now: Moment = moment()
): [TimeStatus, Duration] {
  const ts = new TimeSpan(start, end);
  switch (ts.pastPresentFuture(now)) {
    case 'past':
      return ['past', durationBetween(end, now)];
    case 'present':
      return ['present', durationBetween(now, end)];
    case 'future':
      return ['future', durationBetween(now, start)];
  }
}
