import { OrderData } from '../modelTypes';
import moment from 'moment-timezone';
import Base from '../Model';
import { Warehouse } from '..';
import { getDecimalCount } from '~/utils';
import R from 'ramda';
import { Order as OldOrder } from '~/models';

interface Currency {
  currency: string;
  unit: string;
  val: number;
}

// @Alex, please do the TODO :)
// TODO This is ridiculous. Figure out a way to type Object.defineProperty so that it plays nicely with TS.
export default class Order extends Base<OrderData> {
  old() {
    return new OldOrder(this.state);
  }

  get pid() {
    return this.state.pid;
  }
  get type() {
    return this.state.type;
  }
  get revision() {
    return this.state.revision;
  }
  get status() {
    return this.state.status;
  }
  get splittable() {
    return this.state.splittable;
  }
  get reference() {
    return this.state.reference;
  }
  get note() {
    return this.state.note;
  }

  get tradeable() {
    return this.state.tradeable;
  }
  get reason() {
    return this.state.reason;
  }
  get counteronly() {
    return this.state.counteronly;
  }

  get isOwner() {
    return this.state.is_owner;
  }
  get isPrivate() {
    return this.state.isprivate;
  }
  get isDeclined() {
    return this.state.isdeclined;
  }

  get etd() {
    return {
      from: moment(this.state.etd.from),
      to: moment(this.state.etd.to),
    };
  }
  get quarteretd() {
    return this.state.quarteretd;
  }

  get deliveryfrequency() {
    return this.state.deliveryfrequency;
  }

  get totaldeliveries() {
    return this.state.totaldeliveries;
  }

  get created() {
    return moment(this.state.created);
  }
  get updated() {
    return moment(this.state.updated);
  }
  get expiry() {
    return moment(this.state.expiry);
  }

  get creator() {
    return this.state.creator;
  }
  get owner() {
    return this.state.owner;
  }

  get product() {
    return this.state.product;
  }
  get loading() {
    return this.state.loading;
  }
  get volume() {
    return this.state.volume;
  }
  get price() {
    return this.state.price;
  }
  get _price() {
    return this.state._price;
  }
  get index() {
    return this.state.index;
  }
  get indexdate() {
    return this.state.indexdate;
  }

  get division() {
    return this.state.division;
  }

  get broker() {
    return this.state.broker;
  }

  get fromaddr() {
    return R.pathOr([], ['state', 'fromaddr'], this).map(w => new Warehouse(w));
  }
  get toaddr() {
    return R.pathOr([], ['state', 'toaddr'], this).map(w => new Warehouse(w));
  }

  get trades() {
    return this.state.trades;
  }

  get attachments() {
    return this.state.attachments;
  }

  get attributes() {
    return this.state.attributes;
  }

  priceDecimalCount() {
    if (!this.price || !this.price.step) return 0;
    return getDecimalCount(this.price.step);
  }

  volumeDecimalCount() {
    if (!this.loading || !this.loading.qty) return 0;
    return getDecimalCount(this.loading.qty);
  }

  currencies() {
    return R.concat(
      [this.price as Currency],
      R.propOr([], 'currencies', this.price)
    );
  }

  hasCurrencies() {
    return this.currencies.length > 1;
  }

  getPriceObjByCur(cur: string) {
    return (
      R.find(R.propEq('currency', cur), this.currencies()) || {
        currency: 'n/a',
        unit: 'n/a',
        val: 0,
      }
    );
  }
}
