import React from 'react';
import R from 'ramda';
import FNumber from 'Components/FNumber';
import { Table } from 'Components/nui';
import YesNo from 'Components/YesNo';
import { useStoreState } from 'easy-peasy';
import { inArrayIf, capitalize } from '~/utils';

export default ({ customer }) => {
  const solution = useStoreState(state => state.auth.solution);

  const columns = [
    {
      title: 'Division',
      key: 'bname',
      className: 'col-division valign-t',
      render: (text, record, index) => (
        <div className="td-content">
          {R.pathOr('', ['seller', 'name'], record)} (
          {R.pathOr('', ['seller', 'shortcode'], record)})
        </div>
      ),
    },
    {
      title: 'Reference',
      key: 'reference',
      className: 'col-reference valign-t',
      render: (text, record, index) => (
        <div className="td-content">{R.pathOr('', ['reference'], record)}</div>
      ),
    },
    {
      title: 'Payment term',
      key: 'paymentterm',
      className: 'col-paymentterm valign-t',
      render: (text, record, index) => (
        <div className="td-content">
          {R.pathOr('', ['paymentterm', 'desc'], record)}
        </div>
      ),
    },

    ...inArrayIf(solution.freight === 'customer', {
      title: 'Freight type',
      key: 'freight',
      className: 'col-freight',
      render: (_, record) => (
        <div className="td-content">
          {capitalize(R.pathOr('', ['freight', 'type'], record))}
        </div>
      ),
    }),

    {
      title: 'Docs approved',
      children: [
        {
          title: 'Seller',
          key: 'sellerdoc',
          className: 'col-sellerdoc',
          render: (text, record, index) => {
            return (
              <div className="td-content">
                <YesNo yes={record.seller.docsapproved} />
              </div>
            );
          },
        },
        {
          title: 'Buyer',
          key: 'buyerdoc',
          className: 'col-buyerdoc',
          render: (text, record, index) => {
            return (
              <div className="td-content">
                <YesNo yes={record.buyer.docsapproved} />
              </div>
            );
          },
        },
      ],
    },

    {
      title: 'Credit',
      children: [
        {
          title: `Limit, ${solution.currency}`,
          key: 'limit',
          className: 'col-creditlimit',
          render: (text, record, index) => {
            return (
              <div className="td-content">
                {record.nocreditlimit ? (
                  'No limit'
                ) : (
                  <FNumber value={R.pathOr(0, ['credit', 'amount'], record)} />
                )}
              </div>
            );
          },
        },
        {
          title: `Remaining, ${solution.currency}`,
          key: 'remaining',
          className: 'col-creditremaining',
          render: (text, record, index) => {
            return (
              <div className="td-content">
                {record.nocreditlimit ? (
                  'n/a'
                ) : (
                  <FNumber
                    value={R.pathOr(0, ['credit', 'remaining'], record)}
                  />
                )}
              </div>
            );
          },
        },
        {
          title: 'Period',
          key: 'period',
          render: (text, record, index) => {
            return (
              <div className="td-content">
                {record.nocreditlimit
                  ? 'n/a'
                  : R.pathOr('', ['credit', 'period'], record)}
              </div>
            );
          },
        },
      ],
    },
  ];

  return (
    <div className="generic-table light">
      <Table
        className="table-wrapper"
        columns={columns}
        data={customer.links}
      />
    </div>
  );
};
