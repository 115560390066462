import type * as Market from 'models.market';

import { useState, useEffect } from 'react';
import * as api from '~/fetch';

export function useSchema(order: Market.Order, counter?: Market.Counter) {
  const [schema, setSchema] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const response = await api.market.counterSchema(order.id, counter?.id);
        await api.utils.processResponse(response, {
          async 200(response) {
            const data = await response.json();
            if (mounted) {
              setSchema(data.fields);
              if (data.message) setError(data.message);
            }
          },
          default() {
            throw new Error('Unexpected error');
          },
        });
      } catch (error) {
        if (mounted) {
          setError('Your request could not be processed.');
        }
      }

      if (mounted) setLoading(false);
    })();

    return () => void (mounted = false);
  }, []);

  if (loading) return { loading };
  if (error) return { error };
  return { schema };
}

export function useClients(party: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [choices, setChoices] = useState<[string, string][]>([]);

  useEffect(() => {
    if (party) {
      setLoading(true);

      (async () => {
        try {
          const response = await api.market.clientUsers(party);
          await api.utils.processResponse(response, {
            async 200(response) {
              const data = await response.json();
              setChoices(data.choices);
              setError(false);
            },
            default() {
              throw new Error('Unexpected error');
            },
          });
        } catch (error) {
          setError(true);
        }
        setLoading(false);
      })();
    }
  }, [party]);

  return { loading, error, choices };
}

type RUpdate = Promise<{ validate?: any; error?: Error; success?: true }>;
interface IUpdate {
  order: string;
  counter?: string;
  formdata: FormData;
}
export async function update({ order, counter, formdata }: IUpdate): RUpdate {
  try {
    const response = await api.market.updateCounter({
      order,
      counter,
      formdata,
    });
    return await api.utils.processResponse(response, {
      200() {
        return { success: true };
      },
      async 400(response) {
        const data = await response.json();
        const validate = data.errors[0].description;
        return { validate };
      },
      default() {
        throw new Error('Unexpected error');
      },
    });
  } catch (error) {
    return { error };
  }
}

type RWithdraw = Promise<{ success?: true; error?: Error }>;
export async function withdraw(order: string, counter: string): RWithdraw {
  try {
    const response = await api.market.withdrawCounter(order, counter);
    return await api.utils.processResponse(response, {
      // Typeshit. Kidding, I'm the shit one.
      async [200 as 204]() {
        return { success: true };
      },
      default() {
        throw new Error('Unexpected error');
      },
    });
  } catch (error) {
    return { error };
  }
}

type RTrade = Promise<{ success?: string; error?: Error }>;
export async function trade(order: string, counter: string): RTrade {
  try {
    const response = await api.market.tradeCounter(order, counter);
    return await api.utils.processResponse(response, {
      // Typeshit. Kidding, I'm the shit one.
      async 200(response) {
        const data = await response.json();
        return { success: data.message };
      },
      default() {
        throw new Error('Unexpected error');
      },
    });
  } catch (error) {
    return { error };
  }
}

type DRespond = { decline: boolean; reason: string };
type RRespond = Promise<{ success?: true; error?: Error }>;
export async function respond(
  order: string,
  counter: string,
  data: DRespond
): RRespond {
  try {
    const response = await api.market.counterRespond(order, counter, data);
    return await api.utils.processResponse(response, {
      async 200() {
        return { success: true };
      },
      default() {
        throw new Error('Unexpected error');
      },
    });
  } catch (error) {
    return { error };
  }
}
