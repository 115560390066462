import type { Tender, Order } from '~/models/tenders';
import type { FormComponentProps } from 'antd/lib/form';
import React, { useEffect, useState } from 'react';
import { withRouteX, withPreload, routeUrl } from '~/router';
import {
  normalizeChoices,
  mergeSchema,
  MAX_SAFE_INTEGER,
  MIN_SAFE_INTEGER,
} from 'Components/form/utils';
import * as Data from 'Components/Data';
import { FormWrapper } from 'Components/form';
import { Button, Form, Alert, Tooltip } from 'Components/nui';
import { promisify } from 'util';
import { toast } from 'react-toastify';
import {
  capitalize,
  inArrayIf,
  getDecimalCount,
  getExportLabel,
  invertOrderType,
  SolutionColumns,
} from '~/utils';
import { Value } from '~/models/utils';
import R from 'ramda';
import * as service from '~/services/tenders';
import Access from 'Components/Access';
import { useHistory } from 'react-router-dom';
import { ResAlert } from './components';
import { Eta } from './Eta';
import { useStoreState } from '~/store';
import FNumber from 'Components/FNumber';

interface IConfig extends FormComponentProps {
  schema: any;
  tender: Tender;
  order: Order;
}
function useFormWrapper({ form, schema, tender, order }: IConfig) {
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const solution = useStoreState(state => state.auth.solution);

  const mine = order.counters.mine;

  const priceLabel = order.startprice.index?.type.toLowerCase() || 'price';

  useEffect(() => {
    const field: number = form.getFieldValue('price');
    const price = mine?.price.val;
    if (price) {
      if (order.ordertype === 'offer' && price > field)
        form.setFields({ price: { value: price } });
      else if (order.ordertype === 'bid' && field > price)
        form.setFields({ price: { value: price } });
    }
  }, [mine?.price.val]);

  const exportIntent =
    form.getFieldValue('exportable') ?? !!schema?.exportable?.value;

  const exportdocsRequired =
    form.getFieldValue('exportdocs') ?? !!schema?.exportdocs?.value;

  const heatTreatedPalletsRequired =
    form.getFieldValue('heattreatedpallets') ??
    !!schema?.heattreatedpallets?.value;

  const variantId = form.getFieldValue('variant') || mine?.variant?.id;
  const variant = schema.variant?.choices.find((v: any) => v.id === variantId);
  const offset: number | undefined = variant?.minprice;
  const variantPrice: number = variant?.current;

  const autobid = tender.willStart()
    ? true
    : form.getFieldValue('autobid') ?? mine?.isAuto;

  const getChoicesByDivision =
    ({ choices }: any) =>
    () => {
      const divisionId = form.getFieldValue('division');
      return normalizeChoices(
        Array.isArray(choices) ? choices : R.propOr([], divisionId, choices)
      );
    };

  const showVariant = !!variantPrice;
  const showNextprice = order.showNextprice || offset;

  const currentPrice =
    variantPrice ??
    mine?.price.val ??
    order.nextprice.val ??
    order.startprice.val;
  const indexType = order.startprice.index?.type;

  const [floor, ceil] =
    indexType === 'Multiplier'
      ? [order.startprice.step, MAX_SAFE_INTEGER] // I know, it's redundant
      : indexType === 'Modifier'
      ? [MIN_SAFE_INTEGER, MAX_SAFE_INTEGER]
      : [order.startprice.step, MAX_SAFE_INTEGER];

  const variantprices =
    schema.variant?.choices.map((v: any) => v.current || v.minprice) || [];
  const minvariant = Math.min(...variantprices) ?? floor;

  const [minprice, maxprice] =
    order.ordertype === 'offer'
      ? [Math.min(minvariant, currentPrice ?? floor), ceil]
      : [Math.min(minvariant, floor), currentPrice ?? ceil];

  const orderVol = order.stripvolume?.delivered?.val ?? order.volume.val;
  const initialVol = schema.volume?.val ?? orderVol;

  const fields = [
    {
      name: 'division',
      label: 'Division',
      choices: normalizeChoices,
    },
    ...inArrayIf(schema.variant?.choices, {
      render: () => (
        <React.Fragment key="product-variant-hint">
          <h3 className="fs-1 m-0">Product variants</h3>
          <p className="light-gray smaller">
            Product variants are product sub-categories. Select the relevant
            product variant you want to bid on and note the price. The price of
            product variants will differ from price of the base product. Note:
            Your minimum bid amount cannot be lower than the price.
          </p>
        </React.Fragment>
      ),
    }),
    {
      name: 'variant',
      label: 'Select a product variant',
      choices: (variant: any) =>
        variant?.choices?.map(({ id, name }: any) => ({
          value: id,
          label: name,
          key: id,
        })),
    },
    {
      render() {
        return (
          (showVariant || showNextprice) && (
            <React.Fragment key="price-hint">
              <Alert type="info" hasicon className="bid-price-info">
                {showVariant && (
                  <div className="variant-equivalent">
                    Your equivalent current {tender.ordertype.counter} price for
                    this variant is{' '}
                    <Data.PriceTicker
                      value={order.nextprice.new({ val: variantPrice })}
                      title=""
                    />
                  </div>
                )}
                {showNextprice && (
                  <div key="min-bid" className="min-bid">
                    <div className="clm ttl">
                      <strong className="all-black">
                        {tender.ordertype.counter === 'bid'
                          ? 'Minimum'
                          : 'Maximum'}{' '}
                        next {tender.ordertype.counter}
                      </strong>
                    </div>
                    <div className="clm price">
                      <Data.PriceTicker
                        title=""
                        value={order.nextprice.new({
                          val: offset ?? order.nextprice.val,
                        })}
                      />
                    </div>
                  </div>
                )}
              </Alert>
              <hr />
              {mine?.rank === 1 && (
                <Alert className="mb-20" type="success" hasicon>
                  You are leading
                </Alert>
              )}
              <ResAlert reserve={order.reserve} />
            </React.Fragment>
          )
        );
      },
    },

    autobid
      ? {
          label: tender.started() ? (
            `${
              tender.ordertype.counter === 'bid' ? 'Maximum' : 'Minimum'
            } auto-${tender.ordername.counter}`
          ) : (
            <Tooltip
              placement="bottom"
              title={
                <span className="icon-tooltip">
                  <span className="icon-info-circled" />
                  <p className="light-gray smaller">
                    Auto {tender.ordername.counter} keeps you in the lead by
                    automatically placing a{' '}
                    {tender.ordertype.order === 'offer' ? 'higher' : 'lower'}{' '}
                    {tender.ordername.counter} whenever you&apos;re out-ranked.
                    You choose the{' '}
                    {tender.ordertype.order === 'offer' ? 'maximum' : 'minimum'}{' '}
                    amount you are willing to{' '}
                    {tender.ordertype.order === 'offer' ? 'pay' : 'sell for'} -
                    and auto {tender.ordername.counter} does the rest.
                  </p>
                </span>
              }
            >
              <span className="tbd all-black">
                {tender.ordertype.counter === 'bid' ? 'Maximum' : 'Minimum'}{' '}
                auto-{tender.ordername.counter}
              </span>{' '}
              <span className="show-help inline-block" />
            </Tooltip>
          ),
          name: 'autobid_price',
          step: order.startprice.step,
          min: minprice,
          max: maxprice,
          initialValue:
            schema.autobid_price?.value ??
            mine?.autobid?.val ??
            order.nextprice.val,
        }
      : {
          name: 'price',
          label: !tender.started()
            ? `Starting ${priceLabel}`
            : capitalize(priceLabel),
          min: minprice,
          max: maxprice,
          step: order.startprice.step,
          initialValue: order.startprice.val,
        },

    tender.started() && {
      name: 'autobid',
      label: (
        <Tooltip
          placement="bottom"
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              <p className="light-gray smaller">
                {schema?.autobid?.disabled ? (
                  <>
                    Auto {tender.ordername.counter} is only available if the{' '}
                    {tender.ordername.order} has a start price or if another{' '}
                    {tender.ordername.counter} has been placed.
                  </>
                ) : (
                  <>
                    Auto {tender.ordername.counter} keeps you in the lead by
                    automatically placing a{' '}
                    {tender.ordertype.order === 'offer' ? 'higher' : 'lower'}{' '}
                    {tender.ordername.counter} whenever you&apos;re out-ranked.
                    You choose the{' '}
                    {tender.ordertype.order === 'offer' ? 'maximum' : 'minimum'}{' '}
                    amount you are willing to{' '}
                    {tender.ordertype.order === 'offer' ? 'pay' : 'sell for'} -
                    and auto {tender.ordername.counter} does the rest.
                  </>
                )}
              </p>
            </span>
          }
        >
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <span className="tbd all-black">
              Auto-{tender.ordername.counter}
            </span>{' '}
            <span className="show-help inline-block" />
          </div>
        </Tooltip>
      ),
      type: 'Switch',
      props: {
        disabled: schema?.autobid?.disabled,
      },
      initialValue: schema?.autobid?.value ?? false,
    },
    {
      name: 'volume',
      label: order.stripvolume ? 'Volume per delivery' : 'Volume',
      max: orderVol,
      min: order.loading.qty,
      step: order.loading.qty,
      initialValue: initialVol,
      required: true,
      props: { disabled: schema.volume.disabled },
    },

    {
      name: 'exportable',
      label:
        getExportLabel(
          invertOrderType(order.ordertype),
          'exportable'
        )(solutionColumns as SolutionColumns) ?? 'Exportable',
      choices: [
        { key: 'yes', value: true, label: 'Yes' },
        { key: 'no', value: false, label: 'No' },
      ],
      type: 'RadioSelect',
      initialValue: schema?.exportable?.value,
    },

    ...inArrayIf(
      !('exportable' in schema) ||
        (exportIntent && order.ordertype === 'offer'),
      {
        name: 'exportdocs',
        label:
          getExportLabel(
            invertOrderType(order.ordertype),
            'exportdocs'
          )(solutionColumns as SolutionColumns) ?? 'Export docs',
        choices: [
          { key: 'yes', value: true, label: 'Yes' },
          { key: 'no', value: false, label: 'Not required' },
        ],
        type: 'RadioSelect',
        required: true,
        initialValue: schema?.exportdocs?.value,
      }
    ),

    {
      render: () =>
        exportIntent &&
        solution?.exportprice?.flatfee &&
        exportdocsRequired && (
          <Alert
            hasicon
            type="info"
            className="alert-fee"
            key="alert-exportfee"
          >
            Please note a{' '}
            <strong style={{ width: 'auto' }}>
              {solution.exportprice.price} {solution.currency}
            </strong>{' '}
            flat fee will be added to this order.{' '}
            {solution.exportprice.file && (
              <a
                href={solution.exportprice.file.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                View more information here
              </a>
            )}
          </Alert>
        ),
    },

    ...inArrayIf(exportIntent, {
      name: 'heattreatedpallets',
      label: 'Heat-treated pallets required',
      choices: [
        { key: 'yes', value: true, label: 'Yes' },
        { key: 'no', value: false, label: 'Not required' },
      ],
      type: 'RadioSelect',
      required: true,
      initialValue: schema?.heattreatedpallets?.value,
    }),

    {
      render: () =>
        exportIntent &&
        heatTreatedPalletsRequired &&
        form.getFieldValue('volume') > 0 &&
        solution?.exportprice?.heattreatedfee && (
          <Alert
            hasicon
            type="info"
            className="pallets-request"
            key="pallets-request"
          >
            <h3>Heat-treated pallets summary</h3>
            <blockquote>
              <FNumber
                value={parseFloat(solution.exportprice.heattreatedfee)}
                decimalCount={getDecimalCount(
                  parseFloat(solution.exportprice.heattreatedfee)
                )}
                unit={solution.currency}
              />{' '}
              &times;{' '}
              <FNumber
                value={form.getFieldValue('volume')}
                decimalCount={getDecimalCount(form.getFieldValue('volume'))}
              />{' '}
              ={' '}
              <FNumber
                value={
                  parseFloat(solution.exportprice.heattreatedfee) *
                  form.getFieldValue('volume')
                }
                decimalCount={2}
                unit={solution.currency}
              />
            </blockquote>
          </Alert>
        ),
    },

    ...inArrayIf(exportIntent, {
      name: 'exportcountry',
      label: 'Destination',
      choices: schema?.exportcountry?.choices?.map(
        ([value, label]: [string, string]) => ({
          value,
          label,
          key: value,
        })
      ),
      initialValue: schema?.exportcountry?.value,
    }),

    {
      render: () =>
        order.startprice.index || autobid ? (
          <React.Fragment key="value-empty" />
        ) : (
          <div key="value" className="total-counter inline-block">
            <div className="total-counter-value inline">
              <strong className="all-black inline mr-74">
                {capitalize(tender.ordertype.counter + ' value')}
              </strong>
              <span>
                <Data.PriceTicker
                  className="price-ticker inline"
                  title=""
                  value={
                    new Value({
                      product: order.product.id,
                      loading: order.loading,
                      price: order.startprice.new({
                        val: form.getFieldValue('price') || 0,
                      }),
                      volume: order.volume.new({
                        val:
                          form.getFieldValue('volume') *
                            (order.stripvolume?.delivered?.deliveries || 1) ||
                          0,
                      }),
                    })
                  }
                />
              </span>
            </div>
          </div>
        ),
    },

    {
      name: 'buyer',
      label: 'Buyer',
      choices: (buyer: any) =>
        buyer.choices.map(([value, label]: [string, string]) => ({
          value,
          label,
          key: value,
        })),
    },
    {
      name: 'consignee',
      label: 'Consignee',
      choices: (consignee: any) =>
        consignee.choices.map(([value, label]: [string, string]) => ({
          value,
          label,
          key: value,
        })),
    },
    {
      name: 'warehouse',
      label: 'Warehouse',
      props: {
        disabled: schema.division && !form.getFieldValue('division'),
        placeholder:
          schema.division && !form.getFieldValue('division')
            ? 'Please select a division'
            : 'Please select a location',
      },
      choices: getChoicesByDivision,
    },
    {
      name: 'toaddr',
      label: 'Delivery to',
      props: {
        disabled: schema.division && !form.getFieldValue('division'),
        placeholder:
          schema.division && !form.getFieldValue('division')
            ? 'Please select a division'
            : 'Please select a location',
      },
      choices: getChoicesByDivision,
    },
    ...inArrayIf(['port', 'zone'].includes(schema?.toaddr?.freight), {
      render: () => <Eta order={order} form={form} schema={schema} />,
    }),
    {
      name: 'fromaddr',
      label: 'Delivery from',
      props: {
        disabled: schema.division && !form.getFieldValue('division'),
        placeholder:
          schema.division && !form.getFieldValue('division')
            ? 'Please select a division'
            : 'Please select a location',
      },
      choices: getChoicesByDivision,
    },
    {
      name: 'incoterms',
      label: 'Incoterm',
      choices: getChoicesByDivision,
    },
    {
      name: 'callofftime',
      label: 'Call off times',
      type: 'Input',
    },
    !mine && {
      name: 'acceptdocs',
      label: (
        <>
          I accept the{' '}
          {!!tender.files?.length && (
            <Data.Files files={tender.files} title="tender documents" />
          )}
          {!!tender.files?.length && !!order.files?.length && ' and the '}
          {!!order.files?.length && (
            <Data.Files
              files={order.files}
              title={`${order.ordertype} documents`}
            />
          )}
        </>
      ),
    },
    {
      name: 'comment',
      label: 'Comment',
      type: 'String',
    },
  ];

  return new FormWrapper(form, mine?.formdata, mergeSchema(schema, fields));
}

const BaseForm = ({ form, schema, tender, order }: IConfig) => {
  const [loading, setLoading] = useState(false);
  const formWrapper = useFormWrapper({ form, schema, tender, order });
  const mine = order.counters.mine;
  const autobid = tender.willStart()
    ? true
    : form.getFieldValue('autobid') ?? mine?.isAuto;
  const action = mine?.id ? 'update' : 'place';
  const history = useHistory();

  let mounted = true;
  useEffect(() => {
    return () => {
      mounted = false;
    };
  }, []);

  async function handleSubmit(e: any) {
    e.preventDefault();
    setLoading(true);

    try {
      await promisify(form.validateFieldsAndScroll)();
    } catch (err) {
      if (mounted) {
        setLoading(false);
        return;
      }
    }

    const result = await service.actions.counter(tender.id, order.id, {
      ...formWrapper.serialize(),
      id: mine?.id,
    });

    if (result) {
      if (result.success) {
        toast.success(
          `${capitalize(tender.ordertype.counter)} ${action}d successfully`
        );
        history.push(
          routeUrl('tender-counter-orders', {
            tenderId: tender.id,
            orderId: order.id,
          })
        );
      } else {
        if (result?.errors?.length) {
          for (const e of result.errors) toast.error(e);
        }
        if (result?.validate) {
          formWrapper.setErrors(result.validate);
        }
      }
    }

    if (mounted) {
      setLoading(false);
    }
  }

  const onDelete = async () => {
    setLoading(true);

    const result = await service.actions.deleteCounter(
      tender.id,
      order.id,
      mine.id
    );

    if (result) {
      if (result.success) {
        toast.success(
          <>
            Your {!tender.started() && 'auto'} {tender.ordername.counter} has
            been deleted.
          </>
        );
      } else {
        if (result.errors?.length) {
          toast.error(
            <>
              Something went wrong while deleting this{' '}
              {!tender.started() && 'auto'} {tender.ordername.counter}
            </>
          );
        }
      }
    }

    if (mounted) {
      setLoading(false);
    }
  };

  return (
    <Form className="nui-form order-form" onSubmit={handleSubmit}>
      {formWrapper.render()}
      <div className="button-set pt-10">
        <Button
          key="submit"
          htmlType="submit"
          type={tender.ordertype.counter}
          disabled={loading}
          loading={loading}
        >
          {capitalize(action)} {autobid ? 'auto' : ''}{' '}
          {tender.ordertype.counter}
        </Button>
        <Access to="delete" model={mine}>
          <Button
            key="cancel"
            htmlType="button"
            type="reverse"
            disabled={loading}
            loading={loading}
            onClick={onDelete}
          >
            Delete {!tender.started() ? 'auto' : ''} {tender.ordername.counter}
          </Button>
        </Access>
      </div>
    </Form>
  );
};

const Wrapped = Form.create<IConfig>()(BaseForm);

type ICounterForm = { tender: Tender; order: Order };
const CounterForm = withPreload({
  route: 'tender-counter',
  preload: service.schemas.counter(),
})<ICounterForm>(({ data: { schema } = {}, order, tender }) => {
  return <Wrapped schema={schema} tender={tender} order={order} />;
});

const Page = withPreload({
  route: 'tender-counter',
  preload: service.observe.order(),
})(({ data: { tender, order } }) => {
  return <CounterForm tender={tender} order={order} />;
});

export default withRouteX({ name: 'tender-counter' })(Page);
