import React from 'react';
import { useWindowScroll, usePrevious } from 'react-use';
import classNames from 'classnames';

export default () => {
  const { y } = useWindowScroll();
  const prevY = usePrevious(y);

  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      className={classNames('goup', { visible: y > prevY })}
      onClick={onClick}
      title="Scroll to the top of the page"
    >
      <span />
    </button>
  );
};
