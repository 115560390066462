import React from 'react';
import { useStoreState } from '~/store';
import {
  getDecimalSeparator,
  nuiFormatNumber,
  getGroupingSeparator,
  getDecimalCount,
} from '~/utils';

interface Config {
  value: number;
  decimalCount?: number;
  unit?: string;
}
export default function FNumber({ value, decimalCount, unit }: Config) {
  const { numeric_decimal, numeric_group } = useStoreState(
    state => state.auth.userModel
  );

  const formatted = nuiFormatNumber(
    value,
    decimalCount === undefined ? getDecimalCount(value) : decimalCount,
    numeric_decimal || getDecimalSeparator(),
    numeric_group || getGroupingSeparator()
  );

  return <>{formatted + (unit ? ' ' + unit : '')}</>;
}

interface INonZero extends Config {
  fallback: React.ReactNode;
}
export function FNumberNonZero({ fallback, ...config }: INonZero) {
  if (config.value <= 0) return <>{fallback}</>;
  return <FNumber {...config} />;
}
