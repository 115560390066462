import { useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { api } from '~/api';

export default () => {
  const [data, setData] = useState();
  const solution = useStoreState(state => state.auth.solution);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const newData = await api.analytics('trade', [
          solution.id,
          '2022-02-22',
          '2022-03-08',
        ]);
        if (mounted) {
          console.log(newData);
          setData(newData);
        }
      } catch (error) {
        // pass
      }
    })();

    return () => {
      mounted = false;
    };
  }, [solution]);

  return <pre>{JSON.stringify(data, null, 4)}</pre>;
};
