import type { Store, EasyPeasyConfig, State } from 'easy-peasy';

type StoreType<S extends {} = {}> = Store<S, EasyPeasyConfig<{}, any>>;
type Selector<T, S extends {}> = (state: State<S>) => T;
type OnChange<T> = (data: T) => void;
type Test<S extends {}> = (state: State<S>) => any;

export function createObservable<S extends {}>(store: StoreType<S>) {
  return <P, T>(observer: (params?: P) => [Selector<T, S>, Test<S>]) => {
    return (onChange: OnChange<T>) => {
      let unsubscribe: () => void;
      let current: any;

      return (params?: P) => {
        if (unsubscribe) unsubscribe();
        const [select, test] = observer(params);

        function handle() {
          const state = store.getState();
          const next = test(state);
          if (next !== current) {
            current = next;
            onChange(select(state));
          }
        }

        unsubscribe = store.subscribe(handle);
        handle();
        return unsubscribe;
      };
    };
  };
}

type Loader<T, P> = (onChange: OnChange<T>, params?: Partial<P>) => unknown;
export function loadable<T, P>(loader: Loader<T, P>) {
  return function (onChange: OnChange<T>) {
    return function (params?: Partial<P>) {
      loader(onChange, params);

      return () => {};
    };
  };
}
