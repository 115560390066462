import moment, { Moment, MomentInput, Duration } from 'moment-timezone';

export type PastPresentFuture = 'past' | 'present' | 'future';

export class TimeSpan {
  from: Moment;
  to: Moment;
  constructor(from: MomentInput, to: MomentInput) {
    this.from = moment(from);
    this.to = moment(to);
  }
  static relative(fromDelta: Duration, toDelta: Duration, now = moment()) {
    return new TimeSpan(now.add(fromDelta), now.add(toDelta));
  }
  isPast(now: Moment = moment()) {
    return now.isAfter(this.to);
  }
  isPresent(now: Moment = moment()) {
    return now.isAfter(this.from) && now.isBefore(this.to);
  }
  isFuture(now: Moment = moment()) {
    return now.isBefore(this.from);
  }
  isValid() {
    return this.from.isSameOrBefore(this.to);
  }
  pastPresentFuture(now: Moment = moment()): PastPresentFuture {
    if (this.isPast(now)) return 'past';
    if (this.isFuture(now)) return 'future';
    return 'present';
  }
}
