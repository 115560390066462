import type { Tender } from '~/models/tenders';
import React from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import Orders from './Orders';
import {
  TenderOwned,
  TenderInfo,
  TenderStatus,
  TenderActions,
  TenderCountdown,
  TenderActivity,
} from './components';
import Trades from './Trades';
import {
  routeUrl,
  matchMany,
  RoutingTable,
  withPreload,
  usePathTools,
  withRouteX,
} from '~/router';
import { PermissionDenied } from 'Components/InfoMessage';
import classnames from 'classnames';
import * as service from '~/services/tenders';
import { useStoreState } from '~/store';
import RankingTotals from './RankingTotals';
import Report from './Report';

type TenderProp = Record<'tender', Tender>;
type ChildrenProp = Partial<Record<'children', React.ReactNode>>;
type ClassProp = Partial<Record<'className', string>>;

export const View = withPreload({
  route: 'tender-view',
  preload: service.observe.tender(),
})(({ data: tender }) => {
  const { matchParams } = usePathTools();
  const params = matchParams('tender-view');

  if (['stopped', 'cancelled'].includes(tender.status))
    return (
      <PermissionDenied className="mt--1 mb--1 p-100">
        This tender has been cancelled.
        <br />
        <Link to={routeUrl('tender-overview')}>Return to overview</Link>
      </PermissionDenied>
    );

  return (
    <TenderItem tender={tender} className="viewing">
      <RoutingTable
        routes={[Orders, Trades, Report]}
        redirect={routeUrl('tender-orders', params)}
      />
    </TenderItem>
  );
});

export default withRouteX({ name: 'tender-view' })(View);

type ITenderItem = TenderProp & ChildrenProp & ClassProp;
export const TenderItem = ({ tender, className, children }: ITenderItem) => (
  <div className={classnames('tender-item', className)}>
    <div className="tender-item-content">
      <TenderHeader tender={tender} />
      {children}
    </div>
  </div>
);

export const TenderHeader = ({ tender }: TenderProp) => {
  const tenderlogic = useStoreState(state => state.auth?.solution?.tenderlogic);
  const showActivity =
    tender.owned && tenderlogic === 'classic' && tender.isRunning();

  return (
    <>
      <div className="sticky-counter">
        <div className="tender2-status">
          <TenderCountdown tender={tender} />
          <TenderStatus tender={tender} />
          <RankingTotals tender={tender} />
        </div>
      </div>
      <div className="tender-item-header">
        <h2 className="tender-name">
          <TenderOwned tender={tender} />
          <span
            className={classnames(
              `type-${tender.type}`,
              'mr-10',
              'inline',
              'tender-overview-page'
            )}
          >
            {tender.type}
          </span>
          <Link
            to={routeUrl('tender-view', { tenderId: tender.id })}
            title={`View ${tender.name} details`}
          >
            {tender.name}
          </Link>
        </h2>
        <div className="tender2-status">
          <TenderCountdown tender={tender} />
          <TenderStatus tender={tender} />
        </div>
        <div className="tender2-details">
          <div className="tender2-action-box">
            <Switch>
              <Route
                exact
                path={matchMany(
                  'tender-overview',
                  'tender-history',
                  'tender-trades'
                )}
              >
                <Link
                  to={routeUrl('tender-view', { tenderId: tender.id })}
                  className="nui-button nui-primary nui-small pb-7 pt-7 inline-block"
                >
                  View <span className="show-for-small">tender</span>
                </Link>
              </Route>
              <Route>{showActivity && <TenderActivity />}</Route>
            </Switch>
            <TenderActions tender={tender} />
          </div>
          <TenderInfo tender={tender} />
        </div>
      </div>
    </>
  );
};
