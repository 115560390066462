import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import R from 'ramda';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from 'Components/nui';

export default ({ product, colSpan }) => {
  const mip = useStoreState(state => state.marketplace.mip);
  const toggleMip = useStoreActions(R.path(['marketplace', 'toggleMip']));

  return (
    <tr className="xsmall">
      <td
        colSpan={colSpan}
        className={classNames({
          open: mip.includes(product.p_id),
        })}
      >
        <div className="m-product">
          <button
            className={classNames('toggle', {
              open: mip.includes(product.p_id),
            })}
            onClick={() => toggleMip(product.p_id)}
            title="Show/hide table data"
          />
          <div className="product-info">
            <h2>
              <Link
                to={`/products/${product.id}/quick`}
                className="product-name"
              >
                <Tooltip
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled"></span>View product
                      details in new window
                    </span>
                  }
                >
                  {product.name}
                  <span className="nowrap">
                    &#65279;<span className="icon-link-ext-alt"></span>
                  </span>
                </Tooltip>
              </Link>
            </h2>
            {!mip.includes(product.p_id) && (
              <>
                {R.hasPath(['owner', 'name'], product) && (
                  <p className="division">{product.owner.name}</p>
                )}

                <p className="unit">{product._desc}</p>
              </>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
