import React, { useState, useEffect } from 'react';
import { Alert, Popover, Checkbox, Button } from 'Components/nui';
import { api } from '~/api';
import { useStoreState, useStoreActions } from 'easy-peasy';
import R from 'ramda';
import Tabs, { TabPane } from 'Components/nui/Tabs';
import { toast } from 'react-toastify';
import { Loading } from 'Components/nui';
import { SelectWidget } from 'Components/form/widgets';
import { useTimezone } from '~/timezone';
import moment from 'moment-timezone';
import Tooltip from 'Components/nui/Tooltip';
import classNames from 'classnames';

const ChannelsFieldItem = ({ code, name, checked, disabled }) => {
  const [on, setOn] = useState(!!checked);
  const patch = useStoreActions(actions => actions.notif.patch);

  const onChange = () => {
    setOn(!on);
    patch({ channels: { [code]: !on } });
  };

  return (
    <Checkbox checked={on} onChange={onChange} disabled={!!disabled}>
      {name}
    </Checkbox>
  );
};

const Channels = () => {
  const { channels } = useStoreState(state => state.notif.prefs);

  return (
    <Alert type="info" className="pt-20 mb-20">
      <ChannelsFieldItem
        code="email"
        name="Receive email notifications"
        checked={channels.email}
      />
    </Alert>
  );

  // return (
  //   <>
  //     <h3>Notification channels</h3>
  //     <p>Select how you would like to receive notifications.</p>

  //     <Alert type="info" className="mb-20">
  //       <h4 className="mb-10">Select channel</h4>
  //       <div className="column-count-3">
  //         <ChannelsFieldItem
  //           code="platform"
  //           name="Platform"
  //           checked={channels.platform}
  //           disabled={true}
  //         />
  //         <ChannelsFieldItem
  //           code="email"
  //           name="Email"
  //           checked={channels.email}
  //         />
  //         <ChannelsFieldItem
  //           code="sms"
  //           name="SMS"
  //           checked={channels.sms}
  //           disabled={true}
  //         />
  //       </div>
  //     </Alert>
  //   </>
  // );
};

const Fieldset = ({ children }) => (
  <fieldset className="notifications-fieldset">
    <legend className="offset">
      Select which notifications you would like to receive
    </legend>
    <div className="no-ws">{children}</div>
  </fieldset>
);

const SolFieldItem = ({ keyname, label }) => {
  const solutionId = useStoreState(state => state.notif.solutionId);
  const prefs = useStoreState(state => state.notif.prefs);
  const newSolPrefs = R.clone(prefs.solutions);
  const disabled = R.pathOr({}, ['solutions', solutionId, 'disabled'], prefs);

  const patch = useStoreActions(actions => actions.notif.patch);
  const update = useStoreActions(actions => actions.notif.update);

  const checked = !(keyname in disabled) || disabled[keyname].length === 0;

  const onChange = () => {
    const data = {
      solutions: {
        [solutionId]: {
          disabled: {
            [keyname]: checked ? ['sms', 'email', 'platform'] : [],
          },
        },
      },
    };

    newSolPrefs[solutionId].disabled[keyname] = checked
      ? ['sms', 'email', 'platform']
      : [];
    // just for a better UI
    toast.success('Notification settings saved');
    update({
      solutions: newSolPrefs,
    });
    patch(data);
  };

  return (
    <Checkbox checked={checked} onChange={onChange} className="m-0">
      {label}
    </Checkbox>
  );
};

const SolDeepness = ({ name, close }) => {
  const solutions = useStoreState(state => state.auth.solutions);

  return (
    <div className="pop-panel">
      {solutions.map(sol => (
        <div key={sol.id} className="mb-10">
          <SolFieldItem solutionId={sol.id} keyname={name} label={sol.desc} />
        </div>
      ))}
      <hr className="mt-5 mb-5" />
      {/* <Button type="buttonlink" className="closeme">
        <span>Advanced settings</span>
      </Button>
      <span className="ml-5 mr-5">|</span> */}
      <Button type="buttonlink" className="closeme" onClick={close}>
        <span>Close</span>
      </Button>
    </div>
  );
};

const FieldItem = props => {
  const [name, params] = props.data;

  return (
    <div className="notification-set">
      <Popover placement="topRight" overlay={<span>{params.help}</span>}>
        <span className="popover-link">?</span>
      </Popover>
      <SolFieldItem keyname={name} label={params.label} />
    </div>
  );
};

const GroupSection = ({ name, items }) => {
  const solutionKeys = useStoreState(state => state.notif.solutionKeys);
  const filtered = R.filter(n => solutionKeys.includes(n[0]), items);

  if (filtered.length === 0) return null;

  return (
    <>
      <h4>{name}</h4>
      <Fieldset className="notifications-fieldset">
        {filtered.map(notif => (
          <FieldItem key={notif[0]} data={notif} />
        ))}
      </Fieldset>
    </>
  );
};

const GroupsSection = () => {
  const prefs = useStoreState(state => state.notif.prefs);

  return (
    <div>
      <div className="pb-200">
        {prefs.schema.map(group => (
          <GroupSection key={group[0]} name={group[0]} items={group[1]} />
        ))}
      </div>
    </div>
  );
};

const ProductsSection = () => {
  const solutionId = useStoreState(state => state.notif.solutionId);
  const prefs = useStoreState(state => state.notif.productsPrefs);
  const products = useStoreState(state =>
    state.auth.allSolutionProducts(solutionId)
  );
  const update = useStoreActions(actions => actions.notif.updateProductsPrefs);
  const patch = useStoreActions(actions => actions.notif.patchProductPrefs);

  const selected = R.pathOr([], [solutionId], prefs);
  const onChange = id => {
    const newData = selected.includes(id)
      ? R.without([id], selected)
      : R.append(id, selected);

    // UI
    update({
      [solutionId]: newData,
    });
    patch({ products: newData, solution: solutionId });
  };

  if (!solutionId) return null;

  if (!(solutionId in prefs)) return <Loading size="medium" />;

  return (
    <>
      <Alert hasicon size="small" type="help" className="mt--10 mb-20">
        <span className="smaller">
          All marketplace notifications will be connected with the notification
          selections you make on a product level. Example, if you have selected
          to receive notifications for new orders you will only receive them for
          the products you have selected.
        </span>
      </Alert>
      {products.map(p => (
        <Checkbox
          key={p.pid}
          checked={selected.includes(p.pid)}
          onChange={() => onChange(p.pid)}
        >
          {p.name}
        </Checkbox>
      ))}
    </>
  );
};

const TimezoneSelector = () => {
  const timezone = useTimezone();
  const [loading, setLoading] = useState(false);

  const handleChange = async value => {
    setLoading(true);
    const { success } = await timezone.change(value);
    setLoading(false);
    if (success)
      toast.success(
        `Your email notification timezone updated successfully to ${value}`
      );
    else toast.error('Could not update timezone.');
  };

  return loading || timezone.options.length === 0 ? (
    <Loading />
  ) : (
    <>
      <TimezoneAlert />
      <p>Select a timezone for email notifications</p>
      <SelectWidget
        choices={timezone.options}
        props={{ onChange: handleChange, value: timezone.current }}
      />
    </>
  );
};

const TimezoneAlert = () => {
  const [loading, setLoading] = useState(false);
  const timezone = useTimezone();
  const storeTimeZone = useStoreState(state => state.auth.user.timezone);
  const deviceTimeZone = moment.tz.guess();
  const handleClick = async () => {
    setLoading(true);
    const { success } = await timezone.change(deviceTimeZone);

    setLoading(false);

    if (success) {
      toast.success(
        `Your email notification timezone successfully updated to your device timezone (${deviceTimeZone})`
      );
    } else toast.error('Could not update timezone.');
  };
  if (storeTimeZone === deviceTimeZone) return <></>;
  return (
    <Alert type="warning" className="pt-20 mb-20 timezone-alert">
      <span className="">
        <span className="block">
          Your email notification timezone ({storeTimeZone}) is different from
          your device time zone ({deviceTimeZone}).
        </span>
        {loading ? (
          <Loading size="small" />
        ) : (
          <Button type="buttonlink" onClick={handleClick}>
            Update to {deviceTimeZone}
          </Button>
        )}
      </span>
    </Alert>
  );
};

const SolutionSelector = () => {
  const solutions = useStoreState(state => state.auth.solutions);
  const solutionId = useStoreState(state => state.notif.solutionId);
  const setSolutionId = useStoreActions(actions => actions.notif.setSolutionId);

  return (
    <div className="mb-20 mt-20">
      <h3>Notification settings</h3>

      {solutions.length === 1 ? (
        <p className="mb--20 p-0">
          Select which notification types you would like to receive.
        </p>
      ) : (
        <>
          <p>Select which notification types you would like to receive for:</p>
          <div className="nui-fieldset">
            <label htmlFor="select-solution" className="offset">
              Edit settings for solution
            </label>
            <div className="select-holder">
              <select
                id="select-solution"
                className=""
                onChange={e => setSolutionId(e.target.value)}
                value={solutionId}
              >
                {solutions.map(sol => (
                  <option key={sol.id} value={sol.id}>
                    {sol.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default () => {
  const [loading, setLoading] = useState(true);

  const update = useStoreActions(actions => actions.notif.update);
  const setSolutionId = useStoreActions(actions => actions.notif.setSolutionId);

  const solution = useStoreState(state => state.auth.solution);

  useEffect(() => {
    if (solution) {
      setSolutionId(solution.id);
    }
  }, [solution, setSolutionId]);

  useEffect(() => {
    const fetch = async () => {
      const response = await api.getData({
        type: 'user',
        id: 'notification-prefs',
        getResult: x => x,
      });

      if (response.schema) update(response);
      setLoading(false);
    };

    fetch();
  }, [update, setLoading]);

  if (loading) return <Loading size="medium" />;

  return (
    <>
      <Channels />
      <TimezoneSelector />
      <SolutionSelector />

      <Tabs animated={false} defaultActiveKey="types">
        <TabPane tab="Notification types" key="types">
          <GroupsSection />
        </TabPane>
        <TabPane tab="Product notifications" key="products">
          <ProductsSection />
        </TabPane>
      </Tabs>
    </>
  );
};
