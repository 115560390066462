import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Trades from './Trades';
import View from './View';

export default () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/:id`}>
        <View />
      </Route>
      <Route>
        <Trades />
      </Route>
    </Switch>
  );
};
