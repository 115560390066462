// Some toolbar reference values to save looking in git or docs:
// { key: 'font-style', def: ['bold', 'italic'] },
// { key: 'align', def: [{ align: [] }] },
// { key: 'list', def: [{ list: 'ordered' }, { list: 'bullet' }] },
// { key: 'indent', def: [{ indent: '-1' }, { indent: '+1' }] },
// { key: 'color', def: [{ color: [] }, { background: [] }] },
// { key: 'script', def: [{ script: 'sub' }, { script: 'super' }] },
// { key: 'rtl', def: [{ direction: 'rtl' }] },
// { key: 'link-clean', def: ['link', 'clean'] },

export const TOOLBAR = [
  { key: 'font-style', def: ['bold', 'italic'] },
  { key: 'clean', def: ['clean'] },
] as const;

export type Toolbar = typeof TOOLBAR;
export type ToolbarKey = Toolbar[number]['key'];
export type ToolbarDef = Toolbar[number]['def'];
