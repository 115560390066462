import React, { useEffect, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useInterval, useToggle } from 'react-use';
import { Button, Modal } from 'Components/nui';

export default ({ on, toggle }) => {
  const signOut = useStoreActions(actions => actions.auth.signOut);

  const [seconds, setSeconds] = useState(10);

  const cancel = () => {
    setSeconds(10);
    toggle(false);
  };

  useInterval(
    () => {
      setSeconds(seconds - 1);
    },
    on ? 1000 : null
  );

  useEffect(() => {
    if (seconds < 0) {
      signOut();
    }
  }, [seconds, signOut]);

  if (!on) {
    return null;
  }

  return (
    <Modal close={cancel} size="small">
      <h2>Signing out</h2>
      <p>Signing out in {seconds} seconds...</p>
      <hr />
      <Button onClick={signOut} type="primary" className="mr-10">
        Sign me out now
      </Button>

      <Button onClick={cancel} type="simple">
        Cancel
      </Button>
    </Modal>
  );
};
