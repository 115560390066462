import React from 'react';
import classnames from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';

interface INavList {
  className?: string;
  children?: React.ReactNode;
}
const NavList = ({ className, ...props }: INavList) => {
  return <ul className={classnames('nav-list', className)} {...props} />;
};

interface INavTab {
  className?: string;
  url: string;
  path: string | string[];
  exact?: boolean;
  children?: React.ReactNode;
}
export const NavTab = ({
  url,
  path,
  exact = false,
  children,
  className,
  ...props
}: INavTab) => {
  const match = useRouteMatch({ path, exact });
  const active = !!match;
  const current = active;

  return (
    <li
      className={classnames('nav-list-tab', className, { active })}
      {...props}
    >
      <span>
        <Link to={url} className={classnames({ current })}>
          {children}
        </Link>
      </span>
    </li>
  );
};

NavList.Tab = NavTab;
export default NavList;
