import React, { useContext, useState, useEffect } from 'react';
import * as api from '~/fetch';
import R from 'ramda';
import { toast } from 'react-toastify';

const Context = React.createContext<any>({});

export const Provider = Context.Provider;

export function usePageState() {
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    let mounted = true;
    const loadUserBySolution = async () => {
      try {
        const response = await api.users.getUsers();
        await api.utils.processResponse(response, {
          async 200(response) {
            //Todo: add types
            const data: any = await response.json();

            if (mounted) {
              setUsers(data.users);
            }
          },
          async default(response) {
            toast.error(
              <>
                An unknown error occured, please refresh the page or try again
              </>
            );
          },
        });
      } catch (error) {
        toast.error(
          <>An unknown error occured, please refresh the page or try again</>
        );
      }
    };

    loadUserBySolution();
    return () => {
      mounted = false;
    };
  }, []);

  function getUser(id: string) {
    return users.find(user => user?.id === id);
  }

  function setUser(id: string | undefined, data: any) {
    setUsers((users: any) => {
      const user = users.find((element: any) => element?.id === id);
      const otherUsers = users.filter((user: any) => user.id !== id);

      const mergedUser = R.mergeAll([user, data]);

      users = [...otherUsers, mergedUser];

      return users;
    });
  }

  function deleteUser(userId: string) {
    setUsers((users: any) => {
      users = users.filter((user: any) => user.id !== userId);
      return users;
    });
  }

  return { getUser, setUser, users, setUsers, deleteUser };
}

export function useAllUsers() {
  const context = useContext(Context);

  return context.users;
}

export function useUser(userId: string | undefined) {
  const { getUser, setUser, deleteUser } = useContext(Context);
  const user = getUser(userId);
  return { user, setUser, deleteUser };
}

export function useRoles(userId: string) {
  const { getUser, setUsers } = useContext(Context);
  const user = getUser(userId);

  function getRole(roleId: string) {
    return user?.roles.find((role: any) => role?.id === roleId);
  }

  function setRole(roleId: string | undefined, data: any) {
    setUsers((users: any) => {
      const user = users.find((element: any) => element?.id === userId);
      const otherUsers = users.filter((user: any) => user.id !== userId);
      const otherRoles = user.roles.filter((role: any) => role.id !== roleId);
      users = [
        ...otherUsers,
        {
          ...user,
          roles: [...otherRoles, data].sort((a, b) =>
            a.division.name.localeCompare(b.division.name)
          ),
        },
      ];

      return users;
    });
  }

  function deleteRole(roleId: string, data: any) {
    setUsers((users: any) => {
      const user = users.find((element: any) => element?.id === userId);
      const otherUsers = users.filter((user: any) => user.id !== userId);
      const otherRoles = user.roles.filter((role: any) => role.id !== roleId);
      users = [
        ...otherUsers,
        {
          ...user,
          __acl__: data.__acl__,
          roles: [...otherRoles].sort((a, b) =>
            a.division.name.localeCompare(b.division.name)
          ),
        },
      ];
      return users;
    });
  }

  return { getRole, setRole, deleteRole, roles: user.roles };
}
