import React, { useState, useEffect } from 'react';
import { Popover, Input } from 'antd';
import R from 'ramda';
import { useStoreActions } from 'easy-peasy';
import { Button, Loading } from 'Components/nui';
import { toast } from 'react-toastify';
import { useStoreState } from '~/store';
import { api } from '~/api';

const PopoverContent = ({ product, orderType, formWrapper, setVisible }) => {
  const presets = useStoreState(state => state.auth.presets);
  const solution = useStoreState(state => state.auth.solution);
  const loadPresets = useStoreActions(R.path(['auth', 'loadPresets']));
  const updatePresets = useStoreActions(R.path(['auth', 'updatePresets']));

  useEffect(() => {
    if (presets === null) {
      loadPresets(solution.id);
    }
  }, [presets, solution, loadPresets]);

  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const save = async () => {
    if (!name) return;

    setLoading(true);

    const data = {
      name,
      product: product.id,
      sell: orderType === 'offer',
      ...formWrapper.serialize(),
    };

    try {
      await api.postData('/presets', data);
      toast.success(`Preset "${name}" is saved`);
      setVisible(false);
      setName('');
      updatePresets(null);
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );

      if (errors) {
        formWrapper.setErrors(errors);
        window.scrollTo(0, 0);
      } else {
        toast('An error occurred. Please try again later.', { type: 'error' });
      }
      if (err) {
        console.error(err);
      }
    }

    setLoading(false);
  };

  if (presets === null) return <Loading size="small" />;
  if (presets.length >= 20)
    return (
      <div className="preset-name">
        You have reached the limit of 20 presets.
        <br />
        To be able to save new presets, please remove unnecessary
        <br />
        from the list of the existing presets.
      </div>
    );

  return (
    <div className="preset-name">
      <Input
        placeholder="Name"
        style={{ width: 250 }}
        value={name}
        onChange={e => setName(e.target.value)}
        onPressEnter={save}
      />{' '}
      <Button
        htmlType="button"
        onClick={save}
        loading={loading}
        disabled={!name}
      >
        Save
      </Button>
    </div>
  );
};

export function PresetButton({ product, orderType, formWrapper }) {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={
        <PopoverContent
          product={product}
          orderType={orderType}
          formWrapper={formWrapper}
          setVisible={setVisible}
        />
      }
      title="Preset name"
      trigger="click"
      visible={visible}
      onVisibleChange={v => setVisible(v)}
    >
      <Button htmlType="button" type="reverse">
        Save as preset
      </Button>
    </Popover>
  );
}
