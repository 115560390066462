import React from 'react';
import qs from 'qs';
import R from 'ramda';

export const nl2p = text => {
  if (!text) return '';
  return text.split('\n').map((item, key) => (
    // eslint-disable-next-line react/no-array-index-key
    <p key={key}>{item}</p>
  ));
};

export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getEnv(env, check = x => x !== undefined) {
  if (!check(env)) throw new Error(`env missing`);
  return env ? env : '';
}

export function getHost() {
  const { host, protocol } = R.prop('location', window);
  return `${protocol}//${host}`;
}

export function getPrecision(v) {
  const valueString = String(v);
  return valueString.indexOf('.') >= 0
    ? valueString.length - valueString.indexOf('.') - 1
    : 0;
}

export function formatNumber(n, step, options) {
  try {
    const { currency, locale } = options || {};
    const precision = getPrecision(step);
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
      currency,
      style: currency ? 'currency' : 'decimal',
      ...options,
    }).format(n);
  } catch {
    return String(n);
  }
}

export function parseSearch(locationSearch) {
  return locationSearch ? qs.parse(locationSearch.slice(1)) : {};
}

export const inArrayIf = (predicate, value) => (predicate ? [value] : []);

export const invoke = (fn, ...args) => fn(...args);

export const specConst = {
  10000: {
    className: 'specs',
    name: 'Specifications',
    anchorText: 'specifications',
    symbol: 'S',
  },
  10001: {
    className: 'halal',
    name: 'Halal docs',
    anchorText: 'halal',
    symbol: 'H',
  },
  10002: {
    className: 'kosher',
    name: 'Kosher docs',
    anchorText: 'kosher',
    symbol: 'K',
  },
  10003: {
    className: 'organic',
    name: 'Organic docs',
    anchorText: 'organic',
    symbol: 'O',
  },
  10004: {
    className: 'other',
    name: 'Other docs',
    anchorText: 'other',
    symbol: 'O',
  },
};

export const prepareInitialDataFromSchema = schema => {
  let initialData = {};
  Object.keys(schema).forEach(key => {
    initialData[key] = schema[key]?.value;
  });

  initialData['etd'] = [initialData['frometd'], initialData['toetd']];

  if (initialData['quarters']) {
    initialData['etd'] = '';
  }

  if (initialData['age'] === 'fresh') {
    initialData['productiondate'] = '';
  }

  if (schema.attributes) {
    delete initialData['attributes'];
    const sep = schema.attributes.separator;

    Object.entries(schema.attributes.fields).forEach(([name, spec]) => {
      initialData[['attributes', name].join(sep)] = spec.value;
    });
  }

  return initialData;
};

export const prepareInitialData = (order, schema) => {
  let init = {};

  Object.keys(schema).forEach(key => {
    switch (key) {
      case 'reference':
      case 'expiry':
      case 'productiondate':
      case 'callofftime':
        if (key in order) init[key] = order[key];
        break;

      case 'comment':
        if ('note' in order) init['comment'] = order['note'];
        break;

      case 'splittable':
      case 'exportable':
      case 'freightavailable':
      case 'exportdocs':
        if (key in order) init[key] = order[key] ? 'true' : 'false';
        break;

      case 'gfsi':
      case 'export':
      case 'halal':
      case 'kosher':
        if (order.docs && order.docs[key])
          init[key] = order.docs[key] === 'yes' ? 'true' : 'false';
        break;

      case 'fromaddr':
      case 'toaddr':
        if (key in order && order[key] !== null)
          init[key] =
            order[key].length === 1
              ? order[key][0].id
              : order[key].map(item => item.id);
        break;

      case 'counterparty':
        if ('counterparties' in order) {
          let counterparties = order.counterparties.map(R.prop('id'));
          if (order.counterparties.length === 1)
            [counterparties] = counterparties;
          init[key] = counterparties;
        }
        break;

      case 'brands':
        if ('brand' in order) init[key] = order['brand'].map(item => item.id);
        break;

      case 'locations':
      case 'origins':
        if (order?.[key]?.length) {
          const selectType = R.path([key, 'type'], schema);
          if (selectType === 'Select') {
            init[key] = order[key][0].id;
          } else if (selectType === 'MultipleSelect') {
            init[key] = order[key].map(item => item.id);
          }
        }
        break;

      case 'size':
      case 'group':
        if (key in order && order[key] !== null && order[key].length > 0)
          init[key] = order[key][0].id;
        break;

      case 'destinations':
        if (key in order && order[key] !== null && order[key].length > 0)
          init[key] = order[key].map(x => x.id);
        init['deorco'] = init[key] ? 'destinations' : 'counterparty';
        break;

      case 'incoterms':
        if (key in order && order[key] !== null && order[key].length > 0)
          init[key] = order[key][0];
        break;

      case 'loading':
      case 'age':
        if (key in order && order[key].id) init[key] = order[key].id;
        break;

      case 'buyer':
      case 'consignee':
        if (
          'partners' in order &&
          key in order['partners'] &&
          order['partners'][key].id
        )
          init[key] = order['partners'][key].id;
        break;

      case 'volume':
        if (key in order) init[key] = order[key].pending;
        break;

      case 'price':
        if (key in order) init[key] = order[key].val;
        break;

      case 'privatebid':
        if ('isprivate' in order) init[key] = order['isprivate'];
        break;

      case 'frometd':
        if (!order.quarteretd && order.etd)
          init['etd'] = [order.etd.from, order.etd.to];
        break;

      case 'quarters':
        if (order.quarteretd) {
          if ('etd' in init) delete init['etd'];
          init[key] = order.quarteretd;
        }
        break;

      default:
        break;
    }
  });

  const currencies = R.path(['price', 'currencies'], order);
  if (currencies && currencies.length > 0) {
    const otherCurrencies = Object.keys(schema)
      .filter(key => R.startsWith('price_', key))
      .map(item => item.split('_')[1]);

    otherCurrencies.forEach(cur => {
      const rec = R.find(R.propEq('currency', cur))(currencies);
      if (rec) {
        init[`price_${cur}`] = rec.val;
      }
    });
  }

  if (!('expiry' in init)) {
    init['expiry'] = '';
  }

  return init;
};

const getSeparator = (locale, separatorType) => {
  const part = Intl.NumberFormat(locale)
    .formatToParts(1000000.1)
    .find(part => part.type === separatorType);

  if (part) return part.value;

  return separatorType === 'decimal' ? '.' : '';
};

export const getDecimalCount = value => {
  return getPrecision(value);
};

export const getDecimalSeparator = locale => {
  return getSeparator(locale, 'decimal');
};

export const getGroupingSeparator = locale => {
  return getSeparator(locale, 'group');
};

export const nuiFormatNumber = (
  number,
  decimalCount = 2,
  decimalSeparator = '.',
  groupingSeparator = ','
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = number < 0 ? '-' : '';

    let i = parseInt(
      (number = Math.abs(Number(number) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + groupingSeparator : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + groupingSeparator) +
      (decimalCount
        ? decimalSeparator +
          Math.abs(number - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
    return number;
  }
};

/** This helper is used to get the correct label based on solutionColumns.
 * It returns a functor that calls solutionColumns
 * One example of calling this function is:
 * const label = getExportLabel(order.type, 'exportable')(solutionColumns)
 * The priority to determine the label is:
 * solutionColumns.buy/sell.fieldname.label > solutionColumns.fieldname.label > default
 */

export const invertOrderType = orderType =>
  R.prop(orderType, { bid: 'offer', offer: 'bid' });

export const orderToActionType = ot =>
  R.prop(ot, { offer: 'sell', bid: 'buy' });

export const getExportLabel = (orderType, fieldname) => {
  const label = f => R.compose(R.prop('label'), f);
  return R.apply(
    R.either,
    R.map(label, [
      R.path([fieldname, orderToActionType(orderType)]),
      R.prop(fieldname),
    ])
  );
};

export const canEditCustomer = customer => state => {
  const canManageDivisions = state.auth.canManageDivisions;
  const sellerIdArray = customer?.links?.map(link => link?.seller?.id);
  const canManageDivisionsId = canManageDivisions.map(division => division?.id);

  return canManageDivisionsId.some(canManageDivisionId =>
    sellerIdArray?.includes(canManageDivisionId)
  );
};

export const getAddrChoice = division => choices =>
  division ? choices[division] : choices;

export const getFreightIds = choices =>
  choices.map(choice => {
    const [, , freightInfo] = choice;
    return freightInfo?.zone || freightInfo?.port;
  });

export const getAddrInfo = addrId => choices =>
  R.find(R.propEq(0, addrId))(choices);

export const routesIncluded = (fromaddrFreightIds, toaddrFreightIds) => route =>
  R.all(
    R.apply(R.includes),
    R.zip(
      [route.source.id, route.destination.id],
      [fromaddrFreightIds, toaddrFreightIds]
    )
  );

export const unitsOfMeasure = {
  'kg': 'Kilogram',
  'kL': 'Kilolitre',
  'L': 'Litre',
  'lb': 'Pound',
  'lt': 'Long Ton',
  'm³': 'Cubic Metre',
  'st': 'Short Ton',
  't': 'Tonne',
};

export const subcurrencies = {
  AUD: 'Cents',
  BRL: 'Centavo',
  EUR: 'Cents',
  GBP: 'Pence',
  NZD: 'Cents',
  USD: 'Cents',
};

/* file class names: 
  icon-file-pdf (PDF)
  icon-file-excel (XLS, XLSX)
  icon-file-image (IMG)
  icon-doc (DOC, DOCX)
  icon-doc-text (CSV)
*/

export const mime = {
  'default': {
    className: 'icon-doc',
    hint: '',
  },
  'application/pdf': {
    className: 'icon-file-pdf',
    hint: 'PDF file',
  },
  'text/csv': {
    className: 'icon-doc-text',
    hint: 'CSV file',
  },
  'application/vnd.ms-excel': {
    className: 'icon-file-excel',
    hint: 'Microsoft Excel file',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    className: 'icon-file-excel',
    hint: 'Microsoft Excel file',
  },
  'application/vnd.openxmlformats-o': {
    className: 'icon-file-excel',
    hint: 'Microsoft Excel file',
  },
  'image/jpg': {
    className: 'icon-file-image',
    hint: 'JPG file',
  },
  'image/png': {
    className: 'icon-file-image',
    hint: 'PNG file',
  },
  'image/svg+xml': {
    className: 'icon-file-image',
    hint: 'SVG file',
  },
  'application/msword': {
    className: 'icon-file-word',
    hint: 'Word file',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessing': {
    className: 'icon-file-word',
    hint: 'Word file',
  },
};
