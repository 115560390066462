import React from 'react';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div className="bg-section pb-200 mt--20">
      <div className="error-code-large">
        <p>
          The content you&apos;ve requested was not found. [Error code 404].{' '}
          <span className="nowrap">
            Please <Link to="/">try again</Link>
          </span>{' '}
          or contact support on <span className="nowrap">+64 9 887 4135</span>{' '}
          for assistance{' '}
          <span className="nowrap">quoting this error code.</span>
        </p>
        <p className="code">
          <span>Error code 404</span>404
        </p>
      </div>
    </div>
  );
};
