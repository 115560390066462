import React, { useRef, useState, useEffect } from 'react';
import { Loading, Alert, Form, Button } from 'Components/nui';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { FormWrapper } from 'Components/form';
import { mergeSchema, normalizeChoices } from 'Components/form/utils';
import { promisify } from 'util';
import { useSharedTrade } from '../..';

export default () => {
  const [trade] = useSharedTrade();
  const schema = useRef({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    (async () => {
      try {
        const response = await api.request({
          method: 'get',
          url: `/trades/${trade.id}/freightquote/schema`,
        });
        if (mounted) {
          schema.current = response.data.fields;
          setLoading(false);
        }
      } catch (err) {
        toast.error('An error occurred while loading the form.');
        schema.current = 'error';
      }
    })();

    return () => {
      mounted = false;
    };
  }, [trade]);

  if (loading) {
    return <Loading size="small" />;
  }

  if (schema.current === 'error') {
    return (
      <Alert type="error" hasicon className="align-center">
        An error occurred.
      </Alert>
    );
  }

  return (
    <div className="info-form-area">
      <WrappedForm schema={schema.current} />
    </div>
  );
};

function useFormWrapper({ form, schema }) {
  const [trade] = useSharedTrade();
  const fields = [
    {
      name: 'rate',
      label: `Total freight cost (${trade.price.currency})`,
      step: 0.01,
    },
    {
      name: 'incoterm',
      label: 'Incoterm',
      choices: normalizeChoices,
    },
    {
      name: 'comment',
      label: 'Add a note to the buyer',
      type: null,
    },
  ];

  return new FormWrapper(form, {}, mergeSchema(schema, fields));
}

const BaseForm = ({ form, schema }) => {
  const formWrapper = useFormWrapper({
    form,
    schema,
  });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [trade, setTrade] = useSharedTrade();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      let data = formWrapper.serialize();
      const response = await api.postData(
        `/trades/${trade.id}/freightquote`,
        data
      );
      setLoading(false);
      form.resetFields();
      toast.success('Freight details have been added to the trade');

      setTrade(trade => ({
        ...trade,
        info: [...(trade?.info || [])].filter(x => x),
        freightquote: { quote: response.quote, status: 'pending' },
      }));
    } catch (err) {
      console.error(err);
      toast.error('An error occurred while submitting this form');
    }
  }

  return (
    <div className="nui-form">
      <Form onSubmit={handleSubmit}>
        {formWrapper.render()}
        <Button
          type="primary"
          disabled={loading}
          loading={loading}
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

const WrappedForm = Form.create()(BaseForm);
