import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Edit from './Edit';
import View from './View';
import Products from './Products';
import { Table, Tooltip } from 'Components/nui';
import classNames from 'classnames';
import moment from 'moment-timezone';
import R from 'ramda';
import { specConst } from '~/utils';
import YesNo from 'Components/YesNo';
import { api } from '~/api';
import { useStoreState } from '~/store';
import { Link } from 'react-router-dom';

export const Details = ({ product }) => {
  let data = [
    ['Name', product.name],
    ['ID', product.pid],
    ['Logo', product.logo?.id && <Tooltip img={product.logo} />],
  ];

  if (product.description) {
    data.push(['Description', product.description]);
  }

  if (R.hasPath(['owner', 'name'], product)) {
    data.push(['Owner', R.pathOr('n/a', ['owner', 'name'], product)]);
  }

  data.push(
    ['Price increment', `${product.step} ${product._desc}`],
    ['Unit', product.unit.desc],
    ['Loading unit', product.loadingunit.desc],
    ['Created at', moment(product.created).format('DD MMMM YYYY')]
  );

  if ('incoterm' in product) {
    data.push(['Incoterm', product.incoterm]);
  }

  if ('tags' in product && product.tags.length > 0) {
    data.push(['Tags', product.tags.map(item => item.name).join(', ')]);
  }

  const canManageDivisions = useStoreState(
    state => state.auth.canManageDivisions
  );
  const canManageProductsDivisions = useStoreState(
    state => state.auth.canManageProductsDivisions
  );
  const canEdit =
    canManageProductsDivisions.length > 0 && canManageDivisions.length > 0;

  return (
    <>
      <hr className="hide-for-medium mb-15" />
      <h2>
        Product details
        {canEdit && (
          <Link
            to={`/products/${product.id}/edit`}
            className="editme"
            title="Edit product"
          >
            Edit
          </Link>
        )}
      </h2>
      <ul>
        {data.map(
          ([key, value, className]) =>
            value !== undefined && (
              <li key={key} className={className}>
                <strong>{key}</strong>
                <span>{value}</span>
              </li>
            )
        )}
      </ul>
    </>
  );
};

export const Specifications = ({ specifications }) => {
  const icons = R.uniq(
    specifications.map(item => R.path(['compliance', 'id'], item))
  ).filter(x => x);

  const data = icons.map(id => [
    specConst[id].name,
    specifications.map(
      item =>
        id === item.compliance.id && (
          <a
            key={item.href}
            href={`#${item.filename}`}
            onClick={() => api.getPdf(item.href, item.filename)}
            title={item.filename}
          >
            {item.filename}
          </a>
        )
    ),
  ]);

  return (
    <>
      <h2>
        <span className="icon-file-pdf"></span> Documents
      </h2>
      <ul className="docs">
        {data.map(([key, value, className]) => (
          <li key={key} className="stacked pb-0">
            <strong>{key}</strong>
            <span>{value}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export const LoadingDetails = ({ product, details }) => {
  let columns = [
    {
      title: 'Shipping unit type',
      key: 'container',
      dataIndex: 'container',
    },
    {
      title: 'Reefer',
      key: 'reefer',
      render: (text, record, index) => {
        return <YesNo yes={record.reefer} />;
      },
    },
    {
      title: 'Loading weight',
      key: 'volume',
      render: (text, record, index) => {
        if (record.fixed) return `${record.qty} ${product.loadingunit.desc}`;
        return 'Variable';
      },
    },
    {
      title: 'Packaging type',
      key: 'packaging',
      dataIndex: 'packaging',
    },
  ];

  if (product.unit.name === 'packaging') {
    columns.push({
      title: 'Number of packages',
      key: 'packing_load',
      dataIndex: 'packing_load',
    });
  }

  return (
    <Table
      rowKey="id"
      className={classNames('generic-table products-table')}
      columns={columns}
      pagination={false}
      data={details}
    />
  );
};

export default () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={[`${url}/:id/edit/:area`, `${url}/:id/edit`, `${url}/new`]}>
        <Edit />
      </Route>
      <Route path={`${url}/:id`}>
        <View />
      </Route>
      <Route>
        <Products />
      </Route>
    </Switch>
  );
};
