import React from 'react';
import R from 'ramda';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';
import FNumber from 'Components/FNumber';
import { unitsOfMeasure } from '~/utils';

export const LegendVolumeColumn = ColumnTree.defineNode(
  'legend-volume',
  ({ productById }) => ({
    title: (
      <span>
        <FormattedMessage
          id="marketplace-table-subheading-volume"
          description="Table sub-heading"
          defaultMessage="Volume"
        />
      </span>
    ),
    key: 'volume',
    className: 'col-product-volume-alt',
    render: (text, record, index) => {
      const product = productById(record.product.id);
      const unit = R.path(['volume', 'unit'], record) as string | number; // TODO: 

      return (
        <div className="td-content">
          <div className="col-product-volume">
            <span className="product-price nowrap">
              {record.quarteretd ? (
                <>
                  <FNumber value={record.volume.pending} />
                  <span className="qrts">/ 3</span>
                </>
              ) : (
                <FNumber value={record.volume.pending} />
              )}
            </span>
            <span className="product-unit">
              {product
                ? R.path(['loadingunit', 'desc'], product)
                : R.pathOr(unit, [unit], unitsOfMeasure)}
            </span>
          </div>
        </div>
      );
    },
  })
);
