import React from 'react';
import R from 'ramda';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from 'Components/nui';

export const SpecButtons = ({ product }) => {
  const specConst = {
    10000: {
      className: 'specs',
      tooltipText: 'Specifications',
      anchorText: 'specifications',
      symbol: 'S',
    },
    10001: {
      className: 'halal',
      tooltipText: 'Halal docs',
      anchorText: 'halal',
      symbol: 'H',
    },
    10002: {
      className: 'kosher',
      tooltipText: 'Kosher docs',
      anchorText: 'kosher',
      symbol: 'K',
    },
    10003: {
      className: 'organic',
      tooltipText: 'Organic docs',
      anchorText: 'organic',
      symbol: 'O',
    },
    10004: {
      className: 'other',
      tooltipText: 'Other docs',
      anchorText: 'other',
      symbol: 'O',
    },
  };
  const icons = R.uniq(
    product.specifications.map(item => R.path(['compliance', 'id'], item))
  ).filter(x => x);

  if (icons.length === 0) return null;

  return (
    <ul className="legend-list">
      {icons.map(id => (
        <li key={id}>
          <Tooltip
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                {specConst[id].tooltipText}
              </span>
            }
          >
            <Link
              to={`/products/${product.id}/specifications#${specConst[id].anchorText}`}
              className={classNames('legend', specConst[id].className)}
            >
              {specConst[id].symbol}
            </Link>
          </Tooltip>
        </li>
      ))}
    </ul>
  );
};
