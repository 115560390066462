import { createStore, createTypedHooks } from 'easy-peasy';
import { api } from '~/api';
import { authService } from '~/services/auth-service';
import { history } from '~/history';
import { StoreModel } from './StoreModel';
import { authModel } from './store';
import marketModel from './marketModel';
import marketplaceModel from './marketplaceModel';
import marketdepthModel from './marketdepthModel';
import notifModel from './notifModel';
import bannersModel from '~/store/bannerModel';
import {
  ordersContainer,
  productsContainer,
  tradesContainer,
  participantsContainer,
} from './containers';
import authorisationModel from './AuthorisationModel';

const storeModel: StoreModel = {
  auth: authModel,
  market: marketModel,
  marketplace: marketplaceModel,
  marketdepth: marketdepthModel,
  notif: notifModel,
  authorisation: authorisationModel,
  banners: bannersModel(),

  models: {
    orders: ordersContainer,
    trades: tradesContainer,
    products: productsContainer,
    participants: participantsContainer,
  },
};

export const store = createStore(storeModel, {
  name: 'NuiStore',
  injections: {
    api,
    authService,
    history,
  },
});

// Export typed hooks
export const { useStoreActions, useStoreState, useStoreDispatch } =
  createTypedHooks<StoreModel>();

export {
  authModel,
  marketModel,
  marketplaceModel,
  marketdepthModel,
  notifModel,
};
