import React from 'react';
import classnames from 'classnames';
import R from 'ramda';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const OriginsColumn = ColumnTree.defineNode(
  'origins',
  ({ solutionColumns, sortDirection, sortColumn, setSort }) => {
    if (!('origins' in solutionColumns && solutionColumns.origins)) return [];
    return {
      title: (
        <div>
          <a
            href="#origins"
            className={classnames('icon-sort', 'sortem', {
              [sortDirection]: sortColumn === 'origins',
            })}
            onClick={() => setSort('origins')}
          >
            {R.propOr('Origin', 'label', solutionColumns.origins)}
          </a>
        </div>
      ),
      children: [
        {
          title: '',
          key: 'origins',
          className: 'col-origins',
          render: (text, record, index) => (
            <div className="td-content">
              <span className="tiny-label">
                {R.propOr('Origin', 'label', solutionColumns.origins)}
              </span>
              {record.origins &&
                record.origins.map(item => item.name).join(', ')}
            </div>
          ),
        },
      ],
    };
  }
);
