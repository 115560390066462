import React, { useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { Button } from 'Components/nui';
import { useClickAway, useToggle } from 'react-use';
import classNames from 'classnames';
import Helmet from 'Components/Helmet';

export default () => {
  const solution = useStoreState(state => state.auth.solution);
  const solutions = useStoreState(state => state.auth.solutions);
  const changeSolution = useStoreActions(action => action.auth.changeSolution);
  const history = useHistory();

  const ref = useRef(null);
  const [open, toggle] = useToggle(false);
  useClickAway(ref, () => {
    toggle(false);
  });

  const change = id => {
    toggle(false);
    changeSolution(id);
    history.push('/');
  };

  return (
    <div ref={ref} className="solution-picker">
      <Helmet>
        {solutions.map(s => (
          <link key={s.id} rel="preload" as="image" href={s.logo} />
        ))}
      </Helmet>
      <Button
        htmlType="button"
        type="buttonlink"
        title="Change solution"
        onClick={toggle}
      >
        <span className="solution">
          Change <span className="show-for-large">solution</span>
        </span>
      </Button>
      {open && (
        <nav>
          <ul>
            {solutions.map(s => (
              <li
                key={s.id}
                className={classNames({ selected: s.id === solution.id })}
              >
                <Button htmlType="button" onClick={() => change(s.id)}>
                  <figure>
                    <img src={s.logo} alt={s.desc} />
                  </figure>
                  <span className="solution-name">{s.desc}</span>
                </Button>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
};
