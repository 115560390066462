import type { Moment, Duration as Moment$Duration } from 'moment-timezone';
import type * as Struct from 'struct';
import type * as Models from '~/models/utils';
import type { Tender } from '~/models/tenders';
import React, { useMemo, useState, useEffect } from 'react';
import {
  nuiFormatNumber,
  getDecimalSeparator,
  getGroupingSeparator,
  getDecimalCount,
} from '~/utils';
import { useStoreState } from '~/store';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Tooltip from './nui/Tooltip';
import Button from './nui/Button';
import Modal from './nui/Modal';
import { api } from '~/api';
import * as R from 'ramda';

type ClassProp = Partial<Record<'className', string>>;
type NodeProp<N extends string> = Record<N, React.ReactNode>;
type ChildrenProp = Partial<NodeProp<'children'>>;
type TooltipProp = Partial<NodeProp<'tooltip'>>;
type ValueProp<T> = Record<'value', T>;
type NotRequiredProp = Partial<Record<'notRequired', boolean>>;

type Div = JSX.IntrinsicElements['div'];

type IBool = ValueProp<boolean> & ClassProp;
export const Bool = ({ value, className }: IBool) => (
  <span
    className={classnames('data-bool', className, {
      'icon-ok': value,
      'icon-block': !value,
    })}
  >
    {value ? 'Yes' : 'No'}
  </span>
);

type IRequired = ValueProp<boolean> & ClassProp & NotRequiredProp;
export const Required = ({ value, className, notRequired }: IRequired) => (
  <span
    className={classnames('data-bool', className, {
      'icon-ok': value,
      'icon-block': !value,
    })}
  >
    {value ? 'Yes' : notRequired ? 'Not required' : 'No'}
  </span>
);

interface INumber {
  value: number;
  precision?: number;
  step?: number;
  relative?: boolean;
}
export const Number = ({
  value,
  precision,
  step,
  relative = false,
}: INumber) => {
  const { numeric_decimal, numeric_group } = useStoreState(
    state => state.auth.userModel
  );

  const point = useMemo(
    () => numeric_decimal || getDecimalSeparator(),
    [numeric_decimal]
  );
  const group = useMemo(
    () => numeric_group || getGroupingSeparator(),
    [numeric_group]
  );
  const prec = useMemo(
    () =>
      precision
        ? precision
        : step
        ? getDecimalCount(step)
        : getDecimalCount(value),
    [precision, value, step]
  );

  const nval = relative ? Math.abs(value) : value;

  const formatted = useMemo(
    () => nuiFormatNumber(nval, prec, point, group),
    [value, prec, point, group]
  );

  if (relative)
    return (
      <>
        {value >= 0 ? <>&#43;</> : <>&minus;</>}
        {formatted}
      </>
    );

  return <>{formatted}</>;
};

interface ITicker<T extends { val: number; step: number }> {
  value: T;
  title?: React.ReactNode;
  unit?: React.ReactNode;
  className?: string;
  onZero?: React.ReactNode;
}
export const Ticker = ({ title, value, unit, ...options }: ITicker<any>) => {
  const { className, onZero } = options;
  return (
    <div className={classnames('unit-detail', className)}>
      {title && <h4>{title}</h4>}
      {onZero && value.val === null ? (
        onZero
      ) : (
        <Number value={value.val} step={value.step} />
      )}{' '}
      {unit && (
        <span className="unit">
          {unit}
          {value.price_unit ? `/${value.price_unit}` : ''}
        </span>
      )}
    </div>
  );
};

interface ITickerAlt {
  title: string;
  children?: React.ReactNode;
}
export const TickerAlt = ({ title, children }: ITickerAlt) => (
  <div className="unit-detail">
    <h4>{title}</h4>
    {children}
  </div>
);

interface IOperator {
  value: number;
  index: Struct.Index;
}
const IndexOperator = ({ value, index }: IOperator) =>
  index.type === 'Multiplier' ? (
    <>&times;</>
  ) : value >= 0 ? (
    <>&#43;</>
  ) : (
    <>&minus;</>
  );

interface IIndexSummary {
  index: {
    step: number;
    type: 'Modifier' | 'Multiplier';
    name: string;
    category: string;
    period: 'Day' | 'Week' | 'Month';
    offset: 'Prior to' | 'Of' | 'After';
  };
  date?: Moment;
  className?: string;
}

export const IndexSummary = ({ index, date, className }: IIndexSummary) => (
  <span className={classnames('index-name-summary', 'mt-7', className)}>
    <span className="nowrap">
      <strong className="all-black">{index.category}</strong> {index.name}
    </span>
    <span className="nowrap">
      {index.period} {R.toLower(index.offset)}
      {date && (
        <strong className="all-black">
          &nbsp;
          {index.period === 'Month'
            ? date.format('MMMM YYYY')
            : date.format('LL')}
        </strong>
      )}
    </span>
  </span>
);

interface IIndexName {
  value: Struct.IndexedPrice | Struct.ReservePrice;
}
export const IndexName = ({ value }: IIndexName) =>
  value.index ? (
    <>
      <strong className="all-black">{value.index.category}</strong>{' '}
      {moment(value.indexdate).format('LL')}
      <Tooltip
        title={
          <IndexSummary index={value.index} date={moment(value.indexdate)} />
        }
      >
        <span className="icon-help-circled" />
      </Tooltip>
    </>
  ) : (
    <></>
  );

interface IIndexUnit {
  value: Struct.IndexedPrice;
}
export const IndexUnit = ({ value }: IIndexUnit) =>
  value.index ? <IndexName value={value} /> : <>{value.unit}</>;

interface IPrice {
  value: Pick<Struct.IndexedPrice, 'val' | 'step' | 'index' | 'indexdate'>;
  onZero?: React.ReactNode;
}

export const Price = ({ value, onZero = 'N/A' }: IPrice) => {
  if (value.val === null) return <>{onZero}</>;
  if (value.index)
    return (
      <>
        {value !== null && (
          <IndexOperator value={value.val} index={value.index} />
        )}
        <Number value={Math.abs(value.val)} step={value.index.step} />
      </>
    );
  return <Number value={value.val} step={value.step} />;
};

interface IPriceTicker extends Div {
  title?: string;
  nounit?: boolean;
  value: Struct.IndexedPrice;
  onZero?: React.ReactNode;
}
export const PriceTicker = ({
  title = 'Price',
  nounit = false,
  className,
  value,
  onZero,
  ...props
}: IPriceTicker) => {
  return (
    <div className={classnames('unit-detail', className)} {...props}>
      {title && <h4>{title}</h4>}
      <Price value={value} onZero={onZero} />{' '}
      {value.index && (
        <Tooltip
          title={
            <IndexSummary index={value.index} date={moment(value.indexdate)} />
          }
        >
          <span className="icon-help-circled index-tooltip" />
        </Tooltip>
      )}
      {!nounit && <span className="unit">{value.unit}</span>}
    </div>
  );
};

interface IVolume {
  value: Pick<Struct.Volume, 'val' | 'step'>;
}
export const Volume = ({ value }: IVolume) => <Number value={value.val} />;

interface DeliveryVolume extends Struct.Volume {
  delivered?: {
    val: number;
    deliveries: number;
    frequency: Struct.DeliveryFrequency;
  };
}
export const VolumeTicker = ({
  title = 'Volume',
  value,
  className,
  ...options
}: ITicker<DeliveryVolume>) => {
  const delivered = value.delivered;

  if (delivered) {
    return (
      <div className={classnames('unit-detail', className)}>
        <h4>Deliveries</h4>
        {delivered.deliveries}&times;
        <Number value={delivered.val} step={value.step} />{' '}
        <span className="unit">
          {value.unitdesc} ({delivered.frequency})
        </span>
      </div>
    );
  }

  return (
    <Ticker title={title} value={value} unit={value.unitdesc} {...options} />
  );
};

interface EtdValue {
  from: string | Moment;
  to: string | Moment;
  deliveries?: {
    frequency: 'daily' | 'weekly' | 'monthly';
    total: number;
  };
}
interface IEtd {
  value: EtdValue;
}
export const ETD = ({ value }: IEtd) => {
  const formatted = useMemo(() => {
    const start = moment(value.from);

    if (value.deliveries)
      return [
        value.deliveries.total,
        value.deliveries.frequency,
        'deliveries from',
        start.format('D MMM YYYY'),
      ].join(' ');

    const end = moment(value.to);

    const fmtYear = start.year() === end.year() ? false : 'YYYY';
    const fmtMth = !fmtYear && start.month() === end.month() ? false : 'MMM';
    const fmtStart = ['D', fmtMth, fmtYear].filter(x => !!x).join(' ');
    return `${start.format(fmtStart)} - ${end.format('D MMM YYYY')}`;
  }, [
    value.from,
    value.to,
    value.deliveries?.total,
    value.deliveries?.frequency,
  ]);

  return <>{formatted}</>;
};

interface ILoadingDetails {
  value: Struct.ProductLoading;
}
export const LoadingDetail = ({ value }: ILoadingDetails) => {
  return <>{value.desc}</>;
};

interface ILocation {
  value: Models.Location;
  className?: string;
}
export const Location = ({ value, className }: ILocation) => (
  <dl className={classnames('data-location', className)}>
    <dt className="location-name">{value.name}</dt>
    <dt>Address</dt>
    <dd className="location-address">{value.address()}</dd>
    <dt>Country</dt>
    <dd className="location-country">{value.country.name}</dd>
    {value.port && (
      <>
        <dt>Port</dt>
        <dd className="location-port">{value.port.name}</dd>
      </>
    )}
  </dl>
);

export const ShortLocation = ({ value }: ILocation) => <>{value.name}</>;

interface INote {
  value: string;
}
export const Note = ({ value }: INote) =>
  value.length ? (
    <>
      {value.split('\n').map((pg, index) => (
        <p key={index} className="pb-0">
          {pg}
        </p>
      ))}
    </>
  ) : (
    <>No comments</>
  );

interface IRankNumber {
  value: number;
  className?: string;
}
export const Rank = ({ value, className }: IRankNumber) => {
  const blind = value === 0;
  const leading = value === 1;
  const losing = value > 1;
  const text = blind ? 'N/A' : value;
  return (
    <span
      className={classnames('rank-number', className, {
        blind,
        leading,
        losing,
      })}
    >
      {text}
    </span>
  );
};

type IList = ClassProp & ChildrenProp;
export const List = ({ className, children }: IList) => (
  <ul className={classnames('data-list', className)}>{children}</ul>
);

interface IItem extends IList {
  title?: React.ReactNode;
}
export const Item = ({ title, className, children }: IItem) => (
  <li className={classnames('data-item', className)}>
    {title && <strong>{title}</strong>}
    <span>{children}</span>
  </li>
);

const fmt = (t: number, u: string) =>
  `${Math.floor(t).toFixed(0)} ${u}${Math.floor(t) !== 1 ? 's' : ''}`;

const fmtValues = (values: [number, string][]) =>
  values
    .filter(x => Math.floor(x[0]) > 0)
    .map(x => fmt(...x))
    .join(', ');

export const Duration = ({ value }: Record<'value', Moment$Duration>) => {
  if (Math.floor(value.asDays()) > 1) {
    const date = fmtValues([
      [value.asYears(), 'year'],
      [value.months(), 'month'],
      [value.days(), 'day'],
    ]);

    const time = [value.hours(), value.minutes(), value.seconds()]
      .map(x => x.toFixed(0).padStart(2, '0'))
      .join(':');

    const display = [date, time].filter(x => !!x.length).join(' ');
    return <>{display}</>;
  }

  const time = fmtValues([
    [value.asHours(), 'hour'],
    [value.minutes(), 'minute'],
    [value.seconds(), 'second'],
  ]);

  return <>{time}</>;
};

interface ICountdown {
  target: Moment;
  interval?: number;
}
export const Countdown = ({ target, interval = 1000 }: ICountdown) => {
  const getDuration = (t: Moment) => moment.duration(t.diff(moment()));

  const [value, setValue] = useState(getDuration(target));

  useEffect(() => {
    const timeout = setInterval(() => {
      if (target.isBefore(moment())) {
        clearInterval(timeout);
      }
      setValue(getDuration(target));
    }, interval / 2);

    return () => {
      clearInterval(timeout);
    };
  }, [target, interval]);

  return <Duration value={value} />;
};

type IDatetime = Partial<Record<'value', Moment> & Record<'fmt', string>>;
export const Datetime = ({ value, fmt = 'LL HH:mm:ss' }: IDatetime) => (
  <>{value?.format(fmt)}</>
);

interface IOrderAttr {
  value: Struct.OrderAttr[];
  className?: string;
}
export const OrderAttr = ({ value, className }: IOrderAttr) => (
  <span className={classnames('order-attr', className)}>
    {value.map(a => a.name).join(' | ')}
  </span>
);

interface IAttributes {
  value?: Models.Attribute[];
  className?: string;
}
export const Attributes = ({ value, className }: IAttributes) =>
  value?.length ? (
    <ul className={classnames('order-attributes', className)}>
      {value.map(attr => (
        <li key={attr.name} className="attribute">
          <strong>{attr.name}</strong>
          <span>{attr.toString()}</span>
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );

interface IProductLink {
  product: { name: string; id: string };
  className?: string;
}
export const ProductLink = ({ product, className }: IProductLink) => {
  const url = `/products/${product.id}/quick`;
  return (
    <Tooltip
      title={
        <span className="icon-tooltip">
          <span className="icon-info-circled"></span>
          View product details in new window
        </span>
      }
    >
      <Link className={classnames('product-link', className)} to={url}>
        {product.name}
        <span className="nowrap">
          &#65279;<span className="icon-link-ext-alt"></span>
        </span>
      </Link>
    </Tooltip>
  );
};

const methods = {
  open: 'Open (binding)',
  blind: 'Blind',
  opennonbinding: 'Open (non-binding)',
};

type IInfo = { info: Pick<Tender, 'ordername' | 'method' | 'methodTitle'> };

const Tips = ({ info }: IInfo) => {
  const reservePriceEnabled = useStoreState(
    state => state.auth.solutionSettings.tenderreserveprice
  );

  if (info.method === 'open') {
    return (
      <span className="icon-tooltip">
        <span className="icon-info-circled" />{' '}
        <strong className="all-black">{methods.open}</strong>
        &mdash;Participants will be able to see their {
          info.ordername.counter
        }{' '}
        in relation to {info.ordername.counter}s placed by other participants.
        The tender owner is obligated to trade on the top ranked{' '}
        {info.ordername.counter} for all {info.ordername.order}s
        {reservePriceEnabled && '*'}. Additional volume can be traded by the
        tender owner at their discretion.
        {reservePriceEnabled && (
          <span className="block mt-5">
            * If a reserve price has been set and the {info.ordername.order}
            &apos;s best price does not meet the reserve price, the tender owner
            is not obligated to trade.
          </span>
        )}
      </span>
    );
  }

  if (info.method === 'blind') {
    return (
      <span className="icon-tooltip">
        <span className="icon-info-circled" />{' '}
        <strong className="all-black">{methods.blind}</strong>
        &mdash;Participants will only be able to see their own{' '}
        {info.ordername.counter}s{reservePriceEnabled && '*'}.
        {reservePriceEnabled && (
          <span className="block mt-5">
            * If a reserve price has been set and the {info.ordername.order}
            &apos;s best price does not meet the reserve price, the tender owner
            is not obligated to trade.
          </span>
        )}
      </span>
    );
  }

  if (info.method === 'opennonbinding') {
    return (
      <span className="icon-tooltip">
        <span className="icon-info-circled" />{' '}
        <strong className="all-black">{methods.opennonbinding}</strong>
        &mdash;Participants will be able to see their {
          info.ordername.counter
        }{' '}
        in relation to {info.ordername.counter}s placed by other participants.
        The tender owner is not obligated to trade.
      </span>
    );
  }

  return <span>Other methods</span>;
};

type ITenderMethod = ClassProp & IInfo;

export const TenderMethod = ({ className, info }: ITenderMethod) => (
  <Tooltip title={<Tips info={info} />}>
    <span className={classnames('tender-method', className)}>
      {info.methodTitle || methods[info.method]}
      <span className="icon-help-circled mt-1" />
    </span>
  </Tooltip>
);

type ICountryCode = Record<'country', Struct.Country> & ClassProp;
export const CountryCode = ({ country, className }: ICountryCode) => (
  <Tooltip title={country.name}>
    <span className={classnames('country-code', className)}>
      <span>{country.code}</span>
    </span>
  </Tooltip>
);

type IWebsite = Record<'value', string> & ClassProp;
export const Website = ({ value, className }: IWebsite) => (
  <a
    href={value}
    className={className}
    target="_blank"
    rel="noopener noreferrer"
  >
    {value}
  </a>
);

type IReason = Record<'reason', string> & ClassProp;
export const Reason = ({ reason, className }: IReason) => (
  <Tooltip
    title={
      <span className="icon-tooltip">
        <span className="icon-info-circled" />
        {reason}
      </span>
    }
  >
    <span className="icon-attention" />
  </Tooltip>
);

type IFileLink = Record<'file', Struct.Attachment> & ClassProp;
export const FileLink = ({ file, className }: IFileLink) => {
  const [loading, setLoading] = useState(false);
  const icon = file.mimetype.startsWith('image')
    ? 'icon-file-image'
    : file.mimetype === 'application/pdf'
    ? 'icon-file-pdf'
    : file.mimetype.startsWith('application/vnd')
    ? 'icon-file-excel'
    : file.mimetype === 'text/csv'
    ? 'icon-doc-text'
    : file.filename.endsWith('doc') || file.filename.endsWith('docx')
    ? 'icon-file-word'
    : 'icon-doc';

  return (
    <span className={classnames('file-link', icon, className)}>
      <Button
        type="buttonlink"
        onClick={async () => {
          setLoading(true);
          await api.getFile(file.href, file.filename, file.mimetype);
          setLoading(false);
        }}
        loading={loading}
        disabled={loading}
      >
        {file.filename}
      </Button>
    </span>
  );
};

type IFileList = Record<'files', Struct.Attachment[]> & ClassProp;
export const FileList = ({ files, className }: IFileList) => (
  <ul className={classnames('file-list', className)}>
    {files.map(file => (
      <li className="file-entry" key={file.id}>
        <FileLink file={file} />
      </li>
    ))}
  </ul>
);

type IFiles = IFileList & Partial<Record<'title', React.ReactNode>>;
export const Files = ({ files, title, className }: IFiles) => {
  const [visible, setVisible] = useState(false);
  const open = () => setVisible(true);
  const close = () => setVisible(false);
  return (
    <>
      <Button
        onClick={open}
        type="buttonlink"
        className={classnames('view-files', className)}
      >
        {title || 'View documents'} ({files.length})
      </Button>
      {visible && (
        <Modal close={close} size="small">
          <h1>Documents</h1>
          <FileList files={files} />
          <hr />
          <Button onClick={close} type="reverse">
            Close
          </Button>
        </Modal>
      )}
    </>
  );
};

type IIcon = Record<'icon', string> & TooltipProp & ClassProp;
export const Icon = ({ icon, tooltip, className }: IIcon) => (
  <Tooltip
    title={
      <span className="icon-tooltip">
        <span className="icon-info-circled" /> {tooltip}
      </span>
    }
  >
    <span className={classnames(icon, className)} />
  </Tooltip>
);

type IVariant = { id: string; name: string; price?: Struct.Price };
type IProductVariants = Record<'variants', IVariant[]> & ClassProp;
export const ProductVariants = ({ variants, className }: IProductVariants) => (
  <dl className={classnames('variants-list', className)}>
    {variants.map(variant => (
      <dd key={variant.id} className="variant-entry">
        {variant.name}
        {!!variant.price && (
          <span>
            (<PriceTicker title="" value={variant.price} />)
          </span>
        )}
      </dd>
    ))}
  </dl>
);
