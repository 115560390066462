import React from 'react';

interface IHtmlContent {
  data?: string | null;
  format?: 'html';
}

export default function UserContent({ data, format = 'html' }: IHtmlContent) {
  if (!data) return null;
  if (format === 'html')
    return (
      <div
        className="nui-user-content"
        dangerouslySetInnerHTML={{ __html: data }}
      />
    );
  return null;
}
