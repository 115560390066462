import React from 'react';
import classnames from 'classnames';
import {
  Switch,
  Route,
  Redirect,
  Link,
  useRouteMatch,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useStoreState } from '~/store';
import Profile from './Profile';
import Solutions from './Solutions';
import Configuration from './Configuration';
import Banners from './Banners';
import Trusted from './Trusted';
import { useToggle } from 'react-use';
import SignOutModal from 'Pages/SignOutModal';

const NavItem = ({ name, title, icon }) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const to = `${url}/${name}`;
  const selected = pathname.startsWith(to);

  return (
    <li className={classnames('profile-nav-item', name, { selected })}>
      <Link to={to} className={icon} title={title}>
        <b>{title}</b>
      </Link>
    </li>
  );
};

const Navigation = () => {
  const isSolutionOwner = useStoreState(state => state.auth.isSolutionOwner);
  const isManager = useStoreState(state => state.auth.hasRole('manager'));

  return (
    <div className="account-navigation">
      <ul className="panel-navigator">
        <NavItem title="Profile" name="profile" icon="icon-user" />
        <NavItem title="Solutions" name="solutions" icon="icon-th-list" />
        <NavItem title="Security" name="security" icon="icon-lock" />
        {isSolutionOwner && isManager && (
          <NavItem title="Banners" name="banners" icon="icon-info-circled" />
        )}
        <NavItem title="Settings" name="config/notifications" icon="icon-cog" />
      </ul>
    </div>
  );
};

const Section = () => {
  const { slug } = useParams();

  return (
    <>
      {slug === 'profile' && <Profile />}
      {slug === 'solutions' && <Solutions />}
      {slug === 'config' && <Configuration />}
      {slug === 'banners' && <Banners />}
      {slug === 'security' && <Trusted />}
    </>
  );
};

export default () => {
  const { url } = useRouteMatch();
  const [on, toggle] = useToggle();

  return (
    <div>
      <div>
        <button onClick={toggle} className="signout">
          <span className="icon-logout" />
          <span className="signout-txt">Sign out</span>
        </button>

        {on && <SignOutModal on={on} toggle={toggle} />}
      </div>

      <Navigation />

      <Switch>
        <Route path={`${url}/:slug`}>
          <Section />
        </Route>
        <Route>
          <Redirect to={`${url}/profile`} />
        </Route>
      </Switch>
    </div>
  );
};
