import React from 'react';
import { ComingSoon } from 'Components/InfoMessage';
import { withRoute } from '~/router';

export default withRoute({
  name: 'tender-analytics',
  head: { title: 'Tender Analytics' },
})(() => {
  return <ComingSoon className="mt--1 mb--1 p-100" />;
});
