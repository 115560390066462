import React from 'react';
import R from 'ramda';
import YesNo from 'Components/YesNo';
import FNumber from 'Components/FNumber';
import moment from 'moment-timezone';
import AddressesList from 'Components/AddressesList';
import { Link } from 'react-router-dom';
import { unitsOfMeasure, inArrayIf } from '~/utils';
import { Alert } from 'Components/nui';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { AltIndexUnit, PriceIndex } from 'Components/nui/PriceIndex';
import PrepopulateButtons from 'Pages/MarketRow/PrepopulateButtons';

export default ({ order }) => {
  const volumeUnit = R.pathOr(
    order.volume.unit,
    [order.volume.unit],
    unitsOfMeasure
  );
  let data = [
    [
      <FormattedMessage
        id="orderdetails-field-pid-label"
        description="Label for 'PID' field on Order Details"
        defaultMessage="ID"
      />,
      order.pid,
    ],
    [
      <FormattedMessage
        id="orderdetails-field-revision-label"
        description="Label for 'Revision' field on Order Details"
        defaultMessage="Revision"
      />,
      order.revision,
    ],
    [
      <FormattedMessage
        id="orderdetails-field-product-label"
        description="Label for 'Product' field on Order Details"
        defaultMessage="Product"
      />,
      <Link to={`/products/${order.product.id}/quick`}>
        {order.product.name}
      </Link>,
    ],
    [
      <FormattedMessage
        id="orderdetails-field-private-label"
        description="Label for 'Private' field on Order Details"
        defaultMessage="Private"
      />,

      <>
        <YesNo yes={order.isprivate} />
        {'counterparties' in order && (
          <>
            {order.counterparties.length > 1 ? (
              <>
                <p className="mt-10 pb-5">
                  <FormattedMessage
                    id="orderdetails-field-companies-label"
                    description="Label for 'Companies' field on Order Details"
                    defaultMessage="Companies:"
                  />
                </p>
                <ul className="mb-0">
                  {order.counterparties.map(item => (
                    <li key={item.id}>{item.name}</li>
                  ))}
                </ul>
              </>
            ) : (
              <p className="mt-10 pb-5">{order.counterparties[0].name}</p>
            )}
          </>
        )}
      </>,
    ],
    [
      <FormattedMessage
        id="orderdetails-field-etd-label"
        description="Label for 'Estimated time of departure' field on Order Details"
        defaultMessage="Estimated time of departure"
      />,
      order.deliveryfrequency
        ? moment(order.etd.from).format('DD MMMM YYYY')
        : order.etddesc,
      'stacked',
    ],
    ...inArrayIf('deliveryfrequency' in order, [
      'Deliveries',
      <>
        {order.totaldeliveries} {order.deliveryfrequency} deliveries of{' '}
        <FNumber
          value={order.volume.delivery_total}
          decimalCount={order.volumeDecimalCount}
        />{' '}
        {volumeUnit}
      </>,
    ]),
    [
      <FormattedMessage
        id="orderdetails-field-createdat-label"
        description="Label for 'Created at' field on Order Details"
        defaultMessage="Created at"
      />,
      moment(order.created).format('DD MMMM YYYY HH:mm'),
    ],
    [
      <FormattedMessage
        id="orderdetails-field-updatedat-label"
        description="Label for 'Updated at' field on Order Details"
        defaultMessage="Updated at"
      />,
      moment(order.updated).format('DD MMMM YYYY HH:mm'),
    ],
    [
      <FormattedMessage
        id="orderdetails-field-creator-label"
        description=" "
        defaultMessage="Creator"
      />,
      R.pathOr('n/a', ['creator', 'fullname'], order),
    ],
    [
      <FormattedMessage
        id="orderdetails-field-owner-label"
        description="Label for 'Owner' field on Order Details"
        defaultMessage="Owner"
      />,
      R.pathOr('n/a', ['owner', 'fullname'], order),
    ],
    [
      <FormattedMessage
        id="orderdetails-field-splittable-label"
        description="Label for 'Splittable' field on Order Details"
        defaultMessage="Splittable"
      />,
      <YesNo yes={order.splittable} />,
    ],
  ];

  if ('size' in order && order.size.length > 0)
    data.push([
      <FormattedMessage
        id="orderdetails-field-size-label"
        description="Label for 'Size' field on Order Details"
        defaultMessage="Size"
      />,
      order.size.map(x => x.name).join(', '),
    ]);

  if ('incoterm' in order)
    data.push([
      <FormattedMessage
        id="orderdetails-field-incoterm-label"
        description="Label for 'Incoterm' field on Order Details"
        defaultMessage="Incoterm"
      />,
      order.incoterm,
    ]);

  if ('fromaddr' in order && order.fromaddr.length)
    data.push([
      <FormattedMessage
        id="orderdetails-field-deliveryfrom-label"
        description="Label for 'Delivery from' field on Order Details"
        defaultMessage="Delivery from"
      />,
      <AddressesList addresses={order.fromaddr} />,
      'stacked',
    ]);

  if ('toaddr' in order && order.toaddr.length)
    data.push([
      <FormattedMessage
        id="orderdetails-field-deliveryto-label"
        description="Label for 'Delivery to' field on Order Details"
        defaultMessage="Delivery to"
      />,
      <AddressesList addresses={order.toaddr} />,
      'stacked',
    ]);

  return (
    <>
      <h2>
        <FormattedMessage
          id="orderdetails-header"
          description="Header for Order details"
          defaultMessage="Order details"
        />
      </h2>
      <div className="unit-box">
        <div className="clearfix pb-10">
          <strong className="all-black mr-20">
            <FormattedMessage
              id="orderdetails-field-type-label"
              description="Label for 'Type' field on Order Details"
              defaultMessage="Type"
            />
          </strong>
          <span
            className={classnames(
              `type-${order.type} inline right-for-medium mt-2`,
              { counteronly: order.counteronly }
            )}
          >
            {order.counteronly && 'SFC '}
            {order.type}
          </span>
        </div>
        <hr className="m-0 mb-10 show-for-small-only" />
        <div className="value-items thirds">
          <div className="value-item">
            <span className="item-label">
              <FormattedMessage
                id="orderdetails-field-price-label"
                description="Label for 'Price' field on Order Details"
                defaultMessage="Price"
              />
            </span>
            <strong className="mr-3">
              <PriceIndex order={order} />
            </strong>
            <AltIndexUnit order={order} />
          </div>
          {R.hasPath(
            ['_price', R.path(['_price', 'currency'], order), 'tax'],
            order
          ) && (
            <div className="value-item">
              <span className="item-label">
                <FormattedMessage
                  id="orderdetails-field-tax-label"
                  description="Label for 'Tax' field on Order Details"
                  defaultMessage="Tax"
                />
              </span>
              <strong className="mr-3">
                {R.pathOr(
                  'n/a',
                  ['_price', order._price.currency, 'tax'],
                  order
                )}
              </strong>
              <span className="cur-unit">
                {' '}
                {R.pathOr(
                  'n/a',
                  ['_price', order._price.currency, 'unit'],
                  order
                )}
              </span>
            </div>
          )}

          <div className="value-item">
            <div className="block">
              <span className="item-label">
                <FormattedMessage
                  id="orderdetails-field-volumeexecuted-label"
                  description="Label for 'Volume executed' field on Order Details"
                  defaultMessage="Volume executed"
                />
              </span>
              <strong className="mr-3">
                <FNumber
                  value={order.volume.executed}
                  decimalCount={order.volumeDecimalCount}
                />
              </strong>
              <span className="cur-unit">{volumeUnit}</span>
            </div>
            {order.quarteretd && order.volume.executed > 0 && (
              <>
                <span className="qrts inline-block border mt-7 ml--5 nowrap">
                  <FNumber
                    value={order.volume.executed / 3}
                    decimalCount={order.volumeDecimalCount}
                  />{' '}
                  <FormattedMessage
                    id="orderdetails-field-monthly-label"
                    description="Label for 'monthly' field on Order Details"
                    defaultMessage="monthly"
                  />
                </span>
              </>
            )}
          </div>

          <div className="value-item">
            <div className="block">
              <span className="item-label">
                <FormattedMessage
                  id="orderdetails-field-volumepending-label"
                  description="Label for 'Volume pending' field on Order Details"
                  defaultMessage="Volume pending"
                />
              </span>
              <strong className="mr-3">
                <FNumber
                  value={order.volume.pending}
                  decimalCount={order.volumeDecimalCount}
                />
              </strong>
              <span className="cur-unit">{volumeUnit}</span>
            </div>

            {order.quarteretd && order.volume.pending > 0 && (
              <>
                <span className="qrts inline-block border mt-7 ml--5 nowrap">
                  <FNumber
                    value={order.volume.pending / 3}
                    decimalCount={order.volumeDecimalCount}
                  />{' '}
                  <FormattedMessage
                    id="orderdetails-field-monthly-label"
                    description="Label for 'monthly' field on Order Details"
                    defaultMessage="monthly"
                  />
                </span>
              </>
            )}
            {order.totaldeliveries && order.volume.pending > 0 && (
              <>
                <span className="qrts inline-block border mt-7 ml--5 nowrap">
                  {order.totaldeliveries} &times;{' '}
                  <FNumber
                    value={order.volume.delivery}
                    decimalCount={order.volumeDecimalCount}
                  />{' '}
                  {order.deliveryfrequency}
                </span>
              </>
            )}

            <PrepopulateButtons order={order} />
          </div>
        </div>
      </div>

      <ul>
        {R.hasPath(['broker', 'name'], order) && (
          <Alert className="mb-20">
            <strong>
              <FormattedMessage
                id="orderdetails-field-managedbybroker-label"
                description="Label for 'Managed by Broker' field on Order Details"
                defaultMessage="Managed by Broker"
              />
            </strong>
            <br />
            {order.broker.name}
          </Alert>
        )}
        {data.map(([key, value, className]) => (
          <li key={key} className={className}>
            <strong>{key}</strong>
            <span>{value}</span>
          </li>
        ))}
      </ul>
    </>
  );
};
