import type * as Events from 'services.tenders.events';
import { Target, createEvent } from '~/eventutils';

const target = new Target();

function event<T>(name: string) {
  return createEvent<T>(target, name);
}

export const [onOverview, overview] = event<Events.Overview>('overview');
export const [onTender, tender] = event<Events.Tender>('tender');
export const [onOrder, order] = event<Events.Order>('order');
export const [onCounters, counters] = event<Events.Counters>('counters');
export const [onStarted, started] = event<Events.Started>('started');
export const [onExtended, extend] = event<Events.Extended>('extended');
export const [onFinished, finished] = event<Events.Finished>('finished');
export const [onSignout, signout] = event<Events.SignOut>('signout');
export const [onSolution, solution] = event<Events.Solution>('soluiton');
export const [onDeleteOrder, deleteOrder] =
  event<Events.DeleteOrder>('deleteorder');
export const [onDeleteCounter, deleteCounter] =
  event<Events.DeleteCounter>('deletecounter');
export const [onCancelTender, cancelTender] =
  event<Events.CancelTender>('canceltender');
export const [onReconnect, reconnect] = event<Events.Reconnect>('reconnect');
export const [onPlaceCounter, placeCounter] =
  event<Events.PlaceCounter>('placecounter');

export enum errortype {
  overviewload,
  overviewpoll,
  tenderpoll,
  tenderload,
}

interface ErrorEvt {
  type: errortype;
  data?: any;
}
export const [onError, error] = event<ErrorEvt>('error');
