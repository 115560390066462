import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Button, DatePicker } from 'Components/nui';
import { useTradesTable } from '~/store/models';
import TableFilter, { IFilterConfig } from 'Components/TableFilter';
import { useStoreState } from '~/store';
import R from 'ramda';
import { TradeStatus } from '~/const';
import FieldHolder from 'Components/nui/form/FieldHolder';
import DropCheck from 'Components/nui/form/DropCheck';
import { capitalize } from '~/utils';
import qs from 'qs';
import { api } from '~/api';
import { useToggle } from 'react-use';

interface IExportButton {
  filters: IFilterConfig<any>;
}

const ExportButton = ({ filters }: IExportButton) => {
  const [loading, toggle] = useToggle(false);
  const solution = useStoreState(state => state.auth.solution);

  const exportToCSV = async () => {
    toggle(true);
    const params = {
      ...filters.values(),
      solution: solution.id,
    };
    const url = `/trades?${qs.stringify(params, { indices: false })}`;
    await api.getFile(url, 'Trades.csv', 'text/csv');
    toggle(false);
  };

  return (
    <Button
      htmlType="button"
      size="small"
      type="secondary"
      className="export-button"
      loading={loading}
      onClick={exportToCSV}
    >
      <span className="icon-export"></span>
      <span className="show-for-large">Export to</span> CSV
    </Button>
  );
};

export default () => {
  const solution = useStoreState(state => state.auth.solution);
  const { pagination, filters, ...table } = useTradesTable();

  const disableOtherparty = solution.tenderlogic === 'purchase';

  const products = useStoreState(
    R.pathOr<any[]>([], ['auth', 'commodityFilteredProducts'])
  );

  const getCustomers = (): [string, string][] => {
    const customers = R.pipe(
      R.chain(R.propOr([], 'customers')),
      R.uniq,
      R.sortBy(R.prop('name')),
      R.map((c: any) => [c.id, c.name])
    )(products) as [string, string][];
    // if (!customers.length)
    //   return R.pipe(
    //     R.map(R.prop('owner')),
    //     R.uniq,
    //     R.sortBy(R.prop('name')),
    //     R.map((owner: any) => [owner.id, owner.name] as [string, string])
    //   )(products);
    // else return customers;

    // to be fixed
    return customers.length > 0 ? customers : [];
  };

  return (
    <div className="full-page-header">
      <h1 className="inline mt-10 mr-20 mb-0">Trades</h1>
      <div className="filter-export">
        <TableFilter
          filters={filters}
          total={pagination.total()}
          loading={table.loading()}
          extra={<ExportButton filters={filters} />}
        >
          <FieldHolder
            name="product"
            label="Show products"
            className="cb-set stack-9"
          >
            <DropCheck
              name="product"
              title="All products"
              options={products.map(p => [p.id, p.name])}
              value={filters.value('product')}
              onChange={filters.filter}
            />
          </FieldHolder>
          <FieldHolder name="status" label="Show status" className="cb-set">
            <DropCheck
              name="status"
              title="All"
              options={TradeStatus.map(s => [s, capitalize(s)])}
              value={filters.value('status')}
              onChange={filters.filter}
            />
          </FieldHolder>
          <FieldHolder name="created" label="Created between">
            <DatePicker.RangePicker
              name="created"
              format="DD MMM YYYY"
              value={filters.value('created')}
              onChange={value => {
                filters.filter('created', value);
              }}
            />
          </FieldHolder>
          <FieldHolder label="Estimated time of departure">
            <DatePicker.RangePicker
              name="etd"
              format="DD MMM YYYY"
              value={filters.value('etd')}
              onChange={value => {
                filters.filter('etd', value);
              }}
            />
          </FieldHolder>
          {!disableOtherparty && (
            <FieldHolder name="otherparty" label="Other party">
              <DropCheck
                name="otherparty"
                title="All"
                options={getCustomers()}
                value={filters.value('otherparty')}
                onChange={filters.filter}
              />
            </FieldHolder>
          )}
        </TableFilter>
      </div>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="trades" />
        </li>
        <li>Trades</li>
      </ul>
      <hr className="mb-40" />
    </div>
  );
};
