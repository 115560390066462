import React, { useContext } from 'react';
import classnames from 'classnames';
import { useLocalJsonStorage } from '~/hooks';

type ChildrenProp = Partial<Record<'children', React.ReactNode>>;
type NameProp = Record<'name', string>;
type TitleProp = Record<'title', string>;

type IVisibilityData = Record<string, boolean>;
interface IContext {
  fields: IVisibilityData;
  setField(name: string, value: boolean): void;
}

const Context = React.createContext<IContext | undefined>(undefined);

type IFieldVisibility = ChildrenProp & {
  initial: IVisibilityData;
  store: string;
};
export const FieldVisibility = ({
  store,
  initial,
  children,
}: IFieldVisibility) => {
  const [fields, setFields] = useLocalJsonStorage<IVisibilityData>(
    store,
    initial
  );

  return (
    <Context.Provider
      value={{
        fields,
        setField(name, value) {
          setFields(state => ({ ...state, [name]: value }));
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useFieldVisibility() {
  const context = useContext(Context);
  if (!context) throw new Error('FieldVisibility context is undefined');

  return context;
}

type IFieldVisible = NameProp & ChildrenProp;
export const FieldVisible = ({ name, children }: IFieldVisible) => {
  const { fields } = useFieldVisibility();

  if (fields[name] ?? true) return <>{children}</>;
  return null;
};

type IToggleVisibility = NameProp & TitleProp;
export const ToggleVisibility = ({ name, title }: IToggleVisibility) => {
  const { fields, setField } = useFieldVisibility();

  const state = fields[name] ?? true;

  return (
    <div className="fieldset check-fieldset pt-2 pb-2">
      <label htmlFor={name} className="ant-checkbox-wrapper" title={title}>
        <span
          className={classnames('ant-checkbox', {
            'ant-checkbox-checked': state,
          })}
        >
          <input
            type="checkbox"
            id={name}
            className="ant-checkbox-input"
            value={name}
            onChange={() => {
              setField(name, !state);
            }}
          />
          <span className="ant-checkbox-inner" />
        </span>
        <span>{title}</span>
      </label>
    </div>
  );
};

export const DummyFilter = () => (
  <div className="this-is-just-dummy-data nowrap">
    <strong className="all-black block mb-5">Display on summary</strong>
    <div className="fieldset check-fieldset pt-2 pb-2">
      <label
        htmlFor="show-field-etd"
        className="ant-checkbox-wrapper"
        title="Show ETD"
      >
        <span className="ant-checkbox">
          <input
            type="checkbox"
            id="show-field-etd"
            className="ant-checkbox-input"
            value="show-field-etd"
          />
          <span className="ant-checkbox-inner"></span>
        </span>
        <span>ETD</span>
      </label>
    </div>
  </div>
);
