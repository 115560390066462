import React, { useEffect, useState } from 'react';
import { Select } from 'Components/nui';
import R from 'ramda';
import { useFilterIndexData } from 'Pages/Dashboard';

export const scale = {
  '1d': {
    tickValues: 'every day',
    precision: 'day',
    format: '%d %b',
    tooltipFormat: 'DD MMMM',
    axisBottomLegend: 'Dates (grouped by a day)',
  },
  '1w': {
    tickValues: 'every 7 days',
    precision: 'day',
    format: '%d %b',
    tooltipFormat: 'DD MMMM',
    axisBottomLegend: 'Dates (grouped by a week)',
  },
  '1m': {
    tickValues: 'every month',
    precision: 'day',
    format: '%d %b',
    tooltipFormat: 'DD MMMM',
    axisBottomLegend: 'Dates (grouped by a month)',
  },
};

const rangesList = [
  ['ONE_MONTH', '1 month'],
  ['THREE_MONTHS', '3 months'],
  ['SIX_MONTHS', '6 months'],
  ['TWELVE_MONTHS', '1 year'],
];

const ranges = {
  ONE_MONTH: '1M',
  THREE_MONTHS: '3M',
  SIX_MONTHS: '6M',
  TWELVE_MONTHS: '12M',
};
export default () => {
  const [filter, setFilter] = useFilterIndexData();
  const [selected, setSelected] = useState('THREE_MONTHS');

  useEffect(() => {
    setFilter(state => ({
      ...state,
      chartPeriodFilter: R.pathOr(ranges.ONE_MONTH, [selected], ranges),
    }));
  }, [selected]);

  return (
    <div className="nui-fieldset">
      <label htmlFor="view-date-range">Viewing</label>
      <div className="select-holder">
        <Select id="view-date-range" value={selected} onChange={setSelected}>
          {rangesList.map(item => (
            <option value={item[0]} key={item[0]}>
              {item[1]}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};
