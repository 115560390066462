import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Link } from 'react-router-dom';
import { useSharedOrder } from '.';
import { FormattedMessage } from 'react-intl';

export default () => {
  const [order] = useSharedOrder();

  if (!order) return null;

  return (
    <>
      <h1 className="inline">
        <FormattedMessage
          id="vieworder-header"
          description="Header for View order"
          defaultMessage="View order <span>{pid}</span>"
          values={{
            span: chunks => <span className="smaller">{chunks}</span>,
            pid: order.pid,
          }}
        />
      </h1>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="orders" />
        </li>
        <li>
          <Link to="/orders" title="Back to the orders page">
            Orders
          </Link>
        </li>
        <li>View</li>
      </ul>
    </>
  );
};
