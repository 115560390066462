import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'Components/nui';
import R from 'ramda';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const RefPriceColumn = ColumnTree.defineNode('ref-price', {
  predicate({ solutionColumns }) {
    return R.path(['reference_price', 'type'], solutionColumns) === 'display';
  },
  make({ sortDirection, sortColumn, setSort }) {
    return {
      title: (
        <Tooltip
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled"></span>
              Reference price - Price per reference unit (eg Tonne) for price
              comparison across next pack sizes
            </span>
          }
        >
          <a
            href="#ref-price"
            className={classnames('icon-sort', 'sortem', {
              [sortDirection]: sortColumn === 'ref-price',
            })}
            onClick={() => setSort('ref-price')}
          >
            <FormattedMessage
              id="marketplace-table-heading-refprice"
              description="Table heading, abbreviation 'Reference price'"
              defaultMessage="Ref. price"
            />{' '}
            <span className="show-help inline-block" />
          </a>
        </Tooltip>
      ),
    };
  },
});
