import type * as Struct from 'struct';
import type * as Models from 'services.tenders.storage';
import type * as Report from 'models.tender.report';
import R from 'ramda';
import moment from 'moment-timezone';
import { store } from '~/store';
import { Base, Etd, Volume, Price, Value, Location, Attribute } from './utils';

const $access: Struct.SAccess = Symbol.for('access') as Struct.SAccess;

export class Tender extends Base<Models.Tender> {
  get [$access]() {
    const acl = this.state.__acl__;
    const analyse = this.owned && this.state.status === 'archived';

    return {
      edit: acl.edit && this.state.status === 'planning',
      delete: acl.delete,

      orders_add: acl.add.offers,
      participants_edit: acl.add.participants,

      publish: acl.publish,
      notify: acl.notify,
      finalise: acl.finalise,

      // Custom
      analyse,
    };
  }

  get division() {
    return this.state.division;
  }

  get owner() {
    return this.state.owner;
  }

  get type() {
    return this.state.type;
  }

  get name() {
    return this.state.name;
  }

  get method() {
    return this.state.method;
  }

  get methodTitle() {
    return this.state.method_desc;
  }

  get notified() {
    if (this.state.notified) return moment(this.state.notified);
    return null;
  }

  get start() {
    return moment(this.state.start);
  }

  get finish() {
    return moment(this.state.finish);
  }

  get extended() {
    return this.finish.add(this.state.extramins, 'minutes');
  }

  get target() {
    const d = this.state.delta ?? 0;
    const delta = d > 1 ? Math.round(d) : 0;

    if (
      this.state.finalised ||
      this.state.finished ||
      this.state.status === 'planning'
    )
      return undefined;

    if (this.state.started) return this.extended.add(delta, 'seconds');

    return this.start.add(delta, 'seconds');
  }

  get finalised() {
    if (this.state.finalised) return moment(this.state.finalised);
    return undefined;
  }

  get duration() {
    return moment.duration(this.finish.diff(this.start));
  }

  get extramins() {
    return this.state.extramins;
  }

  get owned() {
    return this.state.is_owner;
  }

  get orders() {
    return this.state.orders;
  }

  get participating() {
    return !this.state.is_owner;
  }

  get participants() {
    if (this.state.participants) {
      const { total, excluded } = this.state.participants;
      return { total, excluded, included: total - excluded };
    }

    return undefined;
  }

  get delivery() {
    if (this.state.type === 'sell') return 'from';
    return 'to';
  }

  get files() {
    if (this.state.files) return this.state.files;
    return undefined;
  }

  isRunning() {
    return this.state.started && !this.state.finished;
  }

  ended() {
    return this.state.finished;
  }

  started() {
    return this.state.started;
  }

  willStart() {
    return this.state.status === 'active' && !this.state.started;
  }

  get status() {
    if (this.state.status === 'active') {
      if (this.isRunning()) return 'active';
      if (this.ended()) return 'finished';
      return 'published';
    }
    if (this.state.status === 'stopped') return 'cancelled';
    return this.state.status;
  }

  get ordertype() {
    if (this.state.type === 'sell')
      return { order: 'offer', counter: 'bid' } as const;
    return { order: 'bid', counter: 'offer' } as const;
  }

  // Use to rename the type of the order - e.g. orders/counter orders
  get ordername() {
    // return { order: 'order', counter: 'counter' };
    return this.ordertype;
  }

  get formdata() {
    const { name, method, start, finish } = this.state;
    return {
      name,
      method,
      start: moment(start),
      finish: moment(finish),
      attachments: this.files?.map(f => f.id),
    };
  }
}

export class Participant extends Base<Models.Participant> {
  get name() {
    return this.state.name;
  }

  get website() {
    return this.state.website;
  }

  get isapproved() {
    return this.state.isapproved;
  }

  get reason() {
    return this.state.reason;
  }

  get disabled() {
    return this.state.disabled;
  }

  get companyno() {
    return this.state.companyno;
  }

  get address() {
    return {
      country: {
        id: 'USA',
        region: 'Pacific',
        code: 'USA',
        name: 'United States',
      },
    };
  }

  get approved() {
    return this.state.isapproved;
  }

  get tenderAccess() {
    return this.state.ownerapproved;
  }
}

export class Order extends Base<Models.Order> {
  private bids: Models.Counter[];

  constructor(order: Models.Order, counters: Models.Counter[]) {
    super(order);
    this.bids = counters;
  }

  get [$access]() {
    const acl = this.state.__acl__;
    return {
      edit: acl.edit,
      delete: acl.delete,
      trade: acl.add.trades,
      bid: acl.add.bids,
    };
  }

  get pid() {
    return this.state.pid;
  }

  get index() {
    return this.state?.index;
  }

  get product() {
    return {
      ...this.state.product,
      ...store
        .getState()
        .auth.products.find(p => p.id === this.state.product.id)!,
    };
  }

  get counters() {
    return new CountersGroup(this.state, this.bids);
  }

  get ordertype() {
    return this.state.ordertype;
  }

  get etd() {
    const { deliveryfrequency: frequency, totaldeliveries: total } = this.state;
    if (frequency && total)
      return new Etd(this.state.etd, { frequency, total });
    return new Etd(this.state.etd);
  }

  get created() {
    return moment(this.state.created);
  }

  get updated() {
    return moment(this.state.updated);
  }

  get tradeable() {
    return this.state.tradeable;
  }

  get comment() {
    return this.state.comment;
  }

  get volume() {
    return new Volume(this.state.volume);
  }

  get stripvolume() {
    const { deliveryfrequency, totaldeliveries } = this.state;
    if (deliveryfrequency && totaldeliveries)
      return new Volume(this.state.volume, {
        deliveryfrequency,
        deliverytotal: totaldeliveries,
      });

    return undefined;
  }

  get loading() {
    return this.state.loading;
  }

  get startprice() {
    return new Price({
      ...this.state.startprice,
      index: this.state.index,
      indexdate: this.state.indexdate,
    });
  }

  get nextprice() {
    return new Price({
      ...this.state.startprice,
      val: this.state.startprice.nextprice,
      index: this.state.index,
      indexdate: this.state.indexdate,
    });
  }

  get reserve() {
    return {
      price: this.startprice.new({
        val: this.state.reserve_price,
      }),
      reserve_met: this.state.reserve_met,
    };
  }

  get showNextprice() {
    return this.state.startprice.nextprice !== null;
  }

  get value() {
    return new Value({
      product: this.state.product.id,
      price: this.state.startprice,
      volume: this.state.volume,
      loading: this.state.loading,
    });
  }

  get trades() {
    return new TradesGroup(this.state, this.bids);
  }

  get variants() {
    if (this.state.variants) {
      return this.state.variants.map(({ id, product, price }) => ({
        id,
        product,
        name: product.name,
        price: price && this.startprice.new(price),
        isBase: product.name === this.state.product.name,
      }));
    }
    return [];
  }

  get delivery() {
    if (this.state.ordertype === 'offer') return 'from';
    return 'to';
  }

  get location() {
    if (this.state.warehouse) return new Location(this.state.warehouse);
    return undefined;
  }

  get files() {
    if (this.state.files) return this.state.files;
    return undefined;
  }

  get indexedAttributes() {
    const attribs = this.state.attributes;
    if (attribs) {
      const pairs = R.toPairs(attribs).map(
        ([name, value]) => [name, new Attribute(name, value)] as const
      );

      return Object.fromEntries(pairs);
    }
    return undefined;
  }

  get futures() {
    return this.state.index?.futures;
  }

  get attributes() {
    const attribs = this.state.attributes;
    if (attribs) {
      return R.toPairs(attribs)
        .map(([name, value]) => new Attribute(name, value))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    return undefined;
  }

  get productionmonth() {
    return this.state.productionmonth;
  }

  get exportable() {
    return this.state.exportable;
  }

  get exportdocs() {
    return this.state.exportdocs;
  }

  get docs() {
    if (this.state.docs)
      return R.fromPairs(
        Object.entries(this.state.docs).map(([item, val]) => [
          item,
          val === 'yes',
        ])
      ) as Record<keyof Struct.OrderDocs, boolean>;
    return undefined;
  }

  get origins() {
    return this.state.origins;
  }

  get places() {
    return this.state.locations;
  }

  get incoterms() {
    return this.state.incoterms;
  }

  get buyers() {
    return !!this.state.buyers?.href;
  }

  get freightavailable() {
    return this.state.freightavailable;
  }

  get formdata() {
    const location = this.location?.id;

    const attributes = Object.entries(this.state.attributes || {}).map(
      ([name, value]) => [`attributes_${name}`, value[0].id] as const
    );

    return {
      ...this.state,
      fromaddr: location,
      toaddr: location,
      loading: this.state.loading.id,
      startprice: this.state.startprice.val,
      volume: this.state.volume.delivery_total ?? this.state.volume.val,
      etd: [this.etd.from, this.etd.to],
      frometd: this.etd.from,
      note: this.state.comment,
      origins: this.state.origins?.map(x => x.id)[0],
      locations: this.state.locations?.map(x => x.id)[0],
      incoterms: this.state.incoterms?.map(x => x.id)[0],
      productionmonth: this.state.productionmonth?.val,
      ...this.docs,
      ...Object.fromEntries(attributes),
    };
  }
}

export class Counter extends Base<Models.Counter> {
  private order: Models.Order;

  constructor(state: Models.Counter, order: Models.Order) {
    super(state);
    this.order = order;
  }

  get [$access]() {
    return {
      ...this.state.__acl__,
      view: true,
    };
  }

  get companybid() {
    return !!this.state.owncompany;
  }

  get isowner() {
    return this.state.isowner;
  }

  get isAuto() {
    return this.state.autobid;
  }

  get hasAuto() {
    return !R.isNil(this.state.price.autobid);
  }

  get rank() {
    return this.state.rank || 0;
  }

  get price() {
    return new Price({
      ...this.state.price,
      index: this.order.index,
      indexdate: this.order.indexdate,
    });
  }

  get autobid() {
    return this.price.new({ val: this.state.price.autobid });
  }

  get askingValue() {
    return new Value({
      product: this.order.product.id,
      price: this.price,
      volume: this.volume,
      loading: this.order.loading,
    });
  }

  get executedValue() {
    return this.price.new({
      val: 0,
      ...this.state.trade?.value,
      unit: this.state.trade?.value.currency,
    });
  }

  get displayedPrice() {
    if (this.state.price.displayed)
      return this.price.new({ ...this.state.price.displayed });
    return undefined;
  }

  // returns 1 or -1 depending on the direction of bidding
  get direction() {
    const isOffer = +(this.ordertype === 'bid');
    return 2 * isOffer - 1;
  }

  get volume() {
    return new Volume(this.state.volume);
  }

  get stripvolume() {
    const { deliveryfrequency, totaldeliveries } = this.order;
    if (deliveryfrequency && totaldeliveries)
      return new Volume(this.state.volume, {
        deliveryfrequency,
        deliverytotal: totaldeliveries,
      });

    return undefined;
  }

  get executed() {
    return this.volume.new({ val: this.volume.executed });
  }

  get variant() {
    if (this.state.variant) {
      const price = this.state.variant.price;
      return {
        id: this.state.variant.id,
        name: this.state.variant.product.name,
        product: this.state.variant.product,
        price: price && this.price.new(price),
        isBase: this.state.variant.product.name === this.order.product.name,
      };
    }
    return undefined;
  }

  get fromaddr() {
    if (this.ordertype === 'offer') return this.location;
    return undefined;
  }

  get toaddr() {
    if (this.ordertype === 'bid') return this.location;
    return undefined;
  }

  get created() {
    return moment(this.state.created);
  }

  get updated() {
    if (this.state.priceupdated) return moment(this.state.priceupdated);
    return undefined;
  }

  get comment() {
    return this.state.comment;
  }

  get ordertype() {
    return this.state.ordertype;
  }

  get location() {
    if (this.state.warehouse) return new Location(this.state.warehouse);
    return undefined;
  }

  get trade() {
    return this.state.trade;
  }

  get owner() {
    return this.state.division?.name;
  }

  get creator() {
    return this.state.creator?.fullname;
  }

  get incoterm() {
    return this.state.incoterm;
  }

  get exportdocs() {
    return this.state.exportdocs;
  }

  get exportable() {
    return this.state.exportable;
  }

  get heattreatedpallets() {
    return this.state.heattreatedpallets;
  }

  get exportcountry() {
    return this.state.exportcountry;
  }

  get callofftime() {
    return this.state.callofftime;
  }

  get formdata() {
    return {
      id: this.id,
      buyer: this.state.partners?.buyer?.id,
      consignee: this.state.partners?.consignee?.id,
      fromaddr: this.fromaddr?.id,
      toaddr: this.toaddr?.id,
      comment: this.state.comment,
      price: this.state.price.val,
      volume: this.stripvolume?.delivered?.val ?? this.state.volume.val,
      callofftime: this.state.callofftime,
      incoterms: this.state.incoterm,
      exportdocs: this.state.exportdocs,
      variant: this.state.variant?.id,
    };
  }
}

export class CountersGroup {
  private order: Models.Order;
  private counters: Models.Counter[];

  constructor(order: Models.Order, counters: Models.Counter[]) {
    this.order = order;
    this.counters = counters;
  }

  get [$access]() {
    return { add: this.order.__acl__.add.bids, view: true };
  }

  get() {
    return this.counters.map(c => new Counter(c, this.order));
  }

  map<T>(mapper: (counter: Counter) => T) {
    return this.get().map(mapper);
  }

  sort(compare: (a: Counter, b: Counter) => number) {
    return this.get().sort(compare);
  }

  filter(predicate: (x: Counter) => boolean) {
    return this.get().filter(predicate);
  }

  get length() {
    return this.counters.length;
  }

  get ranked() {
    return this.sort((a, b) => a.rank - b.rank);
  }

  get mine() {
    return this.filter(c => c.isowner)[0];
  }

  get best() {
    const ranked = this.ranked;
    if (ranked.length === 1 && !ranked[0].rank) return undefined;
    return ranked[0];
  }

  get autoprice() {
    const best = this.best;
    const mine = this.mine;

    if (!best || !mine)
      throw new Error('Cannot get autoprice because no counters are owned');

    const val =
      this.order.startprice.quickprice || this.order.startprice.nextprice;
    return mine.price.new({ val });
  }
}

class TradesGroup {
  private _order: Models.Order;
  private _counters: Models.Counter[];

  constructor(order: Models.Order, counters: Models.Counter[]) {
    this._order = order;
    this._counters = counters;
  }

  private get order() {
    return new Order(this._order, this._counters);
  }

  private get counters() {
    return this._counters.map(c => new Counter(c, this._order));
  }

  get all() {
    return this.counters
      .map(counter => counter.trade)
      .filter(<T>(x: T | undefined): x is T => !!x);
  }

  get length() {
    return this.all.length;
  }

  get value() {
    const val = this._order.index
      ? 0
      : this.counters.reduce(
          (acc, counter) => acc + (counter.executedValue.val || 0),
          0
        );
    return this.order.startprice.new({
      val,
      unit: this.order.startprice.currency,
      index: undefined,
      indexdate: undefined,
    });
  }

  get volume() {
    const val = this.counters.reduce(
      (acc, counter) => acc + counter.volume.executed,
      0
    );
    return this.order.volume.new({ val });
  }

  get wap() {
    const val =
      this.counters.reduce(
        (acc, counter) =>
          acc + counter.volume.executed * (counter.price.val || 0),
        0
      ) / this.volume.val;

    return this.order.startprice.new({ val });
  }
}

export class TenderReport {
  private state: Report.Result;

  constructor(state: Report.Result) {
    this.state = state;
  }

  private getValue(val: number = 0) {
    const currency = this.state.tender.trade_currency || '';
    return { val, currency, unit: currency, step: 0.01, rel: 1 };
  }

  private getPrice(val: number = 0, price_unit: string = '') {
    const currency = this.state.tender.trade_currency || '';
    return { val, currency, unit: price_unit, step: 0.0001, rel: 1 };
  }

  get info() {
    return {
      id: this.state.tender.id,
      name: this.state.tender.name,
      orders: this.state.tender.offers,
      counters: this.state.tender.bids,
      trades: this.state.tender.trades,
      duration: moment.duration(this.state.tender.duration, 'seconds'),
      extensions: this.state.tender.extensions,
      participants: this.state.tender.participants,
      viewers: this.state.tender.viewers,
      value: this.getValue(this.state.tender.trade_value),
      extvalue: this.getValue(this.state.tender.extension_value),
    };
  }

  get participants() {
    return Object.entries(this.state.participants)
      .map(([id, { bids, trade_value, trades, ...participant }]) => ({
        ...participant,
        id,
        counters: bids,
        trades: {
          count: trades,
          value: this.getValue(trade_value),
        },
      }))
      .sort((a, b) => {
        if (a.trades.value.val === b.trades.value.val)
          if (a.counters === b.counters)
            if (a.viewed === b.viewed) return a.name.localeCompare(b.name);
            else return +b.viewed - +a.viewed;
          else return b.counters - a.counters;
        return b.trades.value.val - a.trades.value.val;
      });
  }

  get orders() {
    return Object.values(this.state.offers)
      .map(
        ({
          exts,
          exts_value,
          bidders,
          bids,
          trades,
          trade_value,
          price_max,
          ...order
        }) => ({
          ...order,
          participants: Object.values(bidders).length + trades,
          counters: bids,
          trades: { count: trades, value: this.getValue(trade_value) },
          extensions: { count: exts, value: this.getValue(exts_value || 0) },
          price: this.getPrice(price_max, order.price_unit),
        })
      )
      .sort(
        (a, b) =>
          a.product.label.localeCompare(b.product.label) ||
          a.created_at?.localeCompare(b.created_at) ||
          a.id.localeCompare(b.id)
      );
  }

  get charts() {
    const history = this.state.bidding;
    const frequency = this.state.bid_frequency;
    return new TenderCharts({ history, frequency });
  }
}

interface IChartState {
  history: Report.Bidding;
  frequency: Report.BiddingFreq;
}
export class TenderCharts {
  private state: IChartState;

  private colors = [
    '#7abfda',
    '#fcb441',
    '#e0400a',
    '#056492',
    '#bfbfbf',
    '#1a3b69',
    '#ffe382',
    '#129cdd',
    '#ca6b4b',
    '#005cdb',
    '#f1b9a8',
    '#7893be',
    '#edb07a',
    '#418cf0',
  ];

  constructor(state: IChartState) {
    this.state = state;
  }

  private get nivoFmt() {
    return 'YYYY-MM-DDTHH:mm:ss';
  }

  private get history() {
    return this.state.history;
  }

  private get frequency() {
    return this.state.frequency;
  }

  private get_colour(index: number) {
    const l = this.colors.length;
    return this.colors[index % l];
  }

  get orders() {
    return this.history.map(o => o.key);
  }

  get options() {
    return this.history.map(o => [o.key, o.label] as [string, string]);
  }

  get prices() {
    return Object.fromEntries(
      this.history.map(({ key, data }) => [
        key,
        Math.max(...data.map(d => d.price)),
      ])
    );
  }

  get data() {
    return this.history
      .slice(0)
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ key, data, label }) => ({
        id: key,
        key,
        label,
        data: data.map(({ ts, price, ...rest }) => ({
          ...rest,
          label: moment(ts).format('LL'),
          x: moment(ts).format(this.nivoFmt),
          y: price,
        })),
      }));
  }

  get basic() {
    return this.history
      .slice(0)
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ key, data, label }, index) => ({
        id: key,
        key,
        label,
        color: this.get_colour(index),
        data: data
          .filter(x => x.increased)
          .map(({ ts, price, ...rest }) => ({
            ...rest,
            price,
            priceStr: price.toFixed(4),
            product: label,
            label: moment(ts).format('LL'),
            x: moment(ts).format(this.nivoFmt),
            y: price,
          })),
      }));
  }

  get norm() {
    const prices = this.prices;

    return this.history
      .slice(0)
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ key, data, label }) => ({
        id: key,
        key,
        label,
        data: data
          .filter(x => x.increased)
          .map(({ ts, price, ...rest }) => ({
            ...rest,
            label: moment(ts).format('LL'),
            x: moment(ts).format(this.nivoFmt),
            y: (price / prices[key]) * 100,
          })),
      }));
  }

  get freq() {
    return this.history
      .slice(0)
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ key, data, label }) => ({
        id: key,
        key,
        label,
        data: data.map(({ ts, ...rest }) => ({
          ...rest,
          label: moment(ts).format('LL'),
          x: moment(ts).format(this.nivoFmt),
          y: label,
        })),
      }));
  }

  get heat() {
    return this.frequency.map(({ id, label, ...rest }) => ({
      id: label,
      label: id,
      ...rest,
    }));
  }
}
