import React from 'react';
import { Tooltip } from 'Components/nui';
import R from 'ramda';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const ProductBrandColumn = ColumnTree.defineNode('product-brand', {
  predicate({ solutionSettings }) {
    return R.pathOr(false, ['brandlogos'], solutionSettings);
  },
  make({ productById }) {
    return [
      {
        title: <span className="icon-picture" title="Brand logo"></span>,
        key: 'brand',
        className: 'col-brand',
        render: (text, record, index) => {
          const product = productById(record.product.id);
          const href = R.path<string>(['logo', 'href'], product);
          if (!product || !href) return <div className="td-content" />;
          return (
            <div className="td-content">
              <Tooltip
                title={
                  <span className="img-tooltip">
                    <img src={href} alt={product.name} />
                  </span>
                }
              >
                <img src={href} alt={product.name} />
              </Tooltip>
            </div>
          );
        },
      },
    ];
  },
});
