import Base from '../Model';
import { ParticipantData } from '../modelTypes';
import moment from 'moment-timezone';
import { Address } from '../warehouses/Warehouse';
import { ACL, allowRoles, allowRolesIf } from 'Components/Authorised';

export default class Participant extends Base<ParticipantData> {
  old() {
    return this.state;
  }

  get _acl(): ACL {
    const canEdit = () => !this.viewOnly;

    return {
      view: allowRoles('guest', 'trader', 'manager'),
      add: allowRolesIf('trader', 'manager')(canEdit),
      edit: allowRolesIf('trader', 'manager')(canEdit),
      delete: allowRolesIf('trader', 'manager')(canEdit),
    };
  }

  get name() {
    return this.state.name;
  }
  // get shortcode() {
  //   return this.state.shortcode;
  // }
  get website() {
    return this.state.website;
  }
  get companyno() {
    return this.state.companyno;
  }
  get links() {
    return this.state.links;
  }

  get created() {
    return moment(this.state.created);
  }
  // get reference() {
  //   return this.state.reference;
  // }
  get address() {
    return new Address(this.state.address);
  }
  get viewOnly() {
    return this.state.viewonly;
  }
  get buyData() {
    return this.state.buydata;
  }
  get sellData() {
    return this.state.selldata;
  }
  get tradeRelation() {
    return this.state.traderelation;
  }
  get us() {
    const { updated, requested } = this.state.us;
    return {
      ...this.state.us,
      updated: updated ? moment(updated) : null,
      requested: requested ? moment(requested) : null,
    };
  }
  get them() {
    const { requested } = this.state.them;
    return {
      ...this.state.them,
      requested: requested ? moment(requested) : null,
    };
  }
  // get ourAccept() {
  //   const { updated, requested, paymentterm } = this.state.ouraccept;
  //   return {
  //     ...this.state.ouraccept,
  //     updated: updated ? moment(updated) : null,
  //     requested: requested ? moment(requested) : null,
  //     paymentTerm: paymentterm,
  //   };
  // }
  // get theirAccept() {
  //   const { requested, paymentterm } = this.state.theiraccept;
  //   return {
  //     val: this.state.theiraccept.val,
  //     requested: requested ? moment(requested) : null,
  //     paymentTerm: paymentterm,
  //   };
  // }
}
