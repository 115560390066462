import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'Components/nui';
import { SpecButtons } from 'Components/SpecButtons';
import moment from 'moment-timezone';
import R from 'ramda';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const ProductNameColumn = ColumnTree.defineNode(
  'product-name',
  ({
    sortDirection,
    sortColumn,
    setSort,
    productById,
    solutionDivisionIds,
    expandRow,
  }) => ({
    title: (
      <div>
        <a
          href="#products"
          className={classnames('icon-sort', 'sortem', {
            [sortDirection]: sortColumn === 'product',
          })}
          onClick={() => setSort('product')}
        >
          <FormattedMessage
            id="marketplace-table-subheading-productname"
            description="Table sub-heading"
            defaultMessage="Product name"
          />
        </a>
      </div>
    ),
    key: 'product',
    className: 'col-product-name-alt shaded-right',
    render: (text, record, index) => {
      const product = productById(record.product.id);

      const myCounter = R.find<any /* TODO: Fix type */>(
        item => item.division && solutionDivisionIds.includes(item.division.id)
      )(R.pathOr([], ['counters'], record));

      const broker = !!record.counters?.find(counter => !!counter.broker?.id);

      if (!product) {
        return (
          <div className="td-content">
            <h2>{R.path(['product', 'name'], record)}</h2>
          </div>
        );
      }

      return (
        <div className="td-content">
          <div className="market-icons">
            {record.counters && record.counters.length > 0 && (
              <Tooltip
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled" />
                    This order has {record.counters.length} counter
                    {record.counters.length > 1 && 's'}
                  </span>
                }
              >
                <a
                  className="has-counters"
                  href="#counter"
                  onClick={expandRow('counters', record.id)}
                >
                  {myCounter && <span className="my-counter" />}
                  {broker && <span className="broker" />}
                  {record.counters.length}
                </a>
              </Tooltip>
            )}

            {record.note && record.note.length > 0 && (
              <Tooltip
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled" />
                    This {record.type} has a comment
                  </span>
                }
              >
                <a
                  className="icon-comment has-comment"
                  href="#comment"
                  onClick={expandRow()}
                >
                  {''}
                </a>
              </Tooltip>
            )}

            {record.questions && record.questions.length > 0 && (
              <Tooltip
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled"></span>This{' '}
                    {record.type} has {record.questions.length} question
                    {record.questions.length > 1 && 's'}
                  </span>
                }
              >
                <a
                  className="has-qa"
                  href="#qa"
                  onClick={expandRow('qa', record.id)}
                >
                  Q&amp;A
                </a>
              </Tooltip>
            )}

            {record.attachments && record.attachments.length > 0 && (
              <Tooltip
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled"></span>
                    This {record.type} has {record.attachments.length}{' '}
                    attachment
                    {record.attachments.length > 1 && 's'}
                  </span>
                }
              >
                <a
                  className="icon-attach-circled is-bundle"
                  href="#attachments"
                  onClick={expandRow()}
                >
                  {''}
                </a>
              </Tooltip>
            )}
          </div>
          <h2>
            <Link to={`/products/${product.id}/quick`}>
              <Tooltip
                title={
                  <span className="icon-tooltip">
                    <span className="icon-info-circled"></span>View product
                    details in new window
                  </span>
                }
              >
                {product.name}
                <span className="nowrap">
                  &#65279;<span className="icon-link-ext-alt"></span>
                </span>
              </Tooltip>
            </Link>
          </h2>

          {R.hasPath(['owner', 'name'], product) && (
            <p>{R.pathOr('n/a', ['owner', 'name'], product)}</p>
          )}

          {record.expiry && (
            <p
              className={classnames('icon-clock', 'expiration', {
                'closing-day':
                  moment(record.expiry).diff(moment(), 'days') <= 1,
                'closing-hour':
                  moment(record.expiry).diff(moment(), 'hours') <= 1,
                'closing-now':
                  moment(record.expiry).diff(moment(), 'minutes') <= 10,
              })}
            >
              <FormattedMessage
                id="marketplace-table-cell-expiry"
                description="Expires {in 3 days}"
                defaultMessage="Expires {expiry}"
                values={{ expiry: moment().to(record.expiry) }}
              />
            </p>
          )}

          <SpecButtons product={product} />
        </div>
      );
    },
  })
);
