import React from 'react';
import classnames from 'classnames';

export default React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <textarea
      className={classnames('nui-textarea', className)}
      ref={ref}
      {...props}
    />
  );
});
