import React from 'react';
import { Alert } from 'Components/nui';
import FreightForm from '../FreightForm';

export default ({ product, customer, enabled, incoterm }) => {
  return (
    <div className="freight-rates">
      <h3 className="light">Freight rates</h3>

      {!enabled && (
        <Alert hasicon type="warning">
          Please activate the product for the customer before setting freight
          rates
        </Alert>
      )}
      {enabled && (
        <FreightForm
          product={product}
          customer={customer}
          incoterm={incoterm}
        />
      )}
    </div>
  );
};
