import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import {
  ColorSquare,
  NoDataAvailable,
  TooltipWrapper,
  useFilterTIData,
  useSharedTIData,
} from 'Pages/Dashboard';
import R from 'ramda';
import { Tooltip } from 'Components/nui';
import { useToggle } from 'react-use';

export default () => {
  const [filter] = useFilterTIData();
  const [data] = useSharedTIData();
  const [total, setTotal] = useState(0);

  const rawData = R.propOr([], 'product-totals', data);
  const [chartData, setChartData] = useState([]);
  const [loading, toggle] = useToggle(true);

  useEffect(() => {
    if (R.isEmpty(rawData)) return;

    let _total = 0;
    rawData.forEach(record => {
      _total += record.volume;
    });

    let thelist = rawData.map(record => ({
      id: record.key,
      label: record.label,
      value: record.volume,
      color: record.color,
      percent: _total > 0 ? ((record.volume * 100) / _total).toFixed(2) : '0',
    }));
    thelist = R.reverse(R.sortBy(x => x.value)(thelist));
    setChartData(thelist);
    setTotal(_total);
    toggle(false);
  }, [rawData]);

  return R.isEmpty(rawData) ? (
    !loading && (
      <div className="chart-container pie-chart-container">
        <h3>
          Volume traded by product
          <Tooltip
            className="icon-help-circled"
            placement="bottom"
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                Show product volumes traded over the selected timeframe.
              </span>
            }
          />
        </h3>
        <NoDataAvailable />
      </div>
    )
  ) : (
    <div className="chart-container pie-chart-container">
      <h3>
        Volume traded by product
        <Tooltip
          className="icon-help-circled"
          placement="bottom"
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              Show product volumes traded over the selected timeframe.
            </span>
          }
        />
      </h3>

      {
        <>
          <section>
            <div className="chart pie-chart">
              <ResponsivePie
                data={chartData}
                value="percent"
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.3}
                padAngle={0}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                colors={{ datum: 'data.color' }}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLabelsSkipAngle={15}
                arcLabel={item => `${Math.round(item.value)}%`}
                tooltip={({ datum }) => (
                  <TooltipWrapper>
                    <p className="nowrap p-0">
                      <span className="inline-block mt-2 mr-5">
                        <ColorSquare color={datum.data.color} />{' '}
                      </span>
                      <strong className="all-black">{datum.data.label}</strong>
                    </p>
                    <hr className="mt-2 mb-2" />
                    <p className="p-0">{datum.data.percent}%</p>
                    <p className="p-0">
                      {datum.data.value.toLocaleString()} {filter.uom}
                    </p>
                  </TooltipWrapper>
                )}
              />
            </div>

            <div className="chart-legend">
              <table className="table">
                <thead>
                  <tr>
                    <th colSpan="2">Product</th>
                    <th className="align-right pr-20">
                      Volume <span className="unit">({filter.uom})</span>
                    </th>
                    <th className="align-right">%</th>
                  </tr>
                </thead>
                <tbody>
                  {chartData.map(item => (
                    <tr key={item.id}>
                      <td>
                        <span className="block mt-2">
                          <ColorSquare color={item.color} />
                        </span>
                      </td>
                      <td>{item.label}</td>
                      <td className="align-right pr-20">
                        {item.value.toLocaleString()}
                      </td>
                      <td className="align-right">{item.percent}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <strong className="all-black">Total</strong>
                    </td>
                    <td className="align-right pr-20">
                      <strong className="all-black">
                        {total.toLocaleString()}
                      </strong>
                    </td>
                    <td className="align-right">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </>
      }
    </div>
  );
};
