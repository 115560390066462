import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';

export default () => (
  <div className="full-page-header">
    <h1 className="inline mt-10 mr-20 mb-0">Routes</h1>

    <ul className="nui-bc right pt-20">
      <li>
        <BreadcrumbRoot active="routes" />
      </li>
      <li>Routes</li>
    </ul>
    <hr className="mb-20" />
  </div>
);
