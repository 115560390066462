import * as React from 'react';
import Helmet from 'Components/Helmet';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import Table from './Table';
import { Link } from 'react-router-dom';
import { useStoreState } from '~/store';
import { Alert } from 'Components/nui';

export default () => {
  const auth = useStoreState(state => state.auth);
  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <div className="full-page-header">
        <h1 className="inline mt-10 mr-20 mb-0">Users</h1>

        <ul className="nui-bc right pt-20">
          <li>
            <BreadcrumbRoot active="users" />
          </li>
          <li>Users</li>
        </ul>
      </div>

      <div className="full-page-header">
        <hr className="mb-10 mt-10" />
        <div className="nav-add-item">
          {auth.hasRoleInAnySolution('manager') ? (
            <Link to="/users/add" className="button secondary">
              Add user
            </Link>
          ) : (
            <Alert hasicon type="info" className="">
              Please contact your manager or support to add or modify users and
              their roles
            </Alert>
          )}
        </div>
      </div>

      <div className="full-page-content">
        <div className="users-table">
          <div className="generic-table light mt-0">
            <div className="nui-table table-wrapper th-sticky">
              <Table />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
