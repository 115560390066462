import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserAvatar } from 'Components/UserAvatar';
import SolutionPicker from 'Components/SolutionPicker';
import { useStoreState } from 'easy-peasy';
import SignOutModal from './SignOutModal';
import { useToggle } from 'react-use';
import moment from 'moment-timezone';
import Tooltip from 'Components/nui/Tooltip';
import classNames from 'classnames';
import { useTimezone } from '~/timezone';
import { toast } from 'react-toastify';
import { Button } from 'Components/nui';
import Loading from 'Components/nui/Loading';

const Time = () => {
  const [time, setTime] = useState(moment());
  const storeTimeZone = useStoreState(state => state.auth.user.timezone);
  const deviceTimeZone = moment.tz.guess();
  const [loading, setLoading] = useState(false);

  const timezone = useTimezone();

  const handleClick = async () => {
    setLoading(true);
    const { success } = await timezone.change(deviceTimeZone);
    setLoading(false);

    if (success)
      toast.success(
        `Your email notification timezone successfully updated to your device timezone (${deviceTimeZone})`
      );
    else toast.error('Could not update timezone.');
  };

  useEffect(() => {
    const tick = setInterval(() => void setTime(moment()), 30000);
    return () => void clearInterval(tick);
  }, []);

  return (
    <div className="timezone-wrapper">
      <div className="timezone">
        <span>
          <strong
            className={classNames('all-black', {
              mismatch: storeTimeZone !== deviceTimeZone,
            })}
          >
            Timezone
          </strong>
        </span>

        {storeTimeZone !== deviceTimeZone ? (
          <Tooltip
            placement="bottom"
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                Your email notification timezone ({storeTimeZone}) is different
                from your device time zone ({deviceTimeZone}).
                {loading ? (
                  <Loading size="small" />
                ) : (
                  <Button type="buttonlink" onClick={handleClick}>
                    Update to {deviceTimeZone}
                  </Button>
                )}
              </span>
            }
          >
            <span
              className={classNames({
                mismatch: storeTimeZone !== deviceTimeZone,
              })}
            >
              {deviceTimeZone}
            </span>
            <span className="icon-attention inline-block" />
          </Tooltip>
        ) : (
          <span className="">{deviceTimeZone}</span>
        )}
      </div>
      <div className="local-timezone">
        <span>
          <strong className="all-black">Local time</strong>
        </span>
        {time.format('h:mm a')}
      </div>
    </div>
  );
};

export default () => {
  const user = useStoreState(state => state.auth.userModel);
  const solution = useStoreState(state => state.auth.solution);
  const solutions = useStoreState(state => state.auth.solutions);

  const [on, toggle] = useToggle();

  /**Bond please change the className whatever you want */

  return (
    <header className="header">
      <button className="icon-menu hide-for-medium">Menu</button>

      <div className="logobar inline valign-m no-ws">
        <Link to="/" className="nd inline-block">
          {solution.logo_wide ? (
            <>
              <img
                className="logo-home hide-for-large notr"
                src={solution.logo}
                alt="Home | Nui Markets"
              />
              <img
                className="logo-home show-for-large notr"
                src={solution.logo_wide}
                alt="Home | Nui Markets"
              />
            </>
          ) : (
            <img
              className="logo-home"
              src={
                solution.logo
                  ? solution.logo
                  : 'https://cream-media.nuimarkets.com/logo/nui.png'
              }
              alt="Home | Nui Markets"
            />
          )}
        </Link>
      </div>

      {solutions.length > 1 && <SolutionPicker />}

      <div className="acc account-area nowrap">
        <div id="account-area" className="user acc-user">
          <Time />
          <div className="account-details">
            <UserAvatar />
            <Link to="/account">
              <span className="name">{user._fullname}</span>
            </Link>
            <Link to="/account/solutions" className="solution-name">
              <span>{solution.desc}</span>
            </Link>
          </div>
        </div>

        <a
          href="#signout"
          title="Sign out"
          className="icon-logout signout nowrap"
          onClick={toggle}
        >
          <span>Sign out</span>
        </a>

        <SignOutModal on={on} toggle={toggle} />
      </div>
    </header>
  );
};
