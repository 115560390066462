import { useCallback } from 'react';
import { useStoreState, useStoreActions } from '~/store';
import useExpandedRows from '../useExpandedRows';
import { ColumnFactory } from '../../columns';
export * from './context';

export default function useTable<
  C extends Parameters<typeof ColumnFactory.make>[1]
>(columnTree: C) {
  const solution = useStoreState(state => state.auth.solution);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const isSolutionOwner = useStoreState(state => state.auth.isSolutionOwner);
  const productById = useStoreState(state => state.auth.productById);
  const solutionDivisionIds = useStoreState(
    store => store.auth.solutionDivisionIds
  );

  const sortColumn = useStoreState(state => state.market.sortColumn);
  const sortDirection = useStoreState(state => state.market.sortDirection);

  const setData = useStoreActions(actions => actions.market.setData);

  const expandedRows = useExpandedRows();

  const setSort = useCallback(
    columnKey => {
      if (columnKey === sortColumn) {
        setData({ sortDirection: sortDirection === 'down' ? 'up' : 'down' });
      } else {
        setData({ sortColumn: columnKey, sortDirection: 'down' });
      }
    },
    [setData, sortDirection, sortColumn]
  );

  const context = {
    solution,
    solutionSettings,
    productById,
    setSort,
    solutionDivisionIds,
    sortColumn,
    sortDirection,
    solutionColumns,
    isSolutionOwner,
    ...expandedRows,
  };

  return {
    ...context,
    columns: ColumnFactory.make(context, columnTree),
  } as const;
}
