import React, { useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import R from 'ramda';
import { useClickAway, useToggle } from 'react-use';
import classNames from 'classnames';
import { Button, Checkbox } from 'Components/nui';

const Dropdown = () => {
  const fProducts = useStoreState(state => state.market.fProducts);
  const setData = useStoreActions(action => action.market.setData);

  const solution = useStoreState(state => state.auth.solution);
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const products = useStoreState(state =>
    state.auth.marketplaceProducts(solution.id)
  );

  const productsDict = R.fromPairs(products.map(p => [p.id, p]));
  const ids = R.keys(productsDict);
  const ownersDict = R.mergeAll(
    products.map(p => ({
      [R.pathOr('ALL000', ['owner', 'shortcode'], p)]: R.pathOr(
        'all',
        ['owner', 'name'],
        p
      ),
    }))
  );
  const groups = R.compose(
    R.map(([shortcode, products]) => ({
      key: shortcode,
      value: products.map(x => x.id),
    })),
    R.toPairs,
    R.groupBy(p => R.path(['owner', 'shortcode'], p))
  )(products);

  const onChange = (status, ids) => {
    status
      ? setData({ fProducts: R.uniq(R.concat(ids, fProducts)) })
      : setData({ fProducts: R.without(ids, fProducts) });
  };

  const flength = fProducts.length;

  return (
    <div className="options visible">
      <fieldset className="parent-all">
        <legend className="offset">Select products</legend>
        {groups.length > 1 && (
          <div className="fieldset check-fieldset product-filter all">
            <Checkbox
              indeterminate={flength > 0 && flength !== ids.length}
              checked={flength > 0}
              onChange={e => onChange(e.target.checked, ids)}
            >
              All products
            </Checkbox>
          </div>
        )}
        {groups.map(group => {
          const intersection = R.intersection(group.value, fProducts);
          return (
            <div
              className="fieldset check-fieldset parent product-filter"
              key={group.key}
            >
              <Checkbox
                indeterminate={
                  intersection.length > 0 &&
                  intersection.length !== group.value.length
                }
                checked={intersection.length > 0}
                onChange={e => onChange(e.target.checked, group.value)}
              >
                {R.pathOr('product', ['matchinglevel'], solutionSettings) ===
                'product'
                  ? R.path([group.key], ownersDict)
                  : 'All products'}
              </Checkbox>

              <fieldset className="fieldset child">
                <legend className="offset">
                  {R.path([group.key], ownersDict)} products
                </legend>

                {group.value.map(id => (
                  <div className="fieldset check-fieldset p-0" key={id}>
                    <Checkbox
                      value={id}
                      checked={fProducts.includes(id)}
                      onChange={e =>
                        onChange(e.target.checked, [e.target.value])
                      }
                    >
                      {R.path([id, 'name'], productsDict)}
                    </Checkbox>
                  </div>
                ))}
              </fieldset>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default () => {
  const fProducts = useStoreState(state => state.market.fProducts);
  const solution = useStoreState(state => state.auth.solution);

  const products = useStoreState(state =>
    state.auth.marketplaceProducts(solution.id)
  );

  const ref = useRef(null);
  const [on, toggle] = useToggle(false);

  useClickAway(ref, () => {
    toggle(false);
  });

  return (
    <div
      ref={ref}
      className={classNames('dropcheck', {
        opened: on,
      })}
    >
      <Button
        htmlType="button"
        className="showoptions"
        title="Select products"
        onClick={toggle}
      >
        <span className="total">
          {fProducts.length === 0 ? (
            'No options selected'
          ) : (
            <>
              {fProducts.length === 1 ? (
                R.prop('name', R.find(R.propEq('id', fProducts[0]), products))
              ) : (
                <>
                  {fProducts.length === products.length
                    ? 'All selected'
                    : `${fProducts.length} options selected`}
                </>
              )}
            </>
          )}
        </span>
        <span className="arrow" />
      </Button>
      {on && <Dropdown toggle={toggle} />}
    </div>
  );
};
