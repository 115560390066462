export const areas = {
  details: {
    title: 'Route details',
    description: 'Set up basic route information',
    step: 1,
    previous: null,
    next: 'freight',
  },
  freight: {
    title: 'Freight',
    description: 'Set up route freights',
    step: 2,
    previous: 'details',
    next: null,
  },
};
