import * as React from 'react';
import Helmet from 'Components/Helmet';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Link } from 'react-router-dom';
import ViewContext from './ViewContext';
import Table from './Table';
import Filters from './Filters';

export default () => {
  return (
    <>
      <Helmet>
        <title>Customer requests</title>
      </Helmet>
      <div className="full-page-header">
        <h1 className="inline mt-10 mr-20 mb-0">Customer requests</h1>
        <ul className="nui-bc right pt-20">
          <li>
            <BreadcrumbRoot active="customer-requests" />
          </li>
          <li>Customer requests</li>
        </ul>
      </div>
      <ViewContext>
        <div className="full-page-header customer-request-filter mt--10">
          <hr className="m-0" />
          <Link
            to="/customer-requests/add"
            className="nui-button nui-secondary new-request"
          >
            New request
          </Link>
          <Filters />
        </div>
        <div className="full-page-content">
          <Table />
        </div>
      </ViewContext>
    </>
  );
};
