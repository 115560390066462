import React from 'react';
import R from 'ramda';
import { Attribute } from '~/models/utils';
import { capitalize } from '~/utils';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const AttributesColumn = ColumnTree.defineNode(
  'attribute',
  ({ solution }) => {
    const attributes = R.pathOr([], ['attributes'], solution) as Attribute[];
    if (!attributes) return [];
    return attributes.map(item => ({
      title: capitalize(item.name),
      children: [
        {
          title: '',
          key: item.name,
          className: `col-${item.name}`,
          render: (text, record, index) => (
            <div className="td-content">
              <span className="tiny-label">{capitalize(item.name)}</span>
              {R.pathOr('', ['attributes', item.name, 0, 'name'], record)}
            </div>
          ),
        },
      ],
    }));
  }
);
