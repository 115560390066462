import { useStoreState } from 'easy-peasy';
import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ children, ...props }) => {
  const solution = useStoreState(state => state.auth.solution);
  const name = solution ? solution.name : 'Nui Markets';

  return (
    <Helmet titleTemplate={`%s - ${name}`} defaultTitle={name} {...props}>
      {children}
    </Helmet>
  );
};
