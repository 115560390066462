import React from 'react';
import classnames from 'classnames';

type StepId = number | string;

// TODO create an API (through a context) to programmatically control stepper

interface WizardConfig {
  className: string;
}
export function Wizard({ className, ...props }: WizardConfig) {
  return <div className={classnames('wizard', className)} {...props} />;
}

interface StepConfig {
  stepId: StepId;
  title?: React.ReactNode;
  description?: React.ReactNode;
  disabled?: boolean;
  active?: boolean;
  completed?: boolean;
  children?: React.ReactNode;
  className: string;
}
export function WizardStep({
  stepId,
  title = '',
  description = '',
  disabled = false,
  active = false,
  completed = false,
  children = null,
  className = '',
  ...props
}: StepConfig) {
  return (
    <div
      className={classnames('wizard-step-block', className, {
        active,
        completed,
        disabled,
      })}
      {...props}
    >
      <div className="wizard-step">
        <h2>{title}</h2>
        <p className="description">{description}</p>
      </div>
      <div className="wizard-content">{children}</div>
      <hr className="dashed" />
    </div>
  );
}
