import * as api from './api';
import { IBanner } from '~/store/types';
import R from 'ramda';
import { Schema } from 'struct';
import { Moment } from 'moment-timezone';

export const API_BANNER_DATA_KEYS = [
  'action_text',
  'action_url',
  'content',
  'end',
  'ispublished',
  'start',
  'style',
  'subject',
  'solution',
] as const;

export const API_BANNER_KEYS = ['id', ...API_BANNER_DATA_KEYS] as const;

export type ApiBannerKey = typeof API_BANNER_KEYS[number];
export type ApiBannerDataKey = Exclude<ApiBannerKey, 'id'>;

export async function schema() {
  const result = await api.get<{ fields?: Schema }>({
    url: '/banners/schema',
  });
  return result;
}

export async function editSchema(data: Pick<IBanner, 'id'>) {
  const result = await api.get<{ fields?: Schema }>({
    url: `/banners/${data.id}/schema`,
  });
  return result;
}

type IList = {
  solutionId?: string;
  since?: Moment;
};
export async function list({
  solutionId,
  since,
}: IList | void | undefined = {}) {
  return api.get({
    url: solutionId ? `/banners?solution=${solutionId}` : '/banners',
  });
}

type IPollList = {
  solutionId?: string;
  since: Moment;
};
export async function pollList({ solutionId, since }: IPollList) {
  const config = {
    url: solutionId ? `/banners?solution=${solutionId}` : '/banners',
  } as const;
  return api.poll<IBanner[]>({
    ...config,
    since,
  });
}

export async function add(data: Omit<IBanner, 'id'>) {
  const res = await api.request({
    method: 'POST',
    url: '/banners',
    data,
  });
  return res as any;
}

export async function remove({ id }: Pick<IBanner, 'id'>) {
  return api.remove({
    method: 'DELETE',
    url: `/banners/${id}`,
  });
}

export async function update(
  update: Readonly<Omit<Pick<IBanner, ApiBannerKey>, 'ispublished'>>
) {
  const { id } = update;
  const banner = R.pick(API_BANNER_KEYS, update);
  return await api.modify<{ banner: IBanner }>({
    method: 'PUT',
    url: `/banners/${id}`,
    data: banner,
  });
}

export async function updateIsPublished(
  update: Readonly<Pick<IBanner, 'id' | 'ispublished'>>
) {
  const { id } = update;
  const banner = R.pick(API_BANNER_KEYS, update);
  return await api.modify<any>({
    method: 'PATCH',
    url: `/banners/${id}`,
    data: banner,
  });
}
