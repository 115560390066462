import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import ProfileForm from './ProfileForm';
import { useSiteTitle } from '~/hooks';
import Phone from './Phone';
import { Alert } from 'Components/nui';

export default () => {
  const user = useStoreState(state => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [result, setResult] = useState();

  useSiteTitle('Profile');

  const state = {
    loading,
    setLoading,
    result,
    setResult,
    error,
    setError,
  };

  return (
    <div className="wrapper">
      <div id="profile">
        <h2>Profile</h2>
        <h3 className="numbered outside nowrap">Contact details</h3>
        <p>
          <strong className="all-black">Email</strong>{' '}
          <span className="nowrap">{user.email}</span>
        </p>

        <Alert hasicon type="info" className="mb-20">
          This email address is associated with this account. Contact us at{' '}
          <a href="mailto:support@nuimarkets.com">support@nuimarkets.com</a> if
          you wish to change it.
        </Alert>

        <Phone />

        <hr />
        <h3 className="numbered outside nowrap">Personal details</h3>
        <ProfileForm user={user} submitState={state} />
      </div>
    </div>
  );
};
