import React, { useState, Fragment, useEffect } from 'react';
import classNames from 'classnames';
import { Checkbox, Form, Button, Modal, Popover } from 'Components/nui';
import DropCheck from 'Components/nui/form/DropCheck';
import { FormWrapper } from 'Components/form';
import {
  DateRangeField,
  DateField,
  SelectField,
  DecimalField,
  RadioSelectField,
} from 'Components/form/fields';
import {
  hasDateRange,
  MAX_SAFE_INTEGER,
  mergeSchema,
  normalizeChoices,
  strBoolIsTruthy,
} from 'Components/form/utils';
import { RadioButtonWidget } from 'Components/form/widgets';
import { LoadingState } from 'Components/Layout';
import { ClassicResult, ShowResult } from 'Components/Result';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { promisify } from 'es6-promisify';
import moment from 'moment-timezone';
import R from 'ramda';
import { useAsync } from 'react-use';
import { Product } from '~/models';
import FNumber from 'Components/FNumber';
import {
  parseSearch,
  getDecimalCount,
  capitalize,
  getExportLabel,
} from '~/utils';
import { PresetButton } from './PresetButton';
import { PresetDropdown } from './PresetDropdown';
import { api } from '~/api';
import Helmet from 'Components/Helmet';
import { useParams, Link } from 'react-router-dom';
import DisplayedPrices from 'Pages/Products/DisplayedPrices';
import FilesField from 'Components/form/FilesField';
import Variants from 'Pages/Tenders/Edit/Variants';
import { prepareInitialDataFromSchema } from '~/utils';
import { Radio } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from 'Components/nui';
import { inArrayIf } from '~/utils';
import { FormTotals } from './FormTotals';
import { getFreightIds, getAddrInfo, getAddrChoice } from '~/utils';

/**
 * TODO
 * - Describe workflow
 * - Product itself needs validation and error processing as well
 * - Prefetched and then staled product / product.loading can cause issues.
 */

/**
 * Steps:
 * Take getFormWrapper as example
 * 1. Define the schema list.
 * 2. Optional. Merge the schema with the schema from server-side.
 * 3. Feed the schema list to get formWrapper
 *
 * Check OrderForm
 * 4. Render the formWrapper to render the components (and bind them to underlying form).
 * 5. When submitting and client-side validation passed, collect the data from formWrapper
 */

const addrMatch = (addr, freightId) => {
  const [, , addrFreightInfo] = addr ?? [];

  return (
    !addr ||
    freightId === addrFreightInfo?.zone ||
    freightId === addrFreightInfo?.port
  );
};

const inOptions = (addrOptions, freightId) =>
  !addrOptions || addrOptions.includes(freightId);

const isOptionalRoute = (toaddrOptions, fromaddrOptions) => route =>
  inOptions(toaddrOptions, route.destination.id) &&
  inOptions(fromaddrOptions, route.source.id);

const isMatchingRoute = (toaddr, fromaddr) => route =>
  addrMatch(toaddr, route.destination.id) &&
  addrMatch(fromaddr, route.source.id);

const EtaLabel = () => (
  <FormattedMessage
    id="orderform-field-eta-label"
    description="Label for `ETA` field on new order form"
    defaultMessage="ETA(s)"
  />
);

export function useFormWrapper({
  form,
  product,
  initialData,
  schema,
  solution,
  orderType,
  hideTotals = false,
}) {
  const division = form.getFieldValue('division');
  const exportIntent = strBoolIsTruthy(form, schema, 'exportable');
  const exportdocsRequired = strBoolIsTruthy(form, schema, 'exportdocs');
  const heatTreatedPalletsRequired = strBoolIsTruthy(
    form,
    schema,
    'heattreatedpallets'
  );

  const fromaddr = form.getFieldValue('fromaddr');

  const toaddr = form.getFieldValue('toaddr');

  const totaldeliveries = form.getFieldValue('totaldeliveries') ?? 1;

  const volume = form.getFieldValue('volume') ?? 0;

  const totalVolume = totaldeliveries * volume;

  const hasFlatFee = solution?.exportprice?.flatfee;

  const [files, setFiles] = useState([]);
  const [expiryDays, setExpiryDays] = useState(0);
  const [prodModalVis, setProdModalVis] = useState(false);
  const tradeActions = useStoreState(state => state.auth.userTradeActions);

  const solutionColumns = useStoreState(state => state.auth.solutionColumns);
  const anonymousOrders = useStoreState(
    state => state.auth.solutionSettings?.anonymousorders
  );
  const { formatMessage } = useIntl();

  const [realBuyerID, setRealBuyerID] = useState();
  const [realSellerID, setRealSellerID] = useState();

  const [realBuyerUserChoices, setRealBuyerUserChoices] = useState([]);
  const [realSellerUserChoices, setRealSellerUserChoices] = useState([]);

  const [etdMode, setEtdMode] = useState(
    initialData.deliveryfrequency ? 'periodic' : 'etd'
  );

  useEffect(() => {
    if (!realBuyerID) {
      setRealBuyerUserChoices([]);
    } else {
      (async () => {
        try {
          const data = await api.getData2(`/client-users/${realBuyerID}`);
          setRealBuyerUserChoices(R.propOr([], 'choices', data));
        } catch (error) {
          setRealBuyerUserChoices([]);
        }
      })();
    }
  }, [realBuyerID, setRealBuyerUserChoices]);

  useEffect(() => {
    if (!realSellerID) {
      setRealSellerUserChoices([]);
    } else {
      (async () => {
        try {
          const data = await api.getData2(`/client-users/${realSellerID}`);
          setRealSellerUserChoices(R.propOr([], 'choices', data));
        } catch (error) {
          setRealSellerUserChoices([]);
        }
      })();
    }
  }, [realSellerID, setRealSellerUserChoices]);

  const docsChoices = [
    {
      key: 'yes',
      label: (
        <FormattedMessage
          id="orderform-field-docs-yes-label"
          description="Label for 'Yes' radio button on new order form"
          defaultMessage="Yes"
        />
      ),
      value: 'true',
    },
    {
      key: 'no',
      label:
        orderType === 'offer' ? (
          <FormattedMessage
            id="orderform-field-docs-no-label"
            description="Label for 'No'  radio button on new order form"
            defaultMessage="No"
          />
        ) : (
          <FormattedMessage
            id="orderform-field-docs-notrequired-label"
            description="Label for 'Not required' radio button on new order form"
            defaultMessage="Not required"
          />
        ),
      value: 'false',
    },
  ];

  useEffect(() => {
    setFiles([]);
  }, [initialData?.id]);

  // Some fields returned from API include choices as object, inside which the key is product id.
  function getChoicesByProduct({ choices }) {
    return normalizeChoices(
      Array.isArray(choices) ? choices : R.propOr([], product.id, choices)
    );
  }

  const getChoicesByDivision =
    ({ choices }) =>
    () => {
      const divisionId = form.getFieldValue('division');
      return normalizeChoices(
        Array.isArray(choices) ? choices : R.propOr([], divisionId, choices)
      );
    };

  const otherCurrencies = Object.keys(schema)
    .filter(key => R.startsWith('price_', key))
    .map(item => item.split('_')[1]);

  const [curVis, setCurVis] = useState(() =>
    R.fromPairs(
      otherCurrencies.map(item => [
        item,
        !!R.prop(`price_${item}`, initialData),
      ])
    )
  );

  const priceDecimalCount = getDecimalCount(product.step);
  const priceStep = product.step;

  const indexedprice =
    form.getFieldsValue(['indexedprice']).indexedprice === 'true';

  const loadingId = form.getFieldValue('loading');
  const splittableLoadings = R.pathOr([], ['loading', 'splittable'], schema);
  // form can get updated in render cycle, thus need to run this dynamically in field spec or render()
  const getLoading = () =>
    R.find(R.propEq('id', loadingId), R.propOr([], 'loading', product));

  const now = moment();

  // etd and quarters
  const hasPeriodic = R.has('deliveryfrequency', schema);
  const hasQuarters = R.has('quarters', schema);
  const etdLabel = hasQuarters ? (
    <FormattedMessage
      id="orderform-field-etdquarters-label"
      description="Label for `ETD...` field on new order form"
      defaultMessage="Estimated time of departure..."
    />
  ) : (
    <FormattedMessage
      id="orderform-field-etd-label"
      description="Label for `ETD` field on new order form"
      defaultMessage="Estimated time of departure"
    />
  );
  const hasEtd = R.has('frometd', schema);
  const etdField = new DateRangeField({
    name: 'etd',
    label: etdLabel,
    // When etd field is there and not filled, this field is required, otherwise follow schema.
    required: () => {
      const isQuarters = hasQuarters
        ? !form.getFieldValue('quarters')
        : !!R.path(['frometd', 'required'], schema);
      return isQuarters && etdMode === 'etd';
    },
    fromDate: now.clone(),
    tz: R.prop('timezone', solution),
    props: {
      onChange: v => {
        // XXX v here is not normalized and maybe empty (which is still OK though)
        if (hasQuarters && v) {
          form.setFieldsValue({ quarters: undefined });
        }
        setEtdMode('etd');
      },
    },
    initialValue: R.prop('etd', initialData),
  });

  const startdateField = new DateField({
    name: 'frometd',
    label: 'Start date',
    required: () => etdMode === 'periodic',
    tz: R.prop('timezone', solution),
    fromDate: now.clone(),
    props: {
      onChange(v) {
        form.setFieldsValue({
          frometd: v,
          toetd: v,
        });
        setEtdMode('periodic');
      },
    },
    initialValue: R.prop('frometd', initialData),
  });

  const deliveryfrequencyField = new SelectField({
    name: 'deliveryfrequency',
    label: 'Delivery frequency',
    required: () => etdMode === 'periodic',
    props: () => {
      return {
        onChange(v) {
          form.setFieldsValue({
            deliveryfrequency: v,
          });
          setEtdMode('periodic');
        },
      };
    },
    choices: normalizeChoices(
      R.pathOr([], ['deliveryfrequency', 'choices'], schema),
      false
    ),
    initialValue: R.prop('deliveryfrequency', initialData),
  });

  const totaldeliveriesField = new DecimalField({
    name: 'totaldeliveries',
    label: 'Total number of deliveries',
    format: '%d',
    min: 2,
    required: () => etdMode === 'periodic',
    initialValue: R.prop('totaldeliveries', initialData),
  });

  const quartersField = new SelectField({
    name: 'quarters',
    widget: RadioButtonWidget,
    choices: normalizeChoices(
      R.pathOr([], ['quarters', 'choices'], schema)
    ).filter(x => x.value !== 'range'),
    label: hasEtd ? (
      <FormattedMessage
        id="orderform-field-quarters-label"
        description="Label for `quarterly` field on new order form"
        defaultMessage="...or choose quarterly period"
      />
    ) : (
      etdLabel
    ),
    required: () =>
      hasEtd
        ? !hasDateRange(form.getFieldValue('etd'))
        : !!R.path(['quarters', 'required'], schema),
    props: {
      onChange: v => {
        if (hasEtd) {
          form.setFieldsValue({ etd: undefined });
        }
      },
    },
    initialValue: R.prop('quarters', initialData),
  });

  const deorcoField = new RadioSelectField({
    name: 'deorco',
    choices: [
      {
        key: 'counterparty',
        label: (
          <FormattedMessage
            id="orderform-field-privateparty-radiobutton-counterparty-label"
            description="Label for 'Counterparty' radio button on new order form"
            defaultMessage="Counterparty"
          />
        ),
        value: 'counterparty',
      },
      {
        key: 'destinations',
        label: (
          <FormattedMessage
            id="orderform-field-privateparty-radiobutton-destination-label"
            description="Label for 'Destination' radio button on new order form"
            defaultMessage="Destination"
          />
        ),
        value: 'destinations',
      },
    ],
    label: (
      <FormattedMessage
        id="orderform-field-generic-placeholder"
        description="Placeholder for any field on new order form"
        defaultMessage="Select"
      />
    ),
    required: true,
    initialValue:
      R.isNil(initialData.destinations) || R.isEmpty(initialData.destinations)
        ? 'counterparty'
        : 'destinations',
  });

  // Production Date
  const hasProductionDate = R.has('productiondate', schema);
  const productionDateField = new DateField({
    name: 'productiondate',
    label: (
      <FormattedMessage
        id="orderform-button-productiondate-label"
        description="Label for 'Production date' button on new order form"
        defaultMessage="Production date"
      />
    ),
    required: () => form.getFieldValue('age') !== 'fresh',
    tz: R.prop('timezone', solution),
    initialValue: R.prop('productiondate', initialData),
  });

  const realBuyerUserField = new SelectField({
    name: 'realbuyeruser',
    label: (
      <FormattedMessage
        id="orderform-field-buyer-user-label"
        description="label for 'Buyer's user' selector field on new order form"
        defaultMessage="Buyer's user"
      />
    ),
    choices: () => normalizeChoices(realBuyerUserChoices),
  });

  const realSellerUserField = new SelectField({
    name: 'realselleruser',
    label: (
      <FormattedMessage
        id="orderform-field-seller-user-label"
        description="label for 'Seller's user' selector field on new order form"
        defaultMessage="Seller's user"
      />
    ),
    choices: () => normalizeChoices(realSellerUserChoices),
  });

  // Not great but easier
  // This might not be an ideal abstraction, but putting logic in render is even worse.
  let fields = [
    {
      name: 'division',
      label: (
        <FormattedMessage
          id="orderform-field-division-label"
          description="label for 'Division' selector field on new order form"
          defaultMessage="Division"
        />
      ),
      choices: normalizeChoices,
    },
    {
      name: 'realparty',
      label: orderType === 'offer' ? 'Seller' : ' Buyer',
      choices: () => normalizeChoices(schema?.realparty?.choices),
      initialValue: schema?.realparty?.value,
      props: () => ({ disabled: schema?.realparty?.disabled }),
    },
    {
      name: 'realpartyuser',
      label: orderType === 'offer' ? "Seller's user" : "Buyer's user",
      choices: () => normalizeChoices(schema?.realpartyuser?.choices),
      initialValue: schema?.realpartyuser?.value,
    },
  ];

  if (orderType === 'bid') {
    fields.push({
      name: 'realbuyer',
      label: (
        <FormattedMessage
          id="orderform-field-buyer-label"
          description="label for 'Buyer' selector field on new order form"
          defaultMessage="Buyer"
        />
      ),
      choices: normalizeChoices,
      props: {
        onChange: x => {
          setRealBuyerID(x);
        },
      },
      initialValue: schema?.realparty?.value,
    });
    if (realBuyerID) {
      fields.push({
        render: () => <>{realBuyerUserField.render({ form })}</>,
        serializeField: values => ({
          realbuyeruser: realBuyerUserField.serialize(values.realbuyeruser),
        }),
      });
    }
    if (form.getFieldValue('realbuyer')) {
      fields.push({
        name: 'realseller',
        label: (
          <FormattedMessage
            id="orderform-field-seller-label"
            description="label for 'Seller' selector field on new order form"
            defaultMessage="Seller"
          />
        ),
        choices: normalizeChoices,
        props: {
          onChange: x => {
            setRealSellerID(x);
          },
        },
      });
      if (realSellerID) {
        fields.push({
          render: () => <>{realSellerUserField.render({ form })}</>,
          serializeField: values => ({
            realselleruser: realSellerUserField.serialize(
              values.realselleruser
            ),
          }),
        });
      }
    }
  }

  if (orderType === 'offer') {
    fields.push({
      name: 'realseller',
      label: (
        <FormattedMessage
          id="orderform-field-seller-label"
          description="label for 'Seller' selector field on new order form"
          defaultMessage="Seller"
        />
      ),
      choices: normalizeChoices,
      props: {
        onChange: x => {
          setRealSellerID(x);
        },
      },
    });
    if (realSellerID) {
      fields.push({
        render: () => <>{realSellerUserField.render({ form })}</>,
        serializeField: values => ({
          realselleruser: realSellerUserField.serialize(values.realselleruser),
        }),
      });
    }
    if (form.getFieldValue('realseller')) {
      fields.push({
        name: 'realbuyer',
        label: (
          <FormattedMessage
            id="orderform-field-buyer-label"
            description="label for 'Buyer' selector field on new order form"
            defaultMessage="Buyer"
          />
        ),
        choices: normalizeChoices,
        props: {
          onChange: x => {
            setRealBuyerID(x);
          },
        },
      });
      if (realBuyerID) {
        fields.push({
          render: () => <>{realBuyerUserField.render({ form })}</>,
          serializeField: values => ({
            realbuyeruser: realBuyerUserField.serialize(values.realbuyeruser),
          }),
        });
      }
    }
  }

  if (schema.attributes) {
    const sep = schema.attributes.separator;
    Object.entries(schema.attributes.fields)
      .sort(([a], [b]) => a.localeCompare(b))
      .forEach(([name, spec]) => {
        fields.push({
          ...spec,
          name: ['attributes', name].join(sep),
          label: capitalize(name),
          choices: spec.choices
            .map(([value, label]) => ({
              key: value,
              value,
              label,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
          psuedo: true,
          props: {
            placeholder: (
              <FormattedMessage
                id="orderform-field-attributes-placeholder"
                description="Placeholder for `{name}` selector field on new order form"
                defaultMessage="Select {attribute}"
                values={{ attribute: name }}
              />
            ),
          },
        });
      });
  }

  fields.push(
    {
      name: 'currency',
      label: (
        <FormattedMessage
          id="orderform-field-currency-label"
          description="Label for 'Currency' field on new order form"
          defaultMessage="Currency"
        />
      ),
      choices: normalizeChoices,
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-currency-placeholder"
            description="Placeholder for 'Select currency' selector field on new order form"
            defaultMessage="Select currency"
          />
        ),
      },
    },
    {
      render: () => {
        if (!hasEtd && !hasQuarters) return null;
        const etdNodes = () =>
          etdMode === 'etd' && (
            <Fragment key="form-item-etd-fragment">
              {hasEtd && etdField.render({ form })}
              {hasQuarters && quartersField.render({ form })}
            </Fragment>
          );
        return !hasPeriodic ? (
          etdNodes()
        ) : (
          <div
            key="form-item-etd-fragment"
            className={classNames(
              'ant-row ant-form-item etd-price-volume',
              `etd-mode-${etdMode}`
            )}
          >
            <p className="title">Delivery details</p>
            <p className="description">
              Add an estimated time of departure or a strip order
            </p>
            <Radio.Group
              className="form-etd-group"
              value={etdMode}
              onChange={e => {
                const { value } = e.target;
                if (!['etd', 'periodic'].includes(value)) return;
                if (etdMode === value) return;
                setEtdMode(value);
              }}
            >
              <Radio
                className="form-etd-etd-radio"
                name="delivery-type"
                value="etd"
              >
                <div className="form-etd-etd-radio-label">{etdLabel}</div>
              </Radio>
              <div className="form-etd-etd">{etdNodes()}</div>
              {hasPeriodic && (
                <>
                  <Radio
                    className="form-etd-periodic-radio"
                    name="delivery-type"
                    value="periodic"
                  >
                    <div className="form-etd-periodic-radio-label">
                      Strip order
                      <Popover
                        overlay={
                          <span>
                            A strip order is the buying or selling of contracts
                            in sequential delivery periods traded as a single
                            transaction.
                          </span>
                        }
                      >
                        <span className="popover-link">?</span>
                      </Popover>
                    </div>
                  </Radio>
                  {etdMode === 'periodic' && (
                    <div className="form-etd-periodic">
                      <div className="form-etd-periodic-start">
                        {startdateField.render({ form })}
                      </div>
                      <div className="form-etd-periodic-period">
                        {deliveryfrequencyField.render({ form })}
                        {totaldeliveriesField.render({ form })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </Radio.Group>
          </div>
        );
      },
      serializeField: values => {
        if (!hasEtd && !hasQuarters) return null;
        if (etdMode === 'etd') {
          const etd = etdField.serialize(values.etd);
          const quarters = quartersField.serialize(values.quarters);
          return {
            frometd: R.prop(0, etd),
            toetd: R.prop(1, etd),
            quarters: etd ? 'range' : quarters,
          };
        } else if (etdMode === 'periodic') {
          const deliveryfrequency = deliveryfrequencyField.serialize(
            values.deliveryfrequency
          );
          const totaldeliveries = deliveryfrequencyField.serialize(
            values.totaldeliveries
          );
          const startdate = startdateField.serialize(values.frometd);
          return {
            frometd: startdate,
            deliveryfrequency,
            totaldeliveries,
          };
        }
      },
    },
    {
      render: () => <hr key="random-hr-logging-warnings-and-pissing-me-of" />,
    },
    {
      name: 'loading',
      label: (
        <FormattedMessage
          id="orderform-field-loadingdetails-label"
          description="Label for 'Loading details' field on new order form"
          defaultMessage="Loading details"
        />
      ),
      choices: R.sortBy(R.prop('label'))(
        R.map(
          x => ({
            key: x.id,
            value: x.id,
            label: x.desc,
          }),
          R.propOr([], 'loading', product)
        )
      ),
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-loadingdetails-placeholder"
            description="Placeholder for 'Loading details' selector field on new order form"
            defaultMessage="Select loading details"
          />
        ),
        onChange: v => {
          if (!splittableLoadings.includes(v)) {
            form.setFieldsValue({ splittable: false });
          }
        },
      },
    },
    {
      render: () => (
        <p key="stop-logging-warnings" className="title p-0">
          Price and volume
        </p>
      ),
    },
    {
      name: 'indexedprice',
      label: 'Indexed price',
      required: true,
      initialValue: initialData.indexedprice ?? schema.indexedprice?.value,
      initialData: initialData.indexedprice ?? schema.indexedprice?.value,
      props: {
        disabled: schema.indexedprice?.disabled,
      },
    },
    ...(indexedprice
      ? [
          ...(product.index.type === 'Multiplier'
            ? [
                {
                  name: 'price',
                  label: <>Multiplier</>,
                  step: product.index.step,
                  initialValue: 1,
                  min: product.index.step,
                  max: MAX_SAFE_INTEGER,
                  required: true,
                },
                // TODO check with Alex
                {
                  name: 'startprice',
                  label: 'Start price (multiplier)',
                  step: product.index.step,
                  initialValue: 1,
                  min: product.index.step,
                  max: MAX_SAFE_INTEGER,
                  required: true,
                },
              ]
            : [
                {
                  name: 'price',
                  label: <>Modifier</>,
                  step: product.index.step,
                  min: -9999999,
                  required: true,
                },
                {
                  name: 'startprice',
                  label: <>Start price (modifier)</>,
                  step: product.index.step,
                  min: -9999999,
                  required: true,
                },
              ]),
          {
            name: 'indexdate',
            label: 'Index from',
            fromDate: now.clone(),
            initialValue: now.clone().add(1, 'weeks').day(1),
          },
          {
            render: () => <hr key="no-more-warnings-2" className="mt-5" />,
          },
        ]
      : [
          {
            name: 'price',
            label: (
              <>
                <FormattedMessage
                  id="orderform-field-price-label"
                  description="Label for `Price` field on new order form"
                  defaultMessage="Price"
                />{' '}
                <span className="normal">{product.getDesc(loadingId)}</span>
              </>
            ),
            step: product.step,
            min: R.has('openprice', schema) ? 0 : product.step,
          },
          {
            name: 'startprice',
            label: (
              <>
                <FormattedMessage
                  id="orderform-field-startprice-label"
                  description="Label for 'Start price' field on new order form"
                  defaultMessage="Start price"
                />{' '}
                <span className="normal">{product.getDesc(loadingId)}</span>
              </>
            ),
            initialValue: 0,
            step: product.step,
          },
          {
            name: 'reserve_price',
            label: (
              <>
                Reserve price
                <span className="normal">{product.getDesc(loadingId)}</span>
              </>
            ),
            step: product.step,
          },
        ]),
    {
      name: 'volume',
      label: (
        <>
          {etdMode === 'etd' ? (
            <FormattedMessage
              id="orderform-field-volume-label"
              description="Label for `Volume` field on new order form"
              defaultMessage="Volume"
            />
          ) : (
            <>Per delivery volume</>
          )}{' '}
          <span className="normal">
            {R.path(['loadingunit', 'desc'], product)}
          </span>
        </>
      ),
      step: () => R.propOr(1, 'qty', getLoading()),
      props: () => ({ disabled: !getLoading() || schema.volume?.disabled }),
    },
    {
      render: () => {
        if (hideTotals) return null;

        const { price } = form.getFieldsValue(['price']);

        return (
          <Form.Item key="form-item-value">
            <div className="p-0 totals">
              {!indexedprice &&
                solution.freight &&
                tradeActions.includes('sell') && (
                  <div className="right">
                    {prodModalVis && (
                      <Modal
                        close={() => setProdModalVis(false)}
                        defaultFooter
                        size="medium"
                      >
                        <DisplayedPrices
                          auto
                          productId={product.id}
                          init={{
                            price,
                            loading: loadingId,
                            fromaddr: form.getFieldValue('fromaddr'),
                          }}
                        />
                      </Modal>
                    )}
                    <Button
                      htmlType="button"
                      type="link"
                      className="nui-button customer-prices nui-reverse m-0 mt--5 pr-5"
                      onClick={() => setProdModalVis(true)}
                    >
                      <span>
                        <FormattedMessage
                          id="orderform-field-viewcustomerprices-label"
                          description="Label for `View customer prices` field on new order form"
                          defaultMessage="View customer prices"
                        />
                      </span>
                    </Button>
                  </div>
                )}
            </div>
          </Form.Item>
        );
      },
    },
    {
      name: 'counteronly',
      label: (
        <strong className="all-black">{capitalize(orderType)} type</strong>
      ),
      formItemProps: {
        className: 'form-item-vertical',
      },
      initialData: !!schema.counteronly?.value,
      initialValue: !!schema.counteronly?.value,
      choices: [
        { label: 'Directly tradeable (Firm)', value: 'false', key: 'no' },
        {
          label: 'Subject to final confirmation (SFC)',
          value: 'true',
          key: 'yes',
        },
      ],
    },
    {
      render: () => {
        const fields = form.getFieldsValue([
          'price',
          'startprice',
          'indexedprice',
          'indexdate',
          'volume',
          'totaldeliveries',
          'deliveryfrequency',
          'frometd',
          'loading',
        ]);

        const isTender = !R.isNil(schema.startprice);

        return (
          <FormTotals
            key="form-totals"
            product={product}
            fields={fields}
            isTender={isTender}
          />
        );
      },
    },
    {
      render: () => <hr key="no-more-warnings-1" />,
    }
  );

  // Other currencies

  if (otherCurrencies && otherCurrencies.length > 0) {
    fields.push({
      render: () => (
        <div className="currencies" key="currencies">
          <h3>
            Additional {otherCurrencies.length > 1 ? 'currencies' : 'currency'}
          </h3>
          <p className="smaller justify m-0">
            <FormattedMessage
              id="orderform-field-currencies-helptext-additionalcurrencies"
              description="Help text for `currencies` field on new order form"
              defaultMessage="The currency conversion rate is based on a daily average currency conversion feed."
            />
          </p>
        </div>
      ),
    });

    otherCurrencies.forEach(cur => {
      fields.push({
        render: () => (
          <div className="currency-option" key={`${cur}-vis`}>
            <Checkbox
              className="mb-0"
              checked={curVis[cur]}
              onChange={() =>
                setCurVis({ ...curVis, ...{ [cur]: !curVis[cur] } })
              }
            >
              <FormattedMessage
                id="orderform-field-currencies-helptext-pricein"
                description="Help text for `currencies` field on new order form"
                defaultMessage="Price in"
              />{' '}
              {cur}
            </Checkbox>
          </div>
        ),
      });

      if (curVis[cur]) {
        const newPriceField = new DecimalField({
          name: `price_${cur}`,
          step: product.step,
          min: R.has('openprice', schema) ? 0 : product.step,
          initialValue: R.prop(`price_${cur}`, initialData),
          required: true,
        });

        fields.push({
          render: () => {
            const curPrice = form.getFieldValue('price') || 0;
            const value = curPrice * schema[`price_${cur}`].exchange;
            return (
              <div className="currency-field" key={cur}>
                {newPriceField.render({ form })}
                <div className="currency-exchange">
                  <span>
                    <FormattedMessage
                      id="orderform-field-currencies-helptext-estimated"
                      description="Help text for `currencies` field on new order form"
                      defaultMessage="Estimated in this currency"
                    />{' '}
                  </span>
                  <strong>
                    {cur}{' '}
                    <FNumber
                      value={Math.round(value / priceStep) * priceStep}
                      decimalCount={priceDecimalCount}
                    />
                  </strong>
                </div>
              </div>
            );
          },
          serializeField: values => {
            if (!R.has(`price_${cur}`, schema)) return null;
            return {
              [`price_${cur}`]: newPriceField.serialize(values[`price_${cur}`]),
            };
          },
        });
      }
    });
    // }
    fields.push({
      render: () => <hr key="cur-bottom-line" className="mt-5 mb-20" />,
    });
  }

  fields = fields.concat([
    /**
     * For quarters & frometd/toetd, we could either:
     * - render them in same render(), or
     * - put them in standalone fields, or
     * - invent some combined field type for them
     * - TODO ListField, ObjectField and accepting array of names, in future maybe =p
     */
    {
      name: 'callofftime',
      label: 'Call off times',
    },
    {
      name: 'callofftime',
      label: (
        <FormattedMessage
          id="orderform-field-callofftimes-label"
          description="Label for 'Call off times' field on new order form"
          defaultMessage="Call off times"
        />
      ),
      required: () => {
        const _etd = form.getFieldValue('etd');
        if (!_etd) return false;
        return _etd[0].clone().add(28, 'days') <= _etd[1];
      },
      props: {
        placeholder: formatMessage({
          id: 'marketrow-tradeform-field-callofftimes-placholder',
          description:
            'Placeholder for `Call off times` field on tradeform in Marketrow',
          defaultMessage: 'Ex: 1 load in week 20 1 load in week 22',
        }),
      },
    },
    {
      name: 'origins',
      label: (
        <FormattedMessage
          id="orderform-field-origin-label"
          description="Label for 'Origin' field on new order form"
          defaultMessage="Origin"
        />
      ),
      choices: normalizeChoices,
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-origin-placeholder"
            description="Placeholder for Origins field on new order form"
            defaultMessage="Select Origin"
          />
        ),
      },
    },
    {
      name: 'incoterms',
      label: (
        <FormattedMessage
          id="orderform-field-incoterm-label"
          description="Label for 'Incoterm' field on new order form"
          defaultMessage="Incoterm"
        />
      ),
      // choices: normalizeChoices,
      choices: getChoicesByDivision,
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-incoterm-placeholder"
            description="Placeholder for Incoterms field on new order form"
            defaultMessage="Select Incoterm"
          />
        ),
      },
      // widget: RadioWidget,
    },
    {
      name: 'locations',
      label: (
        <FormattedMessage
          id="orderform-field-location-label"
          description="Label for 'Location' field on new order form"
          defaultMessage="Location"
        />
      ),
      choices: normalizeChoices,
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-location-placeholder"
            description="Placeholder for location field on new order form"
            defaultMessage="Select Location"
          />
        ),
      },
    },
    {
      name: 'regions',
      label: (
        <FormattedMessage
          id="orderform-field-region-label"
          description="Label for 'Regions' field on new order form"
          defaultMessage="Region"
        />
      ),
      choices: normalizeChoices,
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-region-placeholder"
            description="Placeholder for regions field on new order form"
            defaultMessage="Select Region"
          />
        ),
      },
    },
    {
      name: 'fromaddr',
      label: (
        <FormattedMessage
          id="orderform-field-deliveryfrom-label"
          description="Label for 'Delivery from' field on new order form"
          defaultMessage="Delivery from"
        />
      ),
      choices: getChoicesByDivision,
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-deliveryfrom-placeholder"
            description="Placeholder 'Delivery from' any field on new order form"
            defaultMessage="Select Address"
          />
        ),
      },
    },
    {
      name: 'warehouse',
      label: (
        <FormattedMessage
          id="orderform-field-warehouse-label"
          description="Label for 'Warehouse' field on new order form"
          defaultMessage="Warehouse"
        />
      ),
      choices: getChoicesByDivision,
    },
  ]);

  if ('destinations' in schema) {
    fields.push({
      render: () => (
        <Fragment key="deorco-start">
          <hr className="mt-5 mb-20" />
          <h3 className="mr-25">
            <FormattedMessage
              id="orderform-field-privatefilter-heading"
              description="heading  for 'Private filter' field on new order form"
              defaultMessage="Private filter"
            />
          </h3>
          <p className="smaller m-0">
            <FormattedMessage
              id="orderform-field-privatefilter-helptext"
              description="Help text for `Private filter` field on new order form"
              defaultMessage="Select counterparties below, or destination if you want to select
                    all counterparties in a particular country or region."
            />
          </p>
          {deorcoField.render({ form })}
        </Fragment>
      ),
    });

    const deorcoValue =
      form.getFieldValue('deorco') ||
      R.propOr('counterparty', 'deorco', initialData);

    fields.push(
      deorcoValue === 'destinations'
        ? {
            name: 'destinations',
            label: (
              <FormattedMessage
                id="orderform-field-destination-label"
                description="Label for 'Destination' field on new order form"
                defaultMessage="Destination"
              />
            ),
            choices: getChoicesByProduct,
          }
        : {
            name: 'counterparty',
            label: (
              <FormattedMessage
                id="orderform-field-counterparty-label"
                description="Label for 'Counterparty' field on new order form"
                defaultMessage="Counterparty"
              />
            ),
            choices: getChoicesByProduct,
          }
    );

    fields.push({
      render: () => (
        <Fragment key="deorco-end">
          <hr className="mt-5 mb-20" />
        </Fragment>
      ),
    });
  } else {
    fields = fields.concat([
      {
        name: 'counterparty',
        label: (
          <FormattedMessage
            id="orderform-field-counterparty-label"
            description="Label for 'Counterparty' field on new order form"
            defaultMessage="Counterparty"
          />
        ),
        choices: getChoicesByProduct,
      },
    ]);
  }

  fields = fields.concat([
    {
      name: 'toaddr',
      label: (
        <FormattedMessage
          id="orderform-field-deliveryto-label"
          description="Label for 'Delivery to' field on new order form"
          defaultMessage="Delivery to"
        />
      ),
      choices: getChoicesByDivision,
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-deliveryto-placeholder"
            description="Placeholder for 'deliveryto' field on new order form"
            defaultMessage="Select Address"
          />
        ),
      },
    },
    {
      render: () => {
        const etaFreightType = ['port', 'zone'];
        const etd = form.getFieldValue('etd');

        if (
          orderType === 'bid' &&
          toaddr &&
          etaFreightType.includes(schema?.toaddr?.freight) &&
          etd &&
          solution.shippingroutes &&
          schema?.toaddr?.choices
        ) {
          const toaddrChoice = getAddrChoice(division)(schema.toaddr.choices);
          const fromaddrChoice =
            schema?.fromaddr?.choices &&
            getAddrChoice(division)(schema.fromaddr.choices);
          const toaddrInfo = getAddrInfo(toaddr)(toaddrChoice);
          const fromaddrInfo =
            fromaddrChoice && getAddrInfo(fromaddr)(fromaddrChoice);

          const toaddrFreightIds = getFreightIds(toaddrChoice);
          const fromaddrFreightIds =
            fromaddrChoice && getFreightIds(fromaddrChoice);

          const optionalRoutes = R.filter(
            isOptionalRoute(toaddrFreightIds, fromaddrFreightIds),
            solution.shippingroutes
          );

          const matchingRoutes = R.filter(
            isMatchingRoute(toaddrInfo, fromaddrInfo),
            optionalRoutes
          );
          if (!matchingRoutes.length) {
            return (
              <Alert
                className="mb-10"
                type="info"
                key={`from-${fromaddr}-to-${toaddr}`}
              >
                <EtaLabel />
                <span className="block ">No shipping routes found</span>
              </Alert>
            );
          }
          const sortedRoutes = matchingRoutes.sort(
            (route1, route2) => route1.duration - route2.duration
          );
          return (
            <Alert
              className="mb-10"
              type="info"
              key={`from-${fromaddr}-to-${toaddr}`}
            >
              <EtaLabel />

              {sortedRoutes.map(route => (
                <div key={route.id} className="mb-10">
                  <small>
                    From {route.source.name} To {route.destination.name}
                  </small>
                  <div>
                    <strong className="etd">
                      {moment(etd[1].clone())
                        .add(route.duration, 'day')
                        .format('DD MMM YYYY')}
                    </strong>{' '}
                    <span>
                      (ETD + {route.duration} day
                      {route.duration > 1 && 's'})
                    </span>
                  </div>
                </div>
              ))}
            </Alert>
          );
        }

        return null;
      },
    },
    {
      name: 'anonymous',
      label: 'Hide my details on order',
      choices: [
        {
          label: 'Yes',
          value: 'true',
          key: 'yes',
        },
        { label: 'No', value: 'false', key: 'no' },
      ],
      initialValue: anonymousOrders,
      type: 'Boolean',
      required: true,
    },
    {
      name: 'freightavailable',
      label: (
        <FormattedMessage
          id="orderform-field-additionalfreight-label"
          description="Label for 'Additional freight' field on new order form"
          defaultMessage="Additional freight"
        />
      ),
      choices: docsChoices,
    },

    {
      name: 'gfsi',
      label: solutionColumns?.gfsi?.label || (
        <FormattedMessage
          id="orderform-field-gfsicertification-label"
          description="Label for 'GFSI certification' field on new order form"
          defaultMessage="GFSI certification"
        />
      ),
      choices: docsChoices,
    },
    {
      name: 'export',
      label: solutionColumns?.export?.label || (
        <FormattedMessage
          id="orderform-field-exportdocuments-label"
          description="Label for 'Export documents' field on new order form"
          defaultMessage="Export documents"
        />
      ),
      choices: docsChoices,
    },
    {
      name: 'halal',
      label: solutionColumns?.halal?.label || (
        <FormattedMessage
          id="orderform-field-halaldocuments-label"
          description="Label for 'Halal documents' field on new order form"
          defaultMessage="Halal documents"
        />
      ),
      choices: docsChoices,
    },
    {
      name: 'kosher',
      label: solutionColumns?.kosher?.label || (
        <FormattedMessage
          id="orderform-field-kosherdocuments-label"
          description="Label for 'Kosher documents' field on new order form"
          defaultMessage="Kosher documents"
        />
      ),
      choices: docsChoices,
    },
    {
      name: 'expiry',
      fromDate: now.clone(),
      initialValue: now.clone().add(7, 'day').startOf('hour'),
      minuteStep: 15,
      props: {
        showToday: false,
        onChange: v => {
          setExpiryDays(v ? v.diff(now.clone(), 'days') : 0);
        },
      },
      label: (
        <>
          <FormattedMessage
            id="orderform-field-validuntil-label"
            description="Label for 'Valid until' field on new order form"
            defaultMessage="Valid until"
          />
          {expiryDays > 7 && (
            <span className="icon-attention-circled expiry-warning">
              <FormattedMessage
                id="orderform-field-validuntil-helptext"
                description="Help text for 'Valid until' field on new order form"
                defaultMessage="Expires in {expiryDays} days"
                values={{ expiryDays: expiryDays }}
              />
            </span>
          )}
        </>
      ),
    },
    {
      name: 'brands',
      label: (
        <FormattedMessage
          id="orderform-field-brand-label"
          description="Label for 'Brand' field on new order form"
          defaultMessage="Brand"
        />
      ),
      choices: getChoicesByProduct,
    },

    {
      name: 'productionmonth',
      label: 'Production month',
      choices: normalizeChoices(schema.productionmonth?.choices)?.sort((a, b) =>
        a.key.localeCompare(b.key)
      ),
    },

    {
      name: 'exportable',
      label: (
        <>
          {getExportLabel(orderType, 'exportable')(solutionColumns) ?? (
            <FormattedMessage
              id="orderform-field-exportable-label"
              description="Label for 'Exportable' field on new order form"
              defaultMessage="Exportable"
              values={{
                span: chunks => (
                  <span className="normal warning">{chunks}</span>
                ),
              }}
            />
          )}
        </>
      ),
      initialValue: schema?.exportable?.value,
      choices: hasFlatFee
        ? [
            {
              label: 'Yes',
              value: 'true',
              key: 'yes',
            },
            { label: 'No', value: 'false', key: 'no' },
          ]
        : docsChoices,
      required: true,
    },

    ...inArrayIf(
      !('exportable' in schema) || (exportIntent && orderType === 'bid'),
      {
        name: 'exportdocs',
        label: (
          <>
            {getExportLabel(orderType, 'exportdocs')(solutionColumns) ?? (
              <FormattedMessage
                id="orderform-field-exportdocs-label"
                description="Label for 'Export docs' field on new order form"
                defaultMessage="Export docs <span>(Fee applies)</span>"
                values={{
                  span: chunks => (
                    <span className="normal warning">{chunks}</span>
                  ),
                }}
              />
            )}
          </>
        ),
        initialValue: schema?.exportdocs?.value,
        choices: docsChoices,
        required: true,
      }
    ),

    {
      render: () =>
        exportIntent &&
        hasFlatFee &&
        exportdocsRequired &&
        orderType === 'bid' ? (
          <Alert
            hasicon
            type="info"
            className="alert-fee"
            key="alert-exportfee"
          >
            Please note a{' '}
            <strong style={{ width: 'auto' }}>
              {solution?.exportprice?.price} {solution.currency}
            </strong>{' '}
            flat fee will be added to this order.{' '}
            {solution.exportprice.file && (
              <a
                href={solution.exportprice.file.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                View more information here
              </a>
            )}
          </Alert>
        ) : (
          <React.Fragment key="exportintent-value-empty" />
        ),
    },

    ...inArrayIf(exportIntent && orderType === 'bid', {
      name: 'heattreatedpallets',
      label: 'Request heat-treated pallets',
      choices: docsChoices,
      required: true,
      initialValue: schema?.heattreatedpallets?.value,
    }),

    {
      render: () =>
        exportIntent &&
        heatTreatedPalletsRequired &&
        totalVolume > 0 &&
        solution?.exportprice?.heattreatedfee && (
          <Alert
            hasicon
            type="info"
            className="pallets-request"
            key="pallets-request"
          >
            <h3>Heat-treated pallets summary</h3>
            <div>
              <blockquote>
                <FNumber
                  value={parseFloat(solution.exportprice.heattreatedfee)}
                  decimalCount={getDecimalCount(
                    parseFloat(solution.exportprice.heattreatedfee)
                  )}
                  unit={solution.currency}
                />{' '}
                &times;{' '}
                <FNumber
                  value={totalVolume}
                  decimalCount={getDecimalCount(totalVolume)}
                />{' '}
                ={' '}
                <FNumber
                  value={
                    parseFloat(solution.exportprice.heattreatedfee) *
                    totalVolume
                  }
                  decimalCount={2}
                  unit={solution.currency}
                />
              </blockquote>
            </div>
          </Alert>
        ),
    },

    ...inArrayIf(exportIntent, {
      name: 'exportcountry',
      label: 'Destination',
      choices: normalizeChoices(schema?.exportcountry?.choices),
      initialValue: schema?.exportcountry?.value,
    }),
    {
      name: 'splittable',
      label: (
        <FormattedMessage
          id="orderform-field-splittable-label"
          description="Label for 'Splittable' field on new order form"
          defaultMessage="Splittable"
        />
      ),
      initialValue: true,
      props: {
        disabled: !splittableLoadings.includes(loadingId),
      },
    },
    {
      name: 'privatebid',
      label: (
        <FormattedMessage
          id="orderform-field-private-label"
          description="Label for 'Private' field on new order form"
          defaultMessage="Private"
        />
      ),
      initialValue: false,
    },
    {
      name: 'age',
      label: (
        <FormattedMessage
          id="orderform-field-age-label"
          description="Label for 'Age' field on new order form"
          defaultMessage="Age"
        />
      ),
      choices: getChoicesByProduct,
      // Empty choices might be provided
      // TODO Hide the field directly if choices are empty
      required: ({ choices, required }) => {
        if (R.isEmpty(getChoicesByProduct({ choices }))) return false;
        return required;
      },
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-age-placeholder"
            description="Placeholder for 'Age' field on new order form"
            defaultMessage="Select age"
          />
        ),
      },
    },
    {
      render: () => {
        if (!hasProductionDate) return null;
        return (
          <div
            key="form-item-productiondate"
            style={
              !form.getFieldValue('age') ||
              form.getFieldValue('age') === 'fresh'
                ? { display: 'none' }
                : {}
            }
          >
            {productionDateField.render({ form })}
          </div>
        );
      },
      serializeField: values => {
        if (!hasProductionDate) return null;
        return {
          productiondate: productionDateField.serialize(values.productiondate),
        };
      },
    },
    {
      name: 'buyer',
      label: (
        <FormattedMessage
          id="orderform-field-buyer-label"
          description="Label for 'Buyer' field on new order form"
          defaultMessage="Buyer"
        />
      ),
      choices: normalizeChoices,
    },
    {
      name: 'consignee',
      label: (
        <FormattedMessage
          id="orderform-field-consignee-label"
          description="Label for 'Consignee' field on new order form"
          defaultMessage="Consignee"
        />
      ),
      choices: normalizeChoices,
    },
    {
      name: 'note',
      label: (
        <FormattedMessage
          id="orderform-field-comment-label"
          description="Label for 'Comment' field on new order form"
          defaultMessage="Comment"
        />
      ),
    },
    {
      name: 'comment',
      label: (
        <FormattedMessage
          id="orderform-field-comment-label"
          description="Label for 'Comment' field on new order form"
          defaultMessage="Comment"
        />
      ),
    },
    {
      name: 'reference',
      label: (
        <>
          <FormattedMessage
            id="orderform-field-reference-label"
            description="Label for 'Reference' field on new order form"
            defaultMessage="Reference"
          />{' '}
          <span className="normal">
            <FormattedMessage
              id="orderform-field-reference-helptext"
              description="Help text for 'Reference' field on new order form"
              defaultMessage="This field is for internal use only, and will not be published."
            />
          </span>
        </>
      ),
    },
    {
      name: 'openprice',
      initialValue: false,
      render: () => null,
      serializeField: values => {
        if (!R.has('openprice', schema)) return null;
        const { price } = form.getFieldsValue();
        return { openprice: String(price === 0) };
      },
    },
    {
      name: 'files',
      label: (
        <FormattedMessage
          id="orderform-field-attachments-label"
          description="Label for 'Attachments' field on new order form"
          defaultMessage="Attachments"
        />
      ),
      render: () => {
        if (!R.prop('files', schema)) return null;
        return (
          <FilesField
            key="form-files"
            initialData={initialData}
            accept={R.pathOr([], ['files', 'accepted'], schema).join(', ')}
            files={files}
            setFiles={setFiles}
          />
        );
      },
      serializeField: () => {
        if (!R.prop('files', schema)) return null;
        return files.length ? { files } : undefined;
      },
    },
    {
      name: 'attachments',
      label: (
        <FormattedMessage
          id="orderform-field-existingattachments-label"
          description="Label for 'Existing attachments' field on new order form"
          defaultMessage="Existing attachments"
        />
      ),
      choices: normalizeChoices,
      props: {
        placeholder: (
          <FormattedMessage
            id="orderform-field-attachments-placeholder"
            description="Placeholder for 'Attachments' field on new order form"
            defaultMessage="Select attachments to keep"
          />
        ),
      },
      initialValue: R.pathOr([], ['attachments', 'value'], schema),
      initialData: R.pathOr([], ['attachments', 'value'], schema),
      // || R.pathOr([], ['attachments'], initialData).map(item => item.id),
    },
  ]);

  if (schema.variants) {
    const schemaX = {
      ...schema,
      startprice: { ...schema.startprice, step: product.step },
    };
    fields.push(new Variants({ form, schema: schemaX, order: initialData }));
  }

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

function OrderForm({
  form,
  product,
  initialData,
  schema,
  submitState,
  orderType,
  handleModalClose,
  prevLocation,
  solution,
}) {
  const updateOverview = useStoreActions(
    actions => actions.marketplace.updateData
  );
  const update = useStoreActions(actions => actions.market.update);

  // TODO Move this wrapper to outer if it affects performance
  const formWrapper = useFormWrapper({
    form,
    product,
    initialData,
    schema,
    solution,
    orderType,
  });
  // Already bound
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    // Client-side validation
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    // Server-side validation
    submitState.setLoading(true);
    const serialized = {
      ...formWrapper.serialize(),
      product: product.id,
    };

    const { files, ...fields } = serialized;
    const data = new FormData();
    for (const [name, value] of Object.entries(fields)) {
      if (value !== undefined) {
        if (Array.isArray(value)) for (const v of value) data.append(name, v);
        else data.append(name, value);
      }
    }
    if (!data.has('splittable')) {
      data.append('splittable', false);
    }
    data.append('sell', orderType === 'offer');
    if (files) {
      for (const file of files) data.append('files[]', file);
    }

    try {
      const result = await api.getData(
        { type: 'orders', getResult: x => x },
        null,
        { method: 'post', data }
      );
      // TODO postProcess
      submitState.setResult(result);

      switch (prevLocation.pathname) {
        case '/':
          update();
          break;

        case '/overview':
          updateOverview();
          break;

        default:
          break;
      }
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        // TODO scrollIntoView
        formWrapper.setErrors(errors);
      } else {
        // report the unexcepted error
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {/*form.isFieldsTouched() && (
          <Prompt message="Form is incomplete. Are you sure you want to leave?" />
        )*/}
        {formWrapper.render()}
        <Form.Item>
          <div className="button-set">
            {form.getFieldValue('realbuyer') &&
            form.getFieldValue('realseller') ? (
              <Button
                htmlType="submit"
                className={`nui-${orderType}`}
                loading={submitState.loading}
                disabled={submitState.loading}
              >
                <FormattedMessage
                  id="orderform-button-fok-label"
                  description="Label for `Create trade` button on new order form"
                  defaultMessage="Create trade"
                />
              </Button>
            ) : (
              <Button
                htmlType="submit"
                className={`nui-${orderType}`}
                loading={submitState.loading}
                disabled={submitState.loading}
              >
                <FormattedMessage
                  id="orderform-button-placeorder-label"
                  description="Label for `place order` button on new order form"
                  defaultMessage="Place {type, select, offer {offer} bid {bid} other {}}"
                  values={{ type: orderType }}
                />
              </Button>
            )}

            <PresetButton
              product={product}
              orderType={orderType}
              formWrapper={formWrapper}
            />

            <Button htmlType="button" type="simple" onClick={handleModalClose}>
              <FormattedMessage
                id="orderform-button-cancel-label"
                description="Label for `Cancel` button on new order form"
                defaultMessage="Cancel"
              />
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export const WrappedOrderForm = Form.create()(OrderForm);

export default function submitSuccess(result, submitState) {
  const orderType = R.pathOr('', ['order', 'type'], result);
  return (
    <ClassicResult title="Order placed">
      <p>{R.prop('message', result)}</p>
      <hr className="mb-15 mt-15" />
      <div className="button-set">
        <Link to={`/?order=${R.pathOr('', ['order', 'id'], result)}`}>
          <Button type={orderType} className="small mb-0">
            <FormattedMessage
              id="orderform-button-view-label"
              description="Label for `View` button on successful new order form"
              defaultMessage="View"
            />{' '}
            {orderType}
          </Button>
        </Link>

        <Button
          type="reverse"
          className="small mb-0"
          onClick={() => {
            submitState.setResult();
          }}
        >
          <FormattedMessage
            id="orderform-button-view-addanother"
            description="Label for `Add another` button on successful new order form"
            defaultMessage="Add another"
          />
        </Button>
      </div>
    </ClassicResult>
  );
}

export const submitError = (result, submitState, handleModalClose) => (
  <ClassicResult success={false} title="Something went wrong">
    <p>
      <FormattedMessage
        id="orderform-error-submit"
        description="Error on unsuccessful new order form"
        defaultMessage="Failed to submit. Please try again later."
      />
    </p>
    <hr className="mb-15 mt-15" />
    <div className="button-set">
      <Button
        type="primary"
        className="nui-button nui-error small mb-0"
        onClick={() => {
          // Clear result page
          submitState.setResult();
        }}
      >
        <FormattedMessage
          id="orderform-button-tryeditagain-label"
          description="Label for `Try edit again` button on new order form"
          defaultMessage="Try edit again"
        />
      </Button>
      <Button type="simple" onClick={handleModalClose} className="small mb-0">
        <FormattedMessage
          id="orderform-button-closepanel-label"
          description="Label for `Close panel` button on new order form"
          defaultMessage="Close panel"
        />
      </Button>
    </div>
  </ClassicResult>
);

export const ProductTagsFilter = ({
  productGroupChoices,
  setProductGroupChoices,
  tags,
}) => {
  const name = 'tags';

  if (tags.length === 0) {
    return <></>;
  }

  const theOptions = tags.map(item => [item.id, item.name]);
  theOptions.push(['ungrouped', 'Others']);

  return (
    <div className="nui-field-holder cb-set stack-8">
      <label htmlFor={name}>
        <FormattedMessage
          id="orderform-field-productgroups-label"
          description="Label for `product groups` field on new order form"
          defaultMessage="Product group"
        />
      </label>
      <DropCheck
        name={name}
        title="All"
        options={theOptions}
        value={productGroupChoices}
        onChange={(_, values) => {
          setProductGroupChoices(values);
        }}
      />
    </div>
  );
};

// Entry component for selecting tradable product to decide form schema.
export function OrderNew({ location, handleModalClose, prevLocation }) {
  const solution = useStoreState(state => state.auth.solution);
  const tags = useStoreState(state => state.auth.solutionTags);
  const tagIdArray = tags.map(tag => tag.id);
  const [productGroupChoices, setProductGroupChoices] = useState([
    'ungrouped',
    ...tagIdArray,
  ]);

  const { orderType } = useParams();

  const title = `Place market ${orderType}`;

  // Here set global timezone to the one of the selected solution.
  // TODO Refer Google's solution which
  //  - shows current timezone clearly
  //  - notify user when local timezone is different from the master configured timezone
  //  - show timezone picker in event form
  const tradableProducts = useStoreState(state =>
    state.auth.tradableProducts(orderType)
  );
  const productById = id => {
    const product = R.find(R.propEq('id', id), tradableProducts);
    return product && new Product(product);
  };

  // Parse location.search to prefill the fields as initial value.
  // TODO could also use data from location state
  // const initialData = parseSearch(location.search);
  const [initialData, setInitialData] = useState(parseSearch(location.search));
  const [order, setOrder] = useState(R.path(['order'], initialData));
  // XXX this is so dumb; I'm only doing it to be consistent :(
  const [tenderOrder] = useState(R.path(['tender-order'], initialData));
  const [preset, setPreset] = useState();

  // Get product from initial value
  const [product, setProduct] = useState(
    productById(R.prop('product', initialData))
  );

  // tags load asynchronously
  useEffect(() => {
    setProductGroupChoices(['ungrouped', ...tags.map(tag => tag.id)]);
  }, [tags, setProductGroupChoices]);

  const productId = R.prop('id', product);

  const changeProduct = value => {
    R.compose(setProduct, productById)(value);
    setOrder(undefined);
    setPreset(undefined);
  };

  // Load schema of the division of the selected product
  const schemaState = useAsync(() => {
    // When no product selected, returns { loading: false, value: undefined }
    if (!productId) return Promise.resolve();
    let params = { sell: orderType === 'offer' };
    let id = 'neworder';
    if (preset) {
      params.preset = preset;
    } else if (order) {
      id = `${order}/schema`;
    } else if (tenderOrder) {
      params['tender-offer-id'] = tenderOrder;
      delete params.sell;
    } else {
      params.product = productId;
    }
    return api.getData({ type: 'orders', id: id }, params);
  }, [productId, order, preset]);

  const productMatchesGroup = product =>
    product.tags.length > 0
      ? product.tags.some(tag => productGroupChoices.includes(tag.id))
      : productGroupChoices.includes('ungrouped');

  const productFilteredByGroup =
    tags.length === 0
      ? tradableProducts
      : R.filter(productMatchesGroup, tradableProducts);

  const productChoices = R.compose(
    R.map(([group, products]) => ({
      key: group,
      label: group,
      value: products.map(x => ({ key: x.id, value: x.id, label: x.name })),
    })),
    R.toPairs,
    R.groupBy(
      p =>
        `Products${
          R.path(['owner', 'name'], p)
            ? ' of ' + R.path(['owner', 'name'], p)
            : ''
        }`
    ),
    R.filter(R.propEq('marketplace', true))
  )(productFilteredByGroup);

  // TODO product field is not put in a form, thus it is possible to submit invalid product,
  //  if the product has been changed after form is shown.
  const productField = new SelectField({
    name: 'product',
    label: 'Product',
    required: true,
    props: {
      disabled: schemaState && schemaState.loading,
      placeholder: 'Select a product',
      onChange: changeProduct,
      value: productId,
    },
    choices: productChoices,
  });

  return (
    <div role="region" aria-label="New Order" className="wrapper">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <h2>{title}</h2>

      <ShowResult
        renderSuccess={submitSuccess}
        renderError={submitError}
        handleModalClose={handleModalClose}
      >
        {submitState => (
          <div className="nui-form order-form">
            <Form.Item colon={false} required={true}>
              <ProductTagsFilter
                productGroupChoices={productGroupChoices}
                setProductGroupChoices={setProductGroupChoices}
                tags={tags}
              />

              <div className="ant-form-item-label">
                <label
                  className="ant-form-item-required ant-form-item-no-colon m-0"
                  title="Product"
                >
                  <FormattedMessage
                    id="orderform-field-product-label"
                    description="Label for `product` field on new order form"
                    defaultMessage="Product"
                  />
                </label>
                <PresetDropdown
                  orderType={orderType}
                  productById={productById}
                  setPreset={setPreset}
                  setProduct={setProduct}
                />
              </div>
              {productField.renderUnbound()}
            </Form.Item>
            <LoadingState state={product ? schemaState : null}>
              {schema => (
                <WrappedOrderForm
                  // Reset form data when product changes
                  key={`form-${productId}`}
                  orderType={orderType}
                  schema={schema}
                  product={product}
                  initialData={prepareInitialDataFromSchema(schema)}
                  submitState={submitState}
                  handleModalClose={handleModalClose}
                  prevLocation={prevLocation}
                  solution={solution}
                />
              )}
            </LoadingState>
          </div>
        )}
      </ShowResult>
    </div>
  );
}
