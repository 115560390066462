import * as React from 'react';
import classnames from 'classnames';

interface IRowToggle {
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): any;
  open: boolean;
  className?: string;
  children?: React.ReactNode;
}
export default ({ onClick, open, className, children }: IRowToggle) => (
  <button
    className={classnames('nui-row-toggle', className, { open })}
    onClick={onClick}
  >
    <span className={classnames('nui-row-toggle-arrow')} />
    {children}
  </button>
);
