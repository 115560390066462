import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Link } from 'react-router-dom';
import { useSharedProduct } from '.';

export default () => {
  const [product] = useSharedProduct();

  if (!product) return null;

  return (
    <>
      <div className="h1bc">
        <h1 className="inline mt-10 mr-20 mb-0">
          View product <span className="smaller">{product.name}</span>
        </h1>
      </div>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="products" />
        </li>
        <li>
          <Link to="/products" title="Back to the main products page">
            Products
          </Link>
        </li>
        <li>View</li>
      </ul>
    </>
  );
};
