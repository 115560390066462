import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Table, Alert } from 'Components/nui';
import { Link } from 'react-router-dom';
import R from 'ramda';
import { useScrollToTopOnMount, useBodyClassReverse } from '~/hooks.js';
import Header from './Header';
import Helmet from 'Components/Helmet';
import FreightItem from '../FreightItem';

const RouteRow = ({ route }) => {
  if (!route.id) {
    return (
      <Alert type="error" className="align-center" hasicon>
        The route is not yet configured
      </Alert>
    );
  }
  if (!route.freight) {
    return (
      <Alert type="warning" className="align-center" hasicon>
        No freight options to display
      </Alert>
    );
  }
  return (
    <>
      <h3>Freight options</h3>
      <div className="wizard-content">
        <ul className="item-list mt-12 no-ws">
          {route.freight.map(item => (
            <FreightItem key={item.id} item={item} id={route.id} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default () => {
  useScrollToTopOnMount();
  useBodyClassReverse();

  const solution = useStoreState(state => state.auth.solution);
  const isSolutionOwner = useStoreState(state => state.auth.isSolutionOwner);
  const fetchRoutes = useStoreActions(action => action.auth.fetchRoutes);
  const routes = useStoreState(state => state.auth.routes);

  const canManageDivisions = useStoreState(
    state => state.auth.canManageDivisions
  );
  const canManageProductsDivisions = useStoreState(
    state => state.auth.canManageProductsDivisions
  );
  const canEdit =
    canManageProductsDivisions.length > 0 && canManageDivisions.length > 0;

  useEffect(() => {
    if (solution) fetchRoutes(solution.id);
  }, [solution]);

  const columns = [
    {
      title: '',
      className: 'col-details',
      key: 'btn',
      render: () => '',
    },
    {
      title: 'ID',
      key: 'id',
      className: 'col-id',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      render: (text, record, index) => {
        return record.type === 'zone' ? (
          <div className="td-content">ZONE</div>
        ) : (
          <div className="td-content">
            {record.source.id} - {record.destination.id}
          </div>
        );
      },
    },
    {
      title: 'Source',
      key: 'source',
      className: 'col-source',
      sorter: (a, b) => {
        return a.source.name.localeCompare(b.source.name);
      },
      render: (text, record, index) => {
        return (
          <div className="td-content">
            {R.join(
              ', ',
              R.filter(R.complement(R.isNil), [
                R.path(['source', 'name'], record),
                R.path(['source', 'country', 'name'], record),
              ])
            )}
          </div>
        );
      },
    },
    {
      title: 'Destination',
      key: 'destination',
      className: 'col-destination',
      sorter: (a, b) => {
        return a.destination.name.localeCompare(b.destination.name);
      },
      render: (text, record, index) => {
        return (
          <div className="td-content">
            {R.join(
              ', ',
              R.filter(R.complement(R.isNil), [
                R.path(['destination', 'name'], record),
                R.path(['destination', 'country', 'name'], record),
              ])
            )}
          </div>
        );
      },
    },
    {
      title: 'Duration',
      key: 'duration',
      className: 'col-duration',
      render: (text, record, index) => {
        return (
          <div className="td-content nowrap">
            {record.duration
              ? `${record.duration} day${record.duration > 1 ? 's' : ''}`
              : ''}
          </div>
        );
      },
    },
  ];

  if (canEdit) {
    columns.push({
      title: '',
      key: 'edit',
      className: 'col-edit sticky on-right shaded-left',
      render: (text, record, index) => {
        if (record.id)
          return (
            <div className="td-content">
              <Link
                to={`/routes/${record.id}/edit`}
                className="nui-button nui-button-icon"
                title="Edit route"
              >
                <span className="icon-pencil"></span>
              </Link>
            </div>
          );

        if (!isSolutionOwner) return null;

        return (
          <div className="td-content">
            <Link
              to={`/routes/new?source=${record.source.id}&destination=${record.destination.id}`}
              className="nui-button nui-reverse nui-button-icon"
              title="New route"
            >
              <span className="icon-plus"></span>
            </Link>
          </div>
        );
      },
    });
  }

  return (
    <>
      <Helmet>
        <title>Routes</title>
      </Helmet>

      <Header />

      {canEdit && false && (
        <div className="full-page-header">
          <hr className="mb-10 mt-10" />
          <div className="nav-add-item">
            <Link to="/routes/new" className="button secondary">
              Add route
            </Link>
          </div>
        </div>
      )}

      <div className="full-page-content mt--41">
        <div className="generic-table expand-table th-sticky light">
          <Table
            columns={columns}
            emptyText={
              <div className="nui-row full-page-messaging align-center mt--46 p-100">
                <div className="icon-inbox bordered align-center">
                  There are currently no routes to display
                </div>
              </div>
            }
            pagination={{
              size: 'small',
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSize: 10,
            }}
            data={routes}
            expandedRowRender={record => <RouteRow route={record} />}
          />
        </div>
      </div>
    </>
  );
};
