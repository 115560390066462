//
import { createBrowserHistory } from 'history';

// When transition is blocked, use ant.design confirmation modal
// function getUserConfirmation(message, callback) {
//   const content = typeof message === 'string' ? { title: message } : message;
//   Modal.confirm({
//     ...content,
//     onOk() {
//       callback(true);
//     },
//     onCancel() {
//       callback(false);
//     },
//   });
// }

// We are accessing history outside router context, thus instantiate it here
// export const history = createBrowserHistory({ getUserConfirmation });
// XXX When clicking browser back and selecting cancel in the above confirm popup,
//  the url will not be restored, use window.confirm for now.
//  But even window.confirm can not prevent back been clicked again, when confirm is shown.
// Ref https://github.com/ReactTraining/react-router/issues/5405
export const history = createBrowserHistory();
