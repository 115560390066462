import React, { useRef, useState } from 'react';
import { useStoreState } from '~/store';
import R from 'ramda';
import { useToggle, useClickAway } from 'react-use';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { Button, Modal } from 'Components/nui';

export default ({ orders }) => {
  const user = useStoreState(R.path(['auth', 'user']));
  const solution = useStoreState(R.path(['auth', 'solution']));

  const ref = useRef(null);
  const [open, toggle] = useToggle(false);
  useClickAway(ref, () => {
    toggle(false);
  });

  const [modalData, setModalData] = useState(null);
  const [isCancelling, setIsCancelling] = useToggle(false);
  const closeModal = () => {
    setModalData(null);
  };

  const cancelOrders = async () => {
    if (!modalData) return;
    const { params } = modalData;

    try {
      setIsCancelling(true);
      await api.deleteData('/orders', params);
      await orders.update();
      toast('Orders were cancelled', {
        type: 'success',
      });
      setIsCancelling(false);
      closeModal();
    } catch (err) {
      toast('Something went wrong. Please try again later', {
        type: 'error',
      });
    }
  };

  const cancelMy = () => {
    toggle();
    setModalData({
      text: 'Are you sure you want to cancel all your active orders?',
      params: { user: user.id, solution: solution.id },
    });
  };

  const cancelAll = () => {
    toggle();
    setModalData({
      text: "Are you sure you want to cancel all your company's orders?",
      params: { solution: solution.id },
    });
  };

  return (
    <>
      {modalData && (
        <Modal size="small" close={closeModal}>
          <h2>Warning</h2>
          <p>{modalData.text} This can&apos;t be undone.</p>

          <hr />
          <div className="button-set">
            <Button
              type="primary"
              onClick={cancelOrders}
              loading={isCancelling}
            >
              Yes, proceed
            </Button>
            <Button type="simple" onClick={closeModal}>
              No, go back!
            </Button>
          </div>
        </Modal>
      )}

      <span ref={ref} className="has-dd-holder-reverse cancel-all">
        <Button
          type="reverse"
          className="nui-beveled cancel-all has-dd"
          onClick={toggle}
          title="Cancel orders"
        >
          <span className="icon-cancel-circled"></span> Cancel{' '}
          <span>orders</span>
          <i className="normal hide-for-medium">...</i>
          <span className="dd-arrow"></span>
        </Button>
        {open && (
          <ul>
            <li>
              <Button
                type="buttonlink"
                onClick={cancelMy}
                title="Cancel my active orders"
              >
                Cancel my orders
              </Button>
            </li>
            <li>
              <Button
                type="buttonlink"
                onClick={cancelAll}
                title="Cancel my company's orders"
              >
                Cancel all active company orders
              </Button>
            </li>
          </ul>
        )}
      </span>
    </>
  );
};
