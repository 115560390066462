import React from 'react';
import { AxiosError } from 'axios';
import R from 'ramda';
import { toast, ToastOptions } from 'react-toastify';

function isHttpError(error: any): error is AxiosError {
  if (error.response) return true;
  return false;
}

interface OnResponse {
  [key: number]: () => void;
}

export function apiError(errors: any, onResponse: OnResponse) {
  const options: ToastOptions = {
    type: 'error',
    autoClose: 10000,
  };

  const support = 'support@nuimarkets.com';

  if (isHttpError(errors)) {
    const status = errors.response?.status || 0;
    const preDefined = Object.keys(onResponse).map(x => x.toString());
    const display = () => (
      <>
        Error ({errors.response?.status}): There was an error processing your
        request. Please contact support via chat, or email us at{' '}
        <a href={`mailto:${support}`}>{support}</a>
      </>
    );

    if (R.includes(status.toString(), preDefined)) {
      onResponse[status]();
    } else {
      switch (status) {
        case 400: {
          console.log('Programming error');
          console.log(errors.response);
          toast(display(), options);
          break;
        }
        // This is a bit useless - it looks like Axios makes this a network error
        // so 500 statuses are actually handled by the else block below.
        case 500: {
          console.log('Server error');
          console.log(errors.response);
          toast(display(), options);
          break;
        }
        default: {
          console.log('Unknown error');
          console.log(errors.response);
          toast(display(), options);
          break;
        }
      }
    }
  } else {
    console.log('Unhandled error');
    console.log(errors);
    toast(
      () => (
        <>
          Error: There was an error processing your request. Please contact
          support via chat, or email us at{' '}
          <a href={`mailto:${support}`}>{support}</a>
        </>
      ),
      options
    );
  }
}
