import React, { useEffect } from 'react';
import { Form, Button, Loading, Alert } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import {
  mergeSchema,
  normalizeChoices,
  submitError,
} from 'Components/form/utils';
import { LoadingState } from 'Components/Layout';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import R from 'ramda';
import { useAsync, createStateContext } from 'react-use';
import { useParams, useHistory } from 'react-router-dom';
import Helmet from 'Components/Helmet';
import { useSharedRoute } from '..';
import { toast } from 'react-toastify';
import { api } from '~/api';
import { useStoreState } from 'easy-peasy';
import FreightItem from 'Pages/Routes/FreightItem';

const [useSharedFreight, SharedFreightProvider] = createStateContext(null);

const submitSuccess = (result, submitState) => (
  <Alert hasicon type="success" className="mb-20">
    <h3>Success</h3>
    <p>Successfully updated freight options.</p>
    <Button
      type="reverse"
      size="small"
      className="mb-10 mt--10"
      onClick={() => {
        submitState.setResult();
      }}
    >
      Add another
    </Button>
  </Alert>
);

const getFormWrapper = ({ form, initialData, schema, solutionDivisionIds }) => {
  let divisionId = form.getFieldValue('division');
  const rateTypeId = form.getFieldValue('rate_type');

  if (solutionDivisionIds && solutionDivisionIds.length === 1) {
    divisionId = solutionDivisionIds[0];
  }

  const fallback = R.pathOr('', ['rate', divisionId, 'unit'], schema);
  const currency = R.pathOr('', ['rate', divisionId, 'currency'], schema);
  const unitType = R.propOr(
    '',
    1,
    R.find(
      R.propEq(0, rateTypeId),
      R.pathOr([], ['rate_type', 'choices'], schema)
    )
  );

  const unit = currency && unitType ? `${currency}/${unitType}` : fallback;

  const fields = [
    {
      key: 'division',
      name: 'division',
      label: 'Division',
      choices: normalizeChoices,
    },
    {
      key: 'incoterm',
      name: 'incoterm',
      label: 'Incoterm',
      choices: normalizeChoices,
    },
    {
      key: 'loading',
      name: 'loading',
      label: 'Loading',
      choices: normalizeChoices,
    },
    {
      key: 'rate_type',
      name: 'rate_type',
      label: 'Rate type',
      choices: normalizeChoices,
      type: 'RadioSelect',
    },
    {
      key: 'rate',
      name: 'rate',
      label: `Freight rate ${unit ? `(${unit})` : ''}`,
      initialValue: 0,
      step: 0.01,
      min: 0.01,
    },
  ];

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
};

const PForm = ({ form, schema, submitState }) => {
  const [route] = useSharedRoute();
  const [freights, setFreights] = useSharedFreight();
  const solutionDivisionIds = useStoreState(
    store => store.auth.solutionDivisionIds
  );

  const formWrapper = getFormWrapper({
    form,
    schema,
    solutionDivisionIds,
  });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    submitState.setLoading(true);
    const data = formWrapper.serialize();

    try {
      const result = await api.getData(
        { type: 'routes', id: `${route.id}/freight`, getResult: x => x },
        null,
        { method: 'post', data }
      );
      submitState.setResult(result);

      const response = await api.getData({
        type: 'routes',
        id: `${route.id}/freight`,
      });

      setFreights(response);

      // setFreights(R.append(result, freights));
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        formWrapper.setErrors(errors);
      } else {
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <Button
        type="secondary"
        htmlType="submit"
        className="m-0 mt-0"
        loading={submitState.loading}
      >
        Save
      </Button>
    </Form>
  );
};

const List = () => {
  const { id } = useParams();

  const [freights, setFreights] = useSharedFreight();

  useEffect(() => {
    const fetch = async () => {
      const response = await api.getData({
        type: 'routes',
        id: `${id}/freight`,
      });

      setFreights(response);
    };

    fetch();
  }, [id, setFreights]);

  if (freights === null) return <Loading size="medium" />;

  return (
    <ul className="item-list mt-12 no-ws">
      {freights.map(item => (
        <FreightItem key={item.id} item={item} id={id} />
      ))}
    </ul>
  );
};

const WrappedForm = Form.create()(PForm);

export default () => {
  const { id } = useParams();
  const [route] = useSharedRoute();
  const history = useHistory();

  const state = useAsync(() => {
    return api.getData({ type: 'routes', id: `${id}/freight/schema` });
  }, [id]);

  const finish = () => {
    history.push('/routes');
    toast.success('Your route is saved');
  };

  if (!route) return null;

  return (
    <>
      <Helmet>
        <title>Edit route {route.name} - Freight</title>
      </Helmet>
      <SharedFreightProvider>
        <List />
        <ShowResult renderSuccess={submitSuccess} renderError={submitError}>
          {submitState => (
            <div className="wrapper">
              <div className="nui-form order-form inset-form mb-20">
                <h3 className="mt--10">New freight option</h3>
                <LoadingState state={state}>
                  {schema => (
                    <WrappedForm
                      key="route-freight"
                      schema={schema}
                      submitState={submitState}
                    />
                  )}
                </LoadingState>
              </div>
            </div>
          )}
        </ShowResult>
      </SharedFreightProvider>
      <hr />
      <Button type="primary" className="mb-10" onClick={finish}>
        Finish
      </Button>
    </>
  );
};
