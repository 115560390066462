import React from 'react';
import R from 'ramda';
import moment from 'moment-timezone';
import { useAsync } from 'react-use';
import { api } from '~/api';
import { Order } from '~/models';
import { useStoreState } from 'easy-peasy';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { getAddrInfo, routesIncluded, getAddrChoice } from '~/utils';

const EtaLabel = () => (
  <h4>
    <FormattedMessage
      id="marketrow-locationalert-field-eta-label"
      description="Label for `ETA` field on locationalert in Marketrow"
      defaultMessage="ETA"
    />
  </h4>
);

const SelectedLocationHeading = () => (
  <h3>
    <FormattedMessage
      id="marketrow-locationalert-header"
      description="Header for locationalert in Marketrow"
      defaultMessage="Selected location"
    />
  </h3>
);

export default ({ form, order, price, setPrice, schema }) => {
  const [loadingPrice, setLoadingPrice] = useState(false);
  const toetd = order?.etd?.to;
  const etaFreightTypes = ['port', 'zone'];

  const toaddr = form.getFieldValue('toaddr');

  const solution = useStoreState(state => state.auth.solution);

  useAsync(async () => {
    if (!!solution.freight && toaddr) {
      setLoadingPrice(true);
      const data = await api.getData(
        {
          type: 'orders',
          id: order.id,
          getResult: x => new Order(x.order),
        },
        { toaddr }
      );
      setLoadingPrice(false);
      setPrice(data.price.val);
    }
  }, [toaddr, solution]);

  if (
    order.type === 'offer' &&
    etaFreightTypes.includes(schema?.toaddr?.freight) &&
    toetd &&
    solution.shippingroutes &&
    schema?.toaddr?.choices &&
    order?.fromaddr?.length
  ) {
    const division = form.getFieldValue('division');
    const toaddrInfo = getAddrInfo(toaddr)(
      getAddrChoice(division)(schema.toaddr.choices)
    );

    if (!toaddrInfo)
      return (
        <div className="counter-info-box mt-10 mb-10">
          <SelectedLocationHeading />
          No location selected
        </div>
      );

    const matchingRoutes = solution.shippingroutes.filter(route => {
      const fromFreightIds = order.fromaddr.map(e => e?.[route.type]?.id);
      return (
        fromFreightIds?.length &&
        routesIncluded(fromFreightIds, [Object.values(toaddrInfo[2])[0]])(route)
      );
    });

    const sortedRoutes = matchingRoutes.sort(
      (route1, route2) => route1.duration - route2.duration
    );

    return (
      <div className="counter-info-box mt-10 mb-10">
        <SelectedLocationHeading />
        {sortedRoutes.length ? (
          <>
            <div className="half">
              <h4>
                <FormattedMessage
                  id="marketrow-locationalert-field-price-label"
                  description="Label for `Price` field on locationalert in Marketrow"
                  defaultMessage="Price"
                />
              </h4>
              <div className="block">
                <span className="price">
                  {loadingPrice ? (
                    <FormattedMessage
                      id="marketrow-locationalert-field-price-helptext-loading"
                      description="Helptext for `price` field on locationalert in Marketrow when price data is loading"
                      defaultMessage="Loading..."
                    />
                  ) : (
                    price
                  )}
                </span>
                <span className="price-unit">{order.price.unit}</span>
              </div>
            </div>
            {sortedRoutes.map(route => (
              <div key={route.id} className="half">
                <EtaLabel />
                <small>
                  From {route.source.name} To {route.destination.name}
                </small>
                <div>
                  <strong className="etd">
                    {moment(toetd)
                      .add(route.duration, 'day')
                      .format('DD MMM YYYY')}
                  </strong>{' '}
                  <span>
                    ETD + {route.duration} day
                    {route.duration > 1 && 's'}
                  </span>
                </div>
              </div>
            ))}
          </>
        ) : (
          'No shipping routes found'
        )}
      </div>
    );
  }

  return null;
};
