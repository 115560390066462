import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const EtdFromColumn = ColumnTree.defineNode(
  'etd-from',
  ({ sortDirection, sortColumn, setSort }) => ({
    title: (
      <div>
        <a
          href="#etd-from"
          className={classnames('icon-sort', 'sortem', {
            [sortDirection]: sortColumn === 'etd-from',
          })}
          onClick={() => setSort('etd-from')}
        >
          <FormattedMessage
            id="marketplace-table-subheading-etdfrom"
            description="Table sub-heading, ETD from time"
            defaultMessage="From"
          />
        </a>
      </div>
    ),
    key: 'etd-from',
    className: 'col-etd-from',
    render: (text, record, index) => {
      if (record.quarteretd) {
        return {
          children: (
            <div className="td-content">
              <span className="tiny-label">
                <FormattedMessage
                  id="marketplace-table-cellheading-etdquarter"
                  description="Table cell heading, Quarter ETD"
                  defaultMessage="ETD"
                />
              </span>
              <span className="nowrap">{record.quarteretd}</span>
            </div>
          ),
          props: { colSpan: 2 },
        };
      }
      return (
        <div className="td-content">
          <span className="tiny-label">
            <FormattedMessage
              id="marketplace-table-cellheading-etdfrom"
              description="Table cell heading, From ETD"
              defaultMessage="ETD from"
            />
          </span>
          <span className="nowrap">
            {moment(record.etd.from).format('DD MMM')}
          </span>
        </div>
      );
    },
  })
);
