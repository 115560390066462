import React from 'react';

export default ({ addresses }) => {
  if (addresses.length === 1) return addresses[0].desc;

  return (
    <ul className="mb-0">
      {addresses.map(item => (
        <li key={item.id}>
          <span>{item.desc}</span>
        </li>
      ))}
    </ul>
  );
};
