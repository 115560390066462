import React, { useRef } from 'react';
import classnames from 'classnames';
import { useClickAway } from 'react-use';
import { useMountedState } from '~/hooks';

type ClassProp = Partial<Record<'className', string>>;
type ChildrenProp = Partial<Record<'children', React.ReactNode>>;
type TitleProp = Record<'title', React.ReactNode>;

type IActionList = TitleProp & ClassProp & ChildrenProp;
const ActionList = ({ title, className, children }: IActionList) => {
  const [visible, setVisible] = useMountedState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    setVisible(false);
  });

  return (
    <div
      ref={ref}
      className={classnames('action-list', className, { visible })}
      onClick={() => setVisible(true)}
    >
      <span className="icon-info-circled action-list-title">{title}</span>
      <ul className="actions-list-items">{children}</ul>
    </div>
  );
};

export const Item = ({ className, children }: ClassProp & ChildrenProp) => (
  <li className={classnames('action-list-item', className)}>{children}</li>
);

ActionList.Item = Item;
export default ActionList;
