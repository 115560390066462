import React, { useState, useMemo } from 'react';
import { useStoreState } from '~/store';
import R from 'ramda';
import { Table } from 'Components/nui';
import classnames from 'classnames';
import { useWarehouses } from './utils';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import { inArrayIf } from '~/utils';
import { Modal, Button, Form } from 'Components/nui';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { NoData } from 'Components/InfoMessage';

const Cell = ({ children, ...rest }) => {
  return (
    <td {...rest}>
      <div className="td-content">{children}</div>
    </td>
  );
};

//Todo stying the sort title
const SortTitle = ({ title, name, onClick, sortDirection }) => {
  return (
    <div className={classnames(name)}>
      <button
        className={classnames('icon-sort', sortDirection, {
          sortem: sortDirection.length,
        })}
        onClick={onClick}
      >
        {title}
      </button>
    </div>
  );
};

const MainTable = () => {
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState(['description']);

  const [loading, setLoading] = useState(false);
  const [toRemove, setToRemove] = useState(null);

  const warehouses = useWarehouses();
  const data = warehouses.all();

  const sorter = sortAscending ? R.ascend : R.descend;

  const sortedData = useMemo(
    () =>
      R.sortWith([
        sorter(R.compose(R.toLower, R.path(sortColumn))),
        R.ascend(R.prop('id')),
      ])(data),
    [sortAscending, sortColumn, data]
  );

  const hasPort = useStoreState(
    R.o(R.includes('port'), R.pathOr([], ['auth', 'solution', 'freight_types']))
  );

  const auth = useStoreState(R.prop('auth'));
  const getKey = name => `warehousesTable.${name}`;
  const dataLength = warehouses.all().length;

  if (!dataLength)
    return (
      <NoData className="mt-10 p-100">
        There are currently no warehouses to display
      </NoData>
    );

  const changeDefault = warehouse => async e => {
    e.preventDefault();
    if (warehouse.default) return;
    setLoading(true);
    await warehouses.setDefault(warehouse);
    setLoading(false);
  };

  const remove = warehouse => async e => {
    e.preventDefault();
    setLoading(true);
    await warehouses.remove(warehouse);
    setToRemove(null);
    setLoading(false);
  };

  const isSorted = column => R.equals(column, sortColumn);

  const sortFn = column => () => {
    setSortColumn(column);
    const ascend = isSorted(column) ? !sortAscending : true;

    setSortAscending(ascend);
  };

  const getSortDirection = column => {
    return isSorted(column) ? (sortAscending ? 'up' : 'down') : '';
  };

  const columns = [
    {
      title: (
        <SortTitle
          title="Name"
          name="description"
          onClick={sortFn(['description'])}
          sortDirection={getSortDirection(['description'])}
        />
      ),
      key: getKey('name'),
      // sorter: sortStr(R.prop('description')),
      className: classnames('col-description'),
      render: R.prop('description'),
    },
    ...inArrayIf(auth.hasRole('manager') && dataLength > 1, {
      title: 'Default',
      key: getKey('default'),
      className: classnames('col-default'),
      render: warehouse => {
        const active = R.prop('default', warehouse);
        return (
          <Form.Item>
            <Checkbox
              className={classnames('select-default-warehouse', {
                active,
              })}
              disabled={loading}
              onChange={changeDefault(warehouse)}
              checked={active}
            />
          </Form.Item>
        );
      },
    }),
    {
      title: (
        <SortTitle
          title="Division"
          name="division"
          onClick={sortFn(['division', 'name'])}
          sortDirection={getSortDirection(['division', 'name'])}
        />
      ),
      key: getKey('division'),
      className: classnames('col-division'),
      render: role => `${role.division.name} (${role.division.shortcode})`,
    },
    {
      title: 'Zip',
      key: getKey('zip'),
      className: classnames('col-zip'),
      render: R.prop('zip'),
    },
    {
      title: 'Street',
      key: getKey('street'),
      className: classnames('col-street'),
      render: R.prop('street'),
    },
    {
      title: 'Suburb',
      key: getKey('suburb'),
      className: classnames('col-suburb'),
      render: R.prop('suburb'),
    },
    {
      title: 'City',
      key: getKey('city'),
      className: classnames('col-city'),
      render: R.prop('city'),
    },
    {
      title: 'State',
      key: getKey('state'),
      className: classnames('col-state'),
      render: R.prop('state'),
    },
    {
      title: (
        <SortTitle
          title="Country"
          name="country"
          onClick={sortFn(['country', 'name'])}
          sortDirection={getSortDirection(['country', 'name'])}
        />
      ),
      key: getKey('country'),
      className: classnames('col-country'),
      render: R.path(['country', 'name']),
    },
    ...inArrayIf(hasPort, {
      title: 'Port',
      key: getKey('port'),
      className: classnames('col-port'),
      render: R.path(['port', 'name']),
    }),
    ...inArrayIf(auth.hasRole('manager') && dataLength > 1, {
      title: '',
      key: getKey('delete'),
      className: classnames('col-delete'),
      render: warehouse =>
        !warehouse.default ? (
          <button
            className="delete-warehouse"
            onClick={() => {
              setToRemove(warehouse);
            }}
            disabled={loading}
          >
            <span className="icon-trash" />
          </button>
        ) : null,
    }),
  ];

  const components = {
    body: {
      cell: Cell,
    },
  };

  const cancelModal = () => {
    setToRemove(null);
  };

  return [
    <Table
      columns={columns}
      data={R.filter(R.propEq('active', true), sortedData)}
      rowKey={R.prop('id')}
      components={components}
      className="view-warehouses table-wrapper th-sticky"
    />,
    toRemove && (
      <Modal size="small" close={cancelModal}>
        <h2>Delete location</h2>
        <p>
          Are you sure you want to delete{' '}
          <strong className="all-black">{toRemove.description}</strong>?
          Removing this location will cancel all associated orders.
        </p>
        <hr />
        <div className="button-set">
          <Button
            type="primary"
            onClick={remove(toRemove)}
            loading={loading}
            disabled={loading}
          >
            Delete
          </Button>
          <Button type="simple" onClick={cancelModal}>
            Cancel
          </Button>
        </div>
      </Modal>
    ),
  ];
};

export default () => {
  const auth = useStoreState(R.prop('auth'));

  return (
    <>
      <div className="full-page-header">
        <h1 className="inline mr-20 mb-0">Locations</h1>

        <ul className="nui-bc right pt-20">
          <li>
            <BreadcrumbRoot active="locations" />
          </li>
          <li>Locations</li>
        </ul>

        <hr className="mb-10 mt-10" />
        {auth.hasRole('manager') && (
          <div className="nav-add-item">
            <Link to="/locations/new" className="button secondary">
              Add location
            </Link>
          </div>
        )}
      </div>
      <div className="full-page-content mt--1">
        <div className="generic-table light">
          <MainTable />
        </div>
      </div>
    </>
  );
};
