import React from 'react';
import Body from './Body';
import Header from './Header';
import Filter from './Filter';
import Helmet from 'Components/Helmet';
import { useStoreState } from '~/store';
import { Redirect } from 'react-router-dom';

export default () => {
  const marketplace = useStoreState(
    state => state.auth.solutionSettings.marketplace
  );

  if (!marketplace) return <Redirect to="/tenders" />;

  return (
    <div className="market">
      <Helmet>
        <title>Marketplace</title>
      </Helmet>

      <Header />
      <Filter />
      <Body />
    </div>
  );
};
