import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { useLockBodyScroll } from '~/hooks';
import Button from './Button';
import Slider from 'react-slick';
import { useSpring, animated } from 'react-spring';

const slickRoot = document.getElementById('slick-root');

export default ({ close, images, initialSlide }) => {
  const mainRef = useRef();

  useEffect(() => {
    if (mainRef) {
      mainRef.current.innerSlider.list.setAttribute('tabindex', 0);
      mainRef.current.innerSlider.list.focus();
    }
  }, []);

  useLockBodyScroll();
  const [el] = useState(document.createElement('div'));

  const history = useHistory();

  const back = e => {
    e && e.stopPropagation();
    history.goBack();
  };

  const goBack = close || back;

  const onEsc = event => {
    if (event.keyCode === 27) goBack();
  };

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false);
    slickRoot.appendChild(el);

    return () => {
      document.removeEventListener('keydown', onEsc, false);
      slickRoot.removeChild(el);
    };
  }, []);

  const { opacity, transform } = useSpring({
    config: { duration: 150 },
    from: {
      opacity: 0,
      transform: 'translate3d(0,-40px,0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    },
  });

  const modal = (
    <animated.div style={{ opacity }} className="nui-modal-overlay">
      <div className="tc" />
      <Button className="modal-close close-gallery" onClick={close}>
        <span className="icon-cancel-1"></span>
      </Button>

      <div className="gallery-wrapper">
        <Slider
          ref={mainRef}
          buttons={images.length > 1}
          easing="easeInOutCubic"
          initialSlide={initialSlide}
        >
          {images.map(item => (
            <div key={item.id}>
              <img src={item.src} alt={item.name} />
            </div>
          ))}
        </Slider>
      </div>
    </animated.div>
  );

  return ReactDOM.createPortal(modal, el);
};
