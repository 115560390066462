import React from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const PublishedColumn = ColumnTree.defineNode(
  'published',
  ({ sortDirection, sortColumn, setSort }) => ({
    title: (
      <div>
        <a
          href="#published"
          className={classnames('icon-sort', 'sortem', {
            [sortDirection]: sortColumn === 'created',
          })}
          onClick={() => setSort('created')}
        >
          <FormattedMessage
            id="marketplace-table-heading-published"
            description="Order published time, repeated for cell headings"
            defaultMessage="Published at"
          />
        </a>
      </div>
    ),
    children: [
      {
        title: '',
        key: 'published',
        className: 'col-published',
        render: (text, record, index) => (
          <div className="td-content">
            <span className="tiny-label">
              <FormattedMessage
                id="marketplace-table-heading-published"
                description="Order published time, repeated for cell headings"
                defaultMessage="Published at"
              />
            </span>
            {moment(record.created).format('DD MMM YYYY HH:mm')}
          </div>
        ),
      },
    ],
  })
);
