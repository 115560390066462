import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { Link } from 'react-router-dom';
import Helmet from 'Components/Helmet';

export default ({ title }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="full-page-header">
        <h1 className="inline mt-10 mr-20 mb-0">{title}</h1>

        <ul className="nui-bc right pt-20">
          <li>
            <BreadcrumbRoot active="reports" />
          </li>
          <li>
            <Link to="/reports" title="Back to the main reports page">
              Reports
            </Link>
          </li>
          <li>View</li>
        </ul>
      </div>
    </>
  );
};
