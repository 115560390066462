import React from 'react';
import R from 'ramda';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useStoreState } from '~/store';

export default ({ order }) => {
  const productsTradeActions = useStoreState(
    state => state.auth.userProductsTradeActions
  );
  const productById = useStoreState(state => state.auth.productById);
  const productId = R.path(['product', 'id'], order);
  const product = productId ? productById(productId) : null;

  const p_id = R.prop('p_id', product);
  const tradeActions = R.pathOr([], [p_id], productsTradeActions);
  const buyProductId = R.prop('buy', tradeActions);
  const sellProductId = R.prop('sell', tradeActions);

  return (
    <div className="bid-message">
      <p className="desc">
        <FormattedMessage
          id="marketrow-neworder-helptext"
          description="helptext for `new order` field in Marketrow"
          defaultMessage="<strong>Create a new order</strong> based on the details of this one."
          values={{ strong: chunks => <strong>{chunks}</strong> }}
        />
      </p>
      <div className="nowrap">
        {buyProductId && (
          <Link
            to={`/orders/bid?product=${order.product.id}&order=${order.id}`}
            className="pill place-bid"
          >
            <span>
              <FormattedMessage
                id="marketrow-neworder-button-bid"
                description="button for `new order` field bid in Marketrow"
                defaultMessage="New bid"
              />
            </span>
          </Link>
        )}
        {sellProductId && (
          <Link
            to={`/orders/offer?product=${order.product.id}&order=${order.id}`}
            className="pill place-offer"
          >
            <span>
              <FormattedMessage
                id="marketrow-neworder-button-offer"
                description="button for `new order` field offer in Marketrow"
                defaultMessage="New offer"
              />
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};
