import type { Moment } from 'moment-timezone';
import type * as Responses from 'endpoints.tenders';
import * as api from './api';
import * as analytics from './analytics';

export async function overview(solution: string) {
  return await api.get<Responses.Overview>({
    url: '/tenders',
    params: { solution, limit: 50, status: ['planning', 'active'] },
  });
}

export async function pollOverview(solution: string, since: Moment) {
  return await api.poll<Responses.Overview>({
    url: '/tenders',
    params: { solution, limit: 50, status: ['planning', 'active', 'finished'] },
    since,
  });
}

export async function history(solution: string, page: number) {
  return await api.get<Responses.Overview>({
    url: '/tenders',
    params: { solution, page, limit: 50, status: 'archived', sort: '-finish' },
  });
}

export async function tender(id: string) {
  return await api.get<Responses.TenderGet>({
    url: `/tenders/${id}`,
    method: 'GET',
  });
}

export async function order(tenderId: string, orderId: string) {
  return await api.get<Responses.OrderGet>({
    url: `/tenders/${tenderId}/offers/${orderId}`,
    method: 'GET',
  });
}

export async function pollCounters(id: string, since: Moment) {
  return await api.poll<Responses.CountersPoll>({
    url: `/tenders/${id}/bids`,
    method: 'GET',
    since,
  });
}

export async function getCounters(tender: string) {
  return await api.get<Responses.CountersPoll>({
    url: `/tenders/${tender}/bids`,
    method: 'GET',
  });
}

export async function counter(tenderId: string, orderId: string, data: any) {
  const url =
    `/tenders/${tenderId}/offers/${orderId}/bids` +
    (data.id ? `/${data.id}` : '');
  const method = data.id ? 'PUT' : 'POST';
  return await api.modify<Responses.CounterGet>({ url, data, method });
}

export async function deleteCounter(
  tender: string,
  order: string,
  counter: string
) {
  return await api.remove<null>({
    url: `/tenders/${tender}/offers/${order}/bids/${counter}`,
    method: 'DELETE',
  });
}

export async function quickbid(
  tenderId: string,
  orderId: string,
  counterId: string,
  price: number
) {
  return await api.modify<Responses.CounterGet>({
    url: `/tenders/${tenderId}/offers/${orderId}/bids/${counterId}`,
    method: 'PATCH',
    data: { price, quickbid: true },
  });
}

export async function editTender(formdata: FormData) {
  const id = formdata.get('id');
  const url = id ? `/tenders/${id}` : '/tenders';
  const method = id ? 'PUT' : 'POST';
  return await api.modify<Responses.TenderGet>({ url, method, formdata });
}

export async function editOrder(
  formdata: FormData,
  tenderId: string,
  orderId?: string
) {
  const url = `/tenders/${tenderId}/offers` + (orderId ? '/' + orderId : '');
  return await api.modify<Responses.OrderGet>({
    url,
    method: orderId ? 'PUT' : 'POST',
    formdata,
  });
}

export async function publish(tender: string) {
  return await api.modify<Responses.TenderGet>({
    url: `/tenders/${tender}`,
    method: 'PATCH',
    data: { status: 'active' },
  });
}

export async function notify(tender: string) {
  return await api.modify<Responses.TenderGet>({
    url: `/tenders/${tender}`,
    method: 'PATCH',
    data: { status: 'notify' },
  });
}

export async function trade(
  tender: string,
  order: string,
  counter: string,
  data: { volume: number }
) {
  return await api.modify<Responses.CounterTrade>({
    url: `/tenders/${tender}/offers/${order}/bids/${counter}`,
    method: 'PATCH',
    data,
  });
}

export async function finalise(tender: string) {
  return await api.modify<Responses.TenderGet>({
    url: `/tenders/${tender}`,
    method: 'PATCH',
    data: { status: 'archived' },
  });
}

export async function counterSchema(
  tenderId: string,
  orderId: string,
  counterId?: string
) {
  const url =
    `/tenders/${tenderId}/offers/${orderId}/bids` +
    (counterId ? `/${counterId}` : '') +
    '/schema';
  return await api.get<Responses.Schema>({ url, method: 'GET' });
}

export async function tenderSchema(solution: string) {
  const url = '/tenders/schema';
  const params = { solution };
  return await api.get<Responses.Schema>({ url, method: 'GET', params });
}

export async function orderSchema(
  tender: string,
  product: string,
  order?: string
) {
  const url =
    `/tenders/${tender}/offers` + (order ? `/${order}` : '') + '/schema';
  return await api.get<Responses.Schema>({
    url,
    method: 'GET',
    params: { product },
  });
}

export async function counterHistory(tenderId: string, orderId: string) {
  const url = `/tenders/${tenderId}/offers/${orderId}/bids/history`;
  return await api.get<Responses.CounterHistory>({ url, method: 'GET' });
}

export async function buyers(tenderId: string, orderId: string) {
  const url = `/tenders/${tenderId}/offers/${orderId}/displayed-prices`;
  return await api.get<Responses.OrderBuyers>({ url, method: 'GET' });
}

export async function participants(tenderId: string) {
  const url = `/tenders/${tenderId}/participants`;
  return await api.get<Responses.ParticipantsGet>({ url, method: 'GET' });
}

export async function setParticipant(
  tender: string,
  participant: string,
  value: boolean
) {
  const url = `/tenders/${tender}/participants/${participant}`;
  const method = value ? 'POST' : 'DELETE';
  return await api.modify<Responses.Participant>({ url, method });
}

export async function setParticipantGroup(
  tender: string,
  participants: string[]
) {
  return await api.modify<Responses.ParticipantsGet>({
    url: `/tenders/${tender}/participants`,
    method: 'PUT',
    data: { participants },
  });
}

export async function deleteOrder(tender: string, order: string) {
  return await api.remove<Responses.OrderGet>({
    url: `/tenders/${tender}/offers/${order}`,
    method: 'DELETE',
  });
}

export async function cancelTender(tender: string) {
  return await api.remove<Responses.TenderGet>({
    url: `/tenders/${tender}`,
    method: 'DELETE',
  });
}

export async function activity(tender: string) {
  return await analytics.get<Responses.Activity>({
    url: `/tender/${tender}/activity`,
  });
}

export async function reports(tender: string) {
  return await analytics.get<Responses.Detailed>({
    url: `/tender/${tender}/detailed`,
  });
}

export async function reportCounterHistory(tender: string) {
  return await analytics.get<Responses.Bidding>({
    url: `/tender/${tender}/bidding`,
  });
}
