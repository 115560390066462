import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { useLockBodyScroll } from '~/hooks';
import Button from './Button';
import classNames from 'classnames';
import { useSpring, animated } from 'react-spring';

const modalRoot = document.getElementById('modal-root');

export default ({ close, children, size, defaultFooter = false, ...props }) => {
  useLockBodyScroll();
  const [el] = useState(document.createElement('div'));

  const history = useHistory();

  const back = e => {
    e && e.stopPropagation();
    history.goBack();
  };

  const goBack = close || back;

  const onEsc = event => {
    if (event.keyCode === 27) goBack();
  };

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false);
    modalRoot.appendChild(el);

    return () => {
      document.removeEventListener('keydown', onEsc, false);
      modalRoot.removeChild(el);
    };
  }, []);

  const { opacity, transform } = useSpring({
    config: { duration: 150 },
    from: {
      opacity: 0,
      transform: 'translate3d(0,-40px,0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    },
  });

  const modal = (
    <animated.div style={{ opacity }} className="nui-modal-overlay">
      <div className="tc" onClick={goBack} />

      <animated.div
        style={{ opacity, transform }}
        className={classNames('nui-modal', {
          [size]: size,
        })}
        {...props}
      >
        <div className="nui-modal-content">
          <Button className="modal-close" onClick={close}>
            +
          </Button>
          {children}

          {defaultFooter && (
            <footer className="modal-footer">
              <hr className="mt-0" />
              <div className="button-set">
                <Button type="reverse" onClick={close}>
                  Close window
                </Button>
              </div>
            </footer>
          )}
        </div>
      </animated.div>
    </animated.div>
  );

  return ReactDOM.createPortal(modal, el);
};
