import React, { useEffect } from 'react';
import Tabs, { TabPane } from 'Components/nui/Tabs';
import { useSiteTitle } from '~/hooks';
import { useStoreState, useStoreActions } from '~/store';
import Edit from './Edit';
import BannerList from './BannerList';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '~/components/nui/Button';
import { BannerModelMode } from '~/store/types';

function getTitleDesc(mode: BannerModelMode) {
  switch (mode) {
    case 'new':
      return {
        id: 'banners-title-new',
        defaultMessage: 'New banner',
        description: 'Title for accounts / banners new',
      };
    case 'edit':
      return {
        id: 'banners-title-edit',
        defaultMessage: 'Edit banner',
        description: 'Title for accounts / banners edit',
      };
    default:
      return {
        id: 'banners-title',
        defaultMessage: 'Banners',
        description: 'Title for accounts / banners',
      };
  }
}

export default () => {
  const { formatMessage } = useIntl();
  const solutionId = useStoreState(state => state.auth.solution.id);
  const currentMode = useStoreState(state => state.banners.mode.current);
  const pushMode = useStoreActions(state => state.banners.mode.push);

  const bannersTitle = formatMessage(getTitleDesc(currentMode));

  useSiteTitle(bannersTitle);

  const apiSchema = useStoreActions(state => state.banners.apiSchema);
  const schema = useStoreState(state => state.banners.schema);

  const { currentItems, archivedItems } = useStoreState(state => {
    const { current: currentItems = [], expired: archivedItems = [] } =
      state.banners.items.groups;
    return { currentItems, archivedItems };
  });

  useEffect(() => {
    apiSchema();
  }, [solutionId]);

  if (!schema) return <div>Loading...</div>;

  return (
    <div className="wrapper">
      <div id="banners" className={`mode-${currentMode}`}>
        <h2>{bannersTitle}</h2>
        <Tabs
          activeKey={currentMode}
          onChange={value => {
            pushMode(value as BannerModelMode);
          }}
          renderTabBar={(props, TabBar) => (
            <div className="banners-tab-bar">
              <TabBar {...props} />
              <Button type="primary" onClick={() => pushMode('new')}>
                <FormattedMessage
                  id="banners-new-button"
                  defaultMessage="New"
                  description="Title for accounts / banners new button"
                />
              </Button>
            </div>
          )}
        >
          <TabPane
            key="list-current"
            tab={
              <FormattedMessage
                id="banners-current"
                defaultMessage="Current{count}"
                description="Title for accounts / banners current"
                values={{
                  count: !currentItems?.length
                    ? ''
                    : ` (${currentItems.length})`,
                }}
              />
            }
          >
            <BannerList items={currentItems} />
          </TabPane>
          <TabPane
            key="list-archived"
            tab={
              <FormattedMessage
                id="banners-expired"
                defaultMessage="Expired{count}"
                description="Title for accounts / banners expired"
                values={{
                  count: !archivedItems?.length
                    ? ''
                    : ` (${archivedItems.length})`,
                }}
              />
            }
          >
            <BannerList items={archivedItems} />
          </TabPane>
        </Tabs>
        <Edit key={currentMode} />
      </div>
    </div>
  );
};
