import { Tender } from '~/models/tenders';
import * as service from './internal';
import * as api from '~/fetch';

const SHEET_SIZE = 50;

function info(page: number, limit: number) {
  const current = service.storage.getHistory();
  const pages = current.pages;
  const index = (page - 1) * limit;
  const sheet = Math.floor(index / SHEET_SIZE) + 1;
  const total = current.total;
  const start = index + 1;
  const end = Math.min(index + limit, total);
  const offset = (index % SHEET_SIZE) + SHEET_SIZE * pages.indexOf(sheet);
  const slice = offset + limit;

  return { index, offset, slice, start, end, total, sheet };
}

async function load(page: number, limit: number) {
  if (SHEET_SIZE % limit !== 0) throw new Error('Invalid page size');

  const { solution } = service.storage.getOverview();
  const { sheet } = info(page, limit);

  if (!solution) throw new Error('No solution loaded');

  service.storage.onHistoryPagination({ setLoading: [[sheet, true]] });

  try {
    const response = await api.tenders.history(solution, sheet);

    await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        service.events.overview({ data });
        service.storage.onHistoryPagination({
          total: data.total ?? data.tenders.length,
          addPages: [sheet],
          setLoading: [[sheet, false]],
        });
      },
      async 204() {
        service.storage.onHistoryPagination({
          total: 0,
          addPages: [sheet],
          setLoading: [[sheet, false]],
        });
      },
      async 500() {},
      async default() {},
    });
  } catch (error) {
    console.error(error);
  }
}

type Options = Partial<Record<'limit' | 'page', string>>;
type Params = Partial<Record<'options', Options>>;
export function getOptions(options: Options) {
  const { page: _page = '1', limit: _limit = '10' } = options;
  const page = +_page;
  const limit = +_limit;

  return { page, limit };
}

export function observe() {
  return service.storage.observe(({ options = {} }: Params = {}) => [
    state => {
      const { page, limit } = getOptions(options);
      const { sheet, offset, slice } = info(page, limit);
      if (state.history.pages.includes(sheet)) {
        const tenders = state.getHistory();
        return {
          loading: false as const,
          errors: [],
          data: tenders
            .filter(tender => tender.finalised)
            .map(t => new Tender(t))
            .sort((a, b) => b.finalised?.diff(a.finalised) || 0)
            .slice(offset, slice),
        };
      } else if (!state.history.loading.includes(sheet)) load(page, limit);
      return { loading: true as const, errors: [] };
    },
    state => {
      const { page, limit } = getOptions(options);
      return [page, limit, state.history.pages.length];
    },
  ]);
}

export function controls() {
  return service.storage.observe(({ options = {} }: Params = {}) => [
    state => {
      const { page, limit } = getOptions(options);
      return {
        loading: false as const,
        errors: [],
        data: {
          loading: !!state.history.loading.length,
          total: state.history.total,
          page,
          limit,
        },
      };
    },
    state => {
      const { page, limit } = getOptions(options);
      return [page, limit, state.history.pages.length];
    },
  ]);
}
