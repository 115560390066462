import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import moment from 'moment-timezone';
import Button from 'Components/nui/Button';
import UserContent from 'Components/nui/UserContent';
import classNames from 'classnames';
import { getStatus } from '~/store/bannerModel/utils';

function useBanners({ delay = 0, interval }) {
  const solutionId = useStoreState(state => state.auth.solution.id);
  const loadBanners = useStoreActions(
    state => state.banners.apiListDisplayBanners
  );
  const displayBanners = useStoreState(state => state.banners.displayBanners);
  useEffect(() => {
    if (!solutionId) return;
    const timer = setInterval(() => loadBanners(), interval);
    return () => {
      clearInterval(timer);
    };
  }, [solutionId, interval]);

  useEffect(() => {
    if (!solutionId) return;
    const timer = setTimeout(() => loadBanners(), delay);
    return () => clearTimeout(timer);
  }, [solutionId]);

  return displayBanners.filter(banner => {
    if (getStatus(banner) !== 'live') return false;
    if (!banner.solution) return true;
    if (banner.solution.id === solutionId) return true;
    return false;
  });
}

export default () => {
  const banners = useBanners({
    interval: 60 * 1000,
  });

  const [hidden, setHidden] = useState({});

  const toggle = banner => {
    setHidden(state => ({ ...state, [banner.id]: !state[banner.id] }));
  };

  if (banners.length === 0) return null;

  return (
    <div>
      {banners
        .filter(banner => !hidden[banner.id])
        .map(banner => (
          <BannerItem
            key={banner.id}
            banner={banner}
            onClose={() => toggle(banner)}
          />
        ))}
    </div>
  );
};

const BannerItem = ({ banner, onClose }) => {
  return (
    <div
      className={classNames('nui-banner', 'nui-alert', 'has-icon', {
        info: banner.style === 'info',
        warning: banner.style === 'warning',
      })}
    >
      {banner.subject && (
        <h4>
          {banner.start && (
            <span className="date">{moment(banner.start).format('l')} | </span>
          )}
          {banner.subject}
        </h4>
      )}
      {banner.content && <UserContent data={banner.content} />}

      {banner.action_url && banner.action_text && (
        <>
          {banner.action_url.startsWith('http') ? (
            <a
              href={banner.action_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {banner.action_text}
            </a>
          ) : (
            <Link to={banner.action_url}>{banner.action_text}</Link>
          )}
        </>
      )}
      <Button
        htmlType="button"
        type="reverse"
        className="closeme"
        onClick={onClose}
      >
        &times;
      </Button>
    </div>
  );
};
