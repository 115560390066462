import { createContainer } from './models/Container';
import {
  OrderData,
  TradeData,
  ProductData,
  ParticipantData,
} from './models/modelTypes';

export const ordersContainer = createContainer<OrderData>();
export type OrdersContainer = typeof ordersContainer;

export const tradesContainer = createContainer<TradeData>();
export type TradesContainer = typeof tradesContainer;

export const productsContainer = createContainer<ProductData>();
export type ProductsContainer = typeof productsContainer;

export const participantsContainer = createContainer<ParticipantData>();
export type ParticipantsContainer = typeof participantsContainer;
