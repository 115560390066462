import type * as Types from 'models.tender';
import * as Models from '~/models/tenders';
import * as api from '~/fetch';
import * as service from './internal';
import { loadable } from '~/observable';

type RequireId<T extends boolean> = { requireId?: T };
type TenderId = Partial<Record<'tenderId', string>>;
type OrderId = Partial<Record<'orderId', string>>;

const genError = {
  loading: true as const,
  errors: ['There was an error processing this request'],
  data: undefined,
};

export function overview() {
  return service.storage.observe(() => [
    state => {
      const { errors, loading } = state.overview;
      const data = state.getOverview();

      return { loading, errors, data: data.map(t => new Models.Tender(t)) };
    },
    state => [state.overview.updated],
  ]);
}

export function tender<T extends boolean = true>(config: RequireId<T> = {}) {
  const { requireId = true } = config;

  return service.storage.observe(({ tenderId = '' }: TenderId = {}) => [
    state => {
      if (!requireId && !tenderId.length) {
        return {
          loading: false as const,
          errors: [] as string[],
          data: undefined as T extends false ? undefined : Models.Tender,
        };
      }

      const tender = state.getTender(tenderId);
      if (tender?.updated)
        return {
          loading: false as const,
          errors: tender.errors,
          data: new Models.Tender(tender.data),
        };
      return { loading: true as const, errors: [] };
    },
    state => [state.data[tenderId]?.tender.updated],
  ]);
}

export function orders<T extends boolean = true>(config: RequireId<T> = {}) {
  const { requireId = true } = config;

  return service.storage.observe(({ tenderId = '' }: TenderId = {}) => [
    state => {
      if (!requireId && !tenderId.length) {
        return {
          loading: false as const,
          errors: [] as string[],
          data: {
            tender: undefined,
            orders: [] as Models.Order[],
          } as T extends false
            ? { tender?: Models.Tender; orders: Models.Order[] }
            : { tender: Models.Tender; orders: Models.Order[] },
        };
      }

      const tender = state.getTender(tenderId);
      const orders = state.getOrders(tenderId);
      const counters = state.getCounters(tenderId);
      if (tender?.updated && orders?.updated && counters?.updated) {
        return {
          loading: false as const,
          errors: orders.errors,
          data: {
            tender: new Models.Tender(tender.data),
            orders: orders.data.map(
              o =>
                new Models.Order(
                  o,
                  counters.data.filter(c => c.offer.id === o.id)
                )
            ),
          },
        };
      }

      service.tender.load(tenderId);

      return { loading: true as const, errors: orders?.errors || [] };
    },
    state => [
      state.data[tenderId]?.tender.updated,
      state.data[tenderId]?.orders?.updated,
      state.data[tenderId]?.counters?.updated,
    ],
  ]);
}

export function order<T extends boolean = true>(config: RequireId<T> = {}) {
  const { requireId = true } = config;

  return service.storage.observe(
    ({ tenderId = '', orderId = '' }: TenderId & OrderId = {}) => [
      state => {
        const tender = state.getTender(tenderId);
        const orders = state.getOrders(tenderId);
        const counters = state.getCounters(tenderId);
        if (tender?.updated && orders?.updated && counters?.updated) {
          if (!requireId && !orderId.length) {
            return {
              loading: false as const,
              errors: orders.errors,
              data: {
                tender: new Models.Tender(tender.data),
                order: undefined,
              } as T extends false
                ? { tender: Models.Tender; order?: Models.Order }
                : { tender: Models.Tender; order: Models.Order },
            };
          }

          const order = orders.data.find(o => o.id === orderId)!;

          if (!order) {
            return {
              loading: false as const,
              errors: ['order is undefined'],
              data: {
                tender: new Models.Tender(tender.data),
                order: new Models.Order(
                  order,
                  counters.data.filter(() => false)
                ),
              },
            };
          }

          return {
            loading: false as const,
            errors: orders.errors,
            data: {
              tender: new Models.Tender(tender.data),
              order: new Models.Order(
                order,
                counters.data.filter(c => c.offer.id === order.id)
              ),
            },
          };
        }
        return { loading: true as const, errors: orders?.errors || [] };
      },
      state => [
        state.data[tenderId]?.tender.updated,
        state.data[tenderId]?.orders?.updated,
        state.data[tenderId]?.counters?.updated,
      ],
    ]
  );
}

export async function queryTender(tenderId: string) {
  let resolve: (val: Models.Tender | undefined) => void;
  let promise = new Promise<Models.Tender | undefined>(
    res => void (resolve = res)
  );

  tender({ requireId: false })(value => {
    if (!value.loading) resolve(value.data);
  })({ tenderId });

  return promise;
}

export async function queryOrder(tenderId: string, orderId: string) {
  let resolve: (val: { tender: Models.Tender; order: Models.Order }) => void;
  let promise = new Promise<{ order: Models.Order; tender: Models.Tender }>(
    res => void (resolve = res)
  );
  order()(value => {
    if (!value.loading) resolve(value.data);
  })({ orderId, tenderId });

  return promise;
}

type Loader<T> =
  | { loading: true; errors: string[]; data?: undefined }
  | {
      loading: false;
      errors: string[];
      data: T;
    };

type POrderHistory = TenderId & OrderId;
type LOrderHistory = Loader<Models.Order[]>;
export function orderHistory() {
  return loadable<LOrderHistory, POrderHistory>(
    async (onChange, { tenderId = '', orderId = '' } = {}) => {
      onChange({ loading: true as const, errors: [] });

      try {
        const response = await api.tenders.order(tenderId, orderId);
        await api.utils.processResponse(response, {
          async 200(response) {
            const data = await response.json();
            const { history = [], ...offer } = data.offer;
            onChange({
              loading: false as const,
              errors: [],
              data: history.map(o => new Models.Order({ ...offer, ...o }, [])),
            });
          },
          async default() {
            onChange(genError);
          },
        });
      } catch (error) {
        console.error('order-history: unknown error', error);
        onChange(genError);
      }
    }
  );
}

type PCounterHistory = TenderId & OrderId;
type LCounterHistory = Loader<Models.Counter[]>;
export function counterHistory() {
  return loadable<LCounterHistory, PCounterHistory>(
    async (onChange, { tenderId = '', orderId = '' } = {}) => {
      onChange({ loading: true as const, errors: [] });

      try {
        const response = await api.tenders.counterHistory(tenderId, orderId);
        await api.utils.processResponse(response, {
          async 200(response) {
            const { history } = await response.json();
            const order = service.storage
              .getOrders(tenderId)
              .data?.find(o => o.id === orderId);

            if (order) {
              const ordertype = order.ordertype === 'offer' ? 'bid' : 'offer';
              const offer = { id: order.id };
              const pid = '';
              const href = '';
              const isowner = false;
              const __acl__ = { edit: false, delete: false };

              onChange({
                loading: false as const,
                errors: [],
                data: history.map(
                  ({ bid, price, volume, ...counter }) =>
                    new Models.Counter(
                      {
                        pid,
                        href,
                        offer,
                        isowner,
                        ordertype,
                        __acl__,
                        price: { ...order.startprice, ...price },
                        volume: { ...order.volume, ...volume },
                        ...counter,
                      },
                      order
                    )
                ),
              });
            } else {
              onChange(genError);
            }
          },
          default() {
            onChange(genError);
          },
        });
      } catch (error) {
        console.log('counter-history: unknown error', error);
        onChange(genError);
      }
    }
  );
}

type PBuyers = TenderId & OrderId;
type LBuyers = Loader<{ buyers: Types.OrderBuyer[]; order: Models.Order }>;
export function buyers() {
  return loadable<LBuyers, PBuyers>(
    async (onChange, { tenderId = '', orderId = '' } = {}) => {
      onChange({ loading: true as const, errors: [] });

      try {
        const response = await api.tenders.buyers(tenderId, orderId);
        await api.utils.processResponse(response, {
          async 200(response) {
            const data = await response.json();
            const { order } = await queryOrder(tenderId, orderId);
            const { prices: buyers = [] } = data;

            onChange({
              loading: false as const,
              errors: [],
              data: { buyers, order },
            });
          },
          async default() {
            onChange(genError);
          },
        });
      } catch (error) {
        console.error('order-history: unknown error', error);
        onChange(genError);
      }
    }
  );
}

async function loadParticipants(tenderId: string) {
  service.storage.initParticipants(tenderId);
  try {
    const response = await api.tenders.participants(tenderId);
    await api.utils.processResponse(response, {
      async 200(response) {
        const data = await response.json();
        service.storage.onParticipants(tenderId, data);
      },
      async default(response) {
        console.error('Unknown error while loading participants', response);
      },
    });
  } catch (error) {
    console.error('Unknown error while loading participants', error);
  }
}

export async function refreshParticipants(tenderId: string) {
  const participants = service.storage.getParticipants(tenderId);
  if (participants?.updated) await loadParticipants(tenderId);
  return;
}

export function participants() {
  return service.storage.observe(({ tenderId = '' }: TenderId = {}) => [
    state => {
      const tender = state.getTender(tenderId);
      const participants = state.getParticipants(tenderId);

      if (tender?.updated && participants?.updated) {
        return {
          loading: false as const,
          errors: tender.errors,
          data: {
            tender: new Models.Tender(tender.data),
            participants: participants.data.map(p => new Models.Participant(p)),
          },
        };
      } else if (!participants) {
        loadParticipants(tenderId);
      }
      return {
        loading: false as const,
        errors: [],
        data: {
          tender: new Models.Tender(tender.data!),
          participants: [],
        },
      };
    },
    state => [
      state.data[tenderId]?.tender.updated,
      state.participants[tenderId]?.updated,
    ],
  ]);
}
