import React, { useState, useEffect } from 'react';
import { Loading, Table, Tooltip, Switch } from 'Components/nui';
import { useStoreState } from 'easy-peasy';
import R from 'ramda';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { useSharedCustomer } from '..';
import { Link } from 'react-router-dom';
import Freight from './Freight';
import { inArrayIf, canEditCustomer } from '~/utils';

const List = ({ preselected, products, customer }) => {
  const solution = useStoreState(state => state.auth.solution);
  const editable = useStoreState(canEditCustomer(customer));

  const [selected, setSelected] = useState(() => preselected.map(x => x.id));
  const [selIncoterms, setSelIncoterms] = useState(() => {
    let d = {};
    preselected.forEach(x => {
      d[x.id] = x.productlinks[0].incoterm;
    });
    return d;
  });

  const saveProduct = async (id, incoterm) => {
    toast.success('Product added to the list');
    try {
      await api.putData(`/customers/${customer.id}/products/${id}`, {
        incoterm,
      });
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors.incoterm.length === 1) {
        toast.error(
          'Customer needs to have at least one location set up to be able to see product prices and trade. Please contact this customer or Nui support to set up a location'
        );
      } else {
        toast.error(`Something went wrong`);
      }
    }
  };

  const delProduct = async id => {
    toast.success('Product removed from the list');
    try {
      await api.deleteData(`/customers/${customer.id}/products/${id}`);
    } catch (err) {
      toast.error(`Something went wrong {product: ${id}}`);
    }
  };

  const onChange = (c_id, checked) => {
    const newData = checked
      ? R.append(c_id, selected)
      : R.without([c_id], selected);

    setSelected(newData);
    if (checked) saveProduct(c_id, selIncoterms[c_id] || defIncoterm);
    else delProduct(c_id);
  };

  const onSelChange = (c_id, value) => {
    const newData = { ...selIncoterms, [c_id]: value };
    setSelIncoterms(newData);
    saveProduct(c_id, value);
  };

  const incoterms = R.pathOr([], ['incoterms'], solution);
  const defIncoterm = R.pathOr('EXW', [0, 'id'], incoterms);

  const options = incoterms.map(x => (
    <option key={x.id} value={x.id}>
      {x.id}
    </option>
  ));

  const columns = [
    ...inArrayIf(solution.freight === 'product', {
      title: '',
      key: 'expand',
      className: 'col-expand',
    }),
    ...inArrayIf(editable, {
      title: 'Active',
      key: 'switcher',
      className: 'col-switcher',
      render: (text, record, index) => (
        <div className="td-content">
          <Switch
            checked={selected.includes(record.id)}
            onChange={checked => onChange(record.id, checked)}
          />
        </div>
      ),
    }),
    {
      title: 'Product',
      key: 'product',
      className: 'col-buyer shaded-right',
      render: (text, record, index) => (
        <div className="td-content">
          <Link to={`/products/${record.id}/quick`}>
            <Tooltip
              title={
                <span className="icon-tooltip">
                  <span className="icon-info-circled"></span>View product
                  details in new window
                </span>
              }
            >
              {record.name}
              <span className="nowrap">
                &#65279;<span className="icon-link-ext-alt"></span>
              </span>
            </Tooltip>
          </Link>
        </div>
      ),
    },
    {
      title: 'Division',
      key: 'division',
      className: 'col-division',
      render: (text, record, index) => (
        <div className="td-content">
          {record.division.name} ({record.division.shortcode})
        </div>
      ),
    },
    {
      title: 'Incoterm',
      key: 'incoterm',
      className: 'col-incoterm',
      render: (text, record, index) => (
        <div className="td-content">
          <div className="nui-fieldset inline-block p-0 m-0 mt--2">
            <div className="select-holder tiny">
              <select
                value={R.propOr(defIncoterm, record.id, selIncoterms)}
                onChange={e => onSelChange(record.id, e.target.value)}
                disabled={!selected.includes(record.id) || !editable}
              >
                {options}
              </select>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="generic-table expand-table light">
      <Table
        className="table-wrapper th-sticky mb--5"
        columns={columns}
        expandedRowRender={
          solution.freight_types.includes('product')
            ? record => (
                <Freight
                  product={record}
                  customer={customer}
                  enabled={selected.includes(record.id)}
                  incoterm={selIncoterms[record.id]}
                />
              )
            : null
        }
        // expandedRowClassName={(record, i) => `ex-row-${i}`}
        data={products}
      />
    </div>
  );
};

export default () => {
  const solution = useStoreState(state => state.auth.solution);

  const [customer] = useSharedCustomer();

  const [preselected, setPreselected] = useState(null);
  const [products, setProducts] = useState(null);

  useEffect(() => {
    const getResult = x => {
      const ids = customer.links.map(item => item.seller.id);

      let all = [];
      x.products.forEach(item => {
        if (R.includes(item.division.id, ids)) {
          all.push(item);
        }
      });
      return R.sortBy(R.compose(R.toLower, R.prop('name')))(all);
    };

    const fetch = async () => {
      const data = await api.getData(
        {
          type: 'products',
          getResult,
        },
        { solution: solution.id }
      );
      setProducts(data);
    };
    if (solution && customer) fetch();
  }, [api, solution, customer]);

  useEffect(() => {
    const fetch = async () => {
      const response = await api.getData({
        type: 'customers',
        id: `${customer.id}/products`,
        getResult: x => x.products,
      });
      setPreselected(response);
    };
    if (customer) fetch();
  }, [customer]);

  if (preselected === null || products === null)
    return <Loading size="medium" />;

  return (
    <List preselected={preselected} products={products} customer={customer} />
  );
};
