import { computed, Computed } from 'easy-peasy';
import { User, Solution, Role, Rank, AuthModel } from './types';
import R from 'ramda';

interface StoreModel {
  auth: AuthModel;
}

export interface AuthorisationModel {
  user: Computed<AuthorisationModel, User, StoreModel>;
  solution: Computed<AuthorisationModel, Solution, StoreModel>;
  roles: Computed<AuthorisationModel, Role[], StoreModel>;

  hasRole: Computed<AuthorisationModel, (role: Rank) => boolean>;

  traderType: Computed<AuthorisationModel, { seller: boolean; buyer: boolean }>;
}

const authorisationModel: AuthorisationModel = {
  user: computed(
    [(state, store) => R.path(['auth', 'user'], store)],
    user => user
  ),
  solution: computed(
    [(state, store) => R.path(['auth', 'solution'], store)],
    solution => solution
  ),
  roles: computed(
    [(state, store) => R.pathOr([], ['auth', 'solution', 'roles'], store)],
    user => user
  ),
  traderType: computed(
    [
      (state, store) =>
        R.pathOr(
          { seller: false, buyer: false },
          ['auth', 'traderType'],
          store
        ),
    ],
    user => user
  ),

  hasRole: computed(
    state => role => R.includes(role, state.roles.map(R.prop('role')))
  ),
};
export default authorisationModel;
