import type * as Struct from 'struct';
import type { Tender, Order } from '~/models/tenders';
import type { FormComponentProps } from 'antd/lib/form';
import type { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { useFormWrapper } from 'Pages/Orders/New';
import { Button, Form } from 'Components/nui';
import { Product } from '~/models';
import { useMountedState } from '~/hooks';
import { promisify } from 'util';
import { toast } from 'react-toastify';
import R from 'ramda';
import { capitalize } from '~/utils';
import * as services from '~/services/tenders';
import { useStoreState } from '~/store';

type TenderProp = Record<'tender', Tender>;
type OrderProp = Record<'order', Order>;
type OnSuccess = Record<'onSuccess', (form: WrappedFormUtils) => void>;

type IOrderForm = FormComponentProps &
  TenderProp &
  Partial<OrderProp> &
  OnSuccess &
  Record<'product', any> &
  Record<'schema', Struct.Schema>;
const BaseForm = ({
  form,
  tender,
  order,
  product,
  schema,
  onSuccess,
}: IOrderForm) => {
  const [loading, setLoading] = useMountedState(false);
  const solution = useStoreState(state => state.auth.solution);
  const formWrapper = useFormWrapper({
    form,
    product: new Product(product),
    initialData: { ...(order?.formdata || {}) },
    schema: {
      ...schema,
      ...(!!order?.files?.length && {
        attachments: {
          required: false,
          type: 'MultipleSelect',
          value: order.files.map(R.prop('id')),
          choices: order.files.map(f => [f.id, f.filename]),
        },
      }),
    },
    hideTotals: true,
    orderType: tender.ordertype.order,
    solution,
  });

  async function handleSubmit() {
    setLoading(true);

    try {
      await promisify(form.validateFieldsAndScroll)();
    } catch (err) {
      setLoading(false);
      return;
    }

    const { files, ...fields } = formWrapper.serialize();
    const formdata = new FormData();
    formdata.append('product', product.id);
    for (const [name, value] of Object.entries(fields)) {
      if (value !== undefined) {
        if (Array.isArray(value))
          for (const v of value) formdata.append(name, v);
        else formdata.append(name, value as string);
      }
    }
    if (files) for (const f of files) formdata.append('files[]', f);

    const result = await services.actions.editOrder(
      formdata,
      tender.id,
      order?.id
    );

    if (result?.success) {
      const action = order?.id ? 'updated' : 'placed';
      const otype = tender.ordertype.order;
      toast.success(
        `${capitalize(otype)} for ${product.name} ${action} successfully.`
      );
      onSuccess(form);
    } else if (result?.errors) {
      for (const e of result.errors) toast.error(e);
    } else if (result?.validate) {
      const {
        frometd = [],
        toetd = [],
        attributes = {},
        ...validate
      } = result.validate;

      const etd = [...frometd, ...toetd];
      const etdv = etd.length ? { etd } : {};

      const attrErr = Object.fromEntries(
        Object.entries(attributes).map(([name, errors]) => [
          `attributes_${name}`,
          errors,
        ])
      );

      formWrapper.setErrors({ frometd, ...etdv, ...validate, ...attrErr });
    }

    setLoading(false);
  }

  return (
    <Form
      className="nui-form tender-form"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {formWrapper.render()}
      <div className="sticky-btm button-set">
        <Button
          htmlType="submit"
          type="primary"
          disabled={loading}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

const OrderForm = Form.create<IOrderForm>()(BaseForm);
export default OrderForm;
