import Helmet from 'Components/Helmet';
import Header from './Header';
import Filter from './Filter';
import React from 'react';
import {
  NavLink,
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useScrollToTopOnMount } from '~/hooks';
import { createStateContext } from 'react-use';
import CustomerEngagement from './CustomerEngagement';
import TradeInformation from './TradeInformation';
import TradeIndex from './GlobalCommodityPricing';
import FilterTradeInformation from './TradeInformation/Filter';
import FilterCustomerEngagement from './CustomerEngagement/Filter';
import FilterTradeIndex from './GlobalCommodityPricing/Filter';
import R from 'ramda';
import { useStoreState } from '~/store';
import { Alert } from 'Components/nui';

export const [useSharedFilter, SharedFilterProvider] = createStateContext();

export const [useSharedTIData, SharedTIDataProvider] = createStateContext({});
export const [useFilterTIData, FilterTIDataProvider] = createStateContext({});

export const [useSharedCEData, SharedCEDataProvider] = createStateContext({});
export const [useFilterCEData, FilterCEDataProvider] = createStateContext({});

export const [useFilterIndexData, FilterIndexDataProvider] = createStateContext(
  {}
);

export default () => {
  useScrollToTopOnMount();
  const { url } = useRouteMatch();

  const solution = useStoreState(state => state.auth.solution);

  const dashboards = R.propOr([], 'dashboards', solution);

  const { pathname } = useLocation();

  if (dashboards.length === 0) return <Redirect to="/" />;

  return (
    <SharedFilterProvider>
      <SharedCEDataProvider>
        <FilterCEDataProvider>
          <SharedTIDataProvider>
            <FilterTIDataProvider>
              <FilterIndexDataProvider>
                <Helmet>
                  <title>Dashboard</title>
                </Helmet>
                <div className="dashboard2">
                  <Header />
                  <div className="full-page-header dashboard-header">
                    <div className="dashboard-tabs nui-tabs-nav">
                      <nav id="dashboard-nav">
                        {dashboards.includes('indexes') ? (
                          <>
                            <NavLink
                              exact
                              to={url}
                              title="Global commodity pricing"
                            >
                              Global commodity pricing
                            </NavLink>
                            {dashboards.includes('trade') && (
                              <NavLink
                                to={`${url}/trade-information`}
                                title="Trade information"
                              >
                                Trade information
                              </NavLink>
                            )}
                          </>
                        ) : (
                          dashboards.includes('trade') && (
                            <NavLink exact to={url} title="Trade information">
                              Trade information
                            </NavLink>
                          )
                        )}
                        {dashboards.includes('user') && (
                          <NavLink
                            to={`${url}/customer-engagement`}
                            title="Customer engagement"
                          >
                            Customer engagement
                          </NavLink>
                        )}
                      </nav>
                    </div>
                    <div className="dashboard-filter">
                      {dashboards.includes('indexes') ? (
                        pathname !== '/dashboard' && <Filter />
                      ) : (
                        <Filter />
                      )}
                      <hr className="m-0 mt-10 show-for-small-only" />
                    </div>
                  </div>

                  <div className="full-page-header mt--1 pt-0">
                    <div className="filter-section dashboard-filter opened">
                      <div className="filter-block">
                        <Switch>
                          <Route path={`${url}/customer-engagement`}>
                            <FilterCustomerEngagement />
                          </Route>
                          <Route path={`${url}/trade-information`}>
                            <FilterTradeInformation />
                          </Route>
                          <Route>
                            {dashboards.includes('indexes') ? (
                              <FilterTradeIndex />
                            ) : (
                              <FilterTradeInformation />
                            )}
                          </Route>
                        </Switch>
                      </div>
                    </div>

                    <Switch>
                      <Route path={`${url}/customer-engagement`}>
                        <CustomerEngagement />
                      </Route>
                      <Route path={`${url}/trade-information`}>
                        <TradeInformation />
                      </Route>
                      <Route>
                        {dashboards.includes('indexes') ? (
                          <TradeIndex />
                        ) : (
                          <TradeInformation />
                        )}
                      </Route>
                    </Switch>
                  </div>
                </div>
              </FilterIndexDataProvider>
            </FilterTIDataProvider>
          </SharedTIDataProvider>
        </FilterCEDataProvider>
      </SharedCEDataProvider>
    </SharedFilterProvider>
  );
};

export const ColorSquare = ({ color }) => (
  <span
    style={{
      background: color,
      display: 'inline-block',
      width: 12,
      height: 12,
    }}
  />
);

export const TooltipWrapper = ({ children }) => {
  return <div className="nui-tooltip">{children}</div>;
};

export const AlternateBarBackground =
  keys =>
  ({ bars, innerHeight }) => {
    const rects = bars
      .slice(0, Math.floor(bars.length / keys.length))
      .filter((_, index) => index % 2 === 0);

    return (
      <g>
        {rects.map(rect => (
          <rect
            key={rect.key}
            x={rect.x - (rect.width * keys.length) / 2}
            y={0}
            width={rect.width * keys.length * 2}
            height={innerHeight}
            style={{
              fill: 'rgba(0,0,0,.03)',
            }}
          />
        ))}
      </g>
    );
  };

// still wrong, but okk
export const AlternateBackground = ({ series, slices, ...props }) => {
  const rects = slices.filter((_, index) => index % 2 === 1);

  const width = R.pathOr(0, [0, 'width'], rects);

  return (
    <g>
      {slices.map((rect, index) => (
        <rect
          key={rect.id}
          x={rect.x0}
          y={0}
          width={rect.width}
          height={rect.height}
          style={{
            fill: index % 2 === 1 ? 'rgba(0,0,0,.05)' : 'transparent',
          }}
        />
      ))}
    </g>
  );
};

export const NoDataAvailable = () => (
  <Alert hasicon type="info" className="align-center mt-20">
    No data available for the selected date range.
  </Alert>
);

export const ApiError = () => (
  <Alert hasicon type="error" className="align-center mt-20">
    There was an error loading this data
  </Alert>
);

export const majorColors = ['#0d8abc', '#df700d', '#bfbfbf', '#fcb441'];

export const colors = [
  '#7abfda',
  '#fcb441',
  '#e0400a',
  '#056492',
  '#bfbfbf',
  '#1a3b69',
  '#ffe382',
  '#129cdd',
  '#ca6b4b',
  '#005cdb',
  '#f1b9a8',
  '#7893be',
  '#edb07a',
  '#418cf0',
];

export const pairedThemeColors = [
  'rgb(166, 206, 227)',
  'rgb(31, 120, 180)',
  'rgb(178, 223, 138)',
  'rgb(51, 160, 44)',
  'rgb(251, 154, 153)',
  'rgb(227, 26, 28)',
  'rgb(253, 191, 111)',
  'rgb(255, 127, 0)',
  'rgb(202, 178, 214)',
  'rgb(106, 61, 154)',
  'rgb(255, 255, 153)',
  'rgb(177, 89, 40)',
];
