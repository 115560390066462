import React from 'react';
import YesNo from 'Components/YesNo';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const SplittableColumn = ColumnTree.defineNode(
  'splittable',
  _context => ({
    title: (
      <FormattedMessage
        id="marketplace-table-heading-splittable"
        description="Splittable, repeated for cell headings"
        defaultMessage="Splittable"
      />
    ),
    children: [
      {
        title: '',
        key: 'splittable',
        className: 'col-splittable',
        render: (text, record, index) => (
          <div className="td-content">
            <span className="tiny-label">
              <FormattedMessage
                id="marketplace-table-heading-splittable"
                description="Splittable, repeated for cell headings"
                defaultMessage="Splittable"
              />
            </span>
            <YesNo yes={record.splittable} />
          </div>
        ),
      },
    ],
  })
);
