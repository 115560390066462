import type * as Market from 'models.market';

import React, { useState } from 'react';
import { Button, Form, Alert } from 'Components/nui';
import * as actions from './actions';
import { toast } from 'react-toastify';
import { useStoreActions } from '~/store';

interface IRespond {
  order: Market.Order;
  counter: Market.Counter;
  children: (onRespond: () => void) => React.ReactElement;
}
export default ({ order, counter, children }: IRespond) => {
  const [responding, setResponding] = useState(false);

  if (!responding) return children(() => void setResponding(true));

  return (
    <RespondForm
      order={order}
      counter={counter}
      onCancel={() => setResponding(false)}
    />
  );
};

interface IRespondForm {
  order: Market.Order;
  counter: Market.Counter;
  onCancel: () => void;
}
const RespondForm = ({ order, counter, onCancel }: IRespondForm) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const mktUpdate = useStoreActions(state => state.market.update);

  const counterType = order.type === 'offer' ? 'bid' : 'offer';

  const hasError = error && !value.length ? 'error' : '';

  const handleSubmit = (decline: boolean) => async () => {
    if (!value.length) {
      setError(true);
      return;
    }

    setLoading(true);

    const result = await actions.respond(order.id, counter.id, {
      decline,
      reason: value,
    });

    if (result.success) {
      await mktUpdate();
      toast.success(<>The comment was sent</>);
    }

    if (result.error) {
      toast.error(<>There was an unexpected error. Please try again later.</>);
    }

    setLoading(false);
    onCancel();
  };

  return (
    <Alert className="respond-alert nui-form">
      <label htmlFor="reason">Respond with comment</label>
      <p>
        Please add a comment when responding or add a reason for declining the
        counter {counterType}. Please note that declining will remove the
        counter {counterType}.
      </p>
      <Form.Item id="reason" validateStatus={hasError}>
        <textarea
          id="reason"
          className="ant-input"
          value={value}
          onChange={e => void setValue(e.target.value)}
        />
        {hasError && (
          <div className="ant-form-explain">
            <ul>
              <li>This field is required</li>
            </ul>
          </div>
        )}
      </Form.Item>
      <div className="button-set">
        <Button
          className="m-0 mr-10"
          type="primary"
          size="small"
          loading={loading}
          disabled={loading}
          onClick={handleSubmit(false)}
        >
          Respond
        </Button>
        <Button
          className="m-0 mr-10"
          type="secondary"
          size="small"
          onClick={handleSubmit(true)}
          loading={loading}
          disabled={loading}
        >
          Decline
        </Button>
        <Button
          className="right m-0 mt-7 pl-0 pr-0"
          type="simple"
          size="small"
          htmlType="button"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </Alert>
  );
};
