import React, { useState } from 'react';
import { useAsync, createStateContext } from 'react-use';
import { useParams } from 'react-router-dom';
import { Trade } from '~/models';
import Header from './Header';
import Content from './Content';
import Actions from './Actions';
import Helmet from 'Components/Helmet';
import { api } from '~/api';
import { Loading } from 'Components/nui';
import { useInterval } from 'react-use';

export const [useSharedTrade, SharedTradeProvider] = createStateContext();

const Loader = () => {
  const { id } = useParams();
  const [trade, setTrade] = useSharedTrade();

  const [count, setCount] = useState(0);
  useInterval(() => {
    setCount(count + 1);
  }, 1000 * 60 * 5);

  useAsync(async () => {
    const data = await api.getData({
      type: 'trades',
      id,
      getResult: x => x.trade,
    });

    setTrade(new Trade(data));
  }, [id, count]);

  if (!trade) return <Loading size="large" />;

  return (
    <Helmet>
      <title>{`Trade ${trade.pid}`}</title>
    </Helmet>
  );
};

export default () => {
  return (
    <SharedTradeProvider>
      <div className="full-page-header">
        <Loader />
        <Header />
      </div>
      <div className="full-page-header p-0">
        <div className="post-trade has-trade-info">
          <div className="nui-row">
            <hr />
          </div>
          <Actions />
          <Content />
        </div>
      </div>
    </SharedTradeProvider>
  );
};
