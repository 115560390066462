import React from 'react';
import R from 'ramda';
import { useStoreState } from 'easy-peasy';
import Fok from './FokCounter/Fok';
import Decline from './Decline';
import { OrderQuestionAndAnswer } from './QuestionAndAnswer';
import { Alert, Tooltip } from 'Components/nui';
import Tabs, { TabPane } from 'Components/nui/Tabs';
import { parseSearch } from '~/utils';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import CounterTab from './Counters';
import { FormattedMessage } from 'react-intl';

export default ({ order, product, setCurCur, doRefresh }) => {
  const history = useHistory();
  const location = useLocation();
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);

  const productsTradeActions = useStoreState(
    state => state.auth.userProductsTradeActions
  );
  const p_id = R.prop('p_id', product);
  const tradeActions = R.pathOr([], [p_id], productsTradeActions);
  const questionsCount = useStoreState(state =>
    state.marketdepth.orderQuestionsCount(order.id)
  );

  const countertype = order.type === 'offer' ? 'bid' : 'offer';

  const can = {
    trade: true,
    qa: solutionSettings.orderquestions,
    decline:
      order.type === 'bid' &&
      R.pathOr(true, ['biddecline'], solutionSettings) &&
      R.prop('allowdecline', tradeActions),
    counter:
      order.type === 'offer'
        ? R.pathOr(true, ['counterbids'], solutionSettings) &&
          R.prop('buy', tradeActions) &&
          !order.is_owner
        : R.pathOr(true, ['counteroffers'], solutionSettings) &&
          R.prop('sell', tradeActions) &&
          !order.is_owner,
    counters: true,
  };

  const { tab } = parseSearch(R.path(['location', 'search'], history));

  const onTabClick = key =>
    history.push(
      `${location.pathname}?${qs.stringify({
        order: order.id,
        tab: key,
      })}`
    );
  const defaultKey = order?.counteronly ? 'counters' : tab;
  return (
    <Tabs
      animated={false}
      defaultActiveKey={defaultKey}
      activeKey={tab in can && can[tab] ? tab : defaultKey}
      onTabClick={onTabClick}
    >
      <TabPane
        tab={
          <FormattedMessage
            id="marketrow-tabs-header-trade"
            description="Header for Trade on tabs in Marketrow"
            defaultMessage="Trade"
          />
        }
        key="trade"
      >
        {order.tradeable ? (
          <Fok order={order} product={product} setCurCur={setCurCur} />
        ) : (
          <>
            {order.is_owner ? (
              <>
                <Alert type="warning" className="has-icon mt-10 mb-10">
                  <h4>
                    <FormattedMessage
                      id="marketrow-tabs-trade-label-nottradeable"
                      description="Label for `Not tradeable reason` field on qa in Marketrow"
                      defaultMessage="Not tradeable reason"
                    />
                  </h4>
                  {order.reason}
                </Alert>

                {order.isdeclined && (
                  <>
                    <hr className="blank mt-0 show-for-medium" />
                    <Alert className="has-icon mt-10" type="error">
                      <h4>
                        <FormattedMessage
                          id="marketrow-tabs-trade-warning-orderdeclined"
                          description="warning for `Your order was declined` on trade tab in Marketrow"
                          defaultMessage="Your order was declined"
                        />
                      </h4>
                      {order.declinereason}
                    </Alert>
                  </>
                )}
              </>
            ) : order.counteronly ? (
              // I'm going to put an ugly-ass comment here so I can complain
              // about this ugly-ass formatting.
              <Alert type="warning" hasicon>
                This {order.type} is{' '}
                <strong className="all-black inline">
                  subject to final confirmation (SFC)
                </strong>
                . Select the{' '}
                <strong className="all-black inline">Counters</strong> tab to
                place a firm {countertype}.
              </Alert>
            ) : (
              <Alert className="has-icon mt-10" type="error">
                <h4>
                  <FormattedMessage
                    id="marketrow-tabs-trade-label-nottradeable"
                    description="Label for `Not tradeable reason` field on qa in Marketrow"
                    defaultMessage="Not tradeable reason"
                  />
                </h4>
                {/* I'm very VERY sorry */}
                {order.reason === 'Insufficient available credit' &&
                order.type === 'offer' ? (
                  <FormattedMessage
                    id="marketrow-tabs-trade-warning-nocredit"
                    description="warning for `no credit` on trade tab in Marketrow"
                    defaultMessage="Insufficient available credit. Contact '{name}' to request credit extension"
                    values={{ name: R.path(['division', 'name'], order) }}
                  />
                ) : (
                  order.reason
                )}
              </Alert>
            )}
            <hr className="blank mt-0 show-for-medium" />
          </>
        )}
      </TabPane>

      {can.decline && (
        <TabPane
          tab={
            <FormattedMessage
              id="marketrow-tabs-header-decline"
              description="Header for Decline on tabs in Marketrow"
              defaultMessage="Decline"
            />
          }
          key="decline"
        >
          {order.isdeclined ? (
            <Alert className="has-icon" type="warning">
              <h4>
                <FormattedMessage
                  id="marketrow-tabs-trade-label-decliningreason"
                  description="Label for `Reason for declining` field on qa in Marketrow"
                  defaultMessage="Reason for declining"
                />
              </h4>
              {order.declinereason}
            </Alert>
          ) : (
            <Decline order={order} />
          )}
        </TabPane>
      )}

      <TabPane
        tab={
          <>
            Counters
            {order.counters && order.counters.length > 0 && (
              <span className="notification">{order.counters.length}</span>
            )}
          </>
        }
        key="counters"
      >
        <CounterTab order={order} can={{ add: can.counter }} />
      </TabPane>

      {can.qa && (
        <TabPane
          tab={
            <>
              <FormattedMessage
                id="marketrow-tabs-header-qa"
                description="Header for Q/A on tabs in Marketrow"
                defaultMessage="Q&A"
              />
              {!!questionsCount && (
                <Tooltip
                  title={
                    <span className="icon-tooltip">
                      <span className="icon-info-circled" />
                      Questions and answers
                    </span>
                  }
                >
                  <span className="notification">{questionsCount}</span>
                </Tooltip>
              )}
            </>
          }
          key="qa"
        >
          <OrderQuestionAndAnswer order={order} />
        </TabPane>
      )}
    </Tabs>
  );
};
