import { useStoreState } from '~/store';
import { allowRolesIf } from 'Components/Authorised';
import R from 'ramda';

export function useCustomers() {
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const isSeller = useStoreState(state => state.auth.traderType.seller);

  const hasCustomers = () =>
    isSeller && R.path(['matchinglevel'], solutionSettings) !== 'commodity';

  return {
    _acl: {
      view: allowRolesIf('guest', 'trader', 'manager')(hasCustomers),
      add: allowRolesIf('manager')(hasCustomers),
      edit: allowRolesIf('manager')(hasCustomers),
      delete: allowRolesIf('manager')(hasCustomers),
    },
  };
}
