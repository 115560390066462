import React, { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import R from 'ramda';
import { useToggle, useClickAway, useWindowSize } from 'react-use';
import { Authorised } from 'Components/Authorised';
import {
  usePartners,
  useCustomers,
  useParticipants,
  useWarehouses,
  useConsolidation,
  useDeliveries,
  CustomerRequestController,
} from '~/store/models';
import { Tooltip, Button, Loading } from 'Components/nui';
import { getEnv, capitalize } from '~/utils';
import { TendersLink } from './Tenders';
import { useLocale } from '~/lang';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

const MobileMenuLink = ({ exact, to, title, iconClass }) => (
  <li>
    <NavLink
      exact={exact}
      to={to}
      activeClassName="selected"
      className="box"
      title={title}
    >
      <span className={iconClass}>{title}</span>
    </NavLink>
  </li>
);

const MobileMenu = () => {
  const { marketplace, tender } = useStoreState(
    state => state.auth.solutionSettings
  );
  return (
    <div className="mbar mobile-nav">
      <ul>
        {marketplace && (
          <MobileMenuLink
            exact
            to="/"
            title="Marketplace"
            iconClass="icon-bank"
          />
        )}
        {tender && (
          <MobileMenuLink
            to="/tenders"
            title="Tenders"
            iconClass="icon-hammer"
          />
        )}
        <MobileMenuLink
          to="/orders"
          title="Orders"
          iconClass="icon-doc-text-inv"
        />
        <MobileMenuLink to="/trades" title="Trades" iconClass="icon-exchange" />
      </ul>
    </div>
  );
};

const SecondaryMenu = () => {
  const { width } = useWindowSize();
  const participants = useParticipants();
  const customers = useCustomers();
  const warehouses = useWarehouses();
  const partners = usePartners();
  const consolidation = useConsolidation();
  const deliveries = useDeliveries();
  const isSolutionOwner = useStoreState(state => state.auth.isSolutionOwner);
  const isdao = useStoreState(state => state.auth.isdao);
  const solution = useStoreState(state => state.auth.solution);
  const routeTypes = ['port', 'zone'];
  const hasRoutes =
    isSolutionOwner &&
    R.any(R.includes(R.__, routeTypes), [
      ...solution.freight_types,
      solution.freight,
    ]);

  const ref = useRef(null);
  const [open, toggle] = useToggle(false);
  useClickAway(ref, () => {
    toggle(false);
  });

  const SecondaryMenuLink = ({ to, title }) => (
    <li>
      <NavLink
        to={to}
        activeClassName="selected"
        title={title}
        onClick={toggle}
      >
        {title}
      </NavLink>
    </li>
  );

  return (
    <div ref={ref} className="secondary-holder">
      <Tooltip
        title={
          <span className="icon-tooltip">
            <span className="icon-info-circled"></span>
            View pages
          </span>
        }
      >
        <button className="icon-ellipsis show-more" onClick={toggle} />
      </Tooltip>
      {open && (
        <nav className={classNames('secondary', 'open')}>
          <ul>
            {width <= 992 && (
              <>
                <SecondaryMenuLink to="/orders" title="Orders" />
                <SecondaryMenuLink to="/trades" title="Trades" />
                <Authorised to="view" model={participants}>
                  <SecondaryMenuLink to="/participants" title="Participants" />
                </Authorised>
                <Authorised to="view" model={consolidation}>
                  <SecondaryMenuLink
                    to="/consolidation"
                    title="Consolidation"
                  />
                </Authorised>
                <Authorised to="view" model={deliveries}>
                  <SecondaryMenuLink to="/deliveries" title="Deliveries" />
                </Authorised>
              </>
            )}
            {hasRoutes && <SecondaryMenuLink to="/routes" title="Routes" />}
            <Authorised to="view" model={partners}>
              <SecondaryMenuLink to="/partners" title="Partners" />
            </Authorised>

            <SecondaryMenuLink to="/products" title="Products" />
            <Authorised to="view" model={warehouses}>
              <SecondaryMenuLink to="/locations" title="Locations" />
            </Authorised>
            <Authorised to="view" model={customers}>
              <SecondaryMenuLink to="/customers" title="Customers" />
            </Authorised>
            <Authorised to="view" model={CustomerRequestController}>
              <SecondaryMenuLink
                to="/customer-requests"
                title="Customer requests"
              />
            </Authorised>
            {(isSolutionOwner || isdao) && (
              <SecondaryMenuLink to="/reports" title="Reports" />
            )}
            <SecondaryMenuLink to="/users" title="Users" />
          </ul>
        </nav>
      )}
    </div>
  );
};

const DashboardButton = () => {
  const solution = useStoreState(state => state.auth.solution);

  const dashboards = R.propOr([], 'dashboards', solution);

  if (dashboards.length === 0) return null;

  return (
    <Tooltip
      title={
        <span className="icon-tooltip">
          <span className="icon-info-circled" />
          Dashboard
        </span>
      }
    >
      <NavLink
        to="/dashboard"
        activeClassName="selected"
        className="icon-chart-bar"
        title="Dashboard"
      />
    </Tooltip>
  );
};

const TradeButtons = () => {
  const tradeActions = useStoreState(state => state.auth.userTradeActions);
  const marketplace = useStoreState(
    state => state.auth.solutionSettings.marketplace
  );

  if (!marketplace) return <></>;

  return (
    <div className="bset button-set">
      {R.includes('buy', tradeActions) && (
        <Tooltip
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled"></span>
              Placing a bid means you are buying
            </span>
          }
        >
          <Link to="/orders/bid" role="button" className="button bid">
            <FormattedMessage
              id="navbar-button-placebid"
              description="Place bid button"
              defaultMessage="Place bid"
            />
          </Link>
        </Tooltip>
      )}
      {R.includes('sell', tradeActions) && (
        <Tooltip
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              Placing an offer means you are selling
            </span>
          }
        >
          <Link to="/orders/offer" role="button" className="button offer">
            <FormattedMessage
              id="navbar-button-placeoffer"
              description="Place offer button"
              defaultMessage="Place offer"
            />
          </Link>
        </Tooltip>
      )}
    </div>
  );
};

const Lang = () => {
  const ref = useRef();
  const [open, toggle] = useToggle(false);
  const locale = useLocale();

  useClickAway(ref, () => void toggle(false));

  const style = {
    backgroundColor: 'white',
  };

  const onClick = value => async () => {
    const { success } = await locale.change(value);

    if (success) window.location.reload();
    else {
      toast.error('Could not update language.');
    }
  };

  const isSelected = id => id === locale.current;

  return (
    <div ref={ref} className="language-switcher">
      <Button type="buttonlink" className="toggleme" onClick={toggle}>
        <span className="icon-globe"></span>
        <span className="arrow current-language">
          {locale.current.toUpperCase()}
        </span>
      </Button>
      <nav className={classNames('dd-list', { open })}>
        <span className="change-lang">
          Change language{' '}
          <Button type="buttonlink" className="closeme" onClick={toggle}>
            +
          </Button>
        </span>
        <ul style={style}>
          {locale.options.map(([id, name]) => (
            <li key={id} className={classNames({ selected: isSelected(id) })}>
              <Button
                type="buttonlink"
                disabled={isSelected(id) || locale.loading}
                onClick={onClick(id)}
              >
                <span>{id.toUpperCase()}</span> {name}
              </Button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

const HelpSection = () => (
  <div className="help-section config-links has-lang-switcher">
    <div className="configure-me">
      <div className="icon-set">
        <Link
          className="icon-cog"
          to="/account/config/notifications"
          title="Settings"
        >
          <span>
            <FormattedMessage
              id="navbar-tab-settings"
              description="Settings option in navbar"
              defaultMessage="Settings"
            />
          </span>
        </Link>
        <Lang />
      </div>
    </div>
  </div>
);

const MainNav = () => {
  const domain = getEnv(process.env.REACT_APP_OLD_DOMAIN);
  const user = useStoreState(state => state.auth.userModel);
  const isadmin = R.equals(
    'ea5befd8-958c-466e-96b5-a8513a0c416e',
    R.path(['company', 'id'], user)
  );

  const { marketplace, tender } = useStoreState(
    state => state.auth.solutionSettings
  );
  const totalOffers = useStoreState(state => state.market.totalOffers);
  const totalBids = useStoreState(state => state.market.totalBids);
  const { width } = useWindowSize();
  const participants = useParticipants();
  const consolidation = useConsolidation();
  const deliveries = useDeliveries();

  return (
    <nav className="main">
      <ul>
        {marketplace && (
          <li className="marketplace-link">
            {(totalOffers > 0 || totalBids > 0) && (
              <span className="activity-status">
                {!!totalOffers && (
                  <>
                    <FormattedMessage
                      id="navbar-tab-marketplace-sub-offers"
                      description="Total {count} of offers"
                      defaultMessage="{count, plural, =1 {1 offer} other {# offers}}"
                      values={{ count: totalOffers }}
                    />
                    {!!totalBids && ' | '}
                  </>
                )}
                {!!totalBids && (
                  <FormattedMessage
                    id="navbar-tab-marketplace-sub-bids"
                    description="Total {count} of bids"
                    defaultMessage="{count, plural, =1 {1 bid} other {# bids}}"
                    values={{ count: totalBids }}
                  />
                )}
              </span>
            )}

            <NavLink
              to="/"
              activeClassName="selected"
              className="icon-bank"
              title="Marketplace"
              isActive={(match, location) =>
                ['/', '/overview'].includes(location.pathname)
              }
            >
              <FormattedMessage
                id="navbar-tab-marketplace"
                description="Top level/navbar marketplace link"
                defaultMessage="Marketplace"
              />
            </NavLink>
          </li>
        )}

        {tender && <TendersLink />}

        {width > 992 && (
          <>
            <li className="navsmall">
              <NavLink to="/orders" activeClassName="selected" title="Orders">
                <FormattedMessage
                  id="navbar-tab-orders"
                  description="Orders option in navbar"
                  defaultMessage="Orders"
                />
              </NavLink>
            </li>
            <li className="navsmall">
              <NavLink to="/trades" activeClassName="selected" title="Trades">
                <FormattedMessage
                  id="navbar-tab-trades"
                  description="Trades option in navbar"
                  defaultMessage="Trades"
                />
              </NavLink>
            </li>

            <Authorised to="view" model={participants}>
              <li className="navsmall">
                <NavLink
                  to="/participants"
                  activeClassName="selected"
                  title="Participants"
                >
                  <FormattedMessage
                    id="navbar-tab-participants"
                    description="Participants option in navbar"
                    defaultMessage="Participants"
                  />
                </NavLink>
              </li>
            </Authorised>

            <Authorised to="view" model={consolidation}>
              <li className="navsmall">
                <NavLink
                  to="/consolidation"
                  title="Consolidation"
                  activeClassName="selected"
                >
                  <FormattedMessage
                    id="navbar-tab-consolidation"
                    description="Consolidation option in navbar"
                    defaultMessage="Consolidation"
                  />
                </NavLink>
              </li>
            </Authorised>
            <Authorised to="view" model={deliveries}>
              <li className="navsmall">
                <NavLink
                  to="/deliveries"
                  title="Deliveries"
                  activeClassName="selected"
                >
                  <FormattedMessage
                    id="navbar-tab-deliveries"
                    description="Deliveries option in navbar"
                    defaultMessage="Deliveries"
                  />
                </NavLink>
              </li>
            </Authorised>
            {isadmin && (
              <li className="navsmall">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${domain}/signin`}
                  title="Admin"
                  className="admin"
                >
                  Admin
                </a>
              </li>
            )}
          </>
        )}
      </ul>
    </nav>
  );
};

// const DashboardButton2 = () => null;

const MainMenu = () => {
  const { width } = useWindowSize();

  return (
    <div role="navigation" className="nbar navbar">
      {/* {width > 768 && (
        <div className="navigation">
          <DashboardButton />
        </div>
      )} */}
      <div className="navigation">
        <DashboardButton />
      </div>
      <TradeButtons />
      {width > 768 && (
        <div className="navigation">
          <MainNav />
          <SecondaryMenu />
        </div>
      )}
      <HelpSection />
    </div>
  );
};

export default () => {
  const { width } = useWindowSize();

  return (
    <div className="sticky-nav">
      {width <= 768 && <MobileMenu />}
      <MainMenu />
    </div>
  );
};
