import React from 'react';
import OrderTable from './Table';
import { useOTable } from '~/store/models';
import { useScrollToTopOnMount } from '~/hooks';
import Header from './Header';
import Helmet from 'Components/Helmet';

export default () => {
  useScrollToTopOnMount();

  const orders = useOTable();
  const { pagination } = orders;

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <Header />

      <div className="full-page-content">
        {orders.noContent() ? (
          <div className="nui-row full-page-messaging align-center mt--40 p-100 pb-110">
            <div className="icon-inbox bordered align-center">
              There are currently no orders to display
            </div>
          </div>
        ) : (
          <OrderTable orders={orders} pagination={pagination} />
        )}
      </div>
    </>
  );
};
