import { action, computed, thunk, persist } from 'easy-peasy';
import moment from 'moment-timezone';
import R from 'ramda';
import { Order } from '~/models';
import { v4 as uuid } from 'uuid';

const filterData = {
  fProducts: [],
  fTags: [],
  fType: [],
  fEtd: [],
  fUpdated: [],
  fOrigins: [],
  fLocations: [],
};

const locations = [
  ['8dfe0ba1-76f8-4df0-8c5d-d6af1cd445ea', 'Austria'],
  ['2ea59c24-4ca3-497f-96a9-826badfebdfb', 'Belgium'],
  ['509e7635-2e77-41be-b951-111245909b53', 'Bulgaria'],
  ['3463ee60-afe2-4c60-ac5e-df5c488d12bd', 'Czech Republic'],
  ['0940b038-0519-4bfe-9daa-64d7904a2d31', 'Denmark'],
  ['2cbb8db3-4a98-4862-9de8-0bb4fc4be388', 'England - North-Midlands'],
  ['cec8a23f-af24-46a4-a687-347f1470a034', 'England - South'],
  ['3cc85343-8eb0-4989-94ed-c1dfd469766a', 'Estonia'],
  ['f6c68af7-e454-4be0-b798-4a91fcb487d6', 'EU15'],
  ['85ec0771-1095-4f42-8dc3-5c90647eab21', 'EU27'],
  ['c7634052-d519-4e01-b7aa-dd79d3cf40d7', 'Finland'],
  ['424a374b-0203-420a-9797-e5ff0e42c000', 'France'],
  ['249af4e3-9c62-4801-8434-299e2803cc4a', 'France - North/East'],
  ['fe513646-7d8f-45ed-bf59-8cee504c3f32', 'France - South/West'],
  ['aa6b5b86-5007-4152-ac02-00a5a12cfef5', 'Germany'],
  ['6e64a2fd-713c-4710-ba74-fe9c95d727e5', 'Germany - East'],
  ['27594e74-ae9b-4496-b3fd-5b85f9597196', 'Germany - North/West'],
  ['24961d17-6b64-45ec-96ed-19f467c0db77', 'Germany - South'],
  ['ed4429ef-691a-4035-a117-295284b531bf', 'Hungary'],
  ['b54eabb2-aeea-4b61-a78b-712ec7d58cd8', 'Ireland'],
  ['c0710abe-c6c3-4f8b-9ca8-c7638f7aa407', 'Italy'],
  ['ddcfa26e-cf7c-4259-9563-c642b1b5b347', 'Latvia'],
  ['e70c7fbb-1235-42c0-ac7f-6eb1a36d53db', 'Lithuania'],
  ['6ef91734-9d91-49e1-a636-646d5fe1d683', 'Netherlands'],
  ['03275298-7a20-4024-b8f5-177bcba86bd4', 'Netherlands/Belgium/Germany'],
  [
    '07026886-0094-491f-bdd2-443c324109d0',
    'Netherlands/Belgium/Germany/France',
  ],
  ['7a47c86a-97c5-4f77-be0d-2291ad30647f', 'Northern Ireland'],
  ['e9e73200-8434-426f-a0b6-82e88e53f3e4', 'Poland'],
  ['54ed08d9-206f-4ac5-8cb6-9a782b0e68ad', 'Portugal'],
  ['431747ec-69ed-4580-befd-35a717115322', 'Romania'],
  ['ca1e1422-cf42-4fa1-b66a-5663081c5988', 'Scotland'],
  ['9a43c6d8-8b2a-4aff-a29f-4799e0e533b2', 'Spain'],
  ['d326bce5-c2e3-4469-9d0a-2ee8d3c688d9', 'United Kingdom'],
];

const origins = [
  ['a0123c84-72c3-4b07-b0f8-e574d73563f9', 'Austria'],
  ['87aa82c3-236a-4513-a283-a4e44ca6ecc8', 'Belgium'],
  ['dfd04190-f4a1-4bf2-bfc3-bc88eb49f175', 'Bulgaria'],
  ['0d2b5d5b-13cf-4682-b2d5-6aac6a9ed27c', 'Czech Republic'],
  ['8343b1b1-d637-4866-ab92-a0d8b3bba491', 'Denmark'],
  ['175f1324-9061-4c77-b19d-e7747fc45cd1', 'Estonia'],
  ['bd2368ce-ee82-4066-abe9-f5adcb02ee70', 'EU15'],
  ['1f5ff3e1-79a2-4916-b1b0-c38fdf623f5f', 'EU27'],
  ['d22b21ab-4e2b-476d-a7dc-4d171f9fd0ac', 'Finland'],
  ['424a374b-0203-420a-9797-e5ff0e42c011', 'France'],
  ['302ade84-dbeb-4587-843e-93f9436ccf1f', 'Germany'],
  ['d6a32152-d7df-4c47-b85f-c20cdc758b4f', 'Hungary'],
  ['da8f5ae7-f12d-49bf-b93b-2a8ddc2d73a9', 'Ireland'],
  ['ceb24f5a-8053-4d75-b703-f0c80d91f272', 'Italy'],
  ['e8e34571-0a26-4d0f-992a-50893f98f4d3', 'Latvia'],
  ['07f95138-7753-4a30-8641-e9801b8b6271', 'Lithuania'],
  ['61c5fb81-feef-4154-8a06-1b65ab3e8cb6', 'Netherlands'],
  ['b40a749f-8e0f-4b6a-9217-f747f69d545c', 'Netherlands/Belgium/Germany'],
  [
    '083edd17-6a0e-47c4-be63-e9d51c075857',
    'Netherlands/Belgium/Germany/France',
  ],
  ['863a4fc6-7752-4a86-991f-39468caacb33', 'Poland'],
  ['828d0927-75c9-44ba-90e1-9217cb7fc4ee', 'Portugal'],
  ['9020cace-fbcd-4f33-b674-18340e18160f', 'Romania'],
  ['6d97e42b-5f1f-40c7-99f0-3dd30173c648', 'Spain'],
  ['59a057a6-d89c-4a1e-98c3-c1523f3c5a91', 'United Kingdom'],
];

const defData = {
  division: null,
  warehouse: null,
  orders: null,
  updatedDate: null,
  sortDirection: 'down',
  sortColumn: 'created',
  prevFilterData: null,
};

export default persist(
  {
    origins,
    locations,
    filterVis: false,
    ...filterData,
    ...defData,

    tagProducts: computed(
      [state => state, (_, storeState) => storeState.auth.solutionProducts],
      (state, products) => {
        if (!products) return {};

        let tor = {};
        products.forEach(item => {
          if (item.tags) {
            item.tags.forEach(tag => {
              if (!(tag.id in tor)) tor[tag.id] = [];
              tor[tag.id].push(item.id);
            });
          }
        });
        return tor;
      }
    ),

    setData: action((state, payload) => {
      const keys = Object.keys(payload);
      keys.forEach(key => {
        if (
          ['fEtd', 'fUpdated'].includes(key) &&
          Array.isArray(payload[key]) &&
          payload[key].length === 2
        ) {
          state[key] = [moment(payload[key][0]), moment(payload[key][1])];
        } else {
          state[key] = payload[key];
        }
      });
    }),

    reset: action((state, _) => {
      const keys = Object.keys(defData);
      keys.forEach(key => (state[key] = defData[key]));
    }),

    resetFilter: action((state, _) => {
      const keys = Object.keys(filterData);
      keys.forEach(key => (state[key] = filterData[key]));
    }),

    saveFilter: action((state, _) => {
      const keys = Object.keys(filterData);
      let data = {};
      keys.forEach(key => (data[key] = filterData[key]));
      state.prevFilterData = data;
    }),

    recoverFilter: action((state, _) => {
      const keys = Object.keys(filterData);
      keys.forEach(
        key => (state[key] = R.pathOr([], [key], state.prevFilterData))
      );
    }),

    filterData: computed(state => {
      const keys = Object.keys(filterData);
      const data = {};
      keys.forEach(key => (data[key] = state[key]));
      return data;
    }),

    filteredOrders: computed(state => {
      if (!state.orders) return state.orders;

      let filtered = state.orders;

      if (state.fProducts.length !== 0) {
        filtered = R.filter(
          item => state.fProducts.includes(item.product.id),
          filtered
        );
      }

      if (state.fType.length > 0) {
        filtered = R.filter(
          x => state.fType.includes(R.path(['type'], x)),
          filtered
        );
      }

      if (state.fEtd && state.fEtd.length === 2) {
        const etdFrom = state.fEtd[0].startOf('day');
        const etdTo = state.fEtd[1].startOf('day');

        filtered = R.filter(
          x => moment(x.etd.from) <= etdTo && moment(x.etd.to) >= etdFrom,
          filtered
        );
      }

      if (state.fUpdated && state.fUpdated.length === 2) {
        const updatedFrom = state.fUpdated[0].startOf('day');
        const updatedTo = state.fUpdated[1].endOf('day');

        filtered = R.filter(
          x =>
            moment(x.updated) <= updatedTo && moment(x.updated) >= updatedFrom,
          filtered
        );
      }

      if (state.fOrigins.length > 0) {
        filtered = R.filter(
          x =>
            !x.origins ||
            state.fOrigins.includes(R.path(['origins', 0, 'id'], x)),
          filtered
        );
      }

      if (state.fLocations.length > 0) {
        filtered = R.filter(
          x =>
            !x.locations ||
            state.fLocations.includes(R.path(['locations', 0, 'id'], x)),
          filtered
        );
      }

      if (state.fTags.length !== 0) {
        filtered = R.filter(x => {
          let flag = false;
          state.fTags.forEach(item => {
            if (
              R.pathOr([], [item], state.tagProducts).includes(
                R.path(['product', 'id'], x)
              )
            ) {
              flag = true;
            }
          });

          return flag;
        }, filtered);
      }

      return filtered;
    }),

    totalOrders: computed(state => (state.orders ? state.orders.length : 0)),
    totalOffers: computed(state =>
      state.orders ? state.orders.filter(x => x.type === 'offer').length : 0
    ),
    totalBids: computed(state =>
      state.orders ? state.orders.filter(x => x.type === 'bid').length : 0
    ),

    totalFilteredOrders: computed(state =>
      state.filteredOrders ? state.filteredOrders.length : 0
    ),

    isFiltered: computed(state => {
      const keys = Object.keys(filterData);
      for (let i = 0; i < keys.length; i++) {
        if (!R.equals(state[keys[i]], filterData[keys[i]])) return true;
      }
      return false;
    }),

    update: thunk(
      async (actions, _, { injections: { api }, getState, getStoreState }) => {
        const storeState = getStoreState();
        const { solution } = storeState.auth;
        const store = getState();
        const { updatedDate, warehouse } = store;
        const newDate = new Date();

        let params = { solution: solution.id };
        if (warehouse) {
          params['warehouse'] = warehouse.id;
        }
        // Orders
        let orders = [];
        try {
          let [status, response] = await api.getDataIfModifiedSince(
            '/market-orders',
            params,
            updatedDate
          );

          if (status === 200) {
            const changed = response.orders.map(o => new Order(o));
            let ids = [];
            changed.forEach(o => {
              o.status === 'Working' && orders.push(o);
              ids.push(o.id);
            });
            if (updatedDate && store.orders !== null) {
              store.orders.forEach(order => {
                if (!ids.includes(order.id)) {
                  orders.push(order);
                }
              });
            }

            actions.setData({
              orders,
            });
          }

          actions.setData({
            updatedDate: newDate,
          });
        } catch (error) {
          // console.log(error)
        }
      }
    ),

    deletePreset: thunk(
      async (
        _,
        id,
        { injections: { api }, getStoreState, getStoreActions }
      ) => {
        const storeState = getStoreState();
        const updateUser = getStoreActions().auth.updateUser;

        const prefs = storeState.auth.userPrefs;
        const presets = R.pathOr([], ['marketplace', 'presets'], prefs).filter(
          item => item.id !== id
        );
        const newPrefs = R.mergeDeepRight(prefs, {
          marketplace: { presets },
        });
        updateUser({ prefs: newPrefs });

        try {
          const response = await api.patchData('/user/prefs', newPrefs);
          updateUser({ prefs: response });
        } catch (error) {
          // pass
        }
      }
    ),

    savePreset: thunk(
      async (
        _,
        payload,
        { injections: { api }, getState, getStoreState, getStoreActions }
      ) => {
        const state = getState();
        const storeState = getStoreState();
        const updateUser = getStoreActions().auth.updateUser;

        const prefs = storeState.auth.userPrefs;
        const presets = R.pathOr([], ['marketplace', 'presets'], prefs);
        presets.push({ id: uuid(), data: state.filterData, ...payload });
        const newPrefs = R.mergeDeepRight(prefs, {
          marketplace: { presets },
        });
        updateUser({ prefs: newPrefs });

        try {
          const response = await api.patchData('/user/prefs', newPrefs);
          updateUser({ prefs: response });
        } catch (error) {
          // pass
        }
      }
    ),
  }
  // {
  //   whitelist: ['presets'],
  // }
);
