import React from 'react';
import IndexProducts from './IndexProducts';
import LeftHandPanel from './LeftHandPanel';

export default () => {
  return (
    <div className="global-comodity-pricing">
      <LeftHandPanel />
      <IndexProducts />
    </div>
  );
};
