import React from 'react';
import { Switch, useLocation, Route, Redirect } from 'react-router-dom';
import { Alert } from 'Components/nui';

type IView = (props: {}) => React.ReactElement;
interface IRoutingTable {
  routes?: IView[];
  redirect?: string;
  children?: React.ReactNode;
}
export const RoutingTable = ({
  routes = [],
  redirect,
  children,
  ...props
}: IRoutingTable) => {
  const location = useLocation();

  return (
    <Switch location={location} {...props}>
      {children}
      {routes.map(route => route({}))}
      {redirect && <Redirect to={redirect} />}
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

const PageNotFound = () => {
  return <Alert type="error">Page not found</Alert>;
};
