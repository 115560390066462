import React from 'react';
import R from 'ramda';

import AddressesList from 'Components/AddressesList';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default ({ record }) => {
  const data = [];

  if (R.hasPath(['seller', 'name'], record)) {
    data.push([
      <FormattedMessage
        id="tradeparties-field-seller-label"
        description="Label for 'Seller' field on Tradeparties"
        defaultMessage="Seller"
      />,
      R.path(['seller', 'name'], record),
      'stacked',
    ]);
  }

  if (
    R.hasPath(['offer', 'is_owner'], record) &&
    (record.offer.is_owner || record.offer.is_broker)
  ) {
    data.push([
      <FormattedMessage
        id="tradeparties-field-offer-label"
        description="Label for 'Offer' field on Tradeparties"
        defaultMessage="Offer"
      />,
      <Link to={`/orders/${record.offer.id}`}>{record.offer.pid}</Link>,
    ]);
  }

  if (R.hasPath(['buyer', 'name'], record)) {
    data.push([
      <FormattedMessage
        id="tradeparties-field-buyer-label"
        description="Label for 'Buyer' field on Tradeparties"
        defaultMessage="Buyer"
      />,
      R.path(['buyer', 'name'], record),
      'stacked',
    ]);
  }

  if (
    R.hasPath(['bid', 'is_owner'], record) &&
    (record.bid.is_owner || record.bid.is_broker)
  ) {
    data.push([
      <FormattedMessage
        id="tradeparties-field-bid-label"
        description="Label for 'Bid' field on Tradeparties"
        defaultMessage="Bid"
      />,
      <Link to={`/orders/${record.bid.id}`}>{record.bid.pid}</Link>,
    ]);
  }

  if ('fromaddr' in record)
    data.push([
      <FormattedMessage
        id="tradeparties-field-deliveryfrom-label"
        description="Label for 'Delivery from' field on Tradeparties"
        defaultMessage="Delivery from"
      />,
      <AddressesList addresses={record.fromaddr} />,
      'stacked',
    ]);

  if ('toaddr' in record) {
    data.push([
      <FormattedMessage
        id="tradeparties-field-deliveryto-label"
        description="Label for 'Delivery to' field on Tradeparties"
        defaultMessage="Delivery to"
      />,
      <AddressesList addresses={record.toaddr} />,
      'stacked',
    ]);
  }

  if ('paymentterm' in record) {
    data.push([
      <FormattedMessage
        id="tradeparties-field-paymentterm-label"
        description="Label for 'Payment term' field on Tradeparties"
        defaultMessage="Payment term"
      />,
      record.paymentterm,
      'auto',
    ]);
  }

  return (
    <>
      <h2>
        <FormattedMessage
          id="parties-header"
          description="Header for Parties"
          defaultMessage="Parties"
        />
      </h2>
      <ul className="trade-details-list">
        {data.map(([key, value, className]) => (
          <li key={key} className={className}>
            <strong>{key}</strong>
            <span>{value}</span>
          </li>
        ))}
      </ul>
    </>
  );
};
