import { action, Action, computed, Computed } from 'easy-peasy';

export interface IStackModel<T> {
  items: T[];
  current: Computed<IStackModel<T>, T>;
  push: Action<IStackModel<T>, T>;
  pop: Action<IStackModel<T>, void>;
}

export default function stackModel<T>(
  initialItems: T[],
  predicate: (value: T, previous: T) => boolean
): IStackModel<T> {
  return {
    items: initialItems,
    current: computed(state => state.items[state.items.length - 1]),
    push: action((state, payload) => {
      if (predicate && !predicate(payload, state.items[state.items.length - 1]))
        return;
      state.items.push(payload);
    }),
    pop: action(state => {
      state.items.pop();
    }),
  };
}
