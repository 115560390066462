import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';
import { useStoreActions } from 'easy-peasy';
import { useHistory, useLocation } from 'react-router-dom';
import footerlogo from 'Assets/img/mono-logo-black.png';
import moment from 'moment-timezone';
import { api } from '~/api';
import { getEnv, parseSearch } from '~/utils';
import R from 'ramda';
import MaskedInput from 'react-text-mask';
import Helmet from 'Components/Helmet';
import EnvStripe from 'Components/EnvStripe';
import { Button, Form } from 'Components/nui';

const validateEmail = email => /\S+@\S+\.\S+/.test(email);

export default () => {
  const checkAuth = useStoreActions(actions => actions.auth.checkAuth);
  const authSetLoading = useStoreActions(actions => actions.auth.setLoading);
  const clientId = getEnv(process.env.REACT_APP_WEB_CLIENT_ID);

  const history = useHistory();
  const search = parseSearch(R.path(['location', 'search'], history));

  const location = useLocation();
  const camefrom = R.pathOr('/', ['state', 'from'], location);

  const solution = R.path(['solution'], search);
  const urlSolution = R.path(['solution_id'], search);

  useEffect(() => {
    document.body.classList.add('nui-login');
    return () => document.body.classList.remove('nui-login');
  }, []);

  const refEmail = useRef(null);

  const [step, setStep] = useState(search.token ? 2 : 1);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState(search.token || '');
  const [loading, setLoading] = useState(false);

  const initialFi = {
    hasFeedback: false,
    validateStatus: null,
    help: '',
  };
  const [fi, setFi] = useState(initialFi);

  useEffect(() => {
    if (refEmail.current) refEmail.current.focus();
  }, []);

  function resetFi() {
    setFi(initialFi);
  }

  function stepBack() {
    setStep(1);
    resetFi();
  }

  async function authEmail() {
    if (!validateEmail(email)) {
      setFi({
        hasFeedback: true,
        validateStatus: 'error',
        help: 'Please provide a valid email address',
      });
      return;
    }
    setLoading(true);
    setToken('');
    setFi({
      hasFeedback: true,
      validateStatus: 'success',
      help: '',
    });

    try {
      await api.signIn(email, solution);
      setLoading(false);
      setStep(2);
      resetFi();
    } catch (error) {
      setFi({
        hasFeedback: true,
        validateStatus: 'error',
        help: 'Something went wrong. Please refresh the page and try again.',
      });
    }
  }

  async function callbackAuth() {
    setLoading(true);
    try {
      const response = await api.authenticate(token, clientId);

      setFi({
        hasFeedback: true,
        validateStatus: 'success',
        help: 'Redirecting...',
      });
      const { token_type, user_id, access_token, expires_in } = response;
      checkAuth({
        token: {
          user_id,
          access_token,
          token_type,
          expires_in,
        },
        target: R.pathOr({}, ['target'], response),
      });
    } catch (error) {
      setLoading(false);
      authSetLoading(false);
      setFi({
        hasFeedback: true,
        validateStatus: 'error',
        help: R.pathOr(
          'An error occurred please refresh the page and try again',
          ['response', 'data', 'error_description'],
          error
        ),
      });
    }
  }

  useEffect(() => {
    if (token) {
      callbackAuth();
    } else {
      checkAuth({ camefrom, urlSolution });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <EnvStripe />
      <div className="shell">
        <div className="nui-login-form">
          <h1>Please sign in</h1>
          <img
            src={`https://cream-media.nuimarkets.com/logo/signin/${
              solution || 'nui'
            }.png`}
            alt="Nui Markets"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src =
                'https://cream-media.nuimarkets.com/logo/signin/nui.png';
            }}
          />

          {step === 1 ? (
            <>
              <Form.Item
                hasFeedback={fi.hasFeedback}
                validateStatus={fi.validateStatus}
                help={fi.help}
              >
                <Input
                  ref={refEmail}
                  placeholder="Your email"
                  id="email"
                  type="email"
                  size="large"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onPressEnter={authEmail}
                />
              </Form.Item>
              <Button
                type="primary"
                className="w-200"
                loading={loading}
                onClick={authEmail}
              >
                Continue
              </Button>
            </>
          ) : (
            <>
              {email ? (
                <>
                  <h2>Check your inbox</h2>
                  <p>
                    We just emailed a token to{' '}
                    <strong className="all-black">{email}</strong>. Please enter
                    it below or{' '}
                    <button className="inline-button" onClick={stepBack}>
                      try again
                    </button>{' '}
                    if you have not received anything.
                  </p>
                </>
              ) : (
                <>
                  {fi && fi.validateStatus === 'success' ? (
                    <h2>Redirecting...</h2>
                  ) : (
                    <>
                      <h2>Authentication</h2>
                      <p>
                        The sign in link is no longer valid.{' '}
                        <button
                          className="inline-button"
                          onClick={() => {
                            history.push('/signin');
                            stepBack();
                          }}
                        >
                          Please start over
                        </button>
                      </p>
                    </>
                  )}
                </>
              )}
              <Form.Item
                hasFeedback={fi.hasFeedback}
                validateStatus={fi.validateStatus}
                help={fi.help}
              >
                <MaskedInput
                  mask={[
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    '-',
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    '-',
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    '-',
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                    /[a-fA-F0-9]/,
                  ]}
                  className="ant-input ant-input-lg"
                  placeholder="Token"
                  guide={false}
                  id="token"
                  autoComplete="off"
                  onChange={e => setToken(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      callbackAuth();
                    }
                  }}
                  value={token}
                />
              </Form.Item>
              <div className="pl-20 pr-20 mt--10">
                <Button type="primary" loading={loading} onClick={callbackAuth}>
                  Verify
                </Button>
              </div>
            </>
          )}
        </div>

        <div className="nui-login-footer">
          <img src={footerlogo} alt="Nui Markets logo" />
          <span>&copy; 2014 - {moment().year()} Nui Markets</span>{' '}
          <span className="show-for-medium pl-5 pr-5">|</span>
          <span className="block-for-small">
            <strong>Trouble signing in?</strong>{' '}
            <a
              href="https://www.nuimarkets.com/contact/"
              title="Contact us if you are having trouble signing in"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a>
          </span>
          <ul>
            <li>
              <a
                href="https://www.nuimarkets.com/privacy/"
                title="Privacy policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </li>
            <li>
              <a
                href="https://www.nuimarkets.com/terms/"
                title="Legal terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Legal terms
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
