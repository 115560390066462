import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import {
  colors,
  pairedThemeColors,
  ColorSquare,
  NoDataAvailable,
  TooltipWrapper,
  useSharedCEData,
} from 'Pages/Dashboard';
import R from 'ramda';
import { Tooltip } from 'Components/nui';
import { useToggle } from 'react-use';

export default () => {
  const [data] = useSharedCEData();
  const rawData = R.propOr({}, 'company-activity-percentages', data);
  const [loading, toggle] = useToggle(true);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (R.isEmpty(rawData)) return;

    let thelist = [];
    const parse = (value, key) => {
      thelist.push({
        id: key,
        label: key,
        value: value.toFixed(2),
        color: pairedThemeColors[thelist.length % pairedThemeColors.length],
        percent: value.toFixed(2),
      });
    };
    R.forEachObjIndexed(parse, rawData);
    thelist = R.reverse(R.sortBy(x => x.value)(thelist));
    setChartData(thelist);
    toggle(false);
  }, [rawData]);

  return R.isEmpty(rawData) ? (
    !loading && (
      <div className="chart-container pie-chart-container">
        <h3>
          Company activity
          <Tooltip
            className="icon-help-circled"
            placement="bottom"
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                Shows user activity by company over the selected timeframe.
              </span>
            }
          />
        </h3>
        <NoDataAvailable />
      </div>
    )
  ) : (
    <div className="chart-container pie-chart-container">
      <h3>
        Company activity
        <Tooltip
          className="icon-help-circled"
          placement="bottom"
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              Shows user activity by company over the selected timeframe.
            </span>
          }
        />
      </h3>

      <section>
        <div className="chart pie-chart">
          <ResponsivePie
            data={chartData}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            innerRadius={0.3}
            padAngle={0}
            cornerRadius={0}
            activeOuterRadiusOffset={8}
            borderWidth={0}
            // colors={{ scheme: 'paired' }}
            colors={{ datum: 'data.color' }}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            enableArcLinkLabels={false}
            arcLabelsSkipAngle={15}
            arcLabel={item => `${Math.round(item.value)}%`}
            tooltip={({ datum }) => (
              <TooltipWrapper>
                <p className="nowrap p-0">
                  <span className="inline-block mt-2 mr-5">
                    <ColorSquare color={datum.data.color} />{' '}
                  </span>
                  <strong className="all-black">{datum.data.id}</strong>
                </p>
                <hr className="mt-2 mb-2" />
                <p className="p-0">{datum.data.percent}%</p>
              </TooltipWrapper>
            )}
          />
        </div>

        <div className="chart-legend">
          <table className="table">
            <thead>
              <tr>
                <th colSpan="2">Company</th>
                <th className="align-right">%</th>
              </tr>
            </thead>
            <tbody>
              {chartData.map(item => (
                <tr key={item.id}>
                  <td>
                    <span className="block mt-2">
                      <ColorSquare color={item.color} />
                    </span>
                  </td>
                  <td>{item.id}</td>
                  <td className="align-right">{item.percent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
