export const areas = {
  details: {
    title: 'Product details',
    description: 'Set up basic product information',
    step: 1,
    previous: null,
    next: 'specifications',
  },
  specifications: {
    title: 'Specifications',
    description:
      'Upload the specifications and certifications related to the product',
    step: 2,
    previous: 'details',
    next: 'loading-details',
  },
  'loading-details': {
    title: 'Loading options',
    description: 'Set up the loading configuration for this product',
    step: 3,
    previous: 'specifications',
    next: 'attributes',
  },
  attributes: {
    title: 'Product attributes',
    description: 'Add product attributes, such as size, colour, etc. ',
    step: 4,
    previous: 'loading-details',
    next: 'warehouses',
  },
  locations: {
    title: 'Select locations',
    description: 'Select the locations that this product ships from',
    step: 5,
    previous: 'attributes',
    next: 'customers',
  },
  customers: {
    title: 'Select customers',
    description:
      'Select which customers can buy this product as well as the applicable incoterm',
    step: 6,
    previous: 'warehouses',
    next: null,
  },
};
