import React from 'react';
import R from 'ramda';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const LegendColumn = ColumnTree.defineNode(
  'legend',
  ({ solutionSettings }) => ({
    title: (
      <div className="legenda">
        <span className="legenda-item nowrap">
          <span className="my"></span>
          <span className="exp">
            ={' '}
            <FormattedMessage
              id="marketplace-table-heading-legend-own"
              description="Legend in a table heading"
              defaultMessage="My own order"
            />
          </span>
        </span>
        {(R.pathOr(true, ['privatebids'], solutionSettings) ||
          R.pathOr(true, ['privateoffers'], solutionSettings)) && (
          <span className="legenda-item nowrap">
            <span className="private">p</span>
            <span className="exp">
              ={' '}
              <FormattedMessage
                id="marketplace-table-heading-legend-private"
                description="Legend in a table heading"
                defaultMessage="Private order"
              />
            </span>
          </span>
        )}
      </div>
    ),
  })
);
