import React from 'react';
import classnames from 'classnames';

interface IFieldHolder {
  name?: string;
  label?: string;
  className?: string;
  children?: React.ReactNode;
}
export default function FieldHolder({
  name = '',
  label = '',
  className = '',
  children,
  ...props
}: IFieldHolder) {
  return (
    <div className={classnames('nui-field-holder', className)} {...props}>
      <label {...(name ? { htmlFor: name } : {})}>{label}</label>
      {children}
    </div>
  );
}
