import React, { useContext, useEffect } from 'react';
import { Form, Button, Loading } from 'Components/nui';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { ShowResult } from 'Components/Result';
import { promisify } from 'es6-promisify';
import R from 'ramda';
import { ApiContext } from '~/context';
import classNames from 'classnames';
import { submitError } from 'Components/form/utils';
import moment from 'moment-timezone';
import { ClassicResult } from 'Components/Result';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

function getQFormWrapper({ form }) {
  const fields = [
    {
      name: 'question',
      label: (
        <FormattedMessage
          id="marketrow-qa-field-label"
          description="Label for `Ask your question` field on qa in Marketrow"
          defaultMessage="Ask your question"
        />
      ),
    },
  ];
  const initialData = {};
  const schema = { question: { required: true, type: 'String' } };

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

function getAFormWrapper({ form, question }) {
  const fields = [
    {
      name: 'answer',
      label: 'Answer this question',
    },
  ];
  const initialData = {};
  const schema = { answer: { required: true, type: 'String' } };

  return new FormWrapper(form, initialData, mergeSchema(schema, fields));
}

function QForm({ form, order, submitState }) {
  const loadOrderQuestions = useStoreActions(
    action => action.marketdepth.loadOrderQuestions
  );
  const api = useContext(ApiContext);

  const formWrapper = getQFormWrapper({ form });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    // Client-side validation
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    // Server-side validation
    submitState.setLoading(true);
    const data = {
      ...formWrapper.serialize(),
    };
    try {
      const result = await api.getData(
        { type: 'orders', id: `${order.id}/questions`, getResult: x => x },
        null,
        { method: 'post', data }
      );
      // TODO postProcess
      submitState.setResult(result);
      loadOrderQuestions(order.id);
      toast('Your question has been sent', {
        type: 'success',
      });
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        // TODO scrollIntoView
        formWrapper.setErrors(errors);
      } else {
        // report the unexcepted error
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <Form.Item>
        <Button
          className="nui-secondary"
          htmlType="submit"
          loading={submitState.loading}
          disabled={submitState.loading}
        >
          <FormattedMessage
            id="marketrow-qa-button-ask"
            description="button for qa 'Ask' in Marketrow"
            defaultMessage="Ask"
          />
        </Button>
      </Form.Item>
    </Form>
  );
}

function AForm({ form, order, question, submitState }) {
  const api = useContext(ApiContext);
  const loadOrderQuestions = useStoreActions(
    action => action.marketdepth.loadOrderQuestions
  );

  const formWrapper = getAFormWrapper({ form, question });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);

  async function handleSubmit(e) {
    e.preventDefault();
    // Client-side validation
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      return;
    }
    // Server-side validation
    submitState.setLoading(true);
    const data = {
      ...formWrapper.serialize(),
    };
    try {
      await api.getData(
        {
          type: 'orders',
          id: `${order.id}/questions/${question.id}`,
          getResult: x => x,
        },
        null,
        { method: 'post', data }
      );
      // TODO postProcess
      submitState.setResult({ result: 'Your answer has been published' });
      loadOrderQuestions(order.id);
      toast('Your answer has been published', {
        type: 'success',
      });
    } catch (err) {
      const errors = R.path(
        ['response', 'data', 'errors', 0, 'description'],
        err
      );
      if (errors) {
        // TODO scrollIntoView
        formWrapper.setErrors(errors);
      } else {
        // report the unexcepted error
        submitState.setResult(err);
      }
    }
    submitState.setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formWrapper.render()}
      <Form.Item>
        <Button
          className={classNames('button', 'secondary')}
          htmlType="submit"
          loading={submitState.loading}
          disabled={submitState.loading}
        >
          <FormattedMessage
            id="marketrow-qa-button-answer"
            description="button for qa 'Answer' in Marketrow"
            defaultMessage="Answer"
          />
        </Button>
      </Form.Item>
    </Form>
  );
}

const WrappedQForm = Form.create()(QForm);
const WrappedAForm = Form.create()(AForm);

const submitQSuccess = (result, submitState) => (
  <ClassicResult title="">
    <p>
      <FormattedMessage
        id="marketrow-qa-helptext-question-submitted"
        description="helptext for `question submitted` on qa in Marketrow"
        defaultMessage="Thanks! Your question has been sent successfully!"
      />
    </p>
    <hr className="mt-15 mb-15" />
    <Button
      className="nui-reverse mb-5"
      onClick={() => {
        // Clear result page
        submitState.setResult();
      }}
    >
      <FormattedMessage
        id="marketrow-qa-button-anotherquestion"
        description="button for qa 'Another question?' in Marketrow"
        defaultMessage="Another question?"
      />
    </Button>
  </ClassicResult>
);

export function OrderQuestionAndAnswer({ order }) {
  const orderQuestions = useStoreState(
    state => state.marketdepth.orderQuestions
  );
  const loadOrderQuestions = useStoreActions(
    action => action.marketdepth.loadOrderQuestions
  );

  const { is_owner, is_broker } = order;
  const is_mine = is_owner || is_broker;
  const dateFormat = 'll HH:mm';

  const canAsk = useStoreState(
    state =>
      !is_owner &&
      state.auth.solution.roles
        .filter(r =>
          order.type === 'offer' ? r.division.buyer : r.division.seller
        )
        .map(r => r.role)
        .filter(role => role !== 'guest').length > 0
  );

  useEffect(() => {
    loadOrderQuestions(order.id);
  }, [order.id]);

  const questions = R.propOr([], order.id, orderQuestions);

  const translateFrom = (
    <FormattedMessage
      id="marketrow-qa-helptext-generic-from"
      description="helptext for generic `from` on qa in Marketrow"
      defaultMessage="from"
    />
  );

  return (
    <>
      {questions === null ? (
        <Loading size="small" />
      ) : (
        questions
          .sort((a, b) => moment(a.created).diff(moment(b.created)))
          .map(question => (
            <dl className="q-and-a" key={question.id}>
              <dt className="question">
                <span className="date">
                  {moment(question.created).format(dateFormat)} -{' '}
                </span>
                <span className="type">Q</span> {question.question}
                {question.creator && (
                  <div className="author">
                    <FormattedMessage
                      id="marketrow-qa-helptext-generic-from"
                      description="helptext for `from` on qa in Marketrow"
                      defaultMessage="{creator} {division, select, null {} other {from {division_name}}}"
                      values={{
                        division: question.division,
                        division_name: question.division.name,
                        creator: question.creator.fullname,
                      }}
                    />
                  </div>
                )}
              </dt>
              <dd className="answer">
                {question.answers.length > 0 ? (
                  question.answers.map(answer => (
                    <p key={answer.id}>
                      <span className="date">
                        {moment(answer.created).format(dateFormat)} -{' '}
                      </span>
                      <span className="type">A</span> {answer.answer}
                      {answer.creator && (
                        <div className="author">{answer.creator.name}</div>
                      )}
                    </p>
                  ))
                ) : is_mine ? (
                  <ShowResult key={question.id} renderError={submitError}>
                    {submitState => (
                      <div className="qa-wrapper">
                        <div className="nui-form order-form">
                          <WrappedAForm
                            key={`form-a-${question.id}`}
                            order={order}
                            question={question}
                            submitState={submitState}
                          />
                        </div>
                      </div>
                    )}
                  </ShowResult>
                ) : (
                  <>
                    <span className="type">A</span>
                    <FormattedMessage
                      id="marketrow-qa-helptext-noanswer"
                      description="helptext for `No answer yet` on qa in Marketrow"
                      defaultMessage="No answer yet"
                    />
                  </>
                )}
              </dd>
            </dl>
          ))
      )}

      {is_mine && questions.length === 0 && (
        <FormattedMessage
          id="marketrow-qa-helptext-noquestion"
          description="helptext for `No questions so far...` on qa in Marketrow"
          defaultMessage="No questions so far..."
        />
      )}

      {canAsk && (
        <ShowResult renderSuccess={submitQSuccess} renderError={submitError}>
          {submitState => (
            <div className="qa-wrapper">
              <div className="nui-form order-form">
                <WrappedQForm
                  key={`form-q-${order.id}`}
                  order={order}
                  submitState={submitState}
                />
              </div>
            </div>
          )}
        </ShowResult>
      )}
    </>
  );
}
