import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Orders from './Orders';
import View from './View';

export { OrderEdit } from './Edit';
export { OrderNew } from './New';

export default () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/:id`}>
        <View />
      </Route>
      <Route>
        <Orders />
      </Route>
    </Switch>
  );
};
