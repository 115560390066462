import React, { useEffect, useState } from 'react';
import { useScrollToTopOnMount } from '~/hooks';
import Helmet from 'Components/Helmet';
import { Link, useRouteMatch } from 'react-router-dom';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { reportGroups, reportTypes } from '..';
import { useStoreState } from 'easy-peasy';

export default () => {
  useScrollToTopOnMount();
  const { url } = useRouteMatch();

  const isSolutionOwner = useStoreState(state => state.auth.isSolutionOwner);
  const solution = useStoreState(state => state.auth.solution);
  const isdao = useStoreState(state => state.auth.isdao);

  let word = isSolutionOwner ? 'owner' : '';
  if (isdao) {
    word = 'dao';
  }

  const [groups, setGroups] = useState(reportGroups);

  useEffect(() => {
    if (solution.id === '683a2e88-74ef-40b9-8dce-de64b66ba54e') {
      setGroups(['Trades'].concat(reportGroups));
    }
  }, [solution.id, reportGroups]);

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>

      <div className="full-page-header">
        <h1 className="inline mt-10 mr-20 mb-0">Reports</h1>
        <ul className="nui-bc right pt-20">
          <li>
            <BreadcrumbRoot active="reports" />
          </li>
          <li>Reports</li>
        </ul>

        <hr />

        <div className="report-lists">
          {groups.map(
            name =>
              Object.values(reportTypes)
                .filter(x => x.group === name)
                .filter(x => x.acl.includes(word)).length > 0 && (
                <ul key={name}>
                  <li>
                    <h3>{name}</h3>
                  </li>
                  {Object.values(reportTypes)
                    .filter(x => x.group === name)
                    .filter(x => x.acl.includes(word))
                    .map(item => (
                      <li>
                        <Link to={`${url}/${item.path}`} title={item.title}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              )
          )}
        </div>
      </div>
    </>
  );
};
