import type { Tender } from '~/models/tenders';
import React from 'react';
import { useMountedState } from '~/hooks';
import { Button, Modal } from 'Components/nui';
import * as service from '~/services/tenders';
import { toast } from 'react-toastify';
import { usePathTools, routeUrl } from '~/router';
import { useHistory } from 'react-router-dom';

type TenderProp = Record<'tender', Tender>;

export default ({ tender }: TenderProp) => {
  const [visible, setVisible] = useMountedState(false);
  const [loading, setLoading] = useMountedState(false);
  const history = useHistory();

  const toggle = (value: boolean) => () => {
    setVisible(value);
  };

  const { matchParams } = usePathTools();
  const { tenderId = '' } = matchParams('tender-view');

  return (
    <>
      <Button onClick={toggle(true)}>Cancel tender</Button>
      {visible && (
        <Modal close={toggle(false)} size="small">
          <h2>Confirm</h2>
          <p className="pb-10">
            Tender name: <strong className="all-black">{tender.name}</strong>
          </p>
          <p>Are you sure you want to cancel this tender?</p>
          <hr />
          <div className="button-set">
            <Button
              loading={loading}
              disabled={loading}
              onClick={async () => {
                setLoading(true);

                if (tenderId) history.push(routeUrl('tender-overview'));

                const result = await service.actions.cancelTender(tender.id);
                if (result?.success)
                  toast.success(
                    <>
                      Tender <strong className="nowrap">{tender.name}</strong>{' '}
                      cancelled successfully
                    </>
                  );
                else if (result?.errors)
                  toast.error(<>There was an error cancelling this tender</>);

                setLoading(false);
                setVisible(false);
              }}
            >
              Confirm
            </Button>
            <Button type="simple" onClick={toggle(false)}>
              Close
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
