type Listener<T> = ((data: T) => void) | { handleEvent(data: T): void };

const isCustomEvent = <T>(x: any): x is CustomEvent<T> => !!x.detail;
const dispOptions = { bubbles: false, cancelable: false, composed: false };
const hdlrOptions = { capture: false, passive: true };

export class Target extends EventTarget {
  dispatchEvent<T>(event: CustomEvent<T>) {
    return super.dispatchEvent(event);
  }

  addEventListener<T>(type: string, listener: Listener<T>, once: boolean) {
    const handler = (event: Event) => {
      if (isCustomEvent<T>(event)) {
        if (typeof listener === 'function') listener(event.detail);
        else listener.handleEvent(event.detail);
      }
    };

    super.addEventListener(type, handler, { once, ...hdlrOptions });
    return () => void super.removeEventListener(type, handler);
  }
}

export function createEvent<T>(target: Target, name: string) {
  const dispatch = (detail: T) => {
    const event = new CustomEvent(name, { detail, ...dispOptions });
    target.dispatchEvent(event);
  };

  const handle = (listener: Listener<T>, once: boolean = false) => {
    return target.addEventListener(name, listener, once);
  };

  return [handle, dispatch] as const;
}
