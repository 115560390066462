import React from 'react';

import Details from './Details';

import { useUser } from '../Context';

export default ({ record }: any) => {
  const { user } = useUser(record.id);
  const canAdd = user.__acl__.add;
  return (
    <>
      <h3>Roles</h3>
      <div className="users">
        <span className="user-solution">Solution</span>
        <span className="user-division">Division</span>
        <span className="user-role">Role</span>
      </div>
      {canAdd && <Details userId={record.id} role={undefined} canAdd={true} />}

      {record.roles
        .sort((a: any, b: any) =>
          a.division.name.localeCompare(b.division.name)
        )
        .map((role: any) => {
          return (
            <Details
              key={role.id}
              userId={record.id}
              role={role}
              canAdd={false}
            />
          );
        })}
    </>
  );
};
