import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';

export default () => {
  return (
    <div className="full-page-header">
      <h1 className="inline mt-10 mr-20 mb-0">Customers</h1>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="customers" />
        </li>
        <li>Customers</li>
      </ul>
    </div>
  );
};
