import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import R from 'ramda';
import { Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';

export default () => {
  const solution = useStoreState(state => state.auth.solution);
  const isdao = useStoreState(state => state.auth.isdao);
  const divisions = useStoreState(state => state.auth.solutionDivisions);

  const selDivision = useStoreState(state => state.market.division);
  const selWarehouse = useStoreState(state => state.market.warehouse);

  const setData = useStoreActions(action => action.market.setData);
  const update = useStoreActions(action => action.market.update);

  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    setData({
      division: null,
      updatedDate: null,
    });
  }, [divisions, setData]);

  useEffect(() => {
    if (selDivision) {
      setWarehouses(selDivision.warehouses);
      setData({
        warehouse: selDivision.warehouses.length
          ? R.find(R.propEq('default', true))(selDivision.warehouses) ||
            selDivision.warehouses[0]
          : null,
        updatedDate: null,
      });
    } else {
      setWarehouses([]);
      setData({
        warehouse: null,
        updatedDate: null,
      });
    }
    update();
  }, [selDivision]);

  const updateDivision = division => {
    setData({ division, updatedDate: null });
  };

  const updateWarehouse = warehouse => {
    if (selWarehouse !== warehouse) {
      setData({ warehouse, updatedDate: null });
      update();
    }
  };

  const divisionsMenu = (
    <Menu>
      <Menu.Item onClick={() => updateDivision(null)} key="default">
        <FormattedMessage
          id="marketplace-filter-divisions"
          description="Text for 'all divisions' option"
          defaultMessage="All divisions"
        />
      </Menu.Item>
      {divisions.map(item => (
        <Menu.Item onClick={() => updateDivision(item)} key={item.id}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const warehousesMenu = (
    <Menu>
      {warehouses.map(item => (
        <Menu.Item onClick={() => updateWarehouse(item)} key={item.id}>
          {item.desc}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      {divisions.length > 1 && !isdao && (
        <Dropdown overlay={divisionsMenu} trigger={['click']}>
          <a className="show-dd-items" href="#division">
            {selDivision ? (
              selDivision.name
            ) : (
              <FormattedMessage
                id="marketplace-filter-divisions"
                description="Text for 'all divisions' option"
                defaultMessage="All divisions"
              />
            )}
          </a>
        </Dropdown>
      )}

      {solution.freight && warehouses.length > 1 && selWarehouse && (
        <Dropdown overlay={warehousesMenu} trigger={['click']}>
          <a className="show-dd-items" href="#warehouse">
            {selWarehouse.desc}
          </a>
        </Dropdown>
      )}
    </>
  );
};
