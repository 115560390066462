import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStoreState } from '~/store';
import { WithId } from '../types';
import { timeStatus } from './utils';

export default function Status({ id }: WithId) {
  const item = useStoreState(state => state.banners.items.byId(id));

  if (!item) return null;

  const [status, duration] = timeStatus(item);
  return (
    <div className="banner-status">
      {status === 'future' && (
        <FormattedMessage
          id="banner-status-future"
          defaultMessage="Active in {duration}"
          description="Future banner - time until active"
          values={{ duration: duration.humanize() }}
        />
      )}
      {status === 'past' && (
        <FormattedMessage
          id="banner-status-past"
          defaultMessage="Expired {duration} ago"
          description="Past banner - time since expiration"
          values={{ duration: duration.humanize() }}
        />
      )}
      {status === 'present' && (
        <FormattedMessage
          id="banner-status-present"
          defaultMessage="Expires in {duration}"
          description="Present banner - time until expiration"
          values={{ duration: duration.humanize() }}
        />
      )}
    </div>
  );
}
