import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const DetailsColumn = ColumnTree.defineNode('details', _context => ({
  title: (
    <FormattedMessage
      id="marketplace-table-heading-details"
      description="Table heading"
      defaultMessage="Details"
    />
  ),
  children: [
    {
      title: '',
      className: 'col-details',
      key: 'btn',
      render: () => '',
    },
  ],
}));
