import React, { useContext } from 'react';
import { useConsolidation } from '~/store/models';

type IUseConsolidation = ReturnType<typeof useConsolidation>;
export const ConsolidationContext = React.createContext<IUseConsolidation>(
  {} as IUseConsolidation
);

export function useConsolidationContext() {
  const context = useContext(ConsolidationContext);
  return context;
}
