import classNames from 'classnames';
import React from 'react';
import { useStoreActions, useStoreState } from '~/store';
import { Switch } from '~/components/nui';
import { WithId } from '../../types';
import Status from '../Status';
import { getStatus } from '~/store/bannerModel/utils';

export default function PublishSwitch({ id }: WithId) {
  const item = useStoreState(state => state.banners.items.byId(id));
  const setPublished = useStoreActions(
    actions => actions.banners.apiSetPublished
  );

  if (!item) return null;

  const status = getStatus(item);

  return (
    <div
      className={classNames('banner-active-switch', {
        published: item.ispublished,
      })}
    >
      <Switch
        loading={item.busy}
        checked={item.ispublished}
        disabled={status === 'expired'}
        onClick={() => {
          setPublished({ id, ispublished: !item.ispublished });
        }}
      />
      <Status id={id} />
    </div>
  );
}
