import type Struct from 'struct';
import React from 'react';

export const $access: Struct.SAccess = Symbol.for('access') as Struct.SAccess;

interface Access<D extends string> {
  [$access]: { [K in D]: boolean };
}

export function can<D extends string>(
  to: D | D[],
  model?: Access<D> | null,
  strict: boolean = false
) {
  if (!model) return false;
  if (Array.isArray(to)) {
    if (strict) return !to.map(a => model[$access][a]).includes(false);
    return to.map(a => model[$access][a]).includes(true);
  }
  return model[$access][to];
}

interface IAccess<D extends string> {
  to: D | D[];
  model: Access<D>;
  denied?: React.ReactNode;
  children?: React.ReactNode;
  strict?: boolean;
}
export default function Access<D extends string>({
  to,
  model,
  denied = null,
  children,
  strict = false,
}: IAccess<D>) {
  if (can(to, model, strict)) return <>{children}</>;
  return <>{denied}</>;
}

type IDenied<D extends string> = Omit<IAccess<D>, 'denied'>;
export function Denied<D extends string>({
  to,
  model,
  children,
  strict = false,
}: IDenied<D>) {
  if (!can(to, model, strict)) return <>{children}</>;
  return null;
}
