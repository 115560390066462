import { IModelApi, FetchConfig, SaveConfig } from './types';
import { api, API } from '~/api';
import { AxiosRequestConfig } from 'axios';
import R from 'ramda';
import qs from 'qs';

interface Config {
  url: string;
}
export abstract class ModelApi implements IModelApi {
  private api: API;
  protected url: string;

  constructor({ url }: Config) {
    this.api = api;
    this.url = url;
  }

  protected getUrl(...paths: (string | undefined)[]) {
    return R.filter(x => !!x, paths).join('/');
  }

  async request(config: AxiosRequestConfig) {
    return this.api.request(
      R.mergeDeepRight(
        { paramsSerializer: (p: any) => qs.stringify(p, { indices: false }) },
        config
      )
    );
  }

  async get({ model = {}, ...config }: FetchConfig = {}) {
    const url = this.getUrl(this.url, model.id);
    return this.request(R.mergeDeepRight({ method: 'get', url }, config));
  }

  async post({ model = {}, ...config }: SaveConfig) {
    const url = this.getUrl(this.url, model.id);
    return this.request(R.mergeDeepRight({ method: 'post', url }, config));
  }

  async put({ model = {}, ...config }: SaveConfig) {
    const url = this.getUrl(this.url, model.id);
    return this.request(R.mergeDeepRight({ method: 'put', url }, config));
  }

  async patch({ model = {}, ...config }: SaveConfig) {
    const url = this.getUrl(this.url, model.id);
    return this.request(R.mergeDeepRight({ method: 'patch', url }, config));
  }

  async delete({ model = {}, ...config }: SaveConfig) {
    const url = this.getUrl(this.url, model.id);
    return this.request(R.mergeDeepRight({ method: 'delete', url }, config));
  }
}
