import React from 'react';
import { useStoreState, useStoreActions } from '~/store';
import classnames from 'classnames';
import { useSiteTitle } from '~/hooks';
import { Button } from 'Components/nui';

const Detail = ({ title, info, className = '' }) => {
  if (!info) return null;

  return (
    <p className={classnames('solution-detail', className)}>
      <span>{title}</span> {info}
    </p>
  );
};

const Solution = ({ solution, active }) => {
  const changeSolution = useStoreActions(state => state.auth.changeSolution);
  const { owner } = solution;

  const onClick = e => {
    e.preventDefault();
    changeSolution(solution.id);
  };

  return (
    <li className={classnames('solution-item', { active })}>
      <h3>{solution.desc}</h3>
      <img src={solution.logo} alt={solution.desc} className="solution-logo" />
      <Detail
        title={
          solution.terms && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={solution.terms.policy}
            >
              Terms of service
            </a>
          )
        }
        info={!!solution.terms}
        className="solution-terms mt-5 mb-5"
      />
      <Detail
        title={
          solution.privacy && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={solution.privacy.policy}
            >
              Privacy policy
            </a>
          )
        }
        info={!!solution.privacy}
        className="solution-privacy mt-5"
      />

      <div className="mt-10">
        <Detail title="Name" info={owner.name} />
        <Detail title="Company no" info={owner.companyno} />
        <Detail
          title="Website"
          info={
            owner.website && (
              <a
                href={owner.website}
                title={owner.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {owner.website}
              </a>
            )
          }
        />
        {!active && (
          <Button
            type="secondary"
            size="small"
            className="mt-15"
            onClick={onClick}
          >
            Switch
          </Button>
        )}
      </div>
    </li>
  );
};

export default () => {
  const solutions = useStoreState(state => state.auth.solutions);
  const solution = useStoreState(state => state.auth.solution);

  useSiteTitle('Solutions');

  return (
    <div className="wrapper">
      <div id="solutions">
        <h2>Solutions</h2>

        <ul className="solutions-list">
          {solutions.map(s => (
            <Solution key={s.id} solution={s} active={s.id === solution.id} />
          ))}
        </ul>
      </div>
    </div>
  );
};
