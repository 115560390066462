import React, { useState } from 'react';
import * as service from '~/services/tenders';
import { withPreload, routeUrl } from '~/router';
import { Tender, Order } from '~/models/tenders';
import { Helmet } from 'react-helmet';
import { Alert, Button } from 'Components/nui';
import * as Data from 'Components/Data';
import moment from 'moment-timezone';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { capitalize } from '~/utils';

const useDuplicate = (tender: Tender, order: Order) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onDuplicate = async () => {
    setLoading(true);

    const schema = await service.schemas.order({
      tender: tender.id,
      product: order.product.id,
      order: order.id,
    });

    if (!schema?.success) {
      setLoading(false);
      toast.error(<>An error occurred. Please try again later.</>);
      return;
    }

    const data = new FormData();
    for (const [key, field] of R.toPairs(schema.data)) {
      switch (key) {
        case 'attributes': {
          for (const [attr, sch] of R.toPairs(field.fields)) {
            const name = ['attributes', attr].join(field.separator);
            data.append(name, (sch as any).value);
          }
          break;
        }

        case 'files':
        case 'attachments':
        case 'variants':
          break;

        default:
          if (field.value !== undefined) data.append(key, field.value);
      }
    }

    const result = await service.actions.editOrder(data, tender.id);
    if (!result?.success) {
      setLoading(false);
      toast.error(<>An error occurred. Please try again later.</>);
      return;
    }

    toast.success(
      <>
        {capitalize(order.ordertype)} successfully duplicated. You can now edit
        this {order.ordertype}.
      </>
    );

    history.push(
      routeUrl('tender-edit-orders', {
        tenderId: tender.id,
        orderId: result.data.id,
        mode: 'edit',
      })
    );
  };

  return { loading, onDuplicate };
};

interface IDuplicate {
  close: () => void;
}

export const Duplicate = withPreload({
  route: 'tender-duplicate-order',
  preload: service.observe.order(),
})<IDuplicate>(({ data: { tender, order }, close }) => {
  const state = useDuplicate(tender, order);

  return (
    <div className="duplicate-order">
      <Helmet>
        <title>
          Duplicate {order.ordertype} - {order.pid}
        </title>
      </Helmet>
      <h2>Duplicate {order.ordertype}</h2>
      <div className="duplicate-order-content">
        <Alert type="info" hasicon>
          Duplicating this {order.ordertype} will create a new {order.ordertype}{' '}
          based on this one. You will then be redirected to a page where you can
          edit the new {order.ordertype}.
        </Alert>
        <Data.List>
          <Data.Item title="Tender">{tender.name}</Data.Item>
          <Data.Item title="Order">{order.pid}</Data.Item>
          <Data.Item title="Product">{order.product.name}</Data.Item>
          <Data.Item className="order-price">
            <Data.PriceTicker
              title="Start price"
              value={order.startprice}
              onZero="Any"
            />
          </Data.Item>
          {order.reserve.price.val && (
            <Data.Item className="reserve-price">
              <Data.PriceTicker
                value={order.reserve.price}
                title="Reserve price"
              />
            </Data.Item>
          )}
          <Data.Item className="order-volume">
            <Data.VolumeTicker title="Total volume" value={order.volume} />
          </Data.Item>
          {order.stripvolume && (
            <Data.Item className="order-volume">
              <Data.VolumeTicker
                title="Delivery volume"
                value={order.stripvolume}
              />
            </Data.Item>
          )}
          {order.startprice.index && (
            <Data.Item title="Price index">
              <Data.IndexSummary
                index={order.startprice.index}
                date={moment(order.startprice.indexdate)}
                className="font-inherit"
              />
            </Data.Item>
          )}
          {order.productionmonth && (
            <Data.Item title="Production month">
              {order.productionmonth.display}
            </Data.Item>
          )}
        </Data.List>
      </div>
      <div className="modal-footer">
        <Button
          type="primary"
          disabled={state.loading}
          loading={state.loading}
          onClick={() => void state.onDuplicate()}
        >
          Duplicate
        </Button>
        <Button type="buttonlink" onClick={() => void close()}>
          Close
        </Button>
      </div>
    </div>
  );
});
