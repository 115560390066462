import React from 'react';
import { Tooltip } from 'Components/nui';
import { FormattedMessage } from 'react-intl';
import { ColumnTree } from '../hooks/useTable/defineColumns';

export const EtdColumn = ColumnTree.defineNode('etd', _context => ({
  title: (
    <Tooltip
      title={
        <span className="icon-tooltip">
          <span className="icon-info-circled"></span>
          Estimated time of departure
        </span>
      }
    >
      <FormattedMessage
        id="marketplace-table-heading-etd"
        description="Table heading, abbreviation, estimated time of departure"
        defaultMessage="ETD"
      />
      <span className="show-help inline-block" />
    </Tooltip>
  ),
}));
