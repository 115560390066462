import React from 'react';
import R from 'ramda';
import { FormattedMessage } from 'react-intl';

export default ({ order }) => {
  if (!order || !order.owner) return null;

  const { fullname, email, phone, mobile } = order.owner;

  const divisionName = R.path(['division', 'name'], order);

  if (!(fullname || email || phone || mobile || divisionName)) return null;

  return (
    <>
      <h2>
        {' '}
        <FormattedMessage
          id="contactdetails-header"
          description="Header for Contact details"
          defaultMessage="Contact details"
        />
      </h2>

      <ul className="trade-details-list personal-details">
        {divisionName && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="contactdetails-field-division-label"
                description="Label for 'Division' field on Contact details"
                defaultMessage="Division"
              />
            </strong>
            <span>{divisionName}</span>
          </li>
        )}
        {fullname && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="contactdetails-field-name-label"
                description="Label for 'Name' field on Contact details"
                defaultMessage="Name"
              />
            </strong>
            <span>{fullname}</span>
          </li>
        )}
        {email && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="contactdetails-field-email-label"
                description="Label for 'Email' field on Contact details"
                defaultMessage="Email"
              />
            </strong>
            <span>
              <a href={`mailto:${email}`} title={`${email}`}>
                {email}
              </a>
            </span>
          </li>
        )}
        {phone && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="contactdetails-field-phone-label"
                description="Label for 'Phone' field on Contact details"
                defaultMessage="Phone"
              />
            </strong>
            <span className="nowrap">{phone}</span>
          </li>
        )}
        {mobile && (
          <li className="reversed">
            <strong>
              <FormattedMessage
                id="contactdetails-field-mobile-label"
                description="Label for 'Mobile' field on Contact details"
                defaultMessage="Mobile"
              />
            </strong>
            <span className="nowrap">{mobile}</span>
          </li>
        )}
      </ul>
    </>
  );
};
