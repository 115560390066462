import React, { useContext } from 'react';
import { useAsync, createStateContext } from 'react-use';
import { ApiContext } from '~/context';
import { useParams } from 'react-router-dom';
import { Product } from '~/models';
import Header from './Header';
import Content from './Content';
import Actions from './Actions';
import Helmet from 'Components/Helmet';
import { Loading } from 'Components/nui';

export const [useSharedProduct, SharedProductProvider] = createStateContext();

const Loader = () => {
  const api = useContext(ApiContext);
  const { id } = useParams();
  const [product, setProduct] = useSharedProduct();

  useAsync(async () => {
    const data = await api.getData({
      type: 'products',
      id: id,
      getResult: x => x.product,
    });

    setProduct(new Product(data));
  }, [id]);

  if (!product) return <Loading size="large" />;

  return (
    <Helmet>
      <title>{product.name}</title>
    </Helmet>
  );
};

const QHeader = () => {
  const [product] = useSharedProduct();
  return <h2 className="light">{product ? product.name : 'Loading...'}</h2>;
};

export const QuickView = () => {
  return (
    <SharedProductProvider>
      <QHeader />
      <div className="sb-175 numbered-sections">
        <Loader />
        <Content />
      </div>
    </SharedProductProvider>
  );
};

export default () => {
  return (
    <SharedProductProvider>
      <div className="full-page-header">
        <Loader />
        <Header />
        <Actions />
      </div>
      <Content />
    </SharedProductProvider>
  );
};
