import type { IRouteName } from 'router';
import type {
  InjectProps,
  IPreloadConf,
  IComponent,
  IPreloadValue,
  Preloadable,
  IPreloadOptions,
} from 'router/withRoute';
import React from 'react';
import { Route } from 'react-router-dom';
import { matchUrl } from './utils';
import classnames from 'classnames';
import Helmet from 'Components/Helmet';
import { Alert, Loading } from 'Components/nui';
import qs from 'qs';

interface IHead {
  title?: string;
}

type IComponentX<N extends IRouteName> = (
  props: InjectProps<N>
) => React.ReactElement | null;
interface IWithRoute<N extends IRouteName> {
  name: N;
  exact?: boolean;
  head?: IHead;
  className?: string;
}
export function withRouteX<N extends IRouteName = IRouteName>({
  name,
  head,
  exact = false,
  className,
}: IWithRoute<N>) {
  return (Component: IComponentX<N>) =>
    ({ params: givenParams }: Partial<InjectProps<N>>) =>
      (
        <Route
          key={name}
          path={matchUrl(name, givenParams)}
          exact={exact}
          render={({ match, location }) => {
            const params = { ...match?.params, ...givenParams };
            const options = qs.parse(
              location.search.slice(1)
            ) as IPreloadOptions;

            return (
              <div className={classnames('page-route', name, className)}>
                {head && (
                  <Helmet>{head.title && <title>{head.title}</title>}</Helmet>
                )}
                <Component params={params} options={options} />
              </div>
            );
          }}
        />
      );
}

interface IConfig<N extends IRouteName, A extends IPreloadConf<N> = []> {
  name: N;
  exact?: boolean;
  head?: IHead;
  className?: string;
  readonly preload?: A;
}
export function withRoute<
  N extends IRouteName = IRouteName,
  A extends IPreloadConf<N> = []
>({ name, head, exact = false, className, preload }: IConfig<N, A>) {
  return (Component: IComponent<N, IPreloadValue<A>>) =>
    ({ params: givenParams }: Partial<InjectProps<N>>) =>
      (
        <Route
          key={name}
          path={matchUrl(name, givenParams)}
          exact={exact}
          render={({ match, location }) => {
            const params = { ...match?.params, ...givenParams };
            const options = qs.parse(
              location.search.slice(1)
            ) as IPreloadOptions;

            return (
              <div className={classnames('page-route', name, className)}>
                {head && (
                  <Helmet>{head.title && <title>{head.title}</title>}</Helmet>
                )}
                <Preload data={preload} props={{ params, options }} name={name}>
                  {data => (
                    <Component params={params} options={options} data={data} />
                  )}
                </Preload>
              </div>
            );
          }}
        />
      );
}

function isLoadMulti<N extends IRouteName>(
  x: readonly Preloadable<N>[] | Preloadable<N>
): x is Preloadable<N>[] {
  return Array.isArray(x);
}

interface IPreload<N extends IRouteName, A extends IPreloadConf<N>> {
  name: N;
  data?: A;
  props: InjectProps<N>;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge';
  children: (data: IPreloadValue<A>) => React.ReactElement;
}
function Preload<N extends IRouteName, A extends IPreloadConf<N>>({
  name,
  data,
  props,
  size = 'large',
  children,
}: IPreload<N, A>) {
  if (data === undefined) return <>{children(undefined as IPreloadValue<A>)}</>;

  if (isLoadMulti(data)) {
    let errors: string[] = [];
    let isLoading = false;
    let values = [];

    for (const preload of data) {
      const { loading = false, error, value } = preload(props);
      isLoading = isLoading || loading;
      if (error) errors.push(error);
      values.push(value);
    }

    if (errors.length) {
      return (
        <Alert
          type="error"
          hasicon
          className={classnames('alert-route-error', name)}
        >
          <ul className="route-errors">
            {errors.map(e => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </Alert>
      );
    }
    if (isLoading) return <Loading size={size} />;
    return <>{children(values as IPreloadValue<A>)}</>;
  }

  const { loading, error, value } = (data as Preloadable<N>)(props);

  if (error) {
    return (
      <Alert
        type="error"
        hasicon
        className={classnames('alert-route-error', name)}
      >
        {error}
      </Alert>
    );
  }

  if (loading) return <Loading size={size} />;

  return <>{children(value)}</>;
}
