import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button } from 'Components/nui';
import classnames from 'classnames';
import HelpButton from './HelpButton';
import DivisionWarehouse from './DivisionWarehouse';
import R from 'ramda';
import { FormattedMessage } from 'react-intl';

export default () => {
  const solutionSettings = useStoreState(state => state.auth.solutionSettings);
  const divisions = useStoreState(state => state.auth.solutionDivisions);
  const filterVis = useStoreState(state => state.market.filterVis);
  const isFiltered = useStoreState(state => state.market.isFiltered);
  const setData = useStoreActions(action => action.market.setData);
  const match = useRouteMatch('/overview');

  useEffect(() => {
    setData({
      division: null,
      updatedDate: null,
    });
  }, [divisions, setData]);

  const overviewAllowed = R.pathOr(true, ['overview'], solutionSettings);

  return (
    <div className={classnames('nui-row', { 'h1-tabs': overviewAllowed })}>
      {overviewAllowed ? (
        <h1>
          <span className={match === null ? 'active' : ''}>
            <Link to="/">
              <FormattedMessage
                id="marketplace-headertab-marketplace"
                description="Marketplace tab on the market overview"
                defaultMessage="Marketplace"
              />
            </Link>
          </span>
          <span className={match !== null ? 'active' : ''}>
            <Link to="/overview">
              <FormattedMessage
                id="marketplace-headertab-overview"
                description="Overview tab on the market overview"
                defaultMessage="Overview"
              />
            </Link>
          </span>
        </h1>
      ) : (
        <h1>
          <FormattedMessage
            id="marketplace-headertab-marketplace"
            description="Marketplace tab on the market overview"
            defaultMessage="Marketplace"
          />
        </h1>
      )}

      {match === null && (
        <>
          <div className="mpkey">
            <HelpButton />
          </div>

          <div className="inline-block">
            <Button
              htmlType="button"
              type="primary"
              onClick={() => setData({ filterVis: !filterVis })}
              className={classnames('show-filter-section', {
                'opened': filterVis,
                'is-filtered': isFiltered,
              })}
            >
              <span className="arrow" />
              {isFiltered && (
                <span className="icon-ok-circled show-if-filtered" />
              )}
              <FormattedMessage
                id="marketplace-filter-opener"
                description="Filter opener/options text"
                defaultMessage="Filter options"
              />
            </Button>
          </div>
        </>
      )}

      <div className="dds">
        <DivisionWarehouse />
      </div>
    </div>
  );
};
