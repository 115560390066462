import React, { useState, useEffect } from 'react';
import { useStoreDispatch, useStoreState } from 'easy-peasy';
import { api } from '~/api';
import R from 'ramda';
import moment from 'moment-timezone';
import { useBodyClassReverse } from '~/hooks';
import { Checkbox, Button, Alert } from 'Components/nui';

export default () => {
  const dispatch = useStoreDispatch();
  const user = useStoreState(state => state.auth.userModel);
  const solution = useStoreState(state => state.auth.solution);

  const upr =
    'policyupdated' in user &&
    (user.policydate === null ||
      (user.policyupdated !== null &&
        moment(user.policydate) < moment(user.policyupdated)));

  const utr =
    'termsupdated' in user &&
    (user.termsdate === null ||
      (user.termsupdated !== null &&
        moment(user.termsdate) < moment(user.termsupdated)));

  const spr =
    'privacy' in solution &&
    (solution.policydate === null ||
      (solution.privacy.updated !== null &&
        moment(solution.policydate) < moment(solution.privacy.updated)));

  const str =
    'terms' in solution &&
    (solution.termsdate === null ||
      (solution.terms.updated !== null &&
        moment(solution.termsdate) < moment(solution.terms.updated)));

  const [policyAccepted, setPolicyAccepted] = useState(() => !upr);
  const [termsAccepted, setTermsAccepted] = useState(() => !utr);

  const [solPolicyAccepted, setSolPolicyAccepted] = useState(() => !spr);
  const [solTermsAccepted, setSolTermsAccepted] = useState(() => !str);

  const [loading, setLoading] = useState(false);

  async function accept() {
    setLoading(true);
    await api.acceptPolicy(solution.id);
    dispatch.auth.checkAuth();
  }

  useBodyClassReverse();

  useEffect(() => {
    setPolicyAccepted(() => !upr);
    setTermsAccepted(() => !utr);
    setSolPolicyAccepted(() => !spr);
    setSolTermsAccepted(() => !str);
  }, [solution]);

  return (
    <section className="main-content privy-content" role="main">
      <div>
        <div className="privy">
          <h1>Terms and privacy statement</h1>
        </div>
      </div>

      {(upr || utr) && (
        <div>
          <div className="privy">
            <p>
              Please read and accept the{' '}
              {upr && (
                <strong className="all-black">Nui privacy statement</strong>
              )}{' '}
              {upr && utr && <>and</>}{' '}
              {utr && <strong className="all-black">Nui terms of use</strong>}{' '}
              prior to proceeding to the platform.
            </p>
            {upr && (
              <Checkbox
                checked={policyAccepted}
                onChange={e => setPolicyAccepted(e.target.checked)}
                title=""
              >
                I have read and understand the{' '}
                <a
                  href="https://www.nuimarkets.com/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Nui privacy statement
                </a>
                {user.policyupdated && (
                  <em>
                    {' '}
                    (updated on {moment(user.policyupdated).format('ll')})
                  </em>
                )}
              </Checkbox>
            )}
            {utr && (
              <div>
                <Checkbox
                  checked={termsAccepted}
                  onChange={e => setTermsAccepted(e.target.checked)}
                  title=""
                >
                  I have read and understand the{' '}
                  <a
                    href="https://www.nuimarkets.com/terms/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Nui terms of use
                  </a>
                  {user.termsupdated && (
                    <em>
                      {' '}
                      (updated on {moment(user.termsupdated).format('ll')})
                    </em>
                  )}
                </Checkbox>
              </div>
            )}
          </div>
        </div>
      )}

      {(spr || str) && (
        <div className="mt-20">
          <div className="privy">
            <p>
              Please read and accept the{' '}
              {spr && (
                <strong className="all-black">
                  {solution.name} privacy statement
                </strong>
              )}{' '}
              {spr && str && <>and</>}{' '}
              {str && (
                <strong className="all-black">
                  {solution.name} terms of use
                </strong>
              )}{' '}
              prior to proceeding to the platform.
            </p>
            {spr && (
              <Checkbox
                checked={solPolicyAccepted}
                onChange={e => setSolPolicyAccepted(e.target.checked)}
                title=""
              >
                I have read and understand the{' '}
                <a
                  href={R.pathOr('#unknown', ['privacy', 'policy'], solution)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {solution.name} privacy statement
                </a>
                {solution.privacy.updated && (
                  <em>
                    {' '}
                    (updated on {moment(solution.privacy.updated).format('ll')})
                  </em>
                )}
              </Checkbox>
            )}
            {str && (
              <div>
                <Checkbox
                  checked={solTermsAccepted}
                  onChange={e => setSolTermsAccepted(e.target.checked)}
                  title=""
                >
                  I have read and understand the{' '}
                  <a
                    href={R.pathOr('#unknown', ['terms', 'policy'], solution)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {solution.name} terms of use
                  </a>
                  {solution.terms.updated && (
                    <em>
                      {' '}
                      (updated on {moment(solution.terms.updated).format('ll')})
                    </em>
                  )}
                </Checkbox>
              </div>
            )}
          </div>
        </div>
      )}

      <div>
        <div className="privy">
          <hr />
          <div className="button-set pt-20">
            <Button
              type="primary"
              disabled={
                !(
                  policyAccepted &&
                  termsAccepted &&
                  solPolicyAccepted &&
                  solTermsAccepted
                )
              }
              onClick={accept}
              loading={loading}
            >
              Accept
            </Button>
            <Button type="simple" onClick={() => dispatch.auth.signOut()}>
              Sign Out
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
