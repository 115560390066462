import type * as Market from 'models.market';

import R from 'ramda';
import moment from 'moment-timezone';
import { useStoreState } from '~/store';
import { unitsOfMeasure } from '~/utils';

type HasId = Record<'id', string>;
type BrokerRef = Record<'broker', HasId>;
type DivisionRef = Record<'division', HasId>;
type Ref = Partial<DivisionRef | BrokerRef>;

const isMy =
  (type: 'division' | 'broker', divisions: string[]) => (order: Ref) =>
    R.includes(R.pathOr('', [type, 'id'], order), divisions);

const hasDivision = (divisions: string[]) =>
  R.anyPass([isMy('broker', divisions), isMy('division', divisions)]);

const isTraded = R.propEq('status', 'Traded');
const isWorking = R.propEq('status', 'Working');
const isOversubbed = R.propEq('status', 'Oversubscribed');
const notTraded = R.o(R.not, isTraded);

function useHelpers() {
  const divisions = useStoreState(state => state.auth.solutionDivisions);
  const canTrade = useStoreState(state => state.auth.canTradeDivisions);

  const idsOf = R.map(R.prop('id'));

  const canTradeIds = idsOf(canTrade);
  const divIds = idsOf(divisions);
  const divTypes = R.map(R.prop('divisiontype'), divisions);

  const isMyOrder = hasDivision(divIds);
  const hasPermission = hasDivision(canTradeIds);

  const isEditable = R.allPass([hasPermission, notTraded]);
  const amBroker = R.includes('broker', divTypes);

  return { isMyOrder, isEditable, amBroker, hasPermission };
}

export function useCounterOrders(order: Market.Order) {
  const counters = R.reverse(R.sortBy(R.prop('updated'), order.counters || []));
  const { isEditable, amBroker } = useHelpers();
  const editable = R.filter(isEditable)(counters);
  const others = R.reject(isEditable)(counters);

  return { editable, others, amBroker };
}

export function useCounter(order: Market.Order, counter: Market.Counter) {
  const { isMyOrder, isEditable, hasPermission } = useHelpers();

  const mine = isMyOrder(counter);
  const editable = isEditable(counter);
  const tradeable = hasPermission(order) && isWorking(counter);
  const respondable =
    hasPermission(order) && R.or(isWorking(counter), isOversubbed(counter));

  const _price = counter._price;
  const {
    currency,
    incoterm,
    displayed_currency = '',
    displayed_incoterm = '',
  } = _price;
  const incoterms = R.pathOr([incoterm], [currency, 'incoterms'], _price);

  const updated = moment(counter.updated);

  // XXX backend fuglies
  const val = R.pathOr(0, [currency, incoterm, 'val'], _price);
  const unit = R.pathOr('', [currency, 'unit'], _price);
  const price = { val, currency, unit, rel: 1, step: 0 };

  const vu = counter.volume.unit;
  const volunit: string = R.propOr(vu, vu, unitsOfMeasure);

  const volume = {
    val: counter.volume.qty,
    unit: volunit,
    unitdesc: volunit,
    step: 0,
    pending: 0,
  };

  const incoprices = R.map(
    id => ({
      id,
      price: {
        val: R.pathOr(0, [currency, id, 'val'], _price),
        unit,
        currency,
        rel: 1,
        step: 0,
      },
    }),
    incoterms
  );

  const displayed = displayed_incoterm
    ? {
        id: R.propOr('', 'displayed_incoterm', _price),
        price: {
          val: R.pathOr(
            0,
            [displayed_currency, displayed_incoterm, 'val'],
            _price
          ),
          unit: R.pathOr('', [displayed_currency, 'unit'], _price),
          currency: displayed_currency,
          rel: 1,
          step: 0,
        },
      }
    : undefined;

  return {
    mine,
    price,
    volume,
    incoprices,
    displayed,
    editable,
    tradeable,
    respondable,
    timestamp: updated.format('LLL'),
  };
}
