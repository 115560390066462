import React from 'react';
import qs from 'qs';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import FNumber from 'Components/FNumber';
import { PriceIndex } from 'Components/nui/PriceIndex';

const ButtonLink = ({ type, order, open_order, symbol }) => {
  const volumeMode = useStoreState(state => state.marketplace.volumeMode);

  return (
    <div className={`${type}s`}>
      {order && (
        <Link
          to={{
            pathname: `/marketplace/${order.product.id}`,
            search: qs.stringify(
              {
                order: order.id,
              },
              { addQueryPrefix: true }
            ),
          }}
          className={classNames('type', type, {
            [`own-${type}`]: order.is_owner,
            'show-volume': volumeMode,
          })}
          title={`View ${type}`}
        >
          {order.hascurrencies && <>&bull;&nbsp;</>}
          {symbol}{' '}
          {order?.index ? (
            <PriceIndex order={order} />
          ) : (
            <FNumber
              value={order.price.val}
              decimalCount={order.priceDecimalCount}
            />
          )}
          {volumeMode && (
            <>
              {' | '}
              {order.volume && order.volume.available && (
                <FNumber
                  value={order.volume.available}
                  decimalCount={order.volumeDecimalCount}
                />
              )}
            </>
          )}
          {order.is_owner && (
            <span className={`own-${type}`} title={`Your ${type}`} />
          )}
        </Link>
      )}

      {open_order && (
        <Link
          to={{
            pathname: `/marketplace/${open_order.product.id}`,
            search: qs.stringify(
              {
                order: open_order.id,
              },
              { addQueryPrefix: true }
            ),
          }}
          className={classNames(`type open-${type}`, {
            [`own-${type}`]: open_order.is_owner,
          })}
          title={`Open ${type}`}
        >
          Open <span>{type}</span>
          {open_order.is_owner && (
            <span className={`own-${type}`} title={`Your ${type}`} />
          )}
        </Link>
      )}
    </div>
  );
};

export default ({ data, symbol, BidBtn, OfferBtn }) => {
  const { bid, open_bid, offer, open_offer, cnt_bid, cnt_offer, trade } = data;

  const volumeMode = useStoreState(state => state.marketplace.volumeMode);

  return (
    <td className="trade">
      <div className="data">
        <div
          className={classNames('current', {
            'has-open-bid': bid && bid.open_order_id,
            'has-open-offer': offer && offer.open_order_id,
          })}
        >
          <div className="monthly-total">
            {cnt_bid && (
              <span className="monthly-bids" title="Total bids">
                {cnt_bid}
              </span>
            )}
            {cnt_offer && (
              <span className="monthly-offers" title="Total offers">
                {cnt_offer}
              </span>
            )}
          </div>
          {bid || open_bid ? (
            <ButtonLink
              type="bid"
              order={bid}
              open_order={open_bid}
              symbol={symbol}
            />
          ) : (
            BidBtn
          )}
          {offer || open_offer ? (
            <ButtonLink
              type="offer"
              order={offer}
              open_order={open_offer}
              symbol={symbol}
            />
          ) : (
            OfferBtn
          )}
        </div>
        {trade && (
          <div className="last">
            <Link
              className={classNames({
                'show-volume': volumeMode,
              })}
              to={`/preview/trade/${trade.id}`}
            >
              {symbol}{' '}
              <FNumber
                value={trade.price.val}
                decimalCount={trade.priceDecimalCount}
              />
              {volumeMode && (
                <>
                  {' '}
                  |{' '}
                  {trade.volume && trade.volume.val && (
                    <FNumber
                      value={trade.volume.val}
                      decimalCount={trade.volumeDecimalCount}
                    />
                  )}
                </>
              )}
            </Link>
          </div>
        )}
      </div>
    </td>
  );
};
