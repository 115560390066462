import React from 'react';
import { Pagination } from 'antd';

interface IControls {
  total: number;
  page: number;
  limit: number;
  setPage: (n: number) => any;
  setLimit: (n: number) => any;
}
interface IPagination {
  pagination: IControls;
  loading?: boolean;
  pageSizeOptions?: string[];
}
export default ({ pagination, loading, ...props }: IPagination) => {
  const { total, page, limit, setPage, setLimit } = pagination;

  if (total <= 10) return null;

  return (
    <Pagination
      total={total}
      current={page}
      pageSize={limit}
      showSizeChanger={true}
      defaultCurrent={page}
      defaultPageSize={limit}
      pageSizeOptions={['10', '20', '30', '40', '50']}
      onShowSizeChange={(_, pageSize) => {
        setLimit(pageSize);
        setPage(1);
      }}
      onChange={page => {
        setPage(page);
      }}
      disabled={loading}
      {...props}
    />
  );
};
