import type { TabsProps } from 'rc-tabs';
import React from 'react';
import Tabs from 'rc-tabs';
export { TabPane } from 'rc-tabs';

interface IConfig extends TabsProps {
  loading?: boolean;
}
export default ({ loading, children, ...props }: IConfig) => (
  <Tabs className="" prefixCls="nui-tabs" {...props}>
    {children}
  </Tabs>
);
