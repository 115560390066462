import React from 'react';
import BreadcrumbRoot from 'Pages/Config/BreadcrumbRoot';
import { useToggle } from 'react-use';
import { Button } from 'Components/nui';
import qs from 'qs';
import { useStoreState } from 'easy-peasy';
import { api } from '~/api';

const ExportButton = () => {
  const [loading, toggle] = useToggle(false);
  const solution = useStoreState(state => state.auth.solution);

  const exportToCSV = async () => {
    toggle(true);
    const params = {
      solution: solution.id,
    };
    const url = `/products?${qs.stringify(params, { indices: false })}`;
    await api.getFile(url, 'Products.csv', 'text/csv');
    toggle(false);
  };

  return (
    <Button
      htmlType="button"
      size="small"
      className="export-button"
      type="secondary"
      loading={loading}
      onClick={exportToCSV}
    >
      <span className="icon-export"></span>
      <span className="show-for-large">Export to</span> CSV
    </Button>
  );
};

export default () => {
  return (
    <div className="full-page-header">
      <h1 className="inline mt-10 mr-20 mb-0">Products</h1>
      <div className="filter-export">
        <ExportButton />
      </div>

      <ul className="nui-bc right pt-20">
        <li>
          <BreadcrumbRoot active="products" />
        </li>
        <li>Products</li>
      </ul>
    </div>
  );
};
