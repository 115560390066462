import React, { useEffect, useState, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import {
  AlternateBarBackground,
  colors,
  ColorSquare,
  NoDataAvailable,
  TooltipWrapper,
  useSharedCEData,
} from 'Pages/Dashboard';
import R from 'ramda';
import moment from 'moment-timezone';
import { Tooltip } from 'Components/nui';
import { useToggle } from 'react-use';

export default () => {
  const [data] = useSharedCEData();
  const rawData = R.propOr([], 'unique-user-company-activity-by-time', data);
  const [loading, toggle] = useToggle(true);

  const keys = ['Company', 'User'];
  const keyColors = {
    Company: colors[0],
    User: colors[1],
  };

  const [chartData, setChartData] = useState([]);
  const step = R.pathOr('1d', ['period', 'interval'], data);

  useEffect(() => {
    if (R.isEmpty(rawData)) {
      setChartData([]);
      return;
    }
    let thelist = [];
    let checker = 0;
    const parse = obj => {
      let dt = moment(obj.label);
      let date = dt.format('DD MMM');
      if (step === '1h') date = obj.x;
      if (step === '1M') date = dt.format('MMM');

      checker += R.pathOr(0, ['Company'], obj) + R.pathOr(0, ['User'], obj);

      thelist.push({
        date: date,
        Company: R.pathOr(0, ['Company'], obj),
        CompanyColor: keyColors.Company,
        User: R.pathOr(0, ['User'], obj),
        UserColor: keyColors.User,
      });
    };
    R.forEach(parse, rawData);
    setChartData(checker > 0 ? thelist : []);
    toggle(false);
  }, [rawData, setChartData, step]);

  return R.isEmpty(rawData) ? (
    !loading && (
      <div className="chart-container user-activity unique">
        <h3>
          Unique visitors
          <Tooltip
            className="icon-help-circled"
            placement="bottom"
            title={
              <span className="icon-tooltip">
                <span className="icon-info-circled" />
                Shows unique visits by individual users, and companies over the
                selected timeframe.
              </span>
            }
          />
        </h3>
        <NoDataAvailable />
      </div>
    )
  ) : (
    <div className="chart-container user-activity unique">
      <h3>
        Unique visitors
        <Tooltip
          className="icon-help-circled"
          placement="bottom"
          title={
            <span className="icon-tooltip">
              <span className="icon-info-circled" />
              Shows unique visits by individual users, and companies over the
              selected timeframe.
            </span>
          }
        />
      </h3>

      <section>
        <div className="chart">
          <ResponsiveBar
            data={chartData}
            keys={keys}
            indexBy="date"
            groupMode="grouped"
            margin={{ top: 0, right: 0, bottom: 50, left: 60 }}
            padding={0.5}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={({ id, data }) => String(data[`${id}Color`])}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            // borderRadius={3}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -30,
              // legend: 'date',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Visitors',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            tooltip={({ id, value, color }) => (
              <TooltipWrapper>
                <span className="inline-block mt-2 mr-5">
                  <ColorSquare color={color} />
                </span>{' '}
                <strong className="all-black">{id}</strong> {value}
              </TooltipWrapper>
            )}
            layers={['grid', AlternateBarBackground(keys), 'axes', 'bars']}
          />
        </div>

        <ul className="chart-legend">
          {keys.map(item => (
            <li key={item}>
              <span className="color-block mr-2 mt-2">
                <ColorSquare color={keyColors[item]} />
              </span>{' '}
              <span className="name mr-15">{item}</span>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};
