import React from 'react';
import { nl2p } from '~/utils';
import { FormattedMessage } from 'react-intl';

export default ({ record }) => {
  if (!('note' in record && record.note)) return null;

  return (
    <div className="comments m-0">
      <h2>
        <FormattedMessage
          id="orderdetails-comment-header"
          description="Header for Comment"
          defaultMessage="Comment"
        />
      </h2>

      <ul className="loading-details">
        <li className="stacked">
          <span>{nl2p(record.note)}</span>
        </li>
      </ul>
    </div>
  );
};
