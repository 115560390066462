import * as api from './api';

export async function updateLocale(locale: string) {
  return await api.modify({
    url: '/user',
    method: 'PATCH',
    data: { locale },
  });
}

export async function updateTimezone(timezone: string) {
  return await api.modify({
    url: '/user',
    method: 'PATCH',
    data: { timezone },
  });
}
