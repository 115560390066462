import type {
  IToken,
  IAnalyticsConfig,
  IAnyResponse,
  IGetResponse,
} from 'fetch';
import { TokenError } from './api';
import qs from 'qs';

const TOKEN_KEY = '__t';
const BASE_URL = process.env.REACT_APP_ANALYTICS_URL;

async function token(count = 1) {
  if (count === 10) throw new TokenError('Could not get token');

  const tk = localStorage.getItem(TOKEN_KEY);
  if (tk) return JSON.parse(tk) as IToken;

  return new Promise<IToken>(
    resolve =>
      void setTimeout(() => {
        const t = localStorage.getItem(TOKEN_KEY);
        if (t) resolve(JSON.parse(t) as IToken);
        resolve(token(count + 1));
      }, 100)
  );
}

export async function request({
  url,
  params,
  headers = {},
  ...props
}: IAnalyticsConfig): Promise<IAnyResponse> {
  const tk = await token();
  const p = qs.stringify(params, { indices: false });
  const u = BASE_URL + url + (p.length ? '?' + p : '');

  const Authorization = `${tk.token_type} ${tk.access_token}`;

  const response = await fetch(u, {
    headers: {
      Authorization,
      ...headers,
    },
    credentials: 'include',
    ...props,
  });

  return response as IAnyResponse;
}

export async function get<D>(
  config: IAnalyticsConfig
): Promise<IGetResponse<D>> {
  return (await request(config)) as IGetResponse<D>;
}
