import React, { useRef, useState, useEffect } from 'react';
import { Loading, Alert, Form, Button } from 'Components/nui';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { FormWrapper } from 'Components/form';
import { mergeSchema } from 'Components/form/utils';
import { promisify } from 'util';
import { useSharedTrade } from '../..';

export default () => {
  const [trade] = useSharedTrade();
  const schema = useRef({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    (async () => {
      try {
        const response = await api.request({
          method: 'get',
          url: `/trades/${trade.id}/freightquote/schema`,
        });
        if (mounted) {
          schema.current = response.data.fields;
          setLoading(false);
        }
      } catch (err) {
        toast.error('An error occurred while loading this form.');
        schema.current = 'error';
      }
    })();

    return () => {
      mounted = false;
    };
  }, [trade]);

  if (loading) {
    return <Loading size="small" />;
  }

  if (schema.current === 'error') {
    return (
      <Alert type="error" hasicon className="align-center">
        An error occurred.
      </Alert>
    );
  }

  return (
    <div className="info-form-area">
      <WrappedForm schema={schema.current} />
    </div>
  );
};

function useFormWrapper({ form, schema }) {
  const fields = [
    {
      name: 'accept',
      choices: [
        { key: 'yes', label: 'Accept', value: 'true' },
        { key: 'no', label: 'Decline', value: 'false' },
      ],
    },
    {
      name: 'comment',
      label: 'Add a note to the seller',
      type: null,
    },
  ];

  return new FormWrapper(form, {}, mergeSchema(schema, fields));
}

const BaseForm = ({ form, schema, type }) => {
  const formWrapper = useFormWrapper({
    form,
    schema,
    type,
  });
  const validateFieldsAndScroll = promisify(form.validateFieldsAndScroll);
  const [trade, setTrade] = useSharedTrade();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);
    try {
      await validateFieldsAndScroll();
    } catch (err) {
      setLoading(false);
      return;
    }

    try {
      const data = formWrapper.serialize();
      const response = await api.putData(
        `/trades/${trade.id}/freightquote/${trade.freightquote.quote.id}`,
        data
      );
      form.resetFields();
      toast.success('The seller was notified about your choice');

      setLoading(false);
      setTrade(trade => ({
        ...trade,
        freightquote: {
          quote: response.quote,
          status: response.quote.accepted ? 'accepted' : 'declined',
        },
      }));
    } catch (err) {
      console.error(err);
      toast.error('An error occurred while submitting this form');
    }
  }

  return (
    <div className="nui-form">
      <p className="p-0">
        The seller has set the total discount to{' '}
        <strong className="all-black nowrap">
          {trade.freightquote.quote.rate
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
          {trade.price.currency}
        </strong>
        . Please confirm to proceed.
      </p>
      <Form onSubmit={handleSubmit}>
        {formWrapper.render()}
        <Button
          type="primary"
          disabled={loading}
          loading={loading}
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

const WrappedForm = Form.create()(BaseForm);
