import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import qs from 'qs';

export default ({ type, params }) => {
  const search = qs.stringify(params, { addQueryPrefix: true });

  return (
    <div className={`${type}s`}>
      <Link
        to={{
          pathname: `/orders/${type}`,
          search,
        }}
        className={classNames(type, 'ghost')}
        title={`Create new ${type}`}
      >
        +
      </Link>
    </div>
  );
};
