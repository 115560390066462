import React from 'react';
import { FormattedMessage } from 'react-intl';

export default ({ fromaddr, toaddr }) => {
  if ((fromaddr && fromaddr.length > 0) || (toaddr && toaddr.length > 0)) {
    return (
      <>
        <h2>
          <FormattedMessage
            id="orderdetails-locations-header"
            description="Header for Location(s)"
            defaultMessage="Location(s)"
          />
        </h2>

        <ul className="loading-details">
          {fromaddr && fromaddr.length > 0 && (
            <li>
              <strong className="block">
                <FormattedMessage
                  id="orderdetails-locations-field-deliveryfrom-label"
                  description="Label for 'Delivery from' field on Location(s)"
                  defaultMessage="Delivery from"
                />
              </strong>
              <ul className="shipping-details">
                {fromaddr.map(address => (
                  <li key={address.id}>
                    <span className="block wp-100">{address.desc}</span>
                  </li>
                ))}
              </ul>
            </li>
          )}

          {toaddr && toaddr.length > 0 && (
            <li>
              <strong className="block">
                <FormattedMessage
                  id="orderdetails-locations-field-deliveryto-label"
                  description="Label for 'Delivery to' field on Location(s)"
                  defaultMessage="Delivery to"
                />
              </strong>
              <ul className="shipping-details">
                {toaddr.map(address => (
                  <li key={address.id}>
                    <span className="block wp-100">{address.desc}</span>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      </>
    );
  }

  return null;
};

// {'incoterm' in product && (
//   <li className="reversed">
//     <strong>Incoterm</strong>
//     <span>{product.incoterm}</span>
//   </li>
// )}
