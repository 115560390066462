import React, { useState, useEffect } from 'react';
import { api } from '~/api';
import { toast } from 'react-toastify';
import { useSharedCustomer } from '..';
import { Table, Loading } from 'Components/nui';
import { normalizeChoices } from 'Components/form/utils';
import FreightForm from '../FreightForm';

export default () => {
  const [customer] = useSharedCustomer();
  const [schema, setSchema] = useState();

  useEffect(() => {
    const fetch = async () => {
      const response = await api.getData2(
        `/customers/${customer.id}/freight/schema`
      );
      setSchema(response.fields);
    };
    if (customer) fetch();
  }, [customer]);

  const save = async newSelected => {
    toast('Warehouse settings are saved', {
      type: 'success',
    });

    const data = {
      warehouses: newSelected.map(id => ({ id })),
    };

    try {
      await api.putData(`/customers/${customer.id}/warehouses`, data);
    } catch (err) {
      toast.error(`Something went wrong`);
    }
  };

  const columns = [
    {
      title: '',
      key: 'expand',
      className: 'col-expand',
    },
    {
      title: 'Incoterm',
      key: 'incoterm',
      className: 'col-incoterm',
      render: (text, record, index) => (
        <div className="td-content">{record.label}</div>
      ),
    },
  ];

  if (!schema) return <Loading size="medium" />;

  return (
    <div className="full-page-content">
      <div className="generic-table expand-table light">
        <Table
          className="table-wrapper"
          columns={columns}
          data={normalizeChoices(schema.incoterm)}
          expandedRowRender={record => (
            <FreightForm customer={customer} incoterm={record.value} />
          )}
        />
      </div>
    </div>
  );
};
