import React, { useState } from 'react';
import { Alert, Button } from 'Components/nui';
import { useCustomerRequest } from './EditContext';
import {
  LabelledInfo,
  StackedInfo,
  ListedData,
  ListedEntry,
} from 'Components/nui/Wizard';
import R from 'ramda';
import { useStoreState } from '~/store';
import FNumber from 'Components/FNumber';
import { capitalize } from '~/utils';
import { toast } from 'react-toastify';
import { FormStatus } from '../Components';
import { nl2p } from '~/utils';

export default () => {
  const { customer } = useCustomerRequest();
  const editable = R.includes(customer.formstatus, ['incomplete', 'rejected']);

  return (
    <div className="customer-review">
      <FormInfo />
      <FormDetails />

      <h3>Customer details</h3>
      <CompanyDetails />

      <hr />

      <PhyAddressDetails />
      <PostAddressDetails />
      <LinksDetails />
      <LocationsDetails />
      <UsersDetails />

      {editable && (
        <>
          <hr />

          <SubmitArea />
        </>
      )}
    </div>
  );
};

const FormInfo = () => {
  const { customer } = useCustomerRequest();
  const alertStatus = R.includes(customer.formstatus, ['rejected', 'cancelled'])
    ? 'error'
    : customer.formstatus === 'processed'
    ? 'success'
    : 'info';

  return (
    <Alert type={alertStatus} hasicon={true} className="mb-10">
      {customer.formstatus === 'incomplete' ? (
        <>
          Please check the following details carefully, and correct or complete
          the form before submitting.
        </>
      ) : customer.formstatus === 'pending' ? (
        <>
          This form has been sent to Nui for processing. Nui will respond within
          two business days.
        </>
      ) : customer.formstatus === 'rejected' ? (
        <>
          Nui could not process this form. Please check the{' '}
          <strong className="all-black">Feedback</strong> field below.
        </>
      ) : customer.formstatus === 'processed' ? (
        <>
          This form has been processed by Nui. You may need to configure further
          customer details, such as products.
        </>
      ) : customer.formstatus === 'cancelled' ? (
        <>This request has been cancelled and cannot be edited.</>
      ) : (
        ''
      )}
    </Alert>
  );
};

const FormDetails = () => {
  const { customer } = useCustomerRequest();
  const props = { className: 'stacked' };

  return (
    <LabelledInfo
      data={[
        ['Form status', <FormStatus customer={customer} />],
        ['Your comments', nl2p(customer.comment || 'No comments'), props],
        ['Nui feedback', nl2p(customer.feedback || 'No feedback'), props],
      ]}
    />
  );
};

const NotSet = (
  <span className="icon-attention not-set">
    Not set <span className="optional">(optional)</span>
  </span>
);

const CompanyDetails = () => {
  const { customer } = useCustomerRequest();
  const { company } = customer;

  if (company)
    return (
      <LabelledInfo
        data={[
          ['Company name', company.name || NotSet],
          ['Company no', company.companyno || NotSet],
          ['Currency', company.currency || NotSet],
          ['Email', company.email || NotSet],
          ['Phone', company.workphone || NotSet],
          ['Mobile', company.mobilephone || NotSet],
        ]}
      />
    );

  return null;
};

const PhyAddressDetails = () => {
  const { customer } = useCustomerRequest();
  const { address } = customer;

  if (address)
    return (
      <StackedInfo
        title="Physical address"
        className="summary-list stacked inline-block wp-50 valign-t"
        data={[
          address.phyaddr1,
          address.phyaddr2,
          address.phycity,
          address.phystate,
          address.phyzip,
          address.phycountry?.name,
        ]}
      />
    );

  return null;
};

const PostAddressDetails = () => {
  const { customer } = useCustomerRequest();
  const { address } = customer;
  const sameasphy =
    address.postaddr1 === address.phyaddr1 &&
    address.postaddr2 === address.phyaddr2 &&
    address.postcity === address.phycity &&
    address.poststate === address.phystate &&
    address.postzip === address.phyzip &&
    address.postcountry?.name === address.phycountry?.name;

  if (address)
    return (
      <StackedInfo
        title="Postal address"
        className="summary-list stacked inline-block wp-50 valign-t"
        data={
          sameasphy
            ? ['Same as physical address']
            : [
                address.postaddr1,
                address.postaddr2,
                address.postcity,
                address.poststate,
                address.postzip,
                address.postcountry?.name,
              ]
        }
      />
    );

  return null;
};

const sortByPath = (path: string[]) => (a: any, b: any) =>
  R.pathOr('', path, a)?.localeCompare(R.pathOr('', path, b));

const LinksDetails = () => {
  const currency = useStoreState(state => state.auth.solution.currency);
  const { customer } = useCustomerRequest();
  const { links } = customer;

  if (links && links.length)
    return (
      <ListedData title="Linked divisions">
        {links.sort(sortByPath(['division', 'name'])).map(link => (
          <ListedEntry
            key={link.id}
            title={link.division.name}
            data={[
              [
                `Credit limit (${currency})`,
                link.nocreditlimit ? (
                  'No limit'
                ) : (
                  <FNumber value={link.creditamount} />
                ),
              ],
              [
                `Finance premium (${currency})`,
                link.financepremium !== undefined && (
                  <FNumber value={link.financepremium} decimalCount={2} />
                ),
              ],
              [
                `Customer premium (${currency})`,
                link.customerpremium !== undefined && (
                  <FNumber value={link.customerpremium} decimalCount={2} />
                ),
              ],
              ['Payment term', link.paymentterm?.name],
            ]}
          />
        ))}
      </ListedData>
    );

  return null;
};

const getAddr = (location: any) =>
  R.filter(R.identity, [
    location.addr1,
    location.addr2,
    location.city,
    location.state,
    location.zip,
  ]).join(', ');

const LocationsDetails = () => {
  const { customer } = useCustomerRequest();
  const { locations } = customer;

  if (locations && locations.length)
    return (
      <ListedData title="Locations">
        {locations.sort(sortByPath(['description'])).map(location => (
          <ListedEntry
            key={location.id}
            title={location.description}
            data={[
              ['Default location', location.isdefault ? 'Yes' : null],
              ['Address', getAddr(location) || NotSet],
              ['Country', location.country?.name],
              ['Port', location.port?.name],
            ]}
          />
        ))}
      </ListedData>
    );

  return null;
};

const UsersDetails = () => {
  const { customer } = useCustomerRequest();
  const { users } = customer;

  if (users && users.length)
    return (
      <ListedData title="People">
        {users.sort(sortByPath(['firstname'])).map(user => (
          <ListedEntry
            key={user.id}
            title={`${user.firstname} ${user.lastname}`}
            data={[
              ['Email', <a href={`mailto:${user.email}`}>{user.email}</a>],
              ['Role', capitalize(user.role)],
              ['Phone', user.ddiphone || NotSet],
              ['Mobile', user.mobilephone || NotSet],
              ['Timezone', user.timezone],
            ]}
          />
        ))}
      </ListedData>
    );

  return null;
};

const SubmitArea = () => {
  const { customer, actions } = useCustomerRequest();
  const [comment, setComment] = useState(customer.comment);
  const [loading, setLoading] = useState(false);

  return (
    <div className="submit-form">
      <div className="nui-fieldset">
        <label htmlFor="comment">
          <strong className="all-black">Add a note (internal use only)</strong>
          <span className="optional">Optional</span>
        </label>
        <div className="input-holder">
          <p className="smaller light-gray">
            Add any extra details you would like to pass on to the Nui admin
            team.
          </p>
          <textarea
            id="comment"
            value={comment || ''}
            onChange={e => {
              setComment(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="sticky-btm button-set trio mt-20">
        <Button
          type="primary"
          onClick={async () => {
            setLoading(true);
            try {
              await actions.submit(comment);
              window.scrollTo(0, 0);
              toast('Form submitted successfully', { type: 'success' });
            } catch (error) {
              console.error({ error });
              toast('There was an error submitting this form.', {
                type: 'error',
              });
            }
            setLoading(false);
          }}
          loading={loading}
          disabled={loading}
        >
          Submit request
        </Button>
      </div>
    </div>
  );
};
