import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { api } from './api';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory, useRouteMatch } from 'react-router';
import { UUID } from '~/Routes';
import R from 'ramda';
import qs from 'qs';

export default () => {
  const [waiting, setWaiting] = useState(true);
  const [_403, set403] = useState(false);
  // const [, set403] = useState(false);
  const solution = useStoreState(state => state.auth.solution);
  const checkAuth = useStoreActions(actions => actions.auth.checkAuth);
  const history = useHistory();
  const orderMatch = useRouteMatch(`/orders/:id(${UUID})`);
  const tradeMatch = useRouteMatch(`/trades/:id(${UUID})`);
  const tenderMatch = useRouteMatch(`/tenders/:id(${UUID})`);
  const marketplaceMatch = useRouteMatch(`/`);

  const save = async () => {
    const search = R.path(['location', 'search'], history);
    const pathname = R.path(['location', 'pathname'], history);
    let data = {
      solution: solution.id,
      url: `${pathname}${search}`,
    };
    if (orderMatch) {
      data.order = R.path(['params', 'id'], orderMatch);
    }
    if (tradeMatch) {
      data.trade = R.path(['params', 'id'], tradeMatch);
    }
    if (tenderMatch) {
      data.tender = R.path(['params', 'id'], tenderMatch);
    }
    if (R.path(['isExact'], marketplaceMatch) && search) {
      const parse = qs.parse(search.slice(1));
      data.order = R.prop('order', parse);
    }
    try {
      const response = await api.userActivity(data);
      if (
        response.status === 403 &&
        R.hasPath(['headers', 'reason'], response)
      ) {
        set403(true);
      } else {
        set403(false);
      }
    } catch (error) {
      // set403(true);
    }
  };

  useEffect(() => {
    if (_403) checkAuth();
  }, [_403, checkAuth]);

  useEffect(() => {
    if (solution) save();
  }, [solution, history.location]);

  const handleMouseMove = () => {
    if (waiting) setWaiting(false);
  };

  useEffect(() => {
    if (solution && !waiting) save();
  }, [waiting, solution]);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove]);

  useInterval(() => {
    setWaiting(true);
  }, 60000);

  return null;
};
