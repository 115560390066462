import React, { useEffect, useMemo } from 'react';
import BannerCard from './BannerCard';
import { useStoreState, useStoreActions } from '~/store';
import { IBanner } from '~/store/types';
import moment from 'moment-timezone';

interface IBannerList {
  items: IBanner[];
}

export default ({ items }: IBannerList) => {
  const solutionId = useStoreState(state => state.auth.solution.id);

  const { apiList } = useStoreActions(state => state.banners);

  useEffect(() => {
    apiList(solutionId);
  }, [solutionId]);

  const sortedItems = useMemo(
    () =>
      !items || items.length === 0
        ? null
        : [...items].sort((a, b) => moment(a.start).diff(b.end, 'minutes')),
    [items]
  );

  if (!sortedItems) {
    return <div>No banners to show.</div>;
  }

  return (
    <div className="banner-list">
      {sortedItems.map(item => {
        return <BannerCard key={item.id} id={item.id} />;
      })}
    </div>
  );
};
