export const COLUMN_KEYS = [
  'attachments',
  'gfsi',
  'export',
  'halal',
  'kosher',
  'exportable',
  'counteronly',
  'exportdocs',
  'callofftime',
  'origins',
  'locations',
  'age',
  'fromaddr',
  'toaddr',
  'incoterms',
  'buyer',
  'consignee',
  'paymentterm',
  'reference_price',
  'variants',
] as const;

export type ColumnKeys = typeof COLUMN_KEYS[number];
